import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { CHANGE_USER_SELECTED_LOCALE, CHANGE_USER_TOKEN_LOCALE } from '../../../../configuration/lang/actions';
import {
    localDateToLocalEndOfDayInUtc,
    localDateToLocalStartOfDayInUtc,
    minusDuration,
    plusDuration,
    startOfWeek,
    today,
} from '../../../../dateUtils';
import { neverReachedFor } from '../../../../utils';
import { transportOrderApi } from '../../api/transportOrder/transportOrdersApi';
import { Filter } from '../../domain/filters.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { TransportOrder } from '../../domain/transportOrder.types';
import { TimeRangeType } from '../../domain/transportOrderOptions.types';
import { calculateAllowedFilterValues } from '../../domain/utils.transportOrders';
import { filterAndSortTransportOrders } from './TransportOrders.util';

const calculateFromAndTo = (
    preSelection: TimeRangeType,
):
    | {
          startDate: string;
          endDate: string;
      }
    | undefined => {
    let startDate: string;
    let endDate: string;
    switch (preSelection) {
        case TimeRangeType.TODAY:
            startDate = localDateToLocalStartOfDayInUtc(today());
            endDate = localDateToLocalEndOfDayInUtc(today());
            break;
        case TimeRangeType.YESTERDAY:
            const yesterday = minusDuration(today(), { days: 1 });
            startDate = localDateToLocalStartOfDayInUtc(yesterday);
            endDate = localDateToLocalEndOfDayInUtc(yesterday);
            break;
        case TimeRangeType.TOMORROW:
            const tomorrow = plusDuration(today(), { days: 1 });
            startDate = localDateToLocalStartOfDayInUtc(tomorrow);
            endDate = localDateToLocalEndOfDayInUtc(tomorrow);
            break;
        case TimeRangeType.LAST_WEEK:
            const startOfLastWeek = minusDuration(startOfWeek(today()), { weeks: 1 });
            startDate = localDateToLocalStartOfDayInUtc(startOfLastWeek);
            endDate = localDateToLocalEndOfDayInUtc(plusDuration(startOfLastWeek, { days: 6 }));
            break;
        case TimeRangeType.CUSTOM:
            return undefined;
        default:
            neverReachedFor(preSelection);
            return undefined;
    }
    return {
        startDate,
        endDate,
    };
};

const recalculateFilters = (oldFilters: Filter, transportOrders: TransportOrder[]): Filter => {
    const allowedFilterValues = calculateAllowedFilterValues(transportOrders);
    return {
        freightForwarderName: allowedFilterValues.freightForwarderName?.find(
            (value) => value === oldFilters.freightForwarderName,
        ),
        transportConcept: allowedFilterValues.transportConcept?.find((value) => value === oldFilters.transportConcept),
        placeOfDischarge: allowedFilterValues.placeOfDischarge?.find((value) => value === oldFilters.placeOfDischarge),
        buyerName: allowedFilterValues.buyerName?.find((value) => value === oldFilters.buyerName),
        plantNumber: allowedFilterValues.plantNumber?.find((value) => value === oldFilters.plantNumber),
    };
};

const mustResetTransportOrderId = (selectedId: string | undefined, newTransportOrders: TransportOrder[]) =>
    selectedId && !newTransportOrders.map((order) => order.id).includes(selectedId);
const mustResetSelectedTransportOrders = (
    selected: SelectedTransportOrder[],
    newOrders: TransportOrder[],
    filters: Filter,
): boolean => {
    const newOrdersFiltered = filterAndSortTransportOrders(newOrders, filters);
    return !selected.every((selectedOrder) =>
        newOrdersFiltered.some(
            (order, index) => order.id === selectedOrder.transportOrderId && index === selectedOrder.index,
        ),
    );
};

const changeUserSelectedLocale = createAction<string>(CHANGE_USER_SELECTED_LOCALE);
const changeTokenSelectedLocale = createAction<string>(CHANGE_USER_TOKEN_LOCALE);

interface TransportOrdersState {
    locale: string;
    showDetailsForTransportOrder: string | undefined;
    selectedTransportOrders: SelectedTransportOrder[];
    filters: TransportOrderFilters;
}

export interface SelectedTransportOrder {
    shipmentId: string;
    shipmentNumber: number;
    transportOrderId: string;
    transportOrderNumber: string;
    transportConcept: string;
    index: number;
}

interface TransportOrderFilters extends Filter {
    loadingFrom?: string;
    loadingTo?: string;
    timeRangeType: TimeRangeType;
}

const initialState: TransportOrdersState = {
    locale: 'en-GB',
    showDetailsForTransportOrder: undefined,
    selectedTransportOrders: [],
    filters: {
        loadingFrom: undefined,
        loadingTo: undefined,
        timeRangeType: TimeRangeType.TODAY,
        freightForwarderName: undefined,
        transportConcept: undefined,
        placeOfDischarge: undefined,
        buyerName: undefined,
        plantNumber: undefined,
    },
};

export const transportOrdersSlice = createSlice({
    name: 'transportOrders',
    initialState,
    reducers: {
        setShowDetailsForTransportOrder: (state: TransportOrdersState, action: PayloadAction<string | undefined>) => {
            state.showDetailsForTransportOrder = action.payload;
        },
        addSelectedTransportOrder: (state: TransportOrdersState, action: PayloadAction<SelectedTransportOrder>) => {
            state.selectedTransportOrders.push(action.payload);
        },
        removeSelectedTransportOrder: (state: TransportOrdersState, action: PayloadAction<string>) => {
            state.selectedTransportOrders = state.selectedTransportOrders.filter(
                (order) => order.transportOrderId !== action.payload,
            );
        },
        clearSelectedTransportOrders: (state: TransportOrdersState) => {
            state.selectedTransportOrders = [];
        },
        setLoadingTimeFromFilter: (state: TransportOrdersState, action: PayloadAction<string>) => {
            state.filters.loadingFrom = action.payload;
        },
        setLoadingTimeToFilter: (state: TransportOrdersState, action: PayloadAction<string>) => {
            state.filters.loadingTo = action.payload;
        },
        setFilterTimeRangeType: (state: TransportOrdersState, action: PayloadAction<TimeRangeType>) => {
            const calculatedFromAndTo = calculateFromAndTo(action.payload);
            state.filters.timeRangeType = action.payload;
            if (calculatedFromAndTo !== undefined) {
                const { startDate, endDate } = calculatedFromAndTo;
                state.filters.loadingFrom = startDate;
                state.filters.loadingTo = endDate;
            }
        },
        setFreightForwarderNameFilter: (state: TransportOrdersState, action: PayloadAction<string | undefined>) => {
            if (state.filters.freightForwarderName !== action.payload) {
                state.selectedTransportOrders = [];
            }
            state.filters.freightForwarderName = action.payload;
        },
        setTransportConceptFilter: (
            state: TransportOrdersState,
            action: PayloadAction<TransportConcept | undefined>,
        ) => {
            if (state.filters.transportConcept !== action.payload) {
                state.selectedTransportOrders = [];
            }
            state.filters.transportConcept = action.payload;
        },
        setPlaceOfDischargeFilter: (state: TransportOrdersState, action: PayloadAction<string | undefined>) => {
            if (state.filters.placeOfDischarge !== action.payload) {
                state.selectedTransportOrders = [];
            }
            state.filters.placeOfDischarge = action.payload;
        },
        setBuyerNameFilter: (state: TransportOrdersState, action: PayloadAction<string | undefined>) => {
            if (state.filters.buyerName !== action.payload) {
                state.selectedTransportOrders = [];
            }
            state.filters.buyerName = action.payload;
        },
        setPlantNumberFilter: (state: TransportOrdersState, action: PayloadAction<string | undefined>) => {
            if (state.filters.plantNumber !== action.payload) {
                state.selectedTransportOrders = [];
            }
            state.filters.plantNumber = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeUserSelectedLocale, (state, action) => {
                state.locale = action.payload;
            })
            .addCase(changeTokenSelectedLocale, (state, action) => {
                state.locale = action.payload;
            })
            .addMatcher(
                transportOrderApi.endpoints.getTransportOrders.matchFulfilled,
                (state: TransportOrdersState, action: PayloadAction<TransportOrder[]>) => {
                    const newFilters = recalculateFilters(state.filters, action.payload);

                    state.filters.placeOfDischarge = newFilters.placeOfDischarge;
                    state.filters.buyerName = newFilters.buyerName;
                    state.filters.plantNumber = newFilters.plantNumber;
                    state.filters.freightForwarderName = newFilters.freightForwarderName;
                    state.filters.transportConcept = newFilters.transportConcept;

                    if (mustResetTransportOrderId(state.showDetailsForTransportOrder, action.payload)) {
                        state.showDetailsForTransportOrder = undefined;
                    }
                    if (mustResetSelectedTransportOrders(state.selectedTransportOrders, action.payload, newFilters)) {
                        state.selectedTransportOrders = [];
                    }
                },
            )
            .addDefaultCase(() => {});
    },
});
