import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../hooks/useImage';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { NewInnerPackagingStepConfiguration, PackagingStepType } from '../../../reducers/deliverySchedules/types';
import { getSelectedDeliverySchedule } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { PackagingMaterialFormValues } from '../../common/form/BoxFinderInput';
import { FieldErrorType, FormErrors } from '../../common/form/types';
import { BoxFinder } from '../../packaging/BoxFinder';
import { formPropertyNamesInnerCreationPackagingTemplate } from '../../shipments/packaging/dialogs/InnerPackaging/InnerPackagingFormConfig';
import { PACKAGING_TEMPLATE_TEST_IDS } from './PackagingTemplateConfiguration';

export const INNER_PACKAGING_TEMPLATE_TEST_IDS = {
    content: 'INNER_PACKAGING_TEMPLATE_DIALOG_CONTENT_TEST_ID',
};

export const formIds = {
    form: 'innerPackagingTemplateDialogForm',
};

export interface InnerPackagingTemplateFormValues {
    quantityValuePerHandlingUnit?: number;
    packagingMaterial?: PackagingMaterialFormValues;
}

export interface InnerPackagingTemplatePackagingMaterialSelectionFormValues {
    packagingMaterial?: PackagingMaterialFormValues;
}

type InnerPackagingFormErrors = FormErrors<InnerPackagingTemplateFormValues>;

export const validateForm = ({ packagingMaterial }: InnerPackagingTemplateFormValues): InnerPackagingFormErrors => {
    const validationResult: InnerPackagingFormErrors = {
        packagingMaterial: {},
    };
    if (packagingMaterial?.boxCode === undefined) {
        validationResult.packagingMaterial!.boxCode = FieldErrorType.REQUIRED;
    }
    if (packagingMaterial) {
        const { tareWeightInKg, ownership, isReusable, stackingFactor } = packagingMaterial;

        if (tareWeightInKg === undefined || String(tareWeightInKg) === '') {
            validationResult.packagingMaterial!.tareWeightInKg = FieldErrorType.REQUIRED;
        }
        if (ownership === undefined) {
            validationResult.packagingMaterial!.ownership = FieldErrorType.REQUIRED;
        }
        if (isReusable === undefined) {
            validationResult.packagingMaterial!.isReusable = FieldErrorType.REQUIRED;
        }
        if (stackingFactor === undefined || String(stackingFactor) === '') {
            validationResult.packagingMaterial!.stackingFactor = FieldErrorType.REQUIRED;
        }
    }
    return validationResult;
};

export const mapInnerPackagingTemplateFormValuesToInnerPackagingStep = (
    values: Required<InnerPackagingTemplatePackagingMaterialSelectionFormValues>,
    articleNumber: string,
    handlingUnitDescription?: string,
): NewInnerPackagingStepConfiguration => {
    return {
        type: PackagingStepType.INNER,
        articleNumberBuyer: articleNumber,
        typeOfHandlingUnit: values.packagingMaterial.boxCode!,
        descriptionOfHandlingUnit: handlingUnitDescription,
        prerequisites: [],
        handlingUnit: {
            type: values.packagingMaterial.boxCode!,
            description: values.packagingMaterial.name,
            tareWeightInKg: values.packagingMaterial.tareWeightInKg!,
            ownership: values.packagingMaterial.ownership!,
            isReusable: values.packagingMaterial.isReusable!,
            stackingFactor: values.packagingMaterial.stackingFactor!,
            widthInMm: values.packagingMaterial.outerWidthInMm,
            lengthInMm: values.packagingMaterial.outerLengthInMm,
            heightInMm: values.packagingMaterial.outerHeightInMm,
        },
    };
};

export const InnerPackagingTemplateConfigurationContainer = () => {
    const dispatch = useAppDispatch();
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);
    const packagingMaterialSelectionImage = useImage('packagingMaterialSelection');

    useEffect(() => {
        dispatch(deliverySchedulesSlice.actions.updateFormIdInPackagingWizard(formIds.form));
    }, [dispatch]);

    const articleNumber =
        selectedDeliverySchedule!.scheduledArticleDetails.lineItem.lineItemIdentifier.itemNumberIdentification
            .itemIdentifier!;

    const initialValues: InnerPackagingTemplateFormValues = {
        packagingMaterial: undefined,
        quantityValuePerHandlingUnit: undefined,
    };

    const onSubmitHandler = (values: InnerPackagingTemplateFormValues) => {
        const handlingUnitDescription = values.packagingMaterial?.name;
        const innerPackagingStep = () => {
            return mapInnerPackagingTemplateFormValuesToInnerPackagingStep(
                values as Required<InnerPackagingTemplatePackagingMaterialSelectionFormValues>,
                articleNumber,
                handlingUnitDescription,
            );
        };
        dispatch(deliverySchedulesSlice.actions.updateTemplateStepConfiguration(innerPackagingStep()));
        dispatch(deliverySchedulesSlice.actions.showAuxiliaryPackagingStep());
    };

    return (
        <Form<InnerPackagingTemplateFormValues>
            onSubmit={onSubmitHandler}
            initialValues={initialValues}
            validate={(values) => validateForm(values)}
            render={({ handleSubmit }) => {
                return (
                    <div
                        className={
                            'display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct ' +
                            'margin-left-20pct margin-right-20pct min-height-500'
                        }
                        data-testid={PACKAGING_TEMPLATE_TEST_IDS.packagingMaterialSelection}
                    >
                        <div className={'display-flex flex-column'}>
                            <div className={'text-size-18 text-center margin-bottom-25'}>
                                <FormattedMessage id={'webedi.packaging.innerPackaging.step1.title'} />
                            </div>
                            <div className={'counter color-highlight'}>
                                <div>
                                    <FormattedMessage
                                        id={'webedi.packaging.innerPackaging.step1.explanation'}
                                        values={{
                                            articleNumber,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                'border-style-solid border-color-light border-width-1 padding-left-5pct padding-right-5pct padding-20'
                            }
                        >
                            <div className={'text-center margin-bottom-20'}>
                                <img
                                    src={packagingMaterialSelectionImage}
                                    className={'width-70pct'}
                                    alt={'packaging_step_1'}
                                />
                            </div>
                            <form id={formIds.form} onSubmit={handleSubmit}>
                                <BoxFinder
                                    id={formPropertyNamesInnerCreationPackagingTemplate.packagingMaterial}
                                    name={formPropertyNamesInnerCreationPackagingTemplate.packagingMaterial}
                                    showLinkToVWMaterialHelp={true}
                                />
                            </form>
                        </div>
                    </div>
                );
            }}
        />
    );
};
