import { ValidPackagingMaterial } from '../../../../../reducers/packaging/types';

export const formIds = {
    formStep1: 'outerPackagingStep1DialogForm',
    formStep2: 'outerPackagingStep2DialogForm',
    packagingMaterial: 'packagingMaterial',
};

export interface OuterPackagingStep2FormValues {
    packagingMaterial?: ValidPackagingMaterial;
}

export const formPropertyNamesStep2: Record<keyof OuterPackagingStep2FormValues, keyof OuterPackagingStep2FormValues> =
    {
        packagingMaterial: 'packagingMaterial',
    };
