// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { MeasurementUnitCode } from '../../domain/common.types';
import { ItemShortDescriptionCodec } from '../shared/itemShortDescription.types';
import { fromEnum } from '../util';
import { buyerCodec, shipFromCodec, shipToCodec } from './common.types';

const articleSuggestionCodec = t.type({
    referenced_delivery_schedule_id: t.string,
    article_number_buyer: t.string,
    item_short_descriptions: t.array(ItemShortDescriptionCodec),
    measurement_unit_code: fromEnum<MeasurementUnitCode>('MeasurementUnitCode', MeasurementUnitCode),
    has_valid_article_master_data: t.boolean,
    has_valid_cumulative_quantity_sent: t.boolean,
    buyer: buyerCodec,
    ship_from: shipFromCodec,
    ship_to: shipToCodec,
});

export type ApiArticleSuggestion = t.TypeOf<typeof articleSuggestionCodec>;

export const getArticleSuggestionsResponseCodec = t.type({
    items: t.array(articleSuggestionCodec),
});
