import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';
import { useState } from 'react';
import { TransportOrder } from '../../../domain/transportOrder.types';
import { PreSelectionDropdown } from './PreSelectionDropdown';
import { TimeRangeSelection } from './TimeRangeSelection';
import { TransportOrderFilterTags } from './TransportOrderFilterTags';
import { TransportOrdersFilterPanel } from './TransportOrdersFilterPanel';
import { TransportOrdersFilterToggleButton } from './TransportOrdersFilterToggleButton';

interface TransportTableToolbarProps {
    transportOrders: TransportOrder[];
}

export const TransportTableToolbar = (props: TransportTableToolbarProps) => {
    const [showFilter, setShowFilter] = useState<boolean>(false);

    return (
        <>
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div className={'table-toolbar-group-left'}>
                        <div className={'table-toolbar-column'}>
                            <div className={'display-flex'}>
                                <PreSelectionDropdown />
                                <TimeRangeSelection />
                            </div>
                        </div>
                    </div>
                    <div className={'table-toolbar-group-right'}>
                        <div className={'table-toolbar-column'}>
                            <TransportOrderFilterTags />
                        </div>
                        <div className={'table-toolbar-column'}>
                            <TransportOrdersFilterToggleButton
                                active={showFilter}
                                onClick={() => setShowFilter((current) => !current)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'table-row margin-bottom-10'}>
                <Collapse open={showFilter} unmountOnExit={true}>
                    <div className={'panel-wrapper'}>
                        <div className={'panel panel-default'}>
                            <div className={'display-flex justify-content-end'}>
                                <button
                                    className={'btn btn-xs btn-icon-only btn-outline'}
                                    onClick={() => setShowFilter(false)}
                                >
                                    <span className={'rioglyph rioglyph-remove toggle-icon close'} />
                                </button>
                            </div>
                            <div className={'panel-body'}>
                                <TransportOrdersFilterPanel transportOrders={props.transportOrders} />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </>
    );
};
