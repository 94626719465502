// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { AddressCodec } from './address.types';

export const PlaceOfDischargeCodec = t.intersection([
    t.type({
        id: t.string,
    }),
    t.partial({
        name: t.string,
        address: AddressCodec,
    }),
]);

export type ApiPlaceOfDischarge = t.TypeOf<typeof PlaceOfDischargeCodec>;
