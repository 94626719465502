import { ReactElement, ReactNode } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { formatWeight } from '../../../../utils';
import {
    DispatchProposalItem,
    FreightForwarder,
    PackagedDispatchProposalItem,
    UnpackagedDispatchProposalItem,
    isPackagedDispatchProposalItem,
    isUnpackagedDispatchProposalItem,
} from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import {
    getFreightForwarderOfSelectedDispatchProposalItems,
    getSelectedDispatchProposalItems,
    getTransportConceptOfSelectedDispatchProposalItems,
} from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { ArticleNumber } from '../common/ArticleNumber';
import { NoDataAvailable } from '../common/NoDataAvailable';
import { Tooltip } from '../common/Tooltip';

export const DISPATCH_PROPOSAL_PLANNING_SIDEBAR_BODY_TEST_ID = 'DISPATCH_PROPOSAL_PLANNING_SIDEBAR_BODY';
export const DISPATCH_PROPOSAL_PLANNING_SIDEBAR_PLANT_CODES_TEST_ID = 'DISPATCH_PROPOSAL_PLANNING_SIDEBAR_PLANT_CODES';
export const DISPATCH_PROPOSAL_PLANNING_SIDEBAR_SUM_OUTER_PACKAGING_TEST_ID = 'SUM_OUTER_PACKAGING';
export const DISPATCH_PROPOSAL_PLANNING_SIDEBAR_SUM_WEIGHT_TEST_ID = 'SUM_WEIGHTS';

const getArticleNumberFromDispatchProposalItem = (
    item: UnpackagedDispatchProposalItem | PackagedDispatchProposalItem,
): string => {
    if (isUnpackagedDispatchProposalItem(item)) {
        return item.articleContent.articleNumberBuyer;
    } else {
        return item.articleContents.map((content) => content.articleNumberBuyer).join(', ');
    }
};

const getSumOfOuterPackagingFromDispatchProposalItems = (dispatchProposalItems: DispatchProposalItem[]) =>
    dispatchProposalItems
        .filter(isPackagedDispatchProposalItem)
        .map((it) => it.outerPackaging.count)
        .reduce((sum, count) => sum + count, 0);

const getSumOfGrossWeightInKgFromDispatchProposalItems = (dispatchProposalItems: DispatchProposalItem[]) =>
    dispatchProposalItems
        .filter(isPackagedDispatchProposalItem)
        .map((it) => it.grossWeightInKg)
        .reduce((sum, weight) => sum + weight, 0);

const getTableKeyForDispatchProposalItem = (
    dispatchProposalItem: UnpackagedDispatchProposalItem | PackagedDispatchProposalItem,
): string => {
    if (isUnpackagedDispatchProposalItem(dispatchProposalItem)) {
        return `${dispatchProposalItem.identifier.shipmentId}#${dispatchProposalItem.identifier.deliveryNoteNumber}#${
            dispatchProposalItem.identifier.deliveryNotePosition
        }`;
    } else {
        return dispatchProposalItem.identifier.id;
    }
};

const getPlantCodesFromDispatchProposalItems = (
    dispatchProposalItems: DispatchProposalItem[],
    transportConcept: TransportConcept,
): string[] => {
    if (transportConcept === TransportConcept.FTL) {
        return [dispatchProposalItems[0].shipTo.plantCode];
    } else {
        return dispatchProposalItems
            .map((it) => it.shipTo.plantCode)
            .filter((value, index, array) => array.indexOf(value) === index);
    }
};
const ArticleNumberColumn = (props: {
    item: DispatchProposalItem;
}) => {
    const articleNumbers = getArticleNumberFromDispatchProposalItem(props.item);
    return (
        <td>
            <span className={'display-flex column-gap-5 ellipsis-1 flex-0-1'}>
                <Tooltip text={articleNumbers} placement={'bottom'}>
                    <span className={'margin-right-15 ellipsis-1 word-break'}>
                        <ArticleNumber articleNumber={articleNumbers} />
                    </span>
                </Tooltip>
            </span>
        </td>
    );
};

const PackagingQuantityColumn = (props: {
    item: DispatchProposalItem;
}) => {
    const item = props.item;
    if (isUnpackagedDispatchProposalItem(item)) {
        return (
            <td>
                <NoDataAvailable />
            </td>
        );
    } else {
        return (
            <td>
                <div>
                    <span className={'display-flex column-gap-5 ellipsis-1 flex-0-1'}>
                        <span className={'rioglyph rioglyph-parcel text-size-18'} />
                        <span className={'margin-right-15 ellipsis-1 word-break'}>{item.outerPackaging.count}</span>
                    </span>
                </div>
            </td>
        );
    }
};
const PlaceOfDischargeColumn = (props: {
    item: DispatchProposalItem;
}) => {
    return (
        <td>
            <span className={'display-flex column-gap-5 ellipsis-1 flex-0-1'}>
                <span className={'rioglyph rioglyph-load text-size-18'} />
                <span className={'margin-right-15 ellipsis-1 word-break'}>{props.item.shipTo.placeOfDischarge}</span>
            </span>
        </td>
    );
};
const OuterPackagingMaterialColumn = (props: {
    item: DispatchProposalItem;
}) => {
    const item = props.item;
    if (isUnpackagedDispatchProposalItem(item)) {
        return (
            <td>
                <NoDataAvailable />
            </td>
        );
    } else {
        return (
            <td>
                <div className={'ellipsis-1'}>{item.outerPackaging.type}</div>
            </td>
        );
    }
};
const renderRow = (dispatchProposalItem: DispatchProposalItem): ReactElement => {
    return (
        <tr className={'bg-highlight-lightest'} key={getTableKeyForDispatchProposalItem(dispatchProposalItem)}>
            <ArticleNumberColumn item={dispatchProposalItem} />
            <PlaceOfDischargeColumn item={dispatchProposalItem} />
            <OuterPackagingMaterialColumn item={dispatchProposalItem} />
            <PackagingQuantityColumn item={dispatchProposalItem} />
        </tr>
    );
};

const HeadlineTransportConceptInfo = (props: { transportConcept: TransportConcept }) => {
    if (props.transportConcept === TransportConcept.FTL) {
        return (
            <>
                <span className={'rioglyph rioglyph-delivery-on-track text-size-18'} />
                <span className={'text-medium'}>LKW Megatrailer,</span>
            </>
        );
    } else {
        return (
            <>
                <span className={'rioglyph rioglyph-delivery-on-track text-size-18'} />
                <span className={'text-medium'}>
                    <FormattedMessage id={'webedi.dispatchProposals.planDispatchProposal.body.headline.ltl'} />
                </span>
            </>
        );
    }
};

const Headline = (props: {
    freightForwarder: FreightForwarder | undefined;
    transportConcept: TransportConcept;
    dispatchProposalItems: DispatchProposalItem[];
}) => {
    if (!props.freightForwarder || props.dispatchProposalItems.length === 0) {
        return <></>;
    }
    const dispatchProposalPlantCodes = getPlantCodesFromDispatchProposalItems(
        props.dispatchProposalItems,
        props.transportConcept,
    );

    return (
        <div className={'panel-heading'}>
            <div className={'display-flex column-gap-10 justify-content-between height-30'}>
                <span className={'display-flex align-items-center column-gap-5 ellipsis-1 flex-1-1-0'}>
                    <HeadlineTransportConceptInfo transportConcept={props.transportConcept} />
                    <span className={'display-flex column-gap-5 ellipsis-1'}>
                        <span className={'margin-right-15 ellipsis-1 word-break'}>{props.freightForwarder.name}</span>
                    </span>
                </span>
                <span className={'display-flex align-items-center column-gap-5 ellipsis-1 flex-0-1'}>
                    <span className={'text-color-gray text-normal'}>
                        <FormattedPlural
                            value={dispatchProposalPlantCodes.length}
                            zero={
                                <FormattedMessage
                                    id={'webedi.dispatchProposals.planDispatchProposal.headline.plant.zero'}
                                />
                            }
                            one={
                                <FormattedMessage
                                    id={'webedi.dispatchProposals.planDispatchProposal.headline.plant.one'}
                                />
                            }
                            other={
                                <FormattedMessage
                                    id={'webedi.dispatchProposals.planDispatchProposal.headline.plant.other'}
                                />
                            }
                        />
                        {': '}
                    </span>
                    <span className={'rioglyph rioglyph-factory text-size-18'} />
                    <span
                        className={'margin-right-15 ellipsis-1 word-break text-right padding-right-10'}
                        data-testid={DISPATCH_PROPOSAL_PLANNING_SIDEBAR_PLANT_CODES_TEST_ID}
                    >
                        {dispatchProposalPlantCodes.join(', ')}
                    </span>
                </span>
            </div>
        </div>
    );
};
const PlanTransportHint = (props: {
    transportConcept: TransportConcept | undefined;
}) => {
    if (!props.transportConcept) {
        return <></>;
    } else {
        if (props.transportConcept === TransportConcept.FTL) {
            return (
                <div className={'padding-x-20 padding-top-20 text-size-12'}>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.planDispatchProposal.body.ftlHint'}
                        values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
                    />
                </div>
            );
        } else {
            return (
                <div className={'padding-x-20 padding-top-20 text-size-12'}>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.planDispatchProposal.body.ltlHint'}
                        values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
                    />
                </div>
            );
        }
    }
};
export const PlanDispatchProposalSidebarBody = () => {
    const freightForwarder = useAppSelector(getFreightForwarderOfSelectedDispatchProposalItems);
    const transportConcept = useAppSelector(getTransportConceptOfSelectedDispatchProposalItems);
    const selectedDispatchProposalItems = useAppSelector(getSelectedDispatchProposalItems);
    const intl = useIntl();

    if (freightForwarder === undefined || transportConcept === undefined || selectedDispatchProposalItems.length < 1) {
        return <></>;
    }

    return (
        <div data-testid={DISPATCH_PROPOSAL_PLANNING_SIDEBAR_BODY_TEST_ID}>
            <PlanTransportHint transportConcept={transportConcept} />
            <div className={'padding-20 z-index-max'}>
                <div className={'panel panel-default margin-bottom-0'}>
                    <Headline
                        freightForwarder={freightForwarder}
                        transportConcept={transportConcept}
                        dispatchProposalItems={selectedDispatchProposalItems}
                    />
                    <div className={'panel-body'} style={{ padding: 0 }}>
                        <div className={'table-responsive'}>
                            <table className={'table table-head-filled table-layout-fixed table-sticky'}>
                                <colgroup>
                                    <col className={'width-30pct'} />
                                    <col className={'width-15pct'} />
                                    <col className={'width-30pct'} />
                                    <col className={'width-15pct'} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className={'ellipsis-1'}>
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.planDispatchProposal.body.table.header.articleNumber'
                                                }
                                            />
                                        </th>
                                        <th className={'ellipsis-1'}>
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.planDispatchProposal.body.table.header.placeOfDischarge'
                                                }
                                            />
                                        </th>
                                        <th className={'ellipsis-1'}>
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.planDispatchProposal.body.table.header.outerPackaging'
                                                }
                                            />
                                        </th>
                                        <th className={'ellipsis-1'}>
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.planDispatchProposal.body.table.header.quantity'
                                                }
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{selectedDispatchProposalItems.map((it) => renderRow(it))}</tbody>
                            </table>
                        </div>
                    </div>
                    <div
                        className={
                            'panel-footer padding-x-0 display-flex display-grid grid-cols-10 position-sticky align-items-center justify-content-between bottom-0 height-50 bg-white border-width-3'
                        }
                    >
                        <div className='grid-colspan-5 padding-x-15'>
                            <span className={'text-color-gray text-normal'}>
                                <FormattedMessage
                                    id={'webedi.dispatchProposals.planDispatchProposal.footer.weightAndAmount'}
                                />
                                {': '}
                            </span>
                        </div>
                        <div className='text-right grid-colspan-3 padding-x-15'>
                            <div className={'ellipsis-1'}>
                                <span className={'rioglyph rioglyph-weight text-size-18 margin-right-4'} />
                                <span
                                    className={'ellipsis-1 text-medium'}
                                    data-testid={DISPATCH_PROPOSAL_PLANNING_SIDEBAR_SUM_WEIGHT_TEST_ID}
                                >
                                    {formatWeight(
                                        intl.locale,
                                        getSumOfGrossWeightInKgFromDispatchProposalItems(selectedDispatchProposalItems),
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className='grid-colspan-2 padding-x-15'>
                            <div className={'ellipsis-1'}>
                                <span className={'rioglyph rioglyph-parcel text-size-18 margin-right-4'} />
                                <span
                                    className={'ellipsis-1 text-medium'}
                                    data-testid={DISPATCH_PROPOSAL_PLANNING_SIDEBAR_SUM_OUTER_PACKAGING_TEST_ID}
                                >
                                    {getSumOfOuterPackagingFromDispatchProposalItems(selectedDispatchProposalItems)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
