import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import { neverReachedFor } from '../../../../../utils';
import { DispatchProposalProblemLevel } from '../../../domain/dispatchProposal.types';
import { ArticleNumber } from '../../common/ArticleNumber';
import { Tooltip } from '../../common/Tooltip';

export const FormattedArticleNumber = (props: { articleNumber: string }) => {
    return (
        <div>
            <Tooltip text={props.articleNumber} placement={'top'}>
                <div className={'width-150 ellipsis-1'}>
                    <ArticleNumber articleNumber={props.articleNumber} />
                </div>
            </Tooltip>
        </div>
    );
};

export const HorizontalLine = (props: { level: DispatchProposalProblemLevel }) => {
    return <hr className={`border-color-${colorByProblemLevel(props.level)} margin-y-10`} />;
};

export const BannerIcon = (props: { level: DispatchProposalProblemLevel }) => {
    const icon = determineBannerIcon(props.level);
    return (
        <span
            className={`text-color-${colorByProblemLevel(props.level)} text-size-h4 margin-right-10 rioglyph rioglyph ${icon}`}
        />
    );
};

const determineBannerIcon = (level: DispatchProposalProblemLevel): string => {
    switch (level) {
        case DispatchProposalProblemLevel.WARNING:
            return 'rioglyph-exclamation-sign';
        case DispatchProposalProblemLevel.ERROR:
            return 'rioglyph-error-sign';
        case DispatchProposalProblemLevel.INFO:
            return 'rioglyph-info-sign';
        default:
            return neverReachedFor(level);
    }
};

export const colorByProblemLevel = (level: DispatchProposalProblemLevel): BUTTON_STYLE => {
    switch (level) {
        case DispatchProposalProblemLevel.WARNING:
            return 'warning';
        case DispatchProposalProblemLevel.ERROR:
            return 'danger';
        case DispatchProposalProblemLevel.INFO:
            return 'info';
        default:
            return neverReachedFor(level);
    }
};
