import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/lib/es/ApplicationLayoutBodyBanner';
import { FormattedMessage } from 'react-intl';
import { useAnnouncementBanner } from '../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../configuration/featureToggle/utils';
import { isCurrentDateTimeWithinRange, isValidISODateRange } from '../../../../dateUtils';

export const AnnouncementBanner = () => {
    const announcementBannerConfig = getValueOrDefaultWhenLoading(useAnnouncementBanner());

    if (announcementBannerConfig === 'DISABLED' || !isValidISODateRange(announcementBannerConfig)) {
        return null;
    } else {
        const [bannerStartDate, bannerEndDate] = announcementBannerConfig.split('_');
        if (!isCurrentDateTimeWithinRange(bannerStartDate, bannerEndDate)) {
            return null;
        }
        return (
            <div>
                <ApplicationLayoutBodyBanner className={'bg-warning'}>
                    <div>
                        <div className={'text-medium'}>
                            <FormattedMessage id={'webedi.common.announcement.banner.header'} />
                        </div>
                        <span>
                            <FormattedMessage id={'webedi.common.announcement.banner.body'} />
                        </span>
                    </div>
                </ApplicationLayoutBodyBanner>
            </div>
        );
    }
};
