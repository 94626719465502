import { useIntl } from 'react-intl';
import { neverReachedFor } from '../../../../../utils';
import {
    AirTransport,
    Mail,
    MaritimeTransport,
    MeansOfTransport,
    MeansOfTransportMode,
    MultimodalTransport,
    RailTransport,
    RoadTransport,
} from '../../../domain/meansOfTransport.types';
import { TransportMode, TransportModeProperty } from '../TransportMode';

export const MeansOfTransportReadView = (props: {
    meansOfTransport: MeansOfTransport;
}) => {
    const intl = useIntl();
    const mode = props.meansOfTransport?.mode.toLowerCase();
    return (
        <div className={'display-flex-sm flex-row align-items-center width-100pct'}>
            <div className={'width-33pct-sm padding-right-15'}>
                <TransportModeProperty
                    id={'meansOfTransport'}
                    value={intl.formatMessage({ id: `webedi.shipment.meansOfTransport.labels.${mode}` })}
                    readonly={true}
                />
            </div>
            <MeansOfTransportModeProperty meansOfTransport={props.meansOfTransport} />
        </div>
    );
};

export const MeansOfTransportModeProperty = (props: { meansOfTransport: MeansOfTransport }) => {
    const { meansOfTransport } = props;
    const mode = meansOfTransport.mode;
    switch (mode) {
        case MeansOfTransportMode.MARITIME:
            const maritimeTransport = meansOfTransport as MaritimeTransport;
            return (
                <div className={'width-66pct-sm'}>
                    <TransportMode
                        tdtPropertyId={'shipName'}
                        tdtPropertyValue={maritimeTransport.shipName}
                        eqdPropertyId={'containerNumber'}
                        eqdPropertyValue={maritimeTransport.containerNumber}
                        readonly={true}
                    />
                </div>
            );
        case MeansOfTransportMode.RAIL:
            const railTransport = meansOfTransport as RailTransport;
            return (
                <div className={'width-66pct-sm'}>
                    <TransportMode
                        tdtPropertyId={'trainNumber'}
                        tdtPropertyValue={railTransport.trainNumber}
                        eqdPropertyId={'railCarNumber'}
                        eqdPropertyValue={railTransport.railCarNumber}
                        readonly={true}
                    />
                </div>
            );
        case MeansOfTransportMode.ROAD:
            const roadTransport = meansOfTransport as RoadTransport;
            const registrationNumber =
                roadTransport.registrationNumber ?? roadTransport.registrationNumberFromConfirmation;
            return (
                <div className={'width-66pct-sm'}>
                    <TransportMode
                        tdtPropertyId={'registrationNumber'}
                        tdtPropertyValue={registrationNumber}
                        eqdPropertyId={'trailerRegistrationNumber'}
                        eqdPropertyValue={roadTransport.trailerRegistrationNumber}
                        readonly={true}
                    />
                </div>
            );
        case MeansOfTransportMode.AIR:
            const airTransport = meansOfTransport as AirTransport;
            return (
                <div className={'width-66pct-sm'}>
                    <TransportMode
                        tdtPropertyId={'flightNumber'}
                        tdtPropertyValue={airTransport.flightNumber}
                        readonly={true}
                    />
                </div>
            );
        case MeansOfTransportMode.MAIL:
            const mailTransport = meansOfTransport as Mail;
            return (
                <div className={'width-66pct-sm'}>
                    <TransportMode
                        tdtPropertyId={'parcelNumber'}
                        tdtPropertyValue={mailTransport.parcelNumber}
                        readonly={true}
                    />
                </div>
            );
        case MeansOfTransportMode.MULTIMODAL:
            const multimodal = meansOfTransport as MultimodalTransport;
            return (
                <div className={'width-66pct-sm'}>
                    <TransportMode
                        tdtPropertyId={'swapBodyRegistrationNumber'}
                        tdtPropertyValue={multimodal.swapBodyRegistrationNumber}
                        eqdPropertyId={'swapBodyNumber'}
                        eqdPropertyValue={multimodal.swapBodyNumber}
                        readonly={true}
                    />
                </div>
            );
        default:
            return neverReachedFor(mode as never);
    }
};
