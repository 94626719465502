import { FormattedMessage } from 'react-intl';
import { neverReachedFor } from '../../../../../utils';

export enum ShipmentDocumentType {
    TSB = 'TSB',
    GTL = 'GTL',
}

const getLabelKeyOfShipmentDocumentType = (type: ShipmentDocumentType): string => {
    switch (type) {
        case ShipmentDocumentType.GTL:
            return 'webedi.shipmentDocuments.generatedDocuments.gtl';
        case ShipmentDocumentType.TSB:
            return 'webedi.shipmentDocuments.generatedDocuments.tsb';
        default:
            return neverReachedFor(type);
    }
};

export const ShipmentDocumentDownloadBoxTitle = (props: { type: ShipmentDocumentType }) => (
    <div className={'text-bold text-left text-size-16'}>
        <FormattedMessage id={getLabelKeyOfShipmentDocumentType(props.type)} />
    </div>
);

export const ShipmentDocumentDownloadBoxDescription = (props: { hasWarnings: boolean }) => {
    const messageId = props.hasWarnings
        ? 'webedi.shipmentDocuments.generatedDocuments.warning'
        : 'webedi.shipmentDocuments.generatedDocuments.success';

    return (
        <div className={`margin-top-20 text-size-14 text-left text-color-dark`}>
            {props.hasWarnings && (
                <span
                    className={'rioglyph rioglyph-warning-sign text-color-warning margin-right-5 text-size-20'}
                    data-testid={'DOWNLOAD_SHIPMENT_DOCUMENT_LINK_WARNING'}
                />
            )}
            <FormattedMessage id={messageId} />
        </div>
    );
};

export const DownloadIcon = () => (
    <div>
        <div className={'rioglyph rioglyph-download text-size-h1'} />
        <div className={'text-center'}>PDF</div>
    </div>
);
