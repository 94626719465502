import { UserManager } from 'oidc-client-ts';
import { connect } from 'react-redux';
import { getDisplayMessages, getLocale, isUserSessionExpired } from '../../../configuration';
import { useDiscoveryNextGenFeatures } from '../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../configuration/featureToggle/utils';
import { Dispatch, State } from '../../../configuration/setup/store';
import { appSlice } from '../reducers/App.reducers';
import { getInitializationState, getSessionExpiredAcknowledged } from '../selectors/App.selector';
import { App } from './App';
import { AppPropertiesFromDispatch, AppPropertiesFromState } from './types';

type AppContainerProps = {
    userManager: UserManager;
    webSCMPlusFeaturesAreActive: boolean;
};

const mapDispatchToProps = (dispatch: Dispatch): AppPropertiesFromDispatch => {
    return {
        hideSessionDialog: () => dispatch(appSlice.actions.hideSessionExpiredDialog()),
        completeInitialization: () => dispatch(appSlice.actions.completeInitialization()),
    };
};

const mapStateToProps = (state: State, ownProps: AppContainerProps): AppPropertiesFromState => {
    return {
        displayMessages: getDisplayMessages(state),
        showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
        userLocale: getLocale(state),
        initializationState: getInitializationState(state),
        webSCMPlusFeaturesAreActive: ownProps.webSCMPlusFeaturesAreActive,
    };
};

const AppContainerInternal = connect(mapStateToProps, mapDispatchToProps)(App);

export const AppContainer = (props: Pick<AppContainerProps, 'userManager'>) => {
    const webSCMPlusFeaturesAreActive = getValueOrDefaultWhenLoading(useDiscoveryNextGenFeatures());
    return (
        <AppContainerInternal
            userManager={props.userManager}
            webSCMPlusFeaturesAreActive={webSCMPlusFeaturesAreActive}
        />
    );
};
