import { ReactNode, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../../../hooks/useImage';
import { ValidPackagingMaterial } from '../../../../../reducers/packaging/types';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import {
    GroupedHandlingUnits,
    PackagingStep,
    UpdatePackagingConfigurationType,
} from '../../../../../reducers/shipments/packaging.types';
import { getSelectedGroupedHandlingUnits } from '../../../../../selectors/packaging/Packaging.selector';
import { PackagingMaterialFormValues } from '../../../../common/form/BoxFinderInput';
import { FieldErrorType, FormErrors } from '../../../../common/form/types';
import { BoxFinder } from '../../../../packaging/BoxFinder';
import { formIds, formPropertyNamesStep2 } from './OuterPackagingFormConfig';

interface OuterPackagingPackagingMaterialSelectionFormValues {
    packagingMaterial?: PackagingMaterialFormValues;
}

type OuterPackagingPackagingMaterialSelectionFormErrors = FormErrors<
    OuterPackagingPackagingMaterialSelectionFormValues,
    OuterPackagingPackagingMaterialSelectionFormValues
>;

export const validateForm = ({
    packagingMaterial,
}: OuterPackagingPackagingMaterialSelectionFormValues): OuterPackagingPackagingMaterialSelectionFormErrors => {
    const validationResult: OuterPackagingPackagingMaterialSelectionFormErrors = {
        packagingMaterial: {},
    };

    if (packagingMaterial?.boxCode === undefined) {
        validationResult.packagingMaterial!.boxCode = FieldErrorType.REQUIRED;
    }

    if (packagingMaterial) {
        const { tareWeightInKg, ownership, isReusable, stackingFactor } = packagingMaterial;

        if (tareWeightInKg === undefined || String(tareWeightInKg) === '') {
            validationResult.packagingMaterial!.tareWeightInKg = FieldErrorType.REQUIRED;
        }
        if (ownership === undefined) {
            validationResult.packagingMaterial!.ownership = FieldErrorType.REQUIRED;
        }
        if (isReusable === undefined) {
            validationResult.packagingMaterial!.isReusable = FieldErrorType.REQUIRED;
        }
        if (stackingFactor === undefined || String(stackingFactor) === '') {
            validationResult.packagingMaterial!.stackingFactor = FieldErrorType.REQUIRED;
        }
    }

    return validationResult;
};

export const PACKAGING_STEP_PACKAGING_MATERIAL_SELECTION_TEST_ID =
    'PACKAGING_STEP_PACKAGING_MATERIAL_SELECTION_TEST_ID';
export const PACKAGING_MATERIAL_SELECTION_EXPLANATION_TEST_ID = 'PACKAGING_MATERIAL_SELECTION_EXPLANATION_TEST_ID';

export const OuterPackagingPackagingMaterialSelection = () => {
    const dispatch = useAppDispatch();
    const groupedHandlingUnitsToPack: GroupedHandlingUnits[] = useAppSelector(getSelectedGroupedHandlingUnits);
    const packagingMaterialSelectionImage = useImage('packagingMaterialSelection');

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(formIds.formStep2));
    }, [dispatch]);

    return (
        <div data-testid={PACKAGING_STEP_PACKAGING_MATERIAL_SELECTION_TEST_ID}>
            <Form<OuterPackagingPackagingMaterialSelectionFormValues>
                onSubmit={(values: OuterPackagingPackagingMaterialSelectionFormValues) => {
                    dispatch(
                        packagingSlice.actions.addPackagingMaterialForOuterPackaging({
                            type: UpdatePackagingConfigurationType.REGULAR_PACKAGING,
                            packagingMaterial: values.packagingMaterial as ValidPackagingMaterial,
                        }),
                    );
                    dispatch(packagingSlice.actions.setPackagingStep(PackagingStep.CONFIGURATION));
                }}
                validate={validateForm}
                render={({ handleSubmit }) => {
                    return (
                        <div
                            className={
                                'display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct ' +
                                'margin-left-20pct margin-right-20pct min-height-500'
                            }
                        >
                            <div className={'display-flex flex-column'}>
                                <div className={'text-size-18 text-center margin-bottom-25'}>
                                    <FormattedMessage id={'webedi.packaging.innerPackaging.step1.title'} />
                                </div>
                                <div className={'counter color-highlight'}>
                                    <div data-count={2}>
                                        <span data-testid={PACKAGING_MATERIAL_SELECTION_EXPLANATION_TEST_ID}>
                                            <FormattedMessage
                                                id={
                                                    'webedi.packaging.outerPackaging.packagingMaterialSelection.explanation'
                                                }
                                                values={{
                                                    innerPackagingMaterialBoxCodes: groupedHandlingUnitsToPack
                                                        .flatMap((item) => item.type)
                                                        .join(', '),
                                                    b: (chunks: ReactNode) => <b>{chunks}</b>,
                                                }}
                                            />
                                            {':'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    'border-style-solid border-color-light border-width-1 padding-left-5pct padding-right-5pct padding-20'
                                }
                            >
                                <div className={'text-center margin-bottom-20'}>
                                    <img
                                        src={packagingMaterialSelectionImage}
                                        className={'width-70pct'}
                                        alt={'packaging_step_1'}
                                    />
                                </div>
                                <form id={formIds.formStep2} onSubmit={handleSubmit}>
                                    <BoxFinder
                                        id={formIds.packagingMaterial}
                                        name={formPropertyNamesStep2.packagingMaterial}
                                        showLinkToVWMaterialHelp={true}
                                    />
                                </form>
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
};
