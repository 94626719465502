import { Party } from '../../../../reducers/types';

export const PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID = 'PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID';

export const PartyAddressDetails = (party: Party) => (
    <div className={'display-flex'}>
        <span className={'text-size-20 rioglyph rioglyph-factory margin-right-10'} />
        <div className={'flex-1-1 padding-bottom-10'}>
            {party.partyName && (
                <div data-testid={PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID} className={'padding-bottom-10'}>
                    <span className={'text-size-16'}>{party.partyName}</span>
                </div>
            )}
            {party.street && <div data-testid={PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID}>{party.street}</div>}
            <div>
                {party.postalIdentificationCode && (
                    <span className={'padding-right-5'} data-testid={PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID}>
                        {party.postalIdentificationCode}
                    </span>
                )}
                {party.cityName && (
                    <span data-testid={PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID}>{party.cityName}</span>
                )}
            </div>
            {party.countryNameCode && (
                <div data-testid={PARTY_ADDRESS_DETAILS_INFORMATION_TEST_ID}>{party.countryNameCode}</div>
            )}
        </div>
    </div>
);
