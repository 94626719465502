import { ReactNode } from 'react';

type SideMargin = '5' | '10' | '15' | '20' | undefined;

export const renderDetailAttributeTextCard = (
    title: ReactNode,
    content: string | ReactNode | undefined,
    sideMargin?: SideMargin,
) =>
    renderDetailAttributeCard(
        title,
        content ? <span className={'text-size-16 ellipsis-2 padding-right-15pct'}>{content}</span> : undefined,
        sideMargin,
    );

export const renderDetailAttributeCard = (
    title: ReactNode,
    content: ReactNode,
    sideMargin?: SideMargin,
    labelFor?: string,
) => {
    const sideMargins = sideMargin ? `margin-right-${sideMargin} margin-left-${sideMargin}` : '';
    return (
        <div className={`${sideMargins} flex-1-1-0`}>
            <label className={'text-size-12'} htmlFor={labelFor}>
                {title}
            </label>
            <br />
            {content ? content : ' - '}
        </div>
    );
};
