import {
    listOfCalendarWeeksStartingAt,
    listOfDaysStartingAt,
    setComponents,
    toLocalDate,
} from '../../../../../dateUtils';
import { roundOffFloatingPointErrors } from '../../../../../utils';
import {
    RequestCondition,
    ScheduledQuantity,
    isScheduledDate,
    isScheduledDateTime,
} from '../../../reducers/deliverySchedules/types';

export const calculateAmountsForDates = (
    schedulingData: ScheduledQuantity[],
    zeroDemand: boolean,
    onlyOpenAmounts: boolean,
    currentDay: string,
): {
    backOrder: number;
    immediateDemand: number;
    amountBeforeToday: number;
    dailyAmounts: Map<string, number>;
    weeklyAmounts: Map<string, number>;
} => {
    const getStartDate = (scheduledQuantity: ScheduledQuantity) => {
        if (isScheduledDate(scheduledQuantity.scheduledDateTime)) {
            return toLocalDate(scheduledQuantity.scheduledDateTime.scheduledDate);
        }
        if (isScheduledDateTime(scheduledQuantity.scheduledDateTime)) {
            return toLocalDate(scheduledQuantity.scheduledDateTime.scheduledDateTime);
        }
        return toLocalDate(scheduledQuantity.scheduledDateTime.earliestDeliveryDate);
    };
    const getAmount = (scheduledQuantity: ScheduledQuantity) =>
        onlyOpenAmounts ? scheduledQuantity.outstandingQuantity : scheduledQuantity.quantity;
    const roundOffValuesInMap = (map: Map<string, number>) =>
        new Map<string, number>(Array.from(map).map((entry) => [entry[0], roundOffFloatingPointErrors(entry[1])]));

    const dailyAmounts = new Map<string, number>(listOfDaysStartingAt(currentDay, 31).map((date) => [date, 0]));
    const weeklyAmounts = new Map<string, number>(
        listOfCalendarWeeksStartingAt(currentDay, 52).map((date) => [date, 0]),
    );
    let amountBeforeToday = 0;
    let immediateDemand = 0;
    let backOrder = 0;

    if (!zeroDemand) {
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
        schedulingData.forEach((scheduledQuantity) => {
            const startDate = getStartDate(scheduledQuantity);
            const startOfWeek = setComponents(startDate, { weekday: 1 });
            const amount = getAmount(scheduledQuantity);

            if (scheduledQuantity.requestCondition === RequestCondition.IMMEDIATE) {
                immediateDemand += amount;
            }
            if (scheduledQuantity.requestCondition === RequestCondition.BACKORDER) {
                backOrder += amount;
            }
            if (startDate < currentDay) {
                amountBeforeToday += amount;
            }
            if (dailyAmounts.has(startDate)) {
                dailyAmounts.set(startDate, (dailyAmounts.get(startDate) ?? 0) + amount);
            }
            if (weeklyAmounts.has(startOfWeek)) {
                weeklyAmounts.set(startOfWeek, (weeklyAmounts.get(startOfWeek) ?? 0) + amount);
            }
        });
    }

    return {
        amountBeforeToday: roundOffFloatingPointErrors(amountBeforeToday),
        backOrder: roundOffFloatingPointErrors(backOrder),
        immediateDemand: roundOffFloatingPointErrors(immediateDemand),
        dailyAmounts: roundOffValuesInMap(dailyAmounts),
        weeklyAmounts: roundOffValuesInMap(weeklyAmounts),
    };
};
