import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import _ from 'lodash';
import { NavigateFunction } from 'react-router';
import { Dispatch, State } from '../../../../configuration/setup/store';
import { ProblemError } from '../../api/apiUtils';
import { fetchEdiFileDownloadUrl, fetchEdiFileSummaries } from '../../api/file/ediFilesCalls';
import { errorCodeHasTranslation } from '../../api/rtkGlobalErrorHandler';
import {
    deleteShipment,
    fetchShipmentsFilterValues,
    getArticleSuggestions,
    getNextDeliveryNoteNumberData,
    getShipment,
    getShipments,
    patchShipment,
    patchShipmentExported,
    postShipment,
} from '../../api/shipment/shipmentCalls';
import { Routing } from '../../components/routing/Routes';
import { ReferenceType } from '../../domain/files.types';
import { TransportOrderShipment } from '../../domain/transportOrder.types';
import { shipmentDocumentsSlice } from '../../reducers/shipmentDocuments/ShipmentDocuments.reducer';
import { shipmentsSlice } from '../../reducers/shipments/Shipments.reducer';
import {
    ArticleSuggestion,
    ArticleSuggestionForDeliveryNote,
    DeliveryNoteNumberData,
    PlaceOfDischarge,
    SavingShipmentStatus,
    Shipment,
    ShipmentCreationLoadItem,
    ShipmentCreationRequest,
    ShipmentUpdateRequest,
    ShipmentsQuery,
    ShipmentsQueryFor,
    ShipmentsQueryParams,
    ShipmentsQueryResult,
} from '../../reducers/shipments/types';
import { ShipmentSuggestion } from '../../reducers/suggestions/types';
import { FilterValues } from '../../reducers/types';
import { uiSlice } from '../../reducers/ui/UI.reducer';
import {
    getPackagingConfigForShipment,
    getPackagingForSelectedShipment,
} from '../../selectors/packaging/Packaging.selector';
import { getSelectedShipmentId, getShipmentsQuery } from '../../selectors/shipments/Shipments.selector';
import { nonVanishingFormattedErrorNotification, notifySuccess } from '../NotificationUtils';
import { ensureValueExists } from '../ensureValueExists';
import { storeUrlAsFileBlob } from '../files/Files.actions';
import { handleError } from '../ui/UI.actions';
import { getAsnUrlForSelectedShipment } from './Asn.actions';

export const createShipmentFromSuggestion = (
    dunsNumber: string,
    shipmentSuggestion: ShipmentSuggestion,
    navigate: NavigateFunction,
) => {
    const shipmentCreationRequest: ShipmentCreationRequest = {
        dunsNumberOwner: dunsNumber,
        ...shipmentSuggestion,
    };
    return createShipmentFromRequest(shipmentCreationRequest, navigate);
};

export const createShipment = (
    navigate: NavigateFunction,
    dunsNumberOwner: string,
    placeOfDischarge: PlaceOfDischarge,
    load: ShipmentCreationLoadItem[],
    requestedDeliveryDate?: string,
    estimatedArrivalDate?: string,
) => {
    const shipmentCreationRequest: ShipmentCreationRequest = {
        requestedDeliveryDate,
        estimatedArrivalDate,
        dunsNumberOwner,
        placeOfDischarge,
        load,
    };
    return createShipmentFromRequest(shipmentCreationRequest, navigate);
};

export const getRouteForShipment = (
    dunsNumber: string | undefined,
    shipment: Shipment | TransportOrderShipment,
): string => {
    if (dunsNumber === undefined) {
        return `${Routing.dunsSelection}`;
    }
    const exported = 'exported' in shipment ? shipment.exported : shipment.exportedAt !== undefined;
    return `${Routing.webScm}/${dunsNumber}${exported ? Routing.exportedShipments : Routing.shipments}/${shipment.id}`;
};

const createShipmentFromRequest =
    (shipmentCreationRequest: ShipmentCreationRequest, navigate: NavigateFunction) => async (dispatch: Dispatch) => {
        return postShipment(shipmentCreationRequest)
            .then((shipment: Shipment) => {
                dispatch(getShipmentsAction(shipmentCreationRequest.dunsNumberOwner, false));
                return shipment;
            })
            .then((shipment: Shipment) => {
                dispatch(updateSelectedShipmentIdAction(shipment.id));
                navigate(getRouteForShipment(shipmentCreationRequest.dunsNumberOwner, shipment));
            })
            .catch((error: Error) => dispatch(handleError(error)));
    };

export const setSelectedShipment =
    (dunsNumber: string, shipment: Shipment, navigate: NavigateFunction) => async (dispatch: Dispatch) => {
        dispatch(updateSelectedShipmentIdAction(shipment.id));
        navigate(getRouteForShipment(dunsNumber, shipment));
    };

export const exportShipment =
    (dunsNumber: string, shipment: Shipment, navigate: NavigateFunction) => async (dispatch: Dispatch) => {
        return patchShipmentExported(dunsNumber, shipment.id)
            .then(() => {
                notifySuccess('webedi.label.success.export');
            })
            .then(() => {
                dispatch(uiSlice.actions.clearError());
                dispatch(getShipmentsAction(dunsNumber, false));
            })
            .catch((error) => {
                dispatch(setSelectedShipment(dunsNumber, shipment, navigate));
                dispatch(handleError(error));
            });
    };

export const deleteShipmentAndRefresh =
    (dunsNumber: string, shipmentId: string, queryParams: ShipmentsQueryParams) => async (dispatch: Dispatch) => {
        return deleteShipment(dunsNumber, shipmentId)
            .then(() => notifySuccess('webedi.label.success.delete'))
            .then(() => dispatch(getShipmentsAction(dunsNumber, false, queryParams)))
            .catch((error: Error) => {
                if (error instanceof ProblemError) {
                    const translationKey = `webedi.error.${error.errorCode}`;
                    nonVanishingFormattedErrorNotification({
                        messageId:
                            error.errorCode && errorCodeHasTranslation(translationKey)
                                ? translationKey
                                : 'webedi.error.unknown',
                        errorCode: error.errorCode,
                    });
                } else {
                    Notification.error(error.message);
                }
            });
    };

export const getDownloadInformationForSelectedShipment =
    (dunsNumber: string) => async (_dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const shipmentId = getSelectedShipmentId(state);
        if (!shipmentId) {
            throw new Error('Shipment must be selected');
        }

        const ediFileSummaries = await fetchEdiFileSummaries(dunsNumber, {
            referenceId: shipmentId,
            referenceType: ReferenceType.SHIPMENT,
        });
        if (ediFileSummaries.length !== 1) {
            throw new Error(`No or too many EDI File(s) found for shipment with id: ${shipmentId}`);
        }
        const ediFileSummary = ediFileSummaries[0];
        const presignedUrl = await fetchEdiFileDownloadUrl(dunsNumber, ediFileSummary.id);

        return {
            url: presignedUrl,
            filename: ediFileSummary.filename,
        };
    };

export const downloadEdiFile = (dunsNumber: string) => (dispatch: Dispatch, getState: () => State) => {
    const selectedShipmentId = ensureValueExists(getSelectedShipmentId, getState(), 'Selected Shipment');
    dispatch(
        shipmentDocumentsSlice.actions.setGeneratingShipmentDocuments({ state: true, shipmentId: selectedShipmentId }),
    );
    return dispatch(getAsnUrlForSelectedShipment(dunsNumber))
        .then(({ url, filename }) => dispatch(storeUrlAsFileBlob(url, filename)))
        .catch((error) => {
            if (error instanceof ProblemError) {
                dispatch(handleError(error));
            } else {
                nonVanishingFormattedErrorNotification({
                    messageId: 'webedi.shipmentDocuments.generatedDocuments.error.asnUrlFailed',
                });
            }
        })
        .finally(() => {
            dispatch(
                shipmentDocumentsSlice.actions.setGeneratingShipmentDocuments({
                    state: false,
                    shipmentId: selectedShipmentId,
                }),
            );
        });
};

export const updateShipment =
    (shipment: Shipment, dunsNumber: string, shipmentId: string) =>
    async (dispatch: Dispatch, getState: () => State) => {
        dispatch(shipmentsSlice.actions.setSavingStatus(SavingShipmentStatus.SAVING));
        const state = getState();
        const selectedShipmentId = getSelectedShipmentId(state);

        if (selectedShipmentId !== shipmentId) {
            return Promise.reject(
                `Mismatch in shipment id. Packaging data is for shipment with id=${selectedShipmentId}, ` +
                    `while the form data is for shipment with id=${shipmentId}`,
            );
        }

        const packaging = getPackagingForSelectedShipment(state) || [];
        const shipmentUpdateRequest = mapShipmentToShipmentUpdateRequest({ ...shipment, packaging });
        const packagingOuterDimensions =
            getPackagingConfigForShipment(state, selectedShipmentId)?.packagingOuterDimensions ?? {};
        return patchShipment(shipmentUpdateRequest, dunsNumber, shipmentId, packagingOuterDimensions)
            .then(() => {
                notifySuccess('webedi.label.success.save');
                dispatch(uiSlice.actions.clearError());
                dispatch(getShipmentsAction(dunsNumber, false)).then(() =>
                    dispatch(shipmentsSlice.actions.setSavingStatus(SavingShipmentStatus.DEFAULT)),
                );
            })
            .catch((error) => {
                dispatch(shipmentsSlice.actions.setSavingStatus(SavingShipmentStatus.ERROR));
                dispatch(handleError(error));
            });
    };

const toSanitizedQuery = (query: ShipmentsQuery) => {
    // remove leading zeros from search string, if a shipmentNumber, deliveryNotNumber or labelNumber is searched for
    const sanitizedQ =
        query.params.queryFor === ShipmentsQueryFor.SHIPMENT_NUMBER ||
        query.params.queryFor === ShipmentsQueryFor.DELIVERY_NOTE_NUMBER ||
        query.params.queryFor === ShipmentsQueryFor.LABEL_NUMBER
            ? query.params.q?.trim().replace(/^0+/, '')
            : query.params.q;

    return {
        ...query,
        params: {
            ...query.params,
            q: sanitizedQ ? sanitizedQ : undefined,
            queryFor: sanitizedQ ? query.params.queryFor : undefined,
        },
    };
};

export const getShipmentsAction =
    (dunsNumber: string, exported: boolean, queryChanges?: Partial<ShipmentsQueryParams>) =>
    (dispatch: Dispatch, getState: () => State) => {
        dispatch(shipmentsSlice.actions.setIsLoading());

        const currentQuery = getShipmentsQuery(getState(), exported);
        const query = {
            ...currentQuery,
            dunsNumber,
            params: { ...currentQuery.params, ...queryChanges },
        };

        if (exported) {
            dispatch(shipmentsSlice.actions.updateExportedQuery(query));
        } else {
            dispatch(shipmentsSlice.actions.updateNotExportedQuery(query));
        }

        const sanitizedQuery = toSanitizedQuery(query);

        return getShipments(exported, sanitizedQuery)
            .then((result: ShipmentsQueryResult) => {
                const queryAfterFetch = getShipmentsQuery(getState(), exported);
                if (!_.isEqual(queryAfterFetch, query)) {
                    return undefined;
                }
                return dispatch(
                    shipmentsSlice.actions.updateShipments({
                        shipments: result.shipments,
                        totalCountOfMatchedShipments: result.totalCountOfMatchedShipments,
                    }),
                );
            })
            .catch((error: Error) => {
                dispatch(shipmentsSlice.actions.setFailed());
                dispatch(handleError(error));
            });
    };

export const fetchAndStoreShipmentsFilterValues =
    (dunsNumber: string, exported: boolean) => async (dispatch: Dispatch) => {
        return fetchShipmentsFilterValues(dunsNumber, exported)
            .then((filterValues: FilterValues) => {
                if (exported) {
                    dispatch(shipmentsSlice.actions.setExportedFilterValues(filterValues));
                } else {
                    dispatch(shipmentsSlice.actions.setNotExportedFilterValues(filterValues));
                }
            })
            .catch((error: Error) => {
                Notification.error(error.message);
            });
    };

export const getShipmentAction = (dunsNumber: string, shipmentId: string) => async (dispatch: Dispatch) => {
    dispatch(shipmentsSlice.actions.setIsLoading());
    return getShipment(dunsNumber, shipmentId)
        .then((shipment: Shipment) => {
            dispatch(
                shipmentsSlice.actions.updateShipments({
                    shipments: [shipment],
                    totalCountOfMatchedShipments: 1,
                }),
            );
            return shipment;
        })
        .then((shipment: Shipment) => {
            dispatch(updateSelectedShipmentIdAction(shipment.id));
        })
        .catch((error: Error) => {
            dispatch(shipmentsSlice.actions.setFailed());
            dispatch(handleError(error));
        });
};

export const getArticleSuggestionsAction = (dunsNumber: string, shipmentId: string) => async (dispatch: Dispatch) => {
    return getArticleSuggestions(dunsNumber, shipmentId)
        .then((articleSuggestions: ArticleSuggestion[]) => {
            dispatch(shipmentsSlice.actions.updateArticleSuggestions(articleSuggestions));
        })
        .catch((error) => dispatch(handleError(error)));
};

export const getNextAvailableDeliveryNoteNumberAction = (dunsNumber: string) => async (dispatch: Dispatch) => {
    return getNextDeliveryNoteNumberData(dunsNumber)
        .then((deliveryNoteNumberData: DeliveryNoteNumberData) => {
            dispatch(
                shipmentsSlice.actions.updateNextAvailableDeliveryNoteNumber(deliveryNoteNumberData.deliveryNoteNumber),
            );
        })
        .catch((error) => dispatch(handleError(error)));
};

const updateSelectedShipmentIdAction = (shipmentId: string) => async (dispatch: Dispatch) => {
    dispatch(uiSlice.actions.clearError());
    dispatch(shipmentsSlice.actions.updateSelectedShipmentId(shipmentId));
};

export const closeSidebar = () => async (dispatch: Dispatch) =>
    dispatch(shipmentsSlice.actions.closeArticleSuggestionSidebar());

export const openSidebar = (deliveryNoteNumber: number) => async (dispatch: Dispatch) =>
    dispatch(shipmentsSlice.actions.openArticleSuggestionSidebarForDeliveryNote(deliveryNoteNumber));

export const addArticleSuggestionForDeliveryNote =
    (articleSuggestion: ArticleSuggestionForDeliveryNote) => async (dispatch: Dispatch) =>
        dispatch(shipmentsSlice.actions.addArticleSuggestionForDeliveryNote(articleSuggestion));

export const resetArticleSuggestionForDeliveryNote = () => async (dispatch: Dispatch) =>
    dispatch(shipmentsSlice.actions.resetArticleSuggestionForDeliveryNote());

export const openAdditionalPropertiesDialog = (path: string) => async (dispatch: Dispatch) =>
    dispatch(shipmentsSlice.actions.showAdditionalPropertiesDialogForLoadItem(path));

export const closeAdditionalPropertiesDialog = () => async (dispatch: Dispatch) =>
    dispatch(shipmentsSlice.actions.hideAdditionalPropertiesDialog());

export const mapShipmentToShipmentUpdateRequest = (shipment: Shipment): ShipmentUpdateRequest => {
    return {
        despatchDate: shipment.despatchDate,
        load: shipment.load,
        packaging: shipment.packaging,
        termsOfDelivery: shipment.termsOfDelivery,
        managedFreightForwarder:
            shipment.freightForwarder?.type === 'MANAGED_FREIGHT_FORWARDER'
                ? { id: shipment.freightForwarder.id }
                : undefined,
        customs: shipment.customs,
        meansOfTransport: shipment.meansOfTransport,
        shipmentNumber: shipment.shipmentNumber,
        transportOrderNumber: shipment.transportOrderNumber,
        estimatedArrivalDate: shipment.estimatedArrivalDate,
        groupOfIdenticalPackagingHierarchies: shipment.groupOfIdenticalPackagingHierarchies,
    };
};
