import {
    EVENT_USER_LANGUAGE_CHANGED,
    EVENT_USER_LOGGED_OUT,
    EVENT_USER_PROFILE_CHANGED,
} from '@rio-cloud/rio-user-menu-component';
import { config } from '../config';
import { updateDisplayMessages, userTokenLocaleChanged } from './lang/actions';
import { FALLBACK_LOCALE, extractLanguage } from './lang/lang';
import langReducer from './lang/reducer';
import { getDisplayMessages, getLocale } from './lang/selectors';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/actions';
import { configureMockUserManager, configureUserManager } from './login/login';
import handleLoginRedirect from './login/redirect';
import loginReducer from './login/reducer';
import { getEmail, getUserAccount, isUserSessionExpired } from './login/selectors';
import configReducer from './setup/configReducer';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { trace } from './setup/trace';
import { mapExternalTokenToIdToken } from './tokenHandling/IdToken.ts';
import { accessToken } from './tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from './tokenHandling/actions';
import tokenHandlingReducer from './tokenHandling/reducer';
import { getAccessToken, getIdToken } from './tokenHandling/selectors';

const main = (userManagerInput) => {
    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    let lastDisplayLocale = getLocale(store.getState());
    store.subscribe(() => {
        const nextDisplayLocale = getLocale(store.getState());
        const lang = extractLanguage(nextDisplayLocale);
        const html = document.querySelector('html');

        if (html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }

        if (lastDisplayLocale !== nextDisplayLocale) {
            lastDisplayLocale = nextDisplayLocale;
            store.dispatch(updateDisplayMessages());
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(mapExternalTokenToIdToken(result.idToken)));
            store.dispatch(userProfileObtained(result.profile));
            store.dispatch(userTokenLocaleChanged(result.locale));

            store.dispatch(userSessionRenewed());
        },
    };

    const isAllowedToMockAuth = !import.meta.env.PROD;
    const testAccessToken = sessionStorage?.getItem('test_access_token'); // enables mocking of authentication in production
    const testIdToken = sessionStorage?.getItem('test_id_token'); // enables mocking of authentication in production
    const userManager =
        (isAllowedToMockAuth && config.login.mockAuthorization) || testAccessToken
            ? configureMockUserManager(oauthConfig, { accessToken: testAccessToken, idToken: testIdToken })
            : configureUserManager(oauthConfig, userManagerInput);

    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, userManager.signinSilent.bind(userManager));
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, userManager.signinSilent.bind(userManager));
    document.addEventListener(EVENT_USER_LOGGED_OUT, userManager.signoutRedirect.bind(userManager));

    try {
        userManager.clearStaleState().catch((error) => reportErrorToSentry(error));
    } catch (error) {
        reportErrorToSentry(error);
    }
    return userManager;
};

export {
    main,
    configReducer,
    getAccessToken,
    getIdToken,
    getDisplayMessages,
    getLocale,
    getUserAccount,
    getEmail,
    handleLoginRedirect,
    isUserSessionExpired,
    langReducer,
    loginReducer,
    store,
    tokenHandlingReducer,
    FALLBACK_LOCALE,
};
