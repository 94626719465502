import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../configuration/tokenHandling/selectors';
import { masterDataSlice } from '../../reducers/masterData/MasterData.reducer';
import { getSelectedManagedFreightForwarderId } from '../../selectors/masterData/MasterData.selector';
import { Tooltip } from '../common/Tooltip';
import { ManagedFreightForwarderForm } from './ManagedFreightForwarderForm';
import { ManagedFreightForwarderTable } from './ManagedFreightForwarderTable';

export const MANAGED_FREIGHT_FORWARDER_CREATE_TEST_ID = 'MANAGED_FREIGHT_FORWARDER_CREATE_TEST_ID';

export const ManagedFreightForwarderView = () => {
    const dispatch = useAppDispatch();
    const [displayLocationForm, setDisplayLocationForm] = useState(false);
    const selectedManagedFreightForwarderId = useAppSelector(getSelectedManagedFreightForwarderId);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);

    const hideDialog = () => {
        setDisplayLocationForm(false);
        dispatch(masterDataSlice.actions.setSelectedManagedFreightForwarder(undefined));
    };

    const AddManagedFreightForwarderButton = () => {
        const content = (
            <button
                data-testid={MANAGED_FREIGHT_FORWARDER_CREATE_TEST_ID}
                className={'btn btn-default'}
                onClick={() => setDisplayLocationForm(true)}
                disabled={isReadOnly}
            >
                <span className='rioglyph rioglyph-plus-light' />
                <FormattedMessage id={'webedi.carriers.button.create'} />
                {isReadOnly && (
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                        }
                    />
                )}
            </button>
        );

        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'right'}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    };

    return (
        <>
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div className={'table-toolbar-group-left'}>
                        <div className={'table-toolbar-column'}>
                            <AddManagedFreightForwarderButton />
                        </div>
                    </div>
                </div>
            </div>
            <ManagedFreightForwarderTable />
            <ManagedFreightForwarderForm
                show={displayLocationForm || selectedManagedFreightForwarderId !== undefined}
                hideDialog={hideDialog}
            />
        </>
    );
};
