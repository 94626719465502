import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';
import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { roundOffFloatingPointErrors } from '../../../../../../../utils';
import { PackagingMaterialRequiredFields } from '../../../../../reducers/packaging/types';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import {
    HandlingUnitGroup,
    PackagingStep,
    UpdateInnerPackagingConfigurationStepConfiguration,
    UpdatePackagingConfigurationType,
} from '../../../../../reducers/shipments/packaging.types';
import {
    getArticleToBePackaged,
    getPackagingMaterialForPackagingConfiguration,
} from '../../../../../selectors/packaging/Packaging.selector';
import { getSelectedShipmentId } from '../../../../../selectors/shipments/Shipments.selector';
import { HandlingUnitCategory } from '../../../../common/PackagingCommon';
import { FieldErrorType, FormErrors } from '../../../../common/form/types';
import { UnpackagedArticle } from '../../packagingEditView/types';
import { SplitView } from '../SplitView';
import { INNER_PACKAGING_TEST_IDS } from './InnerPackaging';
import { InnerPackagingArticleCreation } from './InnerPackagingArticleCreation';
import {
    InnerPackagingConfigurationFormValues,
    ValidatedFormValuesConfigurationStep,
    formIds,
    formPropertyNamesConfigurationStep,
} from './InnerPackagingFormConfig';
import { InnerPackagingPackagedArticles } from './InnerPackagingPackagedArticles';
import { UnpackagedArticles } from './UnpackagedArticles';

export enum InnerPackingFormErrorType {
    CONTAINS_EMPTY_HANDLING_UNITS = 'webedi.packaging.form.error.containsEmptyHandlingUnits',
}

type InnerPackagingFormErrors = FormErrors<InnerPackagingConfigurationFormValues, InnerPackingFormErrorType>;

const calculator = createDecorator(
    {
        field: [
            formPropertyNamesConfigurationStep.quantityValuePerHandlingUnit,
            formPropertyNamesConfigurationStep.calculateNumberOfHandlingUnits,
        ],
        updates: {
            [formPropertyNamesConfigurationStep.numberOfHandlingUnits]: (_, allValues) => {
                const formValues = allValues as InnerPackagingConfigurationFormValues | undefined;
                if (!formValues?.calculateNumberOfHandlingUnits || !formValues?.quantityValuePerHandlingUnit) {
                    return formValues?.numberOfHandlingUnits;
                } else if (formValues.quantityValuePerHandlingUnit < 0) {
                    return undefined;
                } else {
                    return Math.ceil(formValues.numberOfArticlesToPack / formValues?.quantityValuePerHandlingUnit);
                }
            },
        },
    },
    {
        field: [
            formPropertyNamesConfigurationStep.quantityValuePerHandlingUnit,
            formPropertyNamesConfigurationStep.numberOfHandlingUnits,
        ],
        updates: {
            [formPropertyNamesConfigurationStep.numberOfPackagedArticles]: (_, allValues) => {
                const formValues = allValues as InnerPackagingConfigurationFormValues | undefined;
                if (
                    formValues?.quantityValuePerHandlingUnit &&
                    formValues?.quantityValuePerHandlingUnit >= 0 &&
                    formValues?.numberOfHandlingUnits &&
                    formValues?.numberOfHandlingUnits >= 0
                ) {
                    return Math.min(
                        formValues.numberOfArticlesToPack,
                        roundOffFloatingPointErrors(
                            formValues.quantityValuePerHandlingUnit * formValues.numberOfHandlingUnits,
                        ),
                    );
                }
                return 0;
            },
        },
    },
) as Decorator<InnerPackagingConfigurationFormValues, Partial<InnerPackagingConfigurationFormValues>>;

export const validateForm = ({
    quantityValuePerHandlingUnit,
    numberOfHandlingUnits,
    numberOfArticlesToPack,
}: InnerPackagingConfigurationFormValues): InnerPackagingFormErrors => {
    const validationResult: InnerPackagingFormErrors = {};

    if (quantityValuePerHandlingUnit === undefined || quantityValuePerHandlingUnit <= 0) {
        validationResult.quantityValuePerHandlingUnit = FieldErrorType.NON_POSITIVE_NUMBER;
    }
    if (numberOfHandlingUnits === undefined || numberOfHandlingUnits <= 0) {
        validationResult.numberOfHandlingUnits = FieldErrorType.NON_POSITIVE_NUMBER;
    }

    if (
        numberOfHandlingUnits !== undefined &&
        numberOfHandlingUnits > 0 &&
        quantityValuePerHandlingUnit !== undefined &&
        quantityValuePerHandlingUnit > 0
    ) {
        const excessPackagingCapacity = roundOffFloatingPointErrors(
            numberOfHandlingUnits * quantityValuePerHandlingUnit - numberOfArticlesToPack,
        );
        if (excessPackagingCapacity >= quantityValuePerHandlingUnit) {
            validationResult.numberOfHandlingUnits = InnerPackingFormErrorType.CONTAINS_EMPTY_HANDLING_UNITS;
        }
    }

    return validationResult;
};

export const mapInnerPackagingConfigurationStepFormValuesToHandlingUnitGroups = (
    packagingMaterial: PackagingMaterialRequiredFields,
    values: ValidatedFormValuesConfigurationStep,
    loadItemId: string,
): HandlingUnitGroup[] => {
    const handlingUnitGroups = [];
    const { quantityValuePerHandlingUnit, numberOfArticlesToPack, numberOfHandlingUnits } = values;
    const { tareWeightInKg, ownership, isReusable, stackingFactor, boxCode } = packagingMaterial!;

    if (numberOfHandlingUnits < 1) {
        return [];
    }

    // team decided to create for each handlingUnit a dedicated HandlingUnitGroup with quantity 1 instead of
    // grouping "identical handlingUnits" in one HandlingUnitGroup in the frontend
    const numberOfFullHandlingUnits = Math.max(numberOfHandlingUnits - 1, 0);
    const fillHandlingUnitGroup = (quantity: number): HandlingUnitGroup => ({
        quantity: 1,
        handlingUnit: {
            id: uuid(),
            type: boxCode,
            description: packagingMaterial.name,
            category: HandlingUnitCategory.PARTS_CONTAINER,
            labelNumber: 0, // unused, will be overridden by sequence generator when applying template
            contents: [
                {
                    loadItemId,
                    quantity,
                },
            ],
            auxiliaryPackaging: [],
            isReusable,
            ownership,
            stackingFactor,
            tareWeightInKg,
        },
    });
    for (let i = 0; i < numberOfFullHandlingUnits; i++) {
        handlingUnitGroups.push(fillHandlingUnitGroup(quantityValuePerHandlingUnit));
    }
    const remainingArticlesToPackageForLastHandlingUnit =
        numberOfArticlesToPack - numberOfFullHandlingUnits * quantityValuePerHandlingUnit;
    handlingUnitGroups.push(
        fillHandlingUnitGroup(
            roundOffFloatingPointErrors(
                Math.min(remainingArticlesToPackageForLastHandlingUnit, quantityValuePerHandlingUnit),
            ),
        ),
    );

    return handlingUnitGroups;
};

export const InnerPackagingConfiguration = () => {
    const loadItemToPackage = useAppSelector(getArticleToBePackaged);
    const dispatch = useAppDispatch();
    const selectedShipmentId = useAppSelector(getSelectedShipmentId);
    const packagingMaterialToBePackaged = useAppSelector(getPackagingMaterialForPackagingConfiguration);

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(formIds.packagingConfiguration));
    }, [dispatch]);

    if (!loadItemToPackage || !packagingMaterialToBePackaged) {
        return null;
    }

    const {
        articleNumberBuyer: articleNumber,
        loadItemId: idOfLoadItemToPackage,
        unpackagedQuantityValue: quantity,
    }: UnpackagedArticle = loadItemToPackage;

    const initialValues: InnerPackagingConfigurationFormValues = {
        quantityValuePerHandlingUnit: undefined,
        calculateNumberOfHandlingUnits: true,
        numberOfHandlingUnits: undefined,
        numberOfPackagedArticles: 0,
        numberOfArticlesToPack: quantity,
    };

    const onSubmitHandler = (values: InnerPackagingConfigurationFormValues) => {
        if (selectedShipmentId === undefined) {
            throw new Error('shipment must be selected before submitting form values. This should never happen');
        }
        const articleConfigurationToUpdate: UpdateInnerPackagingConfigurationStepConfiguration = {
            handlingUnitGroups: mapInnerPackagingConfigurationStepFormValuesToHandlingUnitGroups(
                packagingMaterialToBePackaged,
                values as ValidatedFormValuesConfigurationStep,
                idOfLoadItemToPackage,
            ),
            type: UpdatePackagingConfigurationType.INNER_PACKAGING,
        };

        dispatch(packagingSlice.actions.addPackagedArticlesConfiguration(articleConfigurationToUpdate));
        dispatch(packagingSlice.actions.setPackagingStep(PackagingStep.AUXILIARY_CONFIGURATION));
    };

    return (
        <Form<InnerPackagingConfigurationFormValues>
            onSubmit={onSubmitHandler}
            initialValues={initialValues}
            decorators={[calculator]}
            validate={validateForm}
            render={({ handleSubmit, values }) => {
                return (
                    <SplitView
                        left={{
                            content: (
                                <UnpackagedArticles
                                    numberOfArticlesToPack={values.numberOfArticlesToPack}
                                    measurementUnitCode={loadItemToPackage?.measurementUnitCode}
                                    numberOfPackagedArticles={values.numberOfPackagedArticles}
                                />
                            ),
                            alignItemsCenter: true,
                        }}
                        main={{
                            content: (
                                <InnerPackagingArticleCreation
                                    articleNumber={articleNumber}
                                    handleSubmit={handleSubmit}
                                    formId={formIds.packagingConfiguration}
                                    packagingMaterial={packagingMaterialToBePackaged}
                                    measurementUnitCode={loadItemToPackage?.measurementUnitCode}
                                    values={{
                                        calculateNumberOfHandlingUnits: values.calculateNumberOfHandlingUnits,
                                        numberOfHandlingUnits: values.numberOfHandlingUnits,
                                    }}
                                />
                            ),
                            alignItemsCenter: true,
                            renderAsColumn: true,
                        }}
                        right={{
                            content: (
                                <InnerPackagingPackagedArticles
                                    numberOfPackagedArticles={values.numberOfPackagedArticles}
                                    measurementUnitCode={loadItemToPackage?.measurementUnitCode}
                                    packagingMaterial={packagingMaterialToBePackaged}
                                />
                            ),
                            alignItemsCenter: true,
                        }}
                        dataTestId={INNER_PACKAGING_TEST_IDS.configuration}
                    />
                );
            }}
        />
    );
};
