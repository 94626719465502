import { config } from '../../../../config';
import { RequestCondition } from '../../reducers/deliverySchedules/types';
import {
    ShipmentSuggestionResponse,
    ShipmentSuggestionsQuery,
    ShipmentSuggestionsQueryParameters,
} from '../../reducers/suggestions/types';
import { FilterValues } from '../../reducers/types';
import { decodeJson, jsonOrReject } from '../apiUtils';
import { FilterValuesCodec } from '../common.types';
import { getRequest } from '../requests';
import { buildQueryParametersSuffix } from '../shared/sharedMappers';
import { ShipmentSuggestionsCodec } from './shipmentSuggestion.types';
import { mapShipmentSuggestions } from './shipmentSuggestionMapper';

export const fetchShipmentSuggestions = (query: ShipmentSuggestionsQuery): Promise<ShipmentSuggestionResponse> => {
    if (!query.dunsNumber) {
        return Promise.reject('DUNS number must be set');
    }
    const enhancedQuery: ShipmentSuggestionsQueryParameters = enhanceQuery(query);
    const queryParameters = buildQueryParametersSuffix({ ...enhancedQuery });
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${query.dunsNumber}/shipment-suggestions${queryParameters}`;

    return fetch(requestUrl, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ShipmentSuggestionsCodec))
        .then(mapShipmentSuggestions);
};

const enhanceQuery = (query: ShipmentSuggestionsQuery) => {
    if (query.params.onlyImmediateDemandAndBackorder) {
        return {
            ...query.params,
            onlyImmediateDemandAndBackorder: undefined,
            requestConditions: [RequestCondition.BACKORDER, RequestCondition.IMMEDIATE],
        };
    } else {
        return query.params;
    }
};

export const fetchShipmentSuggestionsFilterValues = (dunsNumber: string): Promise<FilterValues> => {
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipment-suggestions-filter-values`;
    return fetch(requestUrl, getRequest()).then(jsonOrReject).then(decodeJson(FilterValuesCodec));
};
