import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { applyUpdatePackagingConfiguration } from '../../../../../actions/shipments/Packaging.actions';
import {
    AuxiliaryHandlingUnitGroup,
    AuxiliaryPackagingPayload,
} from '../../../../../reducers/auxiliaryPackaging.types';
import { PackagingMaterialState } from '../../../../../reducers/packaging/types';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import {
    getLayerStabilizationInformation,
    getNewPackagingFromWizardState,
} from '../../../../../selectors/packaging/Packaging.selector';
import { getAllPackagingMaterials } from '../../../../../selectors/packaging/PackagingMaterial.selector';
import { getSelectedShipmentId } from '../../../../../selectors/shipments/Shipments.selector';
import { findPackagingMaterialResultByBoxCode } from '../../../../common/PackagingCommon';
import {
    AuxiliaryPackagingConfiguration,
    mapAuxiliaryPackagingFormValues,
} from '../../../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingConfiguration';
import { AuxiliaryPackagingConfigurationFormId } from '../../../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingFormConfiguration';
import {
    AuxiliaryPackagingConfigurationFormValues,
    DialogAuxiliaryPackaging,
} from '../../../../common/dialog/AuxiliaryPackaging/types';

export const mapLayerStabilizationFormValues = (
    packagingMaterials: PackagingMaterialState['packagingMaterials'],
    layerStabilizationForm?: DialogAuxiliaryPackaging,
): AuxiliaryHandlingUnitGroup | undefined => {
    const description = findPackagingMaterialResultByBoxCode(
        layerStabilizationForm?.packagingMaterial?.boxCode,
        packagingMaterials,
    )?.packagingMaterial?.name;
    return layerStabilizationForm && layerStabilizationForm.auxiliaryPackagingPerHandlingUnit! > 0
        ? {
              quantity: layerStabilizationForm.auxiliaryPackagingPerHandlingUnit!,
              auxiliaryPackagingContent: {
                  type: layerStabilizationForm.packagingMaterial!.boxCode!,
                  description,
                  auxiliaryPackaging: [], // no nested aux packaging,
                  isReusable: layerStabilizationForm.packagingMaterial!.isReusable!,
                  ownership: layerStabilizationForm.packagingMaterial!.ownership!,
                  stackingFactor: layerStabilizationForm.packagingMaterial!.stackingFactor!,
                  tareWeightInKg: layerStabilizationForm.packagingMaterial!.tareWeightInKg!,
              },
          }
        : undefined;
};

export const mapAuxiliaryPackagingConfigurationFormValuesToAuxiliaryPackagingPayload = (
    formValues: AuxiliaryPackagingConfigurationFormValues,
    packagingMaterials: PackagingMaterialState['packagingMaterials'],
): AuxiliaryPackagingPayload => {
    return {
        auxiliaryPackaging: mapAuxiliaryPackagingFormValues(packagingMaterials, formValues.auxiliaryPackagings),
        layerStabilization: mapLayerStabilizationFormValues(packagingMaterials, formValues.layerStabilization),
    };
};

export const AuxiliaryPackagingConfigurationContainer = () => {
    const dispatch = useAppDispatch();
    const selectedShipmentId = useAppSelector(getSelectedShipmentId);
    const packagingMaterials = useAppSelector(getAllPackagingMaterials);

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(AuxiliaryPackagingConfigurationFormId.form));
    }, [dispatch]);

    const onSubmitHandler = (values: AuxiliaryPackagingConfigurationFormValues) => {
        const auxPayload = mapAuxiliaryPackagingConfigurationFormValuesToAuxiliaryPackagingPayload(
            values,
            packagingMaterials,
        );
        dispatch(applyUpdatePackagingConfiguration(auxPayload, selectedShipmentId!));
        dispatch(packagingSlice.actions.updateArticleToPackage(undefined));
        dispatch(packagingSlice.actions.clearUpdatePackagingConfiguration());
        dispatch(packagingSlice.actions.hidePackagingDialog());
    };
    const stabilizationInformation = useAppSelector(getLayerStabilizationInformation);
    const newPackagingFromWizard = useAppSelector(getNewPackagingFromWizardState);

    return (
        <AuxiliaryPackagingConfiguration
            stabilizationInformation={stabilizationInformation}
            onSubmitHandler={onSubmitHandler}
            newPackagingFromWizardType={newPackagingFromWizard.type}
        />
    );
};
