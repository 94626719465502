// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { config } from '../../../../config';
import { EdiFileSummaryOld, ReferenceType } from '../../domain/files.types';
import { blobOrReject, decodeJson, jsonOrReject } from '../apiUtils';
import { getRequest } from '../requests';

const ApiDownloadDetailsCodec = t.strict({ url: t.string });
const ApiEdiFileSummaryCodec = t.strict({
    id: t.string,
    name: t.string,
});
const ApiEdiFileSummariesCodec = t.strict({ items: t.array(ApiEdiFileSummaryCodec) });

type ApiEdiFileSummaries = t.TypeOf<typeof ApiEdiFileSummariesCodec>;

const mapFileSummariesResponse = (apiEdiFileSummaries: ApiEdiFileSummaries): EdiFileSummaryOld[] =>
    apiEdiFileSummaries.items.map((it) => ({
        id: it.id,
        filename: it.name,
    }));

export const fetchEdiFileSummaries = (
    dunsNumber: string,
    params?: {
        referenceId?: string;
        referenceType?: ReferenceType;
    },
): Promise<EdiFileSummaryOld[]> => {
    const url = new URL(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/files`);
    if (params?.referenceId) {
        url.searchParams.append('reference_id', params.referenceId);
    }
    if (params?.referenceType) {
        url.searchParams.append('reference_type', params.referenceType);
    }
    return fetch(url.toString(), getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiEdiFileSummariesCodec))
        .then(mapFileSummariesResponse);
};

export const fetchEdiFileDownloadUrl = (dunsNumber: string, id: string): Promise<string> =>
    fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/files/${id}/download-link`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiDownloadDetailsCodec))
        .then((downloadDetails) => downloadDetails.url);

export const fetchFileContentAsBlob = (fileurl: string): Promise<Blob> => {
    return fetch(fileurl).then(blobOrReject);
};
