import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CompanyInfo } from '../../api/userInfo/userInfoCalls';

export interface CompanyInfoState {
    companyName: undefined | string;
}

const initialState: CompanyInfoState = {
    companyName: undefined,
};

export const companyInfoSlice = createSlice({
    name: 'companyInfo',
    initialState,
    reducers: {
        setCompanyName: (state: CompanyInfoState, action: PayloadAction<CompanyInfo | undefined>) => {
            state.companyName = action.payload?.companyName;
        },
    },
});
