import { FormattedMessage } from 'react-intl';

export interface FooterProps {
    formId: string;
    totalSteps: number;
    currentStep: number;
    onClose: () => void;
    goNext: () => Promise<void>;
    goPrev?: () => void;
}

export const Footer = (props: FooterProps) => {
    const { formId, totalSteps, currentStep, onClose, goNext, goPrev } = props;
    return (
        <div className={'footer'}>
            <div className={'flex'}>
                {currentStep > 1 && goPrev && (
                    <button className={'btn btn-default float-left'} type={'button'} onClick={goPrev}>
                        <span className={'rioglyph rioglyph-chevron-left'} />
                        <FormattedMessage id='webedi.back' />
                    </button>
                )}
                <div className={'btn-toolbar float-right'}>
                    <button className={'btn btn-default'} type={'button'} onClick={onClose}>
                        <FormattedMessage id='webedi.label.cancel' />
                    </button>
                    {currentStep < totalSteps ? (
                        <button key={'next'} className={'btn btn-primary'} type={'button'} onClick={goNext}>
                            <FormattedMessage id='webedi.next' />
                            <span className={'rioglyph rioglyph-chevron-right padding-left-5'} />
                        </button>
                    ) : (
                        <button key={'submit'} className={'btn btn-primary'} type={'submit'} form={formId}>
                            <FormattedMessage id='webedi.label.save' />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
