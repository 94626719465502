import { useGetLoadingLocationsQuery } from '../../api/loadingLocation/loadingLocationApi';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';

export const useLoadingLocationsQuery = () => {
    const notKnown = 'NOT_KNOWN';
    const dunsNumber = useDunsNumberFromPath() ?? notKnown;

    return useGetLoadingLocationsQuery(
        { dunsNumber },
        {
            refetchOnMountOrArgChange: true,
            skip: dunsNumber === notKnown,
        },
    );
};
