import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import 'moment/min/locales';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import {
    dateTimeToLocalDate,
    isAfter,
    isBefore,
    isSameOrAfter,
    localDateToLocalEndOfDayInUtc,
    localDateToLocalStartOfDayInUtc,
    plusDuration,
} from '../../../../../dateUtils';
import { MAX_REQUEST_INTERVAL_IN_DAYS, TimeRangeType } from '../../../domain/transportOrderOptions.types';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import {
    getSelectedLoadingFromFilter,
    getSelectedLoadingToFilter,
    getSelectedTimeRangeTypeFilter,
} from '../../../selectors/transportOrders/transportOrders.selector';
import { DateFilter } from '../../common/filter/DateFilter';

export const TimeRangeSelection = () => {
    const intl = useIntl();

    const timeRangeType = useSelector(getSelectedTimeRangeTypeFilter);
    const from = useSelector(getSelectedLoadingFromFilter);
    const to = useSelector(getSelectedLoadingToFilter);
    const dispatch = useAppDispatch();

    const [shouldOpenToPicker, setShouldOpenToPicker] = useState(false);

    const didMountRef = useRef(false);
    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (didMountRef.current) {
            if (shouldOpenToPicker && timeRangeType === TimeRangeType.CUSTOM) {
                const elementNodeListOf = document.querySelectorAll('.DatePicker');
                if (elementNodeListOf.length > 1) {
                    // @ts-ignore
                    elementNodeListOf[1].querySelector('input').focus();
                }
            }
        } else {
            didMountRef.current = true;
        }
    }, [from]);

    const setFilterTimeRange = (fromDate: string, toDate: string) => {
        dispatch(transportOrdersSlice.actions.setFilterTimeRangeType(TimeRangeType.CUSTOM));
        dispatch(transportOrdersSlice.actions.setLoadingTimeFromFilter(localDateToLocalStartOfDayInUtc(fromDate)));
        dispatch(transportOrdersSlice.actions.setLoadingTimeToFilter(localDateToLocalEndOfDayInUtc(toDate)));
    };

    const onChangeFrom = (fromDate?: string) => {
        if (fromDate) {
            let toDate = dateTimeToLocalDate(to);
            if (isAfter(fromDate, toDate)) {
                toDate = fromDate;
                Notification.warning(
                    intl.formatMessage({
                        id: 'webedi.timeRangeSelection.notification.endBeforeStart',
                    }),
                );
            }
            if (isAfter(toDate, plusDuration(fromDate, { days: MAX_REQUEST_INTERVAL_IN_DAYS - 1 }))) {
                toDate = plusDuration(fromDate, { days: MAX_REQUEST_INTERVAL_IN_DAYS - 1 });
            }
            setFilterTimeRange(fromDate, toDate);
            setShouldOpenToPicker(true);
        }
    };

    const onChangeTo = (toDate?: string) => {
        if (toDate) {
            setFilterTimeRange(dateTimeToLocalDate(from), toDate);
        }
    };

    const isValidDateTo = (currentDate?: string) => {
        if (currentDate === undefined) {
            return false;
        }
        return (
            isSameOrAfter(currentDate, dateTimeToLocalDate(from)) &&
            isBefore(currentDate, plusDuration(dateTimeToLocalDate(from), { days: MAX_REQUEST_INTERVAL_IN_DAYS }))
        );
    };

    return (
        <div className={'display-flex'}>
            <div className='flex-1-0 display-flex padding-right-5 min-width-200'>
                <DateFilter
                    className={'width-0 min-width-auto flex-1-0'}
                    dataTestId={'timeRangeSelectionFrom'}
                    selectedDate={from ? dateTimeToLocalDate(from) : undefined}
                    onChange={onChangeFrom}
                    clearableInput={false}
                />
            </div>
            <div className='flex-1-0 display-flex padding-left-5 min-width-200'>
                <DateFilter
                    className={'width-0 min-width-auto flex-1-0'}
                    dataTestId={'timeRangeSelectionTo'}
                    selectedDate={to ? dateTimeToLocalDate(to) : undefined}
                    onChange={onChangeTo}
                    isValidDate={isValidDateTo}
                    clearableInput={false}
                />
            </div>
        </div>
    );
};
