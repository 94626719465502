import { useIntl } from 'react-intl';
import { MeasurementUnitCode } from '../../domain/common.types';
import { MeasurementUnitCodeDisplay } from './MeasurementUnitCodeDisplay';

interface QuantityProps {
    quantity: number;
    measurementUnitCode: MeasurementUnitCode;
    className?: string;
}

export const Quantity = (props: QuantityProps) => {
    const intl = useIntl();

    return (
        <span className={props.className}>
            {props.quantity.toLocaleString(intl.locale, { maximumFractionDigits: 2 })}{' '}
            <MeasurementUnitCodeDisplay unit={props.measurementUnitCode} />
        </span>
    );
};
