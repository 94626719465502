// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { RequestCondition } from '../../reducers/deliverySchedules/types';
import { ArticleMasterDataCodec } from '../shared/articleMasterData.types';
import { ItemShortDescriptionCodec } from '../shared/itemShortDescription.types';
import { PlaceOfDischargeCodec } from '../shared/placeOfDischarge.types';
import { quantityCodec } from '../shared/quantity.types';
import { ShipmentDateCodec } from '../shared/shipmentDate.types';
import { fromEnum } from '../util';

const RequestConditionCodec = fromEnum<RequestCondition>('RequestCondition', RequestCondition);

const SuggestedLoadItemCodec = t.intersection([
    t.type({
        delivery_schedule_id: t.string,
        article_number_buyer: t.string,
        amount: quantityCodec,
        order_number: t.string,
        item_short_descriptions: t.array(ItemShortDescriptionCodec),
        request_condition: RequestConditionCodec,
        has_valid_article_master_data: t.boolean,
        has_valid_cumulative_quantity_sent: t.boolean,
    }),
    t.partial({
        article_master_data: ArticleMasterDataCodec,
    }),
]);

export const ShipmentSuggestionCodec = t.exact(
    t.type({
        requested_delivery_date: ShipmentDateCodec,
        place_of_discharge: PlaceOfDischargeCodec,
        load: t.array(SuggestedLoadItemCodec),
        manufacturing_company: t.string,
        ship_to_id: t.string,
        ship_from_id: t.string,
    }),
);

export const ShipmentSuggestionsCodec = t.exact(
    t.type({
        items: t.array(ShipmentSuggestionCodec),
        total_count: t.number,
    }),
);

export const ShipmentSuggestionsQueryCodec = t.intersection([
    t.type({
        offset: t.number,
        limit: t.number,
    }),
    t.partial({
        q: t.string,
    }),
]);

export const ShipmentSuggestionsResponseCodec = t.exact(
    t.type({
        items: t.array(ShipmentSuggestionCodec),
        total_count: t.number,
        query: ShipmentSuggestionsQueryCodec,
    }),
);

export type ApiShipmentSuggestion = t.TypeOf<typeof ShipmentSuggestionCodec>;
export type ApiShipmentSuggestions = t.TypeOf<typeof ShipmentSuggestionsCodec>;
export type ApiSuggestedLoadItem = t.TypeOf<typeof SuggestedLoadItemCodec>;
export type ApiShipmentSuggestionsQueryResponse = t.TypeOf<typeof ShipmentSuggestionsResponseCodec>;
