import { useIntl } from 'react-intl';
import { CourierOrderValidation } from '../../domain/courierOrderIntent.types';
import { mapValidationResponseToFormattedMessages } from './courierOrderIntent/courierOrderValidationErrorResolver';

export interface DispatchProposalCourierOrderValidationErrorsBannerProps {
    validations: CourierOrderValidation[];
}

export const DispatchProposalCourierOrderValidationErrorsBanner = (
    props: DispatchProposalCourierOrderValidationErrorsBannerProps,
) => {
    const intl = useIntl();
    if (props.validations.length === 0) {
        return <></>;
    }
    const errorMessages = mapValidationResponseToFormattedMessages(props.validations, intl);
    return (
        <div
            data-testid={`DISPATCH_PROPOSAL_COURIER_ORDER_PROBLEM_BANNER`}
            className={`alert alert-danger`}
            style={{ marginBottom: '25px' }}
        >
            <div className={'display-flex flex-1-0'}>
                <span
                    className={`text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph rioglyph-error-sign`}
                />
                <div className={'flex-1-1'} style={{ width: '100%' }}>
                    {errorMessages.map((problem) => (
                        <div
                            data-testid={`DISPATCH_PROPOSAL_COURIER_ORDER_PROBLEM_BANNER_DESCRIPTION_ROW`}
                            className={'display-flex gap-50 full-width align-items-center text-color-black'}
                            key={problem}
                        >
                            <strong className={'text-size-16'}>{problem}</strong>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
