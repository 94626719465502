import languages from '@cospired/i18n-iso-languages';
import countries from 'i18n-iso-countries';
import { reportErrorToSentry } from '../setup/sentry';
import { trace } from '../setup/trace';
import { isoCountries } from './isoCountries';
import { isoLanguages } from './isoLanguages';
import { FALLBACK_LOCALE } from './lang';
import { getLocale } from './selectors';

const MODULE_NAME = `webedi-frontend`;

export const CHANGE_USER_SELECTED_LOCALE = 'lang/CHANGE_USER_SELECTED_LOCALE';
export const CHANGE_USER_TOKEN_LOCALE = 'lang/CHANGE_USER_TOKEN_LOCALE';
export const DISPLAY_MESSAGES_CHANGED = 'lang/DISPLAY_MESSAGES_CHANGED';

export const userSelectedLocaleChanged = (locale) => ({
    payload: locale,
    type: CHANGE_USER_SELECTED_LOCALE,
});

export const userTokenLocaleChanged = (locale) => ({
    payload: locale,
    type: CHANGE_USER_TOKEN_LOCALE,
});

export const displayMessagesChanged = (displayMessages) => ({
    payload: displayMessages,
    type: DISPLAY_MESSAGES_CHANGED,
});

const sendError = (exception) => {
    reportErrorToSentry(exception, { tags: { module: MODULE_NAME } });
};

const importIsoCountries = async (locale) => {
    const localeCode = locale.split('-')[0];

    if (localeCode in isoCountries) {
        try {
            const { default: data } = await isoCountries[localeCode]();
            countries.registerLocale(data);
        } catch (e) {
            sendError(e);
        }
    }
};

const importIsoLanguages = async (locale) => {
    const localeCode = locale.split('-')[0];

    if (localeCode in isoLanguages) {
        try {
            const { default: data } = await isoLanguages[localeCode]();
            languages.registerLocale(data);
        } catch (e) {
            sendError(e);
        }
    }
};

const loadDisplayMessages = async (locale) => {
    return import(`../../features/translations/${locale}.json`)
        .catch(async () => await import(`../../features/translations/en-GB.json`))
        .then(({ default: data }) => data)
        .catch((error) => {
            sendError(error);
            return error;
        });
};

export const updateDisplayMessages = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const displayLocale = getLocale(state);

        if (!displayLocale) {
            // happens on initial loading before the user session was processed
            return;
        }

        try {
            await importIsoCountries(displayLocale);
            trace(`ISO countries fetched for "${displayLocale}"`);

            await importIsoLanguages(displayLocale);
            trace(`ISO languages fetched for "${displayLocale}"`);

            const displayMessages = await loadDisplayMessages(displayLocale);
            dispatch(displayMessagesChanged(displayMessages));
        } catch (error) {
            sendError(error);
            dispatch(userSelectedLocaleChanged(FALLBACK_LOCALE));
        }
    };
};
