import { BaseSyntheticEvent, KeyboardEvent, KeyboardEventHandler, ReactElement, useEffect, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { unPad, zeroPad } from '../../../../../utils';
import { MeasurementUnitCode } from '../../../domain/common.types';
import { isDecimalBasedMeasurementUnit } from './NumberInput';

export interface BorderlessNumberInputProps extends FieldRenderProps<number> {
    id?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
    customError?: string;
    maxStringLength?: number;
    className?: string;
    suppressErrorBlock?: boolean | undefined;
    forbidScientificNotation?: boolean;
    measurementUnitCode?: MeasurementUnitCode;
    outerClassName?: string;
    errorMessageClassName?: string;
    errorIcon?: ReactElement;
    zeroPadDigits?: number;
}

export const BORDERLESS_NUMBER_INPUT_TEST_ID = 'BORDERLESS_NUMBER_INPUT_TEST_ID';

export const BorderlessNumberInput = (props: BorderlessNumberInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { input, meta, ...rest } = props;
    const {
        id,
        disabled,
        min,
        max,
        customError,
        maxStringLength,
        className,
        suppressErrorBlock,
        forbidScientificNotation,
        measurementUnitCode,
        outerClassName,
        errorMessageClassName,
        errorIcon,
        zeroPadDigits,
    } = rest;
    const errorClassName = 'form-group has-error has-feedback';
    const getInputRowClassName = (touched: boolean | undefined, error: any) =>
        touched ? `${error ? errorClassName : ''} ${outerClassName ? outerClassName : ''}` : '';
    const blockScientificNotation: KeyboardEventHandler<HTMLInputElement> = (event: KeyboardEvent) => {
        if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault();
        }
    };
    const getDefaultStep = () => (isDecimalBasedMeasurementUnit(measurementUnitCode) ? 0.01 : 1);
    const getDefaultDigitPrecision = () => (isDecimalBasedMeasurementUnit(measurementUnitCode) ? 2 : 0);

    useEffect(() => {
        if (document.activeElement !== inputRef.current) {
            zeroPad(zeroPadDigits, inputRef);
        }
    });

    const onBlur = (event: BaseSyntheticEvent) => {
        input.onChange(Number(event.target.value).toFixed(getDefaultDigitPrecision()));
        zeroPad(zeroPadDigits, inputRef);
        input.onBlur(event as any);
    };

    const error = customError ? customError : meta.error;
    return (
        <div className={getInputRowClassName(meta.touched, error)}>
            <div className={'input-group'}>
                <input
                    id={id}
                    type={'number'}
                    min={min}
                    max={max}
                    step={getDefaultStep()}
                    className={className}
                    disabled={disabled}
                    onKeyDown={forbidScientificNotation ? blockScientificNotation : (event) => event}
                    {...input}
                    ref={inputRef}
                    onBlur={onBlur}
                    onFocus={() => unPad(zeroPadDigits, inputRef)}
                    data-testid={BORDERLESS_NUMBER_INPUT_TEST_ID}
                />
                <div
                    style={{ right: '4px' }}
                    className={`position-absolute z-index-3 ${error ? '' : 'pointer-events-none'}`}
                >
                    {error ? errorIcon : <span className={'rioglyph rioglyph-pencil text-size-18 opacity-30'} />}
                </div>
            </div>
            {!suppressErrorBlock && meta.touched && meta.error ? (
                <span className={`help-block ${errorMessageClassName}`}>
                    <FormattedMessage
                        id={meta.error}
                        values={{
                            number: maxStringLength,
                            maxValue: max,
                        }}
                    />
                </span>
            ) : null}
        </div>
    );
};
