// Vite currently does not support glob imports inside node modules
// see https://github.com/vitejs/vite/issues/14102
// => we hard-code all country-code based imports here

export const isoLanguages = {
    br: () => import('@cospired/i18n-iso-languages/langs/br.json'),
    cs: () => import('@cospired/i18n-iso-languages/langs/cs.json'),
    da: () => import('@cospired/i18n-iso-languages/langs/da.json'),
    de: () => import('@cospired/i18n-iso-languages/langs/de.json'),
    en: () => import('@cospired/i18n-iso-languages/langs/en.json'),
    es: () => import('@cospired/i18n-iso-languages/langs/es.json'),
    fi: () => import('@cospired/i18n-iso-languages/langs/fi.json'),
    fr: () => import('@cospired/i18n-iso-languages/langs/fr.json'),
    he: () => import('@cospired/i18n-iso-languages/langs/he.json'),
    hu: () => import('@cospired/i18n-iso-languages/langs/hu.json'),
    id: () => import('@cospired/i18n-iso-languages/langs/id.json'),
    is: () => import('@cospired/i18n-iso-languages/langs/is.json'),
    it: () => import('@cospired/i18n-iso-languages/langs/it.json'),
    ja: () => import('@cospired/i18n-iso-languages/langs/ja.json'),
    lt: () => import('@cospired/i18n-iso-languages/langs/lt.json'),
    lv: () => import('@cospired/i18n-iso-languages/langs/lv.json'),
    ms: () => import('@cospired/i18n-iso-languages/langs/ms.json'),
    nl: () => import('@cospired/i18n-iso-languages/langs/nl.json'),
    no: () => import('@cospired/i18n-iso-languages/langs/no.json'),
    pl: () => import('@cospired/i18n-iso-languages/langs/pl.json'),
    pt: () => import('@cospired/i18n-iso-languages/langs/pt.json'),
    ro: () => import('@cospired/i18n-iso-languages/langs/ro.json'),
    ru: () => import('@cospired/i18n-iso-languages/langs/ru.json'),
    sv: () => import('@cospired/i18n-iso-languages/langs/sv.json'),
    th: () => import('@cospired/i18n-iso-languages/langs/th.json'),
    uk: () => import('@cospired/i18n-iso-languages/langs/uk.json'),
    vi: () => import('@cospired/i18n-iso-languages/langs/vi.json'),
    zh: () => import('@cospired/i18n-iso-languages/langs/zh.json'),
};
