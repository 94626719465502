import {
    FreightForwarderFromTransportOrder,
    ManagedFreightForwarder,
    ManagedFreightForwarderAddress,
    PartyIdentifier,
} from '../../reducers/transportation/types';
import {
    ApiFreightForwarderFromTransportOrder,
    ApiManagedFreightForwarder,
    ApiManagedFreightForwarderAddress,
    ApiManagedFreightForwarders,
    ApiPartyIdentifier,
} from './transportation.types';

export const mapManagedFreightForwarders = (
    apiManagedFreightForwarders: ApiManagedFreightForwarders,
): ManagedFreightForwarder[] => apiManagedFreightForwarders.items.map(mapManagedFreightForwarder);

export const mapManagedFreightForwarderAddress = (
    apiManagedFreightForwarderAddress: ApiManagedFreightForwarderAddress,
): ManagedFreightForwarderAddress => ({
    streetAddress: apiManagedFreightForwarderAddress.street_address,
    postalCode: apiManagedFreightForwarderAddress.postal_code,
    city: apiManagedFreightForwarderAddress.city,
    regionCode: apiManagedFreightForwarderAddress.region_code,
    countryCode: apiManagedFreightForwarderAddress.country_code,
});

export const mapManagedFreightForwarder = (
    apiManagedFreightForwarder: ApiManagedFreightForwarder,
): ManagedFreightForwarder => ({
    id: apiManagedFreightForwarder.id,
    dunsNumber: apiManagedFreightForwarder.duns_number,
    dunsNumberOwner: apiManagedFreightForwarder.duns_number_owner,
    partyIdentifierBuyer: apiManagedFreightForwarder.party_identifier_buyer,
    partyIdentifierSeller: apiManagedFreightForwarder.party_identifier_seller,
    name: apiManagedFreightForwarder.name,
    address: mapManagedFreightForwarderAddress(apiManagedFreightForwarder.address),
    type: 'MANAGED_FREIGHT_FORWARDER',
});

export const mapFreightForwarderFromTransportOrder = (
    apiFreightForwarderFromTransportOrder: ApiFreightForwarderFromTransportOrder,
): FreightForwarderFromTransportOrder => ({
    name: apiFreightForwarderFromTransportOrder.name,
    dunsNumber: apiFreightForwarderFromTransportOrder.duns_number,
    partyIdentifier: mapPartyIdentifier(apiFreightForwarderFromTransportOrder.party_identifier),
    type: 'FREIGHT_FORWARDER_FROM_TRANSPORT_ORDER',
});

const mapPartyIdentifier = (partyIdentifier: ApiPartyIdentifier): PartyIdentifier => {
    switch (partyIdentifier.type) {
        case 'ASSIGNED_BY_BUYER':
            return {
                type: 'ASSIGNED_BY_BUYER',
                value: partyIdentifier.value,
            };
        case 'ASSIGNED_BY_SELLER':
            return {
                type: 'ASSIGNED_BY_SELLER',
                value: partyIdentifier.value,
            };
        default:
            throw new Error(`Unable to map partyIdentifier ${JSON.stringify(partyIdentifier)}`);
    }
};

export const mapManagedFreightForwarderAddressToApi = (
    address: ManagedFreightForwarderAddress,
): ApiManagedFreightForwarderAddress => ({
    street_address: address.streetAddress,
    postal_code: address.postalCode,
    city: address.city,
    region_code: address.regionCode,
    country_code: address.countryCode,
});

export const mapManagedFreightForwarderToApi = (
    managedFreightForwarder: ManagedFreightForwarder,
): ApiManagedFreightForwarder => ({
    id: managedFreightForwarder.id,
    duns_number: managedFreightForwarder.dunsNumber,
    duns_number_owner: managedFreightForwarder.dunsNumberOwner,
    party_identifier_buyer: managedFreightForwarder.partyIdentifierBuyer,
    party_identifier_seller: managedFreightForwarder.partyIdentifierSeller,
    name: managedFreightForwarder.name,
    address: mapManagedFreightForwarderAddressToApi(managedFreightForwarder.address),
});
