import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CourierOrder } from '../../domain/shippers.types';

export interface ShippersState {
    orders: CourierOrder[];
}

const getInitialState = (): ShippersState => {
    return {
        orders: [],
    };
};

export const initialState: ShippersState = getInitialState();

export const shippersSlice = createSlice({
    name: 'shippers',
    initialState,
    reducers: {
        setCourierOrder: (state: ShippersState, action: PayloadAction<CourierOrder[]>) => {
            action.payload.forEach((newOrder) => {
                const existingOrderIndex = state.orders.findIndex((order) => order.shipmentId === newOrder.shipmentId);

                if (existingOrderIndex > -1) {
                    state.orders[existingOrderIndex] = newOrder;
                } else {
                    state.orders.push(newOrder);
                }
            });
        },
    },
});
