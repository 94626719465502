import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { FormattedMessage } from 'react-intl';
import { ArticleSuggestion, ManualDispatchProposalDraft } from '../../../domain/dispatchProposal.types';
import { ManualDispatchProposalDraftItemsTable } from './ManualDispatchProposalDraftItemsTable';
import { PanelPlantItem, PanelTransportConceptInputItem, StatusIndicator } from './panel.common';

export const ManualDispatchProposalDraftPanel = (props: {
    draft: ManualDispatchProposalDraft;
    articleSuggestion?: ArticleSuggestion;
    onClickAddArticle: () => void;
    isAddingDispatchProposalItem: boolean;
}) => {
    const { draft, onClickAddArticle, isAddingDispatchProposalItem } = props;
    const addDispatchProposalItemButtonColor = `${isAddingDispatchProposalItem ? 'btn-primary' : 'btn-default'}`;

    return (
        <ExpanderPanel
            data-testid={'MANUAL_DISPATCH_PROPOSAL_DRAFT_PANEL_TEST_ID'}
            title={<ManualDispatchProposalDraftPanelHeader draft={draft} />}
            open
            bsStyle={'default'}
            className={'margin-bottom-25'}
            titleClassName={'width-100pct text-normal'}
            bodyClassName={'padding-0'}
        >
            {props.articleSuggestion !== undefined ? (
                <ManualDispatchProposalDraftItemsTable
                    key={draft.id}
                    draft={draft}
                    articleSuggestion={props.articleSuggestion}
                />
            ) : (
                <>
                    <div className={'height-5'} />
                    <NotFoundState
                        headline={
                            <FormattedMessage id={'webedi.dispatchProposals.overview.expander.items.noData.headline'} />
                        }
                        message={
                            <FormattedMessage id={'webedi.dispatchProposals.overview.expander.items.noData.message'} />
                        }
                        outerClassName={'border-none height-auto'}
                    />
                </>
            )}
            <button
                type={'button'}
                className={`btn ${addDispatchProposalItemButtonColor} center-block margin-y-15`}
                onClick={onClickAddArticle}
            >
                <span className={'rioglyph rioglyph-plus-light'} />
                <FormattedMessage id={'webedi.deliveryNote.addArticle'} />
            </button>
        </ExpanderPanel>
    );
};
const ManualDispatchProposalDraftPanelHeader = (props: {
    draft: ManualDispatchProposalDraft;
}) => {
    const { draft } = props;
    return (
        <div className={'display-flex justify-content-between align-items-center text-size-14'}>
            <div className={'display-flex flex-1-1 gap-10 justify-content-between'}>
                <div className={'display-flex align-items-center flex-row gap-5 margin-right-15'}>
                    <div className={`ellipsis-1 text-medium expander-header-text text-uppercase`}>
                        <span>
                            <StatusIndicator status={'UNPLANNED'} />
                            <FormattedMessage
                                id={'webedi.dispatchProposals.overview.expander.header.title.unplanned'}
                            />
                        </span>
                    </div>
                </div>
                <div className={'display-flex flex-wrap align-items-center justify-content-center gap-5'}>
                    <PanelTransportConceptInputItem transportConcept={draft.transportConcept} />
                    <PanelPlantItem plantCodes={[draft.plantCode]} />
                </div>
                <div className={'display-flex flex-0-1'} />
            </div>
        </div>
    );
};
