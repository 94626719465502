import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';

const EMAIL_NOTIFICATION_API_TAG = 'EMAIL_NOTIFICATION';

export interface EmailNotification {
    email: string | undefined;
}

export const emailNotificationApi = createApi({
    reducerPath: 'emailNotificationApi',
    tagTypes: [EMAIL_NOTIFICATION_API_TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: config.emailNotificationServiceUrl,
        prepareHeaders: async (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getEmailNotification: builder.query<EmailNotification, void>({
            query: () => '/email',
            transformResponse: (response: unknown) => {
                if (response === null || response === undefined) {
                    return { email: undefined };
                } else {
                    return response as EmailNotification;
                }
            },
            providesTags: [{ type: EMAIL_NOTIFICATION_API_TAG }],
        }),
        addEmailNotification: builder.mutation<EmailNotification, EmailNotification>({
            query: (body) => ({
                url: '/email',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: EMAIL_NOTIFICATION_API_TAG }],
        }),
        deleteEmailNotification: builder.mutation<EmailNotification, void>({
            query: () => ({
                url: '/email',
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: EMAIL_NOTIFICATION_API_TAG }],
        }),
    }),
});

export const { useGetEmailNotificationQuery, useAddEmailNotificationMutation, useDeleteEmailNotificationMutation } =
    emailNotificationApi;
