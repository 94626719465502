import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

interface DownloadTrackingFilesProps {
    pdf: string;
    courier: string;
    id: string;
}
export const DownloadTrackingFiles = (props: DownloadTrackingFilesProps) => {
    const { pdf, courier, id } = props;
    const downloadPdf = useCallback(() => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${pdf}`;
        link.download = `${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [pdf, id]);

    return (
        <div
            onClick={downloadPdf}
            data-testid='download-label'
            className='panel panel-default padding-20 margin-left-10pct-md margin-right-10pct-md display-flex justify-content-between text-color-primary hover-bg-lightest cursor-pointer'
        >
            <div>
                <div data-testid='download-tracking-data' className='text-bold text-left text-size-16'>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.downloadHeadline'}
                        values={{ courier }}
                    />
                </div>
                <div className='margin-top-20 text-size-14 text-left text-color-dark'>
                    <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.downloadBody'} />
                </div>
            </div>
            <div className='margin-left-20 display-flex align-items-center text-center'>
                <div>
                    <div className='rioglyph rioglyph-tag text-size-h1' />
                </div>
            </div>
        </div>
    );
};
