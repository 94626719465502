import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { packagingSlice } from '../../../../reducers/shipments/Packaging.reducer';
import { PackagingType } from '../../../../reducers/shipments/packaging.types';
import { getSelectedShipmentId } from '../../../../selectors/shipments/Shipments.selector';

export const PACKAGED_ARTICLES_HEADER_EDIT_CREATE_PACKAGING_BUTTON =
    'PACKAGED_ARTICLES_HEADER_EDIT_CREATE_PACKAGING_BUTTON';
export const PACKAGED_ARTICLES_HEADER_EDIT_EXPAND_ALL_BUTTON = 'PACKAGED_ARTICLES_HEADER_EDIT_EXPAND_ALL_BUTTON';
export const PACKAGED_ARTICLES_HEADER_EDIT_VIEW = 'PACKAGED_ARTICLES_HEADER_EDIT_VIEW';
export const PACKAGED_ARTICLES_HEADER_EDIT_VIEW_DROPDOWN_TEST_ID =
    'PACKAGED_ARTICLES_HEADER_EDIT_VIEW_DROPDOWN_TEST_ID';

export interface PackagedArticlesHeaderViewProps {
    allItemsExpanded: boolean;
    setAllItemsExpanded: (arg: boolean) => void;
}

export const PackagedArticlesHeaderEditView = (props: PackagedArticlesHeaderViewProps) => {
    const { allItemsExpanded, setAllItemsExpanded } = props;
    const dispatch = useAppDispatch();
    const selectedShipmentId = useAppSelector(getSelectedShipmentId);

    return (
        <div
            className={'display-flex bg-white padding-10 justify-content-between align-items-center'}
            data-testid={PACKAGED_ARTICLES_HEADER_EDIT_VIEW}
        >
            <button
                type={'button'}
                data-testid={PACKAGED_ARTICLES_HEADER_EDIT_CREATE_PACKAGING_BUTTON}
                className={'btn btn-default pull-left btn-primary'}
                onClick={() => dispatch(packagingSlice.actions.showDialog({ type: PackagingType.REGULAR }))}
            >
                <span className={'rioglyph rioglyph-parcel'} />
                <FormattedMessage id={'webedi.packaging.createPackaging'} />
            </button>
            <div className={'btn-toolbar pull-right'}>
                <button
                    type={'button'}
                    className={'btn btn-danger btn-outline'}
                    onClick={() => dispatch(packagingSlice.actions.deletePackagingConfiguration(selectedShipmentId!))}
                >
                    <span className={'rioglyph rioglyph-trash'} />
                    <FormattedMessage id={'webedi.label.deleteAllPackaging'} />
                </button>
                <button
                    type={'button'}
                    className={'btn btn-link btn-icon-only'}
                    data-testid={PACKAGED_ARTICLES_HEADER_EDIT_EXPAND_ALL_BUTTON}
                    onClick={() => setAllItemsExpanded(!allItemsExpanded)}
                >
                    <span className={`rioglyph rioglyph-resize-${allItemsExpanded ? 'small' : 'full'}`} />
                </button>
            </div>
        </div>
    );
};
