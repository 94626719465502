import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isBefore, utcNow } from '../../../../dateUtils';
import {
    DispatchProposal,
    DispatchProposalItem,
    FreightForwarder,
    TimeBasedDispatchProposalItemOperation,
    TimeBasedDispatchProposalOperation,
    areDispatchProposalItemIdentifiersEqual,
    sameDispatchProposalItemIdentifiers,
} from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { TransportPlanningDraft } from '../../reducers/dispatchProposal/types';
import { selectTransportPlanningDraft } from '../../selectors/dispatchProposals/DispatchProposals.selector';

export const useCanTransportOrderIntentsBeDeleted = (dispatchProposal: DispatchProposal): boolean => {
    const currentDateTime = useCurrentDateTime();
    return isOperationAllowedAt(dispatchProposal.operations.deleteTransportOrderIntent, currentDateTime);
};

export const useDispatchProposalItemCompatibility = (
    proposal: DispatchProposal,
    item: DispatchProposalItem | undefined,
): boolean => {
    const draft = useSelector(selectTransportPlanningDraft);
    const now = useCurrentDateTime();
    if (item === undefined) {
        return false;
    }
    return canDispatchProposalItemBeAddedToTransportPlanningDraft(proposal, item, draft, now);
};

export const useSelectedDispatchProposalItemsInProposal = (proposal: DispatchProposal): DispatchProposalItem[] => {
    const draft = useSelector(selectTransportPlanningDraft);
    const selectedItems = draft?.selectedDispatchProposalItems;

    return (
        selectedItems?.flatMap((selectedItem) => {
            const itemInDispatchProposal = proposal.items.find((item) =>
                areDispatchProposalItemIdentifiersEqual(selectedItem, item),
            );
            return itemInDispatchProposal ? [itemInDispatchProposal] : [];
        }) ?? []
    );
};

export const useDispatchProposalCompatibility = (proposal: DispatchProposal): boolean => {
    const draft = useSelector(selectTransportPlanningDraft);
    const now = useCurrentDateTime();
    const selectedItemsInDispatchProposal = useSelectedDispatchProposalItemsInProposal(proposal);

    const itemsToCheck = selectedItemsInDispatchProposal.length > 0 ? selectedItemsInDispatchProposal : proposal.items;

    return itemsToCheck.every((item) =>
        canDispatchProposalItemBeAddedToTransportPlanningDraft(proposal, item, draft, now),
    );
};

export const useCurrentDateTime = (refreshRateInS: number = 60): string => {
    const [currentDateTime, setCurrentDateTime] = useState(utcNow());
    useEffect(() => {
        const interval = setInterval(() => setCurrentDateTime(utcNow()), refreshRateInS * 1000);
        return () => clearInterval(interval);
    });
    return currentDateTime;
};

const canDispatchProposalItemBeAddedToTransportPlanningDraft = (
    dispatchProposal: DispatchProposal,
    dispatchProposalItem: DispatchProposalItem,
    transportPlanningDraft: TransportPlanningDraft | undefined,
    currentDateUtc: string,
): boolean => {
    const { createTransportOrderIntent } = dispatchProposal.operations;

    const eligibleForOrdering = isOperationForItemAllowedAt(
        createTransportOrderIntent,
        dispatchProposalItem,
        currentDateUtc,
    );

    const compatibleWithTransportPlanningDraft =
        transportPlanningDraft === undefined ||
        isCompatibleWithCurrentTransportPlanningDraft(dispatchProposal, dispatchProposalItem, transportPlanningDraft);
    return eligibleForOrdering && compatibleWithTransportPlanningDraft;
};

const isOperationForItemAllowedAt = (
    operation: TimeBasedDispatchProposalItemOperation,
    dispatchProposalItem: DispatchProposalItem,
    currentDateUtc: string,
): boolean => {
    return (
        satisfiesTimeConstraint(operation, currentDateUtc) &&
        operation.allowedPerItem.some(({ identifier, allowed }) => {
            return sameDispatchProposalItemIdentifiers(identifier, dispatchProposalItem.identifier) && allowed;
        })
    );
};

const satisfiesTimeConstraint = (
    operation: Pick<TimeBasedDispatchProposalOperation, 'availableUntil'>,
    date: string,
): boolean => {
    return isBefore(date, operation.availableUntil);
};

export const isOperationAllowedAt = (operation: TimeBasedDispatchProposalOperation, date: string): boolean => {
    return operation.allowed && satisfiesTimeConstraint(operation, date);
};

const isCompatibleWithCurrentTransportPlanningDraft = (
    dispatchProposal: DispatchProposal,
    dispatchProposalItem: DispatchProposalItem,
    transportPlanningDraft: TransportPlanningDraft,
) => {
    if (dispatchProposal.termination.transportConcept === TransportConcept.KEP) {
        return false;
    }
    if (transportPlanningDraft.transportConcept !== dispatchProposal.termination.transportConcept) {
        return false;
    }

    const sameFreightForwarder = freightForwarderEquals(
        transportPlanningDraft.freightForwarder,
        dispatchProposal.termination.freightForwarder,
    );
    const samePickupDate = transportPlanningDraft.pickupDate === dispatchProposal.termination.pickupDate;
    const matchingDeliveryDates =
        transportPlanningDraft.selectedDispatchProposalItems.length === 0 ||
        transportPlanningDraft.selectedDispatchProposalItems[0].deliveryDate === dispatchProposalItem.deliveryDate;
    const matchingPlantCodes = transportPlanningDraft.selectedDispatchProposalItems
        .map((item) => item.shipTo.plantCode)
        .every((plantCode) => plantCode === dispatchProposalItem.shipTo.plantCode);

    if (dispatchProposal.termination.transportConcept === TransportConcept.LTL) {
        return samePickupDate && sameFreightForwarder;
    } else if (dispatchProposal.termination.transportConcept === TransportConcept.FTL) {
        return samePickupDate && sameFreightForwarder && matchingDeliveryDates && matchingPlantCodes;
    }
    return false;
};

const freightForwarderEquals = (left: FreightForwarder, right: FreightForwarder): boolean => {
    return left.name === right.name && left.dunsNumber === right.dunsNumber && left.supplierCode === right.supplierCode;
};
