import { FreightForwarderEdit } from './FreightForwarderEdit';
import { MeansOfTransportEdit } from './MeansOfTransportEdit';
import { TransportOrderNumberEdit } from './TransportOrderNumberEdit';

export const ASSIGN_FREIGHT_FORWARDER_TEST_ID = 'ASSIGN_FREIGHT_FORWARDER_TEST_ID';

export const TransportEdit = () => {
    return (
        <div>
            <FreightForwarderEdit />
            <hr />
            <MeansOfTransportEdit />
            <hr />
            <TransportOrderNumberEdit />
        </div>
    );
};
