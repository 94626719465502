import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { neverReachedFor } from '../../../../utils';
import { Brand, PlaceOfDischargeMasterData } from '../../domain/placeOfDischargeMasterData.types';
import { decodeJson } from '../apiUtils';
import { mapAddress } from '../shipment/shipmentMapper';
import {
    ApiBrand,
    ApiPlaceOfDischargeMasterData,
    getPlacesOfDischargeMasterDataResponseCodec,
} from './placesOfDischargeMasterDataApi.types';

interface GetPlaceOfDischargeMasterDataParameters {
    eligibleForKep?: boolean;
}

const mapToPlaceOfDischargeMasterData = (src: ApiPlaceOfDischargeMasterData): PlaceOfDischargeMasterData => ({
    id: src.id,
    plantCode: src.plant_code,
    plantName: src.plant_name,
    placeOfDischargeCode: src.place_of_discharge_code,
    brand: mapToBrand(src.brand),
    address: src.address ? mapAddress(src.address) : undefined,
});

const mapToBrand = (src: ApiBrand): Brand => {
    switch (src) {
        case ApiBrand.PORSCHE:
            return Brand.PORSCHE;
        case ApiBrand.LAMBORGHINI:
            return Brand.LAMBORGHINI;
        case ApiBrand.BENTLEY:
            return Brand.BENTLEY;
        case ApiBrand.MAN:
            return Brand.MAN;
        case ApiBrand.VOLKSWAGEN_STANDARD:
            return Brand.VOLKSWAGEN_STANDARD;
        default:
            return neverReachedFor(src);
    }
};

const transformGetPlaceOfDischargeMasterDataResponse = (response: unknown): PlaceOfDischargeMasterData[] => {
    const decoded = decodeJson(getPlacesOfDischargeMasterDataResponseCodec)(response);
    return decoded.items.map((item) => mapToPlaceOfDischargeMasterData(item));
};

export const placeOfDischargeMasterDataApi = createApi({
    reducerPath: 'placeOfDischargeMasterDataApi',
    tagTypes: ['PlacesOfDischargeMasterData'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPlacesOfDischargeMasterData: builder.query<
            PlaceOfDischargeMasterData[],
            GetPlaceOfDischargeMasterDataParameters
        >({
            query: ({ eligibleForKep }) => ({
                url: `/places-of-discharge`,
                params: {
                    eligible_for_kep: eligibleForKep,
                },
            }),
            transformResponse: (response: unknown) => transformGetPlaceOfDischargeMasterDataResponse(response),
        }),
    }),
});

export const { useGetPlacesOfDischargeMasterDataQuery } = placeOfDischargeMasterDataApi;
