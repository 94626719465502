import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { SupplierData } from '../../reducers/deliverySchedules/types';
import { decodeJson } from '../apiUtils';
import { mapPartner, mapParty } from '../commonMapper';
import { ApiSupplierData, getSellerDataResponseCodec } from './supplierDataApi.types';

const transformGetSupplierDataResponse = (response: unknown): SupplierData[] => {
    const decoded = decodeJson(getSellerDataResponseCodec)(response);
    return decoded.items.map(mapShipperData);
};

export const mapShipperData = (apiShipperData: ApiSupplierData): SupplierData => {
    return {
        buyer: mapParty(apiShipperData.buyer),
        seller: mapParty(apiShipperData.seller),
        shipFrom: mapParty(apiShipperData.ship_from),
        partner: mapPartner(apiShipperData.partner),
    };
};

interface GetSupplierDataParam {
    dunsNumber: string;
}

export const supplierDataApi = createApi({
    reducerPath: 'supplierDataApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSupplierData: builder.query<SupplierData[], GetSupplierDataParam>({
            query: ({ dunsNumber }) => ({
                url: `/shippers/${dunsNumber}/supplier-data`,
            }),
            transformResponse: (response: unknown) => transformGetSupplierDataResponse(response),
        }),
    }),
});

export const { useGetSupplierDataQuery } = supplierDataApi;
