import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import { FormattedMessage, useIntl } from 'react-intl';
import { Customs } from '../../../reducers/shipments/customs.types';
import { TermsOfDelivery } from '../../../reducers/shipments/types';
import { AssignedFreightForwarder } from '../AssignedFreightForwarder';

import { Carrier } from '../../../domain/carrier.types';
import { MeansOfTransport } from '../../../domain/meansOfTransport.types';
import { FreightForwarder } from '../../../reducers/transportation/types';
import {
    headingCustoms,
    headingTermsOfDelivery,
    headingTransport,
    panelRow,
} from '../shipmentEditView/transport/TransportContentEditView';
import { MeansOfTransportReadView } from './MeansOfTransportReadView';
import { TransportContentReadViewProps } from './types';

import { ReadOnlyCarrierInput } from '../ReadOnlyCarrierInput';

export const TRANSPORT_READ_VIEW_TEST_ID = 'TRANSPORT_READ_VIEW_TEST_ID';

export const INCOTERM_CODE_TEST_ID = 'INCOTERM_CODE_TEST_ID';
export const INCOTERM_LOCATION_NAME_TEST_ID = 'INCOTERM_LOCATION_NAME_TEST_ID';

export const CUSTOMS_VALUE_TEST_ID = 'CUSTOMS_VALUE_TEST_ID';
export const CUSTOMS_CURRENCY_TEST_ID = 'CUSTOMS_LOCATION_ID_TEST_ID';
export const CUSTOMS_PREFERENCE_AUTHORIZATION_TEST_ID = 'CUSTOMS_PREFERENCE_AUTHORIZATION_TEST_ID';
export const CUSTOMS_LANGUAGE_TEST_ID = 'CUSTOMS_LANGUAGE_TEST_ID';

export const TransportContentReadView = (props: TransportContentReadViewProps) => {
    if (
        !props.termsOfDelivery &&
        !props.freightForwarder &&
        !props.meansOfTransport &&
        !props.customs &&
        !props.transportOrderNumber &&
        !props.carrier &&
        !props.transportOrderReference
    ) {
        return (
            <div data-testid={TRANSPORT_READ_VIEW_TEST_ID}>
                <EmptyState headline={<FormattedMessage id={'webedi.transportAndCustoms.emptyContent'} />} />
            </div>
        );
    }

    return (
        <div data-testid={TRANSPORT_READ_VIEW_TEST_ID}>
            {props.termsOfDelivery &&
                panelRow(
                    headingTermsOfDelivery,
                    'rioglyph-transfer',
                    <IncotermsReadView termsOfDelivery={props.termsOfDelivery} />,
                )}
            {(props.freightForwarder || props.meansOfTransport || props.transportOrderNumber) &&
                panelRow(
                    headingTransport,
                    'rioglyph-truck',
                    <TransportReadView
                        freightForwarder={props.freightForwarder}
                        carrier={props.carrier}
                        meansOfTransport={props.meansOfTransport}
                        transportOrderNumber={props.transportOrderNumber}
                        transportOrderReference={props.transportOrderReference}
                    />,
                )}
            {props.customs &&
                panelRow(headingCustoms, 'rioglyph-cost-efficency', <CustomsReadView customs={props.customs} />)}
        </div>
    );
};

const IncotermsReadView = (props: { termsOfDelivery: TermsOfDelivery }) => {
    const { termsOfDelivery } = props;
    const intl = useIntl();

    const incotermCode = termsOfDelivery.incotermsCode;

    return (
        <>
            <div className={'display-flex-sm flex-row width-100pct'}>
                <div className={'width-33pct-sm padding-right-15 no-control-arrows'}>
                    <label>
                        <FormattedMessage id={'webedi.incoterm'} />
                    </label>
                    <input
                        className={'form-control'}
                        disabled
                        type={'text'}
                        value={
                            incotermCode
                                ? `${incotermCode} - ${intl.formatMessage({ id: `webedi.incoterm.${incotermCode}` })}`
                                : ''
                        }
                        data-testid={INCOTERM_CODE_TEST_ID}
                    />
                </div>
                <div className={'width-33pct-sm padding-right-15 no-control-arrows'}>
                    <label>
                        <FormattedMessage id={'webedi.location.name'} />
                    </label>
                    <input
                        className={'form-control'}
                        disabled
                        type={'text'}
                        value={termsOfDelivery.location?.name ? termsOfDelivery.location.name : undefined}
                        data-testid={INCOTERM_LOCATION_NAME_TEST_ID}
                    />
                </div>
            </div>
        </>
    );
};

export const CustomsReadView = (props: { customs: Customs }) => {
    return (
        <>
            <div className={'display-flex-sm flex-row width-100pct'}>
                <div className={'width-60pct-sm padding-right-15'}>
                    <label>
                        <FormattedMessage id={'webedi.customs.preferenceAuthorization'} />
                    </label>
                    <textarea
                        value={props.customs.preferenceAuthorization?.text}
                        rows={5}
                        className={'form-control'}
                        style={{ resize: 'none' }}
                        disabled
                        data-testid={CUSTOMS_PREFERENCE_AUTHORIZATION_TEST_ID}
                    />
                </div>
                <div className={'width-40pct-sm'}>
                    <label>
                        <FormattedMessage id={'webedi.customs.preferenceAuthorizationLanguage'} />
                    </label>
                    <input
                        className={'form-control'}
                        disabled
                        type={'text'}
                        value={props.customs.preferenceAuthorization?.language}
                        data-testid={CUSTOMS_LANGUAGE_TEST_ID}
                    />
                </div>
            </div>
        </>
    );
};

const TransportReadView = (props: {
    freightForwarder?: FreightForwarder;
    carrier?: Carrier;
    meansOfTransport?: MeansOfTransport;
    transportOrderNumber?: string;
    transportOrderReference?: string;
}) => {
    return (
        <div>
            {props.freightForwarder && (
                <TransportationPartiesReadView
                    freightForwarder={props.freightForwarder}
                    carrier={props.carrier}
                    transportOrderReference={props.transportOrderReference}
                />
            )}
            {props.meansOfTransport && (
                <div>
                    {props.freightForwarder && <hr />}
                    <MeansOfTransportReadView meansOfTransport={props.meansOfTransport} />
                </div>
            )}
            {props.transportOrderNumber && (
                <div>
                    {(props.freightForwarder || props.meansOfTransport) && <hr />}
                    <TransportOrderNumberReadView transportOrderNumber={props.transportOrderNumber} />
                </div>
            )}
        </div>
    );
};

const TransportationPartiesReadView = (props: {
    freightForwarder: FreightForwarder;
    carrier?: Carrier;
    transportOrderReference?: string;
}) => (
    <div className={'display-flex-sm flex-row align-items-center width-100pct'}>
        <div className={'width-33pct-sm padding-right-15'}>
            <AssignedFreightForwarder freightForwarder={props.freightForwarder} />
        </div>
        {props.transportOrderReference && (
            <div className={'width-33pct-sm'}>
                <ReadOnlyCarrierInput carrier={props.carrier} />
            </div>
        )}
    </div>
);

const TransportOrderNumberReadView = (props: { transportOrderNumber: string }) => (
    <div className={'display-flex-sm'}>
        <div className={'width-33pct-sm padding-right-15'}>
            <label>
                <FormattedMessage id={'webedi.shipment.transportOrderNumber'} />
            </label>
            <input className={'form-control'} disabled type={'text'} value={props.transportOrderNumber} />
        </div>
    </div>
);
