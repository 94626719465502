import { FormattedMessage, useIntl } from 'react-intl';
import { SuggestedLoadItem } from '../../reducers/suggestions/types';
import { ArticleNumber } from '../common/ArticleNumber';
import { ItemShortDescription } from '../common/ItemShortDescription';
import { Quantity } from '../common/Quantity';
import {
    getClassnameForRequestCondition,
    getTranslationKeyForRequestCondition,
} from '../common/RequestConditionDisplay';
import { Tooltip } from '../common/Tooltip';
import { getBuyerName } from '../deliverySchedules/BuyerAbbreviationsMappings';
import { SuggestedLoadTableProps } from './types';

const tableClassNames = [
    'table',
    'table-layout-fixed',
    'table-column-overflow-hidden',
    'table-head-filled',
    'margin-0',
].join(' ');

export const SuggestedLoadTable = (props: SuggestedLoadTableProps) => {
    const intl = useIntl();

    const renderTooltip = (text: string) => (
        <Tooltip text={text} extraClasses={'white-space-pre-line'} placement={'bottom'}>
            <span className={'rioglyph rioglyph-exclamation-sign text-warning text-size-18'} />
        </Tooltip>
    );

    const renderWarnings = (loadItem: SuggestedLoadItem) => {
        const invalidMasterDataMsg = intl.formatMessage({ id: 'webedi.articleSuggestion.invalidMasterData.label' });
        const invalidCumulativeQuantityMsg = intl.formatMessage({
            id: 'webedi.articleSuggestion.invalidCumulativeQuantity.label',
        });

        const hasInvalidMasterData = !loadItem.hasValidArticleMasterData;
        const hasInvalidCumulativeQuantitySent = !loadItem.hasValidCumulativeQuantitySent;

        if (hasInvalidMasterData && hasInvalidCumulativeQuantitySent) {
            return renderTooltip(`${invalidMasterDataMsg}\n${invalidCumulativeQuantityMsg}`);
        } else if (hasInvalidMasterData) {
            return renderTooltip(invalidMasterDataMsg);
        } else if (hasInvalidCumulativeQuantitySent) {
            return renderTooltip(invalidCumulativeQuantityMsg);
        } else {
            return undefined;
        }
    };

    const renderRow = (loadItem: SuggestedLoadItem) => {
        const classnameForRequestCondition = getClassnameForRequestCondition(loadItem.requestCondition);
        const translationKeyForRequestCondition = getTranslationKeyForRequestCondition(loadItem.requestCondition);

        const hasInvalidMasterData = !loadItem.hasValidArticleMasterData;
        const hasInvalidCumulativeQuantitySent = !loadItem.hasValidCumulativeQuantitySent;
        const hasWarnings = hasInvalidMasterData || hasInvalidCumulativeQuantitySent;

        return (
            <tr
                className={`${hasWarnings ? 'warning' : ''}`}
                key={loadItem.articleNumberBuyer}
                data-testid={SUGGESTED_LOAD_TABLE_ROW_TEST_ID}
            >
                <td className={'padding-right-25'}>{hasWarnings && renderWarnings(loadItem)}</td>
                <td className={'padding-left-25'}>
                    <ArticleNumber articleNumber={loadItem.articleNumberBuyer} />
                </td>
                <td>
                    <ArticleNumber articleNumber={loadItem.articleMasterData?.articleNumberSeller} />
                </td>
                <td className={'ellipsis-1'}>
                    {loadItem.itemShortDescriptions && (
                        <ItemShortDescription itemShortDescriptions={loadItem.itemShortDescriptions} />
                    )}
                </td>
                <td>
                    <div className={'display-flex align-items-center borderless-input no-control-arrows'}>
                        <Quantity
                            quantity={loadItem.amount.value}
                            measurementUnitCode={loadItem.amount.measurementUnitCode}
                        />
                    </div>
                </td>
                <td className={'padding-right-25'}>{loadItem.orderNumber}</td>
                <td className={'padding-right-25'}>{getBuyerName(props.shipmentSuggestion.manufacturingCompany)}</td>
                <td className={'padding-right-25'}>{props.shipmentSuggestion.shipFromId}</td>
                <td>
                    <span className={classnameForRequestCondition}>
                        {translationKeyForRequestCondition ? (
                            <FormattedMessage id={translationKeyForRequestCondition} />
                        ) : null}
                    </span>
                </td>
            </tr>
        );
    };

    return (
        <>
            <table className={tableClassNames} data-testid={SUGGESTED_LOAD_TABLE_TEST_ID}>
                <thead>
                    <tr>
                        <th className={'top-0 width-40'} />
                        <th className={'top-0 padding-left-25'}>
                            <FormattedMessage id={'webedi.articleNumber.buyer'} />
                        </th>
                        <th className={'top-0'}>
                            <FormattedMessage id={'webedi.articleNumber.seller'} />
                        </th>
                        <th className={'top-0'}>
                            <FormattedMessage id={'webedi.itemShortDescription'} />
                        </th>
                        <th className={'top-0'}>
                            <FormattedMessage id={'webedi.schedulingData.quantity'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.orderNumber'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.common.manufacturingCompany'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.common.shipFrom.identifier'} />
                        </th>
                        <th className={'top-0'}>
                            <FormattedMessage id={'webedi.schedulingData.requestCondition'} />
                        </th>
                    </tr>
                </thead>
                <tbody>{props.shipmentSuggestion.load.map((loadItem: SuggestedLoadItem) => renderRow(loadItem))}</tbody>
            </table>
        </>
    );
};

export const SUGGESTED_LOAD_TABLE_TEST_ID = 'SUGGESTED_LOAD_TABLE_TEST_ID';
export const SUGGESTED_LOAD_TABLE_ROW_TEST_ID = 'SUGGESTED_LOAD_TABLE_ROW_TEST_ID';
