import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { IdentityProvider, config } from '../../../../config';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getDunsNumber, isSupplier } from '../../../../configuration/tokenHandling/selectors';
import { fetchUserInfoFromApi } from '../../api/userInfo/userInfoCalls';
import { Routing } from '../routing/Routes';

export const USER_ID_TEXT = 'User ID';
export const DUNS_SELECTION_INPUT_TEST_ID = 'DUNS_SELECTION_INPUT_TEST_ID';
export const DUNS_SELECTION_BUTTON_TEST_ID = 'DUNS_SELECTION_BUTTON_TEST_ID';

enum IdentifierType {
    DUNS_NUMBER = 'DUNS_NUMBER',
    USER_ID = 'USER_ID',
}

const getUserInfoErrorMessageByStatusCode = (statusCode: number) => {
    if (statusCode === 400) {
        return <FormattedMessage id={'webedi.error.userInfo.notAllowed'} />;
    } else if (statusCode === 404) {
        return <FormattedMessage id={'webedi.error.userInfo.userNotFound'} />;
    } else {
        return <FormattedMessage id={'webedi.error.general'} />;
    }
};

export const DunsSelectionView = () => {
    const tokenDunsNumber = useAppSelector(getDunsNumber);
    const isSupplierUser = useAppSelector(isSupplier);
    const navigate = useNavigate();
    const isSupplerWithValidDunsNumber: boolean = isSupplierUser && tokenDunsNumber !== undefined;
    const [identifier, setIdentifier] = useState(isSupplerWithValidDunsNumber ? tokenDunsNumber : undefined);
    const [identifierType, setIdentifierType] = useState(IdentifierType.DUNS_NUMBER);
    const [disabled, setDisabled] = useState(false);

    const identifierIsValid = () => identifier !== undefined && identifier.trim().length > 0;

    useEffect(() => {
        if (isSupplerWithValidDunsNumber) {
            navigate(`${Routing.webScm}/${tokenDunsNumber}${Routing.deliverySchedules}`);
        }
    }, [isSupplerWithValidDunsNumber, tokenDunsNumber, navigate]);

    const redirectToDeliverySchedulesWithDuns = (duns: string) => {
        navigate(`${Routing.webScm}/${duns}${Routing.deliverySchedules}`);
    };

    const submitIdentifier = () => {
        if (identifierIsValid()) {
            if (identifierType === IdentifierType.DUNS_NUMBER) {
                redirectToDeliverySchedulesWithDuns(identifier!);
            } else {
                setDisabled(true);
                fetchUserInfoFromApi(identifier!)
                    .then((userInfo) => redirectToDeliverySchedulesWithDuns(userInfo.dunsNumber))
                    .catch((err: { statusCode: number }) => {
                        Notification.error(getUserInfoErrorMessageByStatusCode(err.statusCode));
                        setDisabled(false);
                    });
            }
        }
    };

    const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitIdentifier();
        }
    };

    const isRioIDP = config.identityProvider === IdentityProvider.RIO;

    const selectOptions = [
        {
            id: IdentifierType.DUNS_NUMBER,
            label: <FormattedMessage id={'webedi.dunsNumber'} />,
        },
        {
            id: IdentifierType.USER_ID,
            label: <div>{USER_ID_TEXT}</div>,
        },
    ];

    const dunsSelectionConfig = isRioIDP
        ? {
              headlineMessageId: 'webedi.dunsSelection.headline',
              descriptionMessageId: 'webedi.dunsSelection.description',
          }
        : {
              headlineMessageId: 'webedi.dunsSelection.headline.with.userId.option',
              descriptionMessageId: 'webedi.dunsSelection.description.with.userId.option',
          };

    return (
        <div className={'display-flex flex-column justify-content-center align-items-center height-100pct'}>
            <div className={'form-group width-40pct'}>
                <h5>
                    <FormattedMessage id={dunsSelectionConfig.headlineMessageId} values={{ userId: USER_ID_TEXT }} />
                </h5>
                <label>
                    <FormattedMessage id={dunsSelectionConfig.descriptionMessageId} values={{ userId: USER_ID_TEXT }} />
                </label>
                <div className='display-flex'>
                    {!isRioIDP && (
                        <div className='min-width-150'>
                            <Select
                                options={selectOptions}
                                disabled={disabled}
                                value={[identifierType]}
                                onChange={(item?: { id: string; label: ReactNode }) => {
                                    setIdentifierType(item?.id as IdentifierType);
                                }}
                            />
                        </div>
                    )}

                    <ClearableInput
                        value={identifier}
                        disabled={disabled}
                        onChange={setIdentifier}
                        maxLength={9}
                        onKeyPress={onKeyPress}
                        data-testid={DUNS_SELECTION_INPUT_TEST_ID}
                    />
                </div>
                <div className={'margin-top-20 text-right'}>
                    <button
                        disabled={disabled}
                        type={'button'}
                        className={`btn btn-primary ${!identifierIsValid() && 'disabled'}`}
                        onClick={submitIdentifier}
                        data-testid={DUNS_SELECTION_BUTTON_TEST_ID}
                    >
                        <FormattedMessage id={'webedi.next'} />
                    </button>
                </div>
            </div>
        </div>
    );
};
