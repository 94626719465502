import { FormattedMessage } from 'react-intl';
import { DeliveryScheduleFlag } from '../../reducers/deliverySchedules/types';

export interface DeliveryScheduleFlagsProps {
    flags: DeliveryScheduleFlag[];
}

export const DELIVERY_SCHEDULE_FLAGS_TEST_ID = 'DELIVERY_SCHEDULE_FLAGS_TEST_ID';

export const DeliveryScheduleFlags = (props: DeliveryScheduleFlagsProps) => {
    const getLabelAndClasses = (flags: DeliveryScheduleFlag[]): [string?, string?] => {
        if (flags.includes(DeliveryScheduleFlag.INVENTORY_TAKEN)) {
            return ['webedi.deliverySchedule.flag.rollback', 'badge-info'];
        }
        if (flags.includes(DeliveryScheduleFlag.NEW)) {
            return ['webedi.deliverySchedule.flag.new', 'badge-primary'];
        }
        if (flags.includes(DeliveryScheduleFlag.MODIFIED)) {
            return ['webedi.deliverySchedule.flag.update', 'badge-default'];
        }
        return [undefined, undefined];
    };

    const [label, badgeColor] = getLabelAndClasses(props.flags);
    if (label === undefined || badgeColor === undefined) {
        return null;
    }

    return (
        <span className={`badge ${badgeColor} margin-2`} data-testid={DELIVERY_SCHEDULE_FLAGS_TEST_ID}>
            <FormattedMessage id={label} />
        </span>
    );
};
