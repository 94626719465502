import { applyConfigForIdp, config } from './config';
import { reportErrorToSentry } from './configuration/setup/sentry';

applyConfigForIdp();
const setupTheme = () => {
    if (config.uikitCssUrl !== undefined) {
        const linkForUikitCssStyles = document.createElement('link');
        linkForUikitCssStyles.rel = 'stylesheet';
        linkForUikitCssStyles.type = 'text/css';
        linkForUikitCssStyles.href = config.uikitCssUrl;
        document.head.appendChild(linkForUikitCssStyles);
    } else {
        reportErrorToSentry('No uikitCssUrl provided in config');
    }
};

const setupHtmlTitle = () => {
    const titleElement = document.createElement('title');
    titleElement.innerText = config.htmlTitle;
    document.head.appendChild(titleElement);
};

setupHtmlTitle();
setupTheme();
