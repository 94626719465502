import { TransportOrderShipment } from '../../../domain/transportOrder.types';

export const ShipmentLink = (props: {
    dunsNumber: string | undefined;
    shipment: TransportOrderShipment;
    isActive: boolean;
}) => {
    const { dunsNumber, shipment, isActive } = props;
    return (
        <div className={'panel-heading open'}>
            <a
                href={`/webscm/${dunsNumber}/shipments/${shipment.id}`}
                target={'_blank'}
                rel='noreferrer'
                className={`title text-size-16 padding-0 text-decoration-none ${isActive ? 'text-color-lightest' : ''}`}
            >
                <span
                    className={`rioglyph rioglyph-order ${isActive ? 'text-color-lightest' : ''}`}
                    aria-hidden={'true'}
                />
                <span className={'text-capitalize'}> {shipment.shipmentNumber} </span>
            </a>
        </div>
    );
};
