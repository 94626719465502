import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export const ERROR_HELP_BLOCK_TEST_ID = 'ERROR_HELP_BLOCK_TEST_ID';

export const ErrorHelpBlock = (props: {
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<object>>;
    translations: Map<string, string>;
}) => {
    const { error, translations } = props;

    const getTranslation = (): string | undefined => {
        const type = error?.type;
        const message = error?.message;
        const translationByType = type ? translations.get(type) : undefined;
        const translationByMessage = message ? translations.get(message) : undefined;
        return translationByType ?? translationByMessage;
    };

    const translation = getTranslation();
    if (!translation) {
        return null;
    }

    return (
        <>
            <span className={'help-block'} data-testid={ERROR_HELP_BLOCK_TEST_ID}>
                <FormattedMessage id={translation} />
            </span>
            <span className={'form-control-feedback rioglyph rioglyph-error-sign'} />
        </>
    );
};
