import { FormattedMessage } from 'react-intl';
import { LoadItem } from '../../reducers/shipments/types';
import { FormattedDateOrDateTime } from '../common/i18n/FormattedDateOrDateTime';

export interface AdditionalPropertiesDisplayProps {
    loadItem: LoadItem;
    tooltip: boolean;
    testId: string;
}

export const AdditionalPropertiesDisplay = (props: AdditionalPropertiesDisplayProps) => {
    const labelClass = props.tooltip ? '' : 'text-color-gray';
    const spacer = props.tooltip ? <br /> : ' ';
    const additionalPropertyDisplay = (property: string, labelKey: string) => (
        <span key={labelKey}>
            <span className={'white-space-nowrap padding-right-5'}>
                <span className={labelClass}>
                    <FormattedMessage id={labelKey} />:
                </span>{' '}
                {property}
            </span>
            {spacer}
        </span>
    );

    const components = [];
    if (props.loadItem.batchNumber) {
        components.push(
            additionalPropertyDisplay(props.loadItem.batchNumber, 'webedi.shipment.additionalProperties.batchNumber'),
        );
    }
    if (props.loadItem.expiryDate) {
        components.push(
            <span key={'expiryDate'}>
                <span className={'white-space-nowrap padding-right-5'}>
                    <span className={labelClass}>
                        <FormattedMessage id={'webedi.shipment.additionalProperties.expiryDate'} />:
                    </span>{' '}
                    <FormattedDateOrDateTime date={props.loadItem.expiryDate} />
                </span>
                {spacer}
            </span>,
        );
    }
    if (props.loadItem.additionalProductId?.softwareStatus) {
        components.push(
            additionalPropertyDisplay(
                props.loadItem.additionalProductId.softwareStatus,
                'webedi.shipment.additionalProperties.additionalProductId.softwareStatus',
            ),
        );
    }
    if (props.loadItem.additionalProductId?.hardwareStatus) {
        components.push(
            additionalPropertyDisplay(
                props.loadItem.additionalProductId.hardwareStatus,
                'webedi.shipment.additionalProperties.additionalProductId.hardwareStatus',
            ),
        );
    }
    if (props.loadItem.additionalProductId?.partsGenerationStatus) {
        components.push(
            additionalPropertyDisplay(
                props.loadItem.additionalProductId.partsGenerationStatus,
                'webedi.shipment.additionalProperties.additionalProductId.partsGenerationStatus',
            ),
        );
    }
    if (props.loadItem.isSubjectToPreferenceAuthorization) {
        components.push(
            <span className={'white-space-nowrap'} key={'preferenceAuthorization'}>
                <span className={labelClass}>
                    <FormattedMessage id={'webedi.customs.preferenceAuthorization'} />:
                </span>{' '}
                <span className={'rioglyph rioglyph-checkbox-checked'} />
            </span>,
        );
    }
    return <span data-testid={props.testId}>{components}</span>;
};

export const ADDITIONAL_PROPERTIES_DISPLAY_TEST_ID = 'ADDITIONAL_PROPERTIES_DISPLAY_TEST_ID';
export const ADDITIONAL_PROPERTIES_DISPLAY_TOOLTIP_TEST_ID = 'ADDITIONAL_PROPERTIES_DISPLAY_TOOLTIP_TEST_ID';
