import { SelectOption } from '@rio-cloud/rio-uikit';
import { useEffect } from 'react';
import { State } from '../../../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import {
    dateTimeToLocalDate,
    isSameOrAfter,
    isSameOrBefore,
    localDateToLocalEndOfDayInUtc,
    localDateToLocalStartOfDayInUtc,
} from '../../../../dateUtils';
import { fetchAndStoreShipmentsFilterValues, getShipmentsAction } from '../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { ShipmentsQueryParams } from '../../reducers/shipments/types';
import { getShipmentsActiveFilters, getShipmentsFilterValues } from '../../selectors/shipments/Shipments.selector';
import { DateFilter } from '../common/filter/DateFilter';
import { FilterDropdown } from '../common/filter/FilterDropdown';

const PLANT_NUMBER_KEY = 'PLANT_NUMBER';
const PLACE_OF_DISCHARGE_KEY = 'PLACE_OF_DISCHARGE';

export const SHIPMENT_EXPORT_FROM_DATE_PICKER_TEST_ID = 'SHIPMENT_EXPORT_FROM_DATE_PICKER_TEST_ID';
export const SHIPMENT_EXPORT_TO_DATE_PICKER_TEST_ID = 'SHIPMENT_EXPORT_TO_DATE_PICKER_TEST_ID';

interface ShipmentsFilterRowProps {
    exported: boolean;
}

export const ShipmentsFilterRow = (props: ShipmentsFilterRowProps) => {
    const { exported } = props;
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath()!;
    const activeFilters = useAppSelector((state: State) => getShipmentsActiveFilters(state, exported));

    useEffect(() => {
        dispatch(fetchAndStoreShipmentsFilterValues(dunsNumber!, exported));
    }, [dunsNumber, dispatch, exported]);

    const onChange = (queryChange: Partial<ShipmentsQueryParams>) => {
        dispatch(getShipmentsAction(dunsNumber, exported, { offset: 0, ...queryChange }));
    };

    const filterValues = useAppSelector((state: State) => getShipmentsFilterValues(state, exported));

    const getFilterOptionsForKey = (key: string, currentValue: string | undefined): SelectOption[] => {
        const values = filterValues[key] || [];
        return values.map((value) => ({
            id: value,
            label: value,
            selected: value === currentValue,
        }));
    };

    const isValidDateExportedAtFrom = (currentDate?: string) => {
        if (!activeFilters.exportedAtTo) {
            return true;
        }
        if (currentDate === undefined) {
            return false;
        }
        const exportedAtTo = dateTimeToLocalDate(activeFilters.exportedAtTo);
        return isSameOrBefore(currentDate, exportedAtTo);
    };

    const isValidDateExportedAtTo = (currentDate?: string) => {
        if (!activeFilters.exportedAtFrom) {
            return true;
        }
        if (currentDate === undefined) {
            return false;
        }
        const exportedAtFrom = dateTimeToLocalDate(activeFilters.exportedAtFrom);
        return isSameOrAfter(currentDate, exportedAtFrom);
    };

    const filterClassName = 'flex-1-1 form-group margin-10 max-width-250';
    return (
        <>
            <div className={'display-flex flex-wrap justify-content-center'}>
                {exported && (
                    <>
                        <DateFilter
                            className={filterClassName}
                            labelId={'webedi.shipment.exportDateFrom'}
                            dataTestId={SHIPMENT_EXPORT_FROM_DATE_PICKER_TEST_ID}
                            selectedDate={
                                activeFilters.exportedAtFrom
                                    ? dateTimeToLocalDate(activeFilters.exportedAtFrom)
                                    : undefined
                            }
                            onChange={(exportedAtFrom) => {
                                onChange({
                                    exportedAtFrom: exportedAtFrom
                                        ? localDateToLocalStartOfDayInUtc(exportedAtFrom)
                                        : undefined,
                                });
                            }}
                            isValidDate={isValidDateExportedAtFrom}
                        />
                        <DateFilter
                            className={filterClassName}
                            labelId={'webedi.shipment.exportDateTo'}
                            dataTestId={SHIPMENT_EXPORT_TO_DATE_PICKER_TEST_ID}
                            selectedDate={
                                activeFilters.exportedAtTo ? dateTimeToLocalDate(activeFilters.exportedAtTo) : undefined
                            }
                            onChange={(exportedAtTo) => {
                                onChange({
                                    exportedAtTo: exportedAtTo
                                        ? localDateToLocalEndOfDayInUtc(exportedAtTo)
                                        : undefined,
                                });
                            }}
                            isValidDate={isValidDateExportedAtTo}
                        />
                    </>
                )}
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(PLANT_NUMBER_KEY, activeFilters.plantNumber)}
                    labelId={'webedi.plantIdentifier'}
                    onChange={(plantNumber) => onChange({ plantNumber })}
                />

                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(PLACE_OF_DISCHARGE_KEY, activeFilters.placeOfDischarge)}
                    labelId={'webedi.common.placeOfDischarge'}
                    onChange={(placeOfDischarge) => onChange({ placeOfDischarge })}
                />
            </div>
        </>
    );
};
