import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { LoadingLocation } from '../../reducers/loadingLocations/types';
import { decodeJson } from '../apiUtils';
import { LoadingLocationsCodec } from './loadingLocationData.types';
import { mapLoadingLocationToApi, mapLoadingLocations } from './loadingLocationMapper';

const transformLoadingLocationListApi = (response: unknown): LoadingLocation[] => {
    const decoded = decodeJson(LoadingLocationsCodec)(response);
    return mapLoadingLocations(decoded);
};

interface GetLoadingLocationsParameters {
    dunsNumber: string;
}

interface DeleteLoadingLocationParameters {
    dunsNumber: string;
    loadingLocationId: string;
}

export const LOADING_LOCATIONS_TAG = 'LoadingLocations';

export const loadingLocationApi = createApi({
    reducerPath: 'loadingLocationApi',
    tagTypes: [LOADING_LOCATIONS_TAG],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getLoadingLocations: builder.query<LoadingLocation[], GetLoadingLocationsParameters>({
            query: ({ dunsNumber }) => {
                return {
                    url: `/shippers/${dunsNumber}/loading-locations`,
                };
            },
            providesTags: [{ type: LOADING_LOCATIONS_TAG }],
            transformResponse: (response: unknown) => transformLoadingLocationListApi(response),
        }),
        putLoadingLocation: builder.mutation<undefined, LoadingLocation>({
            query: (loadingLocation: LoadingLocation) => {
                const body = mapLoadingLocationToApi(loadingLocation);
                const etag = loadingLocation.etag;

                const additionalHeaders = { ...(etag && { 'If-Match': etag }) };
                return {
                    url: `/shippers/${loadingLocation.dunsNumberOwner}/loading-locations/${loadingLocation.id}`,
                    method: 'PUT',
                    body,
                    headers: additionalHeaders,
                };
            },
            invalidatesTags: () => [{ type: LOADING_LOCATIONS_TAG }],
        }),
        deleteLoadingLocation: builder.mutation<undefined, DeleteLoadingLocationParameters>({
            query: (params: DeleteLoadingLocationParameters) => ({
                url: `/shippers/${params.dunsNumber}/loading-locations/${params.loadingLocationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: LOADING_LOCATIONS_TAG }],
        }),
    }),
});

export const { useGetLoadingLocationsQuery, usePutLoadingLocationMutation, useDeleteLoadingLocationMutation } =
    loadingLocationApi;
