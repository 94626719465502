import { FieldRenderProps } from 'react-final-form';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';

export interface RadioButtonInputProps extends FieldRenderProps<string> {
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
}

export const RadioButtonInput = (props: RadioButtonInputProps) => {
    const { input, ...rest } = props;
    const dataTestId = rest[DATA_TEST_ID_PROPERTY_NAME];

    if (!props.children) {
        throw Error('Must provide a children property to the field component in order to add the respective label.');
    }

    return (
        <div className={'radio'}>
            <label>
                <input type={'radio'} {...input} data-testid={dataTestId} />
                <span className={'radio-text'}>{props.children}</span>
            </label>
        </div>
    );
};
