import { LoadItemPositionReference } from '../../../../utils';
import { HandlingUnitCategory } from '../../components/common/PackagingCommon';
import { AuxiliaryHandlingUnitGroup, AuxiliaryPackaging } from '../../reducers/auxiliaryPackaging.types';
import {
    HandlingUnit,
    HandlingUnitGroup,
    HandlingUnitGroupUpdate,
    HandlingUnitUpdate,
    PackagedArticleGroup,
    PackagedArticleGroupUpdate,
    PackagingConfig,
    PackagingTemplateConfigToApply,
    PackagingTemplateForShipment,
    PackagingTemplatesDictionary,
    isPackagedArticleGroup,
} from '../../reducers/shipments/packaging.types';
import { DeliveryNote } from '../../reducers/shipments/types';
import { mapDimensions, mapDimensionsToApi } from '../shared/sharedMappers';
import {
    ApiApplicablePackagingTemplate,
    ApiApplicablePackagingTemplates,
    ApiAuxiliaryHandlingUnitGroup,
    ApiAuxiliaryPackaging,
    ApiHandlingUnit,
    ApiHandlingUnitGroup,
    ApiHandlingUnitGroupUpdate,
    ApiHandlingUnitUpdate,
    ApiPackagedArticleGroup,
    ApiPackagedArticleGroupUpdate,
    ApiPackagingConfigFromTemplates,
    ApiPackagingTemplateConfigsToApply,
    isApiPackagedArticleGroup,
    isApiPackagedArticleGroupUpdate,
} from './packaging.types';

export const mapHandlingUnitGroup = (
    apiHandlingUnitGroup: ApiHandlingUnitGroup,
    load: DeliveryNote[],
): HandlingUnitGroup => ({
    quantity: apiHandlingUnitGroup.quantity,
    handlingUnit: mapHandlingUnit(apiHandlingUnitGroup.handling_unit, load),
});

export const mapHandlingUnitGroupUpdate = (
    apiHandlingUnitGroup: ApiHandlingUnitGroupUpdate,
    load: DeliveryNote[],
): HandlingUnitGroupUpdate => ({
    quantity: apiHandlingUnitGroup.quantity,
    handlingUnit: mapHandlingUnitUpdateApiToHandlingUnitUpdate(apiHandlingUnitGroup.handling_unit, load),
});

const mapHandlingUnit = (apiHandlingUnit: ApiHandlingUnit, load: DeliveryNote[]): HandlingUnit => ({
    id: apiHandlingUnit.id,
    type: apiHandlingUnit.type,
    description: apiHandlingUnit.description,
    category: determineHandlingUnitCategory(apiHandlingUnit),
    labelNumber: apiHandlingUnit.label_number,
    contents: apiHandlingUnit.contents.map((content) =>
        isApiPackagedArticleGroup(content) ? mapPackagedArticle(content, load) : mapHandlingUnitGroup(content, load),
    ),
    auxiliaryPackaging: apiHandlingUnit.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
    isReusable: apiHandlingUnit.is_reusable,
    ownership: apiHandlingUnit.ownership,
    stackingFactor: apiHandlingUnit.stacking_factor,
    tareWeightInKg: apiHandlingUnit.tare_weight_in_kg,
    netWeightInKg: apiHandlingUnit.net_weight_in_kg,
    grossWeightInKg: apiHandlingUnit.gross_weight_in_kg,
});

const mapHandlingUnitUpdateApiToHandlingUnitUpdate = (
    apiHandlingUnitUpdate: ApiHandlingUnitUpdate,
    load: DeliveryNote[],
): HandlingUnitUpdate => ({
    id: apiHandlingUnitUpdate.id,
    type: apiHandlingUnitUpdate.type,
    description: apiHandlingUnitUpdate.description,
    category: determineHandlingUnitCategory(apiHandlingUnitUpdate),
    labelNumber: apiHandlingUnitUpdate.label_number,
    contents: apiHandlingUnitUpdate.contents.map((content) =>
        isApiPackagedArticleGroupUpdate(content)
            ? mapPackagedArticleUpdate(content, load)
            : mapHandlingUnitGroupUpdate(content, load),
    ),
    auxiliaryPackaging: apiHandlingUnitUpdate.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
    isReusable: apiHandlingUnitUpdate.is_reusable,
    ownership: apiHandlingUnitUpdate.ownership,
    dimensions: mapDimensions(apiHandlingUnitUpdate.dimensions),
    stackingFactor: apiHandlingUnitUpdate.stacking_factor,
    tareWeightInKg: apiHandlingUnitUpdate.tare_weight_in_kg,
});

export const determineHandlingUnitCategory = (
    handlingUnit: ApiHandlingUnit | ApiHandlingUnitUpdate,
): HandlingUnitCategory => {
    if (handlingUnit.contents.length === 0) {
        return HandlingUnitCategory.PACKAGING_AID;
    }
    return isApiPackagedArticleGroup(handlingUnit.contents[0])
        ? HandlingUnitCategory.PARTS_CONTAINER
        : HandlingUnitCategory.LOAD_CARRIER;
};

const mapPackagedArticle = (
    apiPackagedArticle: ApiPackagedArticleGroup,
    load: DeliveryNote[],
): PackagedArticleGroup => {
    const matchingDeliveryNote = load.find(
        (deliveryNote) => deliveryNote.deliveryNoteNumber === apiPackagedArticle.delivery_note_number,
    )!;
    const loadItem = matchingDeliveryNote.loadItems[apiPackagedArticle.delivery_note_position - 1];

    return {
        loadItemId: loadItem.id,
        quantity: apiPackagedArticle.quantity,
        netWeightInKg: apiPackagedArticle.net_weight_in_kg,
    };
};

const mapPackagedArticleUpdate = (
    apiPackagedArticle: ApiPackagedArticleGroupUpdate,
    load: DeliveryNote[],
): PackagedArticleGroupUpdate => {
    const matchingDeliveryNote = load.find(
        (deliveryNote) => deliveryNote.deliveryNoteNumber === apiPackagedArticle.delivery_note_number,
    )!;
    const loadItem = matchingDeliveryNote.loadItems[apiPackagedArticle.delivery_note_position - 1];

    return {
        loadItemId: loadItem.id,
        quantity: apiPackagedArticle.quantity,
    };
};

export const mapAuxiliaryHandlingUnitGroup = (
    apiAuxiliaryHandlingUnitGroup: ApiAuxiliaryHandlingUnitGroup,
): AuxiliaryHandlingUnitGroup => ({
    quantity: apiAuxiliaryHandlingUnitGroup.quantity,
    auxiliaryPackagingContent: mapAuxiliaryPackaging(apiAuxiliaryHandlingUnitGroup.auxiliary_packaging_content),
});

const mapAuxiliaryPackaging = (apiAuxiliaryPackaging: ApiAuxiliaryPackaging): AuxiliaryPackaging => ({
    type: apiAuxiliaryPackaging.type,
    description: apiAuxiliaryPackaging.description,
    auxiliaryPackaging: apiAuxiliaryPackaging.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
    isReusable: apiAuxiliaryPackaging.is_reusable,
    ownership: apiAuxiliaryPackaging.ownership,
    stackingFactor: apiAuxiliaryPackaging.stacking_factor,
    tareWeightInKg: apiAuxiliaryPackaging.tare_weight_in_kg,
});

// TO API

export const mapToHandlingUnitGroupUpdateApi = (
    handlingUnitGroup: HandlingUnitGroup,
    idsMap: Map<string, LoadItemPositionReference>,
    packagingOuterDimensions: NonNullable<PackagingConfig['packagingOuterDimensions']>,
): ApiHandlingUnitGroupUpdate => ({
    quantity: handlingUnitGroup.quantity,
    handling_unit: mapToHandlingUnitUpdateApi(handlingUnitGroup.handlingUnit, idsMap, packagingOuterDimensions),
});

const mapToHandlingUnitUpdateApi = (
    handlingUnit: HandlingUnit,
    idsMap: Map<string, LoadItemPositionReference>,
    packagingOuterDimensions: NonNullable<PackagingConfig['packagingOuterDimensions']>,
): ApiHandlingUnitUpdate => ({
    id: handlingUnit.id,
    type: handlingUnit.type,
    description: handlingUnit.description,
    label_number: handlingUnit.labelNumber,
    contents: handlingUnit.contents.map((content) =>
        isPackagedArticleGroup(content)
            ? mapPackagedArticleGroupToApi(content, idsMap)
            : mapToHandlingUnitGroupUpdateApi(content, idsMap, packagingOuterDimensions),
    ),
    auxiliary_packaging: handlingUnit.auxiliaryPackaging.map(mapAuxiliaryPackagingToApi),
    is_reusable: handlingUnit.isReusable,
    ownership: handlingUnit.ownership,
    dimensions: mapDimensionsToApi(packagingOuterDimensions[handlingUnit.id]),
    stacking_factor: handlingUnit.stackingFactor,
    tare_weight_in_kg: handlingUnit.tareWeightInKg,
});

const mapPackagedArticleGroupToApi = (
    packagedArticle: PackagedArticleGroup,
    idsMap: Map<string, LoadItemPositionReference>,
): ApiPackagedArticleGroup => {
    const loadItemPositionReference = idsMap.get(packagedArticle.loadItemId);
    if (!loadItemPositionReference) {
        throw new Error(`No LoadItem with id=${packagedArticle.loadItemId} found in map=${JSON.stringify(idsMap)}`);
    }
    return {
        delivery_note_number: loadItemPositionReference.deliveryNoteNumber,
        delivery_note_position: loadItemPositionReference.position,
        quantity: packagedArticle.quantity,
    };
};

export const mapAuxiliaryPackagingToApi = (
    auxiliaryHandlingUnitGroup: AuxiliaryHandlingUnitGroup,
): ApiAuxiliaryHandlingUnitGroup => ({
    quantity: auxiliaryHandlingUnitGroup.quantity,
    auxiliary_packaging_content: mapAuxiliaryPackagingContentToApi(
        auxiliaryHandlingUnitGroup.auxiliaryPackagingContent,
    ),
});

const mapAuxiliaryPackagingContentToApi = (auxiliaryPackagingContent: AuxiliaryPackaging): ApiAuxiliaryPackaging => ({
    type: auxiliaryPackagingContent.type,
    description: auxiliaryPackagingContent.description,
    auxiliary_packaging: auxiliaryPackagingContent.auxiliaryPackaging.map(mapAuxiliaryPackagingToApi),
    is_reusable: auxiliaryPackagingContent.isReusable,
    ownership: auxiliaryPackagingContent.ownership,
    stacking_factor: auxiliaryPackagingContent.stackingFactor,
    tare_weight_in_kg: auxiliaryPackagingContent.tareWeightInKg,
});

export const mapPackagingTemplatesForShipment = (
    apiPackagingTemplatesForShipment: ApiApplicablePackagingTemplates,
): PackagingTemplatesDictionary =>
    Object.fromEntries(
        Object.entries(apiPackagingTemplatesForShipment.items).map(([key, value]) => [
            key,
            mapApplicablePackagingTemplate(value),
        ]),
    );

const mapApplicablePackagingTemplate = (
    applicablePackagingTemplates: ApiApplicablePackagingTemplate[],
): PackagingTemplateForShipment[] =>
    applicablePackagingTemplates.map((value) => ({
        id: value.id,
        name: value.name,
        type: value.type,
    }));

export const mapPackagingTemplateConfigsToApplyToApi = (
    configsToApply: PackagingTemplateConfigToApply[],
): ApiPackagingTemplateConfigsToApply => ({
    items: configsToApply.map((configToApply) => ({
        identifier: {
            delivery_note_number: configToApply.identifier.deliveryNoteNumber,
            delivery_note_position: configToApply.identifier.deliveryNotePosition,
        },
        quantity: configToApply.quantity,
        template_id: configToApply.templateId,
    })),
});

export const mapPackagingConfigFromTemplates = (
    packagingConfigFromTemplates: ApiPackagingConfigFromTemplates,
    load: DeliveryNote[],
): HandlingUnitGroupUpdate[] =>
    packagingConfigFromTemplates.items.map((handlingUnitGroup) => mapHandlingUnitGroupUpdate(handlingUnitGroup, load));
