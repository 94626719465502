import { createSelector } from '@reduxjs/toolkit';
import { State } from '../../../../configuration/setup/store';
import { LoadItemPositionReference, hashPackagingTree, roundOffFloatingPointErrors } from '../../../../utils';
import {
    ArticleInPackageConfig,
    HandlingUnitInPackageConfig,
    PackageableInPackageConfig,
    UnpackagedArticle,
} from '../../components/shipments/packaging/packagingEditView/types';
import { LayerStabilizationInformation } from '../../reducers/auxiliaryPackaging.types';
import { ValidPackagingMaterial } from '../../reducers/packaging/types';
import {
    GroupedHandlingUnits,
    HandlingUnitGroup,
    HandlingUnitPackagingMaterial,
    Packageable,
    PackagedArticleGroup,
    PackagedArticleInfo,
    PackagingConfig,
    PackagingTemplatesDictionary,
    PackagingWizard,
    UpdateHeterogeneousAdditionalPackagingConfiguration,
    UpdateHomogeneousAdditionalPackagingConfiguration,
    UpdateInnerPackagingConfigurationStepConfiguration,
    UpdateInnerPackagingPackagingMaterialSelectionStepConfiguration,
    UpdateOuterPackagingPackagingMaterialSelectionStepConfiguration,
    UpdatePackagingConfiguration,
    UpdatePackagingConfigurationType,
    isPackagedArticleGroup,
} from '../../reducers/shipments/packaging.types';
import { LoadItem, Shipment } from '../../reducers/shipments/types';

import {
    getLoadItemIdToDeliveryNoteNumberAndPositionMapForSelectedShipment,
    getLoadItemMapForSelectedShipment,
    getSelectedShipment,
    getSelectedShipmentId,
} from '../shipments/Shipments.selector';
import { getPackagingMaterial } from './PackagingMaterial.selector';

const getPackagingSlice = (state: State) => state.webEdi.packaging;

const getIdOfLoadItemToPackage = (state: State) => {
    return getPackagingSlice(state).idOfLoadItemToPackage;
};

export const getPackagingForSelectedShipment = (state: State): HandlingUnitGroup[] => {
    const selectedShipmentId = getSelectedShipmentId(state);
    const packaging = selectedShipmentId ? getPackagingSlice(state).config[selectedShipmentId]?.packaging : undefined;
    if (selectedShipmentId === undefined || packaging === undefined) {
        throw new Error('No packaging for selected shipment found. This should never happen');
    }
    return packaging;
};

export const hasPackagingConfigurationForSelectedShipmentChanged = (state: State): boolean => {
    const selectedShipmentId = getSelectedShipmentId(state);
    const hasUpdated = selectedShipmentId ? getPackagingSlice(state).config[selectedShipmentId]?.hasUpdated : undefined;
    if (selectedShipmentId === undefined || hasUpdated === undefined) {
        throw new Error('No updateStatus for selected shipment found. This should never happen');
    }
    return hasUpdated;
};

export const getLoadItemIdToPackagedArticleMapForSelectedShipment = createSelector(
    [getPackagingForSelectedShipment],
    (packaging): Map<string, PackagedArticleGroup> => {
        return groupAllPackagedArticleGroups(packaging);
    },
);

const groupAllPackagedArticleGroups = (packaging: HandlingUnitGroup[]): Map<string, PackagedArticleGroup> => {
    const traverse = (packageables: Packageable[]): PackagedArticleGroup[] => {
        return packageables.flatMap((packageable) => {
            return isPackagedArticleGroup(packageable)
                ? [packageable]
                : traverse(packageable.handlingUnit.contents).map((innerPackageable) => ({
                      ...innerPackageable,
                      quantity: innerPackageable.quantity * packageable.quantity,
                  }));
        });
    };

    const mergeIdentical = (articles: PackagedArticleGroup[]): Map<string, PackagedArticleGroup> => {
        const idToArticleMap = new Map<string, PackagedArticleGroup>();
        articles.forEach((article) => {
            if (idToArticleMap.has(article.loadItemId)) {
                idToArticleMap.get(article.loadItemId)!.quantity += article.quantity;
            } else {
                idToArticleMap.set(article.loadItemId, article);
            }
        });
        return idToArticleMap;
    };

    return mergeIdentical(traverse(packaging));
};

const collectPackagedArticlesInfo = (
    handlingUnitGroup: HandlingUnitGroup,
    deliveryNoteNumberAndPositionMap: Map<string, LoadItemPositionReference>,
    loadItemMap: Map<string, LoadItem>,
): PackagedArticleInfo[] => {
    const groupedPackagedArticleGroups = groupAllPackagedArticleGroups([handlingUnitGroup]);
    const packagedArticlesInfo: PackagedArticleInfo[] = [];
    groupedPackagedArticleGroups.forEach((packagedArticleGroup, loadItemId) => {
        const loadItem = loadItemMap.get(loadItemId);
        const loadItemPositionReference = deliveryNoteNumberAndPositionMap.get(loadItemId);
        if (loadItem && loadItemPositionReference) {
            packagedArticlesInfo.push({
                articleNumberBuyer: loadItem.articleNumberBuyer,
                amount: {
                    value: packagedArticleGroup.quantity,
                    measurementUnitCode: loadItem.amount.measurementUnitCode,
                },
                loadItemPositionReference,
            });
        }
    });
    return packagedArticlesInfo;
};

export const getUnpackagedArticlesForSelectedShipment = createSelector(
    [getLoadItemIdToPackagedArticleMapForSelectedShipment, getSelectedShipment],
    (packagedArticleMap, shipment): UnpackagedArticle[] => {
        if (shipment === undefined) {
            throw new Error('No selected shipment found. This should never happen');
        }
        return shipment.load
            .flatMap((deliveryNote) =>
                deliveryNote.loadItems.map((loadItem, index): UnpackagedArticle => {
                    const packagedArticle = packagedArticleMap.get(loadItem.id);
                    const packagedQuantityValue = packagedArticle ? packagedArticle.quantity : 0;
                    const unpackagedQuantityValue = roundOffFloatingPointErrors(
                        loadItem.amount.value - packagedQuantityValue,
                    );
                    return {
                        loadItemId: loadItem.id,
                        articleNumberBuyer: loadItem.articleNumberBuyer,
                        deliveryNoteNumber: deliveryNote.deliveryNoteNumber,
                        position: index + 1,
                        unpackagedQuantityValue,
                        measurementUnitCode: loadItem.amount.measurementUnitCode,
                        packagedQuantityValue,
                    };
                }),
            )
            .filter((unpackagedArticle) => unpackagedArticle.unpackagedQuantityValue > 0);
    },
);

export const getArticleToBePackaged = createSelector(
    [getUnpackagedArticlesForSelectedShipment, getIdOfLoadItemToPackage],
    (unpackagedArticles, idOfLoadItemToPackage): UnpackagedArticle | undefined => {
        return unpackagedArticles.find((unpackagedArticle) => unpackagedArticle.loadItemId === idOfLoadItemToPackage);
    },
);

export const getDescriptionForHandlingUnitType = (type: string, state: State): string | undefined =>
    getPackagingMaterial(state, type).packagingMaterial?.name;

export const getEnrichedPackagingDataForSelectedShipment = createSelector(
    [
        getLoadItemIdToDeliveryNoteNumberAndPositionMapForSelectedShipment,
        getLoadItemMapForSelectedShipment,
        getPackagingForSelectedShipment,
        (state) => state,
    ],
    (loadItemPositionReferenceMap, loadItemMap, packaging, state): HandlingUnitInPackageConfig[] => {
        const mapPackagedArticleGroupToArticleInPackageConfig = (
            packagedArticleGroup: PackagedArticleGroup,
        ): ArticleInPackageConfig => {
            const loadItemPositionReference = loadItemPositionReferenceMap.get(packagedArticleGroup.loadItemId);
            const articleNumberBuyer = loadItemMap.get(packagedArticleGroup.loadItemId)?.articleNumberBuyer;
            const amount = loadItemMap.get(packagedArticleGroup.loadItemId)?.amount;
            if (loadItemPositionReference === undefined || articleNumberBuyer === undefined) {
                throw new Error('Packaged load item not found in load. Shipment is in inconsistent state.');
            }
            return {
                articleDescription: '', // TODO WEBEDI-548
                deliveryNoteNumber: loadItemPositionReference.deliveryNoteNumber,
                deliveryNotePosition: loadItemPositionReference.position,
                quantity: packagedArticleGroup.quantity,
                articleNumber: articleNumberBuyer,
                articleNetWeightInKg: packagedArticleGroup.netWeightInKg,
                articleMeasurementUnitCode: amount?.measurementUnitCode,
            };
        };

        const mapHandlingUnitGroupToHandlingUnitInPackageConfigs = (
            handlingUnitGroup: HandlingUnitGroup,
        ): HandlingUnitInPackageConfig[] => {
            const cachedDescription: string | undefined = getDescriptionForHandlingUnitType(
                handlingUnitGroup.handlingUnit.type,
                state,
            );
            return Array(handlingUnitGroup.quantity).fill({
                id: handlingUnitGroup.handlingUnit.id,
                type: handlingUnitGroup.handlingUnit.type,
                description: handlingUnitGroup.handlingUnit.description
                    ? handlingUnitGroup.handlingUnit.description
                    : cachedDescription,
                category: handlingUnitGroup.handlingUnit.category,
                labelNumber: handlingUnitGroup.handlingUnit.labelNumber,
                contents: handlingUnitGroup.handlingUnit.contents.flatMap((packageable) =>
                    mapPackageableToPackageableInPackageConfig(packageable),
                ),
                auxiliaryPackaging: handlingUnitGroup.handlingUnit.auxiliaryPackaging,
                isReusable: handlingUnitGroup.handlingUnit.isReusable,
                ownership: handlingUnitGroup.handlingUnit.ownership,
                stackingFactor: handlingUnitGroup.handlingUnit.stackingFactor,
                tareWeightInKg: handlingUnitGroup.handlingUnit.tareWeightInKg,
                netWeightInKg: handlingUnitGroup.handlingUnit.netWeightInKg,
                grossWeightInKg: handlingUnitGroup.handlingUnit.grossWeightInKg,
            } as HandlingUnitInPackageConfig);
        };

        const mapPackageableToPackageableInPackageConfig = (packageable: Packageable): PackageableInPackageConfig[] => {
            return isPackagedArticleGroup(packageable)
                ? [mapPackagedArticleGroupToArticleInPackageConfig(packageable)]
                : mapHandlingUnitGroupToHandlingUnitInPackageConfigs(packageable);
        };

        return packaging.flatMap((handlingUnitGroup) =>
            mapHandlingUnitGroupToHandlingUnitInPackageConfigs(handlingUnitGroup),
        );
    },
);

export const getGroupedHandlingUnitsForSelectedShipment = createSelector(
    [
        getPackagingForSelectedShipment,
        getLoadItemIdToDeliveryNoteNumberAndPositionMapForSelectedShipment,
        getLoadItemMapForSelectedShipment,
    ],
    (packaging, loadItemPositionReferenceMap, loadItemMap): GroupedHandlingUnits[] => {
        const hashToGroupedHandlingUnits = new Map<string, GroupedHandlingUnits>();
        packaging.forEach((handlingUnitGroup) => {
            const hashValue = hashPackagingTree(handlingUnitGroup);
            const packagedArticlesInfo = collectPackagedArticlesInfo(
                handlingUnitGroup,
                loadItemPositionReferenceMap,
                loadItemMap,
            );
            const groupedHandlingUnits = hashToGroupedHandlingUnits.get(hashValue);
            if (groupedHandlingUnits === undefined) {
                const handlingUnit = {
                    type: handlingUnitGroup.handlingUnit.type,
                    description: handlingUnitGroup.handlingUnit.description,
                    isReusable: handlingUnitGroup.handlingUnit.isReusable,
                    ownership: handlingUnitGroup.handlingUnit.ownership,
                    tareWeightInKg: handlingUnitGroup.handlingUnit.tareWeightInKg,
                    stackingFactor: handlingUnitGroup.handlingUnit.stackingFactor,
                };
                hashToGroupedHandlingUnits.set(hashValue, {
                    type: handlingUnitGroup.handlingUnit.type,
                    category: handlingUnitGroup.handlingUnit.category,
                    quantity: handlingUnitGroup.quantity,
                    description: handlingUnitGroup.handlingUnit.description,
                    packagedArticlesInfo,
                    hash: hashValue,
                    handlingUnit,
                });
            } else {
                groupedHandlingUnits.quantity += handlingUnitGroup.quantity;
            }
        });
        return Array.from(hashToGroupedHandlingUnits.values());
    },
);

export const getUpdatePackagingConfig = (state: State): UpdatePackagingConfiguration | undefined => {
    return state.webEdi.packaging.updatePackagingConfiguration;
};

export const getNewPackagingFromWizardState = createSelector(
    [getUpdatePackagingConfig],
    (updatePackagingConfiguration): GroupedHandlingUnits => {
        if (updatePackagingConfiguration === undefined) {
            throw new Error('No UpdatePackagingConfiguration found. This should never happen');
        }
        if (updatePackagingConfiguration.type === UpdatePackagingConfigurationType.INNER_PACKAGING) {
            const updatePackagingConfigurationInnerPackaging =
                updatePackagingConfiguration as UpdateInnerPackagingConfigurationStepConfiguration;
            const handlingUnitGroup = updatePackagingConfigurationInnerPackaging.handlingUnitGroups[0];
            const handlingUnit = {
                type: handlingUnitGroup.handlingUnit.type,
                description: handlingUnitGroup.handlingUnit.description,
                tareWeightInKg: handlingUnitGroup.handlingUnit.tareWeightInKg,
                ownership: handlingUnitGroup.handlingUnit.ownership,
                isReusable: handlingUnitGroup.handlingUnit.isReusable,
                stackingFactor: handlingUnitGroup.handlingUnit.stackingFactor,
            };
            return {
                type: handlingUnit.type,
                category: handlingUnitGroup.handlingUnit.category,
                quantity: updatePackagingConfigurationInnerPackaging.handlingUnitGroups.length,
                description: handlingUnit.description,
                packagedArticlesInfo: [],
                hash: '',
                handlingUnit,
            };
        }
        const updatePackagingConfigurationOuterPackaging = updatePackagingConfiguration as
            | UpdateHomogeneousAdditionalPackagingConfiguration
            | UpdateHeterogeneousAdditionalPackagingConfiguration;
        const handlingUnit = {
            type: updatePackagingConfigurationOuterPackaging.handlingUnit.type,
            description: updatePackagingConfigurationOuterPackaging.handlingUnit.description,
            tareWeightInKg: updatePackagingConfigurationOuterPackaging.handlingUnit.tareWeightInKg,
            ownership: updatePackagingConfigurationOuterPackaging.handlingUnit.ownership,
            isReusable: updatePackagingConfigurationOuterPackaging.handlingUnit.isReusable,
            stackingFactor: updatePackagingConfigurationOuterPackaging.handlingUnit.stackingFactor,
        };
        return {
            type: handlingUnit.type,
            category: updatePackagingConfigurationOuterPackaging.handlingUnitCategory,
            quantity: updatePackagingConfigurationOuterPackaging.numberOfHandlingUnits,
            description: handlingUnit.description,
            packagedArticlesInfo: [],
            hash: '',
            handlingUnit,
        };
    },
);

export const getSelectedGroupedHandlingUnits = (state: State): GroupedHandlingUnits[] =>
    getPackagingSlice(state).selectedGroupedHandlingUnits;

export const getLayerStabilizationInformation = createSelector(
    [getUpdatePackagingConfig],
    (updatePackagingConfig): LayerStabilizationInformation | undefined => {
        if (
            updatePackagingConfig === undefined ||
            updatePackagingConfig.type !== UpdatePackagingConfigurationType.HOMOGENEOUS_ADDITIONAL_PACKAGING ||
            updatePackagingConfig.numberOfPackagedHandlingUnits %
                updatePackagingConfig.numberOfHandlingUnitsPerLayer ===
                0
        ) {
            return undefined;
        }
        return {
            type: updatePackagingConfig.contentPerHandlingUnit.handlingUnitType,
            freeSpots:
                updatePackagingConfig.numberOfHandlingUnitsPerLayer -
                (updatePackagingConfig.numberOfPackagedHandlingUnits %
                    updatePackagingConfig.numberOfHandlingUnitsPerLayer),
            handlingUnit: updatePackagingConfig.contentPerHandlingUnit.handlingUnit,
        };
    },
);

export const getPackagingWizardInfo = (state: State): PackagingWizard | undefined => {
    return state.webEdi.packaging.packagingWizard;
};

export const getPackagingTemplates = (state: State): PackagingTemplatesDictionary => {
    return state.webEdi.packaging.templates;
};

export const getNextAvailableLabelNumber = (state: State): number | undefined => {
    return state.webEdi.packaging.nextAvailableLabelNumber;
};

export const getPackagingMaterialForPackagingConfiguration = (state: State): ValidPackagingMaterial => {
    const updatePackagingConfiguration = state.webEdi.packaging.updatePackagingConfiguration as
        | UpdateInnerPackagingPackagingMaterialSelectionStepConfiguration
        | UpdateOuterPackagingPackagingMaterialSelectionStepConfiguration;

    if (updatePackagingConfiguration === undefined) {
        throw new Error('This can never happen, because it should have been set in the previous step.');
    }

    return updatePackagingConfiguration.packagingMaterial;
};

// TODO: RIOINBBL-1556 should return ValidPackagingMaterial, basically the same as HandlingUnitPackagingMaterial but
// TODO: RIOINBBL-1556 already used in the ad-hoc packaging which makes the HandlingUnitPackagingMaterial redundant here
export const getPackagingMaterialForPackagingTemplateConfiguration = (state: State): HandlingUnitPackagingMaterial => {
    const updatePackagingTemplateConfiguration = state.webEdi.deliverySchedules.form.templateStepConfiguration;

    if (updatePackagingTemplateConfiguration === undefined) {
        throw new Error('This can never happen, because it should have been set in the previous step.');
    }

    return updatePackagingTemplateConfiguration.handlingUnit;
};

export const getPackagingConfigForShipment = (state: State, shipmentId: Shipment['id']): PackagingConfig | undefined =>
    getPackagingSlice(state).config[shipmentId];
