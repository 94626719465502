import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import {
    AutoSuggestSuggestion,
    SelectedSuggestion,
} from '@rio-cloud/rio-uikit/lib/es/components/autosuggest/AutoSuggest';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetPlacesOfDischargeMasterDataQuery } from '../../api/masterData/placesOfDischargeMasterDataApi';
import { PlaceOfDischargeMasterData } from '../../domain/placeOfDischargeMasterData.types';

interface PlaceOfDischargeSelectProps {
    onChange: (placeOfDischarge: PlaceOfDischargeMasterData | undefined) => void;
    eligibleForKep?: boolean;
    displayValue?: PlaceOfDischargeMasterData;
    id?: string;
}

type PlaceOfDischargeSuggestion = AutoSuggestSuggestion & PlaceOfDischargeMasterData;

export const PlaceOfDischargeSelect = (props: PlaceOfDischargeSelectProps) => {
    const { data: placesOfDischarge } = useGetPlacesOfDischargeMasterDataQuery({
        eligibleForKep: props.eligibleForKep,
    });
    const formatPlaceOfDischarge = (placeOfDischarge: PlaceOfDischargeMasterData) =>
        `${placeOfDischarge.placeOfDischargeCode}${placeOfDischarge.plantName !== undefined ? ` - ${placeOfDischarge.plantName}` : ''} (${placeOfDischarge.plantCode})`;

    const [displayValue, setDisplayValue] = useState<string | undefined>(
        props.displayValue ? formatPlaceOfDischarge(props.displayValue) : undefined,
    );
    const [suggestions, setSuggestions] = useState<PlaceOfDischargeSuggestion[]>([]);
    useEffect((): void => {
        setSuggestions(suggestionsFor(placesOfDischarge));
    }, [placesOfDischarge]);

    const intl = useIntl();
    const formatPlaceOfDischargeAddress = (placeOfDischarge: PlaceOfDischargeMasterData) =>
        [
            placeOfDischarge.address?.street,
            placeOfDischarge.address?.postalIdentificationCode,
            placeOfDischarge.address?.cityName,
            placeOfDischarge.address?.countryNameCode,
        ]
            .filter((it) => it !== undefined)
            .join(', ');
    const renderSuggestion = (suggestion: AutoSuggestSuggestion) => {
        const placeOfDischarge = suggestion as PlaceOfDischargeSuggestion;
        return (
            <>
                {formatPlaceOfDischarge(placeOfDischarge)}
                <br />
                <span className={'text-size-12 text-color-dark'}>
                    {formatPlaceOfDischargeAddress(placeOfDischarge)}
                </span>
            </>
        );
    };

    const suggestionsFor = (places: PlaceOfDischargeMasterData[] | undefined, query?: string) =>
        (places as PlaceOfDischargeSuggestion[])?.filter(
            (it) =>
                it.placeOfDischargeCode.toLowerCase().includes(query?.toLowerCase() || '') ||
                it.plantName?.toLowerCase().includes(query?.toLowerCase() || '') ||
                it.address?.cityName?.toLowerCase().includes(query?.toLowerCase() || '') ||
                it.address?.postalIdentificationCode?.toLowerCase().includes(query?.toLowerCase() || '') ||
                it.plantCode.toLowerCase().includes(query?.toLowerCase() || ''),
        ) || [];

    return (
        <AutoSuggest
            inputProps={{
                id: props.id,
                placeholder: intl.formatMessage({ id: 'webedi.inputPlaceholder.dropdown.standard' }),
                icon: 'rioglyph rioglyph-search',
                value: displayValue,
                onClear: () => {
                    props.onChange(undefined);
                },
                onChange: () => {
                    props.onChange(undefined);
                },
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={(arg: { value: string }): void => {
                setSuggestions(suggestionsFor(placesOfDischarge, arg.value));
                setDisplayValue(arg.value);
            }}
            onSuggestionSelected={(_: any, { suggestion }: SelectedSuggestion) => {
                const placeOfDischarge = suggestion as PlaceOfDischargeSuggestion;
                setDisplayValue(formatPlaceOfDischarge(placeOfDischarge));
                props.onChange(placeOfDischarge);
            }}
            renderSuggestion={renderSuggestion}
            openOnFocus={true}
            customSuggestionKey={'id'}
        />
    );
};
