import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '../Tooltip';

interface ArticleSuggestionWarningIconProps {
    hasInvalidMasterData: boolean;
    hasInvalidCumulativeQuantitySent: boolean;
}

export const ARTICLE_SUGGESTION_WARNING_ICON_TEST_ID = 'ARTICLE_SUGGESTION_WARNING_ICON';

export const ArticleSuggestionWarningIcon = (props: ArticleSuggestionWarningIconProps) => {
    const { hasInvalidMasterData, hasInvalidCumulativeQuantitySent } = props;
    const hasWarnings = hasInvalidMasterData || hasInvalidCumulativeQuantitySent;

    let text: React.JSX.Element;
    if (hasInvalidMasterData && hasInvalidCumulativeQuantitySent) {
        text = (
            <div>
                <div>
                    <FormattedMessage id={'webedi.articleSuggestion.invalidMasterData.label'} />
                </div>
                <div>
                    <FormattedMessage id={'webedi.articleSuggestion.invalidCumulativeQuantity.label'} />
                </div>
            </div>
        );
    } else if (hasInvalidMasterData && !hasInvalidCumulativeQuantitySent) {
        text = <FormattedMessage id={'webedi.articleSuggestion.invalidMasterData.label'} />;
    } else if (hasInvalidCumulativeQuantitySent && !hasInvalidMasterData) {
        text = <FormattedMessage id={'webedi.articleSuggestion.invalidCumulativeQuantity.label'} />;
    } else {
        return undefined;
    }
    return (
        hasWarnings && (
            <Tooltip text={text} placement={'bottom'}>
                <span
                    className={'rioglyph rioglyph-exclamation-sign text-size-18 text-warning'}
                    data-testid={ARTICLE_SUGGESTION_WARNING_ICON_TEST_ID}
                />
            </Tooltip>
        )
    );
};
