import { KeyboardEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { normalizeToMaxLength, unPad, zeroPad, zeroPadNumber } from '../../../../../utils';
import { setSelectedLabelNumber } from '../../../actions/shipments/Packaging.actions';
import { maxValueLabelNumberField } from '../../shipments/shipmentEditView/ShipmentEditViewFormConfig';

export interface BorderlessLabelNumberInputProps {
    id: string;
    readOnly: boolean;
    labelNumber: number;
}

export const BORDERLESS_LABEL_NUMBER_INPUT_TEST_ID = 'BORDERLESS_LABEL_NUMBER_INPUT_TEST_ID';
export const BORDERLESS_LABEL_NUMBER_SPAN_TEST_ID = 'BORDERLESS_LABEL_NUMBER_SPAN_TEST_ID';

export const BorderlessLabelNumberInput = (props: BorderlessLabelNumberInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<string>(props.labelNumber.toString(10));

    const { ...rest } = props;
    const { id, readOnly } = rest;
    const zeroPadDigits = 9;

    const blockScientificNotation: KeyboardEventHandler<HTMLInputElement> = (event: KeyboardEvent) => {
        if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault();
        }
    };

    const inputOuterClassNames = () => {
        return ['display-flex', 'align-items-center', 'borderless-input', 'no-control-arrows', 'text-size-14'].join(
            ' ',
        );
    };

    useEffect(() => {
        if (document.activeElement !== inputRef.current) {
            zeroPad(zeroPadDigits, inputRef);
        }
    });

    if (readOnly) {
        const padded = zeroPadNumber(Number(props.labelNumber), zeroPadDigits);
        return <span data-testid={`${BORDERLESS_LABEL_NUMBER_SPAN_TEST_ID}-${id}`}>{padded}</span>;
    }

    const onBlur = () => {
        if (value !== '') {
            const normalizedValue = normalizeToMaxLength(value, maxValueLabelNumberField);
            dispatch(setSelectedLabelNumber(id, normalizedValue));
            setValue(normalizedValue.toString(10));
        } else {
            setValue(props.labelNumber.toString());
        }
        zeroPad(zeroPadDigits, inputRef);
    };

    return (
        <div className={inputOuterClassNames()}>
            <div className={'input-group'}>
                <input
                    id={id}
                    value={value}
                    type={'number'}
                    className={`z-index-0 padding-right-25 margin-right-5 margin-left-10 form-control width-100 text-left margin-right-5`}
                    onKeyDown={blockScientificNotation}
                    ref={inputRef}
                    onBlur={onBlur}
                    onFocus={() => unPad(zeroPadDigits, inputRef)}
                    onChange={() => inputRef.current && setValue(inputRef.current.value)}
                    data-testid={`${BORDERLESS_LABEL_NUMBER_INPUT_TEST_ID}-${id}`}
                />
                <div style={{ right: '4px' }} className={`position-absolute z-index-0 pointer-events-none`}>
                    {<span className={'rioglyph rioglyph-pencil text-size-18 opacity-30'} />}
                </div>
            </div>
        </div>
    );
};
