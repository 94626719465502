import { FormattedMessage, useIntl } from 'react-intl';
import { formatDateOrDateTime } from '../../../../../../dateUtils';
import { CourierOrderParcel } from '../../../../domain/courierOrderIntent.types';

interface TrackingInfoBannerProps {
    date: string;
    courier: string;
    parcels: CourierOrderParcel[];
}

export const TrackingInfoBanner = (props: TrackingInfoBannerProps) => {
    const { parcels, date, courier } = props;
    const intl = useIntl();
    return (
        <div className='display-flex flex-column align-items-center'>
            <div
                className='display-flex justify-content-center just flex-row align-items-center text-size-18 text-color-success text-center margin-bottom-15'
                style={{ width: '100%' }}
            >
                <span className='rioglyph rioglyph-ok-sign text-size-200pct padding-x-10' />
                <span data-testid={'tracking-info-banner-headline'} className='text-medium'>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.transportHeadline'}
                        values={{ date: formatDateOrDateTime(date, intl) }}
                    />
                </span>
            </div>
            <div className='panel panel-default padding-10 display-flex flex-column justify-content-center align-items-center border-none'>
                <span>
                    <strong>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.trackingNumber'}
                            values={{ courier }}
                        />
                    </strong>
                </span>
                <div>
                    <ul className='margin-top-10 square-list'>
                        {parcels.map((parcel) => (
                            <li key={parcel.trackingId}>
                                <a href={parcel.trackingUrl}>{parcel.trackingId}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
