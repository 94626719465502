import { PackagedArticles } from '../PackagedArticles';
import { UnpackagedArticleList } from './UnpackagedArticleList';

export const PACKAGING_CONTENT_TEST_ID = 'PACKAGING_CONTENT_TEST_ID';

export const PackagesContentEditView = () => {
    return (
        <div className={'row'} data-testid={PACKAGING_CONTENT_TEST_ID}>
            <div className={'col-ls-12 col-lg-3'}>
                <UnpackagedArticleList />
            </div>
            <div className={'col-ls-12 col-lg-9 height-400'}>
                <PackagedArticles readOnly={false} />
            </div>
        </div>
    );
};
