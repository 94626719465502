export const Routing = {
    webScm: '/webscm',
    deliverySchedules: '/delivery-schedules',
    suggestions: '/suggestions',
    dispatchProposals: '/dispatch-proposals',
    dunsSelection: '/duns-selection',
    shipments: '/shipments',
    exportedShipments: '/exported-shipments',
    loadingLocations: '/loading-locations',
    carriers: '/carriers',
    metadata: '/metadata',
    forbidden: '/forbidden',
    maintenance: '/maintenance',
    transportOrders: '/transport-orders',
    closingDays: '/closing-days',
    notifications: '/notifications',
    admin: '/admin',
};
