import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import { useEffect, useRef, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';
import { fetchAndStorePackagingMaterial } from '../../../actions/packaging/PackagingMaterial.actions';
import { Partner } from '../../../domain/common.types';
import { useBuyerContextFromCurrentlySelectedDeliveryScheduleOrShipment } from '../../../hooks/useBuyerContext';
import { PackagingMaterial } from '../../../reducers/packaging/types';
import { Ownership } from '../../../reducers/shipments/packaging.types';
import { PackagingMaterialExpandable } from './PackagingMaterialExpandable';

export const BOX_FINDER_INPUT_SEARCH_BUTTON_TEST_ID = 'BOX_FINDER_INPUT_SEARCH_BUTTON_TEST_ID';
export const PACKAGING_MATERIAL_FORM_ID = 'packagingMaterial';
export const MAN_COMMON_BOX_CODE_PREFIX = '09.84019-';

export interface PackagingMaterialFormValues {
    name?: string;
    boxCode?: string;
    outerHeightInMm?: number;
    outerWidthInMm?: number;
    outerLengthInMm?: number;
    tareWeightInKg?: number;
    isReusable?: boolean;
    ownership?: Ownership;
    stackingFactor?: number;
}

export const PackagingMaterialFormPropertyNames: Record<
    keyof PackagingMaterialFormValues,
    keyof PackagingMaterialFormValues
> = {
    name: 'name',
    boxCode: 'boxCode',
    outerHeightInMm: 'outerHeightInMm',
    outerWidthInMm: 'outerWidthInMm',
    outerLengthInMm: 'outerLengthInMm',
    tareWeightInKg: 'tareWeightInKg',
    isReusable: 'isReusable',
    ownership: 'ownership',
    stackingFactor: 'stackingFactor',
};

export interface BoxFinderInputProps extends FieldRenderProps<string> {
    id?: string;
    outerClassName?: string;
    disabled?: boolean;
    onChange?: () => void;
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
    packagingMaterialInputPrefix?: string;
}

export const mapFetchedPackagingMaterialToPackagingMaterialFormState = (
    fetchedPackagingMaterial: PackagingMaterial,
): PackagingMaterialFormValues => {
    return {
        name: fetchedPackagingMaterial.name,
        boxCode: fetchedPackagingMaterial.boxCode,
        outerHeightInMm: fetchedPackagingMaterial.outerHeightInMm,
        outerWidthInMm: fetchedPackagingMaterial.outerWidthInMm,
        outerLengthInMm: fetchedPackagingMaterial.outerLengthInMm,
        tareWeightInKg: fetchedPackagingMaterial.tareWeightInKg,
        isReusable: fetchedPackagingMaterial.isReusable,
        ownership: fetchedPackagingMaterial.ownership,
        stackingFactor: fetchedPackagingMaterial.stackingFactor,
    };
};

export const DEBOUNCE_TIMEOUT_IN_MS = 500;
const EXACT_BOX_CODE_LENGTH_OF_MAN = 4;
const MAX_BOX_CODE_LENGTH_OF_VW = 7;
const EXACT_BOX_CODE_LENGTH_OF_POWERCO = 10;

export const BoxFinderInput = (props: BoxFinderInputProps) => {
    const { id, input, meta, outerClassName, disabled, packagingMaterialInputPrefix, ...rest } = props;

    const dispatch = useAppDispatch();
    const buyerContext = useBuyerContextFromCurrentlySelectedDeliveryScheduleOrShipment();
    const isPowerCo = buyerContext.partner === Partner.POWERCO;
    const isMAN = buyerContext.partner === Partner.MAN;
    const intl = useIntl();
    const [internalInputValue, setInternalInputValue] = useState<string | undefined>(undefined);
    const [fetched, setFetched] = useState<boolean>(false);
    const [internalPackagingMaterial, setInternalPackagingMaterial] = useState<PackagingMaterial | undefined>(
        undefined,
    );
    const timerId = useRef(0);

    const dataTestId = rest[DATA_TEST_ID_PROPERTY_NAME];
    const errorClassName = 'form-group has-error has-feedback';
    const inputLength = input.value ? input.value.length : 0;

    const formInputLengthIsEmpty = () => inputLength === 0;
    const getInputRowClassName = (touched: boolean | undefined, error: unknown) => {
        if (!touched) {
            return outerClassName;
        }
        return `${error || !internalPackagingMaterial || formInputLengthIsEmpty() ? errorClassName : ''} ${outerClassName}`;
    };

    const changePackagingMaterial = (fetchedPackagingMaterial: PackagingMaterial | undefined) => {
        const mappedPackagingMaterial = fetchedPackagingMaterial
            ? mapFetchedPackagingMaterialToPackagingMaterialFormState(fetchedPackagingMaterial)
            : undefined;
        setInternalPackagingMaterial(mappedPackagingMaterial);
        input.onChange(mappedPackagingMaterial);
    };

    const isInputDataValid = (unvalidatedInput: string | undefined): unvalidatedInput is string => {
        if (unvalidatedInput === undefined || unvalidatedInput.length < 1 || /[;\\/]/.test(unvalidatedInput)) {
            return false;
        }
        return (
            !(isMAN && unvalidatedInput.length !== EXACT_BOX_CODE_LENGTH_OF_MAN) &&
            !(isPowerCo && unvalidatedInput.length !== EXACT_BOX_CODE_LENGTH_OF_POWERCO)
        );
    };

    const fetchAndUpdatePackagingMaterial = () => {
        if (isInputDataValid(internalInputValue)) {
            const value = isMAN ? MAN_COMMON_BOX_CODE_PREFIX + internalInputValue : internalInputValue;
            dispatch(fetchAndStorePackagingMaterial(value, buyerContext)).then(
                (fetchedPackagingMaterial: PackagingMaterial | undefined) => {
                    changePackagingMaterial(fetchedPackagingMaterial);
                    setFetched(true);
                },
            );
        }
    };

    useEffect(() => {
        // given the boxFinderInput gets populated by the form (i.e. with given initial values),
        // the internalPackagingMaterial will be undefined but not the input.value. This way we sync the two again
        if (internalPackagingMaterial === undefined && input.value !== undefined) {
            setInternalPackagingMaterial(input.value as PackagingMaterialFormValues);
        }
        if (internalInputValue === undefined && (input.value as PackagingMaterialFormValues)?.boxCode !== undefined) {
            setInternalInputValue((input.value as PackagingMaterialFormValues).boxCode);
        }
    }, [input.value, internalInputValue, internalPackagingMaterial]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        window.clearTimeout(timerId.current);
        timerId.current = window.setTimeout(() => fetchAndUpdatePackagingMaterial(), DEBOUNCE_TIMEOUT_IN_MS);
        return () => window.clearTimeout(timerId.current);
    }, [internalInputValue]);

    const maxLength = isMAN
        ? EXACT_BOX_CODE_LENGTH_OF_MAN
        : isPowerCo
          ? EXACT_BOX_CODE_LENGTH_OF_POWERCO
          : MAX_BOX_CODE_LENGTH_OF_VW;
    return (
        <div className={`${getInputRowClassName(meta.touched, meta.error?.boxCode)}`} data-testid={dataTestId}>
            <div className={'input-group'}>
                {isMAN && (
                    <span className='input-group-addon input-group-addon-label'>{MAN_COMMON_BOX_CODE_PREFIX}</span>
                )}
                <ClearableInput
                    id={id}
                    disabled={disabled}
                    value={internalInputValue}
                    onChange={(value: string) => {
                        setFetched(false);
                        setInternalInputValue(value);
                        changePackagingMaterial(undefined);
                    }}
                    maxLength={maxLength}
                    placeholder={intl.formatMessage({ id: 'webedi.inputPlaceholder.packagingMaterial' })}
                    inputClassName={isMAN ? 'padding-left-10' : ''}
                />
                <span className={'input-group-btn'}>
                    <button
                        className={'btn btn-primary btn-icon-only'}
                        data-testid={BOX_FINDER_INPUT_SEARCH_BUTTON_TEST_ID}
                        type={'button'}
                        disabled={disabled ? disabled : !isInputDataValid(internalInputValue)}
                        tabIndex={-1}
                        onClick={fetchAndUpdatePackagingMaterial}
                    >
                        <span className={'rioglyph rioglyph-chevron-right'} />
                    </button>
                </span>
            </div>
            {
                <div className={'margin-top-5'}>
                    <PackagingMaterialExpandable
                        packagingMaterial={internalPackagingMaterial}
                        fetched={fetched}
                        prefix={
                            !packagingMaterialInputPrefix
                                ? PACKAGING_MATERIAL_FORM_ID
                                : packagingMaterialInputPrefix + PACKAGING_MATERIAL_FORM_ID
                        }
                        disabled={disabled === undefined ? false : disabled}
                    />
                </div>
            }
            {meta.touched && meta.error && meta.error.boxCode && (
                <span className={'help-block text-size-12 margin-bottom-0'}>
                    <FormattedMessage id={meta.error.boxCode} />
                </span>
            )}
        </div>
    );
};
