import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { measurementUnitCodeToTranslation } from '../../../../../../../utils';

import { MeasurementUnitCode } from '../../../../../domain/common.types';

export interface PackagedPackagesProps {
    groupedHandlingUnits: Array<{
        packagedAmount?: number;
        packagingMaterialBoxCode: string;
    }>;
    outerPackagingBoxCode: string;
}

export const PACKAGED_PACKAGES_TEST_ID = 'PACKAGED_PACKAGES_AMOUNT_TEST_ID';
export const AMOUNT_OF_PACKAGED_PACKAGES_TEST_ID = 'AMOUNT_OF_PACKAGED_PACKAGES_TEST_ID';

export const PackagedPackages = (props: PackagedPackagesProps) => {
    return (
        <div className={'text-center text-size-16'} data-testid={PACKAGED_PACKAGES_TEST_ID}>
            <FormattedMessage id={'webedi.package.packagedPackages'} />
            <div className={'padding-20'}>
                {props.groupedHandlingUnits.map((handlingUnit, index) => {
                    const actualPackagedAmount = handlingUnit.packagedAmount ? handlingUnit.packagedAmount : 0;
                    return (
                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                        <div className={'text-left'} key={index}>
                            <span
                                className={'rioglyph rioglyph-parcel text-size-h3 margin-right-10-lg margin-right-5-sm'}
                            />
                            <span className={'text-size-20'} data-testid={AMOUNT_OF_PACKAGED_PACKAGES_TEST_ID}>
                                {actualPackagedAmount}&nbsp;
                                {actualPackagedAmount === 1 ? (
                                    <FormattedMessage
                                        id={measurementUnitCodeToTranslation(MeasurementUnitCode.PIECE)}
                                    />
                                ) : (
                                    <FormattedMessage id={'webedi.deliveryInstruction.unit.piece.descriptive'} />
                                )}
                                &nbsp;
                                <b>{handlingUnit.packagingMaterialBoxCode}</b>
                            </span>
                        </div>
                    );
                })}
            </div>
            <span className={'text-size-14'}>
                <FormattedMessage
                    id={'webedi.package.packagedPackagesInto'}
                    values={{
                        handlingUnit: props.outerPackagingBoxCode,
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
            </span>
        </div>
    );
};
