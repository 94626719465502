import { useMatch } from 'react-router';
import { Routing } from '../components/routing/Routes';

export const useDunsNumberFromPath = (): string | undefined => {
    const match = useMatch(`${Routing.webScm}/:dunsNumber/*`);
    return match?.params.dunsNumber;
};

export const useShipmentIdFromPath = (): string | undefined => {
    const matchShipment = useMatch(`${Routing.webScm}/:dunsNumber/shipments/:shipmentId`);
    const matchExportedShipment = useMatch(`${Routing.webScm}/:dunsNumber/exported-shipments/:shipmentId`);

    return matchShipment?.params.shipmentId || matchExportedShipment?.params.shipmentId;
};

export const useDeliveryScheduleIdFromPath = (): string | undefined => {
    const match = useMatch(`${Routing.webScm}/:dunsNumber/delivery-schedules/:deliveryScheduleId/*`);
    return match?.params.deliveryScheduleId;
};
