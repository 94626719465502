import { ShipmentDocument, ShipmentDocuments } from '../../../reducers/shipmentDocuments/ShipmentDocuments.types';
import {
    DownloadIcon,
    ShipmentDocumentDownloadBoxDescription,
    ShipmentDocumentDownloadBoxTitle,
    ShipmentDocumentType,
} from './ShipmentDocumentDownloadBox';

export const DOWNLOAD_SHIPMENT_DOCUMENT_LINK = 'DOWNLOAD_SHIPMENT_DOCUMENT_LINK_';

export interface DownloadShipmentDocumentsProps {
    shipmentDocuments: ShipmentDocuments;
}

export const DownloadShipmentDocumentsComponent = (props: DownloadShipmentDocumentsProps) => (
    <div className={'display-flex-lg max-width-50vw-sm'}>
        <ShipmentDocumentDownloadBox type={ShipmentDocumentType.TSB} document={props.shipmentDocuments.tsbDocument} />
        <ShipmentDocumentDownloadBox type={ShipmentDocumentType.GTL} document={props.shipmentDocuments.gtlDocument} />
    </div>
);

interface ShipmentDocumentDownloadLinkProps {
    document: ShipmentDocument;
    type: ShipmentDocumentType;
}

const ShipmentDocumentDownloadBox = (props: ShipmentDocumentDownloadLinkProps) => {
    const containerClass = [
        'width-50pct-lg',
        'display-flex',
        'justify-content-between',
        'padding-20',
        'margin-10',
        'cursor-pointer',
        'border-style-solid border-width-1',
        'border-color-primary',
        'text-color-primary',
        'hover-bg-highlight-lightest',
        'text-decoration-none',
    ].join(' ');

    const fileName =
        props.type === ShipmentDocumentType.TSB ? `transport-and-shipment-bills` : `global-transport-labels`;

    return (
        <a
            href={props.document.url}
            target={'_blank'}
            download={fileName}
            className={containerClass}
            data-testid={DOWNLOAD_SHIPMENT_DOCUMENT_LINK + props.type}
            rel='noreferrer'
        >
            <div>
                <ShipmentDocumentDownloadBoxTitle type={props.type} />
                <ShipmentDocumentDownloadBoxDescription hasWarnings={props.document.hasWarnings} />
            </div>
            <div className={'margin-left-20 display-flex align-items-center'}>
                <DownloadIcon />
            </div>
        </a>
    );
};
