import { stringify } from 'csv-stringify/browser/esm/sync';
import { listOfCalendarWeeksStartingAt, listOfDaysStartingAt, plusDuration, today } from '../../../../../dateUtils';
import { returnChronologicallySortedQuantities } from '../../../../../utils';
import {
    DeliveryInstructionType,
    DeliverySchedule,
    DespatchDocumentReferenceCodeQualifier,
} from '../../../reducers/deliverySchedules/types';
import { calculateAmountsForDates } from './DeliveryScheduleOrderedQuantitiesCalculator';

export const generateDeliverySchedulesCsv = (deliverySchedules: DeliverySchedule[], onlyOpenAmounts: boolean) => {
    const currentDay = today();
    const tableHeaders = getTableHeaders(currentDay);
    const tableData: string[][] = [];

    deliverySchedules.forEach((deliverySchedule) => {
        const labReferenceNumber = deliverySchedule.deliveryInstructionReferences.find(
            (reference) => reference.type === DeliveryInstructionType.LAB,
        )?.deliveryInstructionNumber;
        const fabReferenceNumber = deliverySchedule.deliveryInstructionReferences.find(
            (reference) => reference.type === DeliveryInstructionType.FAB,
        )?.deliveryInstructionNumber;
        const mostRecentArrivalOfGoods = returnChronologicallySortedQuantities(
            deliverySchedule.scheduledArticleDetails.mostRecentArrivalsOfGoods,
        ).shift();
        const mostRecentDeliveryNoteNumber = mostRecentArrivalOfGoods?.despatchDocumentReferences.find(
            (reference) =>
                reference.referenceCodeQualifier ===
                DespatchDocumentReferenceCodeQualifier.DESPATCH_NOTE_DOCUMENT_IDENTIFIER,
        );
        const deliveryScheduleProperties = [
            deliverySchedule.scheduledArticleDetails.orderNumber.referenceIdentifier,
            deliverySchedule.scheduledArticleDetails.lineItem.lineItemIdentifier.itemNumberIdentification
                .itemIdentifier,
            deliverySchedule.articleMasterData?.articleNumberSeller ?? '',
            deliverySchedule.shipTo.shipTo.partyIdentificationDetails.identifier,
            deliverySchedule.shipTo.placeOfDischarge.locationNameCode,
            deliverySchedule.shipTo.placeOfDelivery.locationNameCode,
            mostRecentArrivalOfGoods?.receiptDate ?? '',
            mostRecentDeliveryNoteNumber?.referenceIdentifier ?? '',
            labReferenceNumber?.referenceIdentifier ?? '',
            labReferenceNumber?.referenceDate ?? '',
            fabReferenceNumber?.referenceIdentifier ?? '',
            fabReferenceNumber?.referenceDate ?? '',
            deliverySchedule.scheduledArticleDetails.measurementUnitCode,
        ];
        const { backOrder, immediateDemand, amountBeforeToday, dailyAmounts, weeklyAmounts } = calculateAmountsForDates(
            deliverySchedule.scheduledArticleDetails.schedulingData,
            deliverySchedule.hasZeroDemand,
            onlyOpenAmounts,
            currentDay,
        );
        tableData.push([
            ...deliveryScheduleProperties,
            immediateDemand.toString(),
            backOrder.toString(),
            amountBeforeToday.toString(),
            ...Array.from(dailyAmounts.values()).map((amount) => amount.toString()),
            ...Array.from(weeklyAmounts.values()).map((amount) => amount.toString()),
        ]);
    });

    return new Blob([stringify(tableData, { delimiter: ',', columns: tableHeaders, quoted: true, header: true })], {
        type: 'text/plain;charset=utf-8',
    });
};

const getTableHeaders = (currentDay: string) => {
    const fixedLabels = [
        'Order number',
        'Article number buyer',
        'Article number seller',
        'Plant number',
        'Place of discharge',
        'Storage place',
        'Last arrival date',
        'Last received delivery note',
        'LAB number',
        'LAB date',
        'FAB number',
        'FAB date',
        'Measurement unit',
        'Immediate demand',
        'Backorder',
    ];
    const beforeLabel = `Total before ${currentDay}`;
    const dayLabels = listOfDaysStartingAt(currentDay, 31);
    const weekLabels = listOfCalendarWeeksStartingAt(currentDay, 52).map(
        (date) => `${date} - ${plusDuration(date, { days: 6 })}`,
    );
    return [...fixedLabels, beforeLabel, ...dayLabels, ...weekLabels];
};
