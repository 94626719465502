import { skipToken } from '@reduxjs/toolkit/query';
import { Children, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCourierOrdersQuery } from '../../../api/shippers/shippersApi';
import { MeansOfTransportMode, TransportConcept } from '../../../domain/meansOfTransport.types';
import {
    FreightForwarderResponse,
    TimeWindow,
    TransportOrder,
    TransportOrderShipment,
} from '../../../domain/transportOrder.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { renderDetailAttributeCard } from '../../common/DetailAttributeCard';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';
import {
    TransportOrderSidebarAddressElement,
    TransportOrderSidebarContentElement,
    TransportOrderSidebarElement,
    TransportOrderSidebarPanel,
    TransportOrderSidebarWeightAndVolumeElement,
} from '../common/TransportOrderSidebarTab';
import { TimeWindowDisplay } from '../table/TimeWindowDisplay';
import { LicensePlateInput } from './LicensePlateInput';

export const TRANSPORT_ORDER_SIDEBAR_VIEW_TEST_ID = 'TRANSPORT_ORDER_SIDEBAR_VIEW';

export const TransportOrderSidebarView = (props: { transportOrder: TransportOrder }) => (
    <div data-testid={TRANSPORT_ORDER_SIDEBAR_VIEW_TEST_ID}>
        <TransportOrderDetails transportOrder={props.transportOrder} open={false} />
        <TransportOrderTimeline transportOrder={props.transportOrder} />
    </div>
);

export const TransportOrderDetails = (props: { transportOrder: TransportOrder; open: boolean }) => {
    const transportIcon: string = props.transportOrder.transportConcept === 'KEP' ? 'rioglyph-van' : 'rioglyph-truck';

    return (
        <TransportOrderSidebarPanel
            title={'webedi.transportOrders.overview.sidebar.transportOrder.title'}
            open={props.open}
        >
            <TransportOrderSidebarElement translationId={'webedi.transportOrders.sidebar.label.transportOrderNumber'}>
                <span className='text-size-14 word-break ellipsis-2'>
                    <span className={`rioglyph ${transportIcon} text-size-18 padding-right-3`} />
                    {props.transportOrder.transportOrderNumber}
                </span>
            </TransportOrderSidebarElement>
            <TransportOrderSidebarElement translationId={'webedi.transportOrders.sidebar.label.transportConcept'}>
                <span className='text-size-14 word-break ellipsis-2'>{props.transportOrder.transportConcept}</span>
            </TransportOrderSidebarElement>
            <TransportOrderSidebarAddressElement
                title={'webedi.transportOrders.overview.sidebar.label.addressPlaceOfLoading'}
                shippingParty={props.transportOrder.loading.shipper}
                loadingLocationId={props.transportOrder.loading.placeOfLoading}
                loadingLocationTitle={'webedi.transportOrders.overview.sidebar.label.loadingPoint'}
            />
            <TransportOrderSidebarAddressElement
                title={'webedi.transportOrders.overview.sidebar.label.addressPlaceOfUnloading'}
                shippingParty={props.transportOrder.unloading.recipient}
                loadingLocationId={props.transportOrder.unloading.placeOfDischarge}
                loadingLocationTitle={'webedi.transportOrders.overview.table.header.placeOfDischarge'}
            />
            <TransportOrderSidebarContentElement
                title={'webedi.transportOrders.overview.sidebar.label.load'}
                content={props.transportOrder.content}
            />
            <TransportOrderSidebarWeightAndVolumeElement
                title={'webedi.transportOrders.overview.sidebar.label.weightAndVolume'}
                totalWeightInKg={props.transportOrder.content?.totalWeightInKg}
                totalVolumeInCubicMetres={props.transportOrder.content?.totalVolumeInCubicMetres}
            />
        </TransportOrderSidebarPanel>
    );
};

const TransportOrderTimeline = (props: { transportOrder: TransportOrder }) => {
    const { transportOrder } = props;
    return (
        <JoinWithArrow>
            <TransportOrderedTimelineItem transportOrder={transportOrder} />
            {transportOrder.freightForwarderResponse !== undefined ? (
                <TransportOrderConfirmedTimelineItem transportOrder={transportOrder as TransportOrderWithResponse} />
            ) : (
                <TransportOrderUnconfirmedTimelineItem transportOrder={transportOrder} />
            )}
        </JoinWithArrow>
    );
};

const CourierTrackingNumberCard = (props: { transportOrder: TransportOrder }) => {
    const duns = useDunsNumberFromPath();
    const query = useCourierOrdersQuery(
        duns && props.transportOrder.shipment
            ? { dunsNumber: duns, shipmentIds: [props.transportOrder.shipment.id] }
            : skipToken,
    );
    return (
        <>
            <div className='divider margin-top-20 margin-bottom-20' />
            {renderDetailAttributeCard(
                <FormattedMessage id={'webedi.transportOrders.overview.sidebar.expressParcel.trackingNumberLabel'} />,
                query.data ? (
                    query.data[0]?.parcels.map((parcel) => (
                        <div key={parcel.trackingId}>
                            <a href={parcel.trackingUrl} target={'_blank'} rel='noreferrer'>
                                {parcel.trackingId}
                            </a>
                        </div>
                    ))
                ) : (
                    <LoadingIndicator />
                ),
            )}
        </>
    );
};

const TransportOrderedTimelineItem = (props: { transportOrder: TransportOrder }) => (
    <div className={'panel panel-default margin-x-20 margin-bottom-0'}>
        <div className={'panel-heading display-flex flex-row justify-content-between'}>
            <div>
                <span className={'rioglyph rioglyph-ok-sign text-size-18 text-color-success'} />
                <span className={'text-color-success text-medium padding-left-3'}>
                    <FormattedMessage id={'webedi.transportOrders.sidebar.timeline.order.ordered'} />
                </span>
            </div>
            <div>
                <span className={'text-size-12 text-color-dark'}>
                    <span className={'rioglyph rioglyph-arrow-right text-color-gray padding-right-5 text-size-14'} />
                    <FormattedMessage
                        id={'webedi.on'}
                        values={{ date: <FormattedDateOrDateTime date={props.transportOrder.sentAt} /> }}
                    />
                </span>
            </div>
        </div>
        <div className={'panel-body'}>
            <div className={'display-flex flex-row gap-25'}>
                <div className={'width-60pct'}>
                    {renderDetailAttributeCard(
                        <FormattedMessage id={'webedi.transportOrders.overview.table.header.freightForwarder'} />,
                        props.transportOrder.freightForwarder.name,
                    )}
                </div>
                <div className={'width-40pct'}>
                    {renderDetailAttributeCard(
                        <FormattedMessage id={'webedi.transportOrders.sidebar.timeline.order.requestedPickupDate'} />,
                        <TimeWindowDisplay
                            plannedLoadingTimeWindow={props.transportOrder.loading.plannedLoadingTimeWindow}
                            lineBreakBetweenDateAndTime={false}
                        />,
                    )}
                </div>
            </div>
            {props.transportOrder.transportConcept === TransportConcept.KEP && (
                <CourierTrackingNumberCard transportOrder={props.transportOrder} />
            )}
        </div>
    </div>
);

type TransportOrderWithResponse = TransportOrder & {
    freightForwarderResponse: FreightForwarderResponse;
};

const TransportOrderConfirmedTimelineItem = (props: {
    transportOrder: TransportOrderWithResponse;
}) => {
    const { transportOrder } = props;
    const { freightForwarderResponse } = transportOrder;
    return (
        <JoinWithDash>
            {freightForwarderResponse.createdAt !== freightForwarderResponse.lastModifiedAt && (
                <InitialConfirmation createdAt={freightForwarderResponse.createdAt} />
            )}
            <CurrentConfirmation transportOrder={transportOrder} />
        </JoinWithDash>
    );
};

const InitialConfirmation = (props: { createdAt: string }) => (
    <div className='panel panel-default margin-x-20 margin-bottom-0'>
        <div className='panel-heading border-bottom-none display-flex flex-row justify-content-between bg-lightest'>
            <div>
                <span className='rioglyph rioglyph-ok-sign text-size-18 text-color-light' />
                <span className='text-color-light text-medium text-decoration-line-through padding-left-3'>
                    <FormattedMessage id={'webedi.transportOrders.overview.table.header.confirmed'} />
                </span>
            </div>
            <span className='text-size-12 text-color-dark'>
                <span className='rioglyph rioglyph-arrow-left text-color-gray padding-right-5 text-size-14' />
                <FormattedMessage
                    id={'webedi.on'}
                    values={{ date: <FormattedDateOrDateTime date={props.createdAt} /> }}
                />
            </span>
        </div>
    </div>
);

const CurrentConfirmation = (props: { transportOrder: TransportOrderWithResponse }) => {
    const { transportOrder } = props;
    const { freightForwarderResponse, shipment } = transportOrder;

    return (
        <div className='panel panel-default margin-x-20'>
            <div className='panel-heading display-flex flex-row justify-content-between'>
                <div>
                    <span className='rioglyph rioglyph-ok-sign text-size-18 text-color-success' />
                    <span className='text-color-success text-medium padding-left-3'>
                        <FormattedMessage id={'webedi.transportOrders.overview.table.header.confirmed'} />
                    </span>
                </div>
                <span className='text-size-12 text-color-dark'>
                    <span className='rioglyph rioglyph-arrow-left text-color-gray padding-right-5 text-size-14' />
                    <FormattedMessage
                        id={'webedi.on'}
                        values={{
                            date: <FormattedDateOrDateTime date={freightForwarderResponse.lastModifiedAt} />,
                        }}
                    />
                </span>
            </div>
            <div className='panel-body'>
                <div className='display-flex flex-row margin-bottom-20'>
                    <CarrierName name={freightForwarderResponse.carrier?.name} />
                    <ConfirmedPickupDate timeWindow={freightForwarderResponse.confirmedLoadingTimeWindow} />
                </div>
                <div className='display-flex flex-row justify-content-between'>
                    <LicensePlate shipment={shipment} />
                </div>
            </div>
        </div>
    );
};

const CarrierName = (props: { name?: string }) => (
    <div className='width-60pct'>
        {renderDetailAttributeCard(
            <FormattedMessage id={'webedi.transportOrders.sidebar.timeline.confirmation.carrier'} />,
            props.name ?? '-',
        )}
    </div>
);

const ConfirmedPickupDate = (props: { timeWindow?: TimeWindow }) => (
    <div className='width-40pct'>
        {renderDetailAttributeCard(
            <FormattedMessage id={'webedi.transportOrders.sidebar.timeline.confirmation.pickupDate'} />,
            props.timeWindow !== undefined ? (
                <TimeWindowDisplay plannedLoadingTimeWindow={props.timeWindow} lineBreakBetweenDateAndTime={false} />
            ) : (
                '-'
            ),
        )}
    </div>
);

const LicensePlate = (props: { shipment?: TransportOrderShipment }) => (
    <div className='width-60pct'>
        {renderDetailAttributeCard(
            <FormattedMessage id={'webedi.transportOrders.overview.table.header.licensePlate'} />,
            props.shipment?.meansOfTransport?.mode === MeansOfTransportMode.ROAD ? (
                <LicensePlateInput
                    id={'licensePlate'}
                    key={props.shipment.id}
                    shipmentId={props.shipment.id}
                    meansOfTransport={props.shipment.meansOfTransport}
                    disabled={props.shipment.exportedAt !== undefined}
                />
            ) : (
                <input className={'form-control'} disabled type={'text'} value={'-'} />
            ),
            undefined,
            'licensePlate',
        )}
    </div>
);

const TransportOrderUnconfirmedTimelineItem = (props: {
    transportOrder: TransportOrder;
}) => {
    const { transportOrder } = props;
    const { shipment } = transportOrder;

    return (
        <div className='panel panel-default margin-x-20'>
            <div className='panel-heading display-flex flex-row justify-content-between'>
                <div>
                    <span className='rioglyph rioglyph-ok-sign text-size-18 text-color-light' />
                    <span className='text-color-light text-medium padding-left-3'>
                        <FormattedMessage id={'webedi.transportOrders.unconfirmed'} />
                    </span>
                </div>
            </div>
            <div className='panel-body'>
                <div className='display-flex flex-row margin-bottom-20'>
                    <CarrierName name={undefined} />
                    <ConfirmedPickupDate timeWindow={undefined} />
                </div>
                <div className='display-flex flex-row justify-content-between'>
                    <LicensePlate shipment={shipment} />
                </div>
            </div>
        </div>
    );
};

const JoinWithArrow = (props: PropsWithChildren) => (
    <>
        {Children.toArray(props.children)
            ?.filter((child) => child !== null && child !== undefined)
            ?.reduce((prev, curr, currentIndex) => {
                return [
                    prev,
                    <div
                        className='display-flex flex-row justify-content-center text-size-18'
                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                        key={`arrow-before-${currentIndex}`}
                    >
                        <span className='rioglyph rioglyph-arrow-down text-size-200pct text-color-light padding-5' />
                    </div>,
                    curr,
                ];
            })}
    </>
);

const JoinWithDash = (props: PropsWithChildren) => (
    <>
        {Children.toArray(props.children)
            ?.filter((child) => child !== null && child !== undefined)
            ?.reduce((prev, curr, currentIndex) => {
                return [
                    prev,
                    // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                    <div className='display-flex flex-row justify-content-center' key={`dash-before-${currentIndex}`}>
                        <span className='width-2 height-15 bg-light' />
                    </div>,
                    curr,
                ];
            })}
    </>
);
