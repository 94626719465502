import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { Quantity } from '../../domain/common.types';
import { FreightForwarder } from '../../domain/dispatchProposal.types';
import {
    MeansOfTransport,
    MeansOfTransportMode,
    MeansOfTransportWithoutConfirmationData,
    TransportConcept,
} from '../../domain/meansOfTransport.types';
import {
    ArticleContent,
    Buyer,
    FreightForwarderResponse,
    HandlingUnit,
    Loading,
    PartialShippingParty,
    ShipmentContent,
    ShipmentLoading,
    ShipmentUnloading,
    ShippingParty,
    ShippingPartyAddress,
    TimeWindow,
    TransportOrder,
    TransportOrderContent,
    TransportOrderShipment,
    Unloading,
} from '../../domain/transportOrder.types';
import { decodeJson } from '../apiUtils';
import { CarrierCodec } from '../shared/carrier.types';
import { ApiQuantity, quantityCodec } from '../shared/quantity.types';
import { mapToCarrier } from '../shared/sharedMappers';
import { fromEnum } from '../util';

const freightForwarderCodec = t.type(
    {
        name: t.string,
        duns_number: t.string,
        supplier_code: t.string,
    },
    'FreightForwarder',
);
type FreightForwarderApi = t.TypeOf<typeof freightForwarderCodec>;

const timeWindowCodec = t.type(
    {
        time_from: t.string,
        time_to: t.string,
    },
    'TimeWindow',
);
type TimeWindowApi = t.TypeOf<typeof timeWindowCodec>;

export enum MeansOfTransportModeApi {
    ROAD = 'ROAD',
    RAIL = 'RAIL',
    MARITIME = 'MARITIME',
    AIR = 'AIR',
    MAIL = 'MAIL',
}

const shipmentMeansOfTransportCodec = t.type(
    {
        mode: fromEnum<MeansOfTransportModeApi>('meansOfTransportMode', MeansOfTransportModeApi),
        license_plate: t.union([t.undefined, t.string]),
        license_plate_from_confirmation: t.union([t.undefined, t.string]),
    },
    'MeansOfTransport',
);
export type ShipmentMeansOfTransportApi = t.TypeOf<typeof shipmentMeansOfTransportCodec>;

const confirmationMeansOfTransportCodec = t.type(
    {
        mode: fromEnum<MeansOfTransportModeApi>('meansOfTransportMode', MeansOfTransportModeApi),
        license_plate: t.union([t.undefined, t.string]),
    },
    'MeansOfTransport',
);
export type ConfirmationMeansOfTransportApi = t.TypeOf<typeof confirmationMeansOfTransportCodec>;

const confirmationCodec = t.type(
    {
        type: t.literal('CONFIRMATION'),
        means_of_transport: t.union([t.undefined, confirmationMeansOfTransportCodec]),
        loading_time_window: t.union([t.undefined, timeWindowCodec]),
        carrier: t.union([t.undefined, CarrierCodec]),
        created_at: t.string,
        last_modified_at: t.string,
    },
    'Confirmation',
);
const freightForwarderResponseCodec = confirmationCodec;
export type FreightForwarderResponseApi = t.TypeOf<typeof freightForwarderResponseCodec>;

const shippingPartyAddressCodec = t.type(
    {
        street: t.string,
        city: t.string,
        postal_code: t.string,
        country: t.string,
    },
    'ShippingAddress',
);
type ShippingPartyAddressApi = t.TypeOf<typeof shippingPartyAddressCodec>;

const shippingPartyCodec = t.type(
    {
        name: t.string,
        address: shippingPartyAddressCodec,
    },
    'ShippingParty',
);
type ShippingPartyApi = t.TypeOf<typeof shippingPartyCodec>;

const loadingCodec = t.type(
    {
        time_window: timeWindowCodec,
        loading_point: t.union([t.undefined, t.string]),
        shipper: t.union([t.undefined, shippingPartyCodec]),
    },
    'Loading',
);
type LoadingApi = t.TypeOf<typeof loadingCodec>;

const unloadingCodec = t.type(
    {
        plant_number: t.string,
        place_of_discharge: t.string,
        recipient: t.union([t.undefined, shippingPartyCodec]),
    },
    'Unloading',
);
type UnloadingApi = t.TypeOf<typeof unloadingCodec>;

const buyerCodec = t.type(
    {
        identifier: t.string,
        name: t.union([t.undefined, t.string]),
    },
    'Buyer',
);
type BuyerApi = t.TypeOf<typeof buyerCodec>;

const articleCodec = t.type(
    {
        article_number_buyer: t.string,
        quantity: quantityCodec,
    },
    'Article',
);
type ArticleApi = t.TypeOf<typeof articleCodec>;

const handlingUnitCodec = t.type(
    {
        amount: t.number,
        type: t.string,
        articles: t.array(articleCodec),
    },
    'HandlingUnit',
);
type HandlingUnitApi = t.TypeOf<typeof handlingUnitCodec>;

const contentCodec = t.type(
    {
        handling_units: t.array(handlingUnitCodec),
        total_volume_in_m3: t.union([t.undefined, t.number]),
        total_weight_in_kg: t.union([t.undefined, t.number]),
    },
    'Content',
);
type ContentApi = t.TypeOf<typeof contentCodec>;

const partialShippingPartyCodec = t.type(
    {
        name: t.union([t.undefined, t.string]),
        address: shippingPartyAddressCodec,
    },
    'PartialShippingParty',
);
type PartialShippingPartyApi = t.TypeOf<typeof partialShippingPartyCodec>;

const shipmentLoadingCodec = t.type(
    {
        loading_point: t.union([t.undefined, t.string]),
        shipper: partialShippingPartyCodec,
    },
    'ShipmentLoading',
);
type ShipmentLoadingApi = t.TypeOf<typeof shipmentLoadingCodec>;

const shipmentUnloadingCodec = t.type(
    {
        place_of_discharge: t.string,
        recipient: partialShippingPartyCodec,
    },
    'ShipmentUnloading',
);
type ShipmentUnloadingApi = t.TypeOf<typeof shipmentUnloadingCodec>;

const transportOrderContentCodec = t.type(
    {
        total_volume_in_m3: t.union([t.undefined, t.number]),
        total_weight_in_kg: t.union([t.undefined, t.number]),
        handling_units: t.array(handlingUnitCodec),
    },
    'Content',
);
type TransportOrderContentApi = t.TypeOf<typeof transportOrderContentCodec>;

const shipmentCodec = t.type(
    {
        id: t.string,
        shipment_number: t.number,
        loading: shipmentLoadingCodec,
        unloading: shipmentUnloadingCodec,
        content: contentCodec,
        exported_at: t.union([t.undefined, t.string]),
        means_of_transport: t.union([t.undefined, shipmentMeansOfTransportCodec]),
    },
    'Shipment',
);
type ShipmentApi = t.TypeOf<typeof shipmentCodec>;

const transportOrderCodec = t.type(
    {
        id: t.string,
        transport_order_number: t.string,
        sent_at: t.string,
        transport_concept: fromEnum<TransportConcept>('transportConcept', TransportConcept),
        freight_forwarder: freightForwarderCodec,
        freight_forwarder_response: t.union([t.undefined, freightForwarderResponseCodec]),
        loading: loadingCodec,
        unloading: unloadingCodec,
        buyer: t.union([t.undefined, buyerCodec]),
        content: t.union([t.undefined, transportOrderContentCodec]),
        // biome-ignore lint/style/useNamingConvention: False positive _is always allowed
        _embedded: t.union([
            t.undefined,
            t.type({
                shipment: t.union([t.undefined, shipmentCodec]),
            }),
        ]),
    },
    'TransportOrder',
);
type TransportOrderApi = t.TypeOf<typeof transportOrderCodec>;

const transportOrderListCodec = t.type(
    {
        items: t.array(transportOrderCodec),
    },
    'TransportOrderList',
);
export type TransportOrderListApi = t.TypeOf<typeof transportOrderListCodec>;

const mapToFreightForwarder = (decoded: FreightForwarderApi): FreightForwarder => {
    return {
        name: decoded.name,
        dunsNumber: decoded.duns_number,
        supplierCode: decoded.supplier_code,
    };
};

const mapToMeansOfTransportMode = (mode: MeansOfTransportModeApi): MeansOfTransportMode => {
    switch (mode) {
        case MeansOfTransportModeApi.ROAD:
            return MeansOfTransportMode.ROAD;
        case MeansOfTransportModeApi.RAIL:
            return MeansOfTransportMode.RAIL;
        case MeansOfTransportModeApi.MARITIME:
            return MeansOfTransportMode.MARITIME;
        case MeansOfTransportModeApi.AIR:
            return MeansOfTransportMode.AIR;
        case MeansOfTransportModeApi.MAIL:
            return MeansOfTransportMode.MAIL;
        default:
            throw new Error(`cannot map means of transport mode "${mode}"`);
    }
};

const mapToShipmentMeansOfTransport = (decoded: ShipmentMeansOfTransportApi): MeansOfTransport => {
    const mode = mapToMeansOfTransportMode(decoded.mode);
    return {
        mode,
        registrationNumber: mode === MeansOfTransportMode.ROAD ? decoded.license_plate : undefined,
        registrationNumberFromConfirmation:
            mode === MeansOfTransportMode.ROAD ? decoded.license_plate_from_confirmation : undefined,
    };
};

const mapToMeansOfTransport = (decoded: ConfirmationMeansOfTransportApi): MeansOfTransportWithoutConfirmationData => {
    const mode = mapToMeansOfTransportMode(decoded.mode);
    return {
        mode,
        registrationNumber: mode === MeansOfTransportMode.ROAD ? decoded.license_plate : undefined,
    };
};

const mapToTimeWindow = (decoded: TimeWindowApi): TimeWindow => {
    return {
        from: decoded.time_from,
        to: decoded.time_to,
    };
};

const mapToFreightForwarderResponse = (decoded: FreightForwarderResponseApi): FreightForwarderResponse => {
    return {
        type: 'Confirmation',
        meansOfTransport:
            decoded.means_of_transport === undefined ? undefined : mapToMeansOfTransport(decoded.means_of_transport),
        confirmedLoadingTimeWindow:
            decoded.loading_time_window === undefined ? undefined : mapToTimeWindow(decoded.loading_time_window),
        carrier: decoded.carrier === undefined ? undefined : mapToCarrier(decoded.carrier),
        createdAt: decoded.created_at,
        lastModifiedAt: decoded.last_modified_at,
    };
};

const mapToShippingParty = (decoded: ShippingPartyApi): ShippingParty => {
    return {
        name: decoded.name,
        address: mapToShippingPartyAddress(decoded.address),
    };
};

const mapToShippingPartyAddress = (decoded: ShippingPartyAddressApi): ShippingPartyAddress => {
    return {
        street: decoded.street,
        country: decoded.country,
        postalCode: decoded.postal_code,
        city: decoded.city,
    };
};

const mapToPartialShippingParty = (decoded: PartialShippingPartyApi): PartialShippingParty => {
    return {
        name: decoded.name,
        address: mapToShippingPartyAddress(decoded.address),
    };
};

const mapToShipmentLoading = (decoded: ShipmentLoadingApi): ShipmentLoading => {
    return {
        loadingPoint: decoded.loading_point,
        shipper: mapToPartialShippingParty(decoded.shipper),
    };
};

const mapToShipmentUnloading = (decoded: ShipmentUnloadingApi): ShipmentUnloading => {
    return {
        placeOfDischarge: decoded.place_of_discharge,
        recipient: mapToPartialShippingParty(decoded.recipient),
    };
};

const mapToLoading = (decoded: LoadingApi): Loading => {
    return {
        plannedLoadingTimeWindow: mapToTimeWindow(decoded.time_window),
        placeOfLoading: decoded.loading_point,
        shipper: decoded.shipper ? mapToShippingParty(decoded.shipper) : undefined,
    };
};

const mapToUnloading = (decoded: UnloadingApi): Unloading => {
    return {
        plantNumber: decoded.plant_number,
        placeOfDischarge: decoded.place_of_discharge,
        recipient: decoded.recipient ? mapToShippingParty(decoded.recipient) : undefined,
    };
};

const mapToBuyer = (decoded: BuyerApi): Buyer => {
    return {
        id: decoded.identifier,
        name: decoded.name,
    };
};

const mapToQuantity = (decoded: ApiQuantity): Quantity => {
    return {
        value: decoded.value,
        measurementUnitCode: decoded.measurement_unit_code,
    };
};

const mapToArticleContent = (decoded: ArticleApi): ArticleContent => {
    return {
        articleNumberBuyer: decoded.article_number_buyer,
        quantity: mapToQuantity(decoded.quantity),
    };
};

const mapToHandlingUnit = (decoded: HandlingUnitApi): HandlingUnit => {
    return {
        amount: decoded.amount,
        type: decoded.type,
        articles: decoded.articles.map(mapToArticleContent),
    };
};

const mapToShipmentContent = (decoded: ContentApi): ShipmentContent => {
    return {
        handlingUnits: decoded.handling_units.map(mapToHandlingUnit),
        totalVolumeInCubicMetres: decoded.total_volume_in_m3,
        totalWeightInKg: decoded.total_weight_in_kg,
    };
};

const mapToShipment = (decoded: ShipmentApi): TransportOrderShipment => {
    return {
        id: decoded.id,
        shipmentNumber: decoded.shipment_number,
        loading: mapToShipmentLoading(decoded.loading),
        unloading: mapToShipmentUnloading(decoded.unloading),
        content: mapToShipmentContent(decoded.content),
        exportedAt: decoded.exported_at === undefined ? undefined : decoded.exported_at,
        meansOfTransport:
            decoded.means_of_transport === undefined
                ? undefined
                : mapToShipmentMeansOfTransport(decoded.means_of_transport),
    };
};

const mapToTransportOrderList = (decoded: TransportOrderListApi): TransportOrder[] => {
    return decoded.items.map((transportOrderApi: TransportOrderApi) => ({
        id: transportOrderApi.id,
        transportOrderNumber: transportOrderApi.transport_order_number,
        sentAt: transportOrderApi.sent_at,
        transportConcept: transportOrderApi.transport_concept,
        freightForwarder: mapToFreightForwarder(transportOrderApi.freight_forwarder),
        freightForwarderResponse:
            transportOrderApi.freight_forwarder_response === undefined
                ? undefined
                : mapToFreightForwarderResponse(transportOrderApi.freight_forwarder_response),
        loading: mapToLoading(transportOrderApi.loading),
        unloading: mapToUnloading(transportOrderApi.unloading),
        buyer: transportOrderApi.buyer === undefined ? undefined : mapToBuyer(transportOrderApi.buyer),
        shipment:
            transportOrderApi?._embedded?.shipment === undefined
                ? undefined
                : mapToShipment(transportOrderApi._embedded.shipment),
        content: transportOrderApi.content ? mapToTransportOrderContent(transportOrderApi.content) : undefined,
    }));
};

const mapToTransportOrderContent = (decoded: TransportOrderContentApi): TransportOrderContent => {
    return {
        totalVolumeInCubicMetres: decoded.total_volume_in_m3,
        totalWeightInKg: decoded.total_weight_in_kg,
        handlingUnits: decoded.handling_units.map((unit) => mapToHandlingUnit(unit)),
    };
};

const transformTransportOrderListApi = (response: unknown): TransportOrder[] => {
    const decoded = decodeJson(transportOrderListCodec)(response);
    return mapToTransportOrderList(decoded);
};

export interface GetTransportOrdersParameters {
    dunsNumber: string;
    embed: 'shipments'[];
    loadingFrom: string;
    loadingTo: string;
}

export const transportOrderApi = createApi({
    reducerPath: 'transportOrderApi',
    tagTypes: ['TransportOrders'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTransportOrders: builder.query<TransportOrder[], GetTransportOrdersParameters>({
            query: ({ dunsNumber, embed, loadingFrom, loadingTo }) => {
                return {
                    url: `/shippers/${dunsNumber}/transport-orders`,
                    params: {
                        loading_from: loadingFrom,

                        loading_to: loadingTo,
                        embed,
                    },
                };
            },
            providesTags: [{ type: 'TransportOrders' }],
            transformResponse: (response: unknown) => transformTransportOrderListApi(response),
        }),
    }),
});

export const { useGetTransportOrdersQuery } = transportOrderApi;
