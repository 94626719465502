import { SteppedProgressBarLabel } from '@rio-cloud/rio-uikit';
import isEmpty from 'lodash/fp/isEmpty';
import { FormattedMessage } from 'react-intl';
import { ShipmentEditErrors } from './shipmentEditView/types';

export enum SteppedProgressBarStep {
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    PACKAGING = 'PACKAGING',
    TRANSPORT = 'TRANSPORT',
    SHIPMENT_DOCUMENTS = 'SHIPMENT_DOCUMENTS',
}

export const mapStepToNumber = (step: SteppedProgressBarStep): number => {
    switch (step) {
        case SteppedProgressBarStep.DELIVERY_NOTE:
            return 0;
        case SteppedProgressBarStep.PACKAGING:
            return 1;
        case SteppedProgressBarStep.TRANSPORT:
            return 2;
        case SteppedProgressBarStep.SHIPMENT_DOCUMENTS:
            return 3;
        default:
            throw Error(`Should never be called. Was called with ${step}`);
    }
};

export const mapNumberToStep = (stepNumber: number): SteppedProgressBarStep => {
    switch (stepNumber) {
        case 0:
            return SteppedProgressBarStep.DELIVERY_NOTE;
        case 1:
            return SteppedProgressBarStep.PACKAGING;
        case 2:
            return SteppedProgressBarStep.TRANSPORT;
        case 3:
            return SteppedProgressBarStep.SHIPMENT_DOCUMENTS;
        default:
            throw Error(`Should never be called. Was called with ${stepNumber}`);
    }
};

export const translationsForTheStepLabels = {
    [SteppedProgressBarStep.DELIVERY_NOTE]: 'webedi.deliveryNote',
    [SteppedProgressBarStep.PACKAGING]: 'webedi.package',
    [SteppedProgressBarStep.TRANSPORT]: 'webedi.transportAndCustoms',
    [SteppedProgressBarStep.SHIPMENT_DOCUMENTS]: 'webedi.shipmentDocuments.labelAndShipmentDocuments',
};

const labelWrapperClassName = (
    step: SteppedProgressBarStep,
    currentStep?: SteppedProgressBarStep,
    errors?: ShipmentEditErrors,
) => {
    const standardClassNames = 'flex-1-1-0 padding-top-5 padding-bottom-5 text-uppercase';
    const disabledClassNames = 'disabled pointer-events-none text-color-lighter';
    if (!isEmpty(errors) && step !== currentStep) {
        return `${standardClassNames} ${disabledClassNames}`;
    }
    return standardClassNames;
};

export const labelsShipmentProgressBar = (
    currentStep?: SteppedProgressBarStep,
    errors?: ShipmentEditErrors,
): SteppedProgressBarLabel[] => {
    const steps = Object.keys(SteppedProgressBarStep) as SteppedProgressBarStep[];
    return steps.map((step) => {
        switch (step) {
            case SteppedProgressBarStep.DELIVERY_NOTE:
                return {
                    icon: <i className='rioglyph rioglyph-document' />,
                    label: <FormattedMessage id={translationsForTheStepLabels[SteppedProgressBarStep.DELIVERY_NOTE]} />,
                    labelWrapperClassName: labelWrapperClassName(step, currentStep, errors),
                };
            case SteppedProgressBarStep.PACKAGING:
                return {
                    icon: <i className='rioglyph rioglyph-parcel' />,
                    label: <FormattedMessage id={translationsForTheStepLabels[SteppedProgressBarStep.PACKAGING]} />,
                    labelWrapperClassName: labelWrapperClassName(step, currentStep, errors),
                };
            case SteppedProgressBarStep.TRANSPORT:
                return {
                    icon: <i className='rioglyph rioglyph-transfer' />,
                    label: <FormattedMessage id={translationsForTheStepLabels[SteppedProgressBarStep.TRANSPORT]} />,
                    labelWrapperClassName: labelWrapperClassName(step, currentStep, errors),
                };
            case SteppedProgressBarStep.SHIPMENT_DOCUMENTS:
                return {
                    icon: <i className='rioglyph rioglyph-tag' />,
                    label: (
                        <FormattedMessage
                            id={translationsForTheStepLabels[SteppedProgressBarStep.SHIPMENT_DOCUMENTS]}
                        />
                    ),
                    labelWrapperClassName: labelWrapperClassName(step, currentStep, errors),
                };
            default:
                throw Error(`Should never be called. Was called with ${step}`);
        }
    });
};
