import { FormattedMessage } from 'react-intl';
import { ArticleContent, TransportOrderShipment } from '../../../domain/transportOrder.types';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { OuterPackaging } from './OuterPackaging';

const ArticleNumberInfo = (props: { article: ArticleContent }) => {
    const { article } = props;
    return (
        <div className={'text-size-14 padding-bottom-5'}>
            <strong>{article.articleNumberBuyer}</strong>
            <span>
                <br />
                {article.quantity.value} <MeasurementUnitCodeDisplay unit={article.quantity.measurementUnitCode} />
            </span>
        </div>
    );
};

const Articles = (props: { shipment: TransportOrderShipment }) => {
    const { shipment } = props;
    return (
        <div className={'width-40pct flex-column'}>
            <label>
                <FormattedMessage id={'webedi.articleNumber.buyer'} />{' '}
            </label>
            {shipment.content.handlingUnits
                .flatMap((handlingUnit) => handlingUnit.articles)
                .map((article) => (
                    <ArticleNumberInfo article={article} key={article.articleNumberBuyer} />
                ))}
        </div>
    );
};

const Packaging = (props: { shipment: TransportOrderShipment }) => {
    const { shipment } = props;
    return (
        <div className={'width-60pct flex-column ellipsis-1'}>
            <label>
                <FormattedMessage id={'webedi.transportOrders.overview.table.header.handlingUnits'} />
            </label>
            {shipment.content.handlingUnits.map((handlingUnit) => (
                <div key={`${handlingUnit.type}-${handlingUnit.amount}`}>
                    <OuterPackaging amount={handlingUnit.amount} type={handlingUnit.type} key={handlingUnit.type} />
                </div>
            ))}
        </div>
    );
};

export const ShipmentDetails = (props: { shipment: TransportOrderShipment }) => (
    <div className={'panel-body display-flex flex-row gap-25'}>
        <Articles shipment={props.shipment} />
        <Packaging shipment={props.shipment} />
    </div>
);
