import { SelectOption } from '@rio-cloud/rio-uikit';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { useIntl } from 'react-intl';

const PAGE_NUMBER_BUTTONS = 'PAGE_NUMBER_BUTTONS';
const ITEM_LIMIT_DROPDOWN = 'ITEM_LIMIT_DROPDOWN';

export interface Props {
    limit: number;
    offset: number;
    totalNumber: number;
    limits: number[];
    onOffsetSelect: (offset: number) => void;
    onChangeLimit: (limit: number, offset: number) => void;
}

export const PaginationBar = (props: Props) => {
    const { limit, offset, totalNumber, limits, onOffsetSelect, onChangeLimit } = props;

    const totalPageCount = getTotalPageCount(limit, totalNumber);
    if (totalPageCount <= 1) {
        return null;
    }

    return (
        <div className='spacer'>
            <div className='btn-toolbar'>
                <div>
                    <PageNumbers
                        limit={limit}
                        offset={offset}
                        totalNumber={totalNumber}
                        onOffsetSelect={onOffsetSelect}
                    />
                </div>
                <div className='padding-left-15' data-testid={ITEM_LIMIT_DROPDOWN}>
                    <LimitDropdown currentLimit={limit} limits={limits} onChangeLimit={onChangeLimit} />
                </div>
            </div>
        </div>
    );
};

interface LimitDropdownProps {
    currentLimit: number;
    limits: number[];
    onChangeLimit: (limit: number, offset: number) => void;
}

const LimitDropdown = (props: LimitDropdownProps) => {
    const { currentLimit, limits, onChangeLimit } = props;
    const intl = useIntl();

    const onChange = (newLimit: number) => {
        onChangeLimit(newLimit, 0);
    };

    return (
        <>
            <Select
                value={[currentLimit.toString()]}
                options={limits.map((limit) => ({
                    id: limit.toString(),
                    label: `${limit} ${intl.formatMessage({ id: 'webedi.common.pagination.rowsPerPage' })}`,
                    selected: limit === currentLimit,
                }))}
                onChange={(item?: SelectOption) => item && onChange(parseInt(item.id, 10))}
                dropup={true}
            />
        </>
    );
};

interface PageNumbersProps {
    limit: number;
    offset: number;
    totalNumber: number;
    onOffsetSelect: (offset: number) => void;
}

const PageNumbers = (props: PageNumbersProps) => {
    const { limit, offset, totalNumber, onOffsetSelect } = props;

    const currentPage = getCurrentPage(offset, limit, totalNumber);
    const totalPageCount = getTotalPageCount(limit, totalNumber);

    const pagesBeforeCurrent = getPagesBeforeCurrent(currentPage);
    const pagesAfterCurrent = getPagesAfterCurrent(currentPage, totalPageCount);
    const consecutivePages = [...pagesBeforeCurrent, currentPage, ...pagesAfterCurrent];

    const displayFirstPage = consecutivePages[0] !== 1;
    const displayDotsAfterFirstPage = displayFirstPage && consecutivePages[0] !== 2;

    const lastConsecutivePage = consecutivePages[consecutivePages.length - 1];
    const displayLastPage = lastConsecutivePage !== totalPageCount;
    const displayDotsBeforeLastPage = displayLastPage && lastConsecutivePage !== totalPageCount - 1;

    const onPageSelect = (page: number) => {
        if (currentPage !== page) {
            const newOffset = calculateNewOffset(page, limit);
            return onOffsetSelect(newOffset);
        }
        return undefined;
    };

    return (
        <>
            <span className='btn-group' data-testid={PAGE_NUMBER_BUTTONS}>
                {displayFirstPage ? (
                    <button key={1} onClick={() => onPageSelect(1)} type='button' className='btn btn-default'>
                        {(1).toString()}
                    </button>
                ) : undefined}
                {displayDotsAfterFirstPage ? (
                    <span className={'align-bottom padding-right-5 padding-left-5'}>...</span>
                ) : undefined}
                {consecutivePages.map((key) => renderButton(key, currentPage, onPageSelect))}
                {displayDotsBeforeLastPage ? (
                    <span className={'align-bottom padding-right-5 padding-left-5'}>...</span>
                ) : undefined}
                {displayLastPage ? (
                    <button
                        key={totalPageCount}
                        type='button'
                        onClick={() => onPageSelect(totalPageCount)}
                        className='btn btn-default'
                    >
                        {totalPageCount.toString()}
                    </button>
                ) : undefined}
            </span>
        </>
    );
};

const getPagesAfterCurrent = (currentPage: number, lastPage: number) => {
    if (currentPage === lastPage) {
        return [];
    }
    if (currentPage === lastPage - 1) {
        return [lastPage];
    }
    return [currentPage + 1, currentPage + 2];
};

const getPagesBeforeCurrent = (currentPage: number) => {
    if (currentPage === 1) {
        return [];
    }
    if (currentPage === 2) {
        return [1];
    }
    return [currentPage - 2, currentPage - 1];
};

const getTotalPageCount = (limit: number, totalCount: number): number => {
    if (totalCount === 0) {
        return 1;
    }
    return Math.ceil(totalCount / limit);
};

const getCurrentPage = (offset: number, limit: number, totalCount: number): number => {
    if (offset >= totalCount) {
        return getTotalPageCount(limit, totalCount);
    }
    return Math.ceil((offset + 1) / limit);
};

const renderButton = (page: number, currentPage: number, onClick: (page: number) => void) => {
    if (page === currentPage) {
        return (
            <button key={page} type='button' className='btn btn-primary'>
                {currentPage.toString()}
            </button>
        );
    } else {
        return (
            <button key={page} onClick={() => onClick(page)} type='button' className='btn btn-default'>
                {page.toString()}
            </button>
        );
    }
};

const calculateNewOffset = (page: number, limit: number): number => {
    return (page - 1) * limit;
};
