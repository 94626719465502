import { SelectOption } from '@rio-cloud/rio-uikit';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { getDisplayCountries } from '../../../../../configuration/lang/selectors';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { MeasurementUnitCode } from '../../../domain/common.types';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { PositiveNumberFormInput } from '../../common/form/PositiveNumberFormInput';
import { useLoadingLocationsQuery } from '../../loadingLocations/LoadingLocationHooks';
import { formatCountryForDisplay } from '../../managedFreightForwarders/ManagedFreightForwarderForm';
import { getBuyerName } from '../BuyerAbbreviationsMappings';
import { ErrorHelpBlock } from './common/ErrorHelpBlock';
import { TextInputForm } from './common/TextInputForm';
import { InputFormProps } from './common/types';
import { ManualCreationOfDeliverySchedulesFormValues } from './formConfig';
import {
    genericInputErrorTranslations,
    numberInputErrorTranslations,
    textInputErrorTranslations,
    undgNumberInputErrorTranslations,
} from './validations';

export const Step2 = () => (
    <div className={'padding-20'}>
        <div className='display-flex flex-column margin--20 padding-y-5pct margin-x-2pct min-height-500'>
            <Header />
            <div className='display-flex flex-column padding-20 border-style-solid border-width-1 border-color-light'>
                <CustomerData />
                <div className={'display-flex flex-column flex-row-md'}>
                    <div className={'display-flex flex-column width-50pct-md'}>
                        <TextInputForm
                            fieldName={'placeOfDelivery'}
                            required={true}
                            label={
                                <FormattedMessage
                                    id={'webedi.deliverySchedules.create.wizard.articleDetails.label.placeOfDelivery'}
                                />
                            }
                            rowClassName={rowClassName}
                            formInputClassName={formInputClassName}
                            labelClassName={labelClassName}
                            pattern={/^[a-zA-Z0-9]{1,5}$/}
                            maxLength={5}
                            errorTranslations={textInputErrorTranslations}
                        />
                        <TextInputForm
                            fieldName={'orderNumber'}
                            required={true}
                            label={<FormattedMessage id={'webedi.orderNumber'} />}
                            rowClassName={rowClassName}
                            formInputClassName={formInputClassName}
                            labelClassName={labelClassName}
                            pattern={/^[a-zA-Z0-9]{1,12}$/}
                            maxLength={12}
                            errorTranslations={textInputErrorTranslations}
                        />
                        <ShippingLocationSelect />
                    </div>
                    <div
                        className={
                            'display-flex flex-column width-25 margin-left-25 border-style-solid border-left-only border-width-1 border-color-light'
                        }
                    />
                    <div className={'display-flex flex-column width-50pct-md'}>
                        <MeasurementUnitSelect />
                        <ArticleNetWeightInput />
                        <NetExplosiveMassInput />
                        <CountryOfOriginSelect />
                        <CheckboxFormInput
                            fieldName={'isDangerousGoods'}
                            required={false}
                            label={<FormattedMessage id={'webedi.article.isDangerousGoods'} />}
                        />
                        <UndgNumberInput />
                        <DangerousGoodsDescriptionInput />
                        <DangerousGoodsDeclarationExceptionInput />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Header = () => (
    <div className='display-flex flex-column align-items-center text-center'>
        <div className='text-size-18 margin-bottom-25'>
            <FormattedMessage id={'webedi.deliverySchedules.create.wizard.articleDetails.title'} />
        </div>
        <div className='counter color-highlight'>
            <span className='display-flex flex-row' data-count='2'>
                <FormattedMessage id={'webedi.deliverySchedules.create.wizard.articleDetails.text'} />
            </span>
        </div>
    </div>
);

const CustomerData = () => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const articleNumberBuyer = watch('articleNumberBuyer');
    const supplierData = watch('supplierData');
    const placeOfDischarge = watch('placeOfDischarge');

    return (
        <div className={'display-flex padding-10 margin-bottom-15 justify-content-center bg-lightest'}>
            <div className='display-flex flex-row min-width-100 text-size-14'>
                <span className='margin-right-25 ellipsis-1'>
                    <FormattedMessage
                        id={'webedi.deliverySchedules.create.wizard.articleDetails.customerData.articleNumberBuyer'}
                    />
                    <span className='margin-left-5 display-consecutive-whitespaces'>{articleNumberBuyer}</span>
                </span>
                <span className='margin-right-25 ellipsis-1'>
                    <FormattedMessage id={'webedi.deliverySchedules.create.wizard.articleDetails.customerData.buyer'} />
                    <span className={'margin-left-5'}>
                        {supplierData?.buyer &&
                            getBuyerName(supplierData?.buyer?.partyIdentificationDetails?.identifier)}
                    </span>
                </span>
                <span className='margin-right-25 ellipsis-1'>
                    <FormattedMessage
                        id={'webedi.deliverySchedules.create.wizard.articleDetails.customerData.plantNumber'}
                    />
                    <span className='rioglyph rioglyph-factory text-size-18 margin-left-5 margin-right-5' />
                    {placeOfDischarge?.plantCode}
                </span>
                <span className='ellipsis-1'>
                    <FormattedMessage
                        id={'webedi.deliverySchedules.create.wizard.articleDetails.customerData.placeOfDischarge'}
                    />
                    <span className='rioglyph rioglyph-load-unload text-size-18 margin-left-5 margin-right-5' />
                    {placeOfDischarge?.placeOfDischargeCode}
                </span>
            </div>
        </div>
    );
};

const rowClassName = 'display-grid grid-cols-10 align-items-center text-color-dark padding-10 margin-bottom-10 gap-15';
const formInputClassName = 'grid-colspan-5 form-group margin-0';
const labelClassName = 'grid-colspan-5 text-size-14';
const errorClassName = 'has-error has-feedback';

const ShippingLocationSelect = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const loadingLocations = useLoadingLocationsQuery().data ?? [];
    const errorLabel = errors['defaultShippingLocationId'];

    const getOptions = (selectedId: string | undefined) =>
        loadingLocations.map((it) => ({
            id: it.id,
            label: it.name,
            selected: selectedId === it.id,
        }));

    return (
        <div className={rowClassName}>
            <label className={labelClassName} htmlFor={'defaultShippingLocationId'}>
                <FormattedMessage id={'webedi.article.defaultShippingLocation'} />*
            </label>
            <div className={`${formInputClassName} ${errorLabel ? errorClassName : ''}`}>
                <Controller
                    name={'defaultShippingLocationId'}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            id={'defaultShippingLocationId'}
                            placeholder={<FormattedMessage id='webedi.inputPlaceholder.dropdown.standard' />}
                            options={getOptions(value)}
                            onChange={(selectedOption?: SelectOption) => onChange(selectedOption?.id)}
                            useFilter={true}
                        />
                    )}
                />
                <ErrorHelpBlock translations={genericInputErrorTranslations} error={errorLabel} />
            </div>
        </div>
    );
};

const MeasurementUnitSelect = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();

    const error = errors['measurementUnitCode'];

    const allowedUnits = [
        MeasurementUnitCode.PIECE,
        MeasurementUnitCode.SET,
        MeasurementUnitCode.METRE,
        MeasurementUnitCode.CENTIMETRE,
        MeasurementUnitCode.MILLIMETRE,
        MeasurementUnitCode.KILOMETRE,
        MeasurementUnitCode.SQUARE_METRE,
        MeasurementUnitCode.LEAF,
        MeasurementUnitCode.CUBIC_METRE,
        MeasurementUnitCode.LITRE,
        MeasurementUnitCode.PAIR,
        MeasurementUnitCode.ROLL,
        MeasurementUnitCode.METRIC_TON,
        MeasurementUnitCode.KILOGRAM,
        MeasurementUnitCode.GRAM,
    ];

    const getOptions = (selectedUnit: string | undefined) =>
        allowedUnits.map((it) => ({
            id: it,
            label: <MeasurementUnitCodeDisplay unit={it} />,
            selected: selectedUnit === it,
        }));

    return (
        <div className={rowClassName}>
            <label className={labelClassName} htmlFor={'measurementUnitCode'}>
                <FormattedMessage id={'webedi.deliverySchedule.scheduledArticleDetails.quantityUnit'} />*
            </label>
            <div className={`${formInputClassName} ${error ? errorClassName : ''}`}>
                <Controller
                    name={'measurementUnitCode'}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            id={'measurementUnitCode'}
                            placeholder={<FormattedMessage id='webedi.inputPlaceholder.dropdown.standard' />}
                            options={getOptions(value)}
                            onChange={(selectedOption?: SelectOption) => onChange(selectedOption?.id)}
                            useFilter={true}
                        />
                    )}
                />
                <ErrorHelpBlock translations={genericInputErrorTranslations} error={error} />
            </div>
        </div>
    );
};

const ArticleNetWeightInput = () => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const measurementUnitCode = watch('measurementUnitCode');

    return (
        <NumberFormInput
            fieldName={'articleNetWeightInKg'}
            label={
                <FormattedMessage
                    id={'webedi.article.articleNetWeight.label'}
                    values={{
                        measurementUnitCode: measurementUnitCode ? (
                            <MeasurementUnitCodeDisplay unit={measurementUnitCode} />
                        ) : (
                            <FormattedMessage id='webedi.deliveryInstruction.unit' />
                        ),
                    }}
                />
            }
            required={true}
            decimalAllowed={true}
        />
    );
};

const NetExplosiveMassInput = () => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const measurementUnitCode = watch('measurementUnitCode');

    return (
        <NumberFormInput
            fieldName={'netExplosiveMassInKg'}
            label={
                <FormattedMessage
                    id={'webedi.article.netExplosiveMassInKg.label'}
                    values={{
                        measurementUnitCode: measurementUnitCode ? (
                            <MeasurementUnitCodeDisplay unit={measurementUnitCode} />
                        ) : (
                            <FormattedMessage id='webedi.deliveryInstruction.unit' />
                        ),
                    }}
                />
            }
            required={false}
            decimalAllowed={true}
        />
    );
};

type NumberFormInputProps = InputFormProps<number> & {
    decimalAllowed: boolean;
};

const NumberFormInput = (props: NumberFormInputProps) => {
    const {
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();

    const error = errors[props.fieldName];
    const step = props.decimalAllowed ? 0.000001 : 1;

    return (
        <div className={rowClassName}>
            <label className={labelClassName} htmlFor={props.fieldName}>
                {props.label}
                {props.required && '*'}
            </label>
            <div className={`${formInputClassName} ${error ? errorClassName : ''}`}>
                <div className='input-group'>
                    <PositiveNumberFormInput
                        fieldName={props.fieldName}
                        required={props.required}
                        step={step}
                        decimalAllowed={props.decimalAllowed}
                    />
                </div>
                <ErrorHelpBlock translations={numberInputErrorTranslations} error={error} />
            </div>
        </div>
    );
};

const CountryOfOriginSelect = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const countries = useAppSelector(getDisplayCountries);

    const error = errors['countryOfOrigin'];

    const getOptions = (selectedId: string | undefined) =>
        Object.entries(countries).map(([key, value]) => ({
            id: key,
            label: formatCountryForDisplay(value, key),
            selected: selectedId === key,
        }));

    return (
        <div className={rowClassName}>
            <label className={labelClassName} htmlFor={'countryOfOrigin'}>
                <FormattedMessage id={'webedi.article.countryOfOrigin'} />*
            </label>
            <div className={`${formInputClassName} ${error ? errorClassName : ''}`}>
                <Controller
                    name={'countryOfOrigin'}
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            id={'countryOfOrigin'}
                            placeholder={<FormattedMessage id='webedi.inputPlaceholder.dropdown.standard' />}
                            options={getOptions(value)}
                            onChange={(selectedOption?: SelectOption) => onChange(selectedOption?.id)}
                            useFilter={true}
                        />
                    )}
                />
                <ErrorHelpBlock translations={genericInputErrorTranslations} error={error} />
            </div>
        </div>
    );
};

const CheckboxFormInput = (props: InputFormProps<boolean>) => {
    const { control } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();

    return (
        <div className={rowClassName}>
            <label className={labelClassName} htmlFor={props.fieldName}>
                {props.label}
                {props.required && '*'}
            </label>
            <div className={formInputClassName}>
                <Controller
                    name={props.fieldName}
                    rules={{ required: props.required }}
                    control={control}
                    render={({ field }) => (
                        <Checkbox id={props.fieldName} onChange={field.onChange} checked={Boolean(field.value)}>
                            <span className={'text-capitalize'}>
                                <FormattedMessage id={'webedi.yes'} />
                            </span>
                        </Checkbox>
                    )}
                />
            </div>
        </div>
    );
};

const UndgNumberInput = () => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const isDangerousGoods = watch('isDangerousGoods');
    if (!isDangerousGoods) {
        return null;
    }

    return (
        <TextInputForm
            fieldName={'unDangerousGoodsNumber'}
            required={true}
            label={<FormattedMessage id={'webedi.article.dangerousGoods.unDangerousGoodsNumber'} />}
            pattern={/^[0-9]{4}$/}
            errorTranslations={undgNumberInputErrorTranslations}
            rowClassName={rowClassName}
            formInputClassName={formInputClassName}
            labelClassName={labelClassName}
        />
    );
};

const DangerousGoodsDescriptionInput = () => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const isDangerousGoods = watch('isDangerousGoods');
    if (!isDangerousGoods) {
        return null;
    }
    return (
        <TextAreaInputForm
            fieldName={'dangerousGoodsDescription'}
            required={false}
            label={<FormattedMessage id={'webedi.article.dangerousGoods.description'} />}
        />
    );
};

const DangerousGoodsDeclarationExceptionInput = () => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const isDangerousGoods = watch('isDangerousGoods');
    if (!isDangerousGoods) {
        return null;
    }
    return (
        <TextAreaInputForm
            fieldName={'dangerousGoodsDeclarationException'}
            required={false}
            label={<FormattedMessage id={'webedi.article.dangerousGoods.declarationException'} />}
        />
    );
};

const TextAreaInputForm = (props: InputFormProps<string>) => {
    const { control } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();

    return (
        <div className={rowClassName}>
            <label className={labelClassName} htmlFor={props.fieldName}>
                {props.label}
                {props.required && '*'}
            </label>
            <div className={formInputClassName}>
                <Controller
                    rules={{ required: props.required }}
                    name={props.fieldName}
                    control={control}
                    render={({ field }) => (
                        <textarea
                            {...field}
                            id={props.fieldName}
                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement>) => {
                                value ? field.onChange(value) : field.onChange(undefined);
                            }}
                            rows={3}
                            className={'form-control'}
                            style={{ resize: 'none' }}
                        />
                    )}
                />
            </div>
        </div>
    );
};
