import {
    CombinePackagingOperation,
    DispatchProposalOperationType,
} from '../../../api/dispatchProposal/operations.types';
import { PackagedDispatchProposalItem } from '../../../domain/dispatchProposal.types';
import { Ownership } from '../../../reducers/shipments/packaging.types';
import { PackageTogetherFormValues } from './PackageTogetherDialog';

export const mapFormStateToCombinePackagingOperation = (
    data: PackageTogetherFormValues,
    dunsNumber: string,
    dispatchProposalId: string,
    shipmentId: string,
): CombinePackagingOperation => {
    if (isFormComplete(data)) {
        return {
            dispatchProposalId,
            dunsNumber,
            parameters: {
                shipmentId,
                giphysToCombine: data.selectedItemsToPackage.map((item) => {
                    return {
                        giphyId: item.dispatchProposalItem.identifier.id,
                        amount: item.amount,
                    };
                }),
                amountOfHandlingUnits: data.amountOfHandlingUnits,
                outerPackagingToApply: {
                    type: data.packagingMaterial.boxCode,
                    description: data.packagingMaterial.name,
                    auxiliaryPackaging: data.auxiliaryPackagings.map((auxiliaryPackaging) => {
                        return {
                            quantity: auxiliaryPackaging.amountPerHandlingUnit,
                            auxiliaryPackagingContent: {
                                type: auxiliaryPackaging.packagingMaterial.boxCode,
                                description: auxiliaryPackaging.packagingMaterial.name,
                                auxiliaryPackaging: [],
                                isReusable: auxiliaryPackaging.packagingMaterial.isReusable,
                                ownership: auxiliaryPackaging.packagingMaterial.ownership,
                                stackingFactor: auxiliaryPackaging.packagingMaterial.stackingFactor,
                                tareWeightInKg: auxiliaryPackaging.packagingMaterial.tareWeightInKg,
                            },
                        };
                    }),
                    isReusable: data.packagingMaterial.isReusable,
                    ownership: data.packagingMaterial.ownership,
                    stackingFactor: data.packagingMaterial.stackingFactor,
                    tareWeightInKg: data.packagingMaterial.tareWeightInKg,
                },
            },
            type: DispatchProposalOperationType.COMBINE_PACKAGING,
        };
    } else {
        throw new Error('Missing mandatory properties. This should not happen as we validate the form.');
    }
};

const isFormComplete = (formValues: PackageTogetherFormValues): formValues is CompletePackageTogetherFormValues => {
    if (formValues.amountOfHandlingUnits === undefined) {
        return false;
    }
    for (const giphyToAmount of formValues.selectedItemsToPackage) {
        if (giphyToAmount.amount === undefined) {
            return false;
        }
    }
    if (formValues.packagingMaterial === undefined) {
        return false;
    }
    if (formValues.packagingMaterial.boxCode === undefined) {
        return false;
    }
    if (formValues.packagingMaterial.tareWeightInKg === undefined) {
        return false;
    }
    if (formValues.packagingMaterial.isReusable === undefined) {
        return false;
    }
    if (formValues.packagingMaterial.ownership === undefined) {
        return false;
    }
    if (formValues.packagingMaterial.stackingFactor === undefined) {
        return false;
    }
    return true;
};

interface CompletePackagedDispatchProposalItemWithAmount {
    dispatchProposalItem: PackagedDispatchProposalItem;
    amount: number;
}

interface CompletePackagingMaterialFormValues {
    name?: string;
    boxCode: string;
    outerHeightInMm?: number;
    outerWidthInMm?: number;
    outerLengthInMm?: number;
    tareWeightInKg: number;
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
}

interface CompleteAuxiliaryPackagings {
    amountPerHandlingUnit: number;
    packagingMaterial: CompletePackagingMaterialFormValues;
}

interface CompletePackageTogetherFormValues {
    selectedItemsToPackage: CompletePackagedDispatchProposalItemWithAmount[];
    amountOfHandlingUnits: number;
    packagingMaterial: CompletePackagingMaterialFormValues;
    auxiliaryPackagings: CompleteAuxiliaryPackagings[];
}
