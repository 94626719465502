import { useAppSelector } from '../../../configuration/setup/typedReduxHooks';
import { getSelectedDeliverySchedule } from '../selectors/deliverySchedules/DeliverySchedules.selector';
import { BuyerContext } from '../selectors/packaging/PackagingMaterial.selector';
import { getSelectedShipment } from '../selectors/shipments/Shipments.selector';
import { useDeliveryScheduleIdFromPath, useShipmentIdFromPath } from './Routing.hooks';

export const useBuyerContextFromCurrentlySelectedDeliveryScheduleOrShipment = (): BuyerContext => {
    const shipmentIdFromPath = useShipmentIdFromPath();
    const deliveryScheduleIdFromPath = useDeliveryScheduleIdFromPath();
    const selectedShipment = useAppSelector(getSelectedShipment);
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);

    if (shipmentIdFromPath && selectedShipment && selectedShipment?.id === shipmentIdFromPath) {
        return {
            buyer: selectedShipment.buyer.partyIdentificationDetails.identifier,
            partner: selectedShipment.partner,
        };
    }
    if (
        deliveryScheduleIdFromPath &&
        selectedDeliverySchedule &&
        selectedDeliverySchedule?.id === deliveryScheduleIdFromPath
    ) {
        return {
            buyer: selectedDeliverySchedule.buyer.partyIdentificationDetails.identifier,
            partner: selectedDeliverySchedule.partner,
        };
    }
    throw new Error('Cannot obtain buyer from shipment or delivery schedule. This should never happen.');
};
