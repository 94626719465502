// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export enum MeansOfTransportType {
    MARITIME = 'MARITIME_TRANSPORT',
    RAIL = 'RAIL_TRANSPORT',
    ROAD = 'ROAD_TRANSPORT',
    AIR = 'AIR_TRANSPORT',
    MAIL = 'MAIL',
    MULTIMODAL = 'MULTIMODAL_TRANSPORT',
}

const maritimeMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.MARITIME),
    }),
    t.partial({
        ship_name: t.string,
        container_number: t.string,
    }),
]);

const railMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.RAIL),
    }),
    t.partial({
        train_number: t.string,
        rail_car_number: t.string,
    }),
]);

const roadMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.ROAD),
    }),
    t.partial({
        vehicle_registration_number: t.string,
        vehicle_registration_number_from_confirmation: t.string,
        vehicle_trailer_registration_number: t.string,
    }),
]);

const airMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.AIR),
    }),
    t.partial({
        flight_number: t.string,
    }),
]);

const mailMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.MAIL),
    }),
    t.partial({
        postal_parcel_number: t.string,
    }),
]);

const multimodalMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.MULTIMODAL),
    }),
    t.partial({
        vehicle_number_plate_interchangeable_chassis: t.string,
        swap_body_number: t.string,
    }),
]);

export const MeansOfTransportCodec = t.union([
    maritimeMeansOfTransportCodec,
    railMeansOfTransportCodec,
    roadMeansOfTransportCodec,
    airMeansOfTransportCodec,
    mailMeansOfTransportCodec,
    multimodalMeansOfTransportCodec,
]);

const updateRoadMeansOfTransportCodec = t.intersection([
    t.type({
        mode: t.literal(MeansOfTransportType.ROAD),
    }),
    t.partial({
        vehicle_registration_number: t.string,
        vehicle_trailer_registration_number: t.string,
    }),
]);

export const UpdateMeansOfTransportCodec = t.union([
    maritimeMeansOfTransportCodec,
    railMeansOfTransportCodec,
    updateRoadMeansOfTransportCodec,
    airMeansOfTransportCodec,
    mailMeansOfTransportCodec,
    multimodalMeansOfTransportCodec,
]);

export type ApiMeansOfTransport = t.TypeOf<typeof MeansOfTransportCodec>;
export type ApiUpdateMeansOfTransport = t.TypeOf<typeof UpdateMeansOfTransportCodec>;
