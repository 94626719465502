import { isEqual } from 'lodash';
import { Form } from 'react-final-form';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor } from '../../../../../utils';
import { getDocumentSettings } from '../../../selectors/shipments/ShipmentDocuments.selector';
import {
    GlobalTransportLabelsSettings,
    GtlSettingsFormValues,
    generateInitialValuesFromRedux as generateInitialValuesFromReduxGtl,
} from '../../shipments/shipmentDocuments/generatorSettings/GlobalTransportLabelsSettings';
import {
    ShippingDocumentsSettings,
    TsbSettingsFormValues,
    generateInitialValuesFromRedux as generateInitialValuesFromReduxTsb,
} from '../../shipments/shipmentDocuments/generatorSettings/ShippingDocumentsSettings';
import { FieldErrorType, FormErrors } from '../form/types';
import { ShipmentDocumentSettingsGroup } from './ShipmentDocumentSettingsDialogMenu';

export const SHIPMENT_DOCUMENT_CONTENT_SETTINGS_FORM_TEST_ID = 'SHIPMENT_DOCUMENT_CONTENT_SETTINGS_FORM_TEST_ID';
export const SHIPMENT_DOCUMENT_CONTENT_SETTINGS_DIALOG_SAVE_TEST_ID =
    'SHIPMENT_DOCUMENT_CONTENT_SETTINGS_DIALOG_SAVE_TEST_ID';

export interface ShipmentDocumentSettingsFormValues {
    gtl: GtlSettingsFormValues;
    tsb: TsbSettingsFormValues;
}

type ShipmentDocumentSettingsFormErrors = FormErrors<ShipmentDocumentSettingsFormValues>;
export const validateGTLForm = (values: ShipmentDocumentSettingsFormValues): ShipmentDocumentSettingsFormErrors => {
    const errors: ShipmentDocumentSettingsFormErrors = {
        gtl: {
            plantSpecificSettings: {},
        },
    };

    if (values.gtl?.plantSpecificSettings) {
        if (
            values.gtl.plantSpecificSettings.numberOfLabelCopies &&
            values.gtl.plantSpecificSettings.numberOfLabelCopies <= 0
        ) {
            errors.gtl!.plantSpecificSettings!.numberOfLabelCopies = FieldErrorType.NON_POSITIVE_NUMBER;
        }

        if (
            values.gtl.plantSpecificSettings.numberOfLabelCopies &&
            values.gtl.plantSpecificSettings.numberOfLabelCopies > 1
        ) {
            if (!values.gtl.plantSpecificSettings.applyForPlants) {
                errors.gtl!.plantSpecificSettings!.applyForPlants = FieldErrorType.DEPENDS_ON_NUMBER_LABEL_COPIES;
            }
        }
    }

    return errors;
};

interface ShipmentDocumentSettingsDialogContentProps {
    type: ShipmentDocumentSettingsGroup;
    onSubmit: (formValues: ShipmentDocumentSettingsFormValues) => void;
}

export const ShipmentDocumentSettingsDialogContent = (props: ShipmentDocumentSettingsDialogContentProps) => {
    const settings = useAppSelector(getDocumentSettings);

    const renderSettings = () => {
        switch (props.type) {
            case ShipmentDocumentSettingsGroup.GTL_GLOBAL_TRANSPORT_LABEL:
                return <GlobalTransportLabelsSettings inputValuesSlicePath={'gtl'} />;
            case ShipmentDocumentSettingsGroup.TSB_SHIPPING_DOCUMENTS:
                return <ShippingDocumentsSettings inputValuesSlicePath={'tsb'} />;
            default:
                return neverReachedFor(props.type);
        }
    };

    return (
        <>
            <Form<ShipmentDocumentSettingsFormValues>
                initialValues={{
                    tsb: generateInitialValuesFromReduxTsb(settings.tsbSettings),
                    gtl: generateInitialValuesFromReduxGtl(settings.gtlSettings),
                }}
                initialValuesEqual={isEqual}
                onSubmit={props.onSubmit}
                validate={validateGTLForm}
                render={({ handleSubmit }) => (
                    <form id={SHIPMENT_DOCUMENT_CONTENT_SETTINGS_FORM_TEST_ID} onSubmit={handleSubmit}>
                        {renderSettings()}
                    </form>
                )}
            />
        </>
    );
};
