// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const LoadingLocationCodec = t.intersection([
    t.type({
        id: t.string,
        duns_number_owner: t.string,
        name: t.string,
        loading_point: t.string,
        company_name: t.string,
        street: t.string,
        postal_code: t.string,
        city: t.string,
        country: t.string,
    }),
    t.partial({
        comment: t.string,
        etag: t.string,
    }),
]);

export const LoadingLocationsCodec = t.type({
    items: t.array(LoadingLocationCodec),
});

export type ApiLoadingLocation = t.TypeOf<typeof LoadingLocationCodec>;
export type ApiLoadingLocations = t.TypeOf<typeof LoadingLocationsCodec>;
