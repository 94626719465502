import { FormattedMessage } from 'react-intl';
import {
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    MissingPackagingHeightProblem,
    isMissingPackagingHeightProblem,
} from '../../../../domain/dispatchProposal.types';
import { DispatchProposalProblemBannerFunctions } from '../DispatchProposalProblemBanner';
import { BannerIcon, colorByProblemLevel } from '../DispatchProposalProblemBannerFormatters';

const filterMissingPackagingHeightProblem = (problems: DispatchProposalProblem[]) =>
    problems.filter(isMissingPackagingHeightProblem);

const useMapToMissingPackagingHeightProblem = (problemBase: DispatchProposalProblemBase[]) => {
    const problems = problemBase as MissingPackagingHeightProblem[];

    if (problems.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    return {
        title: <MissingPackagingHeightProblemTitle entries={problems} />,
        rows: [],
        formatting: {
            testId: problems[0].type,
            horizontalDivider: undefined,
            icon: <BannerIcon level={alertLevel} />,
            buttonStyle: colorByProblemLevel(alertLevel),
        },
    };
};

const MissingPackagingHeightProblemTitle = (props: { entries: MissingPackagingHeightProblem[] }) => {
    const id =
        props.entries.length === 1
            ? 'webedi.dispatchProposals.problemBanner.dispatchProposalMissingPackagingHeight.title.one'
            : 'webedi.dispatchProposals.problemBanner.dispatchProposalMissingPackagingHeight.title.multiple';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} />
        </strong>
    );
};

export const missingPackagingHeightProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterMissingPackagingHeightProblem,
    useGenerateProblemDescriptor: useMapToMissingPackagingHeightProblem,
};
