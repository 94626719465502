import { FormattedMessage } from 'react-intl';

export interface ManyHandlingUnitsProps {
    numberOfHandlingUnits?: number;
}

export const MANY_HANDLING_UNITS_THRESHOLD = 200;

export const ManyHandlingUnitsWarning = (props: ManyHandlingUnitsProps) =>
    props.numberOfHandlingUnits && props.numberOfHandlingUnits > MANY_HANDLING_UNITS_THRESHOLD ? (
        <div className={'alert alert-danger margin-top-10'} role={'alert'}>
            <FormattedMessage id={'webedi.shipment.manyHandlingUnitsWarning'} />
        </div>
    ) : null;
