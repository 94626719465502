import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { useAppDispatch } from '../../../../configuration/setup/typedReduxHooks';
import { masterDataSlice } from '../../reducers/masterData/MasterData.reducer';
import { LoadingLocationForm } from './LoadingLocationForm';
import { LoadingLocationsTable } from './LoadingLocationsTable';

export const SHIPPING_LOCATION_CREATE_TEST_ID = 'SHIPPING_LOCATION_CREATE_TEST_ID';

export const LoadingLocationsView = () => {
    const dispatch = useAppDispatch();
    const [displayLocationForm, setDisplayLocationForm] = useState(false);
    const [shippingLocationToEditId, setShippingLocationToEditId] = useState<string>(uuid());

    const openCreateDialog = () => {
        setShippingLocationToEditId(uuid());
        setDisplayLocationForm(true);
    };

    const openEditDialogForShippingLocation = (shippingLocationId: string) => {
        dispatch(masterDataSlice.actions.setSelectedShippingLocation(shippingLocationId));
        setShippingLocationToEditId(shippingLocationId);
        setDisplayLocationForm(true);
    };

    const hideDialog = () => {
        setDisplayLocationForm(false);
        dispatch(masterDataSlice.actions.setSelectedShippingLocation(undefined));
    };

    return (
        <>
            <div className={'table-toolbar margin-bottom-0'}>
                <div className={'table-toolbar-container'}>
                    <div className={'table-toolbar-group-left'}>
                        <div className={'table-toolbar-column'}>
                            <button
                                data-testid={SHIPPING_LOCATION_CREATE_TEST_ID}
                                className={'btn btn-default'}
                                onClick={() => openCreateDialog()}
                            >
                                <span className='rioglyph rioglyph-plus-light' />
                                <FormattedMessage id={'webedi.loadingLocations.button.create'} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingLocationsTable onRowClick={(shipLoc) => openEditDialogForShippingLocation(shipLoc)} />
            <LoadingLocationForm
                show={displayLocationForm}
                locationToEditId={shippingLocationToEditId}
                hideDialog={hideDialog}
            />
        </>
    );
};
