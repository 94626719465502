import { SelectOption } from '@rio-cloud/rio-uikit';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { FormattedMessage } from 'react-intl';

interface MultiFilterDropdownProps {
    className?: string;
    dropdownOptions: SelectOption[];
    labelId: string;
    onChange: (ids: string[]) => void;
}

export const MultiFilterDropdown = (props: MultiFilterDropdownProps) => {
    const compareSelectOptions = (a: SelectOption, b: SelectOption) => {
        if (typeof a.label === 'string' && typeof b.label === 'string') {
            return a.label.localeCompare(b.label);
        } else {
            return 0;
        }
    };
    const sortedOptions = [...props.dropdownOptions].sort(compareSelectOptions);
    return (
        <>
            <div className={props.className}>
                <label htmlFor={props.labelId}>
                    <FormattedMessage id={props.labelId} />
                </label>
                <Multiselect
                    disabled={sortedOptions.length === 0}
                    id={props.labelId}
                    placeholder={<FormattedMessage id={'webedi.common.filter.selectionPlaceholder'} />}
                    options={sortedOptions}
                    onChange={(ids: string[]) => props.onChange(ids)}
                />
            </div>
        </>
    );
};
