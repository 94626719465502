import { neverReachedFor } from '../../../../utils';
import {
    HeterogeneousPackagingStepContent,
    HomogeneousPackagingStepLayerStabilization,
    PackagingStep,
    PackagingStepType,
    PackagingTemplate,
    PackagingTemplateCreationRequest,
    PackagingTemplateType,
    PackagingTemplateUpdateRequest,
} from '../../reducers/deliverySchedules/types';
import { HandlingUnitPackagingMaterial } from '../../reducers/shipments/packaging.types';

import { mapDimensions, mapDimensionsToApi, mapQuantity, mapQuantityToApi } from '../shared/sharedMappers';
import { mapAuxiliaryHandlingUnitGroup, mapAuxiliaryPackagingToApi } from '../shipment/packagingMapper';
import {
    ApiHeterogeneousPackagingStepContent,
    ApiPackagingStep,
    ApiPackagingTemplate,
    ApiPackagingTemplateCreationRequest,
    ApiPackagingTemplateUpdateRequest,
    ApiPackagingTemplates,
    ApiStepLayerStabilization,
} from './packagingTemplate.types';

export const mapPackagingTemplates = (apiPackagingTemplates: ApiPackagingTemplates): PackagingTemplate[] => {
    return apiPackagingTemplates.items.map(mapPackagingTemplate);
};

export const mapPackagingTemplate = (apiPackagingTemplate: ApiPackagingTemplate): PackagingTemplate => {
    return {
        id: apiPackagingTemplate.id,
        name: apiPackagingTemplate.name,
        type: apiPackagingTemplate.type as PackagingTemplateType,
        dunsNumberOwner: apiPackagingTemplate.duns_number_owner,
        isValid: apiPackagingTemplate.is_valid,
        referencedDeliveryScheduleIds: apiPackagingTemplate.referenced_delivery_schedule_ids,
        steps: apiPackagingTemplate.steps.map(mapPackagingStep),
        dimensions: mapDimensions(apiPackagingTemplate.dimensions),
        persisted: true,
    };
};

export const mapPackagingTemplateToApi = (packagingTemplate: PackagingTemplate): ApiPackagingTemplate => {
    return {
        id: packagingTemplate.id,
        name: packagingTemplate.name,
        type: packagingTemplate.type,
        duns_number_owner: packagingTemplate.dunsNumberOwner,
        is_valid: packagingTemplate.isValid,
        referenced_delivery_schedule_ids: packagingTemplate.referencedDeliveryScheduleIds,
        steps: packagingTemplate.steps.map(mapPackagingStepToApi),
        dimensions: mapDimensionsToApi(packagingTemplate.dimensions),
    };
};

const mapApiPackagingStepToHandlingUnitPackagingMaterial = (step: ApiPackagingStep): HandlingUnitPackagingMaterial => {
    return {
        type: step.type_of_handling_unit,
        description: step.description_of_handling_unit,
        tareWeightInKg: step.tare_weight_in_kg!,
        ownership: step.ownership!,
        isReusable: step.is_reusable!,
        stackingFactor: step.stacking_factor!,
    };
};

const mapPackagingStep = (step: ApiPackagingStep): PackagingStep => {
    const handlingUnit = mapApiPackagingStepToHandlingUnitPackagingMaterial(step);
    switch (step.type) {
        case PackagingStepType.HETEROGENEOUS:
            return {
                id: step.id,
                type: PackagingStepType.HETEROGENEOUS,
                auxiliaryPackaging: step.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
                prerequisites: step.prerequisites,
                typeOfHandlingUnit: step.type_of_handling_unit,
                descriptionOfHandlingUnit: step.description_of_handling_unit,
                content: step.content.map(mapHeterogeneousPackagingStepContent),
                handlingUnit,
            };
        case PackagingStepType.HOMOGENEOUS:
            return {
                id: step.id,
                type: PackagingStepType.HOMOGENEOUS,
                auxiliaryPackaging: step.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
                prerequisites: step.prerequisites,
                typeOfHandlingUnit: step.type_of_handling_unit,
                descriptionOfHandlingUnit: step.description_of_handling_unit,
                handlingUnitTypeToBePackaged: step.handling_unit_type_to_be_packaged,
                handlingUnitDescriptionToBePackaged: step.handling_unit_description_to_be_packaged,
                numberOfHandlingUnitsPerLayer: step.number_of_handling_units_per_layer,
                numberOfLayersPerHandlingUnit: step.number_of_layers_per_handling_unit,
                layerStabilization: mapHomogeneousPackagingStepLayerStabilization(step.layer_stabilization),
                handlingUnit,
            };
        case PackagingStepType.INNER:
            return {
                id: step.id,
                type: PackagingStepType.INNER,
                auxiliaryPackaging: step.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
                prerequisites: step.prerequisites,
                typeOfHandlingUnit: step.type_of_handling_unit,
                descriptionOfHandlingUnit: step.description_of_handling_unit,
                articleNumberBuyer: step.article_number_buyer,
                quantity: mapQuantity(step.quantity),
                handlingUnit,
            };
        default:
            return neverReachedFor(step);
    }
};

export const mapPackagingStepToApi = (step: PackagingStep): ApiPackagingStep => {
    switch (step.type) {
        case PackagingStepType.HETEROGENEOUS:
            return {
                id: step.id,
                type: PackagingStepType.HETEROGENEOUS,
                auxiliary_packaging: step.auxiliaryPackaging.map(mapAuxiliaryPackagingToApi),
                prerequisites: step.prerequisites,
                type_of_handling_unit: step.typeOfHandlingUnit,
                description_of_handling_unit: step.descriptionOfHandlingUnit,
                content: step.content.map(mapHeterogeneousPackagingStepContentToApi),
                tare_weight_in_kg: step.handlingUnit.tareWeightInKg,
                ownership: step.handlingUnit.ownership,
                is_reusable: step.handlingUnit.isReusable,
                stacking_factor: step.handlingUnit.stackingFactor,
            };
        case PackagingStepType.HOMOGENEOUS:
            return {
                id: step.id,
                type: PackagingStepType.HOMOGENEOUS,
                auxiliary_packaging: step.auxiliaryPackaging.map(mapAuxiliaryPackagingToApi),
                prerequisites: step.prerequisites,
                type_of_handling_unit: step.typeOfHandlingUnit,
                description_of_handling_unit: step.descriptionOfHandlingUnit,
                handling_unit_type_to_be_packaged: step.handlingUnitTypeToBePackaged,
                number_of_layers_per_handling_unit: step.numberOfLayersPerHandlingUnit,
                number_of_handling_units_per_layer: step.numberOfHandlingUnitsPerLayer,
                layer_stabilization: mapHomogeneousPackagingStepLayerStabilizationToApi(step.layerStabilization),
                tare_weight_in_kg: step.handlingUnit.tareWeightInKg,
                ownership: step.handlingUnit.ownership,
                is_reusable: step.handlingUnit.isReusable,
                stacking_factor: step.handlingUnit.stackingFactor,
            };
        case PackagingStepType.INNER:
            return {
                id: step.id,
                type: PackagingStepType.INNER,
                auxiliary_packaging: step.auxiliaryPackaging.map(mapAuxiliaryPackagingToApi),
                prerequisites: step.prerequisites,
                type_of_handling_unit: step.typeOfHandlingUnit,
                description_of_handling_unit: step.descriptionOfHandlingUnit,
                article_number_buyer: step.articleNumberBuyer,
                quantity: mapQuantityToApi(step.quantity),
                tare_weight_in_kg: step.handlingUnit.tareWeightInKg,
                ownership: step.handlingUnit.ownership,
                is_reusable: step.handlingUnit.isReusable,
                stacking_factor: step.handlingUnit.stackingFactor,
            };
        default:
            return neverReachedFor(step);
    }
};

const mapHeterogeneousPackagingStepContent = (
    content: ApiHeterogeneousPackagingStepContent,
): HeterogeneousPackagingStepContent => {
    return {
        handlingUnitTypeToBePackaged: content.handling_unit_type_to_be_packaged,
        handlingUnitDescriptionToBePackaged: content.handling_unit_description_to_be_packaged,
        numberOfHandlingUnitsPerHandlingUnit: content.number_of_handling_units_per_handling_unit,
        handlingUnit: {
            type: content.handling_unit_type_to_be_packaged,
            description: content.handling_unit_description_to_be_packaged,
            isReusable: content.is_reusable,
            ownership: content.ownership,
            stackingFactor: content.stacking_factor,
            tareWeightInKg: content.tare_weight_in_kg,
        },
    };
};

const mapHeterogeneousPackagingStepContentToApi = (
    content: HeterogeneousPackagingStepContent,
): ApiHeterogeneousPackagingStepContent => {
    return {
        handling_unit_type_to_be_packaged: content.handlingUnitTypeToBePackaged,
        handling_unit_description_to_be_packaged: content.handlingUnitDescriptionToBePackaged,
        number_of_handling_units_per_handling_unit: content.numberOfHandlingUnitsPerHandlingUnit,
        is_reusable: content.handlingUnit.isReusable,
        ownership: content.handlingUnit.ownership,
        stacking_factor: content.handlingUnit.stackingFactor,
        tare_weight_in_kg: content.handlingUnit.tareWeightInKg,
    };
};

const mapHomogeneousPackagingStepLayerStabilization = (
    content: ApiStepLayerStabilization,
): HomogeneousPackagingStepLayerStabilization => {
    return {
        handlingUnitType: content.handling_unit_type,
        handlingUnitDescription: content.handling_unit_description,
        required: content.required,
        auxiliaryPackaging: content.auxiliary_packaging.map(mapAuxiliaryHandlingUnitGroup),
        isReusable: content.is_reusable,
        ownership: content.ownership,
        stackingFactor: content.stacking_factor,
        tareWeightInKg: content.tare_weight_in_kg,
    };
};

const mapHomogeneousPackagingStepLayerStabilizationToApi = (
    content: HomogeneousPackagingStepLayerStabilization,
): ApiStepLayerStabilization => {
    return {
        handling_unit_type: content.handlingUnitType,
        handling_unit_description: content.handlingUnitDescription,
        required: content.required,
        auxiliary_packaging: content.auxiliaryPackaging.map(mapAuxiliaryPackagingToApi),
        is_reusable: content.isReusable,
        ownership: content.ownership,
        stacking_factor: content.stackingFactor,
        tare_weight_in_kg: content.tareWeightInKg,
    };
};

export const mapPackagingTemplateCreationRequestToApi = (
    request: PackagingTemplateCreationRequest,
): ApiPackagingTemplateCreationRequest => {
    return {
        name: request.name,
        type: request.type,
        referenced_delivery_schedule_ids: request.referencedDeliveryScheduleIds,
        steps: request.steps.map(mapPackagingStepToApi),
        dimensions: mapDimensionsToApi(request.dimensions),
    };
};

export const mapPackagingTemplateUpdatedRequestToApi = (
    request: PackagingTemplateUpdateRequest,
): ApiPackagingTemplateUpdateRequest => {
    return {
        name: request.name,
        steps: request.steps.map(mapPackagingStepToApi),
        dimensions: mapDimensionsToApi(request.dimensions),
    };
};
