// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { AddressCodec } from '../shared/address.types';
import { fromEnum } from '../util';

export enum ApiBrand {
    PORSCHE = 'PORSCHE',
    LAMBORGHINI = 'LAMBORGHINI',
    BENTLEY = 'BENTLEY',
    MAN = 'MAN',
    VOLKSWAGEN_STANDARD = 'VOLKSWAGEN_STANDARD',
}

const placeOfDischargeMasterDataCodec = t.intersection([
    t.type({
        id: t.string,
        plant_code: t.string,
        place_of_discharge_code: t.string,
        brand: fromEnum<ApiBrand>('ApiBrand', ApiBrand),
    }),
    t.partial({
        plant_name: t.string,
        address: AddressCodec,
    }),
]);

export type ApiPlaceOfDischargeMasterData = t.TypeOf<typeof placeOfDischargeMasterDataCodec>;

export const getPlacesOfDischargeMasterDataResponseCodec = t.type({
    items: t.array(placeOfDischargeMasterDataCodec),
});
