import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { decodeJson } from '../apiUtils';
import { TimeWindow, TransportOrderIntent } from './types';

const timeWindow = t.type({
    time_from: t.string,
    time_to: t.string,
});

const transportOrderIntentRequired = t.type(
    {
        id: t.string,
        shipment_id: t.string,
        valid_for_ordering: t.boolean,
        pickup_time_window: timeWindow,
        delivery_date: t.string,
        cut_off_at: t.string,
        created_at: t.string,
        last_modified_at: t.string,
        etag: t.string,
    },
    'transportOrderIntent',
);

const transportOrderIntentOptional = t.partial({
    sent_at: t.string,
    access_code: t.string,
});

const transportOrderIntentCodec = t.intersection([transportOrderIntentRequired, transportOrderIntentOptional]);

const transportOrderIntentListCodec = t.type(
    {
        items: t.array(transportOrderIntentCodec),
    },
    'transportOrderIntentList',
);

type TransportOrderIntentApi = t.TypeOf<typeof transportOrderIntentCodec>;
type TransportOrderIntentListApi = t.TypeOf<typeof transportOrderIntentListCodec>;
const decodeTransportOrderIntentListApiResponse = (parsedObject: unknown): TransportOrderIntentListApi =>
    decodeJson(transportOrderIntentListCodec)(parsedObject);

const mapToTransportOrderIntent = (decoded: TransportOrderIntentApi): TransportOrderIntent => ({
    id: decoded.id,
    shipmentId: decoded.shipment_id,
    sentAt: decoded.sent_at,
    createdAt: decoded.created_at,
    validForOrdering: decoded.valid_for_ordering,
    pickupTimeWindow: mapToTimeWindow(decoded.pickup_time_window),
    accessCode: decoded.access_code,
    deliveryDate: decoded.delivery_date,
    cutOffAt: decoded.cut_off_at,
    lastModifiedAt: decoded.last_modified_at,
    version: decoded.etag,
});

const mapToTimeWindow = (decoded: TransportOrderIntentApi['pickup_time_window']): TimeWindow => ({
    timeFrom: decoded.time_from,
    timeTo: decoded.time_to,
});

const transformTransportOrderIntentList = (response: unknown): TransportOrderIntent | undefined => {
    const decoded = decodeTransportOrderIntentListApiResponse(response);
    const transportOrderIntents = decoded.items.map((it) => mapToTransportOrderIntent(it));
    if (transportOrderIntents.length <= 1) {
        return transportOrderIntents[0];
    } else {
        throw Error('More than one transport order returned for shipment');
    }
};

export const transportOrderIntentApi = createApi({
    reducerPath: 'transportOrderIntentApi',
    tagTypes: ['TransportOrderIntents'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTransportOrderIntent: builder.query<
            TransportOrderIntent | undefined,
            { dunsNumber: string; shipmentId: string }
        >({
            query: ({ dunsNumber, shipmentId }) =>
                `/shippers/${dunsNumber}/transport-order-intents?shipment_id=${shipmentId}`,
            transformResponse: (response: unknown) => transformTransportOrderIntentList(response),
            providesTags: (_result, error, { shipmentId }) =>
                error === undefined
                    ? [
                          {
                              type: 'TransportOrderIntents',
                              id: shipmentId,
                          },
                      ]
                    : [],
        }),
    }),
});

export const { useGetTransportOrderIntentQuery } = transportOrderIntentApi;
