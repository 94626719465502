import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { CourierOrder, CourierOrderValidation } from '../../domain/courierOrderIntent.types';

export interface CourierOrderIntentState {
    isDialogOpen: boolean;
    selectedDispatchProposalId: string | undefined;
    courierOrder: CourierOrder | undefined;
    orderValidationErrors: CourierOrderValidationWithId[];
}

export type CourierOrderValidationWithId = { dispatchProposalId: string; errors: CourierOrderValidation[] };

const getInitialState = (): CourierOrderIntentState => {
    return {
        selectedDispatchProposalId: undefined,
        isDialogOpen: false,
        courierOrder: undefined,
        orderValidationErrors: [],
    };
};

export const initialState: CourierOrderIntentState = getInitialState();

export const courierOrderIntentSlice = createSlice({
    name: 'courierOrderIntent',
    initialState,
    reducers: {
        startCourierOrderIntentCommissioning: (state: CourierOrderIntentState, action: PayloadAction<string>) => {
            state.selectedDispatchProposalId = action.payload;
            state.isDialogOpen = true;
        },
        stopCourierOrderIntentCommissioning: (state: CourierOrderIntentState) => {
            state.selectedDispatchProposalId = undefined;
            state.isDialogOpen = false;
        },
        setCourierOrder: (state: CourierOrderIntentState, action: PayloadAction<CourierOrder>) => {
            state.courierOrder = action.payload;
        },
        setOrderValidationErrors: (
            state: CourierOrderIntentState,
            action: PayloadAction<{
                selectedDispatchProposalId: string;
                orderValidationErrors: CourierOrderValidation[];
            }>,
        ) => {
            const errorsWithoutSelectedId = state.orderValidationErrors.filter(
                (item) => item.dispatchProposalId !== action.payload.selectedDispatchProposalId,
            );
            if (!isEmpty(action.payload.orderValidationErrors)) {
                errorsWithoutSelectedId.push({
                    dispatchProposalId: action.payload.selectedDispatchProposalId,
                    errors: action.payload.orderValidationErrors,
                });
            }
            state.orderValidationErrors = errorsWithoutSelectedId;
        },
    },
});
