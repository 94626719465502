import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isAfter, isTodayOrLater } from '../../../../dateUtils';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { getOverviewSelectedDate } from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { CreateDispatchProposalDialog } from './CreateDispatchProposalDialog';
import { DispatchProposalsFilterRow } from './DispatchProposalsFilterRow';
import { DispatchProposalsFilterToggleButton } from './DispatchProposalsFilterToggleButton';
import { DispatchProposalsSelectedFilters } from './DispatchProposalsSelectedFilters';
import { useCurrentDateTime } from './dispatchProposalHooks';

export const DispatchProposalToolbar = () => {
    const now = useCurrentDateTime();
    const [showCreateDispatchProposalDialog, setShowCreateDispatchProposalDialog] = useState<boolean>(false);
    const [createDispatchProposalTransportConcept, setCreateDispatchProposalTransportConcept] =
        useState<TransportConcept>(TransportConcept.FTL);
    const selectedDay = useAppSelector(getOverviewSelectedDate);
    const selectedDayCutoff = `${selectedDay}T12:00:00`;
    // The timezone itself doesn't matter. The dates only have to be in the same TZ.
    const dropDownDisabled = !isTodayOrLater(selectedDay);
    const createFtlOrLtlDispatchProposalDisabled = isAfter(now, selectedDayCutoff);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const toolbarGroupRight = (
        <div className={'table-toolbar-group-right flex-nowrap'}>
            <div className={'table-toolbar-column'}>
                <DispatchProposalsSelectedFilters />
            </div>
            <div className={'table-toolbar-column'}>
                <DispatchProposalsFilterToggleButton
                    active={showFilter}
                    toggleShow={() => setShowFilter((current) => !current)}
                />
            </div>
        </div>
    );

    return (
        <>
            <TableToolbar>
                <div className={'table-toolbar-container flex-nowrap'}>
                    <div className={'table-toolbar-group-left'}>
                        <div className={'table-toolbar-column'}>
                            <ButtonDropdown
                                disabled={dropDownDisabled}
                                dropdownClassName={'min-width-250'}
                                title={
                                    <>
                                        <span className={'rioglyph rioglyph-plus-light'} />
                                        <FormattedMessage
                                            id={
                                                'webedi.dispatchProposals.overview.toolbar.addManualDispatchProposal.title'
                                            }
                                        />
                                    </>
                                }
                                items={[
                                    {
                                        value: (
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.overview.toolbar.addManualDispatchProposal.ftl'
                                                }
                                            />
                                        ),
                                        disabled: createFtlOrLtlDispatchProposalDisabled,
                                        onSelect: () => {
                                            setCreateDispatchProposalTransportConcept(TransportConcept.FTL);
                                            setShowCreateDispatchProposalDialog(true);
                                        },
                                    },
                                    {
                                        value: (
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.overview.toolbar.addManualDispatchProposal.ltl'
                                                }
                                            />
                                        ),
                                        onSelect: () => {
                                            setCreateDispatchProposalTransportConcept(TransportConcept.LTL);
                                            setShowCreateDispatchProposalDialog(true);
                                        },
                                        disabled: createFtlOrLtlDispatchProposalDisabled,
                                    },
                                    {
                                        value: (
                                            <FormattedMessage
                                                id={
                                                    'webedi.dispatchProposals.overview.toolbar.addManualDispatchProposal.kep'
                                                }
                                            />
                                        ),
                                        onSelect: () => {
                                            setCreateDispatchProposalTransportConcept(TransportConcept.KEP);
                                            setShowCreateDispatchProposalDialog(true);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    {toolbarGroupRight}
                </div>
            </TableToolbar>

            <div className={'table-row margin-bottom-10'}>
                <Collapse open={showFilter} unmountOnExit={true}>
                    <div className={'panel-wrapper'}>
                        <div className={'panel panel-default'}>
                            <div className={'display-flex justify-content-end'}>
                                <button
                                    className={'btn btn-xs btn-icon-only btn-outline'}
                                    onClick={() => setShowFilter(false)}
                                    data-testid={'dispatch-proposals-filter-close-button'}
                                >
                                    <span className={'rioglyph rioglyph-remove toggle-icon close'} />
                                </button>
                            </div>
                            <div className={'panel-body'}>
                                <DispatchProposalsFilterRow />
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>

            <CreateDispatchProposalDialog
                show={showCreateDispatchProposalDialog}
                transportConcept={createDispatchProposalTransportConcept}
                onClose={() => setShowCreateDispatchProposalDialog(false)}
            />
        </>
    );
};
