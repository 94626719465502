// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import {
    DocumentLanguage,
    GtlLabelSize,
    GtlPaperFormat,
    TsbPaperFormat,
} from '../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { fromEnum } from '../util';

const DocumentLanguageCodec = fromEnum<DocumentLanguage>('DocumentLanguage', DocumentLanguage);
const GtlLabelSizeCodec = fromEnum<GtlLabelSize>('GtlLabelSize', GtlLabelSize);
const GtlPaperFormatCodec = fromEnum<GtlPaperFormat>('GtlPaperFormat', GtlPaperFormat);
const TsbPaperFormatCodec = fromEnum<TsbPaperFormat>('TsbPaperFormat', TsbPaperFormat);

const ExtraMarginInMmCodec = t.exact(
    t.type({
        bottom: t.number,
        top: t.number,
    }),
);

const PlantSpecificSettingsCodec = t.exact(
    t.type({
        num_label_copies: t.number,
        apply_for_plants: t.array(t.string),
    }),
);

const ShipmentDocumentsSettingsCodec = t.partial({
    tsb_settings: t.partial({
        language: DocumentLanguageCodec,
        comments: t.array(
            t.exact(
                t.type({
                    consignment_number: t.string,
                    comment: t.string,
                }),
            ),
        ),
        include_receipt_information: t.boolean,
        include_comments: t.boolean,
        merge_packages: t.boolean,
        merge_loading_units: t.boolean,
        paper_format: TsbPaperFormatCodec,
        copies: t.partial({
            num_shipment_master_sheets: t.number,
            num_shipment_position_sheets: t.number,
            num_barcode_sheets: t.number,
            num_loading_unit_sheets: t.number,
        }),
    }),
    gtl_settings: t.partial({
        language: DocumentLanguageCodec,
        include_labels_for_intermediate_levels: t.boolean,
        label_size: GtlLabelSizeCodec,
        paper_format: GtlPaperFormatCodec,
        special_sizes: t.partial({
            additional_small_klt_types: t.array(t.string),
            tray_types: t.array(t.string),
        }),
        extra_margin_in_mm: ExtraMarginInMmCodec,
        include_labels_only_for_outermost_level: t.boolean,
        plant_specific_settings: PlantSpecificSettingsCodec,
    }),
});

const ShipmentDocumentsRequestCodec = t.intersection([
    t.type({
        asn_url: t.string,
    }),
    t.partial({
        document_settings: ShipmentDocumentsSettingsCodec,
    }),
]);

export const DocumentResponseCodec = t.exact(
    t.type({
        url: t.string,
        has_warnings: t.boolean,
    }),
);

export const ShipmentDocumentsResponseCodec = t.exact(
    t.type({
        tsb_document: DocumentResponseCodec,
        gtl_document: DocumentResponseCodec,
    }),
);

export const FileDownloadLinkCodec = t.exact(
    t.type({
        url: t.string,
    }),
);

const DeliveryForecastDocumentsSettingsCodec = t.partial({
    language: DocumentLanguageCodec,
    article_number_buyer: t.string,
});

const DeliveryForecastDocumentsRequestCodec = t.intersection([
    t.type({
        delivery_forecast_url: t.string,
    }),
    t.partial({
        document_settings: DeliveryForecastDocumentsSettingsCodec,
    }),
]);

export type ApiShipmentDocumentsRequest = t.TypeOf<typeof ShipmentDocumentsRequestCodec>;
export type ApiDocumentSettings = t.TypeOf<typeof ShipmentDocumentsSettingsCodec>;
export type ApiShipmentDocumentsResponse = t.TypeOf<typeof ShipmentDocumentsResponseCodec>;
export type ApiDeliveryForecastDocumentsRequest = t.TypeOf<typeof DeliveryForecastDocumentsRequestCodec>;
export type ApiDeliveryForecastDocumentSettings = t.TypeOf<typeof DeliveryForecastDocumentsSettingsCodec>;
