import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../../hooks/useImage';
import {
    AuxiliaryHandlingUnitGroup,
    LayerStabilizationInformation,
} from '../../../../reducers/auxiliaryPackaging.types';
import { PackagingStepType } from '../../../../reducers/deliverySchedules/types';
import { PackagingMaterialState } from '../../../../reducers/packaging/types';
import { PackagingType, PackagingWizard } from '../../../../reducers/shipments/packaging.types';
import { getTemplateWizardState } from '../../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { getPackagingWizardInfo } from '../../../../selectors/packaging/Packaging.selector';
import { findPackagingMaterialResultByBoxCode } from '../../PackagingCommon';
import { AddAuxiliaryPackaging, validateAddAuxiliaryPackaging } from './AddAuxiliaryPackaging';
import { AuxiliaryPackagingConfigurationFormId } from './AuxiliaryPackagingFormConfiguration';
import { LayerStabilization, validateLayerStabilization } from './LayerStabilization';
import {
    AuxiliaryPackagingConfigurationFormValues,
    AuxiliaryPackagingFormErrors,
    DialogAuxiliaryPackaging,
} from './types';

export const AUXILIARY_PACKAGING_CONFIGURATION_TEST_ID = 'AUXILIARY_PACKAGING_CONFIGURATION_TEST_ID';
export const AUXILIARY_PACKAGING_CONFIGURATION_COUNTER_TEST_ID = 'AUXILIARY_PACKAGING_CONFIGURATION_COUNTER_TEST_ID';
export const AUXILIARY_PACKAGING_CONFIGURATION_HINT_FOR_WHICH_HANDLING_UNIT_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_HINT_FOR_WHICH_HANDLING_UNIT_TEST_ID';
export const AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_TEST_ID';

export const mapAuxiliaryPackagingFormValues = (
    packagingMaterials: PackagingMaterialState['packagingMaterials'],
    auxiliaryPackagingsForm?: DialogAuxiliaryPackaging[],
): AuxiliaryHandlingUnitGroup[] => {
    return auxiliaryPackagingsForm
        ? auxiliaryPackagingsForm.map((unit) => {
              const description = findPackagingMaterialResultByBoxCode(
                  unit.packagingMaterial?.boxCode,
                  packagingMaterials,
              )?.packagingMaterial?.name;
              return {
                  quantity: unit.auxiliaryPackagingPerHandlingUnit!,
                  auxiliaryPackagingContent: {
                      type: unit.packagingMaterial!.boxCode!,
                      description,
                      auxiliaryPackaging: [], // no nested aux packaging,
                      isReusable: unit.packagingMaterial!.isReusable!,
                      ownership: unit.packagingMaterial!.ownership!,
                      stackingFactor: unit.packagingMaterial!.stackingFactor!,
                      tareWeightInKg: unit.packagingMaterial!.tareWeightInKg!,
                  },
              };
          })
        : [];
};

export const validateForm = (
    auxiliaryPackagingFormValues: AuxiliaryPackagingConfigurationFormValues,
): AuxiliaryPackagingFormErrors => {
    const validationResult: AuxiliaryPackagingFormErrors = {};
    const auxPackageErrors = validateAddAuxiliaryPackaging(auxiliaryPackagingFormValues);
    if (auxPackageErrors) {
        validationResult.auxiliaryPackagings = auxPackageErrors;
    }
    const layerStabilizationErrors = validateLayerStabilization(auxiliaryPackagingFormValues);
    if (layerStabilizationErrors) {
        validationResult.layerStabilization = layerStabilizationErrors;
    }
    return validationResult;
};

export interface AuxiliaryPackagingConfigurationProps {
    stabilizationInformation?: LayerStabilizationInformation;
    onSubmitHandler: (values: AuxiliaryPackagingConfigurationFormValues) => void;
    newPackagingFromWizardType?: string;
}

const AuxiliaryPackagingHeaderInfoText = (props: { packagingMaterial: string | undefined }) => (
    <span className={'margin-bottom-0'}>
        <FormattedMessage
            id={'webedi.packaging.auxiliaryPackaging.explanation'}
            values={{
                packagingMaterial: props.packagingMaterial,
            }}
        />
    </span>
);

const AuxiliaryHeaderForPackagingWithCounter = (props: { counter: string; packagingMaterial: string | undefined }) => (
    <div className={'display-flex flex-column margin-bottom-20'}>
        <span className={'text-size-18 text-center margin-bottom-25'}>
            <FormattedMessage id={'webedi.packaging.auxiliaryPackaging.title'} />
        </span>
        <div className={'counter color-highlight'} data-testid={AUXILIARY_PACKAGING_CONFIGURATION_COUNTER_TEST_ID}>
            <div data-count={`${props.counter}`}>
                <AuxiliaryPackagingHeaderInfoText packagingMaterial={props.packagingMaterial} />
            </div>
        </div>
    </div>
);

export const AuxiliaryHeaderWithoutCounter = (props: { packagingMaterial: string | undefined }) => (
    <div className={'display-flex flex-column margin-bottom-20'}>
        <span className={'text-size-18 text-center margin-bottom-25'}>
            <FormattedMessage id={'webedi.packaging.auxiliaryPackaging.title'} />
        </span>
        <AuxiliaryPackagingHeaderInfoText packagingMaterial={props.packagingMaterial} />
    </div>
);
const AuxiliaryPackagingHeaderWithOptionalCounter = (props: {
    packagingWizardInfo: PackagingWizard | undefined;
    packagingMaterial: string | undefined;
}) => {
    const templateWizardState = useAppSelector(getTemplateWizardState);

    if (props.packagingWizardInfo) {
        if (props.packagingWizardInfo.type === PackagingType.INNER) {
            return <AuxiliaryHeaderForPackagingWithCounter packagingMaterial={props.packagingMaterial} counter={'4'} />;
        } else {
            return <AuxiliaryHeaderWithoutCounter packagingMaterial={props.packagingMaterial} />;
        }
    }
    if (templateWizardState?.type === PackagingStepType.INNER) {
        return <AuxiliaryHeaderForPackagingWithCounter packagingMaterial={props.packagingMaterial} counter={'3'} />;
    }
    return <AuxiliaryHeaderWithoutCounter packagingMaterial={props.packagingMaterial} />;
};

export const AuxiliaryPackagingConfiguration = (props: AuxiliaryPackagingConfigurationProps) => {
    const { stabilizationInformation, onSubmitHandler, newPackagingFromWizardType } = props;
    const packagingWizardInfo = useAppSelector(getPackagingWizardInfo);
    const auxiliaryPackagingImage = useImage('auxiliaryPackaging');

    const initialValues: AuxiliaryPackagingConfigurationFormValues = {
        layerStabilization: stabilizationInformation && {
            auxiliaryPackagingPerHandlingUnit: stabilizationInformation.freeSpots,
            packagingMaterial:
                stabilizationInformation.handlingUnit !== undefined
                    ? {
                          name: stabilizationInformation.handlingUnit.description,
                          boxCode: stabilizationInformation.handlingUnit.type,
                          isReusable: stabilizationInformation.handlingUnit.isReusable,
                          tareWeightInKg: stabilizationInformation.handlingUnit.tareWeightInKg,
                          ownership: stabilizationInformation.handlingUnit.ownership,
                          stackingFactor: stabilizationInformation.handlingUnit.stackingFactor,
                      }
                    : undefined,
        },
        automaticallyCalculateLayerStabilization: true,
    };

    return (
        <Form<AuxiliaryPackagingConfigurationFormValues>
            onSubmit={onSubmitHandler}
            initialValues={initialValues}
            keepDirtyOnReinitialize={true}
            mutators={{ ...arrayMutators }}
            validate={validateForm}
            render={({
                handleSubmit,
                form: {
                    mutators: { push },
                },
                values,
            }) => {
                return (
                    <form
                        className={'display-flex flex-column flex-1-1-0 height-100pct justify-content-around'}
                        data-testid={AUXILIARY_PACKAGING_CONFIGURATION_TEST_ID}
                        id={AuxiliaryPackagingConfigurationFormId.form}
                        onSubmit={handleSubmit}
                    >
                        <AuxiliaryPackagingHeaderWithOptionalCounter
                            packagingWizardInfo={packagingWizardInfo}
                            packagingMaterial={newPackagingFromWizardType}
                        />
                        <div
                            className={
                                'display-flex flex-column border-style-solid border-color-light border-width-1 padding-left-5pct padding-right-5pct padding-20 margin-bottom-25'
                            }
                        >
                            <span className={'margin-top-25 margin-bottom-25 text-center'}>
                                <img
                                    src={auxiliaryPackagingImage}
                                    className={'width-40pct'}
                                    alt={'auxiliaryPackaging'}
                                />
                            </span>
                            <div className={`display-flex ${stabilizationInformation && 'margin-bottom-20'}`}>
                                <AddAuxiliaryPackaging
                                    addAuxiliaryPackagingItem={(propertyName) =>
                                        push(propertyName, {
                                            auxiliaryPackagingPerHandlingUnit: 1,
                                        })
                                    }
                                    packagingType={packagingWizardInfo ? packagingWizardInfo.type : undefined}
                                />
                            </div>
                        </div>
                        {stabilizationInformation !== undefined && (
                            <LayerStabilization disableInputs={values.automaticallyCalculateLayerStabilization} />
                        )}
                    </form>
                );
            }}
        />
    );
};
