import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import lodDash from 'lodash';
import { Dispatch, State } from '../../../../configuration/setup/store';
import {
    fetchShipmentSuggestions,
    fetchShipmentSuggestionsFilterValues,
} from '../../api/suggestion/shipmentSuggestionCalls';
import { shipmentSuggestionsSlice } from '../../reducers/suggestions/ShipmentSuggestions.reducer';
import { ShipmentSuggestionResponse, ShipmentSuggestionsQueryParameters } from '../../reducers/suggestions/types';
import { FilterValues } from '../../reducers/types';
import { getShipmentSuggestionsQuery } from '../../selectors/suggestions/ShipmentSuggestions.selector';

export const fetchAndStoreShipmentSuggestions = (queryChanges?: Partial<ShipmentSuggestionsQueryParameters>) => {
    return async (dispatch: Dispatch, getState: () => State) => {
        dispatch(shipmentSuggestionsSlice.actions.setIsLoading());
        const currentQuery = getShipmentSuggestionsQuery(getState());
        const query = { ...currentQuery, params: { ...currentQuery.params, ...queryChanges } };

        dispatch(shipmentSuggestionsSlice.actions.updateQuery(query));
        return fetchShipmentSuggestions(query)
            .then((shipmentSuggestionsResponse: ShipmentSuggestionResponse) => {
                const queryAfterFetch = getShipmentSuggestionsQuery(getState());
                if (!lodDash.isEqual(queryAfterFetch, query)) {
                    return undefined;
                }

                return dispatch(
                    shipmentSuggestionsSlice.actions.updateShipmentSuggestions(shipmentSuggestionsResponse),
                );
            })
            .catch((error: Error) => {
                dispatch(shipmentSuggestionsSlice.actions.setFailed());
                Notification.error(error.message);
            });
    };
};

export const fetchAndStoreShipmentSuggestionsFilterValues = (dunsNumber: string) => async (dispatch: Dispatch) => {
    return fetchShipmentSuggestionsFilterValues(dunsNumber)
        .then((filterValues: FilterValues) => dispatch(shipmentSuggestionsSlice.actions.setFilterValues(filterValues)))
        .catch((error: Error) => {
            Notification.error(error.message);
        });
};
