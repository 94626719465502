import { AuxiliaryPackagingConfigurationFormValues, DialogAuxiliaryPackaging } from './types';

export const AuxiliaryPackagingConfigurationFormId = {
    form: 'auxiliaryPackagingConfigurationDialog',
    auxiliaryPackagingPerHandlingUnit: 'auxiliaryPackagingPerHandlingUnit',
    layerStabilization: 'layerStabilization',
    automaticallyCalculateLayerStabilization: 'automaticallyCalculateLayerStabilization',
    packagingMaterial: 'packagingMaterial',
};

export const auxiliaryFormPropertyNames: Record<
    keyof AuxiliaryPackagingConfigurationFormValues | keyof DialogAuxiliaryPackaging,
    keyof AuxiliaryPackagingConfigurationFormValues | keyof DialogAuxiliaryPackaging
> = {
    auxiliaryPackagings: 'auxiliaryPackagings',
    auxiliaryPackagingPerHandlingUnit: 'auxiliaryPackagingPerHandlingUnit',
    layerStabilization: 'layerStabilization',
    automaticallyCalculateLayerStabilization: 'automaticallyCalculateLayerStabilization',
    packagingMaterial: 'packagingMaterial',
};
