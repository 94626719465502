import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import { MenuItemProps } from '@rio-cloud/rio-uikit/MenuItem';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/lib/es/SimpleButtonDropdown';
import { MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../configuration/tokenHandling/selectors';
import { zeroPadNumber } from '../../../../utils';
import {
    deleteShipmentAndRefresh,
    exportShipment,
    getShipmentsAction,
    setSelectedShipment,
} from '../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { DeliveryNote, Shipment } from '../../reducers/shipments/types';
import { getShipments, isLoadingShipments } from '../../selectors/shipments/Shipments.selector';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { Tooltip } from '../common/Tooltip';
import { DeletionConfirmationDialog } from '../common/dialog/DeletionConfirmationDialog';
import { FormattedDateOrDateTime } from '../common/i18n/FormattedDateOrDateTime';
import { FormattedManufacturingCompany } from '../deliverySchedules/FormattedManufacturingCompany';
import { SteppedProgressBarStep } from './ShipmentSteppedProgressConfig';
import { ShipmentListEntryProps, ShipmentsListProps } from './types';

export const SHIPMENTS_LIST_TEST_ID = 'SHIPMENTS_LIST_TEST_ID';
export const SHIPMENTS_LIST_HEADER_TEST_ID = 'SHIPMENTS_LIST_HEADER_TEST_ID';
export const SHIPMENT_LIST_ITEM_TEST_ID = 'SHIPMENT_LIST_ITEM_TEST_ID';
export const SHIPMENT_LIST_ITEM_CONTAINED_ARTICLES_COLUMN_CONTENT_TEST_ID =
    'SHIPMENT_LIST_ITEM_CONTAINED_ARTICLES_COLUMN_CONTENT_TEST_ID';
export const SHIPMENT_LIST_ITEM_CONTAINED_ARTICLES_COLUMN_TOOLTIP_TEST_ID =
    'SHIPMENT_LIST_ITEM_CONTAINED_ARTICLES_COLUMN_TOOLTIP_TEST_ID';
export const SHIPMENT_LIST_ITEM_DELIVERY_NOTES_COLUMN_CONTENT_TEST_ID =
    'SHIPMENT_LIST_ITEM_DELIVERY_NOTES_COLUMN_CONTENT_TEST_ID';
export const SHIPMENT_LIST_ITEM_DELIVERY_NOTES_COLUMN_TOOLTIP_TEST_ID =
    'SHIPMENT_LIST_ITEM_DELIVERY_NOTES_COLUMN_TOOLTIP_TEST_ID';
export const SHIPMENT_LIST_ITEM_EDITING_STATUS_COLUMN_TEST_ID = 'SHIPMENT_LIST_ITEM_EDITING_STATUS_COLUMN_TEST_ID';
export const SHIPMENT_DETAILS_BUTTON_TEST_ID = 'SHIPMENT_DETAILS_BUTTON_TEST_ID';
export const SHIPMENT_DETAILS_ACTION_DROPDOWN_TEST_ID = 'SHIPMENT_DETAILS_ACTION_DROPDOWN_TEST_ID';

const tableClassNames = [
    'table',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled',
    'margin-bottom-20',
].join(' ');

interface DeliveryDateProps {
    requestedDeliveryDate?: string;
    estimatedArrivalDate?: string;
}

export const DeliveryDate = (props: DeliveryDateProps) => {
    const { requestedDeliveryDate, estimatedArrivalDate } = props;

    if (requestedDeliveryDate !== undefined) {
        return <FormattedDateOrDateTime date={requestedDeliveryDate} />;
    } else if (estimatedArrivalDate !== undefined) {
        return <FormattedDateOrDateTime date={estimatedArrivalDate} />;
    }
    return <span>N/A</span>;
};

export const ShipmentsList = (props: ShipmentsListProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isWidthSm = useMediaQuery({ maxWidth: 890 });
    const dunsNumber = useDunsNumberFromPath();
    const shipments = useAppSelector(getShipments);
    const isLoading = useAppSelector(isLoadingShipments);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);
    const exported = props.exported;

    const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] = useState(false);
    const [selectedShipmentToBeDeleted, setSelectedShipmentToBeDeleted] = useState<Shipment>();

    useEffect(() => {
        dispatch(getShipmentsAction(dunsNumber!, exported));
    }, [dunsNumber, exported, dispatch]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (shipments.length === 0) {
        return <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />;
    }

    const getShipmentEditingStatus = (shipment: Shipment): SteppedProgressBarStep[] => {
        const shipmentEditingState = [SteppedProgressBarStep.DELIVERY_NOTE];
        shipment.packaging.length > 0 && shipmentEditingState.push(SteppedProgressBarStep.PACKAGING);
        (shipment.termsOfDelivery || shipment.meansOfTransport || shipment.freightForwarder) &&
            shipmentEditingState.push(SteppedProgressBarStep.TRANSPORT);
        return shipmentEditingState;
    };

    const detailsButtonClickHandler = (event: MouseEvent, shipment: Shipment) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(setSelectedShipment(dunsNumber!, shipment, navigate));
    };

    const exportButtonClickHandler = (shipment: Shipment) => {
        dispatch(exportShipment(dunsNumber!, shipment, navigate));
    };

    const deleteShipment = (shipment: Shipment) => {
        dispatch(deleteShipmentAndRefresh(dunsNumber!, shipment.id, props.queryParams));
        setShowDeletionConfirmationDialog(false);
    };

    const deleteButtonClickHandler = (shipment: Shipment) => {
        setSelectedShipmentToBeDeleted(shipment);
        setShowDeletionConfirmationDialog(true);
    };

    const detailsButtonLabel = exported ? (
        <FormattedMessage id={'webedi.label.details'} />
    ) : (
        <FormattedMessage id={'webedi.shipment.edit'} />
    );

    const dropdownOptions = (shipment: Shipment): MenuItemProps[] => {
        const ExportOption = () => {
            const content = (
                <span>
                    <span className={'rioglyph rioglyph-arrow-right margin-right-10 text-size-20'} />
                    <FormattedMessage id={'webedi.label.export'} />
                    {isReadOnly && (
                        <span
                            className={
                                'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0 opacity-40'
                            }
                        />
                    )}
                </span>
            );

            if (isReadOnly) {
                return (
                    <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'left'}>
                        <span>{content}</span>
                    </Tooltip>
                );
            }

            return content;
        };

        const DeleteOption = () => {
            const content = (
                <span>
                    <span className={'rioglyph rioglyph-trash margin-right-10 text-size-20'} />
                    <FormattedMessage id={'webedi.label.delete'} />
                    {isReadOnly && (
                        <span
                            className={
                                'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0 opacity-40'
                            }
                        />
                    )}
                </span>
            );
            if (isReadOnly) {
                return (
                    <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'left'}>
                        <span>{content}</span>
                    </Tooltip>
                );
            }
            return content;
        };

        return [
            {
                value: <ExportOption />,
                disabled: isReadOnly,
                onSelect: () => exportButtonClickHandler(shipment),
            },
            {
                value: <DeleteOption />,
                disabled: isReadOnly,
                onSelect: () => deleteButtonClickHandler(shipment),
            },
        ];
    };

    const renderRow = (shipment: Shipment, key: string) => {
        const articlesInShipment = shipment.load.flatMap((load) => load.loadItems);
        const renderEditingStates = () => {
            const baseClassName = `padding-2 rioglyph rioglyph`;
            const editingStates = getShipmentEditingStatus(shipment);

            const deliveryNoteState = editingStates.includes(SteppedProgressBarStep.DELIVERY_NOTE);
            const packagingState = editingStates.includes(SteppedProgressBarStep.PACKAGING);
            const transportState = editingStates.includes(SteppedProgressBarStep.TRANSPORT);

            return (
                <div data-testid={SHIPMENT_LIST_ITEM_EDITING_STATUS_COLUMN_TEST_ID}>
                    <span
                        className={`${baseClassName}-document ${deliveryNoteState ? 'text-color-primary' : 'text-color-light'}`}
                    />
                    <span
                        className={`${baseClassName}-parcel ${packagingState ? 'text-color-primary' : 'text-color-light'}`}
                    />
                    <span
                        className={`${baseClassName}-transfer ${transportState ? 'text-color-primary' : 'text-color-light'}`}
                    />
                </div>
            );
        };

        const getArticleInformation = articlesInShipment.map((loadItem, key) => {
            // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
            return <span key={key}>{`${loadItem.articleNumberBuyer} / ${loadItem.amount.value}`}</span>;
        });

        const renderContainingArticlesInformation = () => (
            <span>
                {
                    <Tooltip
                        text={
                            <span>
                                <span className={'text-bold margin-bottom-10'}>
                                    <FormattedMessage id={'webedi.articleNumber.buyer'} /> /&nbsp;
                                    <FormattedMessage id={'webedi.schedulingData.quantity'} />
                                </span>
                                {': '}
                                <p data-testid={SHIPMENT_LIST_ITEM_CONTAINED_ARTICLES_COLUMN_TOOLTIP_TEST_ID}>
                                    {getArticleInformation.map((info, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                                        <span key={`loadItem-${index}`}>
                                            {info}
                                            {index < getArticleInformation.length - 1 ? ',' : null}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                            </span>
                        }
                        placement={'bottom'}
                        textAlignment={'left'}
                    >
                        <span data-testid={SHIPMENT_LIST_ITEM_CONTAINED_ARTICLES_COLUMN_CONTENT_TEST_ID}>
                            {getArticleInformation.slice(0, 2).map((info, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                                <span key={`loadItem-${index}`}>
                                    {info}
                                    {index < getArticleInformation.length - 1 ? ',' : null}
                                    {index === 1 && getArticleInformation.length > 2 ? <> &hellip;</> : null}
                                    {getArticleInformation.length > 1 ? <br /> : null}
                                </span>
                            ))}
                        </span>
                    </Tooltip>
                }
            </span>
        );

        const renderDeliveryNotesInformation = (shipmentLoad: DeliveryNote[]) => (
            <span>
                {
                    <Tooltip
                        text={
                            <span>
                                <span className={'text-bold margin-bottom-10'}>
                                    <FormattedMessage id={'webedi.deliveryNotes'} />
                                </span>
                                {': '}
                                <p data-testid={SHIPMENT_LIST_ITEM_DELIVERY_NOTES_COLUMN_TOOLTIP_TEST_ID}>
                                    {shipmentLoad.map((deliveryNote, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                                        <span key={`delivery-note-${index}`}>
                                            {zeroPadNumber(deliveryNote.deliveryNoteNumber, 8)}
                                            {index < shipmentLoad.length - 1 ? ',' : null}
                                            <br />
                                        </span>
                                    ))}
                                </p>
                            </span>
                        }
                        placement={'bottom'}
                        textAlignment={'left'}
                    >
                        <span data-testid={SHIPMENT_LIST_ITEM_DELIVERY_NOTES_COLUMN_CONTENT_TEST_ID}>
                            {shipmentLoad.slice(0, 2).map((deliveryNote, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                                <span key={`delivery-note-${index}`}>
                                    {zeroPadNumber(deliveryNote.deliveryNoteNumber, 8)}
                                    {index < shipmentLoad.length - 1 ? ',' : null}
                                    {index === 1 && shipmentLoad.length > 2 ? <> &hellip;</> : null}
                                    {shipmentLoad.length > 1 ? <br /> : null}
                                </span>
                            ))}
                        </span>
                    </Tooltip>
                }
            </span>
        );
        return (
            <tr key={key} data-testid={SHIPMENT_LIST_ITEM_TEST_ID}>
                <td className={'width-35'}>
                    <ShipmentIcon shipment={shipment} />
                </td>
                <td className={'text-color-primary'}>
                    <ShipmentNumber shipment={shipment} />
                </td>
                <td>
                    <DisplayedTimestamp shipment={shipment} />
                </td>
                <td>{shipment.despatchDate && <FormattedDateOrDateTime date={shipment.despatchDate} />}</td>
                <td>
                    <DeliveryDate
                        estimatedArrivalDate={shipment.estimatedArrivalDate}
                        requestedDeliveryDate={shipment.requestedDeliveryDate}
                    />
                </td>
                <td>{renderDeliveryNotesInformation(shipment.load)}</td>
                <td>{renderContainingArticlesInformation()}</td>
                <td>
                    <FormattedManufacturingCompany manufacturingCompany={shipment.buyer} />
                </td>
                <td>{shipment.shipToId}</td>
                <td>{shipment.placeOfDischarge.id}</td>
                <td className={'text-size-20'}>{!exported && renderEditingStates()}</td>
                <td className={'width-100'}>
                    <button
                        data-testid={SHIPMENT_DETAILS_BUTTON_TEST_ID}
                        className={`${isWidthSm ? 'btn-link btn-icon-only' : 'btn-default'} btn width-100pct`}
                        onClick={(event) => detailsButtonClickHandler(event, shipment)}
                    >
                        <span
                            className={`rioglyph ${exported ? 'rioglyph-detail-view' : 'rioglyph-pencil'}
                         ${isWidthSm && 'btn-link btn-icon-only'}`}
                        />
                        {!isWidthSm ? detailsButtonLabel : null}
                    </button>
                </td>
                {!exported && (
                    <td className={'width-30 table-action'} data-testid={SHIPMENT_DETAILS_ACTION_DROPDOWN_TEST_ID}>
                        <SimpleButtonDropdown
                            title={<span className={'rioglyph rioglyph-option-vertical'} />}
                            bsStyle={'link' as BUTTON_STYLE}
                            iconOnly
                            items={dropdownOptions(shipment)}
                            pullRight={true}
                        />
                    </td>
                )}
            </tr>
        );
    };

    return (
        <div>
            <table className={tableClassNames} data-testid={SHIPMENTS_LIST_TEST_ID}>
                <thead>
                    <tr data-testid={SHIPMENTS_LIST_HEADER_TEST_ID}>
                        <th className={'width-35'} />
                        <th>
                            <FormattedMessage id={'webedi.shipment'} />
                        </th>
                        <th>
                            <ShipmentTimestampLabel exported={exported} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.shipment.dispatchDate'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.shipment.deliveryDate'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.deliveryNotes'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.shipment.containedArticles'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.common.manufacturingCompany'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.plantIdentifier'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.common.placeOfDischarge'} />
                        </th>
                        {!exported ? (
                            <th>
                                <FormattedMessage id={'webedi.shipment.editingStatus'} />
                            </th>
                        ) : (
                            <th />
                        )}
                        <th />
                        {!exported && <th className={'width-40'} />}
                    </tr>
                </thead>
                <tbody>{shipments.map((shipment) => renderRow(shipment, shipment.id))}</tbody>
            </table>
            {!isReadOnly && (
                <DeletionConfirmationDialog
                    show={showDeletionConfirmationDialog}
                    onClickConfirm={() => deleteShipment(selectedShipmentToBeDeleted!)}
                    onClickCancel={() => setShowDeletionConfirmationDialog(false)}
                />
            )}
        </div>
    );
};

const DisplayedTimestamp = (props: ShipmentListEntryProps) => {
    const { exported, exportedAt, lastModifiedAt } = props.shipment;
    const displayedTimestamp = exported ? exportedAt : lastModifiedAt;
    return <FormattedDateOrDateTime date={displayedTimestamp!} />;
};

const ShipmentTimestampLabel = (props: { exported: boolean }) => {
    return props.exported ? (
        <FormattedMessage id={'webedi.shipment.exportDate'} />
    ) : (
        <FormattedMessage id={'webedi.shipment.lastModified'} />
    );
};

const ShipmentIcon = (props: ShipmentListEntryProps) => {
    const icon = props.shipment.exported ? 'rioglyph rioglyph-arrow-right' : 'rioglyph rioglyph-order';
    return <span className={`${icon} text-size-20 text-color-primary`} />;
};

const ShipmentNumber = (props: ShipmentListEntryProps) => {
    const { exported, shipmentNumber } = props.shipment;
    return (
        <>
            <strong>{zeroPadNumber(shipmentNumber, 8)}</strong>
            {exported && <ExportLabel />}
        </>
    );
};

const ExportLabel = () => {
    return (
        <span className={'label label-default label-condensed label-condensed margin-left-10'}>
            <FormattedMessage id={'webedi.shipment.exported'} />
        </span>
    );
};
