import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { shipmentDocumentsSlice } from '../../../../reducers/shipmentDocuments/ShipmentDocuments.reducer';
import {
    DocumentLanguage,
    TsbDocumentSettings,
    TsbPaperFormat,
} from '../../../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { CheckboxInput } from '../../../common/form/CheckboxInput';
import { NumberInput } from '../../../common/form/NumberInput';
import { RadioButtonInput } from '../../../common/form/RadioButtonInput';
import { TextAreaInput } from '../../../common/form/TextAreaInput';

export const SHIPPING_DOCUMENTS_SETTINGS_TEST_ID = 'SHIPPING_DOCUMENTS_SETTINGS_TEST_ID';
export const SHIPPING_DOCUMENTS_SETTINGS_NUMBER_OF_SHEETS_TEST_ID =
    'SHIPPING_DOCUMENTS_SETTINGS_NUMBER_OF_SHEETS_TEST_ID';

export interface TsbSettingsFormValues {
    language: DocumentLanguage;
    comment: string;
    includeReceiptInformation: boolean;
    mergePackages: boolean;
    mergeLoadingUnits: boolean;
    paperFormat: TsbPaperFormat;
    numShipmentMasterSheets: number;
    numShipmentPositionSheets: number;
    numBarcodeSheets: number;
    numLoadingUnitSheets: number;
}

export const generateInitialValuesFromRedux = (settings: TsbDocumentSettings): TsbSettingsFormValues => ({
    language: settings.language,
    comment: settings.comment,
    includeReceiptInformation: settings.includeReceiptInformation,
    mergePackages: settings.mergePackages,
    mergeLoadingUnits: settings.mergeLoadingUnits,
    paperFormat: settings.paperFormat,
    numShipmentMasterSheets: settings.copies.numShipmentMasterSheets,
    numShipmentPositionSheets: settings.copies.numShipmentPositionSheets,
    numBarcodeSheets: settings.copies.numBarcodeSheets,
    numLoadingUnitSheets: settings.copies.numLoadingUnitSheets,
});

const mapFormValuesToRedux = (formValues: TsbSettingsFormValues): TsbDocumentSettings => ({
    language: formValues.language,
    comment: formValues.comment,
    includeReceiptInformation: formValues.includeReceiptInformation,
    mergePackages: formValues.mergePackages,
    mergeLoadingUnits: formValues.mergeLoadingUnits,
    paperFormat: formValues.paperFormat,
    copies: {
        numShipmentMasterSheets: formValues.numShipmentMasterSheets,
        numShipmentPositionSheets: formValues.numShipmentPositionSheets,
        numBarcodeSheets: formValues.numBarcodeSheets,
        numLoadingUnitSheets: formValues.numLoadingUnitSheets,
    },
});

export const generateSubmitAction = (formValues: TsbSettingsFormValues) => {
    const mappedValues: TsbDocumentSettings = mapFormValuesToRedux(formValues);
    return shipmentDocumentsSlice.actions.setTsbDocumentSettings(mappedValues);
};

const fieldNames: Record<keyof TsbSettingsFormValues, keyof TsbSettingsFormValues> = {
    language: 'language',
    comment: 'comment',
    includeReceiptInformation: 'includeReceiptInformation',
    mergePackages: 'mergePackages',
    mergeLoadingUnits: 'mergeLoadingUnits',
    paperFormat: 'paperFormat',
    numShipmentMasterSheets: 'numShipmentMasterSheets',
    numShipmentPositionSheets: 'numShipmentPositionSheets',
    numBarcodeSheets: 'numBarcodeSheets',
    numLoadingUnitSheets: 'numLoadingUnitSheets',
};

interface ShippingDocumentsSettingsProps {
    inputValuesSlicePath: string;
}

export const ShippingDocumentsSettings = (props: ShippingDocumentsSettingsProps) => {
    const rowClassName = 'display-flex flex-row form-group';

    const fieldNamesWithPath = Object.fromEntries(
        Object.entries(fieldNames).map(([key, value]) => [key, `${props.inputValuesSlicePath}.${value}`]),
    ) as Record<keyof TsbSettingsFormValues, string>;

    return (
        <div data-testid={SHIPPING_DOCUMENTS_SETTINGS_TEST_ID}>
            <div>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.documentLanguage'} />
                    </label>
                </div>
                <hr />
                <div className={`${rowClassName} justify-content-start`}>
                    <label className={'width-33pct'}>
                        <Field<DocumentLanguage>
                            type={'radio'}
                            name={fieldNamesWithPath.language}
                            component={RadioButtonInput}
                            value={DocumentLanguage.GERMAN}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.documentLanguage.german'} />
                        </Field>
                    </label>
                    <label className={'width-33pct'}>
                        <Field<DocumentLanguage>
                            type={'radio'}
                            name={fieldNamesWithPath.language}
                            component={RadioButtonInput}
                            value={DocumentLanguage.ENGLISH}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.documentLanguage.english'} />
                        </Field>
                    </label>
                </div>
            </div>

            <div>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat'} />
                    </label>
                </div>
                <hr />
                <div className={`${rowClassName} justify-content-start`}>
                    <label className={'width-33pct'}>
                        <Field<TsbPaperFormat>
                            type={'radio'}
                            name={fieldNamesWithPath.paperFormat}
                            component={RadioButtonInput}
                            value={TsbPaperFormat.DIN_A4}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.DINA4'} />
                        </Field>
                    </label>
                    <label className={'width-33pct'}>
                        <Field<TsbPaperFormat>
                            type={'radio'}
                            name={fieldNamesWithPath.paperFormat}
                            component={RadioButtonInput}
                            value={TsbPaperFormat.US_LEGAL}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.USLegal'} />
                        </Field>
                    </label>
                </div>
            </div>
            <div className={'justify-content-around'}>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.numberOfSheets'} />
                    </label>
                </div>
                <hr />
                <div className={`${rowClassName} no-control-arrows justify-content-between`}>
                    <label className={'text-size-14'} htmlFor={fieldNamesWithPath.numShipmentMasterSheets}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.numberOfShipmentMasterSheets'} />
                    </label>
                    <Field<number>
                        id={fieldNamesWithPath.numShipmentMasterSheets}
                        name={fieldNamesWithPath.numShipmentMasterSheets}
                        component={NumberInput}
                        min={0}
                        parse={(value) => value && parseInt(value.toString(), 10)}
                        className={'form-control'}
                    />
                </div>
                <div
                    className={`${rowClassName} no-control-arrows justify-content-between`}
                    data-testid={SHIPPING_DOCUMENTS_SETTINGS_NUMBER_OF_SHEETS_TEST_ID}
                >
                    <label className={'text-size-14'} htmlFor={fieldNamesWithPath.numShipmentPositionSheets}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.numberOfShipmentPositionSheets'} />
                    </label>
                    <Field<number>
                        id={fieldNamesWithPath.numShipmentPositionSheets}
                        name={fieldNamesWithPath.numShipmentPositionSheets}
                        component={NumberInput}
                        min={0}
                        parse={(value) => value && parseInt(value.toString(), 10)}
                        className={'form-control'}
                    />
                </div>
                <div className={`${rowClassName} no-control-arrows justify-content-between`}>
                    <label className={'text-size-14'} htmlFor={fieldNamesWithPath.numBarcodeSheets}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.numberOfBarcodeSheets'} />
                    </label>
                    <Field<number>
                        id={fieldNamesWithPath.numBarcodeSheets}
                        name={fieldNamesWithPath.numBarcodeSheets}
                        component={NumberInput}
                        min={0}
                        parse={(value) => value && parseInt(value.toString(), 10)}
                        className={'form-control'}
                    />
                </div>
                <div className={`${rowClassName} no-control-arrows justify-content-between`}>
                    <label className={'text-size-14'} htmlFor={fieldNamesWithPath.numLoadingUnitSheets}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.numberOfLoadingUnitSheets'} />
                    </label>
                    <Field<number>
                        id={fieldNamesWithPath.numLoadingUnitSheets}
                        name={fieldNamesWithPath.numLoadingUnitSheets}
                        component={NumberInput}
                        min={0}
                        parse={(value) => value && parseInt(value.toString(), 10)}
                        className={'form-control'}
                    />
                </div>
            </div>
            <div>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.mergeOptions'} />
                    </label>
                </div>
                <hr />
                <div className={`${rowClassName} justify-content-between`}>
                    <label>
                        <Field<boolean>
                            name={fieldNamesWithPath.includeReceiptInformation}
                            component={CheckboxInput}
                            type={'checkbox'}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.includeReceiptInformation'} />
                        </Field>
                    </label>
                    <label>
                        <Field<boolean>
                            name={fieldNamesWithPath.mergePackages}
                            component={CheckboxInput}
                            type={'checkbox'}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.mergePackages'} />
                        </Field>
                    </label>
                    <label>
                        <Field<boolean>
                            name={fieldNamesWithPath.mergeLoadingUnits}
                            component={CheckboxInput}
                            type={'checkbox'}
                        >
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.mergeHandlingUnits'} />
                        </Field>
                    </label>
                </div>
                <div className={`${rowClassName} justify-content-between`}>
                    <label className={'text-size-14'} htmlFor={fieldNamesWithPath.comment}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.comment'} />
                    </label>
                    <div className={'width-75pct'}>
                        <Field<string>
                            name={fieldNamesWithPath.comment}
                            component={TextAreaInput}
                            className={'form-control'}
                            id={fieldNamesWithPath.comment}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
