import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { PackagingTemplateLisonRefreshButton } from './PackagingTemplateLisonRefreshButton';

export const LISON_PACKAGING_CONFIG_TEST_ID = 'LISON_PACKAGING_CONFIG_TEST_ID';

export const LisonPackagingConfig = () => {
    return (
        <div
            data-testid={LISON_PACKAGING_CONFIG_TEST_ID}
            className={'display-flex flex-column align-items-center justify-content-center min-height-250 padding-25'}
        >
            <div>
                <FormattedMessage
                    id={'webedi.packaging.noLisonPackagingTemplateStored'}
                    values={{
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
            </div>
            <div className={'padding-top-15'}>
                <PackagingTemplateLisonRefreshButton />
            </div>
        </div>
    );
};
