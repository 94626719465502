// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const DimensionsCodec = t.type({
    width_in_mm: t.union([t.undefined, t.number]),
    length_in_mm: t.union([t.undefined, t.number]),
    height_in_mm: t.union([t.undefined, t.number]),
});

export type ApiDimensions = t.TypeOf<typeof DimensionsCodec>;
