import NoData from '@rio-cloud/rio-uikit/lib/es/NoData';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const NoDataAvailable = (props: { className?: string }) => (
    <NoData className={props.className} text={<FormattedMessage id={'webedi.common.noData'} />} />
);

export const NoDataAvailableWrapper = (props: {
    className?: string;
    hasNoData: any | undefined;
    children: ReactNode;
}) => {
    if (props.hasNoData) {
        return props.children;
    } else {
        return <NoData className={props.className} text={<FormattedMessage id={'webedi.common.noData'} />} />;
    }
};
