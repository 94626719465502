import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useAppDispatch } from '../../../../../../../configuration/setup/typedReduxHooks';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import { PackagingStep } from '../../../../../reducers/shipments/packaging.types';
import { formIds } from './OuterPackagingFormConfig';
import { PackagingGroupingSelection } from './PackagingGroupingSelection';

export const PACKAGING_STEP_GROUPING_SELECTION_TEST_ID = 'PACKAGING_STEP_GROUPING_SELECTION_TEST_ID';

export const OuterPackagingGroupingSelection = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(formIds.formStep1));
    }, [dispatch]);

    return (
        <div data-testid={PACKAGING_STEP_GROUPING_SELECTION_TEST_ID}>
            <Form
                onSubmit={() => {
                    dispatch(packagingSlice.actions.setPackagingStep(PackagingStep.PACKAGING_MATERIAL_SELECTION));
                }}
                render={({ handleSubmit }) => {
                    return (
                        <form id={formIds.formStep1} onSubmit={handleSubmit}>
                            <PackagingGroupingSelection />
                        </form>
                    );
                }}
            />
        </div>
    );
};
