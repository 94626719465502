import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';

export interface TextAreaInputProps extends FieldRenderProps<string> {
    disabled?: boolean;
    maxStringLength?: number;
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
}

export const TextAreaInput = (props: TextAreaInputProps) => {
    const { input, meta, ...rest } = props;
    const { disabled, maxStringLength, className, id } = rest;
    const errorClassName = 'form-group has-error has-feedback';
    const getInputRowClassName = (touched: any, error: any) => `${touched && error ? errorClassName : ''}`;

    return (
        <div className={getInputRowClassName(meta.touched, meta.error)}>
            <textarea
                {...input}
                rows={5}
                className={className}
                style={{ resize: 'none' }}
                disabled={disabled}
                data-testid={rest[DATA_TEST_ID_PROPERTY_NAME]}
                id={id}
            />
            {meta.touched && meta.error ? (
                <span className={'help-block'}>
                    <FormattedMessage id={meta.error} values={{ number: maxStringLength }} />
                </span>
            ) : null}
        </div>
    );
};
