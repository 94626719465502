export const HORIZONTAL_ADD_BUTTON_TEST_ID = 'HORIZONTAL_ADD_BUTTON_TEST_ID';
export const HorizontalAddButton = (props: { onClick: () => void }) => {
    const wrapperClassNames = [
        'display-flex',
        'flex-row',
        'justify-content-center',
        'align-items-center',
        'margin-top-15',
        'padding-5',
        'cursor-pointer',
        'text-color-dark',
        'hover-text-color-darkest',
    ].join(' ');

    const iconWrapperClassNames = [
        'padding-left-15',
        'padding-right-15',
        'padding-top-1',
        'padding-bottom-2',
        'text-size-20',
    ].join(' ');

    const hrClassNames = 'flex-1-1 border-width-2';

    return (
        <div data-testid={HORIZONTAL_ADD_BUTTON_TEST_ID} className={wrapperClassNames} onClick={props.onClick}>
            <hr className={hrClassNames} />
            <span className={iconWrapperClassNames}>
                <span className={'rioglyph rioglyph rioglyph-plus-light'} />
            </span>
            <hr className={hrClassNames} />
        </div>
    );
};
