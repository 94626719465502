import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    SHIPMENT_DOCUMENT_CONTENT_SETTINGS_DIALOG_SAVE_TEST_ID,
    SHIPMENT_DOCUMENT_CONTENT_SETTINGS_FORM_TEST_ID,
} from './ShipmentDocumentSettingsDialogContent';

export const SHIPMENT_DOCUMENT_SETTINGS_DIALOG_FOOTER_TEST_ID = 'SHIPMENT_DOCUMENT_SETTINGS_DIALOG_FOOTER_TEST_ID';

interface ShipmentDocumentSettingsDialogFooterProps {
    onCancel: () => void;
    submitButtonText: ReactNode;
}

export const ShipmentDocumentSettingsDialogFooter = (props: ShipmentDocumentSettingsDialogFooterProps) => {
    return (
        <div data-testid={SHIPMENT_DOCUMENT_SETTINGS_DIALOG_FOOTER_TEST_ID}>
            <div className={'btn-toolbar pull-right'}>
                <button className={'CancelButton btn btn-default'} onClick={props.onCancel}>
                    <FormattedMessage id={'webedi.label.cancel'} />
                </button>
                <button
                    data-testid={SHIPMENT_DOCUMENT_CONTENT_SETTINGS_DIALOG_SAVE_TEST_ID}
                    className={`ConfirmationButton btn btn-primary`}
                    type={'submit'}
                    form={SHIPMENT_DOCUMENT_CONTENT_SETTINGS_FORM_TEST_ID}
                >
                    {props.submitButtonText}
                </button>
            </div>
        </div>
    );
};
