import { FormattedMessage } from 'react-intl';
import { neverReachedFor } from '../../../../../utils';

export enum ShipmentDocumentSettingsGroup {
    GTL_GLOBAL_TRANSPORT_LABEL = 'GLOBAL_TRANSPORT_LABEL',
    TSB_SHIPPING_DOCUMENTS = 'TSB_SHIPPING_DOCUMENTS',
}

interface ShipmentDocumentSettingsDialogMenuProps {
    selected: ShipmentDocumentSettingsGroup;
    onChange: (shipmentDocumentSettingsGroup: ShipmentDocumentSettingsGroup) => void;
}

export const ShipmentDocumentSettingsDialogMenu = (props: ShipmentDocumentSettingsDialogMenuProps) => {
    const getTranslationId = (settingsGroup: ShipmentDocumentSettingsGroup) => {
        switch (settingsGroup) {
            case ShipmentDocumentSettingsGroup.GTL_GLOBAL_TRANSPORT_LABEL:
                return 'webedi.shipmentDocuments.gtl';
            case ShipmentDocumentSettingsGroup.TSB_SHIPPING_DOCUMENTS:
                return 'webedi.shipmentDocuments.tsb';
            default:
                return neverReachedFor(settingsGroup);
        }
    };

    const renderListEntry = (settingsGroup: ShipmentDocumentSettingsGroup) => (
        <li onClick={() => props.onChange(settingsGroup)} className={props.selected === settingsGroup ? 'active' : ''}>
            <FormattedMessage id={getTranslationId(settingsGroup)} tagName='span' />
        </li>
    );
    return (
        <div>
            <ul className={'nav nav-pills nav-stacked padding-right-20'}>
                {renderListEntry(ShipmentDocumentSettingsGroup.GTL_GLOBAL_TRANSPORT_LABEL)}
                {renderListEntry(ShipmentDocumentSettingsGroup.TSB_SHIPPING_DOCUMENTS)}
            </ul>
        </div>
    );
};
