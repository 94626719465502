import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { generateShipmentDocumentsForSelectedShipment } from '../../../actions/shipmentDocuments/shipmentDocuments.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { shipmentDocumentsSlice } from '../../../reducers/shipmentDocuments/ShipmentDocuments.reducer';
import { getShipmentDocumentsForSelectedShipment } from '../../../selectors/shipments/ShipmentDocuments.selector';
import { DownloadShipmentDocumentsComponent } from '../../common/shipmentDocumentDownload/DownloadShipmentDocuments';
import { ShipmentDocumentGeneratorSettingsDialog } from './generatorSettings/ShipmentDocumentGeneratorSettingsDialog';

export const SHIPMENT_DOCUMENTS_TEST_ID = 'SHIPMENT_DOCUMENTS_TEST_ID';
export const DOWNLOAD_SHIPMENT_DOCUMENTS_COMPONENT_TEST_ID = 'DOWNLOAD_SHIPMENT_DOCUMENTS_COMPONENT_TEST_ID';

export const ShipmentDocuments = () => {
    const generatedShipmentDocuments = useAppSelector(getShipmentDocumentsForSelectedShipment);

    const [showGeneratorSettings, setShowGeneratorSettings] = useState(false);
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const generateDocuments = () => dispatch(generateShipmentDocumentsForSelectedShipment(dunsNumber));

    useEffect(() => {
        return () => {
            dispatch(shipmentDocumentsSlice.actions.clearShipmentDocuments());
        };
    }, [dispatch]);

    return (
        <div
            data-testid={SHIPMENT_DOCUMENTS_TEST_ID}
            className={
                'panel display-flex justify-content-center align-items-center max-width-100pct padding-top-2pct-lg padding-bottom-5pct-lg padding-bottom-10pct'
            }
        >
            <div className={'panel-body align-items-center text-center display-flex flex-column'}>
                <div className={'text-size-h2 margin-bottom-20 margin-top-25'}>
                    <span className={'rioglyph text-color-light rioglyph-tag text-size-200pct'} />
                </div>
                <h3>
                    <FormattedMessage id={'webedi.shipmentDocuments.labelAndShipmentDocuments'} />
                </h3>
                {generatedShipmentDocuments ? (
                    <div
                        className={'padding-top-15 padding-bottom-25'}
                        data-testid={DOWNLOAD_SHIPMENT_DOCUMENTS_COMPONENT_TEST_ID}
                    >
                        <DownloadShipmentDocumentsComponent shipmentDocuments={generatedShipmentDocuments} />
                    </div>
                ) : (
                    <div className={'text-color-gray padding-bottom-25'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.noDocumentsGeneratedYet'} />
                    </div>
                )}
                <div className={'btn-group-vertical width-300'}>
                    <button
                        type={'button'}
                        onClick={() => setShowGeneratorSettings(true)}
                        className={'btn btn-default margin-bottom-10'}
                    >
                        <span className={'rioglyph rioglyph-cog'} />
                        <FormattedMessage id={'webedi.shipmentDocuments.generatorSettings'} />
                    </button>
                    <button type={'button'} onClick={generateDocuments} className={'btn btn-primary btn-lg'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.generateDocuments'} />
                    </button>
                </div>
                <ShipmentDocumentGeneratorSettingsDialog
                    show={showGeneratorSettings}
                    onClose={() => setShowGeneratorSettings(false)}
                />
            </div>
        </div>
    );
};
