import { LocalizedLanguageNames } from '@cospired/i18n-iso-languages';
import { SelectOption } from '@rio-cloud/rio-uikit';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { getDisplayLanguages } from '../../../../../../configuration/lang/selectors';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { SelectInput } from '../../../common/form/SelectInput';
import { TextAreaInput } from '../../../common/form/TextAreaInput';

export const CUSTOMS_EDIT_PREFERENCE_AUTHORIZATION_TEST_ID = 'CUSTOMS_EDIT_PREFERENCE_AUTHORIZATION_TEST_ID';
export const CUSTOMS_EDIT_LANGUAGE_TEST_ID = 'CUSTOMS_EDIT_LANGUAGE_TEST_ID';

const generateIsoLanguageCodeSelectOptions = (isoLanguages: LocalizedLanguageNames): SelectOption[] => {
    return Object.entries(isoLanguages).map(([languageCode, languageName]) => ({
        id: languageCode,
        label: `${languageName} (${languageCode})`,
    }));
};

export const CustomsEdit = () => {
    const isoLanguages = useAppSelector(getDisplayLanguages);

    return (
        <>
            <div className={'display-flex-sm flex-row width-100pct'}>
                <div
                    className={'width-66pct-sm padding-right-15'}
                    data-testid={CUSTOMS_EDIT_PREFERENCE_AUTHORIZATION_TEST_ID}
                >
                    <label>
                        <FormattedMessage id={'webedi.customs.preferenceAuthorization'} />
                    </label>
                    <Field<string>
                        name={'customs.preferenceAuthorization.text'}
                        component={TextAreaInput}
                        className={'form-control'}
                    />
                </div>
                <div className={'width-33pct-sm padding-right-15'} data-testid={CUSTOMS_EDIT_LANGUAGE_TEST_ID}>
                    <label>
                        <FormattedMessage id={'webedi.customs.preferenceAuthorizationLanguage'} />
                    </label>
                    <Field
                        name={'customs.preferenceAuthorization.language'}
                        component={SelectInput}
                        useClear={true}
                        options={generateIsoLanguageCodeSelectOptions(isoLanguages)}
                        useFilter={true}
                    />
                </div>
            </div>
        </>
    );
};
