export const CHEVRON_TEST_ID = 'CHEVRON_TEST_ID';

export const ChevronRight = (props: { backgroundColor: string }) => (
    <div className={'position-absolute right--3 top-50pct margin-right--5 margin-top--5 z-index-1 '}>
        <div
            className={`rotate-225 height-15 width-15 ${props.backgroundColor} padding-5
                    border-style-solid border-width-1 border-color-lighter border-right-none border-top-none`}
            data-testid={CHEVRON_TEST_ID}
        />
    </div>
);
