import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { BaseSyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isAdmin, isReadOnlyAdmin } from '../../../../configuration/tokenHandling/selectors';
import { fetchAndStoreDeliverySchedules } from '../../actions/deliverySchedules/DeliverySchedules.actions';
import { getDeliverySchedulesQueryParams } from '../../selectors/deliverySchedules/DeliverySchedules.selector';

export const DeliverySchedulesShowDeletedCheckbox = () => {
    const query = useAppSelector(getDeliverySchedulesQueryParams);
    const dispatch = useAppDispatch();
    const isUserAdmin = useAppSelector(isAdmin);
    const isUserReadOnlyAdmin = useAppSelector(isReadOnlyAdmin);

    if (!isUserAdmin && !isUserReadOnlyAdmin) {
        return null;
    }

    const includeSoftDeleted = !query.excludeSoftDeleted;

    const onToggle = (event: BaseSyntheticEvent) => {
        const checked = event.target.checked;
        dispatch(fetchAndStoreDeliverySchedules({ offset: 0, excludeSoftDeleted: !checked }));
    };

    return (
        <div
            className={
                'display-flex border-style-solid border-width-1 padding-y-4 padding-x-10 align-items-center justify-content-center border-color-gray bg-white'
            }
        >
            <div className={'margin-y-2'}>
                <Checkbox onClick={onToggle} checked={includeSoftDeleted}>
                    <span className={'checkbox-text'}>
                        <FormattedMessage id={'webedi.deliverySchedule.includeDeletedLabel'} />
                    </span>
                </Checkbox>
            </div>
        </div>
    );
};
