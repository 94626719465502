// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import {
    CommunicationAddressCodeQualifier,
    DeliveryInstructionType,
    DeliveryPlanCommitmentLevelCode,
    DeliveryScheduleFlag,
    DespatchDocumentReferenceCodeQualifier,
    ItemTypeIdentificationCode,
    ProcessIndicator,
    RequestCondition,
    ResponsibleAgencyCode,
} from '../../reducers/deliverySchedules/types';
import { PartnerCodec, PartyCodec } from '../common.types';
import { ExtendedArticleMasterDataCodec } from '../shared/articleMasterData.types';
import { ItemShortDescriptionCodec } from '../shared/itemShortDescription.types';
import { measurementUnitCodeCodec } from '../shared/quantity.types';
import { fromEnum } from '../util';

const DeliveryPlanCommitmentLevelCodeCodec = fromEnum<DeliveryPlanCommitmentLevelCode>(
    'DeliveryPlanCommitmentLevelCode',
    DeliveryPlanCommitmentLevelCode,
);
const ResponsibleAgencyCodeCodec = fromEnum<ResponsibleAgencyCode>('ResponsibleAgencyCode', ResponsibleAgencyCode);
const ItemTypeIdentificationCodeCodec = fromEnum<ItemTypeIdentificationCode>(
    'ItemTypeIdentificationCode',
    ItemTypeIdentificationCode,
);
const ProcessIndicatorCodec = fromEnum<ProcessIndicator>('ProcessIndicator', ProcessIndicator);
const CommunicationAddressCodeQualifierCodec = fromEnum<CommunicationAddressCodeQualifier>(
    'CommunicationAddressCodeQualifier',
    CommunicationAddressCodeQualifier,
);
const DespatchDocumentReferenceCodeQualifierCodec = fromEnum<DespatchDocumentReferenceCodeQualifier>(
    'DespatchDocumentReferenceCodeQualifier',
    DespatchDocumentReferenceCodeQualifier,
);
const RequestConditionCodec = fromEnum<RequestCondition>('RequestCondition', RequestCondition);
const DeliveryScheduleFlagCodec = fromEnum<DeliveryScheduleFlag>('DeliveryScheduleFlag', DeliveryScheduleFlag);
const DeliveryInstructionTypeCodec = fromEnum<DeliveryInstructionType>('EdiFileType', DeliveryInstructionType);

export enum ApiDeliveryType {
    SHIPMENT = 'SHIPMENT',
    DELIVERY = 'DELIVERY',
}
const ApiDeliveryTypeCodec = fromEnum<ApiDeliveryType>('ApiDeliveryType', ApiDeliveryType);

export const ScheduledDateCodec = t.type({
    date: t.string,
    type: ApiDeliveryTypeCodec,
});

export const ScheduledDateTimeCodec = t.type({
    date_time: t.string,
    type: ApiDeliveryTypeCodec,
});

export const ScheduledPeriodCodec = t.type({
    earliest_delivery_date: t.string,
    latest_delivery_date: t.string,
    type: ApiDeliveryTypeCodec,
});

const ScheduledQuantityCodec = t.type({
    delivery_plan_commitment_level_code: DeliveryPlanCommitmentLevelCodeCodec,
    quantity: t.number,
    outstanding_quantity: t.number,
    scheduled_delivery_time: t.union([ScheduledDateCodec, ScheduledDateTimeCodec, ScheduledPeriodCodec]),
    process_indicator: ProcessIndicatorCodec,
    request_condition: RequestConditionCodec,
});

const CommunicationAddressCodec = t.type({
    identifier: t.string,
    code_qualifier: CommunicationAddressCodeQualifierCodec,
});

const SchedulingContactCodec = t.intersection([
    t.partial({
        id: t.string,
        name: t.string,
    }),
    t.type({
        communication_addresses: t.array(CommunicationAddressCodec),
    }),
]);

const CumulativeQuantityDetailsCodec = t.intersection([
    t.type({
        quantity: t.number,
    }),
    t.partial({
        date: t.string,
    }),
]);

const DeliveryInstructionNumberCodec = t.intersection([
    t.type({
        reference_identifier: t.string,
    }),
    t.partial({
        reference_date: t.string,
    }),
]);

const OrderNumberCodec = t.type({
    reference_identifier: t.string,
});

const ItemNumberIdentificationCodec = t.type({
    item_identifier: t.string,
    item_type_identification_code: ItemTypeIdentificationCodeCodec,
});

const LineItemIdentifierCodec = t.intersection([
    t.type({
        item_number_identification: ItemNumberIdentificationCodec,
    }),
    t.partial({
        line_item_identifier: t.string,
    }),
]);

const LineItemCodec = t.type({
    line_item_identifier: LineItemIdentifierCodec,
    item_short_descriptions: t.array(ItemShortDescriptionCodec),
});

const DespatchDocumentReferenceCodec = t.intersection([
    t.type({
        reference_code_qualifier: DespatchDocumentReferenceCodeQualifierCodec,
        reference_identifier: t.string,
    }),
    t.partial({
        reference_document_date: t.string,
    }),
]);

const ReceivedQuantityCodec = t.intersection([
    t.type({
        quantity: t.number,
        despatch_document_references: t.array(DespatchDocumentReferenceCodec),
    }),
    t.partial({
        receipt_date: t.string,
    }),
]);

const ScheduledArticleDetailsCodec = t.intersection([
    t.type({
        line_item: LineItemCodec,
        measurement_unit_code: measurementUnitCodeCodec,
        delivery_schedule_number: DeliveryInstructionNumberCodec,
        order_document_identifier_buyer_assigned: OrderNumberCodec,
        most_recent_arrivals_of_goods: t.array(ReceivedQuantityCodec),
        scheduling_data: t.array(ScheduledQuantityCodec),
    }),
    t.partial({
        previous_delivery_instruction_number: DeliveryInstructionNumberCodec,
        cumulative_quantity_ordered: CumulativeQuantityDetailsCodec,
        cumulative_quantity_received: CumulativeQuantityDetailsCodec,
        scheduling_contact: SchedulingContactCodec,
    }),
]);

const RequiredAdditionalPartyIdentifierCodec = t.type({
    reference_identifier: t.string,
    reference_code_qualifier: t.string,
});

const LocationCodec = t.intersection([
    t.type({
        location_name_code: t.string,
        responsible_agency_code: ResponsibleAgencyCodeCodec,
    }),
    t.partial({
        location_name: t.string,
    }),
]);

const ShipToCodec = t.intersection([
    t.type({
        ship_to: PartyCodec,
        place_of_discharge: LocationCodec,
        place_of_delivery: LocationCodec,
    }),
    t.partial({
        place_of_transhipment: LocationCodec,
    }),
]);

const DeliveryInstructionReferenceCodec = t.intersection([
    t.type({
        id: t.string,
        type: DeliveryInstructionTypeCodec,
        edi_file_id: t.string,
    }),
    t.partial({
        delivery_instruction_number: DeliveryInstructionNumberCodec,
    }),
]);

export enum ApiDeliveryScheduleWorkflow {
    WEBSCM_CLASSIC = 'WEBSCM_CLASSIC',
    DISCOVERY_NEXT_GEN = 'DISCOVERY_NEXT_GEN',
}
const ApiWorkflowSpec = fromEnum<ApiDeliveryScheduleWorkflow>('ApiWorkflow', ApiDeliveryScheduleWorkflow);

export const DeliveryScheduleCodec = t.exact(
    t.intersection([
        t.type({
            id: t.string,
            partner: PartnerCodec,
            buyer: PartyCodec,
            seller: PartyCodec,
            ship_from: PartyCodec,
            ship_to: ShipToCodec,
            scheduled_article_details: ScheduledArticleDetailsCodec,
            cumulative_quantity_in_preparation: t.number,
            cumulative_quantity_sent: t.number,
            has_valid_article_master_data: t.boolean,
            has_valid_cumulative_quantity_sent: t.boolean,
            has_zero_demand: t.boolean,
            flags: t.array(DeliveryScheduleFlagCodec),
            delivery_instruction_references: t.array(DeliveryInstructionReferenceCodec),
            is_soft_deleted: t.boolean,
            manually_created: t.boolean,
            workflow: ApiWorkflowSpec,
            include_in_dispatch_proposal_creation: t.boolean,
        }),
        t.partial({
            article_master_data: ExtendedArticleMasterDataCodec,
            latest_delivery_call_off_date: t.string,
        }),
    ]),
);

const DeliverySchedulesQueryCodec = t.intersection([
    t.type({
        offset: t.number,
        limit: t.number,
        sort: t.string,
    }),
    t.partial({
        q: t.string,
        supplier_identifier: t.string,
        plant_number: t.string,
        unloading_point: t.string,
        storage_place: t.string,
        order_number: t.string,
        manufacturing_company: t.string,
        delivery_date_from: t.string,
        exclude_soft_deleted: t.boolean,
    }),
]);

export const DeliverySchedulesQueryResponseCodec = t.exact(
    t.type({
        items: t.array(DeliveryScheduleCodec),
        total_count: t.number,
        query: DeliverySchedulesQueryCodec,
    }),
);

export const CumulativeQuantitySentOffsetCodec = t.type({
    date_time: t.string,
    previous_quantity: t.number,
    new_quantity: t.number,
    creator: t.string,
});

export const CumulativeQuantitySentOffsetsQueryResponseCodec = t.type({
    items: t.array(CumulativeQuantitySentOffsetCodec),
});

export const CumulativeQuantitySentOffsetCreationRequestCodec = t.type({
    new_quantity: t.number,
});

export const DeliveryScheduleDataUpdateRequestCodec = t.type({
    article_master_data: ExtendedArticleMasterDataCodec,
    include_in_dispatch_proposal_creation: t.boolean,
});

export type ApiDeliverySchedule = t.TypeOf<typeof DeliveryScheduleCodec>;
export type ApiDeliverySchedulesQueryResponse = t.TypeOf<typeof DeliverySchedulesQueryResponseCodec>;
export type ApiParty = t.TypeOf<typeof PartyCodec>;
export type ApiShipTo = t.TypeOf<typeof ShipToCodec>;
export type ApiLocation = t.TypeOf<typeof LocationCodec>;
export type ApiRequiredAdditionalPartyIdentifier = t.TypeOf<typeof RequiredAdditionalPartyIdentifierCodec>;
export type ApiScheduledArticleDetails = t.TypeOf<typeof ScheduledArticleDetailsCodec>;
export type ApiLineItem = t.TypeOf<typeof LineItemCodec>;
export type ApiLineItemIdentifier = t.TypeOf<typeof LineItemIdentifierCodec>;
export type ApiItemNumberIdentification = t.TypeOf<typeof ItemNumberIdentificationCodec>;
export type ApiDeliveryInstructionNumber = t.TypeOf<typeof DeliveryInstructionNumberCodec>;
export type ApiOrderNumber = t.TypeOf<typeof OrderNumberCodec>;
export type ApiScheduledQuantity = t.TypeOf<typeof ScheduledQuantityCodec>;
export type ApiCumulativeQuantityDetails = t.TypeOf<typeof CumulativeQuantityDetailsCodec>;
export type ApiReceivedQuantity = t.TypeOf<typeof ReceivedQuantityCodec>;
export type ApiDespatchDocumentReference = t.TypeOf<typeof DespatchDocumentReferenceCodec>;
export type ApiScheduledDate = t.TypeOf<typeof ScheduledDateCodec>;
export type ApiScheduledDateTime = t.TypeOf<typeof ScheduledDateTimeCodec>;
export type ApiScheduledPeriod = t.TypeOf<typeof ScheduledPeriodCodec>;
export type ApiCommunicationAddress = t.TypeOf<typeof CommunicationAddressCodec>;
export type ApiSchedulingContact = t.TypeOf<typeof SchedulingContactCodec>;
export type ApiCumulativeQuantitySentOffset = t.TypeOf<typeof CumulativeQuantitySentOffsetCodec>;
export type ApiCumulativeQuantitySentOffsetsQueryResponse = t.TypeOf<
    typeof CumulativeQuantitySentOffsetsQueryResponseCodec
>;
export type ApiCumulativeQuantitySentOffsetCreationRequest = t.TypeOf<
    typeof CumulativeQuantitySentOffsetCreationRequestCodec
>;
export type ApiDeliveryScheduleDataUpdateRequestCodec = t.TypeOf<typeof DeliveryScheduleDataUpdateRequestCodec>;
export type ApiDeliveryInstructionReference = t.TypeOf<typeof DeliveryInstructionReferenceCodec>;

export const isApiScheduledDateTime = (
    apiScheduledDateTimeOrPeriod: ApiScheduledDate | ApiScheduledDateTime | ApiScheduledPeriod,
): apiScheduledDateTimeOrPeriod is ApiScheduledDateTime =>
    (apiScheduledDateTimeOrPeriod as ApiScheduledDateTime).date_time !== undefined;

export const isApiScheduledDate = (
    apiScheduledDateTimeOrPeriod: ApiScheduledDate | ApiScheduledDateTime | ApiScheduledPeriod,
): apiScheduledDateTimeOrPeriod is ApiScheduledDate =>
    (apiScheduledDateTimeOrPeriod as ApiScheduledDate).date !== undefined;
