import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '../../common/Tooltip';
import { TextInput } from '../../common/form/TextInput';
import { AdditionalProductIdInformationProps } from './types';

export const ADDITIONAL_PROPERTIES_ADDITIONAL_PRODUCT_ID_TEST_ID =
    'ADDITIONAL_PROPERTIES_ADDITIONAL_PRODUCT_ID_TEST_ID';

export const ADDITIONAL_PRODUCT_ID_SOFTWARE_STATUS_TEST_ID = 'ADDITIONAL_PRODUCT_ID_SOFTWARE_STATUS_TEST_ID';
export const ADDITIONAL_PRODUCT_ID_HARDWARE_STATUS_TEST_ID = 'ADDITIONAL_PRODUCT_ID_HARDWARE_STATUS_TEST_ID';
export const ADDITIONAL_PRODUCT_ID_PARTS_GENERATION_STATUS_TEST_ID =
    'ADDITIONAL_PRODUCT_ID_PARTS_GENERATION_STATUS_TEST_ID';

export const AdditionalProductIdInformation = (props: AdditionalProductIdInformationProps) => {
    const { loadItemPath } = props;

    const intl = useIntl();

    return (
        <div data-testid={ADDITIONAL_PROPERTIES_ADDITIONAL_PRODUCT_ID_TEST_ID}>
            <p>
                <FormattedMessage id={'webedi.shipment.additionalProperties.additionalProductId.sectionHeader'} />
            </p>
            <div data-testid={ADDITIONAL_PRODUCT_ID_SOFTWARE_STATUS_TEST_ID}>
                <label className={'margin-right-5'}>
                    <FormattedMessage id={'webedi.shipment.additionalProperties.additionalProductId.softwareStatus'} />
                </label>
                <Tooltip
                    text={intl.formatMessage({
                        id: 'webedi.shipment.additionalProperties.additionalProductId.softwareStatus.tooltip',
                    })}
                    placement={'top'}
                >
                    <span className={'rioglyph rioglyph-info-sign text-size-16 text-color-info'} />
                </Tooltip>
                <Field<string>
                    name={`${loadItemPath}.additionalProductId.softwareStatus`}
                    component={TextInput}
                    className={'form-control'}
                />
            </div>
            <div className={'margin-top-15'} data-testid={ADDITIONAL_PRODUCT_ID_HARDWARE_STATUS_TEST_ID}>
                <label className={'margin-right-5'}>
                    <FormattedMessage id={'webedi.shipment.additionalProperties.additionalProductId.hardwareStatus'} />
                </label>
                <Tooltip
                    text={intl.formatMessage({
                        id: 'webedi.shipment.additionalProperties.additionalProductId.hardwareStatus.tooltip',
                    })}
                    placement={'top'}
                >
                    <span className={'rioglyph rioglyph-info-sign text-size-16 text-color-info'} />
                </Tooltip>
                <Field<string>
                    name={`${loadItemPath}.additionalProductId.hardwareStatus`}
                    component={TextInput}
                    className={'form-control'}
                />
            </div>
            <div className={'margin-top-15'} data-testid={ADDITIONAL_PRODUCT_ID_PARTS_GENERATION_STATUS_TEST_ID}>
                <label className={'margin-right-5'}>
                    <FormattedMessage
                        id={'webedi.shipment.additionalProperties.additionalProductId.partsGenerationStatus'}
                    />
                </label>
                <Tooltip
                    text={intl.formatMessage({
                        id: 'webedi.shipment.additionalProperties.additionalProductId.partsGenerationStatus.tooltip',
                    })}
                    placement={'top'}
                >
                    <span className={'rioglyph rioglyph-info-sign text-size-16 text-color-info'} />
                </Tooltip>
                <Field<string>
                    name={`${loadItemPath}.additionalProductId.partsGenerationStatus`}
                    component={TextInput}
                    className={'form-control'}
                />
            </div>
        </div>
    );
};
