import { TagProps } from '@rio-cloud/rio-uikit/Tag';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreShipmentSuggestions } from '../../actions/suggestions/ShipmentSuggestions.actions';
import { ShipmentSuggestionsQueryParameters } from '../../reducers/suggestions/types';
import { getShipmentSuggestionsQueryParams } from '../../selectors/suggestions/ShipmentSuggestions.selector';
import { getBuyerName } from '../deliverySchedules/BuyerAbbreviationsMappings';

const SELECTED_FILTERS_TAG_LIST = 'SELECTED_FILTERS_TAG_LIST';

export const ShipmentSuggestionsSelectedFilters = () => {
    const queryParams = useAppSelector(getShipmentSuggestionsQueryParams);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const dispatchQueryChangeFactory = (changes: Partial<ShipmentSuggestionsQueryParameters>) => () => {
        dispatch(fetchAndStoreShipmentSuggestions({ ...changes, offset: 0 }));
    };

    const tags: FilterTagProps[] = [
        {
            messageKeyId: 'webedi.common.shipFrom.identifier',
            value: queryParams.shipFromId,
            onDelete: dispatchQueryChangeFactory({ shipFromId: undefined }),
        },
        {
            messageKeyId: 'webedi.common.placeOfDischarge',
            value: queryParams.placeOfDischarge,
            onDelete: dispatchQueryChangeFactory({ placeOfDischarge: undefined }),
        },
        {
            messageKeyId: 'webedi.plantIdentifier',
            value: queryParams.shipToId,
            onDelete: dispatchQueryChangeFactory({ shipToId: undefined }),
        },
        {
            messageKeyId: 'webedi.orderNumber',
            value: queryParams.orderNumber,
            onDelete: dispatchQueryChangeFactory({ orderNumber: undefined }),
        },
        {
            messageKeyId: 'webedi.common.manufacturingCompany',
            value: queryParams.manufacturingCompany && getBuyerName(queryParams.manufacturingCompany),
            onDelete: dispatchQueryChangeFactory({ manufacturingCompany: undefined }),
        },
        {
            messageKeyId: 'webedi.common.earliestDeliveryDate',
            value: queryParams.requestedDeliveryDateFrom && intl.formatDate(queryParams.requestedDeliveryDateFrom),
            onDelete: dispatchQueryChangeFactory({ requestedDeliveryDateFrom: undefined }),
        },
        {
            messageKeyId: 'webedi.common.latestDeliveryDate',
            value: queryParams.requestedDeliveryDateTo && intl.formatDate(queryParams.requestedDeliveryDateTo),
            onDelete: dispatchQueryChangeFactory({ requestedDeliveryDateTo: undefined }),
        },
        {
            messageKeyId: 'webedi.common.filter.requestCondition.placeholder',
            value: queryParams.onlyImmediateDemandAndBackorder,
            onDelete: dispatchQueryChangeFactory({ onlyImmediateDemandAndBackorder: undefined }),
        },
    ].filter((kv): kv is FilterTagProps => !!kv.value!);

    return (
        <>
            <TagList data-testid={SELECTED_FILTERS_TAG_LIST}>
                {tags.map((tag) => (
                    <FilterTag
                        key={tag.messageKeyId}
                        messageKeyId={tag.messageKeyId}
                        value={tag.value}
                        onDelete={tag.onDelete}
                    />
                ))}
            </TagList>
        </>
    );
};

interface FilterTagProps {
    messageKeyId: string;
    value: string | boolean;
    onDelete: () => void;
}

const FilterTag = (props: FilterTagProps) => {
    const { onDelete, messageKeyId, value } = props;

    const tagProperties: TagProps = {
        deletable: true,
        onClick: onDelete,
        size: 'small',
    };
    return (
        <Tag {...tagProperties}>
            <FormattedMessage id={messageKeyId} /> {typeof value === 'string' ? `: ${value}` : ''}
        </Tag>
    );
};
