import { SelectOption } from '@rio-cloud/rio-uikit';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { useGetDispatchProposalFilterValuesQuery } from '../../api/dispatchProposal/dispatchProposalApi';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import { DispatchProposalsFilterParams } from '../../reducers/dispatchProposal/types';
import { LoadingLocation } from '../../reducers/loadingLocations/types';
import { getDispatchProposalsSelectedFilters } from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { MultiFilterDropdown } from '../common/filter/MultiFilterDropdown';
import { getBuyerName } from '../deliverySchedules/BuyerAbbreviationsMappings';
import { useLoadingLocationsQuery } from '../loadingLocations/LoadingLocationHooks';

const MANUFACTURING_COMPANY_KEY = 'MANUFACTURING_COMPANY';
const PLANT_NUMBER_KEY = 'PLANT_NUMBER';
const PLACE_OF_DISCHARGE_KEY = 'PLACE_OF_DISCHARGE';
export const MISSING_LOADING_LOCATION_ID = 'MISSING_LOADING_LOCATION_ID';

export const DispatchProposalsFilterRow = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const activeFilters = useAppSelector(getDispatchProposalsSelectedFilters);

    const { data, isSuccess } = useGetDispatchProposalFilterValuesQuery(dunsNumber!);
    const filterValues = (isSuccess && data) || {};
    const loadingLocations = useLoadingLocationsQuery().data ?? [];

    const onChange = <T extends keyof DispatchProposalsFilterParams>(
        filterChange: Pick<DispatchProposalsFilterParams, T>,
    ) => {
        const updatedFilter: DispatchProposalsFilterParams = { ...activeFilters, ...filterChange };
        dispatch(dispatchProposalsSlice.actions.setDispatchProposalSelectedFilters(updatedFilter));
    };
    const getFilterOptionsForKey = (key: string, currentValues: string[] | undefined): SelectOption[] => {
        const values = filterValues[key] || [];
        return values.map((value) => ({
            id: value,
            label: key === MANUFACTURING_COMPANY_KEY ? getBuyerName(value) : value,
            selected: currentValues?.includes(value),
        }));
    };

    const intl = useIntl();
    const getFilterOptionsForLoadingLocation = (
        values: LoadingLocation[],
        currentValues: string[] | undefined,
    ): SelectOption[] => {
        return values
            .map(
                (value): SelectOption => ({
                    id: value.id,
                    label: value.name,
                    selected: currentValues?.includes(value.id),
                }),
            )
            .concat([
                {
                    id: uuid(),
                    label: undefined,
                    header: true,
                },
                {
                    id: MISSING_LOADING_LOCATION_ID,
                    label: (
                        <div className={'text-color-danger'}>
                            {intl.formatMessage({
                                id: 'webedi.dispatchProposals.planDispatchProposal.body.table.missingLoadingLocation',
                            })}
                        </div>
                    ),
                    selected: currentValues?.includes(MISSING_LOADING_LOCATION_ID),
                },
            ]);
    };

    const getFilterOptionsForTransportConcept = (currentValues: string[] | undefined): SelectOption[] => {
        return Object.values(TransportConcept).map((value) => ({
            id: value,
            label: intl.formatMessage({ id: `webedi.transportConcept.${value}` }),
            selected: currentValues?.includes(value),
        }));
    };

    const filterClassName = 'flex-1-1 form-group margin-10 min-width-10pct';
    return (
        <>
            <div className={'display-flex flex-wrap'}>
                <MultiFilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(
                        MANUFACTURING_COMPANY_KEY,
                        activeFilters.manufacturingCompanyList,
                    )}
                    labelId={'webedi.common.manufacturingCompany'}
                    onChange={(manufacturingCompanyList) => onChange({ manufacturingCompanyList })}
                />
                <MultiFilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForLoadingLocation(
                        loadingLocations,
                        activeFilters.loadingLocationList,
                    )}
                    labelId={'webedi.dispatchProposals.overview.expander.table.header.column.loading'}
                    onChange={(loadingLocationList) => onChange({ loadingLocationList })}
                />
                <MultiFilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(PLANT_NUMBER_KEY, activeFilters.plantNumberList)}
                    labelId={'webedi.plantIdentifier'}
                    onChange={(plantNumberList) => onChange({ plantNumberList })}
                />
                <MultiFilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(PLACE_OF_DISCHARGE_KEY, activeFilters.unloadingPointList)}
                    labelId={'webedi.common.placeOfDischarge'}
                    onChange={(unloadingPointList) => onChange({ unloadingPointList })}
                />
                <MultiFilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForTransportConcept(activeFilters.transportConceptList)}
                    labelId={'webedi.dispatchProposals.overview.expander.header.transportConcept'}
                    onChange={(transportConceptList) => onChange({ transportConceptList })}
                />
            </div>
        </>
    );
};
