import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import { FormattedMessage } from 'react-intl';

export const ServiceInfoMenuItem = () => {
    const title = (
        <div>
            <span>{'WebSCM'}</span>
        </div>
    );

    return (
        <ActionBarItem id={'serviceInfo'}>
            <ActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph-info-sign'} data-testid={SERVICE_INFO_TEST_ID} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={title}>
                <ServiceInfo />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export const ServiceInfo = () => {
    const handleClick = () => {};

    return (
        <div>
            <div className={'line-height-20'}>
                <span className={'rioglyph rioglyph-envelope margin-right-10'} />
                <a href={'mailto:b2bteam@vwgroupsupply.com?subject=Feedback WebSCM'} onClick={handleClick}>
                    <FormattedMessage id={'webedi.serviceInfo.feedback'} />
                </a>
            </div>
            <div className={'line-height-20'}>
                <span className={'rioglyph rioglyph-detail-view-info margin-right-10'} />
                <a href={'/libs.html'} target={'blank'}>
                    <FormattedMessage id={'webedi.serviceInfo.libraries'} />
                </a>
            </div>
        </div>
    );
};

export const SERVICE_INFO_TEST_ID = 'SERVICE_INFO_TEST_ID';
