import { createSelector } from '@reduxjs/toolkit';
import { State } from '../../../../configuration/setup/store';
import {
    ShipmentSuggestion,
    ShipmentSuggestionsFilterParams,
    ShipmentSuggestionsQuery,
    ShipmentSuggestionsQueryParameters,
} from '../../reducers/suggestions/types';

export const getShipmentSuggestions = (state: State): ShipmentSuggestion[] =>
    state.webEdi.shipmentSuggestions.shipmentSuggestions;

export const isLoadingShipmentSuggestions = (state: State): boolean =>
    state.webEdi.shipmentSuggestions.isLoadingShipmentSuggestions;

export const getShipmentSuggestionsQuery = (state: State): ShipmentSuggestionsQuery =>
    state.webEdi.shipmentSuggestions.query;

export const getShipmentSuggestionsQueryParams = (state: State): ShipmentSuggestionsQueryParameters =>
    state.webEdi.shipmentSuggestions.query.params;

export const getTotalNumberOfShipmentSuggestions = (state: State): number =>
    state.webEdi.shipmentSuggestions.totalCount;

export const getShipmentSuggestionsActiveFilters = createSelector(
    [getShipmentSuggestionsQuery],
    (query): ShipmentSuggestionsFilterParams => {
        const params = query.params;
        return {
            requestedDeliveryDateFrom: params.requestedDeliveryDateFrom,
            requestedDeliveryDateTo: params.requestedDeliveryDateTo,
            manufacturingCompany: params.manufacturingCompany,
            orderNumber: params.orderNumber,
            shipToId: params.shipToId,
            shipFromId: params.shipFromId,
            placeOfDischarge: params.placeOfDischarge,
            onlyImmediateDemandAndBackorder: params.onlyImmediateDemandAndBackorder,
        };
    },
);

export const getShipmentSuggestionsFilterValues = (state: State): { [key: string]: string[] } =>
    state.webEdi.shipmentSuggestions.filterValues;
