import { neverReachedFor } from '../../../../utils';
import { MeasurementUnitCode, Partner } from '../../domain/common.types';
import {
    DeliveryInstructionNumber,
    DeliverySchedule,
    DeliveryType,
    LineItem,
    Location,
    PackagingStep,
    PackagingTemplateType,
    ProcessIndicator,
    ResponsibleAgencyCode,
    ScheduledDate,
    ScheduledDateTime,
    ScheduledPeriod,
    isScheduledDate,
    isScheduledDateTime,
} from '../../reducers/deliverySchedules/types';
import { ExtendedArticleMasterData } from '../../reducers/shipments/types';
import { AdditionalPartyIdentifier, ItemDescriptionCode, Party, ReferenceCodeQualifier } from '../../reducers/types';
import { DELIVER_SCHEDULES_API_TAG, DISPATCH_PROPOSAL_FILTER_OPTIONS_API_TAG, baseApi } from '../baseApi';
import { ApiAdditionalPartyIdentifier, ApiReferenceCodeQualifier } from '../common.types';
import { mapPackagingStepToApi } from '../packagingTemplate/packagingTemplateMapper';
import { ApiItemDescriptionCode } from '../shared/itemShortDescription.types';
import { ApiResponsibleAgencyCode } from '../shared/partyIdentificationDetails.types';
import { mapExtendedArticleMasterDataToApi } from '../shared/sharedMappers';
import {
    ApiDeliveryInstructionNumber,
    ApiDeliverySchedule,
    ApiDeliveryType,
    ApiLineItem,
    ApiLocation,
    ApiParty,
} from './deliverySchedule.types';
import { mapDeliverySchedule } from './deliveryScheduleMapper';

export interface PostDeliveryScheduleShipTo {
    placeOfDischargeId: string;
    placeOfDelivery: Location;
}

export interface PostDeliveryScheduleScheduledArticleDetails {
    lineItem: LineItem;
    measurementUnitCode: MeasurementUnitCode;
    orderDocumentIdentifierBuyerAssigned: DeliveryInstructionNumber;
    schedulingData: Array<{
        quantity: number;
        scheduledDeliveryTime: ScheduledDate | ScheduledDateTime | ScheduledPeriod;
    }>;
}

export interface PostDeliverySchedulePackagingTemplate {
    name: string;
    type: PackagingTemplateType;
    steps: PackagingStep[];
}

export interface PostDeliverySchedule {
    dunsNumber: string;
    processIndicator: ProcessIndicator;
    seller: Party;
    buyer: Party;
    shipFrom: Party;
    shipTo: PostDeliveryScheduleShipTo;
    scheduledArticleDetails: PostDeliveryScheduleScheduledArticleDetails;
    articleMasterData?: ExtendedArticleMasterData;
    packagingTemplate?: PostDeliverySchedulePackagingTemplate;
    creationDate: string;
    partner: Partner;
}

export const mapToApiPostDeliverySchedule = (request: PostDeliverySchedule) => ({
    process_indicator: request.processIndicator,
    seller: mapPartyToApiParty(request.seller),
    buyer: mapPartyToApiParty(request.buyer),
    ship_from: mapPartyToApiParty(request.shipFrom),
    ship_to: mapToApiPostDeliveryScheduleShipTo(request.shipTo),
    scheduled_article_details: mapToApiPostDeliveryScheduleScheduledArticleDetails(request.scheduledArticleDetails),
    article_master_data:
        request.articleMasterData !== undefined
            ? mapExtendedArticleMasterDataToApi(request.articleMasterData)
            : undefined,
    packaging_template:
        request.packagingTemplate !== undefined
            ? mapPostDeliverySchedulePackagingTemplateToApi(request.packagingTemplate)
            : undefined,
    creation_date: request.creationDate,
    partner: request.partner,
});

const mapPartyToApiParty = (party: Party): ApiParty => ({
    party_identification_details: {
        identifier: party.partyIdentificationDetails.identifier,
        responsible_agency_code: mapToResponsibleAgencyCodeApi(party.partyIdentificationDetails.responsibleAgencyCode),
    },
    additional_party_identifier: party.additionalPartyIdentifier
        ? mapToAdditionalPartyIdentifierApi(party.additionalPartyIdentifier!)
        : undefined,
    party_name: party.partyName,
    street: party.street,
    city_name: party.cityName,
    country_sub_entity_name_code: party.countrySubEntityNameCode,
    postal_identification_code: party.postalIdentificationCode,
    country_name_code: party.countryNameCode,
});

const mapToResponsibleAgencyCodeApi = (code: ResponsibleAgencyCode): ApiResponsibleAgencyCode => {
    switch (code) {
        case ResponsibleAgencyCode.DUNS:
            return ApiResponsibleAgencyCode.DUNS;
        case ResponsibleAgencyCode.SELLER:
            return ApiResponsibleAgencyCode.SELLER;
        case ResponsibleAgencyCode.BUYER:
            return ApiResponsibleAgencyCode.BUYER;
        default:
            return neverReachedFor(code);
    }
};

const mapToAdditionalPartyIdentifierApi = (additional: AdditionalPartyIdentifier): ApiAdditionalPartyIdentifier => ({
    reference_identifier: additional.identifier,
    reference_code_qualifier: mapToReferenceCodeQualifierApi(additional.referenceCodeQualifier),
});

const mapToReferenceCodeQualifierApi = (code: ReferenceCodeQualifier): ApiReferenceCodeQualifier => {
    switch (code) {
        case ReferenceCodeQualifier.DUNS:
            return ApiReferenceCodeQualifier.DUNS;
        default:
            return neverReachedFor(code);
    }
};

const mapToApiPostDeliveryScheduleShipTo = (shipTo: PostDeliveryScheduleShipTo) => ({
    place_of_discharge_id: shipTo.placeOfDischargeId,
    place_of_delivery: mapToApiLocation(shipTo.placeOfDelivery),
});

export const mapToApiLocation = (location: Location): ApiLocation => ({
    location_name_code: location.locationNameCode,
    responsible_agency_code: location.responsibleAgencyCode,
    location_name: location.locationName,
});

const mapToApiPostDeliveryScheduleScheduledArticleDetails = (
    scheduledArticleDetails: PostDeliveryScheduleScheduledArticleDetails,
) => ({
    line_item: mapToApiLineItem(scheduledArticleDetails.lineItem),
    measurement_unit_code: scheduledArticleDetails.measurementUnitCode,
    order_document_identifier_buyer_assigned: mapToApiDeliveryInstructionNumber(
        scheduledArticleDetails.orderDocumentIdentifierBuyerAssigned,
    ),
    scheduling_data: scheduledArticleDetails.schedulingData.map((it) => ({
        quantity: it.quantity,
        scheduled_delivery_time: mapToApiScheduledDeliveryTime(it.scheduledDeliveryTime),
    })),
});

const mapToApiLineItem = (lineItem: LineItem): ApiLineItem => ({
    item_short_descriptions: lineItem.itemShortDescriptions.map((it) => ({
        item_description: it.itemDescription,
        item_description_code: mapToApiItemDescriptionCode(it.itemDescriptionCode),
    })),
    line_item_identifier: {
        item_number_identification: {
            item_identifier: lineItem.lineItemIdentifier.itemNumberIdentification.itemIdentifier,
            item_type_identification_code:
                lineItem.lineItemIdentifier.itemNumberIdentification.itemTypeIdentificationCode,
        },
        line_item_identifier: lineItem.lineItemIdentifier.lineItemIdentifier,
    },
});

const mapToApiItemDescriptionCode = (itemDescriptionCode: ItemDescriptionCode): ApiItemDescriptionCode => {
    switch (itemDescriptionCode) {
        case ItemDescriptionCode.PRODUCTION:
            return ApiItemDescriptionCode.PRODUCTION;
        case ItemDescriptionCode.SPAREPART:
            return ApiItemDescriptionCode.SPAREPART;
        case ItemDescriptionCode.PRODUCTION_AND_SPAREPART:
            return ApiItemDescriptionCode.PRODUCTION_AND_SPAREPART;
        case ItemDescriptionCode.TRY_OUT:
            return ApiItemDescriptionCode.TRY_OUT;
        case ItemDescriptionCode.PILOT:
            return ApiItemDescriptionCode.PILOT;
        case ItemDescriptionCode.ADDITIONAL_REQUIREMENT:
            return ApiItemDescriptionCode.ADDITIONAL_REQUIREMENT;
        case ItemDescriptionCode.INITIAL_SAMPLE:
            return ApiItemDescriptionCode.INITIAL_SAMPLE;
        case ItemDescriptionCode.SAMPLE:
            return ApiItemDescriptionCode.SAMPLE;
        case ItemDescriptionCode.OTHER:
            return ApiItemDescriptionCode.OTHER;
        default:
            return neverReachedFor(itemDescriptionCode);
    }
};

const mapToApiDeliveryInstructionNumber = (
    deliveryInstructionNumber: DeliveryInstructionNumber,
): ApiDeliveryInstructionNumber => ({
    reference_identifier: deliveryInstructionNumber.referenceIdentifier,
    reference_date: deliveryInstructionNumber.referenceDate,
});

const mapToApiScheduledDeliveryTime = (scheduledDeliveryTime: ScheduledDate | ScheduledDateTime | ScheduledPeriod) => {
    const type = mapToApiDeliveryType(scheduledDeliveryTime.type);
    if (isScheduledDate(scheduledDeliveryTime)) {
        return {
            date_type: 'DATE',
            date: scheduledDeliveryTime.scheduledDate,
            type,
        };
    }
    if (isScheduledDateTime(scheduledDeliveryTime)) {
        return {
            date_type: 'DATE_TIME',
            date: scheduledDeliveryTime.scheduledDateTime,
            type,
        };
    }
    return {
        date_type: 'DATE_PERIOD',
        earliest_delivery_date: scheduledDeliveryTime.earliestDeliveryDate,
        latest_delivery_date: scheduledDeliveryTime.latestDeliveryDate,
        type,
    };
};

const mapToApiDeliveryType = (type: DeliveryType): ApiDeliveryType => {
    switch (type) {
        case DeliveryType.DELIVERY:
            return ApiDeliveryType.DELIVERY;
        case DeliveryType.SHIPMENT:
            return ApiDeliveryType.SHIPMENT;
        default:
            return neverReachedFor(type);
    }
};

const mapPostDeliverySchedulePackagingTemplateToApi = (packagingTemplate: PostDeliverySchedulePackagingTemplate) => ({
    name: packagingTemplate.name,
    type: packagingTemplate.type,
    steps: packagingTemplate.steps.map(mapPackagingStepToApi),
});

export const deliveryScheduleApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        postDeliverySchedule: builder.mutation<DeliverySchedule, PostDeliverySchedule>({
            query: (arg: PostDeliverySchedule) => ({
                url: `/shippers/${arg.dunsNumber}/delivery-schedules`,
                method: 'POST',
                body: mapToApiPostDeliverySchedule(arg),
            }),
            invalidatesTags: () => [DELIVER_SCHEDULES_API_TAG, DISPATCH_PROPOSAL_FILTER_OPTIONS_API_TAG],
            transformResponse: (response: ApiDeliverySchedule) => mapDeliverySchedule(response),
        }),
    }),
});

export const { usePostDeliveryScheduleMutation } = deliveryScheduleApi;
