import { createSelector } from '@reduxjs/toolkit';
import { State } from '../../../../configuration/setup/store';
import { LoadItemPositionReference, loadItemIdToDeliveryNoteNumberAndPositionMap } from '../../../../utils';
import {
    ArticleSuggestion,
    LoadItem,
    SavingShipmentStatus,
    Shipment,
    ShipmentsFilterParams,
    ShipmentsQuery,
} from '../../reducers/shipments/types';

export const getSelectedShipmentId = (state: State): string | undefined => state.webEdi.shipments.selectedShipmentId;

export const getNextAvailableDeliveryNoteNumber = (state: State): number | undefined =>
    state.webEdi.shipments.nextAvailableDeliveryNoteNumber;

export const getSelectedShipment = (state: State): Shipment | undefined =>
    state.webEdi.shipments.shipments.filter((shipment) => shipment.id === getSelectedShipmentId(state))[0];

export const getShipments = (state: State): Shipment[] => state.webEdi.shipments.shipments;

export const getTotalCountOfMatchedShipments = (state: State): number =>
    state.webEdi.shipments.totalCountOfMatchedShipments;

export const getShipmentsQuery = (state: State, exported: boolean): ShipmentsQuery => {
    if (exported) {
        return state.webEdi.shipments.exportedQuery;
    }
    return state.webEdi.shipments.notExportedQuery;
};

export const getShipmentsActiveFilters = createSelector([getShipmentsQuery], (query): ShipmentsFilterParams => {
    return {
        plantNumber: query.params.plantNumber,
        placeOfDischarge: query.params.placeOfDischarge,
        exportedAtFrom: query.params.exportedAtFrom,
        exportedAtTo: query.params.exportedAtTo,
    };
});

export const getShipmentsFilterValues = (state: State, exported: boolean) => {
    if (exported) {
        return state.webEdi.shipments.exportedFilterValues;
    }
    return state.webEdi.shipments.notExportedFilterValues;
};

export const isLoadingShipments = (state: State): boolean => state.webEdi.shipments.isLoadingShipments;

export const getSavingShipmentStatus = (state: State): SavingShipmentStatus =>
    state.webEdi.shipments.savingShipmentStatus;

export const getArticleSuggestions = (state: State): ArticleSuggestion[] =>
    state.webEdi.shipments.articleSuggestionSidebar.articleSuggestions;

export const isShipmentArticleSuggestionSidebarOpen = (state: State): boolean =>
    state.webEdi.shipments.articleSuggestionSidebar.open;

export const getReferencedDeliveryNoteNumber = (state: State) =>
    state.webEdi.shipments.articleSuggestionSidebar.referencedDeliveryNote;

export const getArticleSuggestionToAdd = (state: State) =>
    state.webEdi.shipments.form.outstandingArticleSuggestionForDeliveryNote;

export const getLoadItemPathForAdditionalPropertiesDialog = (state: State) =>
    state.webEdi.shipments.form.loadItemPathForAdditionalPropertiesDialog;

export const getAllLoadItemsForSelectedShipment = (state: State): LoadItem[] => {
    const load = getSelectedShipment(state)?.load;
    if (load === undefined) {
        throw new Error('No selected shipment found. This should never happen');
    }
    return load.flatMap((deliveryNote) => deliveryNote.loadItems);
};

export const getLoadItemMapForSelectedShipment = createSelector(
    [getSelectedShipment],
    (shipment): Map<string, LoadItem> => {
        if (shipment?.load === undefined) {
            throw new Error('No selected shipment found. This should never happen');
        }
        const loadItems = shipment.load.flatMap((deliveryNote) => deliveryNote.loadItems);
        return new Map(loadItems.map((loadItem) => [loadItem.id, loadItem]));
    },
);

export const getLoadItemIdToDeliveryNoteNumberAndPositionMapForSelectedShipment = createSelector(
    [getSelectedShipment],
    (shipment): Map<string, LoadItemPositionReference> => {
        if (shipment === undefined) {
            throw new Error('No selected shipment found. This should never happen');
        }
        return loadItemIdToDeliveryNoteNumberAndPositionMap(shipment.load);
    },
);
