import { Mutator } from 'final-form';
import { ReactElement, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDiscoveryNextGenFeatures } from '../../../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../../../configuration/featureToggle/utils';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { MeansOfTransportMode, RoadTransport } from '../../../../domain/meansOfTransport.types';
import { Shipment } from '../../../../reducers/shipments/types';
import { getSelectedShipment } from '../../../../selectors/shipments/Shipments.selector';
import { SelectInput } from '../../../common/form/SelectInput';
import { TransportMode } from '../../TransportMode';

export const MEANS_OF_TRANSPORT_DROPDOWN_TEST_ID = 'MEANS_OF_TRANSPORT_DROPDOWN_TEST_ID';

interface MeansOfTransportModeOptions {
    id: MeansOfTransportMode;
    label: string;
}

export const formPropertyIds = {
    form: 'meansOfTransport',
    mode: 'mode',
    shipName: 'shipName',
    containerNumber: 'containerNumber',
    trainNumber: 'trainNumber',
    railCarNumber: 'railCarNumber',
    registrationNumber: 'registrationNumber',
    trailerRegistrationNumber: 'trailerRegistrationNumber',
    flightNumber: 'flightNumber',
    parcelNumber: 'parcelNumber',
    swapBodyRegistrationNumber: 'swapBodyRegistrationNumber',
    swapBodyNumber: 'swapBodyNumber',
};

export const formPropertyNames = {
    mode: 'meansOfTransport.mode',
    shipName: 'meansOfTransport.shipName',
    containerNumber: 'meansOfTransport.containerNumber',
    trainNumber: 'meansOfTransport.trainNumber',
    railCarNumber: 'meansOfTransport.railCarNumber',
    registrationNumber: 'meansOfTransport.registrationNumber',
    trailerRegistrationNumber: 'meansOfTransport.trailerRegistrationNumber',
    flightNumber: 'meansOfTransport.flightNumber',
    parcelNumber: 'meansOfTransport.parcelNumber',
    swapBodyRegistrationNumber: 'meansOfTransport.swapBodyRegistrationNumber',
    swapBodyNumber: 'meansOfTransport.swapBodyNumber',
};

export const MeansOfTransportEdit = () => {
    return (
        <div className={'display-flex-sm flex-row align-items-center width-100pct'}>
            <MeansOfTransportModeEdit />
            <MeansOfTransportValuesEdit />
        </div>
    );
};

const MeansOfTransportModeEdit = () => {
    const shipment = useAppSelector(getSelectedShipment);
    const intl = useIntl();
    const meansOfTransportModeOptions: MeansOfTransportModeOptions[] = [
        {
            id: MeansOfTransportMode.MARITIME,
            label: intl.formatMessage({ id: 'webedi.shipment.meansOfTransport.labels.maritime' }),
        },
        {
            id: MeansOfTransportMode.AIR,
            label: intl.formatMessage({ id: 'webedi.shipment.meansOfTransport.labels.air' }),
        },
        {
            id: MeansOfTransportMode.RAIL,
            label: intl.formatMessage({ id: 'webedi.shipment.meansOfTransport.labels.rail' }),
        },
        {
            id: MeansOfTransportMode.ROAD,
            label: intl.formatMessage({ id: 'webedi.shipment.meansOfTransport.labels.road' }),
        },
        {
            id: MeansOfTransportMode.MAIL,
            label: intl.formatMessage({ id: 'webedi.shipment.meansOfTransport.labels.mail' }),
        },
        {
            id: MeansOfTransportMode.MULTIMODAL,
            label: intl.formatMessage({ id: 'webedi.shipment.meansOfTransport.labels.multimodal' }),
        },
    ];

    return (
        <div className={'width-33pct-sm padding-right-15'} data-testid={MEANS_OF_TRANSPORT_DROPDOWN_TEST_ID}>
            <label className={'align-left'} htmlFor={formPropertyIds.mode}>
                <FormattedMessage id={'webedi.shipment.meansOfTransport.labels.meansOfTransport'} />
            </label>
            <Field
                name={formPropertyNames.mode}
                component={SelectInput}
                options={meansOfTransportModeOptions}
                placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                disabled={shipment?.transportOrderReference !== undefined}
            />
        </div>
    );
};

export const setInitialVehicleRegistrationNumber: Mutator<Shipment> = (
    args: [registrationNumber: string],
    state,
    // biome-ignore lint/correctness/noUnusedVariables: Parameter is required by final-form
    { changeValue, getIn },
) => {
    const registrationNumber: string = args[0];
    changeValue(state, formPropertyNames.registrationNumber, () => registrationNumber);
};

const RoadTransportMode = () => {
    const webSCMPlusFeaturesAreActive = getValueOrDefaultWhenLoading(useDiscoveryNextGenFeatures());
    const { mutators } = useForm<Shipment>();
    const { initialValues } = useFormState<Shipment>();
    const [roadTransportProps, setRoadTransportProps] = useState({});

    useEffect(() => {
        const meansOfTransport = initialValues?.meansOfTransport as RoadTransport;
        const registrationNumberFromUser = meansOfTransport?.registrationNumber;
        const registrationNumberFromConfirmation = meansOfTransport?.registrationNumberFromConfirmation;

        const registrationNumberInitialValue = registrationNumberFromUser ?? registrationNumberFromConfirmation;
        registrationNumberInitialValue && mutators.setInitialVehicleRegistrationNumber(registrationNumberInitialValue);

        const showPreviousValue = registrationNumberInitialValue !== registrationNumberFromConfirmation;
        if (webSCMPlusFeaturesAreActive && registrationNumberFromUser) {
            setRoadTransportProps({
                tdtPropertyPreviousValue: (showPreviousValue && registrationNumberFromConfirmation) || undefined,
                tdtPropertyTooltip: 'webedi.shipment.meansOfTransport.hint.valueEnteredManually',
            });
        }
    }, [initialValues, webSCMPlusFeaturesAreActive, mutators]);

    return (
        <TransportMode
            {...roadTransportProps}
            tdtPropertyId={formPropertyIds.registrationNumber}
            tdtPropertyValue={formPropertyNames.registrationNumber}
            eqdPropertyId={formPropertyIds.trailerRegistrationNumber}
            eqdPropertyValue={formPropertyNames.trailerRegistrationNumber}
            readonly={false}
        />
    );
};
const MeansOfTransportValuesEdit = () => {
    return (
        <MeansOfTransportCondition when={formPropertyNames.mode} is={Object.values(MeansOfTransportMode)}>
            <div className={'width-66pct-sm'}>
                <MeansOfTransportCondition when={formPropertyNames.mode} is={MeansOfTransportMode.MARITIME}>
                    <TransportMode
                        tdtPropertyId={formPropertyIds.shipName}
                        tdtPropertyValue={formPropertyNames.shipName}
                        eqdPropertyId={formPropertyIds.containerNumber}
                        eqdPropertyValue={formPropertyNames.containerNumber}
                        readonly={false}
                    />
                </MeansOfTransportCondition>
                <MeansOfTransportCondition when={formPropertyNames.mode} is={MeansOfTransportMode.RAIL}>
                    <TransportMode
                        tdtPropertyId={formPropertyIds.trainNumber}
                        tdtPropertyValue={formPropertyNames.trainNumber}
                        eqdPropertyId={formPropertyIds.railCarNumber}
                        eqdPropertyValue={formPropertyNames.railCarNumber}
                        readonly={false}
                    />
                </MeansOfTransportCondition>
                <MeansOfTransportCondition when={formPropertyNames.mode} is={MeansOfTransportMode.ROAD}>
                    <RoadTransportMode />
                </MeansOfTransportCondition>
                <MeansOfTransportCondition when={formPropertyNames.mode} is={MeansOfTransportMode.AIR}>
                    <TransportMode
                        tdtPropertyId={formPropertyIds.flightNumber}
                        tdtPropertyValue={formPropertyNames.flightNumber}
                        readonly={false}
                    />
                </MeansOfTransportCondition>
                <MeansOfTransportCondition when={formPropertyNames.mode} is={MeansOfTransportMode.MAIL}>
                    <TransportMode
                        tdtPropertyId={formPropertyIds.parcelNumber}
                        tdtPropertyValue={formPropertyNames.parcelNumber}
                        readonly={false}
                    />
                </MeansOfTransportCondition>
                <MeansOfTransportCondition when={formPropertyNames.mode} is={MeansOfTransportMode.MULTIMODAL}>
                    <TransportMode
                        tdtPropertyId={formPropertyIds.swapBodyRegistrationNumber}
                        tdtPropertyValue={formPropertyNames.swapBodyRegistrationNumber}
                        eqdPropertyId={formPropertyIds.swapBodyNumber}
                        eqdPropertyValue={formPropertyNames.swapBodyNumber}
                        readonly={false}
                    />
                </MeansOfTransportCondition>
            </div>
        </MeansOfTransportCondition>
    );
};

interface MeansOfTransportConditionArgs {
    when: string;
    is: MeansOfTransportMode | MeansOfTransportMode[];
    children: ReactElement;
}

export const MeansOfTransportCondition = ({ when, is, children }: MeansOfTransportConditionArgs) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (is.includes(value) ? children : null)}
    </Field>
);
