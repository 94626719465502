import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { AuxiliaryHandlingUnitGroup } from '../../../reducers/auxiliaryPackaging.types';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { getTemplateStepConfiguration } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { getAllPackagingMaterials } from '../../../selectors/packaging/PackagingMaterial.selector';
import {
    AuxiliaryPackagingConfiguration,
    mapAuxiliaryPackagingFormValues,
} from '../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingConfiguration';
import { AuxiliaryPackagingConfigurationFormId } from '../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingFormConfiguration';
import { AuxiliaryPackagingConfigurationFormValues } from '../../common/dialog/AuxiliaryPackaging/types';
import { SplitView } from '../../shipments/packaging/dialogs/SplitView';
import { PackagingTemplateContentInformation } from './PackagingTemplateContentInformation';

export const AUXILIARY_PACKAGING_TEMPLATE_TEST_IDS = {
    content: 'AUXILIARY_PACKAGING_TEMPLATE_DIALOG_CONTENT_TEST_ID',
    groupingDescription: 'AUXILIARY_PACKAGING_TEMPLATE_DIALOG_GROUPING_DESCRIPTION_TEST_ID',
};

export const AuxiliaryPackagingTemplateConfigurationContainer = () => {
    const dispatch = useAppDispatch();
    const templateStepConfiguration = useAppSelector(getTemplateStepConfiguration);
    const packagingMaterials = useAppSelector(getAllPackagingMaterials);

    useEffect(() => {
        dispatch(
            deliverySchedulesSlice.actions.updateFormIdInPackagingWizard(AuxiliaryPackagingConfigurationFormId.form),
        );
    }, [dispatch]);

    if (!templateStepConfiguration) {
        return <></>;
    }

    const onSubmitHandler = (values: AuxiliaryPackagingConfigurationFormValues) => {
        const auxiliaryPackaging: AuxiliaryHandlingUnitGroup[] = mapAuxiliaryPackagingFormValues(
            packagingMaterials,
            values.auxiliaryPackagings,
        );
        dispatch(
            deliverySchedulesSlice.actions.createPackagingTemplateStep({
                auxiliaryPackaging,
            }),
        );

        dispatch(deliverySchedulesSlice.actions.clearTemplateStepConfiguration());
        dispatch(deliverySchedulesSlice.actions.hideTemplateDialog());
    };

    return (
        <SplitView
            left={{
                content: (
                    <PackagingTemplateContentInformation
                        templateStepConfiguration={templateStepConfiguration}
                        displayHeadline={true}
                    />
                ),
                renderAsColumn: true,
            }}
            main={{
                content: (
                    <AuxiliaryPackagingConfiguration
                        stabilizationInformation={undefined}
                        onSubmitHandler={onSubmitHandler}
                        newPackagingFromWizardType={templateStepConfiguration.typeOfHandlingUnit}
                    />
                ),
                renderAsColumn: true,
            }}
            dataTestId={AUXILIARY_PACKAGING_TEMPLATE_TEST_IDS.content}
        />
    );
};
