import { isAfter, localNow, plusDuration, toLocalTime, today } from '../../../../../dateUtils';
import { ExpressShipmentCourierOptions } from '../../../domain/courierOrderIntent.types';
import { FieldErrorType } from '../../common/form/types';
import { CourierForm, CourierOrderIntentDialogFormFieldError } from './courierOrderIntentDialog.types';

export const PICKUP_TIME_BUFFER = 15;

export const validateFirstStep = (values: CourierForm) => {
    const errors: { [key: string]: string } = {};

    validateNames(values, errors);
    validatePhoneNumbers(values, errors);
    validateCourierOptions(values, errors);
    validateTermsAndConditions(values, errors);
    validateTransmissionOfData(values, errors);

    return errors;
};

export const validateSecondStep = (
    values: CourierForm,
    expressShipmentCourierOptions?: ExpressShipmentCourierOptions,
) => {
    const errors: { [key: string]: string } = {};

    validateApprovalFields(values, errors, expressShipmentCourierOptions);

    return errors;
};

const validateNames = (values: CourierForm, errors: { [p: string]: string }) => {
    if (!values.senderFirstAndLastName) {
        errors.senderFirstAndLastName = CourierOrderIntentDialogFormFieldError.SHIPPER_NAME_AND_LASTNAME_ABSENT;
    }
    if (values.senderFirstAndLastName && values.senderFirstAndLastName.length > validationRules.nameLength) {
        errors.senderFirstAndLastName = FieldErrorType.TOO_LONG;
    }
    if (!values.recipientFirstAndLastName) {
        errors.recipientFirstAndLastName = CourierOrderIntentDialogFormFieldError.RECIPIENT_NAME_AND_LASTNAME_ABSENT;
    }
    if (values.recipientFirstAndLastName && values.recipientFirstAndLastName.length > validationRules.nameLength) {
        errors.recipientFirstAndLastName = FieldErrorType.TOO_LONG;
    }
};

const validatePhoneNumbers = (values: CourierForm, errors: { [p: string]: string }) => {
    if (!isPhoneNumberValid(values.senderPhoneNumber)) {
        errors.senderPhoneNumber = CourierOrderIntentDialogFormFieldError.SHIPPER_PHONE_NUMBER_ABSENT;
    }
    if (!isPhoneNumberValid(values.recipientPhoneNumber)) {
        errors.recipientPhoneNumber = CourierOrderIntentDialogFormFieldError.RECIPIENT_PHONE_NUMBER_ABSENT;
    }
};

const isPhoneNumberValid = (value?: string) => value && value.trim()?.length >= 4;

const validateCourierOptions = (values: CourierForm, errors: { [p: string]: string }) => {
    if (!values.pickupDate) {
        errors.pickupDate = CourierOrderIntentDialogFormFieldError.PICKUP_DATE_ABSENT;
    }

    if (!values.pickupEarliestTime) {
        errors.pickupEarliestTime = CourierOrderIntentDialogFormFieldError.PICKUP_EARLIEST_TIME_ABSENT;
    }
    const bufferedNow = plusDuration(localNow(), { minutes: PICKUP_TIME_BUFFER });

    const pickupAfterToday = !!(values.pickupDate && isAfter(values.pickupDate, today()));
    const pickupTimeTooSoon = !!(values.pickupEarliestTime && values.pickupEarliestTime < toLocalTime(bufferedNow));

    if (!pickupAfterToday && pickupTimeTooSoon) {
        errors.pickupEarliestTime = CourierOrderIntentDialogFormFieldError.PICKUP_EARLIEST_TIME_TOO_SOON;
    }

    if (!values.pickupLatestTime) {
        errors.pickupLatestTime = CourierOrderIntentDialogFormFieldError.PICKUP_LATEST_TIME_ABSENT;
    }

    if (values.pickupEarliestTime && values.pickupLatestTime) {
        if (values.pickupEarliestTime > values.pickupLatestTime) {
            errors.pickupEarliestTime = CourierOrderIntentDialogFormFieldError.PICKUP_EARLIEST_TIME_AFTER_LATEST_TIME;
        }
    }
};

const validateTermsAndConditions = (values: CourierForm, errors: { [p: string]: string }) => {
    if (!values.termsAndConditions) {
        errors.termsAndConditions = CourierOrderIntentDialogFormFieldError.TERMS_AND_CONDITIONS_CHECKBOX_UNCHECKED;
    }
};

const validateTransmissionOfData = (values: CourierForm, errors: { [p: string]: string }) => {
    if (!values.transmissionOfData) {
        errors.transmissionOfData =
            CourierOrderIntentDialogFormFieldError.TRANSMISSION_OF_DATA_ALLOWED_CHECKBOX_UNCHECKED;
    }
};

const validateApprovalFields = (
    values: CourierForm,
    errors: { [p: string]: string },
    expressShipmentCourierOptions?: ExpressShipmentCourierOptions,
) => {
    const selectedOptionId = values.serviceLevelSelection;
    const selectedOption = expressShipmentCourierOptions?.serviceLevels.find((item) => item.id === selectedOptionId);

    if (selectedOption?.requiresApproval) {
        if (!values.approvingPerson) {
            errors.approvingPerson =
                CourierOrderIntentDialogFormFieldError.SERVICE_LEVEL_SELECTION_APPROVING_PERSON_ABSENT;
        }
        if (values.approvingPerson && values.approvingPerson.length > validationRules.nameLength) {
            errors.approvingPerson = FieldErrorType.TOO_LONG;
        }
        if (!values.approvingCostCenter) {
            errors.approvingCostCenter =
                CourierOrderIntentDialogFormFieldError.SERVICE_LEVEL_SELECTION_APPROVING_COSTCENTER_ABSENT;
        }
    }
};

const validationRules = {
    nameLength: 35,
};
