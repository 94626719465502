import { TagProps } from '@rio-cloud/rio-uikit/Tag';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import { DispatchProposalsFilterParams } from '../../reducers/dispatchProposal/types';
import { getDispatchProposalsSelectedFilters } from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { getBuyerName } from '../deliverySchedules/BuyerAbbreviationsMappings';
import { useLoadingLocationsQuery } from '../loadingLocations/LoadingLocationHooks';
import { MISSING_LOADING_LOCATION_ID } from './DispatchProposalsFilterRow';

export const SELECTED_FILTERS_TAG_LIST = 'SELECTED_FILTERS_TAG_LIST';

export const DispatchProposalsSelectedFilters = () => {
    const selectedFilters = useAppSelector(getDispatchProposalsSelectedFilters);
    const dispatch = useAppDispatch();
    const dispatchFilterChangeFactory =
        <T extends keyof DispatchProposalsFilterParams>(filterChange: Pick<DispatchProposalsFilterParams, T>) =>
        () => {
            const updatedFilter = { ...selectedFilters, ...filterChange };
            dispatch(dispatchProposalsSlice.actions.setDispatchProposalSelectedFilters(updatedFilter));
        };
    const intl = useIntl();

    const loadingLocations = useLoadingLocationsQuery().data ?? [];
    const getLoadingLocationName = (item: string) => {
        if (item === MISSING_LOADING_LOCATION_ID) {
            return intl.formatMessage({
                id: 'webedi.dispatchProposals.planDispatchProposal.body.table.missingLoadingLocation',
            });
        }
        return loadingLocations.find((location) => location.id === item)?.name ?? item;
    };

    const tags = [
        ...(selectedFilters.manufacturingCompanyList?.map((item) => ({
            messageKeyId: 'webedi.common.manufacturingCompany',
            value: item && getBuyerName(item),
            onDelete: dispatchFilterChangeFactory({
                manufacturingCompanyList: selectedFilters.manufacturingCompanyList?.filter((it) => it !== item),
            }),
        })) ?? []),
        ...(selectedFilters.loadingLocationList?.map((item) => ({
            messageKeyId: 'webedi.dispatchProposals.overview.expander.table.header.column.loading',
            value: item && getLoadingLocationName(item),
            onDelete: dispatchFilterChangeFactory({
                loadingLocationList: selectedFilters.loadingLocationList?.filter((it) => it !== item),
            }),
        })) ?? []),
        ...(selectedFilters.plantNumberList?.map((item) => ({
            messageKeyId: 'webedi.plantIdentifier',
            value: item,
            onDelete: dispatchFilterChangeFactory({
                plantNumberList: selectedFilters.plantNumberList?.filter((it) => it !== item),
            }),
        })) ?? []),
        ...(selectedFilters.unloadingPointList?.map((item) => ({
            messageKeyId: 'webedi.common.placeOfDischarge',
            value: item,
            onDelete: dispatchFilterChangeFactory({
                unloadingPointList: selectedFilters.unloadingPointList?.filter((it) => it !== item),
            }),
        })) ?? []),
        ...(selectedFilters.transportConceptList?.map((item) => ({
            messageKeyId: 'webedi.dispatchProposals.overview.expander.header.transportConcept',
            value: intl.formatMessage({ id: `webedi.transportConcept.${item}` }),
            onDelete: dispatchFilterChangeFactory({
                transportConceptList: selectedFilters.transportConceptList?.filter((it) => it !== item),
            }),
        })) ?? []),
    ].filter((kv): kv is FilterTagProps => !!kv.value);

    return (
        <>
            <TagList data-testid={SELECTED_FILTERS_TAG_LIST}>
                {tags.map((tag) => (
                    <FilterTag
                        key={`${tag.messageKeyId}_${tag.value}`}
                        messageKeyId={tag.messageKeyId}
                        value={tag.value}
                        onDelete={tag.onDelete}
                    />
                ))}
            </TagList>
        </>
    );
};

interface FilterTagProps {
    messageKeyId: string;
    value: 'small';
    onDelete: () => void;
}

const FilterTag = (props: FilterTagProps) => {
    const { onDelete, messageKeyId, value } = props;

    const tagProperties: TagProps = {
        deletable: true,
        onClick: onDelete,
        size: 'small',
    };
    return (
        <Tag {...tagProperties}>
            <FormattedMessage id={messageKeyId} />: {value}
        </Tag>
    );
};
