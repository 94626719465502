import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { AuxiliaryHandlingUnitGroup } from '../../../reducers/auxiliaryPackaging.types';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    HomogeneousPackagingStepConfiguration,
    HomogeneousPackagingStepLayerStabilization,
    PackagingStepType,
} from '../../../reducers/deliverySchedules/types';
import { PackagingMaterialState } from '../../../reducers/packaging/types';
import { getSelectedPackagingTemplate } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { getAllPackagingMaterials } from '../../../selectors/packaging/PackagingMaterial.selector';
import { findPackagingMaterialResultByBoxCode } from '../../common/PackagingCommon';
import { PackagingConfigurationNumberInput } from '../../common/dialog/PackagingConfigurationNumberInput';
import { PackagingMaterialFormValues } from '../../common/form/BoxFinderInput';
import { FieldErrorType, FormErrors } from '../../common/form/types';
import { BoxFinder } from '../../packaging/BoxFinder';
import { SplitView } from '../../shipments/packaging/dialogs/SplitView';
import { PackagingTemplateContentInformation } from './PackagingTemplateContentInformation';

export const HOMOGENEOUS_PACKAGING_TEMPLATE_TEST_IDS = {
    content: 'HOMOGENEOUS_PACKAGING_TEMPLATE_DIALOG_CONTENT_TEST_ID',
};

export const formIds = {
    form: 'homogeneousPackagingTemplateDialogForm',
    layersPerHandlingUnit: 'layersPerHandlingUnit',
    contentPerLayer: 'contentPerLayer',
    packagingMaterial: 'packagingMaterial',
};

export interface HomogeneousPackagingTemplateFormValues {
    layersPerHandlingUnit?: number;
    contentPerLayer?: number;
    packagingMaterial?: PackagingMaterialFormValues;
}

const formPropertyNames: Record<
    keyof HomogeneousPackagingTemplateFormValues,
    keyof HomogeneousPackagingTemplateFormValues
> = {
    layersPerHandlingUnit: 'layersPerHandlingUnit',
    contentPerLayer: 'contentPerLayer',
    packagingMaterial: 'packagingMaterial',
};

type HomogeneousPackagingTemplateFormErrors = FormErrors<HomogeneousPackagingTemplateFormValues>;

function validatePackagingMaterial(
    packagingMaterial: PackagingMaterialFormValues | undefined,
    validationResult: HomogeneousPackagingTemplateFormErrors,
): HomogeneousPackagingTemplateFormErrors {
    if (packagingMaterial) {
        const { tareWeightInKg, ownership, isReusable, stackingFactor } = packagingMaterial;

        if (tareWeightInKg === undefined || String(tareWeightInKg) === '') {
            validationResult.packagingMaterial!.tareWeightInKg = FieldErrorType.REQUIRED;
        }
        if (ownership === undefined) {
            validationResult.packagingMaterial!.ownership = FieldErrorType.REQUIRED;
        }
        if (isReusable === undefined) {
            validationResult.packagingMaterial!.isReusable = FieldErrorType.REQUIRED;
        }
        if (stackingFactor === undefined || String(stackingFactor) === '') {
            validationResult.packagingMaterial!.stackingFactor = FieldErrorType.REQUIRED;
        }
    }
    return validationResult;
}

export const validateForm = ({
    layersPerHandlingUnit,
    contentPerLayer,
    packagingMaterial,
}: HomogeneousPackagingTemplateFormValues): HomogeneousPackagingTemplateFormErrors => {
    const validationResult: HomogeneousPackagingTemplateFormErrors = {
        packagingMaterial: {},
    };
    if (layersPerHandlingUnit === undefined || layersPerHandlingUnit <= 0) {
        validationResult.layersPerHandlingUnit = FieldErrorType.NON_POSITIVE_NUMBER;
    }
    if (contentPerLayer === undefined || contentPerLayer <= 0) {
        validationResult.contentPerLayer = FieldErrorType.NON_POSITIVE_NUMBER;
    }

    if (packagingMaterial?.boxCode === undefined) {
        validationResult.packagingMaterial!.boxCode = FieldErrorType.REQUIRED;
    }
    const packagingFormErrors = validatePackagingMaterial(packagingMaterial, validationResult);

    return { ...validationResult, ...packagingFormErrors };
};

export const mapHomogeneousPackagingTemplateFormValuesToHomogeneousPackagingStep = (
    values: Required<HomogeneousPackagingTemplateFormValues>,
    handlingUnitTypeToBePackaged: string,
    prerequisiteInnerPackagingStepId: string,
    layerStabilization: HomogeneousPackagingStepLayerStabilization,
    descriptionOfHandlingUnit?: string,
    handlingUnitDescriptionToBePackaged?: string,
): HomogeneousPackagingStepConfiguration => {
    const handlingUnit = {
        type: values.packagingMaterial.boxCode!,
        description: values.packagingMaterial.name,
        tareWeightInKg: values.packagingMaterial.tareWeightInKg!,
        ownership: values.packagingMaterial.ownership!,
        isReusable: values.packagingMaterial.isReusable!,
        stackingFactor: values.packagingMaterial.stackingFactor!,
        widthInMm: values.packagingMaterial.outerWidthInMm,
        lengthInMm: values.packagingMaterial.outerLengthInMm,
        heightInMm: values.packagingMaterial.outerHeightInMm,
    };
    return {
        type: PackagingStepType.HOMOGENEOUS,
        typeOfHandlingUnit: values.packagingMaterial.boxCode!,
        descriptionOfHandlingUnit,
        prerequisites: [prerequisiteInnerPackagingStepId],
        handlingUnitTypeToBePackaged,
        handlingUnitDescriptionToBePackaged,
        numberOfHandlingUnitsPerLayer: values.contentPerLayer,
        numberOfLayersPerHandlingUnit: values.layersPerHandlingUnit,
        layerStabilization,
        handlingUnit,
    };
};

export const enhanceAuxiliaryHandlingGroupWithDescription = (
    auxiliaryHandlingGroup: AuxiliaryHandlingUnitGroup[],
    packagingMaterials: PackagingMaterialState['packagingMaterials'],
) => {
    return auxiliaryHandlingGroup.map((auxiliaryPackaging) => {
        return {
            ...auxiliaryPackaging,
            auxiliaryPackagingContent: {
                ...auxiliaryPackaging.auxiliaryPackagingContent,
                handlingUnitDescription: findPackagingMaterialResultByBoxCode(
                    auxiliaryPackaging.auxiliaryPackagingContent.type,
                    packagingMaterials,
                ),
            },
        };
    });
};

export const HomogeneousPackagingTemplateConfigurationContainer = () => {
    const dispatch = useAppDispatch();

    const packagingMaterials = useAppSelector(getAllPackagingMaterials);
    const selectedPackagingTemplate = useAppSelector(getSelectedPackagingTemplate);
    const requiredPackagingStep = selectedPackagingTemplate.steps[selectedPackagingTemplate.steps.length - 1];

    useEffect(() => {
        dispatch(deliverySchedulesSlice.actions.updateFormIdInPackagingWizard(formIds.form));
    }, [dispatch]);

    const initialValues: HomogeneousPackagingTemplateFormValues = {
        layersPerHandlingUnit: undefined,
        contentPerLayer: undefined,
        packagingMaterial: undefined,
    };

    const onSubmitHandler = (values: HomogeneousPackagingTemplateFormValues) => {
        const handlingUnitDescription = findPackagingMaterialResultByBoxCode(
            values.packagingMaterial?.boxCode,
            packagingMaterials,
        )?.packagingMaterial?.name;
        const layerStabilization: HomogeneousPackagingStepLayerStabilization = {
            required: true,
            handlingUnitType: requiredPackagingStep.typeOfHandlingUnit,
            handlingUnitDescription: requiredPackagingStep.descriptionOfHandlingUnit,
            auxiliaryPackaging: enhanceAuxiliaryHandlingGroupWithDescription(
                requiredPackagingStep.auxiliaryPackaging,
                packagingMaterials,
            ),
            isReusable: requiredPackagingStep.handlingUnit.isReusable,
            ownership: requiredPackagingStep.handlingUnit.ownership,
            stackingFactor: requiredPackagingStep.handlingUnit.stackingFactor,
            tareWeightInKg: requiredPackagingStep.handlingUnit.tareWeightInKg,
        };
        const homogeneousPackagingStep = mapHomogeneousPackagingTemplateFormValuesToHomogeneousPackagingStep(
            values as Required<HomogeneousPackagingTemplateFormValues>,
            requiredPackagingStep.typeOfHandlingUnit,
            requiredPackagingStep.id,
            layerStabilization,
            handlingUnitDescription,
            requiredPackagingStep.descriptionOfHandlingUnit,
        );
        dispatch(deliverySchedulesSlice.actions.updateTemplateStepConfiguration(homogeneousPackagingStep));
        dispatch(deliverySchedulesSlice.actions.showAuxiliaryPackagingStep());
    };

    const renderMainBody = () => {
        return (
            <Form<HomogeneousPackagingTemplateFormValues>
                onSubmit={onSubmitHandler}
                initialValues={initialValues}
                validate={validateForm}
                render={({ handleSubmit, values }) => {
                    const packagingMaterialResult = findPackagingMaterialResultByBoxCode(
                        values.packagingMaterial?.boxCode,
                        packagingMaterials,
                    );

                    return (
                        <div
                            className={
                                'display-flex flex-column align-items-center justify-content-center width-100pct'
                            }
                            data-testid={HOMOGENEOUS_PACKAGING_TEMPLATE_TEST_IDS.content}
                        >
                            <div>
                                <h5>
                                    <FormattedMessage id={'webedi.packaging.inner.createPackaging'} />
                                </h5>
                            </div>
                            <form id={formIds.form} onSubmit={handleSubmit} className={'width-100pct'}>
                                <BoxFinder
                                    id={formIds.packagingMaterial}
                                    name={formPropertyNames.packagingMaterial}
                                    showLinkToVWMaterialHelp={true}
                                />
                                <hr />
                                <div className={'display-flex flex-row align-content-start text-center'}>
                                    <div className={'width-50pct flex-column padding-10'}>
                                        <PackagingConfigurationNumberInput
                                            {...{
                                                labelDescription: (
                                                    <FormattedMessage
                                                        id={
                                                            'webedi.packaging.layerConfiguration.layersPerHandlingUnit.label'
                                                        }
                                                    />
                                                ),
                                                inputFormId: formIds.layersPerHandlingUnit,
                                                fieldName: formPropertyNames.layersPerHandlingUnit,
                                                fieldDescription:
                                                    'webedi.packaging.layerConfiguration.layersPerHandlingUnit.description',
                                                fieldDescriptionValues: {
                                                    handlingUnit: packagingMaterialResult?.packagingMaterial?.name,
                                                },
                                                showDescription:
                                                    packagingMaterialResult?.packagingMaterial?.name !== undefined,
                                                iconClass: 'rioglyph rioglyph-layer',
                                            }}
                                        />
                                    </div>
                                    <h3 className={'display-flex align-items-center margin-25 text-color-light'}>
                                        &times;
                                    </h3>
                                    <div className={'width-50pct padding-10'}>
                                        <PackagingConfigurationNumberInput
                                            {...{
                                                labelDescription: (
                                                    <FormattedMessage
                                                        id={'webedi.packaging.layerConfiguration.contentPerLayer.label'}
                                                    />
                                                ),
                                                inputFormId: formIds.contentPerLayer,
                                                fieldName: formPropertyNames.contentPerLayer,
                                                fieldDescription:
                                                    'webedi.packaging.layerConfiguration.contentPerLayer.description',
                                                fieldDescriptionValues: {
                                                    content: requiredPackagingStep.typeOfHandlingUnit,
                                                },
                                                showDescription:
                                                    packagingMaterialResult?.packagingMaterial?.name !== undefined,
                                                iconClass: 'rioglyph rioglyph-parcel',
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    );
                }}
            />
        );
    };

    return (
        <SplitView
            left={{
                content: (
                    <PackagingTemplateContentInformation
                        templateStepConfiguration={requiredPackagingStep}
                        displayHeadline={false}
                    />
                ),
                renderAsColumn: true,
            }}
            main={{
                content: renderMainBody(),
            }}
        />
    );
};
