// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { PartyIdentificationDetailsCodec } from './shared/partyIdentificationDetails.types';
import { fromEnum } from './util';

export const FilterValueCodec = t.exact(
    t.type({
        key: t.string,
        values: t.array(t.string),
    }),
);

export const FilterValuesCodec = t.exact(
    t.type({
        items: t.array(FilterValueCodec),
    }),
);

export enum ApiReferenceCodeQualifier {
    DUNS = 'DUNS',
}

const ReferenceCodeQualifierCodec = fromEnum<ApiReferenceCodeQualifier>(
    'ApiReferenceCodeQualifier',
    ApiReferenceCodeQualifier,
);

const AdditionalPartyIdentifierCodec = t.type({
    reference_identifier: t.string,
    reference_code_qualifier: ReferenceCodeQualifierCodec,
});

export const PartyCodec = t.intersection([
    t.type({
        party_identification_details: PartyIdentificationDetailsCodec,
    }),
    t.partial({
        additional_party_identifier: AdditionalPartyIdentifierCodec,
        party_name: t.string,
        street: t.string,
        city_name: t.string,
        country_sub_entity_name_code: t.string,
        postal_identification_code: t.string,
        country_name_code: t.string,
    }),
]);

export enum ApiPartner {
    VW = 'VW',
    PORSCHE = 'PORSCHE',
    MAN = 'MAN',
    POWERCO = 'POWERCO',
}

export const PartnerCodec = fromEnum<ApiPartner>('ApiPartner', ApiPartner);

export type ApiFilterValue = t.TypeOf<typeof FilterValueCodec>;
export type ApiFilterValues = t.TypeOf<typeof FilterValuesCodec>;
export type ApiAdditionalPartyIdentifier = t.TypeOf<typeof AdditionalPartyIdentifierCodec>;
