import { Field } from 'react-final-form';
import { Tooltip } from '../Tooltip';

export const VALIDATION_ERROR_ICON_TEST_ID = 'VALIDATION_ERROR_ICON_TEST';

export const FormErrorIcon = (errorProps: { name: string }) => {
    return (
        <Field name={errorProps.name} subscription={{ error: true }}>
            {({ meta: { error } }) =>
                error ? (
                    <Tooltip text={error} placement={'top'}>
                        <div>
                            <span
                                data-testid={VALIDATION_ERROR_ICON_TEST_ID}
                                className={'rioglyph rioglyph-error-sign text-color-danger text-size-18'}
                            />
                        </div>
                    </Tooltip>
                ) : null
            }
        </Field>
    );
};
