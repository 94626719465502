import { ReactNode } from 'react';

export const MORE_DATA_INDICATOR = 'MORE_DATA_INDICATOR_TEST_ID';
export const MORE_DATA_INDICATOR_NUMBERS = 'MORE_DATA_INDICATOR_NUMBERS_TEST_ID';

export const MoreDataIndicator = (props: {
    shown: number;
    total: number;
    messageOnMore: ReactNode;
}) => {
    const { shown, total, messageOnMore } = props;
    const pctProgress = total === 0 ? 100 : (100 * shown) / total;

    return (
        <div
            className={'LoadMoreButton display-flex justify-content-center margin-y-25 non-printable'}
            data-testid={MORE_DATA_INDICATOR}
        >
            <div className={'min-width-150 max-width-250 text-center'}>
                <div data-testid={MORE_DATA_INDICATOR_NUMBERS} className={'margin-bottom-3 text-medium'}>
                    <span>{shown}</span>
                    <span className={'margin-x-5 text-normal'}>/</span>
                    <span>{total}</span>
                </div>
                <div className={'progress bg-white height-2 margin-bottom-10'}>
                    <div className={'progress-bar'} style={{ width: `${pctProgress.toFixed(2)}%` }} />
                </div>
                {total > shown && messageOnMore && <>{messageOnMore}</>}
            </div>
        </div>
    );
};
