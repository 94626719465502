import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getAndStoreManagedFreightForwarders } from '../../actions/masterData/MasterData.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { masterDataSlice } from '../../reducers/masterData/MasterData.reducer';
import { ManagedFreightForwarder } from '../../reducers/transportation/types';
import {
    getManagedFreightForwarders,
    getSelectedManagedFreightForwarderId,
    isLoadingMasterData,
} from '../../selectors/masterData/MasterData.selector';
import { LoadingIndicator } from '../common/LoadingIndicator';

export const MANAGED_FREIGHT_FORWARDER_TABLE_TEST_ID = 'MANAGED_FREIGHT_FORWARDER_TABLE_TEST_ID';
const tableClassNames = [
    'table',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-layout-fixed',
    'table-head-filled',
    'text-left',
    'cursor-pointer',
    'margin-bottom-20',
].join(' ');

export const ManagedFreightForwarderTable = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    useEffect(() => {
        if (dunsNumber) {
            dispatch(getAndStoreManagedFreightForwarders(dunsNumber));
        }
    }, [dispatch, dunsNumber]);

    const isLoading = useAppSelector(isLoadingMasterData);
    const selectedManagedFreightForwarderId = useAppSelector(getSelectedManagedFreightForwarderId);
    const managedFreightForwarders = useAppSelector(getManagedFreightForwarders);

    const renderRow = (managedFreightForwarder: ManagedFreightForwarder) => {
        const onClickHandler = () => {
            dispatch(masterDataSlice.actions.setSelectedManagedFreightForwarder(managedFreightForwarder.id));
        };
        const isSelected = managedFreightForwarder.id === selectedManagedFreightForwarderId;

        return (
            <tr className={isSelected ? 'active' : undefined} onClick={onClickHandler} key={managedFreightForwarder.id}>
                <td> {managedFreightForwarder.name.join(' ')} </td>
                <td> {managedFreightForwarder.address.streetAddress.join(' ')} </td>
                <td> {managedFreightForwarder.address.city} </td>
                <td> {managedFreightForwarder.address.postalCode} </td>
                <td> {managedFreightForwarder.address.regionCode} </td>
                <td> {managedFreightForwarder.address.countryCode} </td>
                <td> {managedFreightForwarder.partyIdentifierBuyer} </td>
                <td> {managedFreightForwarder.partyIdentifierSeller} </td>
                <td> {managedFreightForwarder.dunsNumber} </td>
            </tr>
        );
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (managedFreightForwarders.length === 0) {
        return <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />;
    }

    return (
        <table className={tableClassNames} data-testid={MANAGED_FREIGHT_FORWARDER_TABLE_TEST_ID}>
            <thead>
                <tr>
                    <th className={'ellipsis-1'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.name'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.address'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1 width-10pct'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.city'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1 width-10pct'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.postalCode'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1 width-10pct'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.regionCode'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1 width-10pct'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.countryCode'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.partyIdentifierBuyer'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1'}>
                        <span>
                            <FormattedMessage id={'webedi.carrier.partyIdentifierSeller'} />{' '}
                        </span>
                    </th>
                    <th className={'ellipsis-1 width-10pct'}>
                        <span>
                            <FormattedMessage id={'webedi.dunsNumber'} />{' '}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>{managedFreightForwarders.map(renderRow)}</tbody>
        </table>
    );
};
