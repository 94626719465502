import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { measurementUnitCodeToTranslation } from '../../../../utils';

import { MeasurementUnitCode } from '../../domain/common.types';

export const MeasurementUnitCodeDisplay = (props: { unit: MeasurementUnitCode }) => {
    return (
        <FormattedMessage
            id={measurementUnitCodeToTranslation(props.unit)}
            values={{
                sup: (msg: ReactNode) => <sup>{msg}</sup>,
            }}
        />
    );
};
