import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/lib';
import { hasMlTypeProcessIndicator, roundOffFloatingPointErrors } from '../../../../../utils';
import {
    CumulativeQuantityDetails,
    DeliveryInstructionType,
    DeliverySchedule,
} from '../../../reducers/deliverySchedules/types';
import { ArticleNumber } from '../../common/ArticleNumber';
import { renderDetailAttributeTextCard } from '../../common/DetailAttributeCard';
import { ItemShortDescription } from '../../common/ItemShortDescription';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { Tooltip } from '../../common/Tooltip';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';
import { FormattedManufacturingCompany } from '../FormattedManufacturingCompany';

export const DELIVERY_SCHEDULES_DETAILS_HEADER_TEST_ID = 'DELIVERY_SCHEDULES_DETAILS_HEADER_TEST_ID';
export const EDIT_CUMULATIVE_QUANTITY_SENT_BUTTON_TEST_ID = 'EDIT_CUMULATIVE_QUANTITY_SENT_BUTTON_TEST_ID';
export const EDIT_INVALID_CUMULATIVE_QUANTITY_SENT_CONTAINER_TEST_ID =
    'EDIT_INVALID_CUMULATIVE_QUANTITY_SENT_CONTAINER_TEST_ID';
export const EDIT_INVALID_CUMULATIVE_QUANTITY_SENT_TOOLTIP_TEST_ID =
    'EDIT_INVALID_CUMULATIVE_QUANTITY_SENT_TOOLTIP_TEST_ID';

const renderInvalidCumulativeQuantitySent = (
    deliverySchedule: DeliverySchedule,
    setShowCumulativeOffsetTable: (value: boolean) => void,
) => (
    <div data-testid={EDIT_INVALID_CUMULATIVE_QUANTITY_SENT_CONTAINER_TEST_ID}>
        {renderCumulativeQuantitySentField(deliverySchedule, setShowCumulativeOffsetTable)}
        <Tooltip
            text={
                <FormattedMessage
                    id={'webedi.deliverySchedule.invalidCumulativeQuantity.label'}
                    values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
                />
            }
            placement={'bottom'}
        >
            <button
                className={'btn btn-primary btn-link padding-0 text-size-16 align-items-baseline'}
                data-testid={EDIT_INVALID_CUMULATIVE_QUANTITY_SENT_TOOLTIP_TEST_ID}
                type={'button'}
            >
                <div>
                    <span className={'rioglyph rioglyph-exclamation-sign text-size-18 text-warning'} />
                </div>
            </button>
        </Tooltip>
    </div>
);

const renderCumulativeQuantitySentField = (
    deliverySchedule: DeliverySchedule,
    setShowCumulativeOffsetTable: (value: boolean) => void,
) => (
    <Tooltip
        text={
            <FormattedMessage
                id={'webedi.deliverySchedule.cumulativeQuantitySent.tooltip'}
                values={{ b: (chunks: ReactNode) => <b>{chunks}</b> }}
            />
        }
        placement={'bottom'}
    >
        <button
            className={'btn btn-primary btn-link padding-0 text-size-16 align-items-baseline'}
            data-testid={EDIT_CUMULATIVE_QUANTITY_SENT_BUTTON_TEST_ID}
            onClick={() => setShowCumulativeOffsetTable(true)}
            type={'button'}
        >
            {deliverySchedule.cumulativeQuantitySent ?? '-'}
            &nbsp;
            <span className={'icon rioglyph rioglyph-pencil'} />
        </button>
    </Tooltip>
);

export const renderCumulativeQuantityReceived = (
    cumulativeQuantityReceived: CumulativeQuantityDetails | undefined,
    intl: IntlShape,
) => {
    const { quantity, date } = { ...cumulativeQuantityReceived };
    return (
        <div>
            {cumulativeQuantityReceived?.quantity ? quantity : ' - '}
            {date ? (
                <Tooltip
                    data-testid={'lastInventoryDate'}
                    text={`${intl.formatMessage({
                        id: 'webedi.deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived.dateOfLastInventory',
                    })} : ${date}`}
                    placement={'right'}
                    extraClasses={'white-space-pre-line'}
                    textAlignment={'left'}
                >
                    <span
                        data-testid={'lastInventoryData'}
                        className={'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5'}
                    />
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    );
};

export const renderDeliveryCallOffDateAndNumber = (
    deliverySchedule: DeliverySchedule,
    deliveryInstructionType: DeliveryInstructionType,
) => {
    const deliveryInstructionReference = deliverySchedule.deliveryInstructionReferences.find(
        (item) => item.type === deliveryInstructionType,
    );
    if (deliveryInstructionReference?.deliveryInstructionNumber) {
        const referenceDate = deliveryInstructionReference.deliveryInstructionNumber.referenceDate;
        const referenceNumber = deliveryInstructionReference.deliveryInstructionNumber.referenceIdentifier;
        if (!referenceDate) {
            return <span>- / {referenceNumber}</span>;
        } else {
            return (
                <span>
                    <FormattedDateOrDateTime date={referenceDate} /> / {referenceNumber}
                </span>
            );
        }
    } else {
        return <span>- / -</span>;
    }
};

export const deliveryScheduleContainsDailyCallOf = (deliverySchedule: DeliverySchedule) => {
    const deliveryInstructionReference = deliverySchedule.deliveryInstructionReferences.find(
        (item) => item.type === DeliveryInstructionType.FAB,
    );
    const referenceDate = deliveryInstructionReference?.deliveryInstructionNumber?.referenceDate;
    return referenceDate !== undefined;
};

export const DeliveryScheduleDetailsHeaderSection = ({
    deliverySchedule,
    setShowCumulativeOffsetTable,
}: {
    deliverySchedule: DeliverySchedule;
    setShowCumulativeOffsetTable: (value: boolean) => void;
}) => {
    const intl = useIntl();

    return (
        <div className={'padding-15 padding-top-20'} data-testid={DELIVERY_SCHEDULES_DETAILS_HEADER_TEST_ID}>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.common.manufacturingCompany'} />,
                    <FormattedManufacturingCompany manufacturingCompany={deliverySchedule.buyer} />,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.plantIdentifier'} />,
                    deliverySchedule.shipTo.shipTo.partyIdentificationDetails.identifier,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.placeOfDelivery'} />,
                    deliverySchedule.shipTo.placeOfDelivery.locationNameCode,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.shipFrom.partyIdentifier'} />,
                    deliverySchedule.shipFrom.partyIdentificationDetails.identifier,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.orderNumber'} />,
                    deliverySchedule.scheduledArticleDetails.orderNumber.referenceIdentifier,
                    '10',
                )}
            </div>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.articleNumber.buyer'} />,

                    <ArticleNumber
                        articleNumber={
                            deliverySchedule.scheduledArticleDetails.lineItem.lineItemIdentifier
                                .itemNumberIdentification.itemIdentifier
                        }
                    />,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.articleNumber.seller'} />,
                    <ArticleNumber articleNumber={deliverySchedule.articleMasterData?.articleNumberSeller} />,
                    '10',
                )}
                <Tooltip
                    width={200}
                    text={deliverySchedule.scheduledArticleDetails.lineItem.itemShortDescriptions[0]?.itemDescription}
                    placement={'bottom'}
                >
                    {renderDetailAttributeTextCard(
                        <FormattedMessage id={'webedi.itemShortDescription'} />,
                        deliverySchedule.scheduledArticleDetails.lineItem.itemShortDescriptions && (
                            <ItemShortDescription
                                itemShortDescriptions={
                                    deliverySchedule.scheduledArticleDetails.lineItem.itemShortDescriptions
                                }
                            />
                        ),
                        '10',
                    )}
                </Tooltip>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.deliveryCallOffDateAndNumber'} />,
                    renderDeliveryCallOffDateAndNumber(deliverySchedule, DeliveryInstructionType.LAB),
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.scheduledArticleDetails.quantityUnit'} />,
                    <MeasurementUnitCodeDisplay unit={deliverySchedule.scheduledArticleDetails.measurementUnitCode} />,
                    '10',
                )}
            </div>
            <div className={'display-flex flex-row'}>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantitySent'} />,
                    !deliverySchedule.hasValidCumulativeQuantitySent
                        ? renderInvalidCumulativeQuantitySent(deliverySchedule, setShowCumulativeOffsetTable)
                        : renderCumulativeQuantitySentField(deliverySchedule, setShowCumulativeOffsetTable),
                    '10',
                )}
                {hasMlTypeProcessIndicator(deliverySchedule)
                    ? renderDetailAttributeTextCard(
                          <FormattedMessage
                              id={'webedi.deliverySchedule.scheduledArticleDetails.cumulativeQuantityOrdered'}
                          />,
                          deliverySchedule.scheduledArticleDetails.cumulativeQuantityOrdered?.quantity,
                          '10',
                      )
                    : renderDetailAttributeTextCard(
                          <FormattedMessage
                              id={'webedi.deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived'}
                          />,
                          renderCumulativeQuantityReceived(
                              deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived,
                              intl,
                          ),
                          '10',
                      )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantityInTransit'} />,
                    Math.max(
                        roundOffFloatingPointErrors(
                            deliverySchedule.cumulativeQuantitySent -
                                (deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived?.quantity ?? 0),
                        ),
                        0,
                    ),
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantityInPreparation'} />,
                    deliverySchedule.cumulativeQuantityInPreparation,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.scheduledArticleDetails.workflowType.label'} />,
                    <b>
                        <FormattedMessage
                            id={'webedi.deliverySchedule.scheduledArticleDetails.workflowType.shipmentProposal'}
                        />
                    </b>,
                    '10',
                )}
            </div>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {deliveryScheduleContainsDailyCallOf(deliverySchedule) ? (
                    renderDetailAttributeTextCard(
                        <FormattedMessage id={'webedi.deliverySchedule.dailyCallOffDateAndNumber'} />,
                        renderDeliveryCallOffDateAndNumber(deliverySchedule, DeliveryInstructionType.FAB),
                        '10',
                    )
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
