import { config } from '../../../../../config';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { getUserSelectedLocale } from '../../../selectors/lang/Lang.selector';

export const ClosingDaysView = () => {
    const dunsNumber = useAppSelector(useDunsNumberFromPath);
    const userSelectedLocale = useAppSelector(getUserSelectedLocale);

    let url = `${config.closingDaysUrl}?duns=${dunsNumber}`;
    if (userSelectedLocale !== undefined) {
        url += `&locale=${userSelectedLocale}`;
    }

    return (
        <iframe src={url} height={'100%'} width={'100%'} style={{ border: '0px' }} data-testid={'ClosingDaysIframe'} />
    );
};
