import { useSelector } from 'react-redux';
import { getIdToken } from '../../../../../../configuration';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { isRioIdToken } from '../../../../../../configuration/tokenHandling/IdToken';
import { useGetTransportOrderIntentQuery } from '../../../../api/transportOrderIntent/transportOrderIntentsApi';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import { Shipment } from '../../../../reducers/shipments/types';
import { getSelectedShipment } from '../../../../selectors/shipments/Shipments.selector';
import { logTransportOrderIntentInfo } from './LogTransportOrderIntentInfo';

const useGetTransportOrderIntentQueryHook = (dunsNumber: string | undefined, shipment: Shipment | undefined) => {
    return useGetTransportOrderIntentQuery(
        {
            dunsNumber: dunsNumber ? dunsNumber : 'isNeverUsedAsCallIsSkippedIfUndefined',
            shipmentId: shipment ? shipment.id : 'isNeverUsedAsCallIsSkippedIfUndefined',
        },
        { skip: dunsNumber === undefined || shipment === undefined, refetchOnMountOrArgChange: true },
    );
};

export const TransportOrderIntentConsoleLog = () => {
    const dunsNumber = useDunsNumberFromPath();
    const shipment = useSelector(getSelectedShipment);

    const { data: transportOrderIntent } = useGetTransportOrderIntentQueryHook(dunsNumber, shipment);

    const idToken = useAppSelector(getIdToken);
    const isTestTenant = idToken && isRioIdToken(idToken) && idToken?.tenant === 'rio-eu.test';

    if (isTestTenant && transportOrderIntent !== undefined) {
        logTransportOrderIntentInfo(transportOrderIntent);
    }

    return null;
};
