import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { DispatchProposal, ManualDispatchProposalDraft } from '../../domain/dispatchProposal.types';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import {
    getAddArticleSidebarOpen,
    getSelectedDispatchProposal,
} from '../../selectors/dispatchProposals/DispatchProposals.selector';
import {
    ArticleSuggestionsForDispatchProposal,
    ArticleSuggestionsForDispatchProposalDraft,
} from './ArticleSuggestionsForDispatchProposal';

export const ArticleSuggestionsSidebar = () => {
    const isOpen = useAppSelector(getAddArticleSidebarOpen);
    const proposalOrDraft = useAppSelector(getSelectedDispatchProposal);
    const dispatch = useAppDispatch();

    const onCloseSidebar = () => {
        dispatch(dispatchProposalsSlice.actions.stopAddArticleToDispatchProposalWorkflow());
    };

    return (
        isOpen && (
            <Sidebar
                className={'margin-30'}
                title={<FormattedMessage id={'webedi.dispatchProposals.articleSuggestionSidebar.header'} />}
                position={'left'}
                titleClassName={'padding-left-10'}
                width={650}
                closed={!isOpen}
                onClose={onCloseSidebar}
                footer={
                    <button className={'btn btn-primary'} type={'button'} onClick={onCloseSidebar}>
                        <FormattedMessage id={'webedi.common.close'} />
                    </button>
                }
                minWidth={560}
                maxWidth={800}
                resizable={true}
            >
                <div className={'border-style-solid border-color-light border-width-1 border-top-only'}>
                    {proposalOrDraft &&
                        (isManualDraft(proposalOrDraft) ? (
                            <ArticleSuggestionsForDispatchProposalDraft draft={proposalOrDraft} />
                        ) : (
                            <ArticleSuggestionsForDispatchProposal dispatchProposal={proposalOrDraft} />
                        ))}
                </div>
            </Sidebar>
        )
    );
};

const isManualDraft = (
    proposalOrDraft: DispatchProposal | ManualDispatchProposalDraft,
): proposalOrDraft is ManualDispatchProposalDraft => {
    return (proposalOrDraft as ManualDispatchProposalDraft).transportConcept !== undefined;
};
