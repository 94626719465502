import lodash, { isEmpty, round } from 'lodash';
import hash from 'object-hash';
import { RefObject } from 'react';
import { TranslationKeys } from './configuration/lang/types';
import { toMillis } from './dateUtils';
import {
    maxValueEditableNumberField,
    minValueEditableNumberField,
} from './features/app/components/shipments/shipmentEditView/ShipmentEditViewFormConfig';
import { MeasurementUnitCode } from './features/app/domain/common.types';
import { DispatchProposal } from './features/app/domain/dispatchProposal.types';

import { DeliverySchedule, ProcessIndicator, ReceivedQuantity } from './features/app/reducers/deliverySchedules/types';
import {
    HandlingUnit,
    HandlingUnitGroup,
    HandlingUnitGroupUpdate,
    PackagingConfig,
} from './features/app/reducers/shipments/packaging.types';
import {
    Address,
    DeliveryNote,
    Dimensions,
    GroupOfIdenticalPackagingHierarchy,
    LoadItem,
    Shipment,
} from './features/app/reducers/shipments/types';

export const DATA_TEST_ID_PROPERTY_NAME = 'data-testid';

export const getOrBlank = (value: string | undefined): string => {
    return value || '';
};

export const neverReachedFor = (arg: never): never => {
    throw Error(`Should never be called. Was called with ${arg}`);
};

export const snakeToCamel = (str: string) =>
    str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const getTranslationKeyForProcessIndicator = (processIndicator: ProcessIndicator) => {
    switch (processIndicator) {
        case ProcessIndicator.FAB_ED:
        case ProcessIndicator.FAB_ML:
        case ProcessIndicator.PAG_FAB:
            return 'webedi.schedulingData.processIndicator.dailyCallOff'; // FAB
        case ProcessIndicator.LAB_ED:
        case ProcessIndicator.PAG_LAB:
        case ProcessIndicator.MAN_LAB:
            return 'webedi.schedulingData.processIndicator.deliveryCallOff'; // LAB
        case ProcessIndicator.LAB_ML:
            return 'webedi.schedulingData.processIndicator.grossDemands';
        default:
            return neverReachedFor(processIndicator);
    }
};

const getSortedQuantitiesWithDates = (receivedQuantities: ReceivedQuantity[]) =>
    receivedQuantities
        .filter((e) => e.receiptDate)
        .sort(
            (entry1: ReceivedQuantity, entry2: ReceivedQuantity) =>
                toMillis(entry2.receiptDate!) - toMillis(entry1.receiptDate!),
        );

const getQuantitiesWithoutDates = (receivedQuantities: ReceivedQuantity[]) =>
    receivedQuantities.filter((e) => !e.receiptDate);

export const returnChronologicallySortedQuantities = (receivedQuantities: ReceivedQuantity[]) =>
    getSortedQuantitiesWithDates(receivedQuantities).concat(getQuantitiesWithoutDates(receivedQuantities));

export const hasMlTypeProcessIndicator = (deliverySchedule: DeliverySchedule) => {
    const schedulingData = deliverySchedule.scheduledArticleDetails.schedulingData;
    return (
        schedulingData.length > 0 &&
        [ProcessIndicator.LAB_ML, ProcessIndicator.FAB_ML].includes(schedulingData[0].processIndicator)
    );
};

export const formatAddress = (address?: Address) => {
    if (!address) {
        return '';
    }
    const { street, postalIdentificationCode, cityName } = address;
    return `${street}, ${postalIdentificationCode} ${cityName}`;
};

export const FRACTIONAL_DIGITS = 1;
export const formatWeight = (locale: string, weight: number | undefined): string => {
    if (weight === undefined) {
        return '';
    }

    return `${weight.toLocaleString(locale, {
        minimumFractionDigits: FRACTIONAL_DIGITS,
        maximumFractionDigits: FRACTIONAL_DIGITS,
    })} kg`;
};

export const formatVolume = (locale: string, volume: number | undefined): string => {
    if (volume === undefined) {
        return '';
    }

    return `${volume.toLocaleString(locale, {
        minimumFractionDigits: FRACTIONAL_DIGITS,
        maximumFractionDigits: FRACTIONAL_DIGITS,
    })} m³`;
};

// Recursively iterate through an object and remove all empty strings, arrays, objects, or undefineds.
export const sanitize = (object: any): any => {
    if (lodash.isString(object)) {
        return _sanitizeString(object);
    }
    if (lodash.isArray(object)) {
        return _sanitizeArray(object);
    }
    if (lodash.isPlainObject(object)) {
        return _sanitizeObject(object);
    }
    return object;
};

const _sanitizeString = (string: string) => (lodash.isEmpty(string) ? null : string);

const _sanitizeArray = (array: any[]) => lodash.filter(lodash.map(array, sanitize), _isProvided);

const _sanitizeObject = (object: object) => lodash.pickBy(lodash.mapValues(object, sanitize), _isProvided);

const _isProvided = (value: any) => {
    const typeIsNotSupported =
        !lodash.isNull(value) &&
        !lodash.isString(value) &&
        !lodash.isArray(value) &&
        !lodash.isPlainObject(value) &&
        !lodash.isUndefined(value);
    return typeIsNotSupported || !lodash.isEmpty(value);
};

export const isStringAlphaNumeric = (testString: string) => /^[a-zA-Z0-9]+$/.test(testString);

export const isStringNumeric = (testString: string) => /^[0-9]+$/.test(testString);

export const isStringUnoCCompliant = (testString: string) => /^[\u0020-\u007E\u00A0-\u00FF]*$/.test(testString);

export interface LoadItemPositionReference {
    deliveryNoteNumber: number;
    position: number;
}

export const loadItemIdToDeliveryNoteNumberAndPositionMap = (
    deliveryNotes: DeliveryNote[],
): Map<string, LoadItemPositionReference> => {
    const map: Map<string, LoadItemPositionReference> = new Map();
    deliveryNotes.forEach((deliveryNote) => {
        deliveryNote.loadItems.forEach((loadItem, index) =>
            map.set(loadItem.id, {
                deliveryNoteNumber: deliveryNote.deliveryNoteNumber,
                position: index + 1,
            }),
        );
    });
    return map;
};

export const toPackagingOuterDimensionsFromGiphys = (
    giphys: GroupOfIdenticalPackagingHierarchy[],
): PackagingConfig['packagingOuterDimensions'] => {
    const assignDimensions = (handlingUnitId: string, giphy: GroupOfIdenticalPackagingHierarchy) => {
        packagingOuterDimensions[handlingUnitId] = giphy.dimensions;
    };

    const packagingOuterDimensions: { [handlingUnitId: HandlingUnit['id']]: Dimensions } = {};
    giphys.forEach((giphy) => {
        giphy.handlingUnitIds.forEach((handlingUnitId) => assignDimensions(handlingUnitId, giphy));
    });
    return packagingOuterDimensions;
};

export const toPackagingOuterDimensionsFromHandlingUnitGroupUpdates = (
    hugUpdates: HandlingUnitGroupUpdate[],
): PackagingConfig['packagingOuterDimensions'] => {
    const packagingOuterDimensions: { [handlingUnitId: HandlingUnit['id']]: Dimensions } = {};
    hugUpdates.forEach((hugUpdate) => {
        if (Object.keys(hugUpdate.handlingUnit.dimensions).length > 0) {
            packagingOuterDimensions[hugUpdate.handlingUnit.id] = hugUpdate.handlingUnit.dimensions;
        }
    });
    return packagingOuterDimensions;
};

export const hashPackagingTree = (handlingUnitGroup: HandlingUnitGroup): string => {
    return hash(handlingUnitGroup, { excludeKeys: (key: string) => key === 'labelNumber' || key === 'id' });
};

export const hashOfHandlingUnit = (handlingUnit: HandlingUnit): string =>
    hashPackagingTree({
        quantity: 1,
        handlingUnit,
    });

export const zeroPadNumber = (number: number | string, padDigits: number): string => {
    const numberString = typeof number === 'string' ? number : number.toString(10);
    return numberString.padStart(padDigits, '0');
};

export const zeroPad = (zeroPadDigits: number | undefined, inputRef: RefObject<HTMLInputElement>) => {
    if (zeroPadDigits && inputRef?.current?.value) {
        inputRef.current.value = zeroPadNumber(inputRef.current.value, zeroPadDigits);
    }
};

export const unPad = (zeroPadDigits: number | undefined, inputRef: RefObject<HTMLInputElement>) => {
    if (zeroPadDigits && inputRef?.current?.value) {
        inputRef.current.value = parseInt(inputRef.current.value, 10).toString();
    }
};

export const roundOffFloatingPointErrors = (value: number): number => round(value, 10);

export const normalizeToMaxLength = (fieldValue: string, maxValue: number = maxValueEditableNumberField) => {
    const parsedFieldValue = parseInt(fieldValue, 10);
    if (parsedFieldValue <= minValueEditableNumberField) {
        return minValueEditableNumberField;
    } else if (parsedFieldValue >= maxValue) {
        return maxValue;
    }
    return parsedFieldValue;
};

export const wouldChangesToShipmentInvalidatePackaging = (
    modifiedShipment: Shipment,
    existingShipment: Shipment,
): boolean => {
    const areLoadItemsEqual = (loadItem1: LoadItem, loadItem2: LoadItem) =>
        loadItem1.id === loadItem2.id && loadItem1.amount.value === loadItem2.amount.value;
    const allExistingLoadItems = existingShipment.load.flatMap((deliveryNote) => deliveryNote.loadItems);
    const allCurrentLoadItems = modifiedShipment.load.flatMap((deliveryNote) => deliveryNote.loadItems);

    return (
        !isEmpty(existingShipment.packaging) &&
        !allExistingLoadItems.every((loadItem) =>
            allCurrentLoadItems.find((currentLoadItem: LoadItem) => areLoadItemsEqual(loadItem, currentLoadItem)),
        )
    );
};

export const measurementUnitCodeToTranslation = (unit: MeasurementUnitCode): TranslationKeys => {
    switch (unit) {
        case MeasurementUnitCode.DEGREE_CELSIUS:
            return `webedi.deliveryInstruction.unit.degreeCelsius`;
        case MeasurementUnitCode.CENTIMETRE:
            return `webedi.deliveryInstruction.unit.centimetre`;
        case MeasurementUnitCode.CUBIC_DECIMETRE:
            return `webedi.deliveryInstruction.unit.cubicDecimetre`;
        case MeasurementUnitCode.DECIMETRE:
            return `webedi.deliveryInstruction.unit.decimetre`;
        case MeasurementUnitCode.DRUM:
            return `webedi.deliveryInstruction.unit.drum`;
        case MeasurementUnitCode.GRAM:
            return `webedi.deliveryInstruction.unit.gram`;
        case MeasurementUnitCode.KILOGRAM:
            return `webedi.deliveryInstruction.unit.kilogram`;
        case MeasurementUnitCode.KILOMETRE:
            return `webedi.deliveryInstruction.unit.kilometre`;
        case MeasurementUnitCode.LEAF:
            return `webedi.deliveryInstruction.unit.leaf`;
        case MeasurementUnitCode.LITRE:
            return `webedi.deliveryInstruction.unit.litre`;
        case MeasurementUnitCode.MILLILITRE:
            return `webedi.deliveryInstruction.unit.millilitre`;
        case MeasurementUnitCode.MILLIMETRE:
            return `webedi.deliveryInstruction.unit.millimetre`;
        case MeasurementUnitCode.SQUARE_METRE:
            return `webedi.deliveryInstruction.unit.squareMetre`;
        case MeasurementUnitCode.CUBIC_METRE:
            return `webedi.deliveryInstruction.unit.cubicMetre`;
        case MeasurementUnitCode.METRE:
            return `webedi.deliveryInstruction.unit.metre`;
        case MeasurementUnitCode.PIECE:
            return `webedi.deliveryInstruction.unit.piece`;
        case MeasurementUnitCode.PAIR:
            return `webedi.deliveryInstruction.unit.pair`;
        case MeasurementUnitCode.ROLL:
            return `webedi.deliveryInstruction.unit.roll`;
        case MeasurementUnitCode.SET:
            return `webedi.deliveryInstruction.unit.set`;
        case MeasurementUnitCode.METRIC_TON:
            return `webedi.deliveryInstruction.unit.metricTon`;
        default:
            return neverReachedFor(unit);
    }
};

export const normalizeDecimalsToContainOnlyOneDigit = (value: number) => {
    if (!Number.isInteger(value)) {
        const decimalParts = value.toString().split('.');

        const normalizedDecimal = decimalParts[1] ? decimalParts[1].substring(0, 1) : 0;
        return parseFloat(`${decimalParts[0]}.${normalizedDecimal}}`);
    } else {
        return value;
    }
};

export const calculateNumberOfOuterHandlingUnits = (shipment: Shipment) => {
    return shipment.packaging.reduce((prev, curr) => prev + curr.quantity, 0);
};

export const containsUnpackagedItems = (dispatchProposal: DispatchProposal) =>
    dispatchProposal.items.some((item) => item.type === 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM');
