import { Dispatch } from '../../../../configuration/setup/store';
import { fetchCompanyInfoFromApi } from '../../api/userInfo/userInfoCalls';
import { companyInfoSlice } from '../../reducers/ui/CompanyInfo.reducer';

export const getCompanyInfo = (duns: string) => async (dispatch: Dispatch) => {
    dispatch(companyInfoSlice.actions.setCompanyName(undefined));
    await fetchCompanyInfoFromApi(duns)
        .then((companyInfo) => {
            dispatch(companyInfoSlice.actions.setCompanyName(companyInfo));
        })
        .catch(() => {
            // Do nothing, as a failure to fetch company info is not critical.
        });
};
