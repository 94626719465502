import { FormErrors } from '../common/form/types';

export type ManagedFreightForwarderFormValues = {
    id?: string;
    dunsNumberOwner?: string;
    dunsNumber: string;
    partyIdentifierBuyer: string;
    partyIdentifierSeller: string;
    name: string;
    name2: string;
    address: string;
    address2: string;
    postalCode: string;
    city: string;
    regionCode: string;
    countryCode: string;
};

export enum ManagedFreightForwarderFormFieldError {
    AT_LEAST_ONE_PARTY_IDENTIFIER = 'webedi.carrier.form.error.atLeastOnePartyIdentifier',
    NAME_ABSENT = 'webedi.carrier.form.error.nameAbsent',
    ADDRESS_ABSENT = 'webedi.carrier.form.error.addressAbsent',
    POSTAL_CODE_ABSENT = 'webedi.carrier.form.error.postalCodeAbsent',
    CITY_ABSENT = 'webedi.carrier.form.error.cityAbsent',
    COUNTRY_CODE_ABSENT = 'webedi.carrier.form.error.countryCodeAbsent',
    DUNS_NUMBER_ABSENT = 'webedi.carrier.form.error.dunsNumberAbsent',
}

export type ManagedFreightForwarderFormErrors = FormErrors<
    Omit<ManagedFreightForwarderFormValues, 'id' | 'dunsNumberOwner'>,
    ManagedFreightForwarderFormFieldError
>;
