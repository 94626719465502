import { RequestCondition } from '../../reducers/deliverySchedules/types';

export const getClassnameForRequestCondition = (requestCondition: RequestCondition): string => {
    switch (requestCondition) {
        case RequestCondition.BACKORDER:
            return 'label label-default label-condensed';
        case RequestCondition.IMMEDIATE:
            return 'label label-primary label-condensed';
        default:
            return '';
    }
};

export const getTranslationKeyForRequestCondition = (requestCondition: RequestCondition) => {
    switch (requestCondition) {
        case RequestCondition.BACKORDER:
            return 'webedi.schedulingData.requestCondition.BACKORDER';
        case RequestCondition.IMMEDIATE:
            return 'webedi.schedulingData.requestCondition.IMMEDIATE';
        default:
            return null;
    }
};
