import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getRouteForShipment } from '../../../actions/shipments/Shipments.actions';
import { TransportOrderShipment } from '../../../domain/transportOrder.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { ShipmentExportedLabel } from '../../shipments/shipmentReadView/ShipmentReadView';
import {
    TransportOrderSidebarAddressElement,
    TransportOrderSidebarContentElement,
    TransportOrderSidebarElement,
    TransportOrderSidebarPanel,
    TransportOrderSidebarWeightAndVolumeElement,
} from '../common/TransportOrderSidebarTab';

export const SHIPMENT_SIDEBAR_VIEW_TEST_ID = 'SHIPMENT_SIDEBAR_VIEW';
export const ShipmentSidebarView = (props: { shipment: TransportOrderShipment }) => {
    const { shipment } = props;
    const dunsNumber = useDunsNumberFromPath();

    return (
        <div data-testid={SHIPMENT_SIDEBAR_VIEW_TEST_ID}>
            <TransportOrderSidebarPanel title={'webedi.transportOrders.overview.sidebar.shipment.title'} open={true}>
                <TransportOrderSidebarElement translationId={'webedi.transportOrders.sidebar.label.shipmentNumber'}>
                    <TransportOrderShipmentLink dunsNumber={dunsNumber} shipment={shipment} />
                </TransportOrderSidebarElement>
                <TransportOrderSidebarAddressElement
                    title={'webedi.transportOrders.overview.sidebar.label.addressPlaceOfLoading'}
                    shippingParty={shipment.loading?.shipper}
                    loadingLocationId={shipment.loading?.loadingPoint}
                    loadingLocationTitle={'webedi.transportOrders.overview.sidebar.label.loadingPoint'}
                />
                <TransportOrderSidebarAddressElement
                    title={'webedi.transportOrders.overview.sidebar.label.addressPlaceOfUnloading'}
                    shippingParty={shipment.unloading?.recipient}
                    loadingLocationId={shipment.unloading?.placeOfDischarge}
                    loadingLocationTitle={'webedi.transportOrders.overview.table.header.placeOfDischarge'}
                />
                <TransportOrderSidebarContentElement
                    title={'webedi.transportOrders.overview.sidebar.label.shipment.content'}
                    content={shipment.content}
                />
                <TransportOrderSidebarWeightAndVolumeElement
                    title={'webedi.transportOrders.overview.sidebar.label.weightAndVolume'}
                    totalWeightInKg={shipment.content?.totalWeightInKg}
                    totalVolumeInCubicMetres={shipment.content?.totalVolumeInCubicMetres}
                />
            </TransportOrderSidebarPanel>
        </div>
    );
};
export const TransportOrderShipmentLink = (props: {
    dunsNumber: string | undefined;
    shipment: TransportOrderShipment;
}) => {
    const { dunsNumber, shipment } = props;
    return (
        <div className='display-flex justify-content-between align-items-center'>
            <span>
                <span className='rioglyph rioglyph-order text-size-18 padding-right-3' />
                {shipment.shipmentNumber}
                {shipment.exportedAt && <ShipmentExportedLabel />}
            </span>
            <Link to={getRouteForShipment(dunsNumber, shipment)} target={'_blank'} rel='noreferrer'>
                <FormattedMessage id={'webedi.transportOrders.sidebar.label.shipment.link'} />
                <span className={'rioglyph rioglyph-new-window padding-left-3'} />
            </Link>
        </div>
    );
};
