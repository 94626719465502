import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { BoxFinderV2 } from '../../packaging/BoxFinderV2';

import { useFormContext } from 'react-hook-form';
import { useImage } from '../../../hooks/useImage';
import { PACKAGING_MATERIAL_SELECTION_EXPLANATION_TEST_ID } from '../../shipments/packaging/dialogs/Packaging/OuterPackagingPackagingMaterialSelection';
import { PackageTogetherFormValues } from './PackageTogetherDialog';

const Header = () => {
    const { watch } = useFormContext<PackageTogetherFormValues>();
    const selectedItemsToPackage = watch('selectedItemsToPackage');
    const selectedItemsBoxCodes = new Set(
        selectedItemsToPackage.map(({ dispatchProposalItem: { outerPackaging } }) => outerPackaging.type),
    ).values();

    return (
        <div className={'display-flex flex-column'}>
            <div className={'text-size-18 text-center margin-bottom-25'}>
                <FormattedMessage id={'webedi.packaging.innerPackaging.step1.title'} />
            </div>
            <div className={'counter color-highlight'}>
                <div data-count={2}>
                    <span data-testid={PACKAGING_MATERIAL_SELECTION_EXPLANATION_TEST_ID}>
                        <FormattedMessage
                            id={'webedi.packaging.outerPackaging.packagingMaterialSelection.explanation'}
                            values={{
                                innerPackagingMaterialBoxCodes: [...selectedItemsBoxCodes].join(', '),
                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                            }}
                        />
                        {':'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const PackageTogetherStep2 = () => {
    const packagingMaterialSelectionImage = useImage('packagingMaterialSelection');
    return (
        <div
            className={
                'display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct ' +
                'margin-left-20pct margin-right-20pct min-height-500'
            }
        >
            <Header />
            <div
                className={
                    'border-style-solid border-color-light border-width-1 padding-left-5pct padding-right-5pct padding-20'
                }
            >
                <div className={'text-center margin-bottom-20'}>
                    <img src={packagingMaterialSelectionImage} className={'width-70pct'} alt={'packaging_step_2'} />
                </div>
                <BoxFinderV2
                    fieldName={'packagingMaterial'}
                    getPackagingMaterialError={(errors) => errors.packagingMaterial}
                    showLinkToVWMaterialHelp={true}
                    boxFinderInputProps={{
                        disabled: false,
                        outerClassName: '',
                        packagingMaterialInputPrefix: '',
                    }}
                />
            </div>
        </div>
    );
};
