import { State } from '../../../../configuration/setup/store';
import { CourierOrder } from '../../domain/courierOrderIntent.types';
import { CourierOrderValidationWithId } from '../../reducers/courierOrderIntent/CourierOrderIntentReducer';

export const getIsDialogOpen = (state: State): boolean => state.webEdi.courierOrderIntent.isDialogOpen;

export const getSelectedDispatchProposalId = (state: State): string | undefined =>
    state.webEdi.courierOrderIntent.selectedDispatchProposalId;

export const getCourierOrder = (state: State): CourierOrder | undefined => state.webEdi.courierOrderIntent.courierOrder;

export const getOrderValidationErrors = (state: State): CourierOrderValidationWithId[] =>
    state.webEdi.courierOrderIntent.orderValidationErrors;
