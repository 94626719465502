// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { PartnerCodec, PartyCodec } from '../common.types';

const supplierDataCodec = t.type({
    seller: PartyCodec,
    ship_from: PartyCodec,
    buyer: PartyCodec,
    partner: PartnerCodec,
});

export type ApiSupplierData = t.TypeOf<typeof supplierDataCodec>;

export const getSellerDataResponseCodec = t.type({
    items: t.array(supplierDataCodec),
});
