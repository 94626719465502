import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';

const FALLBACK_LOCALE = 'en-GB';

const supportedLocaleMap = {
    de: 'de-DE',
    'de-DE': 'de-DE',
    en: 'en-GB',
    'en-GB': 'en-GB',
    es: 'es-ES',
    'es-ES': 'es-ES',
    it: 'it-IT',
    'it-IT': 'it-IT',
};

const extractLanguage = flow(defaultTo(FALLBACK_LOCALE), split('-'), head);

const FALLBACK_LANG = extractLanguage(FALLBACK_LOCALE);

const getSupportedLocaleOrUndefined = (locale) => supportedLocaleMap[locale];

export { FALLBACK_LANG, FALLBACK_LOCALE, extractLanguage, supportedLocaleMap, getSupportedLocaleOrUndefined };
