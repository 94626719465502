// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

const courierOrdersParcel = t.type({
    tracking_id: t.string,
    tracking_url: t.string,
});

export const courierOrdersCodec = t.type({
    items: t.array(
        t.type({
            shipment_id: t.string,
            printable_label: t.string,
            parcels: t.array(courierOrdersParcel),
        }),
    ),
});

export type ApiCourierOrders = t.TypeOf<typeof courierOrdersCodec>;
export type ApiCourierOrderParcel = t.TypeOf<typeof courierOrdersParcel>;
