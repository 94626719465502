export interface EdiFileSummaryOld {
    id: string;
    filename: string;
}

export type EdiFileSummary = VDA4984EdiFileSummary | VDA4987EdiFileSummary;
export type ReferencedDeliveryInstruction = {
    readonly id: string;
    readonly deliveryInstructionNumber?: string;
    readonly deliveryInstructionDate?: string;
};

export type ReferencedDeliveryInstructions = {
    [key: string]: ReferencedDeliveryInstruction;
};

export interface VDA4984EdiFileSummary {
    readonly id: string;
    readonly storedAt: string;
    readonly dunsNumber: string;
    readonly filename: string;
    readonly fileSize: number;
    readonly referencedDeliveryInstructions: ReferencedDeliveryInstructions;
    readonly referenceType: ReferenceType.DELIVERY_INSTRUCTION;
    readonly deliveryInstructionType: DeliveryInstructionType;
}

export type ReferencedShipments = {
    [key: string]: { id: string };
};

export interface VDA4987EdiFileSummary {
    readonly id: string;
    readonly storedAt: string;
    readonly dunsNumber: string;
    readonly filename: string;
    readonly fileSize: number;
    readonly referencedShipments: ReferencedShipments;
    readonly referenceType: ReferenceType.SHIPMENT;
}

export enum ReferenceType {
    DELIVERY_INSTRUCTION = 'DELIVERY_INSTRUCTION',
    SHIPMENT = 'SHIPMENT',
}

export enum DeliveryInstructionType {
    FAB = 'FAB',
    LAB = 'LAB',
}
