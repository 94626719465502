import { LoadingLocation } from '../../reducers/loadingLocations/types';
import { ApiLoadingLocation, ApiLoadingLocations } from './loadingLocationData.types';

export const mapLoadingLocations = (apiLoadingLocations: ApiLoadingLocations): LoadingLocation[] =>
    apiLoadingLocations.items.map(mapLoadingLocation);

export const mapLoadingLocation = (apiLoadingLocation: ApiLoadingLocation): LoadingLocation => ({
    id: apiLoadingLocation.id,
    dunsNumberOwner: apiLoadingLocation.duns_number_owner,
    companyName: apiLoadingLocation.company_name,
    name: apiLoadingLocation.name,
    loadingPoint: apiLoadingLocation.loading_point,
    street: apiLoadingLocation.street,
    postalCode: apiLoadingLocation.postal_code,
    city: apiLoadingLocation.city,
    country: apiLoadingLocation.country,
    comment: apiLoadingLocation.comment,
    etag: apiLoadingLocation.etag,
});

export const mapLoadingLocationToApi = (loadingLocation: LoadingLocation): ApiLoadingLocation => ({
    id: loadingLocation.id,
    duns_number_owner: loadingLocation.dunsNumberOwner,
    name: loadingLocation.name,
    loading_point: loadingLocation.loadingPoint,
    company_name: loadingLocation.companyName,
    street: loadingLocation.street,
    postal_code: loadingLocation.postalCode,
    city: loadingLocation.city,
    country: loadingLocation.country,
    comment: loadingLocation.comment,
});
