import { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../../../configuration/lang/types';
import { MeasurementUnitCode } from '../../../domain/common.types';
import { NumberInput, getNumberInputParseFunction, isDecimalBasedMeasurementUnit } from '../form/NumberInput';

export const PACKAGING_CONFIG_NUMBER_INPUT_TEST_IDS = {
    content: 'PACKAGING_CONFIGURATION_NUMBER_INPUT_VALUE_TEST_ID',
    description: 'PACKAGING_CONFIGURATION_NUMBER_INPUT_DESCRIPTION_TEST_ID',
};

export interface ConfigurationNumberInputProps {
    labelDescription: ReactNode;
    inputFormId: string;
    fieldName: string;
    fieldDescription?: TranslationKeys;
    fieldDisabled?: boolean;
    fieldDescriptionValues?: { [key: string]: string | undefined };
    measurementUnitCode?: MeasurementUnitCode;
    showDescription: boolean;
    iconClass?: string;
    innerPackaging?: boolean;
    templateDialog?: boolean;
}

export const PackagingConfigurationNumberInput = (props: ConfigurationNumberInputProps) => {
    const {
        inputFormId,
        fieldName,
        labelDescription,
        fieldDescription,
        fieldDisabled,
        fieldDescriptionValues,
        measurementUnitCode,
        showDescription,
        iconClass,
    } = props;

    return (
        <div className={`display-flex flex-column align-items-start`}>
            <label className={'text-size-14 text-color-darkest'} htmlFor={inputFormId}>
                {labelDescription}
            </label>
            <div
                data-testid={PACKAGING_CONFIG_NUMBER_INPUT_TEST_IDS.content}
                className={'display-flex flex-row align-items-center justify-content-center margin-y-10'}
            >
                <span className={`${iconClass} text-size-20 padding-right-10`} />
                <Field
                    id={inputFormId}
                    name={fieldName}
                    component={NumberInput}
                    className={'form-control'}
                    measurementUnitCode={measurementUnitCode}
                    parse={getNumberInputParseFunction(measurementUnitCode)}
                    forbidScientificNotation
                    min={isDecimalBasedMeasurementUnit(measurementUnitCode) ? 0 : 1}
                    errorMessageClassName={'text-size-10'}
                    disabled={fieldDisabled === true}
                />
            </div>
            <div className={'text-size-12 text-color-gray'}>
                {showDescription && (
                    <span data-testid={PACKAGING_CONFIG_NUMBER_INPUT_TEST_IDS.description}>
                        <FormattedMessage
                            id={fieldDescription}
                            values={{
                                b: (chunk: ReactNode) => <b>{chunk}</b>,
                                ...fieldDescriptionValues,
                            }}
                        />
                    </span>
                )}
            </div>
        </div>
    );
};
