import { isRight } from 'fp-ts/lib/Either';
// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { ApiErrorCode } from '../reducers/ui/UI.reducer';

const formatIoTsErrors = (errors: t.Errors): string => {
    return errors.map((error) => error.context.map(({ key }) => key).join('.')).join('\n');
};

export const decodeJson = <T>(decoder: t.Decoder<unknown, T>) => {
    return (json: unknown): T => {
        const validationResult = decoder.decode(json);

        if (isRight(validationResult)) {
            return validationResult.right;
        }
        throw new Error(`Error during parsing response: ${formatIoTsErrors(validationResult.left)}`);
    };
};

export const okOrReject = (response: Response): Promise<void> => {
    if (response.ok) {
        return Promise.resolve();
    }
    return reject(response);
};

export const jsonOrReject = (response: Response): Promise<unknown> => {
    if (response.ok) {
        return response
            .json()
            .catch((error) => Promise.reject(new Error(`${response.status} Invalid payload ${error.message}`)));
    }
    return reject(response);
};

export const blobOrReject = (response: Response): Promise<Blob> => {
    if (response.ok) {
        return response
            .blob()
            .catch((error) => Promise.reject(new Error(`${response.status} Invalid payload ${error.message}`)));
    }
    return reject(response);
};

const reject = (response: Response): Promise<never> => {
    if (response.status === 403) {
        window.location.href = '/forbidden';
    }
    return response
        .json()
        .catch((error) => {
            throw new ApiError(
                `An Error occurred during request but response body could not be parsed: "${error}"`,
                response,
            );
        })
        .then((json: ProblemResponse) => {
            if (
                response.status === 503 &&
                (json.detail ?? '').includes('The service is currently undergoing maintenance')
            ) {
                window.location.href = '/maintenance';
            }
            throw new ProblemError(json, response);
        });
};

export class ApiError extends Error {
    readonly statusCode: number;
    readonly url: string;

    constructor(message: string, response: Response) {
        super(message);
        this.name = 'ApiError';
        this.statusCode = response.status;
        this.url = response.url;
    }
}

export class ProblemError extends ApiError {
    readonly errorCode: ApiErrorCode | undefined;

    constructor(problem: ProblemResponse, response: Response) {
        super(`${problem.title} (status: ${problem.status}): ${buildErrorDetail(problem)}`, response);
        this.name = 'ProblemError';
        this.errorCode = problem.errorCode as ApiErrorCode;
    }
}

const buildErrorDetail = (problem: ProblemResponse) => {
    if (problem.detail !== undefined) {
        return problem.detail;
    } else if (problem.violations !== undefined && problem.violations.length > 0) {
        return extractMessageFromConstraintValidation(problem);
    }

    return '';
};

export interface RTKQueryErrorResponse {
    data: ProblemResponse;
    status: number;
}

// see https://opensource.zalando.com/problem/schema.yaml
export interface ProblemResponse {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    errorCode?: string;
    violations?: ZalandoViolation[];
}

interface ZalandoViolation {
    field: string;
    message: string;
}

// This is needed because when the user sends blank field for example,
// zalando library in the backend will catch a ConstraintValidationException,
// which causes that the response structure is different from usual...
// See https://opensource.zalando.com/problem/constraint-violation
const extractMessageFromConstraintValidation = (constraintError: ProblemResponse) => {
    const errors = [] as string[];
    constraintError.violations?.forEach((violation) => {
        errors.push(violation.message);
    });
    return errors.join(',');
};
