import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { roundOffFloatingPointErrors } from '../../../../../../../utils';
import { MeasurementUnitCode } from '../../../../../domain/common.types';
import { MeasurementUnitCodeDisplay } from '../../../../common/MeasurementUnitCodeDisplay';
import { INNER_PACKAGING_TEST_IDS } from './InnerPackaging';

interface UnpackagedArticlesProps {
    numberOfArticlesToPack: number;
    measurementUnitCode: MeasurementUnitCode;
    numberOfPackagedArticles: number;
}

export const UNPACKAGED_ARTICLES_AMOUNT_TEST_ID = 'UNPACKAGED_ARTICLES_AMOUNT_TEST_ID';

export const UnpackagedArticles = (props: UnpackagedArticlesProps) => {
    const measurementUnitCodeTranslation = <MeasurementUnitCodeDisplay unit={props.measurementUnitCode} />;

    return (
        <div className={'text-center'}>
            <span data-testid={UNPACKAGED_ARTICLES_AMOUNT_TEST_ID}>
                <FormattedMessage
                    id={'webedi.packaging.inner.unpackagedArticles'}
                    values={{
                        measurementUnitCode: measurementUnitCodeTranslation,
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
                <div className={'text-size-20 padding-20'}>
                    <span className={'rioglyph rioglyph-cards-grid'} />
                    &nbsp;
                    <span data-testid={INNER_PACKAGING_TEST_IDS.numberOfUnpackagedArticles}>
                        {roundOffFloatingPointErrors(props.numberOfArticlesToPack - props.numberOfPackagedArticles)}
                    </span>
                </div>
            </span>
        </div>
    );
};
