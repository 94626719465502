import { LoadItemPositionReference } from '../../../../utils';
import { HandlingUnitCategory } from '../../components/common/PackagingCommon';
import { Quantity } from '../../domain/common.types';
import { AuxiliaryHandlingUnitGroup } from '../auxiliaryPackaging.types';
import { PackagingTemplate } from '../deliverySchedules/types';
import { ValidPackagingMaterial } from '../packaging/types';
import { Dimensions } from './types';

export interface PackagedArticleGroup {
    loadItemId: string;
    quantity: number;
    netWeightInKg?: number;
}

export type Packageable = HandlingUnitGroup | PackagedArticleGroup;

export interface PackagedArticleGroupUpdate {
    loadItemId: string;
    quantity: number;
}

export type PackageableUpdate = HandlingUnitGroupUpdate | PackagedArticleGroupUpdate;

export const instanceOfHandlingUnitGroup = (object: any): object is HandlingUnitGroup => {
    return (object as HandlingUnitGroup).handlingUnit !== undefined;
};

export const instanceOfHandlingUnitGroupUpdate = (object: any): object is HandlingUnitGroupUpdate => {
    return (object as HandlingUnitGroupUpdate).handlingUnit !== undefined;
};

export interface HandlingUnit {
    id: string;
    type: string;
    description?: string;
    category: HandlingUnitCategory;
    labelNumber: number;
    contents: Packageable[];
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
    tareWeightInKg: number;
    netWeightInKg?: number;
    grossWeightInKg?: number;
}

export interface HandlingUnitUpdate {
    id: string;
    type: string;
    description?: string;
    category: HandlingUnitCategory;
    labelNumber: number;
    contents: PackageableUpdate[];
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    isReusable: boolean;
    ownership: Ownership;
    dimensions: Dimensions;
    stackingFactor: number;
    tareWeightInKg: number;
}

export interface HandlingUnitGroup {
    quantity: number;
    handlingUnit: HandlingUnit;
}

export interface HandlingUnitGroupUpdate {
    quantity: number;
    handlingUnit: HandlingUnitUpdate;
}

export interface OuterPackagingConfig {
    handlingUnitType: string;
    numberOfHandlingUnits: number;
    contentPerHandlingUnit: {
        hashOfContainedHandlingUnitGroup: string;
        quantity: number;
    }[];
}

export enum Ownership {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
}

export enum PackagingMaterialType {
    DISPOSABLE = 'DISPOSABLE',
    REUSABLE = 'REUSABLE',
}

export interface PackagedArticleInfo {
    articleNumberBuyer: string;
    amount: Quantity;
    loadItemPositionReference: LoadItemPositionReference;
}

export interface GroupedHandlingUnits {
    type: string;
    category: HandlingUnitCategory;
    quantity: number;
    description?: string;
    packagedArticlesInfo: PackagedArticleInfo[];
    hash: string;
    handlingUnit: HandlingUnitPackagingMaterial;
}

export enum UpdatePackagingConfigurationType {
    INNER_PACKAGING = 'INNER_PACKAGING',
    REGULAR_PACKAGING = 'REGULAR PACKAGING',
    HOMOGENEOUS_ADDITIONAL_PACKAGING = 'HOMOGENEOUS_ADDITIONAL_PACKAGING',
    HETEROGENEOUS_ADDITIONAL_PACKAGING = 'HETEROGENEOUS_ADDITIONAL_PACKAGING',
}

export interface ContentPerHandlingUnitGroupEntry {
    hashOfContainedHandlingUnitGroup: string;
    handlingUnitType: string;
    handlingUnitDescription?: string;
    quantity: number;
    handlingUnit: HandlingUnitPackagingMaterial;
}

export type UpdateInnerPackagingConfigurationStepConfiguration = {
    type: UpdatePackagingConfigurationType.INNER_PACKAGING;
    handlingUnitGroups: HandlingUnitGroup[];
};

export type UpdateInnerPackagingPackagingMaterialSelectionStepConfiguration = {
    type: UpdatePackagingConfigurationType.INNER_PACKAGING;
    packagingMaterial: ValidPackagingMaterial;
};

export type UpdateOuterPackagingPackagingMaterialSelectionStepConfiguration = {
    type: UpdatePackagingConfigurationType.REGULAR_PACKAGING;
    packagingMaterial: ValidPackagingMaterial;
};

export type UpdateHomogeneousAdditionalPackagingConfiguration = {
    type: UpdatePackagingConfigurationType.HOMOGENEOUS_ADDITIONAL_PACKAGING;
    numberOfHandlingUnitsPerLayer: number;
    handlingUnitType: string;
    handlingUnitDescription?: string;
    handlingUnitCategory: HandlingUnitCategory;
    numberOfHandlingUnits: number;
    numberOfPackagedHandlingUnits: number;
    contentPerHandlingUnit: ContentPerHandlingUnitGroupEntry;
    handlingUnit: HandlingUnitPackagingMaterial;
};

export type HandlingUnitPackagingMaterial = {
    type: string;
    description?: string;
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
    tareWeightInKg: number;
    widthInMm?: number;
    lengthInMm?: number;
    heightInMm?: number;
};

export type UpdateHeterogeneousAdditionalPackagingConfiguration = {
    type: UpdatePackagingConfigurationType.HETEROGENEOUS_ADDITIONAL_PACKAGING;
    handlingUnitType: string;
    handlingUnitDescription?: string;
    handlingUnitCategory: HandlingUnitCategory;
    numberOfHandlingUnits: number;
    contentPerHandlingUnit: ContentPerHandlingUnitGroupEntry[];
    handlingUnit: HandlingUnitPackagingMaterial;
};

export type UpdatePackagingConfiguration = UpdateInnerPackagingConfiguration | UpdateRegularPackagingConfiguration;

export type UpdateInnerPackagingConfiguration =
    | UpdateInnerPackagingConfigurationStepConfiguration
    | UpdateInnerPackagingPackagingMaterialSelectionStepConfiguration;
export type UpdateRegularPackagingConfiguration =
    | UpdateHomogeneousAdditionalPackagingConfiguration
    | UpdateHeterogeneousAdditionalPackagingConfiguration
    | UpdateOuterPackagingPackagingMaterialSelectionStepConfiguration;
export type UpdateHomogeneousAdditionalPackagingConfigurationPayload = Omit<
    UpdateHomogeneousAdditionalPackagingConfiguration,
    'type' | 'handlingUnitCategory'
>;
export type UpdateHeterogeneousAdditionalPackagingConfigurationPayload = Omit<
    UpdateHeterogeneousAdditionalPackagingConfiguration,
    'type' | 'handlingUnitCategory'
>;

export type UpdateLabelNumberConfigurationPayload = {
    id: string;
    shipmentId: string;
    labelNumber: number;
};

export enum PackagingType {
    INNER = 'INNER',
    REGULAR = 'REGULAR',
}

export enum PackagingStep {
    GROUPING_SELECTION = 'GROUPING_SELECTION',
    PACKAGING_MATERIAL_SELECTION = 'PACKAGING_MATERIAL_SELECTION',
    CONFIGURATION = 'CONFIGURATION',
    AUXILIARY_CONFIGURATION = 'AUXILIARY_CONFIGURATION',
}

export interface PackagingWizard {
    formId?: string;
    type: PackagingType;
    step?: PackagingStep;
}

export interface PackagingState {
    config: PackagingConfigDictionary;
    idOfLoadItemToPackage?: string;
    selectedGroupedHandlingUnits: GroupedHandlingUnits[];
    updatePackagingConfiguration?: UpdatePackagingConfiguration;
    packagingWizard?: PackagingWizard;
    templates: PackagingTemplatesDictionary;
    nextAvailableLabelNumber?: number;
}

export type PackagingTemplatesDictionary = { [key: string]: PackagingTemplateForShipment[] };

export type PackagingTemplateForShipment = Pick<PackagingTemplate, 'id' | 'name' | 'type'>;

export interface PackagingCompositeId {
    deliveryNoteNumber: number;
    deliveryNotePosition: number;
}

export type HandlingUnitGroupsDictionary = { [hash: string]: HandlingUnitGroup[] | undefined };

export type PackagingConfig = {
    packaging: HandlingUnitGroup[];
    packagingOuterDimensions?: { [handlingUnitId: HandlingUnit['id']]: Dimensions };
    hasUpdated: boolean;
};

export interface PackagingConfigDictionary {
    [index: string]: PackagingConfig | undefined;
}

export interface HandlingUnitType {
    id: string;
    typeDescription: string;
}

export interface ApplicablePackagingTemplateCodec {
    id: string;
}

export interface PackagingTemplatesForShipment {
    items: { [key: string]: ApplicablePackagingTemplateCodec[] };
}

export interface LoadItemIdentifier {
    deliveryNoteNumber: number;
    deliveryNotePosition: number;
}

export interface PackagingTemplateConfigToApply {
    identifier: LoadItemIdentifier;
    quantity: number;
    templateId: string;
}

export interface PackagingTemplateConfigsToApply {
    items: PackagingTemplateConfigToApply[];
}

export const isPackagedArticleGroup = (packageable: Packageable): packageable is PackagedArticleGroup =>
    (packageable as PackagedArticleGroup).loadItemId !== undefined;

export const isPackagedArticleGroupUpdate = (
    packageable: PackageableUpdate,
): packageable is PackagedArticleGroupUpdate => (packageable as PackagedArticleGroupUpdate).loadItemId !== undefined;
