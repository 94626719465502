import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { isLocalDate } from '../../../../dateUtils';
import { loadItemIdToDeliveryNoteNumberAndPositionMap, neverReachedFor } from '../../../../utils';
import { Quantity } from '../../domain/common.types';
import {
    MeansOfTransport,
    MeansOfTransportMode,
    MeansOfTransportWithoutConfirmationData,
} from '../../domain/meansOfTransport.types';
import { ShipTo } from '../../reducers/deliverySchedules/types';
import { Customs, CustomsValue, PreferenceAuthorization } from '../../reducers/shipments/customs.types';
import { PackagingConfig } from '../../reducers/shipments/packaging.types';
import {
    AdditionalProductId,
    Address,
    ArticleSuggestion,
    DeliveryNote,
    DeliveryNoteNumberData,
    ExtendedArticleMasterData,
    GroupOfIdenticalPackagingHierarchy,
    LabelNumberData,
    LoadItem,
    PlaceOfDischarge,
    Shipment,
    ShipmentCreationLoadItem,
    ShipmentCreationOrigin,
    ShipmentCreationRequest,
    ShipmentUpdateRequest,
    ShipmentsQueryFor,
    ShipmentsQueryResult,
    TermsOfDelivery,
} from '../../reducers/shipments/types';
import { FreightForwarder, ManagedFreightForwarder } from '../../reducers/transportation/types';
import { mapPartner, mapParty } from '../commonMapper';
import { mapItemShortDescription } from '../deliverySchedule/deliveryScheduleMapper';
import { ApiAddress } from '../shared/address.types';
import { mapFreightForwarderFromTransportOrder, mapManagedFreightForwarder } from '../shared/freightForwarderMapper';
import { ApiMeansOfTransport, ApiUpdateMeansOfTransport, MeansOfTransportType } from '../shared/meansOfTransport.types';
import { ApiPlaceOfDischarge } from '../shared/placeOfDischarge.types';
import { ApiQuantity } from '../shared/quantity.types';
import {
    mapArticleMasterData,
    mapArticleMasterDataToApi,
    mapDimensions,
    mapExtendedArticleMasterData,
    mapToCarrier,
} from '../shared/sharedMappers';
import { ShipmentDateType } from '../shared/shipmentDate.types';
import { ApiFreightForwarder, ApiManagedFreightForwarder } from '../shared/transportation.types';
import { ApiCustoms, ApiCustomsValue, ApiPreferenceAuthorization } from './customs.types';
import { mapHandlingUnitGroup, mapToHandlingUnitGroupUpdateApi } from './packagingMapper';
import {
    ApiAdditionalProductId,
    ApiArticleSuggestion,
    ApiArticleSuggestions,
    ApiDeliveryNote,
    ApiDeliveryNoteNumberData,
    ApiExtendedArticleMasterDataList,
    ApiGroupOfIdenticalPackagingHierarchy,
    ApiLabelNumberData,
    ApiLoadItem,
    ApiShipment,
    ApiShipmentCreationLoadItem,
    ApiShipmentCreationOrigin,
    ApiShipmentCreationRequest,
    ApiShipmentUpdateRequest,
    ApiShipmentsQueryResult,
    ApiTermsOfDelivery,
} from './shipment.types';

export const mapShipmentsQueryResult = (apiShipmentsQueryResult: ApiShipmentsQueryResult): ShipmentsQueryResult => {
    const query = apiShipmentsQueryResult.query;
    return {
        shipments: apiShipmentsQueryResult.items.map((it) => mapShipment(it)),
        totalCountOfMatchedShipments: apiShipmentsQueryResult.total_count,
        query: {
            limit: query.limit,
            offset: query.offset,
            q: query.q,
            queryFor: query.query_for ?? ShipmentsQueryFor.SHIPMENT_NUMBER,
            plantNumber: query.plant_number,
            placeOfDischarge: query.place_of_discharge,
            exportedAtFrom: query.exported_at_from,
            exportedAtTo: query.exported_at_to,
        },
    };
};

const mapGroupOfIdenticalPackagingHierarchy = (
    apiGroupOfIdenticalPackagingHierarchy: ApiGroupOfIdenticalPackagingHierarchy,
): GroupOfIdenticalPackagingHierarchy => {
    return {
        id: apiGroupOfIdenticalPackagingHierarchy.id,
        dimensions: mapDimensions(apiGroupOfIdenticalPackagingHierarchy.dimensions),
        mergeable: apiGroupOfIdenticalPackagingHierarchy.mergeable,
        handlingUnitIds: apiGroupOfIdenticalPackagingHierarchy.handling_unit_ids,
    };
};
export const mapShipment = (apiShipment: ApiShipment): Shipment => {
    const load = mapLoad(apiShipment.load);

    return {
        id: apiShipment.id,
        partner: mapPartner(apiShipment.partner),
        buyer: mapParty(apiShipment.buyer),
        shipmentNumber: apiShipment.shipment_number,
        shipToId: apiShipment.ship_to_id,
        requestedDeliveryDate: apiShipment.requested_delivery_date
            ? apiShipment.requested_delivery_date.value
            : undefined,
        estimatedArrivalDate: apiShipment.estimated_arrival_date ? apiShipment.estimated_arrival_date.value : undefined,
        despatchDate: apiShipment.despatch_date ? apiShipment.despatch_date.value : undefined,
        dunsNumberOwner: apiShipment.duns_number_owner,
        placeOfDischarge: mapPlaceOfDischarge(apiShipment.place_of_discharge),
        load,
        exported: apiShipment.exported,
        exportedAt: apiShipment.exported_at,
        lastModifiedAt: apiShipment.last_modified_at,
        packaging: apiShipment.packaging.map((handlingUnitGroup) => mapHandlingUnitGroup(handlingUnitGroup, load)),
        groupOfIdenticalPackagingHierarchies: apiShipment.group_of_identical_packaging_hierarchies.map(
            mapGroupOfIdenticalPackagingHierarchy,
        ),
        termsOfDelivery: apiShipment.terms_of_delivery ? mapTermsOfDelivery(apiShipment.terms_of_delivery) : undefined,
        freightForwarder: apiShipment.carrier ? mapFreightForwarder(apiShipment.carrier) : undefined,
        carrier: apiShipment.executive_carrier ? mapToCarrier(apiShipment.executive_carrier) : undefined,
        customs: apiShipment.customs ? mapCustoms(apiShipment.customs) : undefined,
        meansOfTransport: apiShipment.means_of_transport
            ? mapMeansOfTransport(apiShipment.means_of_transport)
            : undefined,
        transportOrderNumber: apiShipment.transport_order_number,
        netWeightInKg: apiShipment.net_weight_in_kg,
        grossWeightInKg: apiShipment.gross_weight_in_kg,
        transportOrderReference: apiShipment.transport_order_reference,
        creationOrigin: mapShipmentCreationOrigin(apiShipment.creation_origin),
    };
};

const mapShipmentCreationOrigin = (apiShipmentCreationOrigin: ApiShipmentCreationOrigin): ShipmentCreationOrigin => {
    switch (apiShipmentCreationOrigin) {
        case 'DISPATCH_PROPOSAL':
            return ShipmentCreationOrigin.DISPATCH_PROPOSAL;
        case 'SHIPMENT_SUGGESTION':
            return ShipmentCreationOrigin.SHIPMENT_SUGGESTION;
        default:
            throw new Error(`Unhandled API ShipmentCreationOrigin value: ${apiShipmentCreationOrigin}`);
    }
};

const isManagedFreightForwarder = (
    apiFreightForwarder: ApiFreightForwarder,
): apiFreightForwarder is ApiManagedFreightForwarder =>
    (apiFreightForwarder as unknown as ManagedFreightForwarder)['id'] !== undefined;

const mapFreightForwarder = (apiFreightForwarder: ApiFreightForwarder): FreightForwarder => {
    if (isManagedFreightForwarder(apiFreightForwarder)) {
        return mapManagedFreightForwarder(apiFreightForwarder);
    } else {
        return mapFreightForwarderFromTransportOrder(apiFreightForwarder);
    }
};

const mapCustoms = (apiCustoms: ApiCustoms): Customs => ({
    value: apiCustoms.value ? mapCustomsValue(apiCustoms.value) : undefined,
    clearance: apiCustoms.clearance,
    preferenceAuthorization: apiCustoms.preference_authorization
        ? mapPreferenceAuthorization(apiCustoms.preference_authorization)
        : undefined,
});

const mapCustomsValue = (apiCustomsValue: ApiCustomsValue): CustomsValue => ({
    amount: apiCustomsValue.amount,
    currencyCode: apiCustomsValue.currency_code,
});

const mapMeansOfTransport = (apiMeansOfTransport: ApiMeansOfTransport): MeansOfTransport => {
    switch (apiMeansOfTransport.mode) {
        case MeansOfTransportType.MARITIME:
            return {
                mode: MeansOfTransportMode.MARITIME,
                shipName: apiMeansOfTransport.ship_name,
                containerNumber: apiMeansOfTransport.container_number,
            };
        case MeansOfTransportType.RAIL:
            return {
                mode: MeansOfTransportMode.RAIL,
                trainNumber: apiMeansOfTransport.train_number,
                railCarNumber: apiMeansOfTransport.rail_car_number,
            };
        case MeansOfTransportType.ROAD:
            return {
                mode: MeansOfTransportMode.ROAD,
                registrationNumber: apiMeansOfTransport.vehicle_registration_number,
                registrationNumberFromConfirmation: apiMeansOfTransport.vehicle_registration_number_from_confirmation,
                trailerRegistrationNumber: apiMeansOfTransport.vehicle_trailer_registration_number,
            };
        case MeansOfTransportType.AIR:
            return {
                mode: MeansOfTransportMode.AIR,
                flightNumber: apiMeansOfTransport.flight_number,
            };
        case MeansOfTransportType.MAIL:
            return {
                mode: MeansOfTransportMode.MAIL,
                parcelNumber: apiMeansOfTransport.postal_parcel_number,
            };
        case MeansOfTransportType.MULTIMODAL:
            return {
                mode: MeansOfTransportMode.MULTIMODAL,
                swapBodyRegistrationNumber: apiMeansOfTransport.vehicle_number_plate_interchangeable_chassis,
                swapBodyNumber: apiMeansOfTransport.swap_body_number,
            };
        default:
            return neverReachedFor(apiMeansOfTransport);
    }
};

const mapPreferenceAuthorization = (
    apiPreferenceAuthorization: ApiPreferenceAuthorization,
): PreferenceAuthorization => ({
    text: apiPreferenceAuthorization.text,
    language: apiPreferenceAuthorization.language,
});

const mapTermsOfDelivery = (apiTermsOfDelivery: ApiTermsOfDelivery): TermsOfDelivery => ({
    incotermsCode: apiTermsOfDelivery.incoterms_code,
    location: apiTermsOfDelivery.location,
});

const mapLoad = (apiDeliveryNotes: ApiDeliveryNote[]): DeliveryNote[] => {
    return apiDeliveryNotes.map(mapDeliveryNote);
};

const mapDeliveryNote = (apiDeliveryNote: ApiDeliveryNote): DeliveryNote => {
    return {
        deliveryNoteNumber: apiDeliveryNote.delivery_note_number,
        loadItems: apiDeliveryNote.load_items.map(mapLoadItem),
    };
};

const mapLoadItem = (apiLoadItem: ApiLoadItem): LoadItem => {
    return {
        id: uuid(),
        orderNumber: apiLoadItem.order_number,
        articleNumberBuyer: apiLoadItem.article_number_buyer,
        articleMasterData: apiLoadItem.article_master_data && mapArticleMasterData(apiLoadItem.article_master_data),
        amount: mapQuantity(apiLoadItem.amount),
        batchNumber: apiLoadItem.batch_number,
        isSubjectToPreferenceAuthorization: apiLoadItem.is_subject_to_preference_authorization,
        expiryDate: apiLoadItem.expiry_date,
        additionalProductId: apiLoadItem.additional_product_id
            ? mapAdditionalProductId(apiLoadItem.additional_product_id)
            : undefined,
        referencedDeliveryScheduleId: apiLoadItem.referenced_delivery_schedule_id,
    };
};

const mapAdditionalProductId = (apiAdditionalProductId: ApiAdditionalProductId): AdditionalProductId => ({
    softwareStatus: apiAdditionalProductId.software_status,
    hardwareStatus: apiAdditionalProductId.hardware_status,
    partsGenerationStatus: apiAdditionalProductId.parts_generation_status,
});

const mapQuantity = (apiQuantity: ApiQuantity): Quantity => {
    return {
        value: apiQuantity.value,
        measurementUnitCode: apiQuantity.measurement_unit_code,
    };
};

export const mapPlaceOfDischarge = (apiPlaceOfDischarge: ApiPlaceOfDischarge): PlaceOfDischarge => {
    return {
        id: apiPlaceOfDischarge.id,
        name: apiPlaceOfDischarge.name,
        address: apiPlaceOfDischarge.address ? mapAddress(apiPlaceOfDischarge.address) : undefined,
    };
};

export const mapShipToToPlaceOfDischarge = (shipTo: ShipTo): PlaceOfDischarge => {
    return {
        id: shipTo.placeOfDischarge.locationNameCode,
        name: shipTo.placeOfDischarge.locationName,
        address: {
            street: shipTo.shipTo.street,
            cityName: shipTo.shipTo.cityName,
            countrySubEntityNameCode: shipTo.shipTo.countrySubEntityNameCode,
            postalIdentificationCode: shipTo.shipTo.postalIdentificationCode,
            countryNameCode: shipTo.shipTo.countryNameCode,
        },
    };
};

export const mapAddress = (apiAddress: ApiAddress): Address => {
    return {
        street: apiAddress.street,
        cityName: apiAddress.city_name,
        countrySubEntityNameCode: apiAddress.country_sub_entity_name_code,
        postalIdentificationCode: apiAddress.postal_identification_code,
        countryNameCode: apiAddress.country_name_code,
    };
};

export const mapDateToApiShipmentDate = (dateString: string) => {
    if (isLocalDate(dateString)) {
        return {
            value: dateString.slice(0, 10), // only the date part
            type: ShipmentDateType.DATE,
        };
    }
    return {
        value: dateString,
        type: ShipmentDateType.DATE_TIME,
    };
};

export const mapShipmentCreationRequestToApi = (
    shipmentCreationRequest: ShipmentCreationRequest,
): ApiShipmentCreationRequest => ({
    requested_delivery_date: shipmentCreationRequest.requestedDeliveryDate
        ? mapDateToApiShipmentDate(shipmentCreationRequest.requestedDeliveryDate)
        : undefined,
    estimated_arrival_date: shipmentCreationRequest.estimatedArrivalDate
        ? mapDateToApiShipmentDate(shipmentCreationRequest.estimatedArrivalDate)
        : undefined,
    place_of_discharge: mapPlaceOfDischargeToApi(shipmentCreationRequest.placeOfDischarge),
    load: shipmentCreationRequest.load.map(mapCreationRequestLoadItemToApi),
});

export const mapShipmentUpdateRequestToApi = (
    shipmentUpdateRequest: ShipmentUpdateRequest,
    packagingOuterDimensions: NonNullable<PackagingConfig['packagingOuterDimensions']>,
): ApiShipmentUpdateRequest => {
    const idToDeliveryNoteNumberAndPositionMap = loadItemIdToDeliveryNoteNumberAndPositionMap(
        shipmentUpdateRequest.load,
    );

    const filteredPackagingOuterDimensions: PackagingConfig['packagingOuterDimensions'] = {};
    shipmentUpdateRequest.packaging.forEach((outerHandlingUnit) => {
        const outerHandlingUnitId = outerHandlingUnit.handlingUnit.id;
        filteredPackagingOuterDimensions[outerHandlingUnitId] = packagingOuterDimensions[outerHandlingUnitId];
    });

    return {
        despatch_date: shipmentUpdateRequest.despatchDate
            ? mapDateToApiShipmentDate(shipmentUpdateRequest.despatchDate)
            : undefined,
        load: shipmentUpdateRequest.load.map(mapDeliveryNoteToApi),
        packaging: shipmentUpdateRequest.packaging.map((handlingUnitGroup) =>
            mapToHandlingUnitGroupUpdateApi(
                handlingUnitGroup,
                idToDeliveryNoteNumberAndPositionMap,
                filteredPackagingOuterDimensions,
            ),
        ),
        terms_of_delivery: shipmentUpdateRequest.termsOfDelivery
            ? mapTermsOfDeliveryToApi(shipmentUpdateRequest.termsOfDelivery)
            : undefined,
        carrier: shipmentUpdateRequest.managedFreightForwarder,
        customs: shipmentUpdateRequest.customs ? mapCustomsToApi(shipmentUpdateRequest.customs) : undefined,
        means_of_transport: shipmentUpdateRequest.meansOfTransport
            ? mapMeansOfTransportToApi(shipmentUpdateRequest.meansOfTransport)
            : undefined,
        shipment_number: shipmentUpdateRequest.shipmentNumber,
        transport_order_number: shipmentUpdateRequest.transportOrderNumber,
        estimated_arrival_date: shipmentUpdateRequest.estimatedArrivalDate
            ? mapDateToApiShipmentDate(shipmentUpdateRequest.estimatedArrivalDate)
            : undefined,
    };
};

export const mapCustomsToApi = (customs: Customs): ApiCustoms => ({
    value: customs.value ? mapCustomsValueToApi(customs.value) : undefined,
    clearance: customs.clearance,
    preference_authorization: customs.preferenceAuthorization
        ? mapPreferenceAuthorizationToApi(customs.preferenceAuthorization)
        : undefined,
});

const mapCustomsValueToApi = (customValue: CustomsValue): ApiCustomsValue => ({
    amount: customValue.amount,
    currency_code: customValue.currencyCode,
});

export const mapMeansOfTransportToApi = (
    meansOfTransport: MeansOfTransportWithoutConfirmationData,
): ApiUpdateMeansOfTransport => {
    switch (meansOfTransport.mode) {
        case MeansOfTransportMode.MARITIME:
            return {
                mode: MeansOfTransportType.MARITIME,
                ship_name: meansOfTransport.shipName,
                container_number: meansOfTransport.containerNumber,
            };
        case MeansOfTransportMode.RAIL:
            return {
                mode: MeansOfTransportType.RAIL,
                train_number: meansOfTransport.trainNumber,
                rail_car_number: meansOfTransport.railCarNumber,
            };
        case MeansOfTransportMode.ROAD:
            return {
                mode: MeansOfTransportType.ROAD,
                vehicle_registration_number: meansOfTransport.registrationNumber,
                vehicle_trailer_registration_number: meansOfTransport.trailerRegistrationNumber,
            };
        case MeansOfTransportMode.AIR:
            return {
                mode: MeansOfTransportType.AIR,
                flight_number: meansOfTransport.flightNumber,
            };
        case MeansOfTransportMode.MAIL:
            return {
                mode: MeansOfTransportType.MAIL,
                postal_parcel_number: meansOfTransport.parcelNumber,
            };
        case MeansOfTransportMode.MULTIMODAL:
            return {
                mode: MeansOfTransportType.MULTIMODAL,
                vehicle_number_plate_interchangeable_chassis: meansOfTransport.swapBodyRegistrationNumber,
                swap_body_number: meansOfTransport.swapBodyNumber,
            };
        default:
            return neverReachedFor(meansOfTransport);
    }
};

export const mapPreferenceAuthorizationToApi = (
    preferenceAuthorization: PreferenceAuthorization,
): ApiPreferenceAuthorization => ({
    text: preferenceAuthorization.text,
    language: preferenceAuthorization.language,
});

export const mapTermsOfDeliveryToApi = (termsOfDelivery: TermsOfDelivery): ApiTermsOfDelivery => ({
    incoterms_code: termsOfDelivery.incotermsCode,
    location:
        termsOfDelivery.location === undefined || isEmpty(termsOfDelivery.location.name.trim())
            ? undefined
            : { name: termsOfDelivery.location.name },
});

export const mapPlaceOfDischargeToApi = (placeOfDischarge: PlaceOfDischarge): ApiPlaceOfDischarge => ({
    id: placeOfDischarge.id,
    name: placeOfDischarge.name,
    address: placeOfDischarge.address ? mapAddressToApi(placeOfDischarge.address) : undefined,
});

const mapAddressToApi = (address: Address): ApiAddress => ({
    street: address.street,
    city_name: address.cityName,
    country_sub_entity_name_code: address.countrySubEntityNameCode,
    postal_identification_code: address.postalIdentificationCode,
    country_name_code: address.countryNameCode,
});

export const mapDeliveryNoteToApi = (deliveryNote: DeliveryNote): ApiDeliveryNote => ({
    delivery_note_number: deliveryNote.deliveryNoteNumber,
    load_items: deliveryNote.loadItems.map(mapLoadItemToApi),
});

const mapLoadItemToApi = (loadItem: LoadItem): ApiLoadItem => ({
    order_number: loadItem.orderNumber,
    article_number_buyer: loadItem.articleNumberBuyer,
    article_master_data: loadItem.articleMasterData && mapArticleMasterDataToApi(loadItem.articleMasterData),
    amount: mapAmountToApi(loadItem.amount),
    batch_number: loadItem.batchNumber,
    is_subject_to_preference_authorization: loadItem.isSubjectToPreferenceAuthorization,
    expiry_date: loadItem.expiryDate,
    additional_product_id: !isEmpty(loadItem.additionalProductId)
        ? mapAdditionalProductIdToApi(loadItem.additionalProductId!)
        : undefined,
    referenced_delivery_schedule_id: loadItem.referencedDeliveryScheduleId,
});

const mapAdditionalProductIdToApi = (additionalProductId: AdditionalProductId): ApiAdditionalProductId => ({
    software_status: additionalProductId.softwareStatus,
    hardware_status: additionalProductId.hardwareStatus,
    parts_generation_status: additionalProductId.partsGenerationStatus,
});

export const mapCreationRequestLoadItemToApi = (loadItem: ShipmentCreationLoadItem): ApiShipmentCreationLoadItem => ({
    delivery_schedule_id: loadItem.deliveryScheduleId,
    amount: mapAmountToApi(loadItem.amount),
});

const mapAmountToApi = (amount: Quantity): ApiQuantity => ({
    value: amount.value,
    measurement_unit_code: amount.measurementUnitCode,
});

export const mapArticleSuggestions = (articleSuggestions: ApiArticleSuggestions): ArticleSuggestion[] => {
    return articleSuggestions.items.map(mapArticleSuggestion);
};

export const mapArticleSuggestion = (apiArticleSuggestion: ApiArticleSuggestion): ArticleSuggestion => {
    return {
        articleNumberBuyer: apiArticleSuggestion.article_number_buyer,
        articleMasterData:
            apiArticleSuggestion.article_master_data && mapArticleMasterData(apiArticleSuggestion.article_master_data),
        itemShortDescriptions: apiArticleSuggestion.item_short_descriptions.map(mapItemShortDescription),
        orderNumber: apiArticleSuggestion.order_number,
        measurementUnitCode: apiArticleSuggestion.measurement_unit_code,
        referencedDeliveryScheduleId: apiArticleSuggestion.referenced_delivery_schedule_id,
        hasValidArticleMasterData: apiArticleSuggestion.has_valid_article_master_data,
        hasValidCumulativeQuantitySent: apiArticleSuggestion.has_valid_cumulative_quantity_sent,
    };
};

export const mapDeliveryNoteNumberData = (
    apiDeliveryNoteNumberData: ApiDeliveryNoteNumberData,
): DeliveryNoteNumberData => ({
    deliveryNoteNumber: apiDeliveryNoteNumberData.delivery_note_number,
});

export const mapLabelNumberData = (apiLabelNumberData: ApiLabelNumberData): LabelNumberData => ({
    labelNumber: apiLabelNumberData.label_number,
});

export const mapExtendedArticleMasterDataList = (
    apiExternalArticleMasterDataList: ApiExtendedArticleMasterDataList,
): ExtendedArticleMasterData[] => apiExternalArticleMasterDataList.items.map((it) => mapExtendedArticleMasterData(it));
