import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { BaseSyntheticEvent, ReactElement, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { formatWeight } from '../../../../../utils';
import { usePostOperationMutation } from '../../../api/dispatchProposal/dispatchProposalApi';
import {
    AddArticleToDispatchProposalOperation,
    DispatchProposalOperationType,
} from '../../../api/dispatchProposal/operations.types';
import {
    ArticleSuggestion,
    DispatchProposal,
    DispatchProposalItem,
    DispatchProposalProblem,
    PackagingSummary,
    TransportOrderStatus,
    areDispatchProposalItemIdentifiersEqual,
    containsProblemsForDispatchProposalItem,
    isDispatchProposalItem,
    isUnpackagedDispatchProposalItem,
} from '../../../domain/dispatchProposal.types';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../../reducers/dispatchProposal/DispatchProposalsReducer';
import { getSelectedDispatchProposalItems } from '../../../selectors/dispatchProposals/DispatchProposals.selector';
import { NoDataAvailable } from '../../common/NoDataAvailable';
import { Tooltip } from '../../common/Tooltip';
import { FormattedDateOnly } from '../../common/i18n/FormattedDateOrDateTime';
import { useDispatchProposalItemCompatibility } from '../dispatchProposalHooks';
import { LoadingLocationSelector } from './LoadingLocationSelector';
import { PackagingHeight } from './PackagingHeight';
import { ThreeDotMenu } from './ThreeDotMenu';
import { BuyerColumn, EditableArticleNumberColumn, PlantColumn } from './table.common';

export const PACKAGING_SUMMARIES_TOOLTIP_TEST_ID = 'PACKAGING_SUMMARIES_TOOLTIP_TEST_ID';
export const TABLE_ACTION_COLUMN_TEST_ID = 'TABLE_ACTION_COLUMN_TEST_ID';
const EDITABLE_PACKAGING_HEIGHT_PLACEHOLDER_TEST_ID = 'EDITABLE_PACKAGING_HEIGHT_PLACEHOLDER_TEST_ID';
const EDITABLE_LOADING_LOCATION_COLUMN_TEST_ID = 'EDITABLE_LOADING_LOCATION_COLUMN_TEST_ID';
export const DISPATCH_PROPOSAL_ITEMS_TABLE_TEST_ID = 'DISPATCH_PROPOSAL_ITEMS_TABLE_TEST_ID';

export const DispatchProposalItemsTable = (props: {
    dispatchProposal: DispatchProposal;
    articleSuggestion?: ArticleSuggestion;
    problems: DispatchProposalProblem[];
}) => {
    const { dispatchProposal, articleSuggestion, problems } = props;
    const tableConfiguration =
        dispatchProposal.termination.transportConcept === 'KEP' ? kepTableConfiguration : nonKepTableConfiguration;
    return (
        <table
            className='table table-column-overflow-hidden table-head-filled table-layout-fixed border border-bottom-only'
            data-testid={DISPATCH_PROPOSAL_ITEMS_TABLE_TEST_ID}
        >
            <DispatchProposalItemTableColGroups tableConfig={tableConfiguration} />
            <DispatchProposalItemTableHeader tableConfig={tableConfiguration} />
            <DispatchProposalItemTableBody
                tableConfig={tableConfiguration}
                proposal={dispatchProposal}
                articleSuggestion={articleSuggestion}
                problems={problems}
            />
        </table>
    );
};

interface TableConfiguration {
    columns: TableColumnConfiguration[];
}

export type TableColumnConfigurationRenderProps = {
    dispatchProposal: DispatchProposal;
    item: DispatchProposalItem | ArticleSuggestion;
    rowIndex: number;
};

interface TableColumnConfiguration {
    name: string;
    hideLabel?: boolean;
    headerClassName?: string;
    colGroupClassName?: string;
    render: (props: TableColumnConfigurationRenderProps) => ReactElement;
}

interface DispatchProposalItemTableBodyProps {
    proposal: DispatchProposal;
    tableConfig: TableConfiguration;
    articleSuggestion?: ArticleSuggestion;
    problems: DispatchProposalProblem[];
}

const DispatchProposalItemTableColGroups = (props: { tableConfig: TableConfiguration }) => {
    const cols = props.tableConfig.columns.map((column) => (
        <col key={column.name} className={column.colGroupClassName} />
    ));

    return <colgroup>{cols}</colgroup>;
};

const DispatchProposalItemTableHeader = (props: { tableConfig: TableConfiguration }) => {
    const headerColumns = props.tableConfig.columns.map((column: TableColumnConfiguration) => (
        <DispatchProposalItemTableHeaderColumn
            key={column.name}
            hideLabel={column.hideLabel ?? false}
            className={column.headerClassName ?? ''}
            columnId={column.name}
        />
    ));

    return (
        <thead>
            <tr>{headerColumns}</tr>
        </thead>
    );
};

const DispatchProposalItemTableHeaderColumn = (props: { columnId: string; className: string; hideLabel: boolean }) => {
    const text =
        props.columnId && !props.hideLabel ? (
            <FormattedMessage id={`webedi.dispatchProposals.overview.expander.table.header.column.${props.columnId}`} />
        ) : (
            <></>
        );
    return <th className={props.className}>{text}</th>;
};

const rowColorClassNameByTransportOrderStatus: { [Status in TransportOrderStatus]: string } = {
    // biome-ignore lint/style/useNamingConvention: Enum values are not camelCase
    ORDERED: 'bg-lightest success',
    // biome-ignore lint/style/useNamingConvention: Enum values are not camelCase
    PLANNED: 'bg-lightest',
    // biome-ignore lint/style/useNamingConvention: Enum values are not camelCase
    UNPLANNED: '',
};

const determineRowColorClassName = (
    selectedItems: DispatchProposalItem[],
    transportOrderStatus: TransportOrderStatus,
    item: DispatchProposalItem,
    hasProblem: boolean,
): string => {
    let rowClassName = rowColorClassNameByTransportOrderStatus[transportOrderStatus];
    const isItemSelected = selectedItems.some((selectedItem) =>
        areDispatchProposalItemIdentifiersEqual(selectedItem, item),
    );
    if (rowClassName === '') {
        if (isItemSelected) {
            rowClassName = 'bg-highlight-lightest';
        } else {
            rowClassName = 'hover-bg-highlight-lightest';
        }
    }
    if (isUnpackagedDispatchProposalItem(item) || hasProblem) {
        rowClassName = 'danger';
    }
    return rowClassName;
};

const DispatchProposalItemTableBody = (props: DispatchProposalItemTableBodyProps) => {
    const selectedItems = useAppSelector(getSelectedDispatchProposalItems);
    const proposal = props.proposal;

    const rows = proposal.items.map((item, index) => {
        const hasProblem = containsProblemsForDispatchProposalItem(props.problems, proposal, item);

        const rowClassName = determineRowColorClassName(selectedItems, proposal.transportOrderStatus, item, hasProblem);
        const columns = props.tableConfig.columns.map((column) =>
            column.render({
                dispatchProposal: proposal,
                item,
                rowIndex: index,
            }),
        );
        let key = `${JSON.stringify(item.identifier)}`;
        if (item.type === 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM') {
            key = `${JSON.stringify(item.identifier)}-${item.articleContent.quantity.value}`;
        }
        return (
            <tr key={key} id={`${index}`} className={rowClassName}>
                {columns}
            </tr>
        );
    });

    const articleSuggestion = props.articleSuggestion;
    if (articleSuggestion) {
        const index = proposal.items.length;
        const rowClassName = rowColorClassNameByTransportOrderStatus['UNPLANNED'];
        const columns = props.tableConfig.columns.map((column) =>
            column.render({
                dispatchProposal: proposal,
                item: articleSuggestion,
                rowIndex: index,
            }),
        );
        rows.push(
            <tr key={index} id={`${index}`} className={rowClassName}>
                {columns}
            </tr>,
        );
    }

    return <tbody>{rows}</tbody>;
};

const CheckboxColumn = (props: TableColumnConfigurationRenderProps) => {
    const selectedItems = useAppSelector(getSelectedDispatchProposalItems);
    const dispatch = useAppDispatch();

    const nonKepItem =
        isDispatchProposalItem(props.item) && !isKepDispatchProposal(props.dispatchProposal)
            ? (props.item as DispatchProposalItem)
            : undefined;
    const transportCanBeOrderedForItem = useDispatchProposalItemCompatibility(props.dispatchProposal, nonKepItem);

    if (nonKepItem === undefined) {
        return (
            <td className={'table-checkbox'}>
                <Checkbox tabIndex={props.rowIndex} disabled={true} />
            </td>
        );
    }

    const dispatchProposalItem = props.item as DispatchProposalItem;
    const onToggle = (event: BaseSyntheticEvent) => {
        if (event.target.checked) {
            dispatch(
                dispatchProposalsSlice.actions.addDispatchProposalItemToTransportPlanningDraft({
                    dispatchProposal: props.dispatchProposal,
                    item: dispatchProposalItem,
                }),
            );
        } else {
            dispatch(
                dispatchProposalsSlice.actions.removeDispatchProposalItemFromTransportPlanningDraft({
                    dispatchProposal: props.dispatchProposal,
                    item: dispatchProposalItem,
                }),
            );
        }
    };

    const checked =
        selectedItems.find((it) => areDispatchProposalItemIdentifiersEqual(it, dispatchProposalItem)) !== undefined;
    return (
        <td className={'table-checkbox'}>
            <Checkbox
                tabIndex={props.rowIndex}
                onClick={onToggle}
                checked={checked}
                disabled={!transportCanBeOrderedForItem}
            />
        </td>
    );
};

const isKepDispatchProposal = (dispatchProposal: DispatchProposal): boolean =>
    dispatchProposal.termination.transportConcept === TransportConcept.KEP;

const ArticleNumberColumn = (props: {
    dispatchProposal: DispatchProposal;
    item: DispatchProposalItem | ArticleSuggestion;
}) => {
    const [addArticle] = usePostOperationMutation();
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath()!;

    const onSaveArticleSuggestion = (item: ArticleSuggestion, quantity: number) => {
        const operation: AddArticleToDispatchProposalOperation = {
            type: DispatchProposalOperationType.ADD_ARTICLE,
            dunsNumber,
            dispatchProposalId: props.dispatchProposal.id,
            referencedDeliveryScheduleId: item.referencedDeliveryScheduleId,
            amount: { value: quantity, measurementUnitCode: item.measurementUnitCode },
        };
        addArticle(operation)
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
            })
            .catch(() => {
                // do nothing; the error is already presented by the rtkGlobalErrorHandler
            })
            .finally(() => {
                dispatch(dispatchProposalsSlice.actions.articleAddedToDispatchProposal());
            });
    };

    return (
        <EditableArticleNumberColumn
            item={props.item}
            dispatchProposal={props.dispatchProposal}
            onSaveArticleSuggestion={onSaveArticleSuggestion}
            onSaveDispatchProposalItem={() => {}}
            data-testid={'article-number-column'}
            disabled={!props.dispatchProposal.operations.modifyLoadItemAmount.allowed}
        />
    );
};

export const DeliveryDateColumn = (props: { item: DispatchProposalItem | ArticleSuggestion }) => (
    <td>
        <div className={'ellipsis-1'}>
            {isDispatchProposalItem(props.item) && props.item.deliveryDate ? (
                <FormattedDateOnly date={props.item.deliveryDate} />
            ) : (
                <></>
            )}
        </div>
    </td>
);

const WeightColumn = (props: { item: DispatchProposalItem | ArticleSuggestion }) => {
    const intl = useIntl();

    if (isDispatchProposalItem(props.item) && props.item.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM') {
        const formattedWeight = formatWeight(intl.locale, props.item.grossWeightInKg);
        return (
            <td className=''>
                <div className='ellipsis-1'>
                    <span className='rioglyph rioglyph-weight text-size-18 margin-right-3' />
                    <span className='margin-right-5 ellipsis-1 word-break'>{formattedWeight}</span>
                </div>
            </td>
        );
    }

    return (
        <td key={'Weight'}>
            <NoDataAvailable />
        </td>
    );
};

const OuterPackagingColumn = (props: { item: DispatchProposalItem | ArticleSuggestion }) => {
    const dataFieldValue = 'outer-packaging';
    if (isDispatchProposalItem(props.item) && props.item.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM') {
        return (
            <td className={'text-left'} data-field={dataFieldValue}>
                {renderPackagingSummaries([props.item.outerPackaging], true)}
            </td>
        );
    }
    if (isDispatchProposalItem(props.item) && props.item.type === 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM') {
        return (
            <td className={'text-left'} data-field={dataFieldValue}>
                {renderMissingPackaging()}
            </td>
        );
    }
    return (
        <td data-field={dataFieldValue}>
            <NoDataAvailable />
        </td>
    );
};

const renderMissingPackaging = () => (
    <div className={'ellipsis-1'}>
        <div className={'ellipsis-1 text-color-danger'}>
            <span className={'align-items-center'}>
                <span className={'text-color-danger rioglyph rioglyph-error-sign text-size-18 margin-right-3'} />
                <span>
                    <FormattedMessage
                        id={'webedi.dispatchProposals.planDispatchProposal.body.table.missingPackaging'}
                    />
                </span>
            </span>
        </div>
    </div>
);

const InnerPackagingColumn = (props: { item: DispatchProposalItem | ArticleSuggestion }) => {
    const dataFieldValue = 'inner-packaging';
    if (isDispatchProposalItem(props.item) && props.item.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM') {
        return (
            <td className={'text-left'} data-field={dataFieldValue}>
                {renderPackagingSummaries(props.item.innerPackaging, false)}
            </td>
        );
    }
    return (
        <td data-field={dataFieldValue}>
            <NoDataAvailable />
        </td>
    );
};

const renderPackagingSummary = (packagingSummary: PackagingSummary) => (
    <FormattedMessage
        id={'webedi.dispatchProposals.planDispatchProposal.body.table.packagingSummary'}
        values={{
            amount: packagingSummary.count,
            type: packagingSummary.type,
            strong: (chunks: ReactNode) => <strong className={'padding-left-3'}>{chunks}</strong>,
        }}
    />
);

const renderPackagingSummaries = (packagingSummaries: PackagingSummary[], showIcon: boolean) => {
    const tooltipContents = (
        <span data-testid={PACKAGING_SUMMARIES_TOOLTIP_TEST_ID}>
            {packagingSummaries.map((packagingSummary) => (
                <div key={`${packagingSummary.type}-${packagingSummary.count}`}>
                    {renderPackagingSummary(packagingSummary)}
                </div>
            ))}
        </span>
    );
    return (
        <Tooltip text={tooltipContents} placement={'bottom-start'}>
            <div className={'ellipsis-1'}>
                {packagingSummaries.map((packagingSummary, index) => {
                    if (index > 1) {
                        return '';
                    }
                    return (
                        <div className={'ellipsis-1'} key={`${packagingSummary.type}-${packagingSummary.count}`}>
                            <span className={'align-items-center'}>
                                {showIcon ? (
                                    <span className={'rioglyph rioglyph-parcel text-size-18 margin-right-3'} />
                                ) : (
                                    ''
                                )}
                                {renderPackagingSummary(packagingSummary)}
                                {index === 1 && packagingSummaries.length > 2 ? <span>, …</span> : ''}
                            </span>
                        </div>
                    );
                })}
            </div>
        </Tooltip>
    );
};

const checkboxColumnConfig: TableColumnConfiguration = {
    name: 'checkbox',
    hideLabel: true,
    colGroupClassName: 'table-checkbox',
    render: (props) => <CheckboxColumn {...props} key={'CheckboxColumn'} />,
};

const articleNumberColumnConfig: TableColumnConfiguration = {
    name: 'articleNumber',
    colGroupClassName: 'width-250-lg width-300-xl',
    render: (props) => <ArticleNumberColumn {...props} key={'ArticleNumberColumn'} />,
};

const deliveryDateColumnConfig: TableColumnConfiguration = {
    name: 'deliveryDate',
    headerClassName: 'ellipsis-1',
    render: (props) => <DeliveryDateColumn {...props} key={'DeliveryDateColumn'} />,
};

const customerColumnConfig: TableColumnConfiguration = {
    name: 'customer',
    headerClassName: 'ellipsis-1',
    render: (props) => <BuyerColumn {...props} key={'BuyerColumn'} />,
};

const plantUnloadingColumnConfig: TableColumnConfiguration = {
    name: 'plantUnloading',
    headerClassName: 'ellipsis-1',
    render: (props) => <PlantColumn {...props} key={'PlantColumn'} />,
};

const loadingLocationColumnConfig: TableColumnConfiguration = {
    name: 'loading',
    headerClassName: 'ellipsis-1',
    render: (props) => (
        <td key={'LoadingLocationColumn'} data-testid={EDITABLE_LOADING_LOCATION_COLUMN_TEST_ID}>
            <LoadingLocationSelector
                item={props.item as DispatchProposalItem}
                dispatchProposalId={props.dispatchProposal.id}
                isOperationAllowed={props.dispatchProposal.operations.changeLoadingLocation.allowed}
            />
        </td>
    ),
};

const weightColumnConfig: TableColumnConfiguration = {
    name: 'weight',
    headerClassName: 'ellipsis-1',
    render: (props) => <WeightColumn {...props} key={'DimensionsColumn'} />,
};

const outerPackagingColumnConfig: TableColumnConfiguration = {
    name: 'outerPackaging',
    headerClassName: 'ellipsis-1',
    render: (props) => <OuterPackagingColumn {...props} key={'OuterPackagingColumn'} />,
};

const innerPackagingColumnConfig: TableColumnConfiguration = {
    name: 'innerPackaging',
    headerClassName: 'ellipsis-1',
    render: (props) => <InnerPackagingColumn {...props} key={'InnerPackagingColumn'} />,
};

const packagingHeightColumnConfig: TableColumnConfiguration = {
    name: 'packagingHeight',
    colGroupClassName: 'width-200',
    headerClassName: 'ellipsis-1',
    render: (props) => (
        <td key={'PackagingHeightColumn'} data-testid={EDITABLE_PACKAGING_HEIGHT_PLACEHOLDER_TEST_ID}>
            <PackagingHeight item={props.item} transportOrderStatus={props.dispatchProposal.transportOrderStatus} />
        </td>
    ),
};

const actionsColumnConfig: TableColumnConfiguration = {
    name: 'actions',
    hideLabel: true,
    colGroupClassName: 'table-action min-width-50 width-50',
    render: (props) => (
        <td className='table-action' key={'ActionsColumn'} data-testid={TABLE_ACTION_COLUMN_TEST_ID}>
            {isDispatchProposalItem(props.item) ? (
                <ThreeDotMenu {...props} key={'ThreeDotMenu'} />
            ) : (
                <ButtonDropdown
                    iconOnly={true}
                    bsStyle={'link' as BUTTON_STYLE}
                    title={<span className='rioglyph rioglyph-option-vertical' />}
                    items={[]}
                    disabled={true}
                />
            )}
        </td>
    ),
};

const nonKepTableConfiguration: TableConfiguration = {
    columns: [
        checkboxColumnConfig,
        articleNumberColumnConfig,
        deliveryDateColumnConfig,
        customerColumnConfig,
        plantUnloadingColumnConfig,
        loadingLocationColumnConfig,
        weightColumnConfig,
        outerPackagingColumnConfig,
        innerPackagingColumnConfig,
        packagingHeightColumnConfig,
        actionsColumnConfig,
    ],
};

const kepTableConfiguration: TableConfiguration = {
    columns: [
        checkboxColumnConfig,
        articleNumberColumnConfig,
        { ...deliveryDateColumnConfig, hideLabel: true, render: () => <td key={'alignmentPlaceHolderKey1'} /> },
        customerColumnConfig,
        plantUnloadingColumnConfig,
        loadingLocationColumnConfig,
        weightColumnConfig,
        outerPackagingColumnConfig,
        innerPackagingColumnConfig,
        { ...packagingHeightColumnConfig, hideLabel: true, render: () => <td key={'alignmentPlaceHolderKey2'} /> },
        actionsColumnConfig,
    ],
};
