import { DocumentSettings, DocumentSettingsWithOptionalFields } from './ShipmentDocuments.types';

export const documentSettingsLocalStorageKey = 'shipmentDocumentGeneratorSettings';

export const writeDocumentSettingsToLocalStorage = (documentSettings: DocumentSettings) => {
    localStorage.setItem(documentSettingsLocalStorageKey, JSON.stringify(documentSettings));
};

export const readDocumentSettingsFromLocalStorage = (): DocumentSettingsWithOptionalFields | undefined => {
    const documentSettingsJsonFromStorage = localStorage.getItem(documentSettingsLocalStorageKey);
    return documentSettingsJsonFromStorage
        ? (JSON.parse(documentSettingsJsonFromStorage) as DocumentSettingsWithOptionalFields)
        : undefined;
};
