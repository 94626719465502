import { localDateToLocalEndOfDayInUtc, localDateToLocalStartOfDayInUtc, today } from '../../../../dateUtils';
import { State } from '../../../../reducers';

export const getShowDetailsForTransportOrder = (state: State): string | undefined =>
    state.webEdi.transportOrders.showDetailsForTransportOrder;
export const getSelectedTransportOrders = (state: State) => state.webEdi.transportOrders.selectedTransportOrders;
export const isTransportOrderSelected = (state: State, transportOrderId: string) =>
    state.webEdi.transportOrders.selectedTransportOrders.some((it) => it.transportOrderId === transportOrderId);
export const getSelectedFilters = (state: State) => state.webEdi.transportOrders.filters;
export const getSelectedLoadingFromFilter = (state: State) =>
    state.webEdi.transportOrders.filters.loadingFrom || localDateToLocalStartOfDayInUtc(today());
export const getSelectedLoadingToFilter = (state: State): string =>
    state.webEdi.transportOrders.filters.loadingTo || localDateToLocalEndOfDayInUtc(today());
export const getSelectedFreightForwarderNameFilter = (state: State) =>
    state.webEdi.transportOrders.filters.freightForwarderName;
export const getSelectedTransportConceptFilter = (state: State) =>
    state.webEdi.transportOrders.filters.transportConcept;
export const getSelectedPlaceOfDischargeFilter = (state: State) =>
    state.webEdi.transportOrders.filters.placeOfDischarge;
export const getSelectedBuyerNameFilter = (state: State) => state.webEdi.transportOrders.filters.buyerName;
export const getSelectedPlantNumberFilter = (state: State) => state.webEdi.transportOrders.filters.plantNumber;
export const getSelectedTimeRangeTypeFilter = (state: State) => state.webEdi.transportOrders.filters.timeRangeType;
