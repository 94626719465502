import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { dateTimeToLocalDate, isAfter, isSameOrAfter, plusDuration } from '../../../../dateUtils';
import { useGetTransportOrdersQuery } from '../../api/transportOrder/transportOrdersApi';
import { TransportOrder } from '../../domain/transportOrder.types';
import { MAX_REQUEST_INTERVAL_IN_DAYS } from '../../domain/transportOrderOptions.types';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import {
    getSelectedLoadingFromFilter,
    getSelectedLoadingToFilter,
    getShowDetailsForTransportOrder,
} from '../../selectors/transportOrders/transportOrders.selector';

export const useTransportOrdersQuery = () => {
    const notKnown = 'NOT_KNOWN';

    const dunsNumber = useDunsNumberFromPath() ?? notKnown;
    const loadingFrom = useAppSelector(getSelectedLoadingFromFilter);
    const loadingTo = useAppSelector(getSelectedLoadingToFilter);
    const embed: 'shipments'[] = ['shipments'];

    const rangeExceedsMaximum = isSameOrAfter(
        dateTimeToLocalDate(loadingTo),
        plusDuration(dateTimeToLocalDate(loadingFrom), { days: MAX_REQUEST_INTERVAL_IN_DAYS }),
    );

    return useGetTransportOrdersQuery(
        { dunsNumber, embed, loadingFrom, loadingTo },
        {
            refetchOnMountOrArgChange: true,
            skip: dunsNumber === notKnown || isAfter(loadingFrom, loadingTo) || rangeExceedsMaximum,
        },
    );
};

export const useShowDetailsForTransportOrder = (): TransportOrder | undefined => {
    const selectedTransportOrderId = useAppSelector(getShowDetailsForTransportOrder);
    const { data } = useTransportOrdersQuery();
    return data?.find((it) => it.id === selectedTransportOrderId);
};
