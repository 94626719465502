import { FocusEvent, useCallback, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';

export interface TextInputProps extends FieldRenderProps<string> {
    disabled?: boolean;
    exactStringLength?: number;
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
    previousValue?: string;
}

export const TextInput = (props: TextInputProps) => {
    const { input, meta, ...rest } = props;
    const { id, disabled, exactStringLength, className, previousValue } = rest;
    const dataTestId = rest[DATA_TEST_ID_PROPERTY_NAME];
    const errorClassName = 'form-group has-error has-feedback';

    const getInputRowClassName = (error: any, touched?: boolean) => `${touched && error ? errorClassName : ''}`;

    return (
        <div className={`${getInputRowClassName(meta.error, meta.touched)}`}>
            {previousValue && <TextInputWithHistory {...props} />}
            {!previousValue && (
                <input
                    id={id}
                    type={'text'}
                    {...input}
                    className={className}
                    disabled={disabled}
                    data-testid={dataTestId}
                />
            )}

            {meta.touched && meta.error ? (
                <span className={'help-block'}>
                    <FormattedMessage id={meta.error} values={{ number: exactStringLength }} />
                </span>
            ) : null}
        </div>
    );
};
const TextInputWithHistory = (props: TextInputProps) => {
    const { input, meta, ...rest } = props;
    const { id, disabled, className, previousValue } = rest;
    const dataTestId = rest[DATA_TEST_ID_PROPERTY_NAME];
    const [inFocus, setInFocus] = useState<boolean>(false);

    const getInputPreviousValueClassName = useCallback(() => {
        return inFocus || !previousValue ? '' : 'padding-bottom-0 padding-top-10 text-size-12';
    }, [inFocus, previousValue]);
    const onBlurEvent = (event: FocusEvent<HTMLElement>) => {
        previousValue && setInFocus(false);
        input.onBlur?.(event);
    };

    const onFocusEvent = (event: FocusEvent<HTMLElement>) => {
        previousValue && setInFocus(true);
        input.onFocus?.(event);
    };

    return (
        <div className='input-group'>
            <input
                id={id}
                type={'text'}
                {...input}
                className={`${className} ${getInputPreviousValueClassName()} `}
                disabled={disabled}
                data-testid={dataTestId}
                onBlur={onBlurEvent}
                onFocus={onFocusEvent}
            />
            {previousValue && !inFocus && (
                <div
                    className='position-absolute top-3 left-10 margin-left-3 text-size-10 text-decoration-line-through'
                    aria-label='previous value'
                >
                    {previousValue}
                </div>
            )}
        </div>
    );
};
