import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Partner } from '../../domain/common.types';
import { useBuyerContextFromCurrentlySelectedDeliveryScheduleOrShipment } from '../../hooks/useBuyerContext';
import { BoxFinderInput } from '../common/form/BoxFinderInput';

const PackagingMaterialHelpLink = () => {
    return (
        <a
            className={'float-right text-size-12 text-decoration-none'}
            href={
                'https://lso.volkswagen.de/one-kbp/content/media/kbp_private/kbp_private_documents/kbp_systemdokumente_applikationen/webscm/handbuecher_3/WEB_SCM_Verpackungs-Codierung_fuer_Einweg-_und_Ausweichverpackung.pdf?streaming=true'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
            <span className={'rioglyph rioglyph-question-sign padding-right-3'} />
            <FormattedMessage id={'webedi.packaging.packagingMaterial.help'} />
        </a>
    );
};

type BoxFinderProps = {
    id: string;
    name: string;
    showLinkToVWMaterialHelp: boolean;
    boxFinderInputProps?: {
        disabled: boolean;
        outerClassName: string;
        packagingMaterialInputPrefix: string;
    };
};

export const BoxFinder = (props: BoxFinderProps) => {
    const { partner } = useBuyerContextFromCurrentlySelectedDeliveryScheduleOrShipment();
    const isVWorPorschePartner = partner === Partner.VW || partner === Partner.PORSCHE;
    return (
        <div>
            <label className={'align-left'} htmlFor={props.id}>
                <FormattedMessage id={'webedi.packagingMaterial'} />
            </label>
            {props.showLinkToVWMaterialHelp && isVWorPorschePartner && (
                <span className={'float-right text-size-12'}>
                    <PackagingMaterialHelpLink />
                </span>
            )}
            <Field
                id={props.id}
                name={props.name}
                component={BoxFinderInput}
                placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                {...props.boxFinderInputProps}
            />
        </div>
    );
};
