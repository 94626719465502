import { ReactNode, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { State } from '../../../../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../hooks/useImage';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { getSelectedDeliverySchedule } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { getPackagingMaterialForPackagingTemplateConfiguration } from '../../../selectors/packaging/Packaging.selector';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { PackagingConfigurationNumberInput } from '../../common/dialog/PackagingConfigurationNumberInput';
import { FieldErrorType, FormErrors } from '../../common/form/types';
import {
    formIds,
    formPropertyNamesInnerCreationPackagingTemplate,
} from '../../shipments/packaging/dialogs/InnerPackaging/InnerPackagingFormConfig';
import { PACKAGING_TEMPLATE_TEST_IDS } from './PackagingTemplateConfiguration';

export interface InnerPackagingTemplateConfigurationFormValues {
    quantityValuePerHandlingUnit?: number;
}
type InnerPackagingTemplateConfigurationFormErrors = FormErrors<InnerPackagingTemplateConfigurationFormValues>;

export const validateForm = ({
    quantityValuePerHandlingUnit,
}: InnerPackagingTemplateConfigurationFormValues): InnerPackagingTemplateConfigurationFormErrors => {
    const validationResult: InnerPackagingTemplateConfigurationFormErrors = {};
    if (quantityValuePerHandlingUnit === undefined || quantityValuePerHandlingUnit <= 0) {
        validationResult.quantityValuePerHandlingUnit = FieldErrorType.NON_POSITIVE_NUMBER;
    }

    return validationResult;
};
export const InnerPackagingTemplateConfiguration = () => {
    const dispatch = useAppDispatch();
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);

    const measurementUnitCode = selectedDeliverySchedule?.scheduledArticleDetails.measurementUnitCode!;
    const articleNumber =
        selectedDeliverySchedule?.scheduledArticleDetails.lineItem.lineItemIdentifier.itemNumberIdentification
            .itemIdentifier!;
    const packagingMaterial = useAppSelector((state: State) =>
        getPackagingMaterialForPackagingTemplateConfiguration(state),
    );
    const handlingUnitLabel = packagingMaterial.description;
    const measurementUnitCodeTranslation = <MeasurementUnitCodeDisplay unit={measurementUnitCode} />;
    const handlingUnitWithArticlesImage = useImage('handlingUnitWithArticles');

    useEffect(() => {
        dispatch(deliverySchedulesSlice.actions.updateFormIdInPackagingWizard(formIds.packagingConfiguration));
    }, [dispatch]);

    return (
        <Form<{ quantityValuePerHandlingUnit: number }>
            onSubmit={(values) => {
                dispatch(
                    deliverySchedulesSlice.actions.updateInnerPackagingTemplateStepQuantityConfiguration({
                        value: values.quantityValuePerHandlingUnit,
                        measurementUnitCode,
                    }),
                );
                dispatch(deliverySchedulesSlice.actions.showNewAuxiliaryPackagingTemplateStep());
            }}
            validate={validateForm}
            render={({ handleSubmit }) => {
                const fieldName = formPropertyNamesInnerCreationPackagingTemplate.quantityValuePerHandlingUnit;
                const fieldDescriptionValues = { handlingUnit: handlingUnitLabel, articleNumber };
                return (
                    <form id={formIds.packagingConfiguration} onSubmit={handleSubmit}>
                        <div
                            className={'display-flex flex-row align-items-center'}
                            data-testid={PACKAGING_TEMPLATE_TEST_IDS.innerConfig}
                        >
                            <div
                                className={
                                    'display-flex justify-content-center position-relative width-25pct margin-x-20'
                                }
                            />
                            <div
                                className={
                                    'display-flex justify-content-center width-50pct padding-y-5pct bg-white align-items-center flex-column'
                                }
                            >
                                <span className={'text-size-18 text-center margin-bottom-25'}>
                                    <FormattedMessage id={'webedi.packaging.template.innerPackaging.step2.title'} />
                                </span>
                                <span className={'margin-bottom-20'}>
                                    <FormattedMessage
                                        id={'webedi.packaging.innerPackaging.step2.explanation'}
                                        values={{
                                            packagingMaterial: handlingUnitLabel,
                                            articleNumber,
                                        }}
                                    />
                                </span>
                                <div
                                    className={
                                        'display-flex justify-content-center position-relative border-style-solid border-color-lighter border-width-1 padding-y-5pct bg-white align-items-center flex-column'
                                    }
                                >
                                    <div className={'display-flex flex-row align-items-center'}>
                                        <div className={'display-flex flex-column width-40pct align-items-center'}>
                                            <img
                                                src={handlingUnitWithArticlesImage}
                                                className={'width-50pct height-50pct'}
                                                alt={'handlingUnitWithArticles'}
                                            />
                                        </div>
                                        <div className={`flex-column width-60pct counter color-highlight`}>
                                            <div className={'invisible min-height-auto margin-0'} />
                                            <div className={'display-flex flex-column align-items-baseline'}>
                                                <PackagingConfigurationNumberInput
                                                    {...{
                                                        labelDescription: (
                                                            <FormattedMessage
                                                                id={
                                                                    'webedi.packaging.inner.articlesPerHandlingUnit.label'
                                                                }
                                                                values={{
                                                                    measurementUnitCode: measurementUnitCodeTranslation,
                                                                    b: (chunks: ReactNode) => <b>{chunks}</b>,
                                                                }}
                                                            />
                                                        ),
                                                        inputFormId: formIds.quantityValuePerHandlingUnit,
                                                        fieldName,
                                                        fieldDescription:
                                                            'webedi.packaging.inner.articlesPerHandlingUnit.description',
                                                        fieldDescriptionValues,
                                                        measurementUnitCode,
                                                        showDescription: handlingUnitLabel !== undefined,
                                                        iconClass: undefined,
                                                        innerPackaging: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={
                                    'display-flex justify-content-center position-relative width-25pct margin-x-20'
                                }
                            />
                        </div>
                    </form>
                );
            }}
        />
    );
};
