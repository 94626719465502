import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { ExtendedArticleMasterData } from '../../reducers/shipments/types';
import { decodeJson } from '../apiUtils';
import { ExtendedArticleMasterDataListCodec } from './shipment.types';
import { mapExtendedArticleMasterDataList } from './shipmentMapper';

export interface UpdateShipmentPackagingHeightRequest {
    shipmentId: string;
    dunsNumber: string;
    giphyId: string;
    heightInMm: number;
}

const mapToApiUpdateShipmentPackagingHeight = (request: UpdateShipmentPackagingHeightRequest) => ({
    giphy_id: request.giphyId,
    height_in_mm: request.heightInMm,
});

export const shipmentApi = createApi({
    reducerPath: 'shipmentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getExtendedArticleMasterDataList: builder.query<
            ExtendedArticleMasterData[],
            { dunsNumber: string; shipmentId: string }
        >({
            query: ({ dunsNumber, shipmentId }) =>
                `/shippers/${dunsNumber}/shipments/${shipmentId}/extended-article-master-data`,
            transformResponse: (response: unknown) => {
                const responseDecoded = decodeJson(ExtendedArticleMasterDataListCodec)(response);
                return mapExtendedArticleMasterDataList(responseDecoded);
            },
        }),
        updateShipmentPackagingHeight: builder.mutation<undefined, UpdateShipmentPackagingHeightRequest>({
            query: (request: UpdateShipmentPackagingHeightRequest) => ({
                url: `/shippers/${request.dunsNumber}/shipments/${request.shipmentId}`,
                method: 'PATCH',
                body: mapToApiUpdateShipmentPackagingHeight(request),
            }),
        }),
    }),
});

export const { useGetExtendedArticleMasterDataListQuery, useUpdateShipmentPackagingHeightMutation } = shipmentApi;
