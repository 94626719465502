import { IdentityProvider } from '../../config';
import { isRioIdToken } from '../tokenHandling/IdToken';
import { getIdToken } from '../tokenHandling/selectors';

export const getConfigCatSdkKeyForToken = (state: any): string => {
    const identityProvider = state.config.identityProvider;
    const idToken = getIdToken(state);
    if (identityProvider === IdentityProvider.VW) {
        return import.meta.env.VITE_CONFIG_CAT_API_KEY_VW || 'unavailable';
    } else if (identityProvider === IdentityProvider.VW_QA) {
        return import.meta.env.VITE_CONFIG_CAT_API_KEY_VW_QA || 'unavailable';
    } else if (idToken && isRioIdToken(idToken) && idToken.tenant === 'rio-eu.prod') {
        return import.meta.env.VITE_CONFIG_CAT_API_KEY_RIO_EU_PROD || 'unavailable';
    } else {
        return import.meta.env.VITE_CONFIG_CAT_API_KEY_RIO_EU_TEST || 'unavailable';
    }
};
