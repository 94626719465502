import DataTab from '@rio-cloud/rio-uikit/lib/es/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/lib/es/DataTabs';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { notifySuccess } from '../../../actions/NotificationUtils';
import { ProblemError } from '../../../api/apiUtils';
import { patchShipmentExported } from '../../../api/shipment/shipmentCalls';
import { transportOrderApi } from '../../../api/transportOrder/transportOrdersApi';
import { TransportOrder } from '../../../domain/transportOrder.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import {
    SelectedTransportOrder,
    transportOrdersSlice,
} from '../../../reducers/transportOrders/TransportOrders.reducer';
import { uiSlice } from '../../../reducers/ui/UI.reducer';
import { getSelectedTransportOrders } from '../../../selectors/transportOrders/transportOrders.selector';
import { ShipmentDetails } from '../common/ShipmentDetails';
import { ShipmentLink } from '../common/ShipmentLink';

interface ExportShippingNoticesDialogProps {
    show: boolean;
    onClose: () => void;
    transportOrders: TransportOrder[];
}

export const ExportShippingNoticesDialog = (props: ExportShippingNoticesDialogProps) => {
    const dispatch = useAppDispatch();
    const selectedTransportOrders = useAppSelector(getSelectedTransportOrders);
    const [confirmDisabled, setConfirmDisabled] = useState<boolean>(false);
    const dunsNumber = useDunsNumberFromPath() ?? 'NOT_KNOWN';
    const errors: Record<string, string> = {};
    const successfullyExported: string[] = [];

    const onConfirm = async () => {
        setConfirmDisabled(true);
        await Promise.all(
            selectedTransportOrders.map(async (transportOrder) => {
                const shipmentId = transportOrder.shipmentId;
                try {
                    await patchShipmentExported(dunsNumber, shipmentId);
                    successfullyExported.push(transportOrder.transportOrderId);
                } catch (exception) {
                    const error: ProblemError = exception as ProblemError;
                    errors[shipmentId] = `webedi.error.${error.errorCode ? error.errorCode : 'unknown'}`;
                }
            }),
        );

        if (Object.keys(errors).length > 0) {
            dispatch(uiSlice.actions.setTransportOrderViewErrors(errors));
        } else {
            notifySuccess('webedi.label.success.export');
        }
        successfullyExported.forEach((id) => dispatch(transportOrdersSlice.actions.removeSelectedTransportOrder(id)));
        dispatch(transportOrderApi.util.invalidateTags([{ type: 'TransportOrders' }]));
        setConfirmDisabled(false);
        props.onClose();
    };

    return (
        <Dialog
            body={
                <ExportShippingNoticesDialogBody
                    selectedTransportOrders={selectedTransportOrders}
                    transportOrders={props.transportOrders}
                />
            }
            title={<FormattedMessage id={'webedi.shipment.export.dialog.title'} />}
            footer={
                <ExportShippingNoticesDialogFooter
                    onCancel={props.onClose}
                    onConfirm={onConfirm}
                    selectedTransportOrderCount={selectedTransportOrders.length}
                    confirmDisabled={confirmDisabled}
                />
            }
            show={props.show}
            onHide={props.onClose}
            onEsc={props.onClose}
            showCloseButton
        />
    );
};

interface ExportShippingNoticesDialogBodyProps {
    selectedTransportOrders: SelectedTransportOrder[];
    transportOrders: TransportOrder[];
}

const ExportShippingNoticesDialogBody = (props: ExportShippingNoticesDialogBodyProps) => {
    const getInitialActiveShipmentId = (selectedTransportOrders: SelectedTransportOrder[]) =>
        selectedTransportOrders.length > 0 ? selectedTransportOrders[0].shipmentId : undefined;
    const [activeShipmentId, setActiveShipmentId] = useState<string | undefined>(
        getInitialActiveShipmentId(props.selectedTransportOrders),
    );
    const dunsNumber = useDunsNumberFromPath();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        setActiveShipmentId(getInitialActiveShipmentId(props.selectedTransportOrders));
    }, [props.selectedTransportOrders]);

    return (
        <div>
            <p>
                <FormattedMessage id={'webedi.shipment.export.dialog.description.paragraph1'} />
            </p>
            <p>
                <FormattedMessage id={'webedi.shipment.export.dialog.description.paragraph2'} />
            </p>
            {activeShipmentId && (
                <DataTabs
                    activeKey={activeShipmentId}
                    onSelectTab={(tabKey: string) => setActiveShipmentId(tabKey)}
                    className={'margin-top-20'}
                >
                    {props.selectedTransportOrders.map((it) => {
                        const correspondingTransportOrder = props.transportOrders.find(
                            (transportOrder) => transportOrder.shipment?.id === it.shipmentId,
                        );
                        return (
                            <DataTab
                                key={it.shipmentId}
                                tabKey={it.shipmentId}
                                title={
                                    <ShipmentLink
                                        shipment={correspondingTransportOrder?.shipment!}
                                        dunsNumber={dunsNumber}
                                        isActive={activeShipmentId === it.shipmentId}
                                    />
                                }
                            >
                                {correspondingTransportOrder?.shipment ? (
                                    <ShipmentDetails shipment={correspondingTransportOrder.shipment} />
                                ) : null}
                            </DataTab>
                        );
                    })}
                </DataTabs>
            )}
        </div>
    );
};

interface ExportShippingNoticesDialogFooterProps {
    onCancel: () => void;
    onConfirm: () => void;
    selectedTransportOrderCount: number;
    confirmDisabled: boolean;
}

const ExportShippingNoticesDialogFooter = (props: ExportShippingNoticesDialogFooterProps) => (
    <div className={'btn-toolbar pull-right'}>
        <button className={'CancelButton btn btn-default'} onClick={props.onCancel}>
            <FormattedMessage id={'webedi.label.cancel'} />
        </button>
        <button
            className={`ConfirmationButton btn btn-primary`}
            onClick={() => props.onConfirm()}
            disabled={props.confirmDisabled}
        >
            <FormattedMessage
                id={'webedi.transportOrders.overview.table.batch.menu.exportASN'}
                values={{ number: props.selectedTransportOrderCount }}
            />
        </button>
    </div>
);
