import { accessToken } from '../../../configuration/tokenHandling/accessToken';

export const getRequest = (): RequestInit => ({
    method: 'GET',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
    },
});

export const postRequest = (body: object): RequestInit => ({
    method: 'POST',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
});

export const putRequest = (body: object, additionalHeaders: Record<string, string> = {}): RequestInit => ({
    method: 'PUT',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...additionalHeaders,
    },
    body: JSON.stringify(body),
});

const undefinedWithNullReplacer = (_key: unknown, value: unknown): unknown =>
    typeof value === 'undefined' ? null : value;

export const patchRequest = (body: object, useUndefinedWithNullReplacer: boolean = false): RequestInit => ({
    method: 'PATCH',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: useUndefinedWithNullReplacer ? JSON.stringify(body, undefinedWithNullReplacer) : JSON.stringify(body),
});

export const deleteRequest = (): RequestInit => ({
    method: 'DELETE',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
        Accept: 'application/json',
    },
});
