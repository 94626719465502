import { SelectOption } from '@rio-cloud/rio-uikit';
import { Field, useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { SelectInput } from '../../../common/form/SelectInput';
import { TextInput } from '../../../common/form/TextInput';
import { fieldNames } from '../courierOrderIntentDialog.types';

export type ServiceLevelOption = SelectOption & { requiresApproval: boolean };

interface ServiceLevelSelectionProps {
    initialOptions?: ServiceLevelOption[];
}

export const ServiceLevelSelection = (props: ServiceLevelSelectionProps) => {
    const { initialOptions } = props;
    const fieldName = fieldNames.serviceLevelSelection;
    const field = useField(fieldName, { subscription: { value: true } });
    const value = field.input.value;

    const selectedOption = initialOptions?.find((option) => option.id === value);

    return (
        <div className='display-flex flex-column align-items-center'>
            <div className='display-flex flex-column padding-20 border-style-solid border-width-1 border-color-light bg-lightest width-75pct'>
                <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                    <label className='width-50pct text-size-14' htmlFor={fieldName}>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.secondStep.label.serviceLevel'}
                        />
                        {' *'}
                    </label>
                    <div className='form-group margin-0 flex-1-1 '>
                        <Field
                            name={fieldName}
                            id={fieldName}
                            // @ts-ignore
                            component={SelectInput}
                            options={initialOptions}
                            initialValue={selectedOption?.id}
                        />
                    </div>
                </div>
                {selectedOption?.requiresApproval && (
                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14' htmlFor={fieldNames.approvingPerson}>
                            <FormattedMessage
                                id={'webedi.dispatchProposals.expressParcel.dialog.secondStep.label.approvingPerson'}
                            />{' '}
                            *
                        </label>
                        <div className='form-group margin-0 flex-1-1 '>
                            <Field
                                name={fieldNames.approvingPerson}
                                className='form-control'
                                id={fieldNames.approvingPerson}
                                type='text'
                                component={TextInput}
                                initialValue={''}
                            />
                        </div>
                    </div>
                )}
                {selectedOption?.requiresApproval && (
                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14' htmlFor={fieldNames.approvingCostCenter}>
                            <FormattedMessage
                                id={
                                    'webedi.dispatchProposals.expressParcel.dialog.secondStep.label.approvingCostCenter'
                                }
                            />{' '}
                            *
                        </label>
                        <div className='form-group margin-0 flex-1-1 '>
                            <Field
                                name={fieldNames.approvingCostCenter}
                                className='form-control'
                                id={fieldNames.approvingCostCenter}
                                type='text'
                                component={TextInput}
                                initialValue={''}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
