// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const CustomsValueCodec = t.type({
    amount: t.number,
    currency_code: t.string,
});

export const PreferenceAuthorizationCodec = t.intersection([
    t.type({
        text: t.string,
    }),
    t.partial({
        language: t.string,
    }),
]);

export const CustomsCodec = t.partial({
    value: CustomsValueCodec,
    clearance: t.boolean,
    preference_authorization: PreferenceAuthorizationCodec,
});

export type ApiCustoms = t.TypeOf<typeof CustomsCodec>;
export type ApiPreferenceAuthorization = t.TypeOf<typeof PreferenceAuthorizationCodec>;
export type ApiCustomsValue = t.TypeOf<typeof CustomsValueCodec>;
