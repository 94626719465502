import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';
import { ReactNode, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { reportErrorToSentry } from '../../../../../../../configuration/setup/sentry';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../../../hooks/useImage';
import { ValidPackagingMaterial } from '../../../../../reducers/packaging/types';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import {
    GroupedHandlingUnits,
    PackagingStep,
    UpdateHomogeneousAdditionalPackagingConfigurationPayload,
} from '../../../../../reducers/shipments/packaging.types';
import {
    getPackagingMaterialForPackagingConfiguration,
    getSelectedGroupedHandlingUnits,
} from '../../../../../selectors/packaging/Packaging.selector';
import { getSelectedShipmentId } from '../../../../../selectors/shipments/Shipments.selector';
import { Tooltip } from '../../../../common/Tooltip';
import { CheckboxInput } from '../../../../common/form/CheckboxInput';
import { NumberInput } from '../../../../common/form/NumberInput';
import { FieldErrorType, FormErrors } from '../../../../common/form/types';
import { formIds, formPropertyNamesConfigurationStep } from '../InnerPackaging/InnerPackagingFormConfig';
import { ManyHandlingUnitsWarning } from '../ManyHandlingUnitsWarning';
import { PackagedArticlesInfoTooltip } from '../PackagedArticlesInfoTooltip';
import { SplitView } from '../SplitView';
import { PackagedPackages } from './PackagedPackages';
import { UnpackagedPackages } from './UnpackagedPackages';

export const HOMOGENEOUS_PACKAGING_CONTENT_TEST_ID = 'HOMOGENEOUS_PACKAGING_CONTENT_TEST_ID';
export const HOMOGENEOUS_PACKAGING_LAYERS_PER_HANDLING_UNIT_LABEL_TEST_ID =
    'HOMOGENEOUS_PACKAGING_LAYERS_PER_HANDLING_UNIT_LABEL_TEST_ID';
export const HOMOGENEOUS_PACKAGING_CONTENT_PER_LAYER_LABEL_TEST_ID =
    'HOMOGENEOUS_PACKAGING_CONTENT_PER_LAYER_LABEL_TEST_ID';
export const HOMOGENEOUS_PACKAGING_LAYERS_PER_HANDLING_UNIT_INPUT_TEST_ID =
    'HOMOGENEOUS_PACKAGING_LAYERS_PER_HANDLING_UNIT_INPUT_TEST_ID';
export const HOMOGENEOUS_PACKAGING_CONTENT_PER_LAYER_INPUT_TEST_ID =
    'HOMOGENEOUS_PACKAGING_CONTENT_PER_LAYER_INPUT_TEST_ID';
export const HOMOGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_TEST_ID =
    'HOMOGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_TEST_ID';
export const HOMOGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_CONTAINER_TEST_ID =
    'HOMOGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_CONTAINER_TEST_ID';

export const HomogeneousConfigurationFormId = {
    form: 'homogeneousPackagingConfirmationDialog',
    calculateNumberOfHandlingUnits: 'calculateNumberOfHandlingUnits',
    layersPerHandlingUnit: 'layersPerHandlingUnit',
    contentPerLayer: 'numberOfLayers',
    numberOfHandlingUnits: 'numberOfHandlingUnits',
    packagingMaterial: 'packagingMaterial',
};

export interface HomogeneousPackagingConfigurationFormValues {
    calculateNumberOfHandlingUnits: boolean;
    layersPerHandlingUnit?: number;
    contentPerLayer?: number;
    numberOfHandlingUnits?: number;
    amountOfContentToPack: number;
    packagedAmountOfHandlingUnit?: number;
}

const formPropertyNames: Record<
    keyof HomogeneousPackagingConfigurationFormValues,
    keyof HomogeneousPackagingConfigurationFormValues
> = {
    calculateNumberOfHandlingUnits: 'calculateNumberOfHandlingUnits',
    layersPerHandlingUnit: 'layersPerHandlingUnit',
    contentPerLayer: 'contentPerLayer',
    numberOfHandlingUnits: 'numberOfHandlingUnits',
    amountOfContentToPack: 'amountOfContentToPack',
    packagedAmountOfHandlingUnit: 'packagedAmountOfHandlingUnit',
};

const calculator = createDecorator({
    field: [
        formPropertyNames.layersPerHandlingUnit,
        formPropertyNames.contentPerLayer,
        formPropertyNames.calculateNumberOfHandlingUnits,
        formPropertyNames.numberOfHandlingUnits,
    ],
    updates: {
        [formPropertyNames.numberOfHandlingUnits]: (_, allValues) => {
            const formValues = allValues as HomogeneousPackagingConfigurationFormValues | undefined;
            if (!formValues?.calculateNumberOfHandlingUnits) {
                return formValues?.numberOfHandlingUnits;
            }
            if (
                formValues?.layersPerHandlingUnit &&
                formValues?.layersPerHandlingUnit > 0 &&
                formValues?.contentPerLayer &&
                formValues?.contentPerLayer > 0
            ) {
                return Math.ceil(
                    formValues.amountOfContentToPack / (formValues.layersPerHandlingUnit * formValues.contentPerLayer),
                );
            }
            return undefined;
        },
        [formPropertyNames.packagedAmountOfHandlingUnit]: (_, allValues) => {
            const formValues = allValues as HomogeneousPackagingConfigurationFormValues | undefined;
            if (formValues?.amountOfContentToPack) {
                if (
                    formValues.layersPerHandlingUnit &&
                    formValues.contentPerLayer &&
                    formValues.numberOfHandlingUnits
                ) {
                    const totalPackageableHandlingUnits =
                        formValues.layersPerHandlingUnit *
                        formValues.contentPerLayer *
                        formValues.numberOfHandlingUnits;
                    if (totalPackageableHandlingUnits >= formValues.amountOfContentToPack) {
                        return formValues.amountOfContentToPack;
                    } else {
                        return totalPackageableHandlingUnits;
                    }
                }
                return 0;
            }
            return undefined;
        },
    },
}) as Decorator<HomogeneousPackagingConfigurationFormValues, Partial<HomogeneousPackagingConfigurationFormValues>>;

export enum HomogeneousPackagingConfigurationErrorType {
    CONTAINS_EMPTY_HANDLING_UNITS = 'webedi.packaging.form.error.containsEmptyHandlingUnits',
}

type HomogeneousPackagingFormErrors = FormErrors<
    HomogeneousPackagingConfigurationFormValues,
    HomogeneousPackagingConfigurationErrorType
>;

export const validateForm = ({
    layersPerHandlingUnit,
    contentPerLayer,
    numberOfHandlingUnits,
    amountOfContentToPack,
}: HomogeneousPackagingConfigurationFormValues): HomogeneousPackagingFormErrors => {
    const validationResult: HomogeneousPackagingFormErrors = {};

    if (layersPerHandlingUnit === undefined || layersPerHandlingUnit <= 0) {
        validationResult.layersPerHandlingUnit = FieldErrorType.NON_POSITIVE_NUMBER;
    }
    if (contentPerLayer === undefined || contentPerLayer <= 0) {
        validationResult.contentPerLayer = FieldErrorType.NON_POSITIVE_NUMBER;
    }
    if (numberOfHandlingUnits === undefined || numberOfHandlingUnits <= 0) {
        validationResult.numberOfHandlingUnits = FieldErrorType.NON_POSITIVE_NUMBER;
    }

    if (
        layersPerHandlingUnit !== undefined &&
        layersPerHandlingUnit > 0 &&
        contentPerLayer !== undefined &&
        contentPerLayer > 0 &&
        numberOfHandlingUnits !== undefined &&
        numberOfHandlingUnits > 0
    ) {
        const quantityValuePerHandlingUnit = contentPerLayer * layersPerHandlingUnit;
        const totalPackagingSpace = numberOfHandlingUnits * contentPerLayer * layersPerHandlingUnit;
        if (totalPackagingSpace - amountOfContentToPack >= quantityValuePerHandlingUnit) {
            validationResult.numberOfHandlingUnits =
                HomogeneousPackagingConfigurationErrorType.CONTAINS_EMPTY_HANDLING_UNITS;
        }
    }

    return validationResult;
};

export const HomogeneousPackagingConfiguration = () => {
    const dispatch = useAppDispatch();
    const groupedHandlingUnitsToPack: GroupedHandlingUnits[] = useAppSelector(getSelectedGroupedHandlingUnits);
    const selectedShipmentId = useAppSelector(getSelectedShipmentId);
    const packagingMaterialToBePackaged = useAppSelector(getPackagingMaterialForPackagingConfiguration);

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(HomogeneousConfigurationFormId.form));
    }, [dispatch]);

    if (groupedHandlingUnitsToPack.length !== 1) {
        reportErrorToSentry(
            new Error('Try to render homogeneous packaging with multiple grouped handling unit to pack'),
        );
        return <div />;
    }
    const groupedHandlingUnitToPack = groupedHandlingUnitsToPack[0];

    const initialValues: HomogeneousPackagingConfigurationFormValues = {
        calculateNumberOfHandlingUnits: true,
        layersPerHandlingUnit: undefined,
        contentPerLayer: undefined,
        numberOfHandlingUnits: undefined,
        amountOfContentToPack: groupedHandlingUnitToPack.quantity,
        packagedAmountOfHandlingUnit: 0,
    };

    const onSubmitHandler = (values: HomogeneousPackagingConfigurationFormValues) => {
        if (selectedShipmentId === undefined) {
            throw new Error('shipment must be selected before submitting form values. This should never happen');
        }
        const validatedValues = values as Required<HomogeneousPackagingConfigurationFormValues>;
        const handlingUnitDescription = packagingMaterialToBePackaged.name;
        const packagingConfiguration: UpdateHomogeneousAdditionalPackagingConfigurationPayload = {
            contentPerHandlingUnit: {
                hashOfContainedHandlingUnitGroup: groupedHandlingUnitToPack.hash,
                handlingUnitType: groupedHandlingUnitToPack.type,
                handlingUnitDescription: groupedHandlingUnitToPack.description,
                quantity: validatedValues.contentPerLayer * validatedValues.layersPerHandlingUnit,
                handlingUnit: {
                    type: groupedHandlingUnitToPack.type,
                    description: groupedHandlingUnitToPack.description,
                    tareWeightInKg: groupedHandlingUnitToPack.handlingUnit.tareWeightInKg,
                    ownership: groupedHandlingUnitToPack.handlingUnit.ownership,
                    isReusable: groupedHandlingUnitToPack.handlingUnit.isReusable,
                    stackingFactor: groupedHandlingUnitToPack.handlingUnit.stackingFactor,
                },
            },
            handlingUnitType: packagingMaterialToBePackaged.boxCode,
            handlingUnitDescription,
            numberOfHandlingUnits: validatedValues.numberOfHandlingUnits,
            numberOfPackagedHandlingUnits: validatedValues.packagedAmountOfHandlingUnit,
            numberOfHandlingUnitsPerLayer: validatedValues.contentPerLayer,
            handlingUnit: {
                type: packagingMaterialToBePackaged.boxCode,
                description: packagingMaterialToBePackaged.name,
                tareWeightInKg: packagingMaterialToBePackaged.tareWeightInKg,
                ownership: packagingMaterialToBePackaged.ownership,
                isReusable: packagingMaterialToBePackaged.isReusable,
                stackingFactor: packagingMaterialToBePackaged.stackingFactor,
            },
        };
        dispatch(packagingSlice.actions.addHomogeneousAdditionalPackagingConfiguration(packagingConfiguration));
        dispatch(packagingSlice.actions.setPackagingStep(PackagingStep.AUXILIARY_CONFIGURATION));
    };

    return (
        <Form<HomogeneousPackagingConfigurationFormValues>
            onSubmit={onSubmitHandler}
            initialValues={initialValues}
            keepDirtyOnReinitialize={true}
            decorators={[calculator]}
            validate={validateForm}
            render={({ handleSubmit, values }) => {
                const groupedHandlingUnitsPackagingInformation = [
                    {
                        packagedAmount: values.packagedAmountOfHandlingUnit,
                        toBePackagedAmount: values.amountOfContentToPack,
                        packagingMaterialBoxCode: groupedHandlingUnitToPack.type,
                    },
                ];

                return (
                    <div className={'display-flex flex-column align-items-center width-100pct'}>
                        <form
                            id={HomogeneousConfigurationFormId.form}
                            onSubmit={handleSubmit}
                            className={'width-100pct'}
                        >
                            <SplitView
                                left={{
                                    content: (
                                        <UnpackagedPackages
                                            groupedHandlingUnits={groupedHandlingUnitsPackagingInformation}
                                        />
                                    ),
                                    alignItemsCenter: true,
                                }}
                                main={{
                                    content: (
                                        <HomogeneousPackagingConfigurationContent
                                            groupedHandlingUnitToPack={groupedHandlingUnitToPack}
                                            packagingMaterialToBePackaged={packagingMaterialToBePackaged}
                                            formValues={values}
                                        />
                                    ),
                                    alignItemsCenter: true,
                                    renderAsColumn: true,
                                }}
                                right={{
                                    content: (
                                        <PackagedPackages
                                            groupedHandlingUnits={groupedHandlingUnitsPackagingInformation}
                                            outerPackagingBoxCode={packagingMaterialToBePackaged!.boxCode}
                                        />
                                    ),
                                    alignItemsCenter: true,
                                }}
                                dataTestId={HOMOGENEOUS_PACKAGING_CONTENT_TEST_ID}
                            />
                        </form>
                    </div>
                );
            }}
        />
    );
};

const HomogeneousPackagingConfigurationContent = ({
    groupedHandlingUnitToPack,
    packagingMaterialToBePackaged,
    formValues,
}: {
    groupedHandlingUnitToPack: GroupedHandlingUnits;
    packagingMaterialToBePackaged: ValidPackagingMaterial;
    formValues: HomogeneousPackagingConfigurationFormValues;
}) => {
    const layerImage = useImage('layer');
    const layersImage = useImage('layers');
    const multiPackageImage = useImage('multiPackage');

    const joinedArticleNumbers = groupedHandlingUnitToPack.packagedArticlesInfo
        .map((article) => article.articleNumberBuyer)
        .join(', ');
    return (
        <>
            <span className={'text-size-18 text-center margin-bottom-25'}>
                <FormattedMessage id={'webedi.packaging.homogeneousPackaging.title'} />
            </span>
            <span className={'margin-bottom-20 text-center'}>
                <FormattedMessage
                    id={'webedi.packaging.homogeneousPackaging.explanation'}
                    values={{
                        packageToBePackaged: groupedHandlingUnitToPack.type,
                        handlingUnit: packagingMaterialToBePackaged.boxCode,
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
                {':'}
            </span>
            <div>
                <div className={'display-flex flex-row align-items-center'}>
                    <div className={'flex-column width-60pct text-center'}>
                        <img src={layersImage} className={'width-50pct height-50pct'} alt={'layers'} />
                    </div>
                    <div className={`flex-column width-60pct counter color-highlight`}>
                        <div className={'display-flex flex-column align-items-baseline'} data-count={'3'}>
                            <label
                                data-testid={HOMOGENEOUS_PACKAGING_LAYERS_PER_HANDLING_UNIT_LABEL_TEST_ID}
                                className={'text-size-14 text-color-darkest'}
                                htmlFor={HomogeneousConfigurationFormId.layersPerHandlingUnit}
                            >
                                <FormattedMessage
                                    id={'webedi.packaging.homogeneousPackaging.layersPerHandlingUnit.label'}
                                    values={{
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                        handlingUnit: `${packagingMaterialToBePackaged.boxCode}${packagingMaterialToBePackaged.name ? ` - ${packagingMaterialToBePackaged.name}` : ''}`,
                                    }}
                                />
                            </label>
                            <Field
                                id={HomogeneousConfigurationFormId.layersPerHandlingUnit}
                                name={formPropertyNames.layersPerHandlingUnit}
                                component={NumberInput}
                                className={'form-control'}
                                forbidScientificNotation
                                min={1}
                                outerClassName={'width-100pct'}
                                errorMessageClassName={'text-size-10'}
                                disabled={false}
                                parse={(value) => value && parseInt(value, 10)}
                                data-testid={HOMOGENEOUS_PACKAGING_LAYERS_PER_HANDLING_UNIT_INPUT_TEST_ID}
                            />
                        </div>
                    </div>
                </div>
                <hr className={'padding-top-10 padding-bottom-10'} />
                <div className={'display-flex flex-row align-items-center'}>
                    <div className={'display-flex flex-row align-items-center'}>
                        <div className={'flex-column width-60pct text-center'}>
                            <img src={layerImage} className={'width-50pct height-50pct'} alt={'layer'} />
                        </div>
                        <div className={`flex-column width-60pct counter color-highlight`}>
                            <div className={'display-flex flex-column align-items-baseline'} data-count={'4'}>
                                <label
                                    data-testid={HOMOGENEOUS_PACKAGING_CONTENT_PER_LAYER_LABEL_TEST_ID}
                                    className={'text-size-14 text-color-darkest'}
                                    htmlFor={HomogeneousConfigurationFormId.contentPerLayer}
                                >
                                    <FormattedMessage
                                        id={'webedi.packaging.homogeneousPackaging.contentPerLayer.label'}
                                        values={{
                                            b: (chunks: ReactNode) => <b>{chunks}</b>,
                                            packageToBePackaged: `${groupedHandlingUnitToPack.type}${groupedHandlingUnitToPack.description ? ` - ${groupedHandlingUnitToPack.description}` : ''}`,
                                        }}
                                    />
                                    &nbsp;
                                    <PackagedArticlesInfoTooltip
                                        packagedArticlesInfo={groupedHandlingUnitToPack.packagedArticlesInfo}
                                    />
                                    {joinedArticleNumbers && (
                                        <div className={'text-size-12 text-color-gray ellipsis-1'}>
                                            <Tooltip text={joinedArticleNumbers} placement={'bottom'}>
                                                <span>
                                                    <FormattedMessage id={'webedi.packaging.contents'} />
                                                    {': '}
                                                    {joinedArticleNumbers}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )}
                                </label>
                                <Field
                                    id={HomogeneousConfigurationFormId.contentPerLayer}
                                    name={formPropertyNames.contentPerLayer}
                                    component={NumberInput}
                                    className={'form-control'}
                                    forbidScientificNotation
                                    min={1}
                                    outerClassName={'width-100pct'}
                                    errorMessageClassName={'text-size-10'}
                                    disabled={false}
                                    parse={(value) => value && parseInt(value, 10)}
                                    data-testid={HOMOGENEOUS_PACKAGING_CONTENT_PER_LAYER_INPUT_TEST_ID}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={'padding-top-10 padding-bottom-10'} />
                <div className={'display-flex flex-row justify-content-center margin-bottom-20'}>
                    <Field
                        id={formIds.calculateNumberOfHandlingUnits}
                        name={formPropertyNamesConfigurationStep.calculateNumberOfHandlingUnits}
                        component={CheckboxInput}
                        type={'checkbox'}
                        tabIndex={-1}
                    >
                        <FormattedMessage id={'webedi.packaging.inner.calculateNumberOfHandlingUnits'} />
                    </Field>
                </div>
                <div className={'display-flex flex-row align-items-center'}>
                    <div className={'flex-column width-60pct text-center'}>
                        <img src={multiPackageImage} className={'width-60pct height-60pct'} alt={'multiPackage'} />
                    </div>
                    <div
                        className={`flex-column width-60pct counter color-highlight ${formValues.calculateNumberOfHandlingUnits ? 'opacity-40' : ''}`}
                    >
                        <div
                            className={'display-flex flex-column align-items-baseline'}
                            data-count={'5'}
                            data-testid={HOMOGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_CONTAINER_TEST_ID}
                        >
                            <label
                                className={'text-size-14 text-color-darkest'}
                                htmlFor={HomogeneousConfigurationFormId.numberOfHandlingUnits}
                            >
                                <FormattedMessage
                                    id={'webedi.packaging.homogeneousPackaging.numberOfHandlingUnits.label'}
                                    values={{
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                        handlingUnit: packagingMaterialToBePackaged?.name,
                                    }}
                                />
                            </label>
                            <Field
                                id={HomogeneousConfigurationFormId.numberOfHandlingUnits}
                                name={formPropertyNames.numberOfHandlingUnits}
                                component={NumberInput}
                                className={'form-control'}
                                forbidScientificNotation
                                min={1}
                                outerClassName={'width-100pct'}
                                errorMessageClassName={'text-size-10'}
                                disabled={formValues.calculateNumberOfHandlingUnits}
                                parse={(value) => value && parseInt(value, 10)}
                                data-testid={HOMOGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_TEST_ID}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ManyHandlingUnitsWarning numberOfHandlingUnits={formValues.numberOfHandlingUnits} />
        </>
    );
};
