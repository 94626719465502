import { ReactNode } from 'react';
import { MeasurementUnitCode, Quantity } from '../../domain/common.types';
import { AuxiliaryHandlingUnitGroup } from '../../reducers/auxiliaryPackaging.types';
import {
    DeliverySchedule,
    DeliveryScheduleWorkflow,
    PackagingStepType,
    ScheduledArticleDetails,
    ScheduledQuantity,
} from '../../reducers/deliverySchedules/types';
import { Ownership } from '../../reducers/shipments/packaging.types';
import { Party } from '../../reducers/types';
import { HandlingUnitCategory } from '../common/PackagingCommon';

export interface DeliveryScheduleDetailsProps {
    deliverySchedule?: DeliverySchedule;
}

export interface DeliveryScheduleDetailsTableProps {
    schedulingData: ScheduledQuantity[];
    hasZeroDemand: boolean;
    workflow: DeliveryScheduleWorkflow;
}

export interface FormattedManufacturingCompanyProps {
    manufacturingCompany: Party;
}

export interface MostRecentArrivalOfGoodsProps {
    mostRecentArrivalOfGoods: ScheduledArticleDetails['mostRecentArrivalsOfGoods'];
    measurementUnitCode: MeasurementUnitCode;
}

export interface ArticleMasterDataFormProps {
    deliverySchedule: DeliverySchedule;
}

export interface PackagingTemplateRowContentProps {
    description: ReactNode | string;
    category: string;
    categoryTooltip?: ReactNode | string;
    classNameDescriptionElement?: string;
    classNameCategoryElement?: string;
    iteration: number;
    quantity?: string;
    fourthColumn?: string;
    fifthColumn?: string;
}

export type PackageableInPackagingTemplateConfig =
    | HandlingUnitInPackagingTemplateConfig
    | ArticleInPackagingTemplateConfig;

export interface HandlingUnitInPackagingTemplateConfigCommon {
    typeOfHandlingUnit: string;
    type: PackagingStepType;
    description?: string;
    category: HandlingUnitCategory;
    contents: PackageableInPackagingTemplateConfig[];
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    numberOfContainers: number;
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
    tareWeightInKg: number;
}

export type HandlingUnitInPackagingTemplateConfig =
    | HandlingUnitInPackagingTemplateConfigForInnerSteps
    | HandlingUnitInPackagingTemplateConfigForHomogeneousSteps;

export interface HandlingUnitInPackagingTemplateConfigForInnerSteps
    extends HandlingUnitInPackagingTemplateConfigCommon {
    quantity: Quantity;
}

export interface HandlingUnitInPackagingTemplateConfigForHomogeneousSteps
    extends HandlingUnitInPackagingTemplateConfigCommon {
    numberOfHandlingUnitsPerLayer: number;
    numberOfLayersPerHandlingUnit: number;
}

export interface ArticleInPackagingTemplateConfig {
    articleNumber: string;
}

export interface HandlingUnitInPackagingTemplateConfigProps {
    handlingUnit: HandlingUnitInPackagingTemplateConfig;
    iteration: number;
}

export interface ArticleInPackagingTemplateConfigProps {
    articleHandlingUnitConfig: ArticleInPackagingTemplateConfig;
    iteration: number;
}

export interface AuxiliaryRowProps {
    auxiliaryHandlingUnitGroup: AuxiliaryHandlingUnitGroup;
    iteration: number;
}

export interface PackagingTemplateHandlingUnitExpanderProps {
    handlingUnitConfig: HandlingUnitInPackagingTemplateConfig;
    index: number;
    iteration: number;
    collapsed: boolean;
}

export const isArticleInPackagingTemplateConfig = (
    packageable: PackageableInPackagingTemplateConfig,
): packageable is ArticleInPackagingTemplateConfig =>
    (packageable as ArticleInPackagingTemplateConfig).articleNumber !== undefined;

export const isHandlingUnitInPackagingTemplateConfig = (
    packageable: PackageableInPackagingTemplateConfig,
): packageable is HandlingUnitInPackagingTemplateConfig =>
    (packageable as HandlingUnitInPackagingTemplateConfig).type !== undefined;
