import { isEmpty } from 'lodash';
import { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { useLazyCourierOrderValidationQuery } from '../../../api/dispatchProposal/courierOrderIntent/courierOrderIntentApi';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { courierOrderIntentSlice } from '../../../reducers/courierOrderIntent/CourierOrderIntentReducer';

export const OrderCourierButton = (props: {
    dispatchProposalId: string;
    enabled: boolean;
}) => {
    const dispatch = useAppDispatch();
    const duns = useDunsNumberFromPath();

    const [validateCourierOrder, validationData] = useLazyCourierOrderValidationQuery();

    const buttonClassNames = `btn btn-primary btn-component ${
        validationData.isFetching ? 'btn-loading' : ''
    } ${!props.enabled || validationData.isFetching ? 'disabled' : ''}`;

    const openCourierIntentDialog = async (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation(); // to prevent the Expander from collapsing
        const data = await validateCourierOrder({
            dispatchProposalId: props.dispatchProposalId,
            dunsNumber: duns ?? '',
        }).unwrap();

        if (isEmpty(data)) {
            dispatch(courierOrderIntentSlice.actions.startCourierOrderIntentCommissioning(props.dispatchProposalId));
        }
        dispatch(
            courierOrderIntentSlice.actions.setOrderValidationErrors({
                selectedDispatchProposalId: props.dispatchProposalId,
                orderValidationErrors: data,
            }),
        );
    };

    return (
        <div
            className={
                'display-flex justify-content-end align-items-center max-width-400 margin-right-20 padding-right-10'
            }
        >
            <button
                onClick={openCourierIntentDialog}
                disabled={!props.enabled || validationData.isFetching}
                className={buttonClassNames.trim()}
                type='button'
            >
                <span className={'rioglyph rioglyph-van'} />
                <FormattedMessage id={'webedi.dispatchProposals.overview.expander.header.courier'} />
            </button>
        </div>
    );
};
