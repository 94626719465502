import { config } from '../../../../config';
import {
    HandlingUnitGroupUpdate,
    PackagingTemplateConfigToApply,
    PackagingTemplatesDictionary,
} from '../../reducers/shipments/packaging.types';
import { DeliveryNote, LabelNumberData } from '../../reducers/shipments/types';
import { decodeJson, jsonOrReject } from '../apiUtils';
import { getRequest, postRequest } from '../requests';
import { ApplicablePackagingTemplatesCodec, PackagingConfigFromTemplatesCodec } from './packaging.types';
import {
    mapPackagingConfigFromTemplates,
    mapPackagingTemplateConfigsToApplyToApi,
    mapPackagingTemplatesForShipment,
} from './packagingMapper';
import { LabelNumberDataCodec } from './shipment.types';
import { mapLabelNumberData } from './shipmentMapper';

export const fetchPackagingTemplatesForShipment = (
    dunsNumber: string,
    shipmentId: string,
): Promise<PackagingTemplatesDictionary> => {
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}/packaging-templates`,
        getRequest(),
    )
        .then(jsonOrReject)
        .then(decodeJson(ApplicablePackagingTemplatesCodec))
        .then(mapPackagingTemplatesForShipment);
};

export const fetchPackagingConfigurationForTemplates = (
    dunsNumber: string,
    shipmentId: string,
    packagingTemplateConfigs: PackagingTemplateConfigToApply[],
    load: DeliveryNote[],
): Promise<HandlingUnitGroupUpdate[]> => {
    const body = mapPackagingTemplateConfigsToApplyToApi(packagingTemplateConfigs);
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}/packaging-templates`,
        postRequest(body),
    )
        .then(jsonOrReject)
        .then(decodeJson(PackagingConfigFromTemplatesCodec))
        .then((packagingConfigFromTemplates) => mapPackagingConfigFromTemplates(packagingConfigFromTemplates, load));
};

export const getNextLabelNumberData = async (dunsNumber: string): Promise<LabelNumberData> => {
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipment-number-sequences/next-label-number`,
        getRequest(),
    )
        .then(jsonOrReject)
        .then(decodeJson(LabelNumberDataCodec))
        .then(mapLabelNumberData);
};
