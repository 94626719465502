import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { PackagingTemplate } from '../../reducers/deliverySchedules/types';
import { decodeJson } from '../apiUtils';
import { PackagingTemplatesCodec } from './packagingTemplate.types';
import { mapPackagingTemplates } from './packagingTemplateMapper';

interface GetPackagingTemplatesParameters {
    dunsNumber: string;
    deliveryScheduleId: string;
}

const transformGetPackagingTemplatesDataResponse = (response: unknown): PackagingTemplate[] => {
    const decoded = decodeJson(PackagingTemplatesCodec)(response);
    return mapPackagingTemplates(decoded);
};

export const packagingTemplateApi = createApi({
    reducerPath: 'packagingTemplateApi',
    tagTypes: ['PackagingTemplates'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPackagingTemplates: builder.query<PackagingTemplate[], GetPackagingTemplatesParameters>({
            query: ({ dunsNumber, deliveryScheduleId }) => ({
                url: `/shippers/${dunsNumber}/packaging-templates`,

                params: { delivery_schedule_id: deliveryScheduleId },
            }),
            transformResponse: (response: unknown) => transformGetPackagingTemplatesDataResponse(response),
        }),
    }),
});

export const { useGetPackagingTemplatesQuery } = packagingTemplateApi;
