import { Action } from 'redux';
import { MeasurementUnitCode, Partner, Quantity } from '../../domain/common.types';
import { AuxiliaryHandlingUnitGroup } from '../auxiliaryPackaging.types';
import { HandlingUnitPackagingMaterial, Ownership } from '../shipments/packaging.types';
import { ArticleMasterData, Dimensions, ExtendedArticleMasterData } from '../shipments/types';
import { ItemShortDescription, Party } from '../types';

export enum ResponsibleAgencyCode {
    BUYER = 'BUYER',
    SELLER = 'SELLER',
    DUNS = 'DUNS',
}

export interface Location {
    locationNameCode: string;
    responsibleAgencyCode: ResponsibleAgencyCode;
    locationName?: string;
}

export interface ShipTo {
    shipTo: Party;
    placeOfDischarge: Location;
    placeOfDelivery: Location;
    placeOfTranshipment?: Location;
}

export enum ItemTypeIdentificationCode {
    BUYERS_ITEM_NUMBER = 'BUYERS_ITEM_NUMBER',
}

export interface ItemNumberIdentification {
    itemIdentifier: string;
    itemTypeIdentificationCode: ItemTypeIdentificationCode;
}

export interface LineItemIdentifier {
    lineItemIdentifier?: string;
    itemNumberIdentification: ItemNumberIdentification;
}

export interface LineItem {
    lineItemIdentifier: LineItemIdentifier;
    itemShortDescriptions: ItemShortDescription[];
}

export interface DeliveryInstructionNumber {
    referenceIdentifier: string;
    referenceDate?: string;
}

export interface OrderNumber {
    referenceIdentifier: string;
}

export interface CumulativeQuantityDetails {
    quantity: number;
    date?: string;
}

export enum DeliveryPlanCommitmentLevelCode {
    FIRM = 'FIRM',
    PLANNING_SLASH_FORECAST = 'PLANNING_SLASH_FORECAST',
    IMMEDIATE = 'IMMEDIATE',
    REFERENCE_TO_COMMERCIAL_AGREEMENT_BETWEEN_PARTNERS = 'REFERENCE_TO_COMMERCIAL_AGREEMENT_BETWEEN_PARTNERS',
}

export enum RequestCondition {
    STANDARD = 'STANDARD',
    IMMEDIATE = 'IMMEDIATE',
    BACKORDER = 'BACKORDER',
}

export interface ScheduledPeriod {
    earliestDeliveryDate: string;
    latestDeliveryDate: string;
    type: DeliveryType;
}

export interface ScheduledDate {
    scheduledDate: string;
    type: DeliveryType;
}

export interface ScheduledDateTime {
    scheduledDateTime: string;
    type: DeliveryType;
}

export enum DeliveryType {
    SHIPMENT = 'SHIPMENT',
    DELIVERY = 'DELIVERY',
}

export enum ProcessIndicator {
    FAB_ML = 'FAB_ML',
    FAB_ED = 'FAB_ED',
    LAB_ED = 'LAB_ED',
    LAB_ML = 'LAB_ML',
    PAG_LAB = 'PAG_LAB',
    PAG_FAB = 'PAG_FAB',
    MAN_LAB = 'MAN_LAB',
}

export enum CommunicationAddressCodeQualifier {
    ELECTRONIC_MAIL = 'ELECTRONIC_MAIL',
    TELEFAX = 'TELEFAX',
    TELEPHONE = 'TELEPHONE',
}

export enum DespatchDocumentReferenceCodeQualifier {
    DESPATCH_ADVICE_NUMBER = 'DESPATCH_ADVICE_NUMBER',
    DESPATCH_NOTE_DOCUMENT_IDENTIFIER = 'DESPATCH_NOTE_DOCUMENT_IDENTIFIER',
    TRANSPORT_MEANS_JOURNEY_IDENTIFIER = 'TRANSPORT_MEANS_JOURNEY_IDENTIFIER',
}

export interface DespatchDocumentReference {
    referenceCodeQualifier: DespatchDocumentReferenceCodeQualifier;
    referenceIdentifier: string;
    referenceDocumentDate?: string;
}

export interface ReceivedQuantity {
    quantity: number;
    receiptDate?: string;
    despatchDocumentReferences: DespatchDocumentReference[];
}

export interface ScheduledQuantity {
    deliveryPlanCommitmentLevelCode: DeliveryPlanCommitmentLevelCode;
    quantity: number;
    outstandingQuantity: number;
    scheduledDateTime: ScheduledDate | ScheduledDateTime | ScheduledPeriod;
    processIndicator: ProcessIndicator;
    requestCondition: RequestCondition;
}

export interface CommunicationAddress {
    identifier: string;
    codeQualifier: CommunicationAddressCodeQualifier;
}

export interface SchedulingContact {
    id?: string;
    name?: string;
    communicationAddresses: CommunicationAddress[];
}

export interface ScheduledArticleDetails {
    lineItem: LineItem;
    measurementUnitCode: MeasurementUnitCode;
    deliveryInstructionNumberNew: DeliveryInstructionNumber;
    deliveryInstructionNumberOld?: DeliveryInstructionNumber;
    orderNumber: OrderNumber;
    cumulativeQuantityOrdered?: CumulativeQuantityDetails;
    cumulativeQuantityReceived?: CumulativeQuantityDetails;
    mostRecentArrivalsOfGoods: ReceivedQuantity[];
    schedulingData: ScheduledQuantity[];
    schedulingContact?: SchedulingContact;
}

export const isScheduledDate = (
    variableToCheck: ScheduledDate | ScheduledDateTime | ScheduledPeriod,
): variableToCheck is ScheduledDate => (variableToCheck as ScheduledDate).scheduledDate !== undefined;

export const isScheduledDateTime = (
    variableToCheck: ScheduledDate | ScheduledDateTime | ScheduledPeriod,
): variableToCheck is ScheduledDateTime => (variableToCheck as ScheduledDateTime).scheduledDateTime !== undefined;

export enum DeliveryScheduleFlag {
    NEW = 'NEW',
    MODIFIED = 'MODIFIED',
    INVENTORY_TAKEN = 'INVENTORY_TAKEN',
}

export enum DeliveryInstructionType {
    LAB = 'LAB',
    FAB = 'FAB',
}

export interface DeliveryInstructionReference {
    id: string;
    type: DeliveryInstructionType;
    ediFileId: string;
    deliveryInstructionNumber?: DeliveryInstructionNumber;
}

export enum DeliveryScheduleWorkflow {
    WEBSCM_CLASSIC = 'WEBSCM_CLASSIC',
    DISCOVERY_NEXT_GEN = 'DISCOVERY_NEXT_GEN',
}

export interface DeliverySchedule {
    id: string;
    partner: Partner;
    buyer: Party;
    seller: Party;
    shipFrom: Party;
    shipTo: ShipTo;
    scheduledArticleDetails: ScheduledArticleDetails;
    cumulativeQuantitySent: number;
    cumulativeQuantityInPreparation: number;
    articleMasterData?: ExtendedArticleMasterData;
    hasValidArticleMasterData: boolean;
    hasValidCumulativeQuantitySent: boolean;
    hasZeroDemand: boolean;
    flags: DeliveryScheduleFlag[];
    deliveryInstructionReferences: DeliveryInstructionReference[];
    latestDeliveryCallOffDate?: string;
    isSoftDeleted: boolean;
    manuallyCreated: boolean;
    workflow: DeliveryScheduleWorkflow;
    includeInDispatchProposalCreation: boolean;
}

export interface CumulativeQuantitySentOffset {
    dateTime: string;
    previousQuantity: number;
    newQuantity: number;
    creator: string;
}

export interface CumulativeQuantitySentOffsetCreationRequest {
    dunsNumberOwner: string;
    deliveryScheduleId: string;
    newQuantity: number;
}

export interface DeliverySchedulesQueryResult {
    deliverySchedules: DeliverySchedule[];
    totalCountOfMatchedDeliverySchedules: number;
    query: DeliverySchedulesQueryParameters;
}

export enum SelectedMetadataEntryId {
    ARTICLE_MASTER_DATA = 'ARTICLE_MASTER_DATA',
    MISSING_LISON = 'MISSING_LISON',
}

export type PackagingTemplateDictionary = { [key: string]: PackagingTemplate };

export interface DeliverySchedulesPaginationQuery {
    articleNumberSearchString: string;
    offset: number;
    limit: number;
}

export interface DeliverySchedulesQuery {
    dunsNumber?: string;
    params: DeliverySchedulesQueryParameters;
}

export interface DeliverySchedulesQueryParameters extends DeliverySchedulesFilterParams {
    q: string;
    offset: number;
    limit: number;
    sort: DeliverySchedulesSortParameters;
}

export interface DeliverySchedulesFilterParams {
    supplierIdentifier?: string;
    plantNumber?: string;
    unloadingPoint?: string;
    storagePlace?: string;
    orderNumber?: string;
    manufacturingCompany?: string;
    deliveryDateFrom?: string;
    excludeSoftDeleted: boolean;
}

export interface DeliverySchedulesSortParameters {
    sortBy: DeliveryScheduleSortParameterName;
    direction: SortDirection;
}

export enum DeliveryScheduleSortParameterName {
    ARTICLE_NUMBER_BUYER = 'ARTICLE_NUMBER_BUYER',
    LATEST_DELIVERY_CALL_OFF_DATE = 'LATEST_DELIVERY_CALL_OFF_DATE',
}

export enum SortDirection {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

export interface DeliverySchedulesState {
    deliverySchedules: DeliverySchedule[];
    totalCountOfMatchedDeliverySchedules: number;
    deliverySchedulesQuery: DeliverySchedulesQuery;
    filterValues: { [key: string]: string[] };
    isLoadingDeliverySchedules: boolean;
    isLoadingPackagingTemplates: boolean;
    isLoadingLisonPackagingTemplate: boolean;
    isLoadingCumulativeQuantitySentOffsets: boolean;
    isPostingCumulativeQuantitySentOffset: boolean;
    isSavingArticleMetadata: boolean;
    selectedDeliveryScheduleId?: string;
    selectedDeliverySchedulePackagingTemplates: PackagingTemplateDictionary;
    selectedDeliveryScheduleCumulativeQuantitySentOffsets: CumulativeQuantitySentOffset[];
    selectedMetadataEntryId?: string;
    showDeliveryScheduleDialog: boolean;
    form: {
        templateName?: string;
        templateStepsAdded: boolean;
        actionToBeConfirmed?: Action;
        isMetadataViewContentDirty: boolean;
        templateWizardState?: TemplateWizardState;
        templateStepConfiguration?: PackagingStepConfiguration;
        templateDimensions?: Dimensions;
    };
}

export interface PackagingTemplate {
    id: string;
    name: string;
    type: PackagingTemplateType;
    dunsNumberOwner: string;
    isValid: boolean;
    referencedDeliveryScheduleIds: string[];
    steps: PackagingStep[];
    dimensions: Dimensions;
    persisted: boolean;
}

export const isPackagingTemplate = (variableToCheck: MetadataEntry): variableToCheck is PackagingTemplate =>
    (variableToCheck as PackagingTemplate).steps !== undefined;

export interface TemplateWizardState {
    formId?: string;
    type: PackagingStepType;
    step?: number;
}

export interface MissingLisonPackagingTemplate {
    type: PackagingTemplateType.MISSING_LISON;
}

export type MetadataEntry = ArticleMasterData | PackagingTemplate | MissingLisonPackagingTemplate;

interface PackagingStepCommon {
    id: string;
    type: PackagingStepType;
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    prerequisites: PackagingStepCommon['id'][];
    typeOfHandlingUnit: string;
    descriptionOfHandlingUnit?: string;
    handlingUnit: HandlingUnitPackagingMaterial;
}

export enum PackagingTemplateType {
    LISON = 'LISON',
    MISSING_LISON = 'MISSING_LISON',
    HETEROGENEOUS = 'HETEROGENEOUS',
    HOMOGENEOUS = 'HOMOGENEOUS',
}

export enum PackagingStepType {
    HETEROGENEOUS = 'HETEROGENEOUS',
    HOMOGENEOUS = 'HOMOGENEOUS',
    INNER = 'INNER',
}

export interface HeterogeneousPackagingStep extends PackagingStepCommon {
    type: PackagingStepType.HETEROGENEOUS;
    content: HeterogeneousPackagingStepContent[];
}

export interface HeterogeneousPackagingStepContent {
    handlingUnitTypeToBePackaged: string;
    handlingUnitDescriptionToBePackaged?: string;
    numberOfHandlingUnitsPerHandlingUnit: number;
    handlingUnit: HandlingUnitPackagingMaterial;
}

export interface HomogeneousPackagingStep extends PackagingStepCommon {
    type: PackagingStepType.HOMOGENEOUS;
    handlingUnitTypeToBePackaged: string;
    handlingUnitDescriptionToBePackaged?: string;
    numberOfHandlingUnitsPerLayer: number;
    numberOfLayersPerHandlingUnit: number;
    layerStabilization: HomogeneousPackagingStepLayerStabilization;
}

export interface HomogeneousPackagingStepLayerStabilization {
    handlingUnitType: string;
    handlingUnitDescription?: string;
    required: boolean;
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
    tareWeightInKg: number;
}

export interface InnerPackagingStep extends PackagingStepCommon {
    type: PackagingStepType.INNER;
    articleNumberBuyer: string;
    quantity: Quantity;
}

export type InnerPackagingStepConfiguration = Omit<InnerPackagingStep, 'id' | 'auxiliaryPackaging'>;
export type NewInnerPackagingStepConfiguration = Omit<InnerPackagingStep, 'id' | 'auxiliaryPackaging' | 'quantity'>;
export type HomogeneousPackagingStepConfiguration = Omit<HomogeneousPackagingStep, 'id' | 'auxiliaryPackaging'>;
export type HeterogeneousPackagingStepConfiguration = Omit<HeterogeneousPackagingStep, 'id' | 'auxiliaryPackaging'>;
export type PackagingStepConfiguration =
    | InnerPackagingStepConfiguration
    | HomogeneousPackagingStepConfiguration
    | HeterogeneousPackagingStepConfiguration
    | NewInnerPackagingStepConfiguration;

export interface PackagingTemplateCreationRequest {
    name: string;
    type: PackagingTemplateType;
    referencedDeliveryScheduleIds: string[];
    steps: PackagingStep[];
    dimensions: Dimensions;
}

export interface PackagingTemplateUpdateRequest {
    name?: string;
    steps: PackagingStep[];
    dimensions: Dimensions;
}

export type PackagingStep = HeterogeneousPackagingStep | HomogeneousPackagingStep | InnerPackagingStep;

export interface AuxiliaryPackagingTemplatePayload {
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
}

export interface SupplierData {
    buyer: Party;
    seller: Party;
    shipFrom: Party;
    partner: Partner;
}

export interface UpdatePackagingDimensionPayload {
    dimension: 'widthInMm' | 'heightInMm' | 'lengthInMm';
    value: number | undefined;
}

export interface DeliveryScheduleUpdateDataRequest {
    dunsNumber: string;
    deliveryScheduleId: string;
    articleMasterData: ExtendedArticleMasterData;
    includeInDispatchProposalCreation: boolean;
}
