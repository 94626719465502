import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { getLocale } from '../../../../configuration/lang/selectors';
import { Dispatch, State } from '../../../../configuration/setup/store';
import { ProblemError } from '../../api/apiUtils';
import { fetchEdiFileDownloadUrl } from '../../api/file/ediFilesCalls';
import {
    requestDeliveryForecastDocumentsGeneration,
    requestShipmentDocumentsGeneration,
    startAsnGenerationForShipment,
    testIfFileIsAvailableInS3,
} from '../../api/shipmentDocuments/shipmentDocumentCalls';
import { waitByRetryingForResponseToBeAvailable } from '../../api/util';
import { shipmentDocumentsSlice } from '../../reducers/shipmentDocuments/ShipmentDocuments.reducer';
import {
    DocumentLanguage,
    DocumentSettings,
    ShipmentDocuments,
} from '../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { getSelectedDeliverySchedule } from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { getDocumentSettings } from '../../selectors/shipments/ShipmentDocuments.selector';
import { getSelectedShipment, getSelectedShipmentId } from '../../selectors/shipments/Shipments.selector';
import { nonVanishingFormattedErrorNotification } from '../NotificationUtils';
import { ensureValueExists } from '../ensureValueExists';
import { storeUrlAsFileBlob } from '../files/Files.actions';
import { getAsnUrlForSelectedShipment } from '../shipments/Asn.actions';
import { handleError } from '../ui/UI.actions';

export const generateShipmentDocumentsForSelectedShipment =
    (dunsNumber: string) => async (dispatch: Dispatch, getState: () => State) => {
        const selectedShipmentId = ensureValueExists(getSelectedShipmentId, getState(), 'Selected Shipment');
        dispatch(
            shipmentDocumentsSlice.actions.setGeneratingShipmentDocuments({
                state: true,
                shipmentId: selectedShipmentId,
            }),
        );

        try {
            const url = await dispatch(getAsnUrl(dunsNumber));
            if (url) {
                await dispatch(getShipmentDocuments(url));
            }
        } finally {
            dispatch(
                shipmentDocumentsSlice.actions.setGeneratingShipmentDocuments({
                    state: false,
                    shipmentId: selectedShipmentId,
                }),
            );
        }
    };

const getAsnUrl =
    (dunsNumber: string) =>
    async (dispatch: Dispatch): Promise<string | undefined> => {
        try {
            return await dispatch(getAsnUrlForSelectedShipment(dunsNumber)).then(({ url }) => url);
        } catch (error) {
            if (error instanceof ProblemError) {
                dispatch(handleError(error));
            } else {
                nonVanishingFormattedErrorNotification({
                    messageId: 'webedi.shipmentDocuments.generatedDocuments.error.asnUrlFailed',
                    titleId: 'webedi.shipmentDocuments.generatedDocuments.error.title',
                });
            }
            return undefined;
        }
    };

const getShipmentDocuments = (asnUrl: string) => async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const shipment = ensureValueExists(getSelectedShipment, state);

    try {
        const shipmentDocuments = await requestShipmentDocumentsGeneration({
            asnUrl,
            documentSettings: getDocumentSettings(state),
            shipmentNumber: shipment.shipmentNumber,
        });
        dispatch(
            shipmentDocumentsSlice.actions.updateShipmentDocuments({ shipmentId: shipment.id, shipmentDocuments }),
        );
    } catch (err) {
        handleErrorAtShipmentDocumentGeneration(err);
    }
};

const handleErrorAtShipmentDocumentGeneration = (error: unknown) => {
    const errorTitleId = 'webedi.shipmentDocuments.generatedDocuments.error.title';
    if (error instanceof ProblemError) {
        const statusCode = error.statusCode;
        if (400 <= statusCode && statusCode < 500) {
            nonVanishingFormattedErrorNotification({
                messageId: 'webedi.shipmentDocuments.generatedDocuments.error.checkData',
                titleId: errorTitleId,
            });
        } else if (500 <= statusCode && statusCode < 600) {
            nonVanishingFormattedErrorNotification({
                messageId: 'webedi.shipmentDocuments.generatedDocuments.error.retryPlease',
                titleId: errorTitleId,
            });
        }
    } else {
        nonVanishingFormattedErrorNotification({
            messageId: 'webedi.shipmentDocuments.generatedDocuments.error.general',
            titleId: errorTitleId,
        });
    }
};

export const generateShipmentDocuments =
    (dunsNumber: string, shipments: { shipmentId: string; shipmentNumber: number }[]) =>
    async (dispatch: Dispatch, getState: () => State) => {
        const documentSettings = getDocumentSettings(getState());
        return Promise.all(
            shipments.map((it) => generateShipmentDocumentsForShipment(dispatch, dunsNumber, documentSettings, it)),
        );
    };

export class ShipmentDocumentsGenerationError extends Error {
    readonly shipmentId: string;
    readonly shipmentNumber: number;
    readonly cause: unknown;

    constructor(shipmentId: string, shipmentNumber: number, cause: unknown) {
        super(`Error generating documents for shipment ${shipmentId}`, { cause });
        this.shipmentId = shipmentId;
        this.shipmentNumber = shipmentNumber;
        this.cause = cause;
    }
}

const generateShipmentDocumentsForShipment = async (
    dispatch: Dispatch,
    dunsNumber: string,
    documentSettings: DocumentSettings,
    shipment: { shipmentId: string; shipmentNumber: number },
): Promise<ShipmentDocuments> => {
    const { shipmentId, shipmentNumber } = shipment;
    dispatch(shipmentDocumentsSlice.actions.setGeneratingShipmentDocuments({ state: true, shipmentId }));
    try {
        const asnUrl = await startAsnGenerationForShipment(dunsNumber, shipmentId);
        await waitByRetryingForResponseToBeAvailable(() => testIfFileIsAvailableInS3(asnUrl));
        return requestShipmentDocumentsGeneration({ asnUrl, documentSettings, shipmentNumber });
    } catch (error) {
        throw new ShipmentDocumentsGenerationError(shipmentId, shipmentNumber, error);
    } finally {
        dispatch(shipmentDocumentsSlice.actions.setGeneratingShipmentDocuments({ state: false, shipmentId }));
    }
};

export const generateDeliveryForecastDocument =
    (dunsNumber: string, ediFileId: string, filename: string) => async (dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const deliverySchedule = await getSelectedDeliverySchedule(state);
        const articleNumberBuyer =
            deliverySchedule === undefined
                ? undefined
                : deliverySchedule.scheduledArticleDetails.lineItem.lineItemIdentifier.itemNumberIdentification
                      .itemIdentifier;
        const language = getLocale(state)?.toLowerCase()?.startsWith('de')
            ? DocumentLanguage.GERMAN
            : DocumentLanguage.ENGLISH;
        const pdfFilename = `${filename.includes('.') ? filename.substring(0, filename.lastIndexOf('.')) : filename}.pdf`;
        return fetchEdiFileDownloadUrl(dunsNumber, ediFileId)
            .then((delforUrl) => requestDeliveryForecastDocumentsGeneration(delforUrl, language, articleNumberBuyer))
            .then((pdfUrl) => dispatch(storeUrlAsFileBlob(pdfUrl, pdfFilename)))
            .catch((err) => Notification.error(err.message));
    };
