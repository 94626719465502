import { MeasurementUnitCode } from '../../../../domain/common.types';
import { AuxiliaryHandlingUnitGroup } from '../../../../reducers/auxiliaryPackaging.types';
import { Ownership } from '../../../../reducers/shipments/packaging.types';
import { HandlingUnitCategory } from '../../../common/PackagingCommon';

export interface UnpackagedArticle {
    loadItemId: string;
    articleNumberBuyer: string;
    deliveryNoteNumber: number;
    position: number;
    unpackagedQuantityValue: number;
    measurementUnitCode: MeasurementUnitCode;
    packagedQuantityValue: number;
}

export type PackageableInPackageConfig = HandlingUnitInPackageConfig | ArticleInPackageConfig;

export interface HandlingUnitInPackageConfig {
    id: string;
    type: string;
    description?: string;
    category: HandlingUnitCategory;
    labelNumber: number;
    contents: PackageableInPackageConfig[];
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
    tareWeightInKg: number;
    netWeightInKg?: number;
    grossWeightInKg?: number;
}
export type PackagingMaterialConfiguration = Pick<
    HandlingUnitInPackageConfig,
    'isReusable' | 'ownership' | 'stackingFactor' | 'tareWeightInKg'
>;

export interface ArticleInPackageConfig {
    articleDescription: string;
    deliveryNoteNumber: number;
    deliveryNotePosition: number;
    articleNumber: string;
    quantity: number;
    articleNetWeightInKg?: number;
    articleMeasurementUnitCode?: MeasurementUnitCode;
}

export const isArticleInPackageConfig = (
    packageable: PackageableInPackageConfig,
): packageable is ArticleInPackageConfig => (packageable as ArticleInPackageConfig).articleNumber !== undefined;

export const isHandlingUnitInPackageConfig = (
    packageable: PackageableInPackageConfig,
): packageable is HandlingUnitInPackageConfig => (packageable as HandlingUnitInPackageConfig).type !== undefined;
