import { LoadingIndicator } from '../common/LoadingIndicator';
import { ExportedShipmentsErrorBox } from './ExportedShipmentsErrorBox';
import { TransportOrdersTable } from './table/TransportOrdersTable';
import { TransportTableToolbar } from './tabletoolbar/TableToolbar';
import { useTransportOrdersQuery } from './transportOrdersHooks';

export const TransportOrdersView = () => {
    const { data, isLoading } = useTransportOrdersQuery();

    if (isLoading) {
        return <LoadingIndicator />;
    } else {
        return (
            <>
                <ExportedShipmentsErrorBox />
                <TransportTableToolbar transportOrders={data ?? []} />
                <TransportOrdersTable transportOrders={data ?? []} />
            </>
        );
    }
};
