import { ApplicationLayoutBodyNavigation, OnboardingTip } from '@rio-cloud/rio-uikit';
import SubNavigation from '@rio-cloud/rio-uikit/SubNavigation';
import { Fragment, ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { IdentityProvider, config } from '../../../config';
import { TranslationKeys } from '../../../configuration/lang/types';
import { useAppDispatch } from '../../../configuration/setup/typedReduxHooks';
import { DISPATCH_PROPOSALS_TAG, DISPATCH_PROPOSAL_PROBLEMS_TAG } from '../api/baseApi';
import { dispatchProposalApi } from '../api/dispatchProposal/dispatchProposalApi';
import { Routing } from './routing/Routes';

const SHOW_CLOSING_DAYS_ONBOARDING_TIP_UNTIL = '2024-12-01';

const createNavLink = (
    dunsNumber: string | undefined,
    path: string,
    translationForLabel: TranslationKeys,
    exact?: boolean,
    additionalOnClick?: () => void,
    newFeature?: boolean,
) => {
    const basePath = `${Routing.webScm}/${dunsNumber}`;

    return (
        <NavLink to={`${basePath}${path}`} end={exact} onClick={additionalOnClick ?? (() => {})}>
            <FormattedMessage id={translationForLabel} />
            {newFeature ? (
                <span className={'badge badge-secondary margin-left-5 text-uppercase'}>
                    <FormattedMessage id={'webedi.badge.new'} />
                </span>
            ) : (
                <Fragment />
            )}
        </NavLink>
    );
};

export const homeLink = <NavLink to={'/'} />;
export const useNavLinks = (dunsNumber: string | undefined, webSCMPlusFeaturesAreActive: boolean) => {
    const isRioEnvironment = config.identityProvider === IdentityProvider.RIO;
    const dispatch = useAppDispatch();

    const deliverySchedulesLink = {
        key: 'delivery-schedules',
        route: createNavLink(dunsNumber, `${Routing.deliverySchedules}`, 'webedi.sublink.deliverySchedules'),
    };
    const suggestionsLink = {
        key: 'suggestions',
        route: createNavLink(dunsNumber, `${Routing.suggestions}`, 'webedi.sublink.suggestionsV2'),
    };
    const shipmentsLink = {
        key: 'shipments',
        route: createNavLink(dunsNumber, `${Routing.shipments}`, 'webedi.sublink.shipments'),
    };
    const exportedShipmentsLink = {
        key: 'exported-shipments',
        route: createNavLink(dunsNumber, `${Routing.exportedShipments}`, 'webedi.sublink.exportedShipments'),
    };
    const carriersLink = {
        key: 'carriers',
        route: createNavLink(dunsNumber, `${Routing.carriers}`, 'webedi.sublink.carriers'),
    };

    const dispatchProposalsLink = {
        key: 'dispatch-proposals',
        route: createNavLink(
            dunsNumber,
            `${Routing.dispatchProposals}`,
            'webedi.sublink.dispatchProposals',
            undefined,
            () => {
                dispatch(
                    dispatchProposalApi.util?.invalidateTags([DISPATCH_PROPOSALS_TAG, DISPATCH_PROPOSAL_PROBLEMS_TAG]),
                );
            },
        ),
    };
    const transportOrdersLink = {
        key: 'transport-orders',
        route: createNavLink(dunsNumber, `${Routing.transportOrders}`, 'webedi.sublink.transportOrders'),
    };

    const dunsSelectionLink = {
        key: 'duns-selection',
        route: (
            <NavLink to={Routing.dunsSelection}>
                <FormattedMessage id={'webedi.sublink.dunsSelection'} />
            </NavLink>
        ),
    };

    const adminNavLink = createNavLink(dunsNumber, `${Routing.admin}`, 'webedi.sublink.administration');
    const shouldShowOnboardingTip =
        config.identityProvider !== IdentityProvider.RIO &&
        webSCMPlusFeaturesAreActive &&
        new Date() < new Date(SHOW_CLOSING_DAYS_ONBOARDING_TIP_UNTIL);
    const wrappedAdminNavLink = wrapInOnboardingLink(
        adminNavLink,
        shouldShowOnboardingTip,
        'webedi.sublink.administration.onboardingTip.closingDays.title',
        'webedi.sublink.administration.onboardingTip.closingDays.body',
    );
    const adminLink = {
        key: 'administration',
        route: wrappedAdminNavLink,
    };

    if (dunsNumber && webSCMPlusFeaturesAreActive) {
        return [
            deliverySchedulesLink,
            dispatchProposalsLink,
            transportOrdersLink,
            suggestionsLink,
            shipmentsLink,
            exportedShipmentsLink,
            adminLink,
        ];
    } else if (dunsNumber) {
        return [
            deliverySchedulesLink,
            suggestionsLink,
            shipmentsLink,
            exportedShipmentsLink,
            isRioEnvironment ? adminLink : carriersLink,
        ];
    } else {
        return [dunsSelectionLink];
    }
};

const wrapInOnboardingLink = (
    componentToWrap: ReactElement,
    onboardingTipEnabled: boolean,
    phraseKeyTitle: string,
    phraseKeyBody: string,
) => {
    const [showOnboardingTip, setShowOnboardingTip] = useState(true);
    const intl = useIntl();

    const localStorageDontShowAgainProperty = `onboardingTip_${phraseKeyTitle}_dontShowAgain`;
    if (!onboardingTipEnabled || localStorage.getItem(localStorageDontShowAgainProperty) === 'true') {
        return componentToWrap;
    }

    const handleDontShowAgain = () => {
        localStorage.setItem(localStorageDontShowAgainProperty, 'true');
    };

    return (
        <OnboardingTip
            show={showOnboardingTip}
            placement='bottom'
            width={500}
            clickflow
            textAlignment='left'
            title={<FormattedMessage id={phraseKeyTitle} />}
            content={
                <p className={'white-space-pre-line'}>
                    <FormattedMessage id={phraseKeyBody} />
                </p>
            }
            previousButton={{
                text: intl.formatMessage({ id: 'webedi.sublink.onboardingTip.dontShowAgain' }),
                iconName: 'rioglyph-eye-close',
                onClick: handleDontShowAgain,
            }}
            onHide={() => {
                setShowOnboardingTip(false);
            }}
        >
            {componentToWrap}
        </OnboardingTip>
    );
};

export const useSubNavigation = (dunsNumber: string | undefined, webSCMPlusFeaturesAreActive: boolean) => {
    const isRioIDP = config.identityProvider === IdentityProvider.RIO;
    const location = useLocation();

    if (dunsNumber && location.pathname.indexOf(`${Routing.admin}`) > -1) {
        const carriersLink = {
            key: 'carriers',
            route: createNavLink(dunsNumber, `${Routing.admin}${Routing.carriers}`, 'webedi.sublink.carriers'),
        };
        const loadingLocationsLink = {
            key: 'loading-locations',
            route: createNavLink(
                dunsNumber,
                `${Routing.admin}${Routing.loadingLocations}`,
                'webedi.sublink.loadingLocations',
            ),
        };
        const closingDaysIsNewFeature = new Date() < new Date(SHOW_CLOSING_DAYS_ONBOARDING_TIP_UNTIL);
        const closingDaysLink = {
            key: 'closing-days',
            route: createNavLink(
                dunsNumber,
                `${Routing.admin}${Routing.closingDays}`,
                'webedi.sublink.closingDays',
                false,
                undefined,
                closingDaysIsNewFeature,
            ),
        };
        const notificationsLink = {
            key: 'notifications',
            route: createNavLink(
                dunsNumber,
                `${Routing.admin}${Routing.notifications}`,
                'webedi.sublink.notifications',
            ),
        };

        const subNavItems = [
            ...(webSCMPlusFeaturesAreActive ? [loadingLocationsLink] : []),
            carriersLink,
            ...(webSCMPlusFeaturesAreActive && !isRioIDP ? [closingDaysLink] : []),
            ...(isRioIDP ? [notificationsLink] : []),
        ];
        return (
            <ApplicationLayoutBodyNavigation>
                <SubNavigation navItems={subNavItems} />
            </ApplicationLayoutBodyNavigation>
        );
    }
    return undefined;
};

export const useClosingDaysPageActive = () => {
    const { pathname } = useLocation();
    return pathname.includes(`${Routing.admin}${Routing.closingDays}`);
};
