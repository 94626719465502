import {
    ScheduledDate,
    ScheduledDateTime,
    ScheduledPeriod,
    isScheduledDate,
    isScheduledDateTime,
} from '../../../reducers/deliverySchedules/types';
import { FormattedDateOrDateTime } from './FormattedDateOrDateTime';

interface FormattedDateTimeOrPeriodProps {
    datetimeOrPeriod: ScheduledDate | ScheduledDateTime | ScheduledPeriod;
}

export const FormattedDateTimeOrPeriod = (props: FormattedDateTimeOrPeriodProps) => {
    if (isScheduledDate(props.datetimeOrPeriod)) {
        return <FormattedDateOrDateTime date={props.datetimeOrPeriod.scheduledDate} />;
    }
    if (isScheduledDateTime(props.datetimeOrPeriod)) {
        return <FormattedDateOrDateTime date={props.datetimeOrPeriod.scheduledDateTime} />;
    }
    return (
        <>
            <FormattedDateOrDateTime date={props.datetimeOrPeriod.earliestDeliveryDate} />
            {' - '}
            <FormattedDateOrDateTime date={props.datetimeOrPeriod.latestDeliveryDate} />
        </>
    );
};
