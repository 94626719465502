import { createSelector } from '@reduxjs/toolkit';
import { State } from '../../../../configuration/setup/store';
import { Partner } from '../../domain/common.types';
import { PackagingMaterialResult, PackagingMaterialState } from '../../reducers/packaging/types';

export const getAllPackagingMaterials = (state: State): PackagingMaterialState['packagingMaterials'] => {
    return state.webEdi.packagingMaterial.packagingMaterials;
};

export const getPackagingMaterial = createSelector(
    [getAllPackagingMaterials, (_, boxCode) => boxCode],
    (packagingMaterials, boxCode: string | undefined): PackagingMaterialResult => ({
        packagingMaterial: boxCode !== undefined ? packagingMaterials[boxCode]?.packagingMaterial : undefined,
        successful: boxCode !== undefined ? packagingMaterials[boxCode]?.successful : undefined,
    }),
);

export interface BuyerContext {
    buyer: string;
    partner: Partner;
}
