import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { FieldErrors, Path, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Ownership, PackagingMaterialType } from '../../../reducers/shipments/packaging.types';
import { PackageTogetherFormValues } from '../../dispatchProposals/dispatchProposalPanel/PackageTogetherDialog';
import { PackagingMaterialFormValues } from './BoxFinderInputV2';
import { PositiveNumberFormInputWithFeedback } from './PositiveNumberFormInput';
import { SelectHookForm, SelectOptionWithValue } from './react-hook-form/SelectHookForm';

export const PACKAGING_MATERIAL_EXPANDABLE_TEST_ID = 'PACKAGING_MATERIAL_EXPANDABLE_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_CONTENT_TEST_ID = 'PACKAGING_MATERIAL_EXPANDABLE_CONTENT_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_INPUT_ERROR_BLOCK_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_INPUT_ERROR_BLOCK_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_INPUT_SUCCESS_BLOCK_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_INPUT_SUCCESS_BLOCK_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_OWNERSHIP_SELECT_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_OWNERSHIP_SELECT_TEST_ID';
export const PACKAGING_MATERIAL_EXPANDABLE_IS_REUSABLE_SELECT_TEST_ID =
    'PACKAGING_MATERIAL_EXPANDABLE_IS_REUSABLE_SELECT_TEST_ID';

export const PackagingMaterialExpandableV2 = (props: {
    fetched: boolean;
    disabled: boolean;
    packagingMaterialFieldName: Path<PackageTogetherFormValues>;
    getPackagingMaterialError: (
        errors: FieldErrors<PackageTogetherFormValues>,
    ) => FieldErrors<PackagingMaterialFormValues> | undefined;
}) => {
    const intl = useIntl();
    const { watch } = useFormContext<PackageTogetherFormValues>();
    const { fetched, disabled, packagingMaterialFieldName, getPackagingMaterialError } = props;
    const packagingMaterial: PackagingMaterialFormValues = watch(packagingMaterialFieldName);

    const getPackagingMaterialDimensionsFooter = () => {
        const parts: string[] = [];
        const dimensionParts: string[] = [];

        if (packagingMaterial?.outerLengthInMm) {
            dimensionParts.push(`${packagingMaterial.outerLengthInMm} mm`);
        }
        if (packagingMaterial?.outerWidthInMm) {
            dimensionParts.push(`${packagingMaterial.outerWidthInMm} mm`);
        }
        if (packagingMaterial?.outerHeightInMm) {
            dimensionParts.push(`${packagingMaterial.outerHeightInMm} mm`);
        }
        if (dimensionParts.length > 0) {
            parts.push(dimensionParts.join(' x '));
        }

        if (fetched && parts.length === 0) {
            return intl.formatMessage({ id: 'webedi.error.notAvailable' });
        }

        return parts.join(' - ');
    };

    const ownershipOptions: SelectOptionWithValue[] = [
        {
            id: Ownership.SELLER,
            label: intl.formatMessage({ id: 'webedi.packaging.ownership.option.seller' }),
        },
        {
            id: Ownership.BUYER,
            label: intl.formatMessage({ id: 'webedi.packaging.ownership.option.buyer' }),
        },
    ].map((option) => {
        return {
            ...option,
            value: option.id,
        };
    });

    const isReusableOptions: SelectOptionWithValue<boolean>[] = [
        {
            id: PackagingMaterialType.DISPOSABLE,
            label: intl.formatMessage({ id: 'webedi.packaging.packagingType.option.disposable' }),
            value: false,
        },
        {
            id: PackagingMaterialType.REUSABLE,
            label: intl.formatMessage({ id: 'webedi.packaging.packagingType.option.reusable' }),
            value: true,
        },
    ];

    const responsivenessClasses = 'col-xs-12 col-sm-6 col-md-6 col-lg-6';
    const content = (
        <div data-testid={PACKAGING_MATERIAL_EXPANDABLE_CONTENT_TEST_ID}>
            <div className={'row col-12'}>
                <div
                    className={`${responsivenessClasses} padding-right-5 padding-bottom-5`}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_OWNERSHIP_SELECT_TEST_ID}
                >
                    <label htmlFor={`${packagingMaterialFieldName}.ownership`}>
                        <span className={'text-size-12 text-color-gray'}>
                            <FormattedMessage id={'webedi.packaging.ownership'} />
                        </span>
                    </label>
                    <SelectHookForm
                        fieldName={`${packagingMaterialFieldName}.ownership`}
                        useFilter={false}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                        options={ownershipOptions}
                        required={true}
                    />
                </div>

                <div
                    className={`${responsivenessClasses} padding-right-5 padding-bottom-5`}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_IS_REUSABLE_SELECT_TEST_ID}
                >
                    <label htmlFor={`${packagingMaterialFieldName}.isReusable`}>
                        <span className={'text-size-12 text-color-gray '}>
                            <FormattedMessage id={'webedi.packaging.packagingType'} />
                        </span>
                    </label>
                    <SelectHookForm
                        fieldName={`${packagingMaterialFieldName}.isReusable`}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                        options={isReusableOptions}
                        required={true}
                    />
                </div>

                <div className={`${responsivenessClasses} padding-right-5 padding-bottom-5`}>
                    <label htmlFor={`${packagingMaterialFieldName}.tareWeightInKg`}>
                        <span className={'text-size-12 text-color-gray'}>
                            <FormattedMessage id={'webedi.packaging.tareWeight'} />
                        </span>
                    </label>
                    <PositiveNumberFormInputWithFeedback<PackageTogetherFormValues>
                        fieldName={`${packagingMaterialFieldName}.tareWeightInKg`}
                        required={true}
                        decimalAllowed={true}
                        zeroAllowed={true}
                        step={0.001}
                        digitPrecision={3}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                        getError={(errors) => getPackagingMaterialError(errors)?.tareWeightInKg}
                    />
                </div>

                <div className={`${responsivenessClasses} padding-right-5  padding-bottom-5`}>
                    <label htmlFor={`${packagingMaterialFieldName}.stackingFactor`}>
                        <span className={'text-size-12 text-color-gray'}>
                            <FormattedMessage id={'webedi.packaging.stackFactor'} />
                        </span>
                    </label>
                    <PositiveNumberFormInputWithFeedback<PackageTogetherFormValues>
                        fieldName={`${packagingMaterialFieldName}.stackingFactor`}
                        required={true}
                        decimalAllowed={false}
                        step={1}
                        max={999}
                        disabled={disabled || !fetched || packagingMaterial?.name === undefined}
                        getError={(errors) => getPackagingMaterialError(errors)?.stackingFactor}
                    />
                </div>
            </div>
            <div className={'padding-top-15'}>
                <span className={'text-size-13'}>
                    <FormattedMessage id={'webedi.packaging.dimensions'} />: {getPackagingMaterialDimensionsFooter()}
                </span>
            </div>
        </div>
    );

    const renderHeader = () => {
        if (!fetched) {
            return <span className={'text-size-18 margin-right-5'} />;
        } else if (packagingMaterial?.name === undefined) {
            return (
                <span
                    className={'text-center margin-bottom-15 text-color-danger'}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_INPUT_ERROR_BLOCK_TEST_ID}
                >
                    <div className={'text-size-18 margin-right-5 display-inline'}>
                        <span className={'rioglyph rioglyph-remove-sign'} />
                    </div>
                    <FormattedMessage id={'webedi.packagingMaterial.notFound'} />
                </span>
            );
        } else {
            return (
                <span
                    className={'text-center margin-bottom-15 text-color-success'}
                    data-testid={PACKAGING_MATERIAL_EXPANDABLE_INPUT_SUCCESS_BLOCK_TEST_ID}
                >
                    <div className={'text-size-18 margin-right-5 display-inline'}>
                        <span className={'rioglyph rioglyph-ok-sign'} />
                    </div>
                    {packagingMaterial.name}
                </span>
            );
        }
    };

    return (
        <div data-testid={PACKAGING_MATERIAL_EXPANDABLE_TEST_ID}>
            <ExpanderPanel title={renderHeader()} bsStyle='default' unmountOnExit={false} open={true}>
                {content}
            </ExpanderPanel>
        </div>
    );
};
