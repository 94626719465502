import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { toPackagingOuterDimensionsFromGiphys } from '../../../../utils';
import { HandlingUnitCategory } from '../../components/common/PackagingCommon';
import {
    GroupedHandlingUnits,
    HandlingUnit,
    HandlingUnitGroup,
    Packageable,
    PackagingConfig,
    PackagingConfigDictionary,
    PackagingState,
    PackagingStep,
    PackagingTemplatesDictionary,
    PackagingType,
    PackagingWizard,
    UpdateHeterogeneousAdditionalPackagingConfigurationPayload,
    UpdateHomogeneousAdditionalPackagingConfigurationPayload,
    UpdateInnerPackagingConfigurationStepConfiguration,
    UpdateInnerPackagingPackagingMaterialSelectionStepConfiguration,
    UpdateLabelNumberConfigurationPayload,
    UpdateOuterPackagingPackagingMaterialSelectionStepConfiguration,
    UpdatePackagingConfigurationType,
    instanceOfHandlingUnitGroup,
} from './packaging.types';
import { ShipmentsUpdatePayload } from './types';

const LARGEST_LABEL_NUMBER = 999_999_999;

const initialState: PackagingState = {
    config: {},
    idOfLoadItemToPackage: undefined,
    selectedGroupedHandlingUnits: [],
    templates: {},
    nextAvailableLabelNumber: undefined,
};

export const getAndIncrementNextAvailableLabelNumber = (state: PackagingState): number => {
    const nextAvailableLabelNumber = state.nextAvailableLabelNumber ?? 1;
    setNextAvailableLabelNumber(state, nextAvailableLabelNumber);
    return nextAvailableLabelNumber;
};

const setNextAvailableLabelNumber = (state: PackagingState, largestUsedLabelNumber: number) => {
    if (largestUsedLabelNumber === LARGEST_LABEL_NUMBER) {
        state.nextAvailableLabelNumber = 1;
    } else {
        state.nextAvailableLabelNumber = largestUsedLabelNumber + 1;
    }
};

export const incrementLabelNumbers = (huGroup: HandlingUnitGroup, state: PackagingState): HandlingUnitGroup => {
    return {
        ...huGroup,
        handlingUnit: {
            ...huGroup.handlingUnit,
            labelNumber: getAndIncrementNextAvailableLabelNumber(state),
            contents: huGroup.handlingUnit.contents.map((it) => {
                if (instanceOfHandlingUnitGroup(it)) {
                    return incrementLabelNumbers(it, state);
                } else {
                    return it;
                }
            }),
        },
    };
};

export const packagingSlice = createSlice({
    name: 'packaging',
    initialState,
    reducers: {
        addPackagedArticlesOrPackagingFromTemplates: {
            reducer: (
                state: PackagingState,
                action: PayloadAction<HandlingUnitGroup[], string, { shipmentId: string }>,
            ) => {
                const packagingConfig = state.config[action.meta.shipmentId];
                if (packagingConfig) {
                    const newPackaging = action.payload.map((it) => incrementLabelNumbers(it, state));
                    packagingConfig.packaging = [...packagingConfig.packaging, ...newPackaging];
                    packagingConfig.hasUpdated = true;
                } else {
                    throw new Error('Cannot add packaging data to missing config. This should never happen');
                }
            },
            prepare: (payload: HandlingUnitGroup[], shipmentId: string) => {
                return { payload, meta: { shipmentId } };
            },
        },
        updatePackagingForShipment: {
            reducer: (
                state: PackagingState,
                action: PayloadAction<Omit<PackagingConfig, 'hasUpdated'>, string, { shipmentId: string }>,
            ) => {
                state.config[action.meta.shipmentId] = {
                    packaging: action.payload.packaging,
                    packagingOuterDimensions: {
                        ...state.config[action.meta.shipmentId]?.packagingOuterDimensions,
                        ...action.payload.packagingOuterDimensions,
                    },
                    hasUpdated: true,
                };
            },
            prepare: (payload: Omit<PackagingConfig, 'hasUpdated'>, shipmentId: string) => {
                return { payload, meta: { shipmentId } };
            },
        },
        updateFormIdInPackagingWizard: (state: PackagingState, action: PayloadAction<string>) => {
            state.packagingWizard = {
                ...state.packagingWizard!,
                formId: action.payload,
            };
        },
        showDialog: (state: PackagingState, action: PayloadAction<PackagingWizard>) => {
            const packagingType = action.payload.type;
            state.packagingWizard = {
                type: packagingType,
                step:
                    packagingType === PackagingType.INNER
                        ? PackagingStep.PACKAGING_MATERIAL_SELECTION
                        : PackagingStep.GROUPING_SELECTION,
            };
        },
        setPackagingStep: (state: PackagingState, action: PayloadAction<PackagingStep>) => {
            state.packagingWizard = {
                ...state.packagingWizard!,
                step: action.payload,
            };
        },
        hidePackagingDialog: (state: PackagingState) => {
            state.packagingWizard = undefined;
        },
        updateArticleToPackage: (state: PackagingState, action: PayloadAction<string | undefined>) => {
            state.idOfLoadItemToPackage = action.payload || undefined;
        },
        updateSelectedGroupedHandlingUnits: (state: PackagingState, action: PayloadAction<GroupedHandlingUnits[]>) => {
            state.selectedGroupedHandlingUnits = action.payload;
        },
        updateSelectedLabelNumber: (
            state: PackagingState,
            action: PayloadAction<UpdateLabelNumberConfigurationPayload>,
        ) => {
            const { id, labelNumber, shipmentId } = action.payload;
            const configPackaging = state.config[shipmentId];
            if (configPackaging === undefined) {
                throw Error('This should never happen, since changing a label number requires having a packaging');
            }

            const packaging = configPackaging.packaging;

            packaging.forEach((handlingUnitGroup) => {
                const handlingUnit = findHandlingUnitGroup(handlingUnitGroup, id);

                if (handlingUnit !== undefined) {
                    handlingUnit.labelNumber = labelNumber;
                    if (configPackaging) {
                        configPackaging.hasUpdated = true;
                    }
                    return;
                }
            });
        },
        clearUpdatePackagingConfiguration: (state: PackagingState) => {
            state.updatePackagingConfiguration = undefined;
        },
        addPackagedArticlesConfiguration: (
            state: PackagingState,
            action: PayloadAction<UpdateInnerPackagingConfigurationStepConfiguration>,
        ) => {
            state.updatePackagingConfiguration = {
                ...action.payload,
            };
        },
        addPackagingMaterialForInnerPackaging: (
            state: PackagingState,
            action: PayloadAction<UpdateInnerPackagingPackagingMaterialSelectionStepConfiguration>,
        ) => {
            state.updatePackagingConfiguration = {
                ...action.payload,
            };
        },
        addPackagingMaterialForOuterPackaging: (
            state: PackagingState,
            action: PayloadAction<UpdateOuterPackagingPackagingMaterialSelectionStepConfiguration>,
        ) => {
            state.updatePackagingConfiguration = {
                ...action.payload,
            };
        },
        addHomogeneousAdditionalPackagingConfiguration: (
            state: PackagingState,
            action: PayloadAction<UpdateHomogeneousAdditionalPackagingConfigurationPayload>,
        ) => {
            state.updatePackagingConfiguration = {
                ...action.payload,
                type: UpdatePackagingConfigurationType.HOMOGENEOUS_ADDITIONAL_PACKAGING,
                handlingUnitCategory: HandlingUnitCategory.LOAD_CARRIER,
            };
        },
        addHeterogeneousAdditionalPackagingConfiguration: (
            state: PackagingState,
            action: PayloadAction<UpdateHeterogeneousAdditionalPackagingConfigurationPayload>,
        ) => {
            state.updatePackagingConfiguration = {
                ...action.payload,
                type: UpdatePackagingConfigurationType.HETEROGENEOUS_ADDITIONAL_PACKAGING,
                handlingUnitCategory: HandlingUnitCategory.LOAD_CARRIER,
            };
        },
        deletePackagingConfiguration: (state: PackagingState, action: PayloadAction<string>) => {
            const shipmentId = action.payload;
            const packagingConfig = state.config[shipmentId];
            if (packagingConfig === undefined) {
                throw new Error('Existing packaging not set for given shipment id. This should never happen');
            }
            packagingConfig.packaging = [];
            packagingConfig.packagingOuterDimensions = {};
            packagingConfig.hasUpdated = true;
        },
        setPackagingTemplates: (state: PackagingState, action: PayloadAction<PackagingTemplatesDictionary>) => {
            state.templates = action.payload;
        },
        updateNextAvailableLabelNumber: (state: PackagingState, action: PayloadAction<number>) => {
            if (action.payload > LARGEST_LABEL_NUMBER) {
                state.nextAvailableLabelNumber = 1;
            } else {
                state.nextAvailableLabelNumber = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createAction<ShipmentsUpdatePayload, string>('shipments/updateShipments'), (state, action) => {
            state.config = action.payload.shipments.reduce<PackagingConfigDictionary>((map, shipment) => {
                map[shipment.id] = {
                    packaging: shipment.packaging,
                    packagingOuterDimensions: toPackagingOuterDimensionsFromGiphys(
                        shipment.groupOfIdenticalPackagingHierarchies,
                    ),
                    hasUpdated: false,
                };
                return map;
            }, {});
        });
    },
});

const findHandlingUnitGroup = (handlingUnitGroup: HandlingUnitGroup, id?: string): HandlingUnit | undefined => {
    if (handlingUnitGroup.handlingUnit.id === id) {
        return handlingUnitGroup.handlingUnit;
    } else {
        return checkHandlingUnitChildren(handlingUnitGroup, id);
    }
};

const checkHandlingUnitChildren = (handlingUnitGroup: HandlingUnitGroup, id?: string): HandlingUnit | undefined => {
    if (handlingUnitGroup.handlingUnit.contents.length !== 0) {
        const handlingUnitResult = handlingUnitGroup.handlingUnit.contents.find((item) => {
            if (isHandlingUnitGroup(item)) {
                return findHandlingUnitGroup(item as HandlingUnitGroup, id);
            }
            return undefined;
        }) as HandlingUnitGroup;
        return handlingUnitResult?.handlingUnit;
    }
    return undefined;
};

const isHandlingUnitGroup = (packageable: Packageable): packageable is HandlingUnitGroup =>
    (packageable as HandlingUnitGroup).handlingUnit !== undefined;
