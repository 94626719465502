import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { State } from '../../../../../../../configuration/setup/store';
import { useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { MeasurementUnitCode } from '../../../../../domain/common.types';
import { getPackagingMaterial } from '../../../../../selectors/packaging/PackagingMaterial.selector';
import { MeasurementUnitCodeDisplay } from '../../../../common/MeasurementUnitCodeDisplay';
import { PackagingMaterialFormValues } from '../../../../common/form/BoxFinderInput';
import { INNER_PACKAGING_TEST_IDS } from './InnerPackaging';

interface PackagedArticlesProps {
    numberOfPackagedArticles: number;
    measurementUnitCode: MeasurementUnitCode;
    packagingMaterial?: PackagingMaterialFormValues;
}

export const INNER_PACKAGING_NUMBER_PACKAGED_ARTICLES_TEST_ID = 'INNER_PACKAGING_NUMBER_PACKAGED_ARTICLES_TEST_ID';
export const INNER_PACKAGING_PACKAGED_HANDLING_UNIT_TEST_ID = 'INNER_PACKAGING_PACKAGED_HANDLING_UNIT_TEST_ID';

export const InnerPackagingPackagedArticles = (props: PackagedArticlesProps) => {
    const packagingMaterial = useAppSelector((state: State) =>
        getPackagingMaterial(state, props.packagingMaterial?.boxCode),
    );
    const handlingUnitLabel = packagingMaterial.packagingMaterial?.name;

    const measurementUnitCodeTranslation = <MeasurementUnitCodeDisplay unit={props.measurementUnitCode} />;

    return (
        <div className={'text-center'}>
            <span data-testid={INNER_PACKAGING_NUMBER_PACKAGED_ARTICLES_TEST_ID}>
                <FormattedMessage
                    id={'webedi.packaging.inner.packagedArticles'}
                    values={{
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                        measurementUnitCode: measurementUnitCodeTranslation,
                    }}
                />
                <div className={'text-size-20 padding-20'}>
                    <span className={'rioglyph rioglyph-cards-grid'} />
                    &nbsp;
                    <span data-testid={INNER_PACKAGING_TEST_IDS.numberOfPackagedArticles}>
                        {props.numberOfPackagedArticles}
                    </span>
                </div>
            </span>
            <div className={'text-size-12 text-color-gray'}>
                {handlingUnitLabel && (
                    <span data-testid={INNER_PACKAGING_PACKAGED_HANDLING_UNIT_TEST_ID}>
                        <FormattedMessage
                            id={'webedi.packaging.inner.numberOfPackagedArticles'}
                            values={{
                                innerPackaging: handlingUnitLabel, // TODO use consistent wording
                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                            }}
                        />
                    </span>
                )}
            </div>
        </div>
    );
};
