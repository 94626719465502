// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { fromEnum } from '../util';

export enum ApiResponsibleAgencyCode {
    DUNS = 'DUNS',
    SELLER = 'SELLER',
    BUYER = 'BUYER',
}

export const PartyIdentificationDetailsCodec = t.type({
    identifier: t.string,
    responsible_agency_code: fromEnum<ApiResponsibleAgencyCode>('ApiResponsibleAgencyCode', ApiResponsibleAgencyCode),
});

export type ApiPartyIdentificationDetails = t.TypeOf<typeof PartyIdentificationDetailsCodec>;
