import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { v4 as uuid } from 'uuid';
import { neverReachedFor } from '../../../../utils';
import { Quantity } from '../../domain/common.types';
import { Dimensions } from '../shipments/types';
import { FilterValues } from '../types';
import {
    AuxiliaryPackagingTemplatePayload,
    CumulativeQuantitySentOffset,
    DeliverySchedule,
    DeliveryScheduleSortParameterName,
    DeliverySchedulesQuery,
    DeliverySchedulesQueryResult,
    DeliverySchedulesState,
    InnerPackagingStepConfiguration,
    PackagingStepConfiguration,
    PackagingStepType,
    PackagingTemplate,
    PackagingTemplateDictionary,
    PackagingTemplateType,
    SelectedMetadataEntryId,
    SortDirection,
    UpdatePackagingDimensionPayload,
} from './types';

export const initialDeliverySchedulesQuery: DeliverySchedulesQuery = {
    dunsNumber: undefined,
    params: {
        q: '',
        offset: 0,
        limit: 25,
        sort: {
            sortBy: DeliveryScheduleSortParameterName.LATEST_DELIVERY_CALL_OFF_DATE,
            direction: SortDirection.DESCENDING,
        },
        excludeSoftDeleted: true,
    },
};

const initialState: DeliverySchedulesState = {
    deliverySchedules: [],
    totalCountOfMatchedDeliverySchedules: 0,
    deliverySchedulesQuery: initialDeliverySchedulesQuery,
    filterValues: {},
    isLoadingDeliverySchedules: false,
    isLoadingPackagingTemplates: false,
    isLoadingLisonPackagingTemplate: false,
    isLoadingCumulativeQuantitySentOffsets: false,
    isPostingCumulativeQuantitySentOffset: false,
    isSavingArticleMetadata: false,
    selectedDeliveryScheduleId: undefined,
    selectedDeliverySchedulePackagingTemplates: {},
    selectedDeliveryScheduleCumulativeQuantitySentOffsets: [],
    selectedMetadataEntryId: undefined,
    showDeliveryScheduleDialog: false,
    form: {
        isMetadataViewContentDirty: false,
        templateStepsAdded: false,
    },
};

export const containHomogeneousTemplate = (packagingTemplates: PackagingTemplateDictionary) =>
    Object.values(packagingTemplates).find(
        (packagingTemplate) => packagingTemplate.type === PackagingTemplateType.HOMOGENEOUS,
    ) !== undefined;

export const hasMaxNumberOfHomogeneousTemplates = (packagingTemplates: PackagingTemplateDictionary) => {
    return (
        Object.values(packagingTemplates).filter(
            (packagingTemplate) => packagingTemplate.type === PackagingTemplateType.HOMOGENEOUS,
        ).length > 4
    );
};

export const containLisonTemplate = (packagingTemplates: PackagingTemplateDictionary) =>
    Object.values(packagingTemplates).find(
        (packagingTemplate) => packagingTemplate.type === PackagingTemplateType.LISON,
    ) !== undefined;

export const packagingTemplatesWithoutLison = (packagingTemplates: PackagingTemplateDictionary) =>
    Object.fromEntries(
        Object.values(packagingTemplates)
            .filter((existingTemplate) => existingTemplate.type !== PackagingTemplateType.LISON)
            .map((templates) => [templates.id, templates]),
    );

const getDunsNumberOwnerFromState = (state: DeliverySchedulesState): string => {
    const deliverySchedule = state.deliverySchedules.find(
        (delSchedule) => delSchedule.id === state.selectedDeliveryScheduleId,
    );
    if (deliverySchedule === undefined) {
        throw new Error('No delivery schedule selected. This should never happen.');
    }
    if (deliverySchedule.shipFrom.additionalPartyIdentifier?.identifier === undefined) {
        throw new Error('Delivery schedule has no dunsNumberOwner. This should never happen.');
    }
    return deliverySchedule.shipFrom.additionalPartyIdentifier?.identifier;
};

export const deliverySchedulesSlice = createSlice({
    name: 'deliverySchedules',
    initialState,
    reducers: {
        setDeliverySchedulesAreLoading: (state: DeliverySchedulesState) => {
            state.isLoadingDeliverySchedules = true;
        },
        setPackagingTemplatesAreLoading: (state: DeliverySchedulesState) => {
            state.isLoadingPackagingTemplates = true;
        },
        setDeliverySchedulesFailed: (state: DeliverySchedulesState) => {
            state.isLoadingDeliverySchedules = false;
            state.deliverySchedules = [];
        },
        setPackagingTemplatesFailed: (state: DeliverySchedulesState) => {
            state.isLoadingPackagingTemplates = false;
            state.selectedDeliverySchedulePackagingTemplates = {};
        },
        updateQuery: (state: DeliverySchedulesState, action: PayloadAction<DeliverySchedulesQuery>) => {
            state.deliverySchedulesQuery = action.payload;
        },
        updateDeliverySchedules: (
            state: DeliverySchedulesState,
            action: PayloadAction<DeliverySchedulesQueryResult>,
        ) => {
            state.isLoadingDeliverySchedules = false;
            state.totalCountOfMatchedDeliverySchedules = action.payload.totalCountOfMatchedDeliverySchedules;
            state.deliverySchedules = action.payload.deliverySchedules;
        },
        updateDeliverySchedule: (state: DeliverySchedulesState, action: PayloadAction<DeliverySchedule>) => {
            const updatedDeliverySchedule = action.payload;

            const index = state.deliverySchedules.findIndex((item) => item.id === updatedDeliverySchedule.id);
            if (index !== -1) {
                state.deliverySchedules[index] = updatedDeliverySchedule;
            } else {
                state.deliverySchedules.push(updatedDeliverySchedule);
            }
            state.isLoadingDeliverySchedules = false;
        },
        setDeliverySchedulesFilterValues: (state: DeliverySchedulesState, action: PayloadAction<FilterValues>) => {
            state.filterValues = Object.fromEntries(
                action.payload.items.map((filterItem) => [filterItem.key, filterItem.values]),
            );
        },
        updatePackagingTemplates: (state: DeliverySchedulesState, action: PayloadAction<PackagingTemplate[]>) => {
            state.isLoadingPackagingTemplates = false;
            state.selectedDeliverySchedulePackagingTemplates = Object.fromEntries(
                action.payload.map((packagingTemplate) => [packagingTemplate.id, packagingTemplate]),
            );
        },
        updateCumulativeQuantitySentOffsets: (
            state: DeliverySchedulesState,
            action: PayloadAction<CumulativeQuantitySentOffset[]>,
        ) => {
            state.selectedDeliveryScheduleCumulativeQuantitySentOffsets = action.payload;
        },
        setIsLoadingLisonPackagingTemplate: (state: DeliverySchedulesState, action: PayloadAction<boolean>) => {
            state.isLoadingLisonPackagingTemplate = action.payload;
        },
        setIsLoadingCumulativeQuantitySentOffsets: (state: DeliverySchedulesState, action: PayloadAction<boolean>) => {
            state.isLoadingCumulativeQuantitySentOffsets = action.payload;
        },
        setIsPostingCumulativeQuantitySentOffset: (state: DeliverySchedulesState, action: PayloadAction<boolean>) => {
            state.isPostingCumulativeQuantitySentOffset = action.payload;
        },
        setLoadingCumulativeQuantitySentOffsetsFailed: (state: DeliverySchedulesState) => {
            state.isLoadingCumulativeQuantitySentOffsets = false;
            state.selectedDeliveryScheduleCumulativeQuantitySentOffsets = [];
        },
        setIsSavingArticleMetadata: (state: DeliverySchedulesState, action: PayloadAction<boolean>) => {
            state.isSavingArticleMetadata = action.payload;
        },
        setLisonPackagingTemplateFailed: (state: DeliverySchedulesState) => {
            state.selectedDeliverySchedulePackagingTemplates = {
                ...packagingTemplatesWithoutLison(state.selectedDeliverySchedulePackagingTemplates),
            };
            state.selectedMetadataEntryId = PackagingTemplateType.MISSING_LISON;
        },
        updateLisonPackagingTemplate: (state: DeliverySchedulesState, action: PayloadAction<PackagingTemplate>) => {
            state.selectedDeliverySchedulePackagingTemplates = {
                ...packagingTemplatesWithoutLison(state.selectedDeliverySchedulePackagingTemplates),
                ...{ [action.payload.id]: action.payload },
            };
            state.selectedMetadataEntryId = action.payload.id;
        },
        replaceSelectedPackagingTemplate: (state: DeliverySchedulesState, action: PayloadAction<PackagingTemplate>) => {
            const updatedSelectedPackagingTemplate = action.payload;
            const previousSelectedPackagingTemplateId = state.selectedMetadataEntryId;
            if (previousSelectedPackagingTemplateId === undefined) {
                throw Error('Previously selected metadata entry is undefined. This should never happen.');
            }
            const previousSelectedPackagingTemplate =
                state.selectedDeliverySchedulePackagingTemplates[previousSelectedPackagingTemplateId];
            if (previousSelectedPackagingTemplate === undefined) {
                throw Error('Selected metadata entry does not refer to a template. This should never happen.');
            }
            delete state.selectedDeliverySchedulePackagingTemplates[previousSelectedPackagingTemplateId];
            state.selectedMetadataEntryId = updatedSelectedPackagingTemplate.id;
            state.selectedDeliverySchedulePackagingTemplates[updatedSelectedPackagingTemplate.id] =
                updatedSelectedPackagingTemplate;
        },
        setSelectedDeliveryScheduleId: (state: DeliverySchedulesState, action: PayloadAction<string | undefined>) => {
            state.selectedDeliveryScheduleId = action.payload;
        },
        clearSelectedDeliveryScheduleId: (state: DeliverySchedulesState) => {
            state.selectedDeliveryScheduleId = undefined;
            state.selectedMetadataEntryId = undefined;
        },
        clearSelectedDeliveryScheduleCumulativeQuantitySentOffsets: (state: DeliverySchedulesState) => {
            state.selectedDeliveryScheduleCumulativeQuantitySentOffsets = [];
        },
        setSelectedDeliveryScheduleMetadataEntry: (state: DeliverySchedulesState, action: PayloadAction<string>) => {
            state.selectedMetadataEntryId = action.payload;
        },
        clearSelectedDeliveryScheduleMetadata: (state: DeliverySchedulesState) => {
            state.selectedMetadataEntryId = undefined;
        },
        setShowDeliveryScheduleDialog: (state: DeliverySchedulesState, action: PayloadAction<boolean>) => {
            state.showDeliveryScheduleDialog = action.payload;
        },
        clearNonPersistedTemplates: (state: DeliverySchedulesState) => {
            Object.values(state.selectedDeliverySchedulePackagingTemplates)
                .filter((template) => !template.persisted)
                .forEach((template) => {
                    delete state.selectedDeliverySchedulePackagingTemplates[template.id];
                });
        },
        addHomogeneousPackagingTemplate: (state: DeliverySchedulesState, action: PayloadAction<string>) => {
            if (hasMaxNumberOfHomogeneousTemplates(state.selectedDeliverySchedulePackagingTemplates)) {
                throw new Error(
                    'Cannot add HomogeneousPackagingTemplate because the max amount of HomogeneousPackagingTemplates is reached. This should never happen.',
                );
            }
            if (state.selectedDeliveryScheduleId === undefined) {
                throw new Error('No selected delivery schedule id. This should never happen.');
            }
            // temporary id as we delegate the id generation to the BE
            const placeholderId = uuid();
            state.selectedDeliverySchedulePackagingTemplates[placeholderId] = {
                id: placeholderId,
                name: action.payload,
                type: PackagingTemplateType.HOMOGENEOUS,
                dunsNumberOwner: getDunsNumberOwnerFromState(state),
                isValid: false,
                referencedDeliveryScheduleIds: [state.selectedDeliveryScheduleId],
                steps: [],
                dimensions: {},
                persisted: false,
            };
            state.selectedMetadataEntryId = placeholderId;
        },
        setIsMetadataViewContentDirty: (state: DeliverySchedulesState, action: PayloadAction<boolean>) => {
            state.form.isMetadataViewContentDirty = action.payload;
        },
        setActionToBeConfirmed: (state: DeliverySchedulesState, action: PayloadAction<Action | undefined>) => {
            state.form.actionToBeConfirmed = action.payload;
        },
        showTemplateDialog: (state: DeliverySchedulesState, action: PayloadAction<PackagingStepType>) => {
            state.form.templateWizardState = {
                type: action.payload,
                step: 0,
            };
        },
        // TODO: RIOINBBL-1556 consolidate and test
        showAuxiliaryPackagingStep: (state: DeliverySchedulesState) => {
            state.form.templateWizardState = {
                ...state.form.templateWizardState!,
                step: 1,
            };
        },
        // TODO: RIOINBBL-1556 consolidate and test
        showNewAuxiliaryPackagingTemplateStep: (state: DeliverySchedulesState) => {
            state.form.templateWizardState = {
                ...state.form.templateWizardState!,
                step: 2,
            };
        },
        hideTemplateDialog: (state: DeliverySchedulesState) => {
            state.form.templateWizardState = undefined;
        },
        updateFormIdInPackagingWizard: (state: DeliverySchedulesState, action: PayloadAction<string>) => {
            state.form.templateWizardState = {
                ...state.form.templateWizardState!,
                formId: action.payload,
            };
        },
        clearTemplateStepConfiguration: (state: DeliverySchedulesState) => {
            state.form.templateStepConfiguration = undefined;
        },
        // TODO: RIOINBBL-1556 make the payload action types & store structure more understandable and test
        updateTemplateStepConfiguration: (
            state: DeliverySchedulesState,
            action: PayloadAction<PackagingStepConfiguration>,
        ) => {
            state.form.templateStepConfiguration = {
                ...action.payload,
            };
            state.form.templateStepsAdded = true;
        },
        // TODO: RIOINBBL-1556 make the payload action types & store structure more understandable and test
        updateInnerPackagingTemplateStepQuantityConfiguration: (
            state: DeliverySchedulesState,
            action: PayloadAction<Quantity>,
        ) => {
            state.form.templateStepConfiguration = {
                ...state.form.templateStepConfiguration,
                quantity: action.payload,
            } as InnerPackagingStepConfiguration;
            state.form.templateStepsAdded = true;
        },
        updatePackagingTemplateName: (state: DeliverySchedulesState, action: PayloadAction<string>) => {
            const persistedPackagingTemplateName =
                state.selectedDeliverySchedulePackagingTemplates[state.selectedMetadataEntryId!].name;
            state.form.templateName = persistedPackagingTemplateName !== action.payload ? action.payload : undefined;
        },
        setPackagingTemplateDimensions: (state: DeliverySchedulesState, action: PayloadAction<Dimensions>) => {
            state.form.templateDimensions = action.payload;
        },
        updatePackagingTemplateDimension: (
            state: DeliverySchedulesState,
            action: PayloadAction<UpdatePackagingDimensionPayload>,
        ) => {
            state.form.templateDimensions = {
                ...state.form.templateDimensions,
                [action.payload.dimension]: action.payload.value,
            };
        },
        clearPackagingTemplateDimensions: (state: DeliverySchedulesState) => {
            state.form.templateDimensions = undefined;
        },
        clearPackagingTemplateName: (state: DeliverySchedulesState) => {
            state.form.templateName = undefined;
        },
        clearPackagingTemplateStepsAdded: (state: DeliverySchedulesState) => {
            state.form.templateStepsAdded = false;
        },
        createPackagingTemplateStep: (
            state: DeliverySchedulesState,
            action: PayloadAction<AuxiliaryPackagingTemplatePayload>,
        ) => {
            const selectedTemplateId = state.selectedMetadataEntryId;
            if (selectedTemplateId === undefined) {
                throw new Error('Selected template id should not be undefined. This should never happen.');
            }
            const selectedTemplate = state.selectedDeliverySchedulePackagingTemplates[selectedTemplateId];
            if (
                selectedTemplate === undefined ||
                selectedTemplate.type === PackagingTemplateType.LISON ||
                selectedTemplate.type === PackagingTemplateType.MISSING_LISON
            ) {
                throw new Error('No editable template selected. This should never happen.');
            }

            const templateStepConfiguration = state.form.templateStepConfiguration;
            if (templateStepConfiguration === undefined) {
                throw new Error('Packaging step configuration cannot be undefined. This should never happen.');
            }
            const { auxiliaryPackaging } = action.payload;
            const handlingUnit = {
                type: templateStepConfiguration.handlingUnit.type,
                description: templateStepConfiguration.handlingUnit.description,
                tareWeightInKg: templateStepConfiguration.handlingUnit.tareWeightInKg,
                ownership: templateStepConfiguration.handlingUnit.ownership,
                isReusable: templateStepConfiguration.handlingUnit.isReusable,
                stackingFactor: templateStepConfiguration.handlingUnit.stackingFactor,
            };
            switch (templateStepConfiguration.type) {
                case PackagingStepType.INNER:
                    const innerPackagingTemplateStepConfiguration =
                        templateStepConfiguration as InnerPackagingStepConfiguration;
                    selectedTemplate.steps.push({
                        id: uuid(),
                        type: PackagingStepType.INNER,
                        auxiliaryPackaging,
                        prerequisites: innerPackagingTemplateStepConfiguration.prerequisites,
                        typeOfHandlingUnit: innerPackagingTemplateStepConfiguration.typeOfHandlingUnit,
                        descriptionOfHandlingUnit: innerPackagingTemplateStepConfiguration.descriptionOfHandlingUnit,
                        articleNumberBuyer: innerPackagingTemplateStepConfiguration.articleNumberBuyer,
                        quantity: innerPackagingTemplateStepConfiguration.quantity,
                        handlingUnit,
                    });
                    state.form.templateStepsAdded = true;
                    state.form.templateDimensions = {
                        widthInMm: templateStepConfiguration.handlingUnit.widthInMm,
                        lengthInMm: templateStepConfiguration.handlingUnit.lengthInMm,
                        heightInMm: templateStepConfiguration.handlingUnit.heightInMm,
                    };
                    break;
                case PackagingStepType.HOMOGENEOUS:
                    selectedTemplate.steps.push({
                        id: uuid(),
                        type: PackagingStepType.HOMOGENEOUS,
                        auxiliaryPackaging,
                        prerequisites: templateStepConfiguration.prerequisites,
                        typeOfHandlingUnit: templateStepConfiguration.typeOfHandlingUnit,
                        descriptionOfHandlingUnit: templateStepConfiguration.descriptionOfHandlingUnit,
                        handlingUnitTypeToBePackaged: templateStepConfiguration.handlingUnitTypeToBePackaged,
                        handlingUnitDescriptionToBePackaged:
                            templateStepConfiguration.handlingUnitDescriptionToBePackaged,
                        numberOfHandlingUnitsPerLayer: templateStepConfiguration.numberOfHandlingUnitsPerLayer,
                        numberOfLayersPerHandlingUnit: templateStepConfiguration.numberOfLayersPerHandlingUnit,
                        layerStabilization: templateStepConfiguration.layerStabilization,
                        handlingUnit,
                    });
                    state.form.templateStepsAdded = true;
                    state.form.templateDimensions = {
                        widthInMm: templateStepConfiguration.handlingUnit.widthInMm,
                        lengthInMm: templateStepConfiguration.handlingUnit.lengthInMm,
                        heightInMm: templateStepConfiguration.handlingUnit.heightInMm,
                    };
                    break;
                case PackagingStepType.HETEROGENEOUS:
                    selectedTemplate.steps.push({
                        id: uuid(),
                        type: PackagingStepType.HETEROGENEOUS,
                        auxiliaryPackaging,
                        prerequisites: templateStepConfiguration.prerequisites,
                        typeOfHandlingUnit: templateStepConfiguration.typeOfHandlingUnit,
                        descriptionOfHandlingUnit: templateStepConfiguration.descriptionOfHandlingUnit,
                        content: templateStepConfiguration.content,
                        handlingUnit,
                    });
                    state.form.templateDimensions = {
                        widthInMm: templateStepConfiguration.handlingUnit.widthInMm,
                        lengthInMm: templateStepConfiguration.handlingUnit.lengthInMm,
                        heightInMm: templateStepConfiguration.handlingUnit.heightInMm,
                    };
                    state.form.templateStepsAdded = true;
                    break;
                default:
                    neverReachedFor(templateStepConfiguration);
            }
        },
        deletePackagingTemplate: (state: DeliverySchedulesState, action: PayloadAction<string>) => {
            delete state.selectedDeliverySchedulePackagingTemplates[action.payload];
            state.form.templateStepsAdded = false;
            state.form.templateName = undefined;
            state.selectedMetadataEntryId = SelectedMetadataEntryId.ARTICLE_MASTER_DATA;
        },
    },
});
