import ToggleButton from '@rio-cloud/rio-uikit/lib/es/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { State } from '../../../../../reducers';
import { getSelectedFilters } from '../../../selectors/transportOrders/transportOrders.selector';

export const TOGGLE_FILTERS_PANEL_BUTTON = 'TOGGLE_FILTERS_PANEL_BUTTON';
export const TOGGLE_FILTERS_PANEL_ICON = 'TOGGLE_FILTERS_PANEL_ICON';

interface TransportOrderFilterToggleButtonProps {
    active: boolean;
    onClick: () => void;
}

export const TransportOrdersFilterToggleButton = (props: TransportOrderFilterToggleButtonProps) => {
    const { active, onClick } = props;

    const filterParams = useAppSelector((state: State) => getSelectedFilters(state));
    const { loadingFrom, loadingTo, timeRangeType, ...relevantFilters } = filterParams;
    const activeFilterCount = Object.values(relevantFilters).filter((it) => it !== undefined).length;

    return (
        <ToggleButton
            data-testid={TOGGLE_FILTERS_PANEL_BUTTON}
            active={active}
            bsStyle={'default'}
            onClick={() => onClick()}
        >
            <span
                data-testid={TOGGLE_FILTERS_PANEL_ICON}
                className={activeFilterCount > 0 ? 'rioglyph rioglyph-filter-active' : 'rioglyph rioglyph-filter'}
            />
            <FormattedMessage id={'webedi.common.filter'} />
        </ToggleButton>
    );
};
