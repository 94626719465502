import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../../../configuration/lang/types';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { nonVanishingFormattedErrorNotification, notifySuccess } from '../../../actions/NotificationUtils';
import { usePostOperationMutation } from '../../../api/dispatchProposal/dispatchProposalApi';
import { DispatchProposal, PackagedDispatchProposalItem } from '../../../domain/dispatchProposal.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../../reducers/dispatchProposal/DispatchProposalsReducer';
import {
    getSelectedDispatchProposalItemToPackage,
    getSelectedDispatchProposalToPackage,
} from '../../../selectors/dispatchProposals/DispatchProposals.selector';
import { Footer } from '../../common/dialog/Footer';
import { PackagingMaterialFormValues } from '../../common/form/BoxFinderInputV2';
import { PackageTogetherStep1 } from './PackageTogetherStep1';
import { PackageTogetherStep2 } from './PackageTogetherStep2';
import { PackageTogetherStep3 } from './PackageTogetherStep3';
import { PackageTogetherStep4 } from './PackageTogetherStep4';
import { mapFormStateToCombinePackagingOperation } from './packageTogetherFormMapper';

export const PACKAGE_TOGETHER_FORM_ID = 'PACKAGE_TOGETHER_FORM_ID';

export interface PackageTogetherDialogProps {
    showCreateDialog: boolean;
    close: () => void;
}

export interface PackagedDispatchProposalItemWithAmount {
    dispatchProposalItem: PackagedDispatchProposalItem;
    amount?: number;
}

export interface AuxiliaryPackagings {
    amountPerHandlingUnit?: number;
    packagingMaterial: PackagingMaterialFormValues;
}

export interface PackageTogetherFormValues extends FieldValues {
    selectedItemsToPackage: PackagedDispatchProposalItemWithAmount[];
    amountOfHandlingUnits?: number;
    packagingMaterial?: PackagingMaterialFormValues;
    auxiliaryPackagings: AuxiliaryPackagings[];
}

export const PackageTogetherDialog = (props: PackageTogetherDialogProps) => {
    const { showCreateDialog, close } = props;
    const dispatch = useAppDispatch();
    const selectedDispatchProposalItemToPackage: PackagedDispatchProposalItem = useAppSelector(
        getSelectedDispatchProposalItemToPackage,
    );
    const [currentStep, setCurrentStep] = useState(1);

    const methods = useForm<PackageTogetherFormValues>({
        mode: 'all',
        values: {
            selectedItemsToPackage: [
                {
                    dispatchProposalItem: selectedDispatchProposalItemToPackage,
                },
            ],
            auxiliaryPackagings: [],
        },
        defaultValues: {
            selectedItemsToPackage: [
                {
                    dispatchProposalItem: selectedDispatchProposalItemToPackage,
                },
            ],
            auxiliaryPackagings: [],
        },
    });
    const reset = methods.reset;

    const onClose = () => {
        setCurrentStep(1);
        dispatch(dispatchProposalsSlice.actions.setSelectedDispatchProposalToPackage(undefined));
        dispatch(dispatchProposalsSlice.actions.setSelectedDispatchProposalItemToPackage(undefined));
        reset();
        close();
    };

    const dunsNumber = useDunsNumberFromPath() ?? '';
    const selectedDispatchProposalToPackage: DispatchProposal = useAppSelector(getSelectedDispatchProposalToPackage);
    const [packageTogether] = usePostOperationMutation();
    const onSubmit: SubmitHandler<PackageTogetherFormValues> = async (data) => {
        await packageTogether(
            mapFormStateToCombinePackagingOperation(
                data,
                dunsNumber,
                selectedDispatchProposalToPackage.id,
                selectedDispatchProposalItemToPackage.identifier.shipmentId,
            ),
        )
            .unwrap()
            .then(() => {
                notifySuccess('webedi.label.success.save');
            })
            .catch((error) => {
                nonVanishingFormattedErrorNotification({
                    messageId: error?.data?.errorCode
                        ? (`webedi.error.${error.data.errorCode}` as TranslationKeys)
                        : 'webedi.error.unknown',
                    errorCode: error?.data?.errorCode,
                });
            })
            .finally(onClose);
    };

    const goNext = async () => {
        const validationsPass = await methods.trigger();
        if (validationsPass) {
            setCurrentStep((current) => current + 1);
        }
    };
    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <PackageTogetherStep1 />;
            case 2:
                return <PackageTogetherStep2 />;
            case 3:
                return <PackageTogetherStep3 />;
            case 4:
                return <PackageTogetherStep4 />;
            default:
                return null;
        }
    };

    return (
        <Dialog
            show={showCreateDialog}
            onClose={onClose}
            title={<FormattedMessage id={'webedi.label.packageTogether'} />}
            body={
                <>
                    <FormProvider {...methods}>
                        {' '}
                        {
                            <div className={'padding-20 overflow-y-scroll max-height-75vh'}>
                                <form
                                    id={PACKAGE_TOGETHER_FORM_ID}
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                    className={'width-100pct'}
                                >
                                    {renderStep()}
                                </form>
                            </div>
                        }
                    </FormProvider>
                </>
            }
            footer={
                <Footer
                    formId={PACKAGE_TOGETHER_FORM_ID}
                    totalSteps={4}
                    currentStep={currentStep}
                    onClose={onClose}
                    goNext={goNext}
                />
            }
            bsSize={Dialog.SIZE_LG}
            bodyClassName={'padding-0'}
        />
    );
};
