import { SelectOption } from '@rio-cloud/rio-uikit';
import ClearableInputUiKit from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { debounce } from 'lodash';
import { KeyboardEvent, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { State } from '../../../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getShipmentsAction } from '../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { shipmentsSlice } from '../../reducers/shipments/Shipments.reducer';
import { ShipmentsQueryFor, ShipmentsQueryParams } from '../../reducers/shipments/types';
import { getShipmentsQuery } from '../../selectors/shipments/Shipments.selector';

export const SHIPMENT_SEARCH_FIELD_TEST_ID = 'SHIPMENT_SEARCH_FIELD_TEST_ID';
export const SHIPMENT_SEARCH_SELECT_TEST_ID = 'SHIPMENT_SEARCH_SELECT_TEST_ID';

export const SHIPMENT_SEARCH_DEBOUNCE_TIME = 750;

interface ShipmentSearchSelectOptions extends SelectOption {
    labelId: string;
}

export const selectOptionsForSearchFor: ShipmentSearchSelectOptions[] = [
    {
        id: ShipmentsQueryFor.SHIPMENT_NUMBER,
        label: <FormattedMessage id={'webedi.shipment'} />,
        labelId: 'webedi.shipment',
    },
    {
        id: ShipmentsQueryFor.DELIVERY_NOTE_NUMBER,
        label: <FormattedMessage id={'webedi.deliveryNotes'} />,
        labelId: 'webedi.deliveryNotes',
    },
    {
        id: ShipmentsQueryFor.ARTICLE_NUMBER,
        label: <FormattedMessage id={'webedi.deliveryInstruction.articleNumber'} />,
        labelId: 'webedi.deliveryInstruction.articleNumber',
    },
    {
        id: ShipmentsQueryFor.LABEL_NUMBER,
        label: <FormattedMessage id={'webedi.packaging.header.labelNumber'} />,
        labelId: 'webedi.packaging.header.labelNumber',
    },
];

export const ShipmentsSearch = (props: { exported: boolean }) => {
    const { exported } = props;

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const query = useAppSelector((state: State) => getShipmentsQuery(state, exported));
    const dunsNumber = useDunsNumberFromPath();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    const debouncedSearch = useCallback(
        debounce(() => {
            dispatch(getShipmentsAction(dunsNumber!, exported));
        }, SHIPMENT_SEARCH_DEBOUNCE_TIME),
        [exported],
    );
    const onKeyPress = useCallback(
        (e: KeyboardEvent) => e.key === 'Enter' && debouncedSearch.flush(),
        [debouncedSearch],
    );

    const provideSelectOptionsWithCurrentSelectedValue = (): SelectOption[] => {
        const queryFor = query.params.queryFor;
        return selectOptionsForSearchFor.map((item) => (item.id === queryFor ? { ...item, selected: true } : item));
    };

    const getSelectedLabelId = (): string | undefined => {
        const queryFor = query.params.queryFor;
        return selectOptionsForSearchFor.find((it) => it.id === queryFor)?.labelId as string;
    };

    const qSatisfiesNumericConstraints = (queryParams: ShipmentsQueryParams): boolean => {
        const { queryFor, q } = queryParams;
        switch (queryFor) {
            case ShipmentsQueryFor.SHIPMENT_NUMBER:
            case ShipmentsQueryFor.DELIVERY_NOTE_NUMBER:
            case ShipmentsQueryFor.LABEL_NUMBER:
                return /^\s*[\d]*\s*$/.test(q || '');
            default:
                return true;
        }
    };

    const qInStateViolatesNumericConstraints = !qSatisfiesNumericConstraints(query.params);

    return (
        <div className={'input-group width-400'}>
            <div className={'input-group-btn'} data-testid={SHIPMENT_SEARCH_SELECT_TEST_ID}>
                <Select
                    options={provideSelectOptionsWithCurrentSelectedValue()}
                    onChange={(selectedOption?: SelectOption) => {
                        const selectedQueryFor: ShipmentsQueryFor = selectedOption?.id as ShipmentsQueryFor;
                        if (selectedQueryFor !== query.params.queryFor) {
                            dispatch(
                                getShipmentsAction(dunsNumber!, exported, {
                                    queryFor: selectedQueryFor,
                                    q: undefined,
                                    offset: 0,
                                }),
                            );
                        }
                    }}
                    className={'dropdown-toggle'}
                />
            </div>
            <div
                className={`form-group width-300 margin-bottom-0 ${qInStateViolatesNumericConstraints ? 'has-feedback has-error' : ''}`}
            >
                <span className={'input-group-addon'}>
                    <span className={'rioglyph rioglyph-search'} aria-hidden={'true'} />
                </span>
                <MemoizedClearableInput
                    data-testid={SHIPMENT_SEARCH_FIELD_TEST_ID}
                    placeholder={intl
                        .formatMessage({ id: 'webedi.shipment.searchFor' })
                        .concat(' ', intl.formatMessage({ id: getSelectedLabelId() }))}
                    onChange={(newValue: string) => {
                        const queryUpdate = { ...query, params: { ...query.params, q: newValue, offset: 0 } };
                        dispatch(
                            exported
                                ? shipmentsSlice.actions.updateExportedQuery(queryUpdate)
                                : shipmentsSlice.actions.updateNotExportedQuery(queryUpdate),
                        );
                        qSatisfiesNumericConstraints(queryUpdate.params) ? debouncedSearch() : debouncedSearch.cancel();
                    }}
                    onKeyPress={onKeyPress}
                    value={query.params.q}
                />
                {qInStateViolatesNumericConstraints && (
                    <>
                        <span className={'form-control-feedback rioglyph rioglyph-warning-sign'} aria-hidden={true} />
                        <div>
                            <span className={'help-block'}>
                                <FormattedMessage id={'webedi.form.error.nonPositiveNumber'} />
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const MemoizedClearableInput = memo(ClearableInputUiKit);
