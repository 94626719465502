import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { CheckboxInput } from '../../form/CheckboxInput';
import { FieldErrorType } from '../../form/types';
import { auxiliaryFormPropertyNames } from './AuxiliaryPackagingFormConfiguration';
import { AuxiliaryPackagingInputs } from './AuxiliaryPackagingInputs';
import { AuxiliaryPackagingConfigurationFormValues, AuxiliaryPackagingErrorType } from './types';

export const AUXILIARY_PACKAGING_CONFIGURATION_CONTENT_LAYER_STABILIZATION_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_CONTENT_LAYER_STABILIZATION_TEST_ID';

export const validateLayerStabilization = (
    auxiliaryPackagingFormValues: AuxiliaryPackagingConfigurationFormValues,
    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
): AuxiliaryPackagingErrorType | undefined => {
    const { layerStabilization } = auxiliaryPackagingFormValues;
    if (layerStabilization) {
        const layerStabilizationErrors: AuxiliaryPackagingErrorType = {
            packagingMaterial: {},
        };
        // Even though it's a number input, final form will set the value to empty string when the user deletes
        // the inserted number
        if (
            layerStabilization.auxiliaryPackagingPerHandlingUnit === undefined ||
            String(layerStabilization.auxiliaryPackagingPerHandlingUnit) === ''
        ) {
            layerStabilizationErrors.auxiliaryPackagingPerHandlingUnit = FieldErrorType.REQUIRED;
        }
        if (layerStabilization.packagingMaterial?.boxCode === undefined) {
            layerStabilizationErrors.packagingMaterial!.boxCode = FieldErrorType.REQUIRED;
        }
        if (layerStabilization.packagingMaterial) {
            const { tareWeightInKg, ownership, isReusable, stackingFactor } = layerStabilization.packagingMaterial;

            if (tareWeightInKg === undefined || String(tareWeightInKg) === '') {
                layerStabilizationErrors.packagingMaterial!.tareWeightInKg = FieldErrorType.REQUIRED;
            }
            if (ownership === undefined) {
                layerStabilizationErrors.packagingMaterial!.ownership = FieldErrorType.REQUIRED;
            }
            if (isReusable === undefined) {
                layerStabilizationErrors.packagingMaterial!.isReusable = FieldErrorType.REQUIRED;
            }
            if (stackingFactor === undefined || String(stackingFactor) === '') {
                layerStabilizationErrors.packagingMaterial!.stackingFactor = FieldErrorType.REQUIRED;
            }
        }
        return layerStabilizationErrors;
    }
    return undefined;
};

export const LayerStabilization = (props: { disableInputs: boolean }) => {
    return (
        <div
            className={'panel panel-warning width-100pct margin-bottom-0'}
            data-testid={AUXILIARY_PACKAGING_CONFIGURATION_CONTENT_LAYER_STABILIZATION_TEST_ID}
        >
            <div className={'panel-heading'}>
                <span className={'rioglyph rioglyph-warning-sign text-size-18 text-color-white margin-right-5'} />
                <FormattedMessage id={'webedi.packaging.layerStabilizationNeeded'} tagName={'span'} />
            </div>
            <div className={'panel-body'}>
                <Field<boolean>
                    name={auxiliaryFormPropertyNames.automaticallyCalculateLayerStabilization}
                    component={CheckboxInput}
                    type={'checkbox'}
                >
                    <FormattedMessage id={'webedi.packaging.addLayerStabilizationAutomatically'} />
                </Field>
                <div className={'margin-top-15'}>
                    <AuxiliaryPackagingInputs
                        auxiliaryPackagingPropertyName={auxiliaryFormPropertyNames.layerStabilization}
                        disableInputs={props.disableInputs}
                        renderRemoveButton={false}
                    />
                </div>
            </div>
        </div>
    );
};
