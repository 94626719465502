import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { minusDuration, startOfWeek, today } from '../../../../dateUtils';
import {
    ArticleSuggestion,
    DispatchProposal,
    EditLoadItemAmountDialogData,
    ManualDispatchProposalDraft,
    PackagedDispatchProposalItem,
    areDispatchProposalItemIdentifiersEqual,
} from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import {
    AddDispatchProposalItemToTransportPlanningDraft,
    Dialog,
    DispatchProposalsFilterParams,
    RemoveDispatchProposalItemToTransportPlanningDraft,
    SetAdditionalTransportPlanningDraftInformation,
    SetDialog,
    TransportPlanningDraft,
} from './types';

export interface DispatchProposalsState {
    overviewSelectedDate: string;
    overviewStartDate: string;
    addArticleSidebarOpen: boolean;
    planDispatchProposalsSidebarOpen: boolean;
    selectedDispatchProposal: DispatchProposal | ManualDispatchProposalDraft | undefined;
    selectedArticleSuggestion: ArticleSuggestion | undefined;
    transportPlanningDraft: TransportPlanningDraft | undefined;
    courierOrderIntentDialogOpen: boolean;
    manualDispatchProposalDrafts: ManualDispatchProposalDraft[];
    supplierTimezone: string;
    editLoadItemAmountDialogData: EditLoadItemAmountDialogData | undefined;
    visibleDialog: Dialog | undefined;
    selectedDispatchProposalToPackage: DispatchProposal | undefined;
    selectedDispatchProposalItemToPackage: PackagedDispatchProposalItem | undefined;
    selectedFilters: DispatchProposalsFilterParams;
    filterValues: { [key: string]: string[] };
}

const getInitialState = (): DispatchProposalsState => {
    const currentDay = today();
    const startDate = minusDuration(startOfWeek(currentDay), { weeks: 1 });
    return {
        overviewSelectedDate: currentDay,
        overviewStartDate: startDate,
        addArticleSidebarOpen: false,
        planDispatchProposalsSidebarOpen: false,
        selectedDispatchProposal: undefined,
        selectedArticleSuggestion: undefined,
        transportPlanningDraft: undefined,
        courierOrderIntentDialogOpen: false,
        supplierTimezone: 'Europe/Berlin', // TODO: find out correct timezone
        manualDispatchProposalDrafts: [],
        editLoadItemAmountDialogData: undefined,
        visibleDialog: undefined,
        selectedDispatchProposalToPackage: undefined,
        selectedDispatchProposalItemToPackage: undefined,
        selectedFilters: {
            manufacturingCompanyList: [],
            loadingLocationList: [],
            plantNumberList: [],
            unloadingPointList: [],
            transportConceptList: [],
        },
        filterValues: {},
    };
};

export const initialState: DispatchProposalsState = getInitialState();

export const dispatchProposalsSlice = createSlice({
    name: 'dispatchProposals',
    initialState,
    reducers: {
        setOverviewSelectedDate: (state: DispatchProposalsState, action: PayloadAction<string>) => {
            state.overviewSelectedDate = action.payload.split('T')[0]; // to support date and datetime
        },
        setOverviewBeginOfWeek: (state: DispatchProposalsState, action: PayloadAction<string>) => {
            state.overviewStartDate = action.payload.split('T')[0]; // to support date and datetime
        },
        addManualDispatchProposalDraft: (
            state: DispatchProposalsState,
            action: PayloadAction<ManualDispatchProposalDraft>,
        ) => {
            state.manualDispatchProposalDrafts.push(action.payload);
        },
        removeManualDispatchProposalDraft: (state: DispatchProposalsState, action: PayloadAction<string>) => {
            state.manualDispatchProposalDrafts.forEach((value, index) => {
                if (value.id === action.payload) {
                    state.manualDispatchProposalDrafts.splice(index, 1);
                }
            });
        },
        startAddArticleToDispatchProposalWorkflow: (
            state: DispatchProposalsState,
            action: PayloadAction<DispatchProposal | ManualDispatchProposalDraft>,
        ) => {
            state.selectedDispatchProposal = action.payload;
            state.addArticleSidebarOpen = true;
            state.selectedArticleSuggestion = undefined;
        },
        stopAddArticleToDispatchProposalWorkflow: (state: DispatchProposalsState) => {
            state.selectedDispatchProposal = undefined;
            state.addArticleSidebarOpen = false;
            state.selectedArticleSuggestion = undefined;
        },
        articleSuggestionSelected: (state: DispatchProposalsState, action: PayloadAction<ArticleSuggestion>) => {
            state.selectedArticleSuggestion = action.payload;
            state.addArticleSidebarOpen = false;
        },
        articleAddedToDispatchProposal: (state: DispatchProposalsState) => {
            state.selectedDispatchProposal = undefined;
            state.selectedArticleSuggestion = undefined;
        },
        setPlanDispatchProposalsSidebarOpen: (state: DispatchProposalsState, action: PayloadAction<boolean>) => {
            state.planDispatchProposalsSidebarOpen = action.payload;
        },
        addDispatchProposalItemToTransportPlanningDraft: (
            state: DispatchProposalsState,
            action: PayloadAction<AddDispatchProposalItemToTransportPlanningDraft>,
        ) => {
            const dispatchProposalFromPayload = action.payload.dispatchProposal;
            if (dispatchProposalFromPayload.termination.transportConcept === TransportConcept.KEP) {
                return;
            }
            if (!state.transportPlanningDraft) {
                state.transportPlanningDraft = {
                    transportConcept: dispatchProposalFromPayload.termination.transportConcept,
                    freightForwarder: dispatchProposalFromPayload.termination.freightForwarder,
                    pickupDate: dispatchProposalFromPayload.termination.pickupDate,
                    pickupTimeFrom: undefined,
                    pickupTimeTo: undefined,
                    accessCode: undefined,
                    supplierTimezone: dispatchProposalFromPayload.supplierTimezone,
                    selectedDispatchProposalItems: [action.payload.item],
                };
            } else {
                state.transportPlanningDraft.selectedDispatchProposalItems.push(action.payload.item);
            }
        },
        removeDispatchProposalItemFromTransportPlanningDraft: (
            state: DispatchProposalsState,
            action: PayloadAction<RemoveDispatchProposalItemToTransportPlanningDraft>,
        ) => {
            if (state.transportPlanningDraft === undefined) {
                return;
            }
            const filteredDispatchProposalItems = state.transportPlanningDraft.selectedDispatchProposalItems.filter(
                (it) => !areDispatchProposalItemIdentifiersEqual(it, action.payload.item),
            );
            state.transportPlanningDraft.selectedDispatchProposalItems = filteredDispatchProposalItems;
            if (filteredDispatchProposalItems.length < 1) {
                state.transportPlanningDraft = undefined;
                state.planDispatchProposalsSidebarOpen = false;
            }
        },
        setAdditionalTransportPlanningDraftInformation: (
            state: DispatchProposalsState,
            action: PayloadAction<SetAdditionalTransportPlanningDraftInformation>,
        ) => {
            if (state.transportPlanningDraft === undefined) {
                return;
            }
            state.transportPlanningDraft.pickupTimeFrom = action.payload.pickupTimeFrom;
            state.transportPlanningDraft.pickupTimeTo = action.payload.pickupTimeTo;
            state.transportPlanningDraft.accessCode = action.payload.accessCode;
        },
        clearTransportPlanningDraft: (state: DispatchProposalsState) => {
            state.transportPlanningDraft = undefined;
            state.planDispatchProposalsSidebarOpen = false;
        },
        setEditLoadItemAmountDialogData: (
            state: DispatchProposalsState,
            action: PayloadAction<EditLoadItemAmountDialogData | undefined>,
        ) => {
            state.editLoadItemAmountDialogData = action.payload;
        },
        setOverlayDialogue: (state, action: PayloadAction<SetDialog>) => {
            state.visibleDialog = action.payload.dialog;
        },
        setSelectedDispatchProposalItemToPackage: (
            state,
            action: PayloadAction<PackagedDispatchProposalItem | undefined>,
        ) => {
            state.selectedDispatchProposalItemToPackage = action.payload;
        },
        setSelectedDispatchProposalToPackage: (state, action: PayloadAction<DispatchProposal | undefined>) => {
            state.selectedDispatchProposalToPackage = action.payload;
        },
        setDispatchProposalSelectedFilters: (state, action: PayloadAction<DispatchProposalsFilterParams>) => {
            state.selectedFilters = action.payload;
            state.transportPlanningDraft = undefined;
            state.planDispatchProposalsSidebarOpen = false;
        },
    },
});
