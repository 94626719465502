import { Mutators } from 'final-form-arrays';
import { Dispatch, SetStateAction } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { MeansOfTransportPropertyKeys } from '../../../domain/meansOfTransport.types';
import { DeliveryNote, LoadItem, Shipment } from '../../../reducers/shipments/types';
import { FieldErrorType } from '../../common/form/types';
import { SteppedProgressBarStep } from '../ShipmentSteppedProgressConfig';

/** The shape of the mutators once final-form has bound them to state */
export interface ShipmentFormMutators extends Mutators {
    handleOnDragEnd: (dropResult: DropResult) => void;
    addDeliveryNote: (nextDeliveryNoteNumber: number | undefined) => void;
    deleteDeliveryNote: (deliveryNote: DeliveryNote) => void;
    updateDeliveryNote: (deliveryNote: DeliveryNote) => void;
    addLoadItem: (deliveryNoteNumber: number, newLoadItem: LoadItem) => void;
    addHandlingUnitGroup: () => void;
    deleteHandlingUnit: (indexToBeRemoved: number) => void;
}

export interface ShipmentEditViewProps {
    shipment: Shipment;
}

export interface ShipmentEditHeaderProps {
    shipment: Shipment;
    pristine: boolean;
    submitForm: () => Promise<Shipment | undefined> | undefined;
    selectedStep: SteppedProgressBarStep;
    setSelectedStep: Dispatch<SetStateAction<SteppedProgressBarStep | undefined>>;
    readOnly: boolean;
    errors: ShipmentEditErrors | undefined;
}

export interface LoadItemErrors {
    amount?: {
        value?: FieldErrorType;
    };
    batchNumber?: FieldErrorType;
    additionalProductId?: AdditionalProductIdErrors;
}

interface DeliveryNoteErrors {
    loadItems?: LoadItemErrors[];
}

export interface TermsOfDeliveryErrors {
    location?: {
        id?: FieldErrorType;
        name?: FieldErrorType;
    };
}

export interface CustomsErrors {
    value?: {
        amount?: FieldErrorType;
    };
    preferenceAuthorization?: {
        text?: FieldErrorType;
    };
}

export interface ShipmentEditErrors {
    termsOfDelivery?: TermsOfDeliveryErrors;
    load?: DeliveryNoteErrors[];
    someDeliveryNotesAreEmpty?: boolean;
    customs?: CustomsErrors;
    transportOrderNumber?: FieldErrorType;
    meansOfTransport?: {
        [Key in MeansOfTransportPropertyKeys]?: FieldErrorType | MeansOfTransportFieldError;
    };
}

export interface AdditionalProductIdInformationProps {
    loadItemPath: string;
}

export interface AdditionalProductIdErrors {
    softwareStatus?: AdditionalProductIdFieldError | FieldErrorType;
    hardwareStatus?: AdditionalProductIdFieldError | FieldErrorType;
    partsGenerationStatus?: AdditionalProductIdFieldError | FieldErrorType;
}

export enum AdditionalProductIdFieldError {
    INVALID_STATUS = 'webedi.form.error.additionalProductId.statusInvalid',
}

export enum MeansOfTransportFieldError {
    SAME_VALUE_IN_MULTIPLE_FIELDS = 'webedi.form.error.meansOfTransport.sameValueInMultipleFields',
}
