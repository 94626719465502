import { FormattedMessage } from 'react-intl';
import { getDisplayCountries } from '../../../../configuration/lang/selectors';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { FreightForwarder, ManagedFreightForwarder } from '../../reducers/transportation/types';

export const AssignedFreightForwarder = (props: { freightForwarder: FreightForwarder }) => {
    const { freightForwarder } = props;

    const assignedFreightForwarder = () => {
        switch (freightForwarder.type) {
            case 'FREIGHT_FORWARDER_FROM_TRANSPORT_ORDER':
                return <AssignedFreightForwarderFromTransportOrder freightForwarder={freightForwarder} />;
            case 'MANAGED_FREIGHT_FORWARDER':
                return <AssignedManagedFreightForwarder freightForwarder={freightForwarder} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <label>
                <FormattedMessage id={'webedi.freightForwarder'} />
            </label>
            {assignedFreightForwarder()}
        </>
    );
};

const AssignedFreightForwarderFromTransportOrder = (props: { freightForwarder: FreightForwarder }) => (
    <div className={'text-size-16'}>{props.freightForwarder.name.join(' ')}</div>
);

const AssignedManagedFreightForwarder = (props: { freightForwarder: ManagedFreightForwarder }) => {
    const isoCountries = useAppSelector(getDisplayCountries);

    const { freightForwarder } = props;
    const countryName = freightForwarder.address.countryCode
        ? isoCountries[freightForwarder.address.countryCode]
        : undefined;

    return (
        <>
            <div className={'text-size-16'}>{freightForwarder.name.join(' ')}</div>
            <div>{freightForwarder.address.streetAddress.join(' ')}</div>
            {(freightForwarder.address.postalCode || freightForwarder.address.city) && (
                <div>
                    <span className={'padding-right-5'}>{freightForwarder.address.postalCode}</span>
                    <span>{freightForwarder.address.city}</span>
                </div>
            )}
            {countryName && <div>{countryName}</div>}
        </>
    );
};
