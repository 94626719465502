import { CHANGE_USER_SELECTED_LOCALE, CHANGE_USER_TOKEN_LOCALE, DISPLAY_MESSAGES_CHANGED } from './actions';
import { FALLBACK_LOCALE, getSupportedLocaleOrUndefined } from './lang';

const calculateDisplayLocale = (userSelectedLocale, userTokenLocale) => {
    return (
        getSupportedLocaleOrUndefined(userSelectedLocale) ??
        getSupportedLocaleOrUndefined(userTokenLocale) ??
        FALLBACK_LOCALE
    );
};

const applyLocale = (userSelectedLocale, userTokenLocale) => {
    const displayLocale = calculateDisplayLocale(userSelectedLocale, userTokenLocale);

    return {
        displayLocale,
        userSelectedLocale,
        userTokenLocale,
    };
};
const applyUserTokenLocale = (state, userTokenLocale) => applyLocale(state.userSelectedLocale, userTokenLocale);
const applyUserSelectedLocale = (state, userSelectedLocale) => applyLocale(userSelectedLocale, state.userTokenLocale);

export const defaultLanguageState = {
    displayMessages: null,
    displayLocale: null,
    userSelectedLocale: null,
    userTokenLocale: null,
};

const langReducer = (state = defaultLanguageState, action = {}) => {
    switch (action.type) {
        case CHANGE_USER_TOKEN_LOCALE: {
            return {
                ...state,
                ...applyUserTokenLocale(state, action.payload),
            };
        }
        case CHANGE_USER_SELECTED_LOCALE: {
            return {
                ...state,
                ...applyUserSelectedLocale(state, action.payload),
            };
        }
        case DISPLAY_MESSAGES_CHANGED: {
            return {
                ...state,
                displayMessages: action.payload,
            };
        }
        default:
            return state;
    }
};

export default langReducer;
