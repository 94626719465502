import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../../config';
import { accessToken } from '../../../../../configuration/tokenHandling/accessToken';
import { neverReachedFor } from '../../../../../utils';
import { PickupSelection } from '../../../components/dispatchProposals/courierOrderIntent/courierOrderIntentDialog.types';
import { ServiceLevelOption } from '../../../components/dispatchProposals/courierOrderIntent/secondStep/ServiceLevelSelection';
import {
    ContactDetails,
    CourierOrder,
    CourierOrderDetails,
    CourierOrderParcel,
    CourierOrderValidation,
    CourierOrderValidationError,
    CourierSelection,
    ExpressShipmentCourierOptions,
    ExpressShipmentPickupSelection,
    ExpressShipmentServiceProvider,
} from '../../../domain/courierOrderIntent.types';
import { decodeJson } from '../../apiUtils';
import {
    ApiCourierOrder,
    ApiCourierOrderValidation,
    ApiCourierOrderValidationError,
    ApiExpressSelectionPickupType,
    ApiExpressSelectionServiceProviderType,
    ApiExpressShipmentCourierOptions,
    ApiExpressShipmentServiceLevel,
    ApiParcel,
    courierOrderCodec,
    courierOrderValidationsArrayCodec,
    expressCourierShippingOptionsCodec,
} from './courierOrderIntent.types';

export interface GetShipmentPickupSelection {
    dunsNumber: string;
    dispatchProposalId: string;
    pickupSelection: ExpressShipmentPickupSelection;
}

export interface CreateOrderRequestParams {
    dunsNumber: string;
    dispatchProposalId: string;
    createOrderDetails: CourierOrderDetails;
}

interface ApiCourierShippingDetails {
    pickup_selection: ApiPickupSelection;
}

interface ApiPickupSelection {
    pickup_type: string;
    pickup_date: string;
    pickup_earliest_time: string;
    pickup_latest_time: string;
}

interface ApiCourierOrderDetails {
    pickup_selection: ApiPickupSelection;
    courier_selection: ApiCourierSelection;
    contact_details: ApiContactDetails;
}

interface ApiCourierSelection {
    service_provider: string;
    service_level: string;
    approval_person?: string;
    approval_cost_center?: string;
}

interface ApiContactDetails {
    sender_name: string;
    sender_phone: string;
    recipient_name: string;
    recipient_phone: string;
}

export interface CourierOrderValidationParams {
    dunsNumber: string;
    dispatchProposalId: string;
}

const mapToApiCourierPickupType = (pickupType: PickupSelection): ApiExpressSelectionPickupType => {
    return pickupType === PickupSelection.REGULAR
        ? ApiExpressSelectionPickupType.REGULAR
        : ApiExpressSelectionPickupType.REQUESTED;
};

const mapToApiPickupSelection = (pickupSelection: ExpressShipmentPickupSelection): ApiPickupSelection => {
    return {
        pickup_type: mapToApiCourierPickupType(pickupSelection.pickupType),
        pickup_date: pickupSelection.pickupDate,
        pickup_earliest_time: pickupSelection.pickupEarliestTime,
        pickup_latest_time: pickupSelection.pickupLatestTime,
    };
};

const mapToApiCourierShippingDetails = (pickupSelection: ExpressShipmentPickupSelection): ApiCourierShippingDetails => {
    return {
        pickup_selection: mapToApiPickupSelection(pickupSelection),
    };
};

const mapToApiCourierSelection = (courierSelection: CourierSelection): ApiCourierSelection => {
    return {
        service_provider: courierSelection.serviceProvider,
        service_level: courierSelection.serviceLevel,
        approval_person: courierSelection.approvalPerson,
        approval_cost_center: courierSelection.approvalCostCenter,
    };
};

const mapToApiContactDetails = (contactDetails: ContactDetails): ApiContactDetails => {
    return {
        sender_name: contactDetails.senderName,
        sender_phone: contactDetails.senderPhone,
        recipient_name: contactDetails.recipientName,
        recipient_phone: contactDetails.recipientPhone,
    };
};

const mapToApiCourierOrderDetails = (courierOrderDetails: CourierOrderDetails): ApiCourierOrderDetails => {
    return {
        pickup_selection: mapToApiPickupSelection(courierOrderDetails.pickupSelection),
        courier_selection: mapToApiCourierSelection(courierOrderDetails.courierSelection),
        contact_details: mapToApiContactDetails(courierOrderDetails.contactDetails),
    };
};

const mapToShipmentServiceProvider = (src: ApiExpressSelectionServiceProviderType): ExpressShipmentServiceProvider => {
    switch (src) {
        case ApiExpressSelectionServiceProviderType.UPS:
            return ExpressShipmentServiceProvider.UPS;
        case ApiExpressSelectionServiceProviderType.DHL:
            return ExpressShipmentServiceProvider.DHL;
        default:
            return neverReachedFor(src);
    }
};

const mapToShipmentServiceLevels = (src: ApiExpressShipmentServiceLevel[]): ServiceLevelOption[] => {
    return src.map((i) => ({
        id: i.code,
        code: i.code,
        label: i.name,
        requiresApproval: i.requires_approval,
    }));
};

const mapToShipmentPickupSelection = (src: ApiExpressShipmentCourierOptions): ExpressShipmentCourierOptions => ({
    serviceLevels: mapToShipmentServiceLevels(src.service_levels),
    serviceProvider: mapToShipmentServiceProvider(src.service_provider),
});

const transformGetCourierShippingOptions = (response: unknown): ExpressShipmentCourierOptions => {
    const decode = decodeJson(expressCourierShippingOptionsCodec)(response);
    return mapToShipmentPickupSelection(decode);
};

const mapApiParcelToCourierOrderParcel = (apiParcel: ApiParcel): CourierOrderParcel => {
    return {
        trackingId: apiParcel.tracking_id,
        trackingUrl: apiParcel.tracking_url,
        articleNumbers: apiParcel.article_numbers,
        packageItemNumber: apiParcel.package_item_number,
    };
};

const mapApiCourierOrderToCourierOrder = (apiCourierOrder: ApiCourierOrder): CourierOrder => ({
    printableLabel: apiCourierOrder.printable_label,
    parcels: apiCourierOrder.parcels.map((p: ApiParcel) => mapApiParcelToCourierOrderParcel(p)),
});

const transformCreateCourierOrderResponse = (response: unknown): CourierOrder => {
    const decoded = decodeJson(courierOrderCodec)(response);

    return mapApiCourierOrderToCourierOrder(decoded);
};

const mapApiCourierOrderValidationError = (src: ApiCourierOrderValidationError) => {
    switch (src) {
        case ApiCourierOrderValidationError.WEIGHT_EXCEEDED:
            return CourierOrderValidationError.WEIGHT_EXCEEDED;
        case ApiCourierOrderValidationError.LENGTH_EXCEEDED:
            return CourierOrderValidationError.LENGTH_EXCEEDED;
        case ApiCourierOrderValidationError.GIRTH_EXCEEDED:
            return CourierOrderValidationError.GIRTH_EXCEEDED;
        case ApiCourierOrderValidationError.DANGEROUS_GOODS_UNSUPPORTED:
            return CourierOrderValidationError.DANGEROUS_GOODS_UNSUPPORTED;
        default:
            return neverReachedFor(src);
    }
};

const mapApiCourierOrderValidation = (data: ApiCourierOrderValidation): CourierOrderValidation[] => {
    return data.validation_errors.map((i) => ({
        actual: i.actual,
        limit: i.allowed,
        code: mapApiCourierOrderValidationError(i.code),
    }));
};

const transformCourierOrderValidationResponse = (response: unknown): CourierOrderValidation[] => {
    const decoded = decodeJson(courierOrderValidationsArrayCodec)(response);
    return mapApiCourierOrderValidation(decoded);
};

export const courierOrderIntentApi = createApi({
    reducerPath: 'courierOrderIntentApi',
    tagTypes: ['CourierOrderIntent'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCourierShippingOptions: builder.mutation<ExpressShipmentCourierOptions, GetShipmentPickupSelection>({
            query: ({ dunsNumber, dispatchProposalId, pickupSelection }) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals/${dispatchProposalId}/courier-shipping-options`,
                method: 'POST',
                body: mapToApiCourierShippingDetails(pickupSelection),
            }),
            transformResponse: (response: unknown) => transformGetCourierShippingOptions(response),
        }),
        createCourierOrder: builder.mutation<CourierOrder, CreateOrderRequestParams>({
            query: ({ dunsNumber, dispatchProposalId, createOrderDetails }) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals/${dispatchProposalId}/courier-orders`,
                method: 'POST',
                body: mapToApiCourierOrderDetails(createOrderDetails),
            }),
            transformResponse: (response: unknown) => transformCreateCourierOrderResponse(response),
        }),
        courierOrderValidation: builder.query<CourierOrderValidation[], CourierOrderValidationParams>({
            query: ({ dunsNumber, dispatchProposalId }) => {
                return {
                    url: `/shippers/${dunsNumber}/dispatch-proposals/${dispatchProposalId}/courier-order-validations`,
                };
            },
            transformResponse: (response: unknown) => transformCourierOrderValidationResponse(response),
        }),
    }),
});

export const {
    useGetCourierShippingOptionsMutation,
    useCreateCourierOrderMutation,
    useCourierOrderValidationQuery,
    useLazyCourierOrderValidationQuery,
} = courierOrderIntentApi;
