import { zeroPadNumber } from '../../../../utils';
import {
    DeliveryForecastDocumentSettings,
    DocumentSettings,
    ShipmentDocuments,
    ShipmentDocumentsRequest,
} from '../../reducers/shipmentDocuments/ShipmentDocuments.types';
import {
    ApiDeliveryForecastDocumentSettings,
    ApiDeliveryForecastDocumentsRequest,
    ApiDocumentSettings,
    ApiShipmentDocumentsRequest,
    ApiShipmentDocumentsResponse,
} from './shipmentDocuments.types';

export const mapToShipmentDocumentsResponse = (
    shipmentDocumentsResponse: ApiShipmentDocumentsResponse,
): ShipmentDocuments => ({
    gtlDocument: mapToDocumentResponse(shipmentDocumentsResponse.gtl_document),
    tsbDocument: mapToDocumentResponse(shipmentDocumentsResponse.tsb_document),
});

const mapToDocumentResponse = (
    shipmentDocument: ApiShipmentDocumentsResponse['gtl_document'],
): ShipmentDocuments['gtlDocument'] => ({
    url: shipmentDocument.url,
    hasWarnings: shipmentDocument.has_warnings,
});

export const mapShipmentDocumentRequestToApi = (
    shipmentDocumentRequest: ShipmentDocumentsRequest,
): ApiShipmentDocumentsRequest => ({
    asn_url: shipmentDocumentRequest.asnUrl,
    document_settings: mapDocumentSettingsToApi(
        shipmentDocumentRequest.documentSettings,
        shipmentDocumentRequest.shipmentNumber,
    ),
});

const mapDocumentSettingsToApi = (
    settings: DocumentSettings | undefined,
    shipmentNumber: number,
): ApiDocumentSettings | undefined => {
    if (!settings) {
        return undefined;
    }

    const comment = mapCommentToApi(settings.tsbSettings.comment, shipmentNumber);
    const comments = comment ? [comment] : [];

    return {
        tsb_settings: {
            language: settings.tsbSettings.language,
            comments,
            include_receipt_information: settings.tsbSettings.includeReceiptInformation,
            include_comments: comments.length > 0,
            merge_packages: settings.tsbSettings.mergePackages,
            merge_loading_units: settings.tsbSettings.mergeLoadingUnits,
            paper_format: settings.tsbSettings.paperFormat,
            copies: {
                num_shipment_master_sheets: settings.tsbSettings.copies.numShipmentMasterSheets,
                num_shipment_position_sheets: settings.tsbSettings.copies.numShipmentPositionSheets,
                num_barcode_sheets: settings.tsbSettings.copies.numBarcodeSheets,
                num_loading_unit_sheets: settings.tsbSettings.copies.numLoadingUnitSheets,
            },
        },
        gtl_settings: {
            language: settings.gtlSettings.language,
            include_labels_for_intermediate_levels: settings.gtlSettings.labelsForIntermediateLevels,
            label_size: settings.gtlSettings.labelSize,
            paper_format: settings.gtlSettings.paperFormat,
            special_sizes: {
                additional_small_klt_types: settings.gtlSettings.specialSizes.additionalSmallKltTypes,
                tray_types: settings.gtlSettings.specialSizes.trayTypes,
            },
            extra_margin_in_mm: {
                bottom: settings.gtlSettings.extraMarginInMm.bottom,
                top: settings.gtlSettings.extraMarginInMm.top,
            },
            include_labels_only_for_outermost_level: settings.gtlSettings.includeLabelsOnlyForOuterMostLevel,
            plant_specific_settings: {
                num_label_copies: settings.gtlSettings.plantSpecificSettings.numberOfLabelCopies,
                apply_for_plants: settings.gtlSettings.plantSpecificSettings.applyForPlants,
            },
        },
    };
};

const mapCommentToApi = (originalComment: string | undefined, number: number) => {
    if (!originalComment || originalComment.trim().length === 0) {
        return undefined;
    }

    const shipmentNumber = zeroPadNumber(number, 8);
    return {
        consignment_number: shipmentNumber.toString(),
        comment: originalComment,
    };
};

export const mapDeliveryForecastDocumentsRequestToApi = (
    deliveryForecastUrl: string,
    settings: DeliveryForecastDocumentSettings,
): ApiDeliveryForecastDocumentsRequest => ({
    delivery_forecast_url: deliveryForecastUrl,
    document_settings: mapDeliveryForecastDocumentSettingsToApi(settings),
});

const mapDeliveryForecastDocumentSettingsToApi = (
    setting: DeliveryForecastDocumentSettings,
): ApiDeliveryForecastDocumentSettings => ({
    language: setting.documentLanguage,
    article_number_buyer: setting.articleNumberBuyer,
});
