import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getShipmentAction } from '../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath, useShipmentIdFromPath } from '../../hooks/Routing.hooks';
import { getSelectedShipment, isLoadingShipments } from '../../selectors/shipments/Shipments.selector';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { ShipmentEditView } from './shipmentEditView/ShipmentEditView';
import { ShipmentReadView } from './shipmentReadView/ShipmentReadView';

export const ShipmentView = () => {
    const dispatch = useAppDispatch();

    const dunsNumber = useDunsNumberFromPath();
    const shipmentId = useShipmentIdFromPath();
    const shipment = useAppSelector(getSelectedShipment);
    const isLoading = useAppSelector(isLoadingShipments);

    useEffect(() => {
        if (!shipment) {
            dispatch(getShipmentAction(dunsNumber!, shipmentId!));
        }
    }, [dunsNumber, shipmentId, shipment, dispatch]);

    if (!shipment) {
        return isLoading ? (
            <LoadingIndicator />
        ) : (
            <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />
        );
    }

    return shipment.exported ? <ShipmentReadView shipment={shipment} /> : <ShipmentEditView shipment={shipment} />;
};
