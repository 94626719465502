import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';
import { ReactNode, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { reportErrorToSentry } from '../../../../../../../configuration/setup/sentry';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../../../hooks/useImage';
import { ValidPackagingMaterial } from '../../../../../reducers/packaging/types';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import {
    GroupedHandlingUnits,
    PackagingStep,
    UpdateHeterogeneousAdditionalPackagingConfigurationPayload,
} from '../../../../../reducers/shipments/packaging.types';
import {
    getPackagingMaterialForPackagingConfiguration,
    getSelectedGroupedHandlingUnits,
} from '../../../../../selectors/packaging/Packaging.selector';
import { getSelectedShipmentId } from '../../../../../selectors/shipments/Shipments.selector';
import { Tooltip } from '../../../../common/Tooltip';
import { CheckboxInput } from '../../../../common/form/CheckboxInput';
import { NumberInput } from '../../../../common/form/NumberInput';
import { FieldErrorType, FormErrors } from '../../../../common/form/types';
import { ManyHandlingUnitsWarning } from '../ManyHandlingUnitsWarning';
import { PackagedArticlesInfoTooltip } from '../PackagedArticlesInfoTooltip';
import { SplitView } from '../SplitView';
import { PackagedPackages } from './PackagedPackages';
import { UnpackagedPackages } from './UnpackagedPackages';

export const HETEROGENEOUS_PACKAGING_CONTENT_TEST_ID = 'HETEROGENEOUS_PACKAGING_CONTENT_TEST_ID';
export const HETEROGENEOUS_PACKAGING_GROUP_INPUT_TEST_ID = 'HETEROGENEOUS_PACKAGING_GROUP_INPUT_TEST_ID';
export const HETEROGENEOUS_PACKAGING_GROUP_INPUT_CONTAINER_TEST_ID =
    'HETEROGENEOUS_PACKAGING_GROUP_INPUT_CONTAINER_TEST_ID';
export const HETEROGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_CONTAINER_TEST_ID =
    'HETEROGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_CONTAINER_TEST_ID';
export const HETEROGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_TEST_ID =
    'HETEROGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_TEST_ID';
export const NUMBER_OF_PACKAGES_PER_HANDLING_UNIT_DESCRIPTION_TEST_ID =
    'NUMBER_OF_PACKAGES_PER_HANDLING_UNIT_DESCRIPTION';

export const HeterogeneousConfigurationFormId = {
    form: 'heterogeneousPackagingConfigurationDialog',
    calculateNumberOfHandlingUnits: 'calculateNumberOfHandlingUnits',
    contentPerHandlingUnit: 'contentPerHandlingUnit',
    numberOfHandlingUnits: 'numberOfHandlingUnits',
    amountOfContentToPack: 'amountOfContentToPack',
    packagingMaterial: 'packagingMaterial',
};

export interface HeterogeneousPackaging {
    packagingType: string;
    packagingPerHandlingUnit?: number;
    hash: string;
    toBePackagedAmount: number;
    packagedAmount?: number;
}

export interface HeterogeneousPackagingConfigurationFormValues {
    heterogeneousPackaging: HeterogeneousPackaging[];
    groupedHandlingUnitsToPack: GroupedHandlingUnits[];
    calculateNumberOfHandlingUnits: boolean;
    numberOfHandlingUnits?: number;
}

export const formPropertyNames: Record<
    keyof HeterogeneousPackagingConfigurationFormValues,
    keyof HeterogeneousPackagingConfigurationFormValues
> = {
    heterogeneousPackaging: 'heterogeneousPackaging',
    groupedHandlingUnitsToPack: 'groupedHandlingUnitsToPack',
    calculateNumberOfHandlingUnits: 'calculateNumberOfHandlingUnits',
    numberOfHandlingUnits: 'numberOfHandlingUnits',
};

export enum HeterogeneousPackagingConfigurationErrorType {
    CONTAINS_EMPTY_HANDLING_UNITS = 'webedi.packaging.form.error.containsEmptyHandlingUnits',
}

type HeterogeneousPackagingFormErrors = FormErrors<
    HeterogeneousPackagingConfigurationFormValues,
    HeterogeneousPackagingConfigurationErrorType
>;

export const validateForm = ({
    heterogeneousPackaging,
    groupedHandlingUnitsToPack,
    numberOfHandlingUnits,
}: HeterogeneousPackagingConfigurationFormValues): HeterogeneousPackagingFormErrors => {
    const validationResult: HeterogeneousPackagingFormErrors = {
        heterogeneousPackaging: [],
        calculateNumberOfHandlingUnits: undefined,
        numberOfHandlingUnits: undefined,
    };

    let hasInvalidHeterogeneousPackagingConfiguration = false;
    validationResult.heterogeneousPackaging = heterogeneousPackaging.map((value, index) => {
        const { packagingPerHandlingUnit } = value;

        if (packagingPerHandlingUnit === undefined || packagingPerHandlingUnit <= 0) {
            hasInvalidHeterogeneousPackagingConfiguration = true;
            return { packagingPerHandlingUnit: FieldErrorType.NON_POSITIVE_NUMBER };
        }

        if (packagingPerHandlingUnit > groupedHandlingUnitsToPack[index].quantity) {
            hasInvalidHeterogeneousPackagingConfiguration = true;
            return { packagingPerHandlingUnit: FieldErrorType.TOO_BIG };
        }

        return {};
    });

    if (numberOfHandlingUnits === undefined || numberOfHandlingUnits <= 0) {
        validationResult.numberOfHandlingUnits = FieldErrorType.NON_POSITIVE_NUMBER;
    } else if (!hasInvalidHeterogeneousPackagingConfiguration) {
        const requiredNumberOfHandlingUnits = Math.min(
            ...groupedHandlingUnitsToPack.map((value: GroupedHandlingUnits, index: number) => {
                return Math.floor(value.quantity / heterogeneousPackaging[index].packagingPerHandlingUnit!);
            }),
        );
        if (numberOfHandlingUnits > requiredNumberOfHandlingUnits) {
            validationResult.numberOfHandlingUnits =
                HeterogeneousPackagingConfigurationErrorType.CONTAINS_EMPTY_HANDLING_UNITS;
        }
    }

    return validationResult;
};

const calculator = createDecorator(
    {
        field: [
            // @ts-expect-error react-final-form package lacks correct typing
            RegExp(`${formPropertyNames.heterogeneousPackaging}\\[\\d+]\\.packagingPerHandlingUnit`),
            formPropertyNames.calculateNumberOfHandlingUnits,
        ],
        updates: {
            [formPropertyNames.numberOfHandlingUnits]: (_, allValues) => {
                if (allValues === undefined) {
                    return undefined;
                }
                const formValues = allValues as HeterogeneousPackagingConfigurationFormValues;
                if (!formValues.calculateNumberOfHandlingUnits) {
                    return formValues.numberOfHandlingUnits;
                }
                const numberOfHandlingUnitsForEachHandlingUnit = formValues.groupedHandlingUnitsToPack.map(
                    (value: GroupedHandlingUnits, index: number) => {
                        const packagingPerHandlingUnit =
                            formValues.heterogeneousPackaging[index].packagingPerHandlingUnit;
                        if (packagingPerHandlingUnit) {
                            return Math.floor(value.quantity / packagingPerHandlingUnit);
                        } else {
                            return -1;
                        }
                    },
                );
                if (numberOfHandlingUnitsForEachHandlingUnit.includes(-1)) {
                    return formValues.numberOfHandlingUnits;
                } else {
                    return Math.min(...numberOfHandlingUnitsForEachHandlingUnit);
                }
            },
        },
    },
    {
        field: [
            RegExp(`${formPropertyNames.heterogeneousPackaging}\\[\\d+]\\.packagingPerHandlingUnit`),
            formPropertyNames.calculateNumberOfHandlingUnits,
            formPropertyNames.numberOfHandlingUnits,
        ],
        updates: {
            [formPropertyNames.heterogeneousPackaging]: (_, allValues) => {
                const formValues = allValues as HeterogeneousPackagingConfigurationFormValues;

                return formValues.heterogeneousPackaging.map((value: HeterogeneousPackaging, index: number) => {
                    const toBePackagedAmount = formValues.groupedHandlingUnitsToPack[index].quantity;
                    const packagesPerOuterPackaging = value.packagingPerHandlingUnit;

                    if (formValues.numberOfHandlingUnits && packagesPerOuterPackaging) {
                        const packagedAmount = formValues.numberOfHandlingUnits * packagesPerOuterPackaging;
                        return {
                            ...value,
                            toBePackagedAmount,
                            packagedAmount: Math.min(Math.max(packagedAmount, 0), toBePackagedAmount),
                        };
                    } else {
                        return {
                            ...value,
                        };
                    }
                });
            },
        },
    },
) as Decorator<HeterogeneousPackagingConfigurationFormValues, Partial<HeterogeneousPackagingConfigurationFormValues>>;

export const HeterogeneousPackagingConfiguration = () => {
    const dispatch = useAppDispatch();
    // TODO WEBEDI-620: FE: Fix order of selected GroupedHandlingUnits
    const groupedHandlingUnitsToPack: GroupedHandlingUnits[] = useAppSelector(getSelectedGroupedHandlingUnits);
    const selectedShipmentId = useAppSelector(getSelectedShipmentId);
    const outerPackagingMaterialFromPreviousStep = useAppSelector(getPackagingMaterialForPackagingConfiguration);

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(HeterogeneousConfigurationFormId.form));
    }, [dispatch]);

    if (groupedHandlingUnitsToPack.length <= 1) {
        reportErrorToSentry(new Error('Try to render Heterogeneous packaging with one grouped handling unit'));
        return <div />;
    }

    const initialValues: HeterogeneousPackagingConfigurationFormValues = {
        heterogeneousPackaging: groupedHandlingUnitsToPack.map((groupedHandlingUnits) => ({
            packagingType: groupedHandlingUnits.type,
            packagingPerHandlingUnit: undefined,
            hash: groupedHandlingUnits.hash,
            toBePackagedAmount: groupedHandlingUnits.quantity,
            packagedAmount: undefined,
        })),
        groupedHandlingUnitsToPack,
        calculateNumberOfHandlingUnits: true,
        numberOfHandlingUnits: undefined,
    };

    const getPackagingQuantityForHandlingUnitType = (
        heterogeneousPackaging: HeterogeneousPackaging[],
        handlingUnitHash: string,
    ): number => {
        const matchingPackaging = heterogeneousPackaging.find((packaging) => packaging.hash === handlingUnitHash);
        if (!matchingPackaging || !matchingPackaging.packagingPerHandlingUnit) {
            new Error('Matching HeterogeneousPackaging not found for hash');
        }
        return matchingPackaging!.packagingPerHandlingUnit!;
    };

    const onSubmitHandler = (values: HeterogeneousPackagingConfigurationFormValues) => {
        if (selectedShipmentId === undefined) {
            throw new Error('shipment must be selected before submitting form values. This should never happen');
        }
        const validatedValues = values as Required<HeterogeneousPackagingConfigurationFormValues>;
        const packagingConfiguration: UpdateHeterogeneousAdditionalPackagingConfigurationPayload = {
            contentPerHandlingUnit: groupedHandlingUnitsToPack.map((handlingUnitToPack) => ({
                hashOfContainedHandlingUnitGroup: handlingUnitToPack.hash,
                quantity: getPackagingQuantityForHandlingUnitType(
                    validatedValues.heterogeneousPackaging,
                    handlingUnitToPack.hash,
                ),
                handlingUnitType: handlingUnitToPack.type,
                handlingUnitDescription: handlingUnitToPack.description,
                handlingUnit: {
                    type: handlingUnitToPack.type,
                    description: handlingUnitToPack.description,
                    tareWeightInKg: handlingUnitToPack.handlingUnit.tareWeightInKg,
                    ownership: handlingUnitToPack.handlingUnit.ownership,
                    isReusable: handlingUnitToPack.handlingUnit.isReusable,
                    stackingFactor: handlingUnitToPack.handlingUnit.stackingFactor,
                },
            })),
            handlingUnitType: outerPackagingMaterialFromPreviousStep.boxCode,
            handlingUnitDescription: outerPackagingMaterialFromPreviousStep.name,
            numberOfHandlingUnits: validatedValues.numberOfHandlingUnits,
            handlingUnit: {
                type: outerPackagingMaterialFromPreviousStep.boxCode,
                description: outerPackagingMaterialFromPreviousStep.name,
                tareWeightInKg: outerPackagingMaterialFromPreviousStep.tareWeightInKg,
                ownership: outerPackagingMaterialFromPreviousStep.ownership,
                isReusable: outerPackagingMaterialFromPreviousStep.isReusable,
                stackingFactor: outerPackagingMaterialFromPreviousStep.stackingFactor,
            },
        };
        dispatch(packagingSlice.actions.addHeterogeneousAdditionalPackagingConfiguration(packagingConfiguration));
        dispatch(packagingSlice.actions.setPackagingStep(PackagingStep.AUXILIARY_CONFIGURATION));
    };

    return (
        <Form<HeterogeneousPackagingConfigurationFormValues>
            onSubmit={onSubmitHandler}
            initialValues={initialValues}
            keepDirtyOnReinitialize={true}
            decorators={[calculator]}
            validate={validateForm}
            render={({ handleSubmit, values }) => {
                const groupedHandlingUnitsPackagingInformation = values.heterogeneousPackaging.map((handlingUnit) => ({
                    packagedAmount: handlingUnit.packagedAmount,
                    toBePackagedAmount: handlingUnit.toBePackagedAmount,
                    packagingMaterialBoxCode: handlingUnit.packagingType,
                }));

                return (
                    <div className={'display-flex flex-column align-items-center width-100pct'}>
                        <form
                            id={HeterogeneousConfigurationFormId.form}
                            onSubmit={handleSubmit}
                            className={'width-100pct'}
                        >
                            <SplitView
                                left={{
                                    content: (
                                        <UnpackagedPackages
                                            groupedHandlingUnits={groupedHandlingUnitsPackagingInformation}
                                        />
                                    ),
                                    alignItemsCenter: true,
                                }}
                                main={{
                                    content: (
                                        <HeterogeneousPackagingConfigurationContent
                                            groupedHandlingUnitsToPack={groupedHandlingUnitsToPack}
                                            outerPackagingMaterialFromPreviousStep={
                                                outerPackagingMaterialFromPreviousStep
                                            }
                                            formValues={values}
                                        />
                                    ),
                                    alignItemsCenter: true,
                                    renderAsColumn: true,
                                }}
                                right={{
                                    content: (
                                        <PackagedPackages
                                            groupedHandlingUnits={groupedHandlingUnitsPackagingInformation}
                                            outerPackagingBoxCode={outerPackagingMaterialFromPreviousStep!.boxCode!}
                                        />
                                    ),
                                    alignItemsCenter: true,
                                }}
                                dataTestId={HETEROGENEOUS_PACKAGING_CONTENT_TEST_ID}
                            />
                        </form>
                    </div>
                );
            }}
        />
    );
};

const HeterogeneousPackagingConfigurationContent = ({
    groupedHandlingUnitsToPack,
    outerPackagingMaterialFromPreviousStep,
    formValues,
}: {
    groupedHandlingUnitsToPack: GroupedHandlingUnits[];
    outerPackagingMaterialFromPreviousStep: ValidPackagingMaterial;
    formValues: HeterogeneousPackagingConfigurationFormValues;
}) => {
    const multiPackageImage = useImage('multiPackage');

    return (
        <>
            <span className={'text-size-18 text-center margin-bottom-25'}>
                <FormattedMessage id={'webedi.packaging.homogeneousPackaging.title'} />
            </span>
            <span className={'margin-bottom-20 text-center'}>
                <FormattedMessage
                    id={'webedi.packaging.heterogeneousPackaging.explanation'}
                    values={{
                        innerPackagingMaterialBoxCodes: groupedHandlingUnitsToPack
                            .map((groupedHandlingUnit) => groupedHandlingUnit.type)
                            .join(', '),
                        outerPackagingMaterial: outerPackagingMaterialFromPreviousStep.boxCode,
                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                    }}
                />
                {'.'}
            </span>
            <div>
                {formValues.heterogeneousPackaging.map((_, index) => {
                    const name = `heterogeneousPackaging[${index}]`;
                    return (
                        <div key={name}>
                            <HeterogeneousPackagingInput
                                index={index}
                                heterogeneousPackagingInputPropertyName={name}
                                groupedHandlingUnits={formValues.groupedHandlingUnitsToPack[index]}
                            />
                            <hr className={'padding-top-10 padding-bottom-10'} />
                        </div>
                    );
                })}
                <div className={'display-flex flex-row justify-content-center margin-bottom-20'}>
                    <Field
                        id={HeterogeneousConfigurationFormId.calculateNumberOfHandlingUnits}
                        name={formPropertyNames.calculateNumberOfHandlingUnits}
                        component={CheckboxInput}
                        type={'checkbox'}
                        tabIndex={-1}
                    >
                        <FormattedMessage id={'webedi.packaging.inner.calculateNumberOfHandlingUnits'} />
                    </Field>
                </div>
                <div className={'display-flex flex-row align-items-center'}>
                    <div className={'flex-column width-60pct text-center'}>
                        <img src={multiPackageImage} className={'width-60pct height-60pct'} alt={'multiPackage'} />
                    </div>
                    <div
                        className={`flex-column width-60pct counter color-highlight ${formValues.calculateNumberOfHandlingUnits ? 'opacity-40' : ''}`}
                    >
                        <div
                            className={'display-flex flex-column align-items-baseline'}
                            data-count={(groupedHandlingUnitsToPack.length + 3).toString()}
                            data-testid={HETEROGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_CONTAINER_TEST_ID}
                        >
                            <label
                                className={'text-size-14 text-color-darkest'}
                                htmlFor={HeterogeneousConfigurationFormId.numberOfHandlingUnits}
                            >
                                <FormattedMessage
                                    id={'webedi.packaging.homogeneousPackaging.numberOfHandlingUnits.label'}
                                    values={{
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                        handlingUnit: `${outerPackagingMaterialFromPreviousStep?.boxCode}${outerPackagingMaterialFromPreviousStep?.name ? ` - ${outerPackagingMaterialFromPreviousStep?.name}` : ''}`,
                                    }}
                                />
                            </label>
                            <Field
                                id={HeterogeneousConfigurationFormId.numberOfHandlingUnits}
                                name={formPropertyNames.numberOfHandlingUnits}
                                component={NumberInput}
                                className={'form-control'}
                                forbidScientificNotation
                                min={1}
                                outerClassName={'width-100pct'}
                                errorMessageClassName={'text-size-10'}
                                disabled={formValues.calculateNumberOfHandlingUnits}
                                parse={(value) => value && parseInt(value, 10)}
                                data-testid={HETEROGENEOUS_PACKAGING_NUMBER_OF_HANDLING_UNITS_INPUT_TEST_ID}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ManyHandlingUnitsWarning numberOfHandlingUnits={formValues.numberOfHandlingUnits} />
        </>
    );
};

export const HeterogeneousPackagingInput = ({
    heterogeneousPackagingInputPropertyName,
    groupedHandlingUnits,
    index,
}: {
    heterogeneousPackagingInputPropertyName: string;
    groupedHandlingUnits: GroupedHandlingUnits;
    index: number;
}) => {
    const id = `${heterogeneousPackagingInputPropertyName}.packagingPerHandlingUnit`;
    const joinedArticleNumbers = groupedHandlingUnits.packagedArticlesInfo
        .map((article) => article.articleNumberBuyer)
        .join(', ');
    const miscPackageImage = useImage('miscPackage');

    return (
        <div className={'display-flex flex-row align-items-center'}>
            <div className={'flex-column width-60pct text-center'}>
                <img src={miscPackageImage} className={'width-50pct height-50pct'} alt={'miscPackage'} />
            </div>
            <div className={`flex-column width-60pct counter color-highlight`}>
                <div
                    className={'display-flex flex-column align-items-baseline'}
                    data-count={(3 + index).toString()}
                    data-testid={HETEROGENEOUS_PACKAGING_GROUP_INPUT_CONTAINER_TEST_ID}
                >
                    <label
                        className={'text-size-14 text-color-darkest'}
                        htmlFor={HeterogeneousConfigurationFormId.contentPerHandlingUnit}
                    >
                        <FormattedMessage
                            id={'webedi.packaging.outer.numberOfPackagesPerHandlingUnit.label'}
                            values={{
                                innerHandlingUnitTypeAndDescription: `${groupedHandlingUnits.type}${groupedHandlingUnits.description ? ` - ${groupedHandlingUnits.description}` : ''}`,
                                b: (chunks: ReactNode) => <b>{chunks}</b>,
                            }}
                        />
                        &nbsp;
                        <PackagedArticlesInfoTooltip packagedArticlesInfo={groupedHandlingUnits.packagedArticlesInfo} />
                        {joinedArticleNumbers && (
                            <div className={'text-size-12 text-color-gray ellipsis-1'}>
                                <Tooltip text={joinedArticleNumbers} placement={'bottom'}>
                                    <span>
                                        <FormattedMessage id={'webedi.packaging.contents'} />
                                        {': '}
                                        {joinedArticleNumbers}
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    </label>
                    <Field
                        id={id}
                        name={`${heterogeneousPackagingInputPropertyName}.packagingPerHandlingUnit`}
                        component={NumberInput}
                        className={'form-control'}
                        forbidScientificNotation
                        min={1}
                        max={groupedHandlingUnits.quantity}
                        outerClassName={'width-100pct'}
                        errorMessageClassName={'text-size-10'}
                        disabled={false}
                        parse={(value) => value && parseInt(value, 10)}
                        data-testid={HETEROGENEOUS_PACKAGING_GROUP_INPUT_TEST_ID}
                    />
                </div>
            </div>
        </div>
    );
};
