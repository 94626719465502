import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { useGetDispatchProposalsQuery } from '../../../../api/dispatchProposal/dispatchProposalApi';
import {
    DispatchProposal,
    DispatchProposalItem,
    DispatchProposalNotCompletelyPackagedProblem,
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    UnpackagedDispatchProposalItem,
    UnpackagedDispatchProposalItemIdentifier,
    isDispatchProposalNotCompletelyPackagedProblem,
} from '../../../../domain/dispatchProposal.types';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import { getOverviewDateRangeISO } from '../../../../selectors/dispatchProposals/DispatchProposals.selector';
import { DispatchProposalProblemBannerFunctions, ProblemDescriptorRow } from '../DispatchProposalProblemBanner';
import {
    BannerIcon,
    FormattedArticleNumber,
    HorizontalLine,
    colorByProblemLevel,
} from '../DispatchProposalProblemBannerFormatters';

const filterDispatchProposalNotCompletelyPackagedProblem = (problems: DispatchProposalProblem[]) =>
    problems.filter(isDispatchProposalNotCompletelyPackagedProblem);

const useMapToDispatchProposalNotCompletelyPackagedProblem = (problemBase: DispatchProposalProblemBase[]) => {
    const problems = problemBase as DispatchProposalNotCompletelyPackagedProblem[];
    const rows = useUnpackedArticleNumbersBuyer(problems);

    if (problemBase.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    return {
        title: <NewDispatchProposalNotCompletelyPackagedProblemTitle entries={rows} />,
        rows,
        formatting: {
            testId: problems[0].type,
            horizontalDivider: <HorizontalLine level={alertLevel} />,
            icon: <BannerIcon level={alertLevel} />,
            buttonStyle: colorByProblemLevel(alertLevel),
            printEntries: true,
        },
    };
};

const useUnpackedArticleNumbersBuyer = (
    problems: DispatchProposalNotCompletelyPackagedProblem[],
): ProblemDescriptorRow[] => {
    const dunsNumber = useDunsNumberFromPath()!;
    const dispatchProposalsDateRange = useAppSelector(getOverviewDateRangeISO);

    const { data: dispatchProposals, isLoading: isLoadingProposals } = useGetDispatchProposalsQuery(
        {
            dunsNumber,
            cutoffDateFrom: dispatchProposalsDateRange.from,
            cutoffDateTo: dispatchProposalsDateRange.to,
        },
        {
            skip: dunsNumber === '',
            pollingInterval: 60000,
        },
    );

    if (isLoadingProposals || dispatchProposals === undefined) {
        return [];
    }

    return problems.flatMap((p) => obtainUnpackedArticleNumbersFromOneDispatchProposal(dispatchProposals, p));
};

const obtainUnpackedArticleNumbersFromOneDispatchProposal: (
    dispatchProposals: DispatchProposal[],
    problem: DispatchProposalNotCompletelyPackagedProblem,
) => ProblemDescriptorRow[] = (
    dispatchProposals: DispatchProposal[],
    problem: DispatchProposalNotCompletelyPackagedProblem,
) => {
    const dispatchProposal = dispatchProposals.find((item) => item.id === problem.dispatchProposalId)!;
    const unpackagedDispatchProposalItems = dispatchProposal.items.filter(
        (item) => item.type === 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM',
    );

    const unpackagedDispatchProposalItemsOfProblem = unpackagedDispatchProposalItems.filter((item) =>
        problemContainedInDispatchProposal(item, problem),
    );
    return unpackagedDispatchProposalItemsOfProblem.map((item) => {
        return {
            id: problem.id,
            identifier: (
                <FormattedArticleNumber
                    articleNumber={(item as UnpackagedDispatchProposalItem).articleContent.articleNumberBuyer}
                />
            ),
            description: <WithInternationalDescription />,
            actionButton: undefined,
        } as ProblemDescriptorRow;
    });
};

const problemContainedInDispatchProposal: (
    item: DispatchProposalItem,
    problem: DispatchProposalNotCompletelyPackagedProblem,
) => boolean = (item: DispatchProposalItem, problem: DispatchProposalNotCompletelyPackagedProblem) => {
    const it = item.identifier as UnpackagedDispatchProposalItemIdentifier;
    return problem.identifiers.some(
        (identifier) =>
            identifier.shipmentId === it.shipmentId &&
            identifier.deliveryNoteNumber === it.deliveryNoteNumber &&
            identifier.deliveryNotePosition === it.deliveryNotePosition,
    );
};

const WithInternationalDescription = () => {
    return (
        <p>
            <strong>
                <FormattedMessage id='webedi.dispatchProposals.problemBanner.dispatchProposalNotCompletelyPackaged.rowDescription' />
            </strong>
        </p>
    );
};

export const NewDispatchProposalNotCompletelyPackagedProblemTitle = (props: { entries: ProblemDescriptorRow[] }) => {
    const entries = props.entries;
    if (entries.length === 0) {
        return <></>;
    }
    const id =
        entries.length === 1
            ? 'webedi.dispatchProposals.problemBanner.dispatchProposalNotCompletelyPackaged.title.one'
            : 'webedi.dispatchProposals.problemBanner.dispatchProposalNotCompletelyPackaged.title.multiple';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} values={{ numberOfPositions: entries.length }} />
        </strong>
    );
};

export const dispatchProposalNotCompletelyPackagedProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterDispatchProposalNotCompletelyPackagedProblem,
    useGenerateProblemDescriptor: useMapToDispatchProposalNotCompletelyPackagedProblem,
};
