import { SelectOption } from '@rio-cloud/rio-uikit';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { IncotermsCode } from '../../../../reducers/shipments/types';
import { SelectInput } from '../../../common/form/SelectInput';
import { TextInput } from '../../../common/form/TextInput';

export const INCOTERM_EDIT_TEST_ID = 'INCOTERM_EDIT_TEST_ID';
export const INCOTERM_EDIT_INCOTERMSCODE_TEST_ID = 'INCOTERM_EDIT_INCOTERMSCODE_TEST_ID';
export const INCOTERM_EDIT_LOCATION_NAME_TEST_ID = 'INCOTERM_EDIT_LOCATION_NAME_TEST_ID';

export const IncotermEdit = () => {
    const intl = useIntl();

    const incotermOptions: SelectOption[] = Object.keys(IncotermsCode).map((incotermCode) => ({
        id: incotermCode,
        label: `${incotermCode} - ${intl.formatMessage({ id: `webedi.incoterm.${incotermCode}` })}`,
    }));

    return (
        <div className={'display-flex-sm flex-row align-items-center width-100pct'} data-testid={INCOTERM_EDIT_TEST_ID}>
            <div className={'width-33pct-sm padding-right-15'} data-testid={INCOTERM_EDIT_INCOTERMSCODE_TEST_ID}>
                <label>
                    <FormattedMessage id={'webedi.incoterm'} />
                </label>
                <Field
                    name={'termsOfDelivery.incotermsCode'}
                    component={SelectInput}
                    options={incotermOptions}
                    useFilter={true}
                />
            </div>
            <div className={'width-33pct-sm padding-right-15'} data-testid={INCOTERM_EDIT_LOCATION_NAME_TEST_ID}>
                <label>
                    <FormattedMessage id={'webedi.location.name'} />
                </label>
                <Field<string>
                    name={'termsOfDelivery.location.name'}
                    component={TextInput}
                    className={'form-control'}
                />
            </div>
        </div>
    );
};
