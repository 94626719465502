import { PackagingMaterial } from '../../reducers/packaging/types';
import { ApiPackagingMaterial } from '../shared/packagingMaterial.types';

export const mapPackagingMaterial = (apiPackagingMaterial: ApiPackagingMaterial): PackagingMaterial => ({
    boxCode: apiPackagingMaterial.box_code,
    name: apiPackagingMaterial.name,
    outerHeightInMm: apiPackagingMaterial.outer_height_in_mm,
    outerLengthInMm: apiPackagingMaterial.outer_length_in_mm,
    outerWidthInMm: apiPackagingMaterial.outer_width_in_mm,
    tareWeightInKg: apiPackagingMaterial.tare_weight_in_kg,
    isReusable: apiPackagingMaterial.is_reusable,
    ownership: apiPackagingMaterial.ownership,
    stackingFactor: apiPackagingMaterial.stacking_factor,
});
