import { ResponsibleAgencyCode } from './deliverySchedules/types';

export enum InitializationState {
    INCOMPLETE = 'INCOMPLETE',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
}

export interface AppState {
    sessionExpiredAcknowledged: boolean;
    initializationState: InitializationState;
}

export enum ItemDescriptionCode {
    PRODUCTION = 'PRODUCTION',
    SPAREPART = 'SPAREPART',
    PRODUCTION_AND_SPAREPART = 'PRODUCTION_AND_SPAREPART',
    TRY_OUT = 'TRY_OUT',
    PILOT = 'PILOT',
    ADDITIONAL_REQUIREMENT = 'ADDITIONAL_REQUIREMENT',
    INITIAL_SAMPLE = 'INITIAL_SAMPLE',
    SAMPLE = 'SAMPLE',
    OTHER = 'OTHER',
}

export interface ItemShortDescription {
    itemDescription?: string;
    itemDescriptionCode: ItemDescriptionCode;
}

export interface FilterValue {
    key: string;
    values: string[];
}

export interface FilterValues {
    items: FilterValue[];
}

export interface PartyIdentificationDetails {
    identifier: string;
    responsibleAgencyCode: ResponsibleAgencyCode;
}

export enum ReferenceCodeQualifier {
    DUNS = 'DUNS',
}

export interface AdditionalPartyIdentifier {
    identifier: string;
    referenceCodeQualifier: ReferenceCodeQualifier;
}

export interface Party {
    partyIdentificationDetails: PartyIdentificationDetails;
    additionalPartyIdentifier?: AdditionalPartyIdentifier;
    partyName?: string;
    street?: string;
    cityName?: string;
    countrySubEntityNameCode?: string;
    postalIdentificationCode?: string;
    countryNameCode?: string;
}

export type OptionalType<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;
