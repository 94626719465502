import { FormattedMessage } from 'react-intl';
import {
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    MissingLoadingLocationProblem,
    isMissingLoadingLocationProblem,
} from '../../../../domain/dispatchProposal.types';
import { DispatchProposalProblemBannerFunctions } from '../DispatchProposalProblemBanner';
import { BannerIcon, colorByProblemLevel } from '../DispatchProposalProblemBannerFormatters';

const filterMissingLoadingLocationProblem = (problems: DispatchProposalProblem[]) =>
    problems.filter(isMissingLoadingLocationProblem);

const useMapToMissingLoadingLocationProblem = (problemBases: DispatchProposalProblemBase[]) => {
    const problems = problemBases as MissingLoadingLocationProblem[];

    if (problems.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    return {
        title: <MissingLoadingLocationProblemTitle entries={problems} />,
        rows: [],
        formatting: {
            testId: problems[0].type,
            horizontalDivider: undefined,
            icon: BannerIcon({ level: alertLevel }),
            buttonStyle: colorByProblemLevel(alertLevel),
            printEntries: false,
        },
    };
};

const MissingLoadingLocationProblemTitle = (props: { entries: MissingLoadingLocationProblem[] }) => {
    const id =
        props.entries.length === 1
            ? 'webedi.dispatchProposals.problemBanner.missingLoadingLocation.title.one'
            : 'webedi.dispatchProposals.problemBanner.missingLoadingLocation.title.multiple';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} values={{ numberOfPositions: props.entries.length }} />
        </strong>
    );
};

export const missingLoadingLocationProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterMissingLoadingLocationProblem,
    useGenerateProblemDescriptor: useMapToMissingLoadingLocationProblem,
};
