import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';

export interface DiscardChangesConfirmationDialogProps {
    show: boolean;
    onClickDiscard: () => void;
    onClickReturn: () => void;
}

export const DiscardChangesConfirmationDialog = (props: DiscardChangesConfirmationDialogProps) => {
    return (
        <ConfirmationDialog
            show={props.show}
            title={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.title'} />}
            content={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.description'} />}
            onClickConfirm={props.onClickDiscard}
            onClickCancel={props.onClickReturn}
            cancelButtonText={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.back'} />}
            confirmButtonText={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.discard'} />}
            useOverflow={false}
            bsSize={'sm'}
        />
    );
};
