import { SelectOption } from '@rio-cloud/rio-uikit';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import {
    dateTimeToLocalDate,
    localDateToLocalEndOfDayInUtc,
    localDateToLocalStartOfDayInUtc,
} from '../../../../dateUtils';
import {
    fetchAndStoreShipmentSuggestions,
    fetchAndStoreShipmentSuggestionsFilterValues,
} from '../../actions/suggestions/ShipmentSuggestions.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { ShipmentSuggestionsQueryParameters } from '../../reducers/suggestions/types';
import {
    getShipmentSuggestionsActiveFilters,
    getShipmentSuggestionsFilterValues,
} from '../../selectors/suggestions/ShipmentSuggestions.selector';
import { DateFilter } from '../common/filter/DateFilter';
import { FilterDropdown } from '../common/filter/FilterDropdown';
import { getBuyerName } from '../deliverySchedules/BuyerAbbreviationsMappings';
const SHIP_FROM_ID_KEY = 'SHIP_FROM_ID';
const SHIP_TO_ID_KEY = 'SHIP_TO_ID';
const PLACE_OF_DISCHARGE_KEY = 'PLACE_OF_DISCHARGE';
const ORDER_NUMBER_KEY = 'ORDER_NUMBER';
const MANUFACTURING_COMPANY_KEY = 'MANUFACTURING_COMPANY';

const EARLIEST_DELIVERY_DATE_PICKER_TEST_ID = 'EARLIEST_DELIVERY_DATE_PICKER_TEST_ID';
const LATEST_DELIVERY_DATE_TO_PICKER_TEST_ID = 'LATEST_DELIVERY_DATE_TO_PICKER_TEST_ID';
const REQUEST_CONDITION_CHECKBOX_TEST_ID = 'REQUEST_CONDITION_CHECKBOX_TEST_ID';

export const ShipmentSuggestionsFilterRow = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const activeFilters = useAppSelector(getShipmentSuggestionsActiveFilters);

    useEffect(() => {
        dispatch(fetchAndStoreShipmentSuggestionsFilterValues(dunsNumber!));
    }, [dunsNumber, dispatch]);

    const onChange = (queryChange: Partial<ShipmentSuggestionsQueryParameters>) => {
        dispatch(fetchAndStoreShipmentSuggestions({ ...queryChange, offset: 0 }));
    };

    const filterValues = useAppSelector(getShipmentSuggestionsFilterValues);

    const getFilterOptionsForKey = (key: string, currentValue: string | undefined): SelectOption[] => {
        const values = filterValues[key] || [];
        return values.map((value) => ({
            id: value,
            label: getBuyerName(value),
            selected: value === currentValue,
        }));
    };

    const requestConditionsClassnames =
        'flex-1-1 margin-10 display-flex border-style-solid border-width-1' +
        ' height-35 margin-10 max-width-300 padding-10 align-items-center justify-content-center border-color-gray';

    const filterClassName = 'flex-1-1 form-group margin-10';
    return (
        <>
            <div className={'display-flex flex-wrap align-items-end'}>
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(SHIP_FROM_ID_KEY, activeFilters.shipFromId)}
                    labelId={'webedi.common.shipFrom.identifier'}
                    onChange={(shipFromId) => onChange({ shipFromId })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(PLACE_OF_DISCHARGE_KEY, activeFilters.placeOfDischarge)}
                    labelId={'webedi.common.placeOfDischarge'}
                    onChange={(placeOfDischarge) => onChange({ placeOfDischarge })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(SHIP_TO_ID_KEY, activeFilters.shipToId)}
                    labelId={'webedi.plantIdentifier'}
                    onChange={(shipToId) => onChange({ shipToId })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(ORDER_NUMBER_KEY, activeFilters.orderNumber)}
                    labelId={'webedi.orderNumber'}
                    onChange={(orderNumber) => onChange({ orderNumber })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(
                        MANUFACTURING_COMPANY_KEY,
                        activeFilters.manufacturingCompany,
                    )}
                    labelId={'webedi.common.manufacturingCompany'}
                    onChange={(manufacturingCompany) => onChange({ manufacturingCompany })}
                />
                <DateFilter
                    className={filterClassName}
                    datePickerClassName={'margin-0'}
                    labelId={'webedi.common.earliestDeliveryDate'}
                    dataTestId={EARLIEST_DELIVERY_DATE_PICKER_TEST_ID}
                    selectedDate={
                        activeFilters.requestedDeliveryDateFrom
                            ? dateTimeToLocalDate(activeFilters.requestedDeliveryDateFrom)
                            : undefined
                    }
                    onChange={(deliveryDateFrom) => {
                        onChange({
                            requestedDeliveryDateFrom: deliveryDateFrom
                                ? localDateToLocalStartOfDayInUtc(deliveryDateFrom)
                                : undefined,
                        });
                    }}
                />
                <DateFilter
                    className={filterClassName}
                    datePickerClassName={'margin-0'}
                    labelId={'webedi.common.latestDeliveryDate'}
                    dataTestId={LATEST_DELIVERY_DATE_TO_PICKER_TEST_ID}
                    selectedDate={
                        activeFilters.requestedDeliveryDateTo
                            ? dateTimeToLocalDate(activeFilters.requestedDeliveryDateTo)
                            : undefined
                    }
                    onChange={(deliveryDateTo) => {
                        onChange({
                            requestedDeliveryDateTo: deliveryDateTo
                                ? localDateToLocalEndOfDayInUtc(deliveryDateTo)
                                : undefined,
                        });
                    }}
                />
                <div className={requestConditionsClassnames} data-testid={REQUEST_CONDITION_CHECKBOX_TEST_ID}>
                    <Switch
                        onChange={(onlyImmediateDemandOrBackorderValue: boolean) => {
                            onChange({ onlyImmediateDemandAndBackorder: onlyImmediateDemandOrBackorderValue });
                        }}
                        checked={activeFilters.onlyImmediateDemandAndBackorder}
                        labelPosition={'left'}
                    >
                        <span>
                            <FormattedMessage id={'webedi.common.filter.requestCondition.placeholder'} />
                        </span>
                    </Switch>
                </div>
            </div>
        </>
    );
};
