import { FormattedMessage } from 'react-intl';
import { config } from '../../../../config';
import { useGetLatestVersionNumber } from '../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../configuration/featureToggle/utils';

export const NewVersionAvailableBox = () => {
    const latestVersionNumber = getValueOrDefaultWhenLoading(useGetLatestVersionNumber());
    const currentVersionNumber = config.versionNumber;

    if (latestVersionNumber <= currentVersionNumber) {
        return null;
    }

    return (
        <div
            className={'alert alert-info margin-bottom-10'}
            role={'alert'}
            data-testid={NEW_VERSION_AVAILABLE_BOX_TEST_ID}
        >
            <span className={'rioglyph rioglyph-info-sign text-size-20 margin-right-10'} />
            <strong>
                <FormattedMessage id={'webedi.newVersionAvailable'} />
            </strong>
        </div>
    );
};

export const NEW_VERSION_AVAILABLE_BOX_TEST_ID = 'NEW_VERSION_AVAILABLE_BOX_TEST_ID';
