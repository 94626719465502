import { ReactNode, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { uiSlice } from '../../reducers/ui/UI.reducer';
import { getCurrentTransportOrderViewErrors } from '../../selectors/ui/UI.selector';

export const ExportedShipmentsErrorBox = () => {
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const errors = useAppSelector(getCurrentTransportOrderViewErrors);
    const dunsNumber = useDunsNumberFromPath() ?? 'NOT_KNOWN';
    const containErrors = errors !== undefined && Object.keys(errors).length > 0;

    useEffect(() => {
        if (containErrors) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [containErrors]);

    const onClose = () => dispatch(uiSlice.actions.clearTransportOrderViewErrors());

    return (
        containErrors && (
            <div
                ref={ref}
                className={'alert alert-dismissible alert-danger margin-bottom-20'}
                role={'alert'}
                data-testid={EXPORTED_SHIPMENTS_ERROR_BOX_TEST_ID}
            >
                <button type='button' className='btn btn-icon-only close' data-dismiss='alert' onClick={onClose}>
                    <span className='rioglyph rioglyph-remove' />
                </button>
                <div className={'display-flex'}>
                    <span className={`text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph-error-sign`} />
                    <div>
                        <span className={'text-size-16'}>
                            <strong>
                                <FormattedMessage id={'webedi.error.shipment'} />
                            </strong>
                        </span>
                        <ul className={'margin-0'}>
                            {Object.keys(errors).map((key) => {
                                return (
                                    <li key={key}>
                                        <FormattedMessage
                                            id={'webedi.transportOrders.error.exportedShipments'}
                                            values={{
                                                shipmentId: key,
                                                errorMessage: <FormattedMessage id={errors[key]} />,
                                                dunsNumber,
                                                a: (chunks: ReactNode) => (
                                                    <strong>
                                                        <a
                                                            href={`/webscm/${dunsNumber}/shipments/${key}`}
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                        >
                                                            {chunks}
                                                        </a>
                                                    </strong>
                                                ),
                                            }}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        )
    );
};

export const EXPORTED_SHIPMENTS_ERROR_BOX_TEST_ID = 'EXPORTED_SHIPMENTS_ERROR_BOX_TEST_ID';
