import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreShipmentSuggestions } from '../../actions/suggestions/ShipmentSuggestions.actions';
import {
    getTotalNumberOfShipmentSuggestions,
    isLoadingShipmentSuggestions,
} from '../../selectors/suggestions/ShipmentSuggestions.selector';
import { PaginationBar } from '../common/PaginationBar';

export interface ShipmentSuggestionPaginationBarProps {
    totalNumber: number;
    limit: number;
    offset: number;
}

export const ShipmentSuggestionPaginationBar = (props: ShipmentSuggestionPaginationBarProps) => {
    const dispatch = useAppDispatch();
    const { limit, offset } = props;
    const isLoading = useAppSelector(isLoadingShipmentSuggestions);
    const totalNumber = useAppSelector(getTotalNumberOfShipmentSuggestions);

    if (isLoading) {
        return null;
    }

    const onOffsetSelect = (newOffset: number) => {
        dispatch(fetchAndStoreShipmentSuggestions({ offset: newOffset }));
    };

    const onChangeLimit = (newLimit: number, newOffset: number) => {
        dispatch(fetchAndStoreShipmentSuggestions({ limit: newLimit, offset: newOffset }));
    };

    return (
        <div>
            <PaginationBar
                limit={limit}
                offset={offset}
                totalNumber={totalNumber}
                limits={[25, 50, 100]}
                onOffsetSelect={onOffsetSelect}
                onChangeLimit={onChangeLimit}
            />
        </div>
    );
};
