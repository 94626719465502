import { IntlShape } from 'react-intl/lib';
import { CourierOrderValidation, CourierOrderValidationError } from '../../../domain/courierOrderIntent.types';

export const mapValidationResponseToFormattedMessages = (
    validations: CourierOrderValidation[],
    intl: IntlShape,
): string[] => {
    return validations.map((e: any) => formatValidationErrorCode(e, intl));
};

const formatValidationErrorCode = (error: CourierOrderValidation, intl: IntlShape): string => {
    switch (error.code) {
        case CourierOrderValidationError.WEIGHT_EXCEEDED:
            return intl.formatMessage(
                { id: 'webedi.dispatchProposals.expressParcel.errors.weight' },
                { actual: error.actual, limit: error.limit },
            );
        case CourierOrderValidationError.LENGTH_EXCEEDED:
            return intl.formatMessage(
                { id: 'webedi.dispatchProposals.expressParcel.errors.length' },
                { actual: error.actual, limit: error.limit },
            );
        case CourierOrderValidationError.GIRTH_EXCEEDED:
            return intl.formatMessage(
                { id: 'webedi.dispatchProposals.expressParcel.errors.girth' },
                { actual: error.actual, limit: error.limit },
            );
        case CourierOrderValidationError.DANGEROUS_GOODS_UNSUPPORTED:
            return intl.formatMessage({ id: 'webedi.dispatchProposals.expressParcel.errors.dangerousGoods' });
        default:
            throw new Error(`Unknown validation code: ${error.code}`);
    }
};
