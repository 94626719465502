import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';

import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { NavigateFunction } from 'react-router';
import { Dispatch, State } from '../../../../configuration/setup/store';
import { ApiError } from '../../api/apiUtils';
import {
    clearMarkingsOfDeliverySchedule,
    deleteDeliverySchedule,
    fetchCumulativeQuantitySentOffsets,
    fetchDeliverySchedule,
    fetchDeliverySchedules,
    fetchDeliverySchedulesFilterValues,
    postCumulativeQuantitySentOffset,
    updateDeliveryScheduleData,
} from '../../api/deliverySchedule/deliveryScheduleCalls';
import { Routing } from '../../components/routing/Routes';
import { deliverySchedulesSlice } from '../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    CumulativeQuantitySentOffset,
    CumulativeQuantitySentOffsetCreationRequest,
    DeliverySchedule,
    DeliveryScheduleUpdateDataRequest,
    DeliverySchedulesQueryParameters,
    DeliverySchedulesQueryResult,
    SelectedMetadataEntryId,
} from '../../reducers/deliverySchedules/types';
import { FilterValues } from '../../reducers/types';
import { uiSlice } from '../../reducers/ui/UI.reducer';
import { getDeliverySchedulesQuery } from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { nonVanishingFormattedErrorNotification, notifySuccess } from '../NotificationUtils';
import { handleError } from '../ui/UI.actions';
import { fetchAndStorePackagingTemplates } from './PackagingTemplate.actions';

export const fetchAndStoreDeliverySchedules =
    (queryChanges?: Partial<DeliverySchedulesQueryParameters>) => async (dispatch: Dispatch, getState: () => State) => {
        dispatch(deliverySchedulesSlice.actions.setDeliverySchedulesAreLoading());
        const currentQuery = getDeliverySchedulesQuery(getState());
        const query = { ...currentQuery, params: { ...currentQuery.params, ...queryChanges } };

        dispatch(deliverySchedulesSlice.actions.updateQuery(query));
        return fetchDeliverySchedules(query)
            .then((queryResult: DeliverySchedulesQueryResult) => {
                const queryAfterFetch = getDeliverySchedulesQuery(getState());
                if (!_.isEqual(queryAfterFetch, query)) {
                    return undefined;
                }
                return dispatch(deliverySchedulesSlice.actions.updateDeliverySchedules(queryResult));
            })
            .catch((error: Error) => {
                dispatch(deliverySchedulesSlice.actions.setDeliverySchedulesFailed());
                Notification.error(error.message);
            });
    };

export const fetchAndStoreDeliverySchedule =
    (dunsNumber: string, deliveryScheduleId: string) => async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.setDeliverySchedulesAreLoading());
        return fetchDeliverySchedule(dunsNumber, deliveryScheduleId)
            .then((deliverySchedule: DeliverySchedule) => {
                dispatch(deliverySchedulesSlice.actions.updateDeliverySchedule(deliverySchedule));
            })
            .catch((error: Error) => {
                dispatch(deliverySchedulesSlice.actions.setDeliverySchedulesFailed());
                Notification.error(error.message);
            });
    };

export const deleteDeliveryScheduleAndRefresh =
    (dunsNumber: string, deliveryScheduleId: string, queryParams: DeliverySchedulesQueryParameters) =>
    async (dispatch: Dispatch) => {
        return deleteDeliverySchedule(dunsNumber, deliveryScheduleId)
            .then(() => notifySuccess('webedi.label.success.delete'))
            .then(() => dispatch(fetchAndStoreDeliverySchedules(queryParams)))
            .catch((error: Error) => {
                if (error instanceof ApiError && error.statusCode === 409) {
                    nonVanishingFormattedErrorNotification({
                        messageId: 'webedi.error.deliverySchedule.delete.conflict',
                    });
                } else {
                    Notification.error(error.message);
                }
            });
    };

export const clearMarkingsOfDeliveryScheduleAndRefresh =
    (dunsNumber: string, deliveryScheduleId: string, queryParams: DeliverySchedulesQueryParameters) =>
    async (dispatch: Dispatch) => {
        return clearMarkingsOfDeliverySchedule(dunsNumber, deliveryScheduleId)
            .then(() => dispatch(fetchAndStoreDeliverySchedules(queryParams)))
            .catch((error: Error) => {
                Notification.error(error.message);
            });
    };

export const fetchAndStoreCumulativeQuantitySentOffsets =
    (dunsNumber: string, deliveryScheduleId: string) => async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.setIsLoadingCumulativeQuantitySentOffsets(true));
        return fetchCumulativeQuantitySentOffsets(dunsNumber, deliveryScheduleId)
            .then((cumulativeQuantitySentOffset: CumulativeQuantitySentOffset[]) => {
                dispatch(
                    deliverySchedulesSlice.actions.updateCumulativeQuantitySentOffsets(cumulativeQuantitySentOffset),
                );
                dispatch(deliverySchedulesSlice.actions.setIsLoadingCumulativeQuantitySentOffsets(false));
            })
            .catch((error: Error) => {
                dispatch(deliverySchedulesSlice.actions.setLoadingCumulativeQuantitySentOffsetsFailed());
                Notification.error(error.message);
            });
    };

export const postCumulativeQuantitySentOffsetAndRefresh =
    (dunsNumber: string, deliveryScheduleId: string, newQuantity: number) => async (dispatch: Dispatch) => {
        const cumulativeQuantitySentOffsetCreationRequest: CumulativeQuantitySentOffsetCreationRequest = {
            dunsNumberOwner: dunsNumber,
            deliveryScheduleId,
            newQuantity,
        };
        dispatch(deliverySchedulesSlice.actions.setIsPostingCumulativeQuantitySentOffset(true));
        return postCumulativeQuantitySentOffset(cumulativeQuantitySentOffsetCreationRequest)
            .then(() => {
                dispatch(fetchAndStoreCumulativeQuantitySentOffsets(dunsNumber, deliveryScheduleId));
            })
            .then(() => {
                dispatch(fetchAndStoreDeliverySchedule(dunsNumber, deliveryScheduleId));
            })
            .catch((error: Error) => {
                Notification.error(error.message);
            })
            .finally(() => {
                dispatch(deliverySchedulesSlice.actions.setIsPostingCumulativeQuantitySentOffset(false));
            });
    };

export const fetchAndStoreDeliveryScheduleFilterValues = (dunsNumber: string) => async (dispatch: Dispatch) => {
    return fetchDeliverySchedulesFilterValues(dunsNumber)
        .then((filterValues: FilterValues) =>
            dispatch(deliverySchedulesSlice.actions.setDeliverySchedulesFilterValues(filterValues)),
        )
        .catch((error: Error) => {
            Notification.error(error.message);
        });
};

export const updateDeliveryScheduleDataAndRefresh =
    (request: DeliveryScheduleUpdateDataRequest) => async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.setIsSavingArticleMetadata(true));
        return updateDeliveryScheduleData(request)
            .then(() => dispatch(fetchAndStoreDeliverySchedules()))
            .then(() => {
                dispatch(deliverySchedulesSlice.actions.setIsMetadataViewContentDirty(false));
                dispatch(deliverySchedulesSlice.actions.clearSelectedDeliveryScheduleId());
                Notification.success(<FormattedMessage id={'webedi.masterData.editData.success'} />);
                dispatch(uiSlice.actions.clearError());
            })
            .catch((error: Error) => {
                dispatch(handleError(error));
            })
            .finally(() => {
                dispatch(deliverySchedulesSlice.actions.setIsSavingArticleMetadata(false));
            });
    };

export const setSelectedDeliveryScheduleId =
    (dunsNumber: string, selectedDeliveryScheduleId: string, navigate: NavigateFunction) =>
    async (dispatch: Dispatch) => {
        dispatch(deliverySchedulesSlice.actions.setSelectedDeliveryScheduleId(selectedDeliveryScheduleId));
        dispatch(uiSlice.actions.clearError());

        return fetchAndStorePackagingTemplates(
            dunsNumber,
            selectedDeliveryScheduleId,
        )(dispatch)
            .then(() =>
                dispatch(
                    deliverySchedulesSlice.actions.setSelectedDeliveryScheduleMetadataEntry(
                        SelectedMetadataEntryId.ARTICLE_MASTER_DATA,
                    ),
                ),
            )
            .then(() =>
                navigate(
                    `${Routing.webScm}/${dunsNumber}${Routing.deliverySchedules}/${selectedDeliveryScheduleId}${Routing.metadata}`,
                ),
            )
            .catch((error: Error) => {
                Notification.error(error.message);
            });
    };
