import getOr from 'lodash/fp/getOr';
import { RIOIdToken, VWIdToken, isRioIdToken, isVWIdToken } from './IdToken';

export const getAccessToken = getOr('NO_ACCESS_TOKEN_AVAILABLE', 'tokenHandling.accessToken');

export const getIdToken = getOr<null | RIOIdToken | VWIdToken>(null, 'tokenHandling.idToken');

export const READ_ONLY_ADMIN_ROLES = [
    'B2BX_WEBSCM_WEBSCMGLOBALADMINREADONLY',
    'B2BX_WEBSCM_WEBSCMPLANTADMINREADONLY',
    'B2BX_WEBSCM_WEBSCMCOUNTRYADMINREADONLY',
];
export const ADMIN_ROLE = 'B2BX_WEBSCM_WEBSCMGLOBALADMIN';
export const SUPPLIER_ROLE = 'B2BX_WEBSCM_WEBSCMSUPPLIER';

export const isReadOnlyAdmin = (state: any): boolean => {
    const idToken = getIdToken(state);
    if (idToken !== null && isVWIdToken(idToken)) {
        return (
            idToken.roles.some((role) => READ_ONLY_ADMIN_ROLES.some((adminRole) => adminRole === role)) ||
            idToken.groups.some((group) => READ_ONLY_ADMIN_ROLES.some((adminRole) => adminRole === group))
        );
    }
    return false;
};

export const isAdmin = (state: any): boolean => {
    const idToken = getIdToken(state);
    if (idToken == null) {
        return false;
    }
    if (isRioIdToken(idToken)) {
        const queryParams = new URLSearchParams(window.location.search.split('?')[1]);
        return queryParams.get('role') !== 'supplier';
    }
    if (isVWIdToken(idToken)) {
        return (
            idToken.roles.some((role) => role === ADMIN_ROLE) || idToken.groups.some((group) => group === ADMIN_ROLE)
        );
    }
    return false;
};

export const isSupplier = (state: any): boolean => {
    const idToken = getIdToken(state);
    if (idToken == null) {
        return false;
    }
    if (isRioIdToken(idToken)) {
        const queryParams = new URLSearchParams(window.location.search.split('?')[1]);
        if (queryParams.get('role') === 'supplier') {
            return true;
        }
        return false;
    }
    if (isVWIdToken(idToken)) {
        return (
            idToken.roles.some((role) => role === SUPPLIER_ROLE) ||
            idToken.groups.some((group) => group === SUPPLIER_ROLE)
        );
    }
    return false;
};

export const getDunsNumber = (state: any): string | undefined => {
    const idToken = getIdToken(state);
    if (idToken !== null && isVWIdToken(idToken)) {
        return idToken.duns_number;
    }
    return undefined;
};
