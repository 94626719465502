import { captureException, init } from '@sentry/browser';
import { config } from '../../config';

const release = APP_VERSION;
const environment = import.meta.env.MODE;

// should have been called before using it here
// ideally before even rendering your react app
init({
    dsn: config.sentryToken,
    environment,
    release,
});

export const reportErrorToSentry = (...args) => {
    captureException(...args);
};
