import { ReactNode } from 'react';
import { FieldErrors, FieldValues, Path, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export interface PositiveNumberFormInputProps<T extends FieldValues> {
    fieldName: Path<T>;
    required: boolean;
    decimalAllowed: boolean;
    zeroAllowed?: boolean;
    step: number;
    disabled?: boolean;
    max?: number;
    digitPrecision?: number;
}

const VALIDATION_ERROR_MIN = 'validation.min';
const VALIDATION_ERROR_MAX = 'validation.max';

type ValidationError = typeof VALIDATION_ERROR_MIN | typeof VALIDATION_ERROR_MAX;

export const PositiveNumberFormInput = <T extends FieldValues>(props: PositiveNumberFormInputProps<T>) => {
    const { register } = useFormContext<T>();

    const pattern = props.decimalAllowed ? /^(?:0|[1-9]\d*)(?:[.,]\d+)?$/ : /^[0-9]+$/;
    const currentValue = useWatch({ name: props.fieldName });

    const toPrecision = (value: number) => {
        return Number.parseFloat(Number(value).toFixed(props.digitPrecision));
    };

    return (
        <input
            {...register(props.fieldName, {
                required: props.required,
                pattern,
                setValueAs: (value: string | undefined) => {
                    if (value === undefined || value === '') {
                        return undefined;
                    }
                    if (!pattern.test(value)) {
                        return value;
                    }
                    let num: number;
                    if (props.decimalAllowed) {
                        num = parseFloat(value);
                    } else {
                        num = parseInt(value, 10);
                    }
                    if (isNaN(num) || num < 0) {
                        return value;
                    }
                    if (props.digitPrecision) {
                        num = toPrecision(num);
                    }
                    return num;
                },
                validate: (value?: number): boolean | ValidationError => {
                    if (value === undefined) {
                        return true;
                    }
                    const minOk = props.zeroAllowed ? value >= 0 : value > 0;
                    if (!minOk) {
                        return VALIDATION_ERROR_MIN;
                    }
                    const maxOk = props.max !== undefined ? value <= props.max : true;
                    if (!maxOk) {
                        return VALIDATION_ERROR_MAX;
                    }
                    return true;
                },
            })}
            className='form-control padding-right-5'
            type='number'
            min={props.decimalAllowed ? 0 : 1}
            max={props.max}
            disabled={props.disabled}
            id={props.fieldName}
            step={props.step}
            value={currentValue ?? ''}
            data-testid={props.fieldName}
        />
    );
};

export const PositiveNumberFormInputWithFeedback = <T extends FieldValues>(
    props: PositiveNumberFormInputProps<T> & {
        getError: (errors: FieldErrors<T>) => FieldErrors<T>[Path<T>];
    },
) => {
    const {
        formState: { errors },
    } = useFormContext<T>();
    const error = props.getError(errors);

    const getErrorMessage = (): ReactNode => {
        if (!error || typeof error.type !== 'string') {
            return undefined;
        }
        switch (error.type) {
            case 'required':
                return <FormattedMessage id={'webedi.form.error.requiredField'} />;
            case 'pattern':
                return <FormattedMessage id={'webedi.form.error.nonPositiveNumber'} />;
            case 'validate':
                switch (error.message) {
                    case VALIDATION_ERROR_MIN:
                        return <FormattedMessage id={'webedi.form.error.nonPositiveNumber'} />;
                    case VALIDATION_ERROR_MAX:
                        return (
                            <FormattedMessage id={'webedi.form.error.tooBigNumber'} values={{ maxValue: props.max }} />
                        );
                    default:
                        return 'Unknown error';
                }
            default:
                return 'Unknown error';
        }
    };

    const errorMessage = getErrorMessage();

    return (
        <div className={`width-100pct form-group ${error ? 'has-error has-feedback' : ''}`}>
            <div className='input-group'>
                <PositiveNumberFormInput {...props} />
            </div>
            {!props.disabled && errorMessage && <ErrorHelpBlock errorMessage={errorMessage} />}
        </div>
    );
};

const ErrorHelpBlock = (props: { errorMessage: ReactNode }) => {
    return (
        <>
            <span className={'help-block'}>{props.errorMessage}</span>
            <span className={'form-control-feedback rioglyph rioglyph-error-sign'} />
        </>
    );
};
