// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { fromEnum } from '../util';

import { MeasurementUnitCode } from '../../domain/common.types';

export const measurementUnitCodeCodec = fromEnum<MeasurementUnitCode>('MeasurementUnitCode', MeasurementUnitCode);

export const quantityCodec = t.type({
    value: t.number,
    measurement_unit_code: measurementUnitCodeCodec,
});

export type ApiQuantity = t.TypeOf<typeof quantityCodec>;
