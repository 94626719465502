import { DispatchProposal, DispatchProposalItem, FreightForwarder } from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';

export interface TransportPlanningDraft {
    transportConcept: TransportConcept;
    freightForwarder: FreightForwarder;
    pickupDate: string;
    pickupTimeFrom: string | undefined;
    pickupTimeTo: string | undefined;
    accessCode: string | undefined;
    supplierTimezone: string;
    selectedDispatchProposalItems: DispatchProposalItem[];
}

export interface AddDispatchProposalItemToTransportPlanningDraft {
    dispatchProposal: DispatchProposal;
    item: DispatchProposalItem;
}

export interface RemoveDispatchProposalItemToTransportPlanningDraft {
    dispatchProposal: DispatchProposal;
    item: DispatchProposalItem;
}

export interface SetAdditionalTransportPlanningDraftInformation {
    pickupTimeFrom: string | undefined;
    pickupTimeTo: string | undefined;
    accessCode: string | undefined;
}

export enum Dialog {
    PACKAGE_TOGETHER = 'PACKAGE_TOGETHER',
}
export interface SetDialog {
    dialog?: Dialog;
}

export interface DispatchProposalsFilterParams {
    manufacturingCompanyList: string[];
    loadingLocationList: string[];
    plantNumberList: string[];
    unloadingPointList: string[];
    transportConceptList: string[];
}
