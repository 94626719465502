import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { BoxFinder } from '../../../packaging/BoxFinder';
import { NumberInput } from '../../form/NumberInput';
import { auxiliaryFormPropertyNames } from './AuxiliaryPackagingFormConfiguration';

export const AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_INPUTS_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_INPUTS_TEST_ID';
export const AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_REMOVE_BUTTON_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_REMOVE_BUTTON_TEST_ID';
export const AUXILIARY_PACKAGING_CONFIGURATION_AUXILIARY_PACKAGING_PER_HANDLING_UNIT_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_AUXILIARY_PACKAGING_PER_HANDLING_UNIT_TEST_ID';

export interface AuxiliaryPackagingInputsProps {
    auxiliaryPackagingPropertyName: string;
    removeHandler?: () => void;
    renderRemoveButton: boolean;
    disableInputs?: boolean;
}

export const AuxiliaryPackagingInputs = (props: AuxiliaryPackagingInputsProps) => {
    const { auxiliaryPackagingPropertyName, renderRemoveButton, disableInputs, removeHandler } = props;

    return (
        <>
            <div
                className={'row padding-top-1pct padding-bottom-1pct margin-bottom-20'}
                data-testid={AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_INPUTS_TEST_ID}
            >
                <div className={'column col-xs-12 col-sm-11'}>
                    <div
                        className={'col-xs-12 col-md-4 word-break ellipsis-1 margin-bottom-10'}
                        data-testid={AUXILIARY_PACKAGING_CONFIGURATION_AUXILIARY_PACKAGING_PER_HANDLING_UNIT_TEST_ID}
                    >
                        <label
                            htmlFor={`${auxiliaryPackagingPropertyName}.${auxiliaryFormPropertyNames.auxiliaryPackagingPerHandlingUnit}`}
                        >
                            <FormattedMessage id={'webedi.packaging.auxiliaryPackagingPerUnit'} />
                        </label>
                        <Field
                            id={`${auxiliaryPackagingPropertyName}.${auxiliaryFormPropertyNames.auxiliaryPackagingPerHandlingUnit}`}
                            name={`${auxiliaryPackagingPropertyName}.${auxiliaryFormPropertyNames.auxiliaryPackagingPerHandlingUnit}`}
                            component={NumberInput}
                            className={'form-control'}
                            parse={(value) => value && parseInt(value, 10)}
                            forbidScientificNotation
                            min={0}
                            errorMessageClassName={'text-size-10 margin-bottom-0'}
                            disabled={disableInputs}
                        />
                    </div>
                    <div className={'col-xs-12 col-md-8'}>
                        <BoxFinder
                            id={`${auxiliaryPackagingPropertyName}.${auxiliaryFormPropertyNames.packagingMaterial}`}
                            name={`${auxiliaryPackagingPropertyName}.${auxiliaryFormPropertyNames.packagingMaterial}`}
                            boxFinderInputProps={{
                                outerClassName: 'width-100pct',
                                disabled: disableInputs ?? false,
                                packagingMaterialInputPrefix: `${auxiliaryPackagingPropertyName}.`,
                            }}
                            showLinkToVWMaterialHelp={false}
                        />
                    </div>
                </div>
                {renderRemoveButton ? (
                    <div
                        data-testid={AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_REMOVE_BUTTON_TEST_ID}
                        onClick={() => removeHandler?.()}
                        className={
                            'column col-xs-12 col-sm-1 text-size-20 text-center padding-top-25-sm padding-top-10'
                        }
                    >
                        <span
                            className={'rioglyph rioglyph-trash cursor-pointer text-color-gray hover-text-color-danger'}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};
