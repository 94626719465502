import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useImage } from '../../../hooks/useImage';
import { GroupedHandlingUnits, Ownership } from '../../../reducers/shipments/packaging.types';
import { HorizontalAddButton } from '../../common/HorizontalAddButton';
import { HandlingUnitCategory } from '../../common/PackagingCommon';
import { AuxiliaryHeaderWithoutCounter } from '../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingConfiguration';
import { AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_REMOVE_BUTTON_TEST_ID } from '../../common/dialog/AuxiliaryPackaging/AuxiliaryPackagingInputs';
import { PositiveNumberFormInputWithFeedback } from '../../common/form/PositiveNumberFormInput';
import { BoxFinderV2 } from '../../packaging/BoxFinderV2';
import { GroupedPackagingHandlingUnits } from '../../shipments/packaging/dialogs/GroupedPackagingHandlingUnits';
import { SplitView } from '../../shipments/packaging/dialogs/SplitView';
import { PackageTogetherFormValues } from './PackageTogetherDialog';

export const PackageTogetherStep4 = () => (
    <SplitView left={{ content: <Left />, renderAsColumn: true }} main={{ content: <Main />, renderAsColumn: true }} />
);

const Left = () => {
    const { watch } = useFormContext<PackageTogetherFormValues>();
    const amountOfHandlingUnits = watch('amountOfHandlingUnits');
    const packagingMaterial = watch('packagingMaterial');

    const groupedHandlingUnits: GroupedHandlingUnits = {
        type: packagingMaterial?.boxCode ?? '',
        category: HandlingUnitCategory.LOAD_CARRIER,
        quantity: amountOfHandlingUnits ?? 0,
        packagedArticlesInfo: [],
        description: packagingMaterial?.name,
        hash: '',
        handlingUnit: {
            type: packagingMaterial?.boxCode ?? '',
            description: packagingMaterial?.name,
            isReusable: packagingMaterial?.isReusable ?? true,
            ownership: packagingMaterial?.ownership ?? Ownership.BUYER,
            stackingFactor: packagingMaterial?.stackingFactor ?? 1,
            tareWeightInKg: packagingMaterial?.tareWeightInKg ?? 0,
        },
    };

    return (
        <>
            <span className={'text-center margin-bottom-15 text-color-success'}>
                <div className={'text-size-18 margin-right-5 display-inline'}>
                    <span className={'rioglyph rioglyph-ok-sign'} />
                </div>
                <FormattedMessage id={'webedi.packaging.hasBeenCreated'} />:
            </span>
            <GroupedPackagingHandlingUnits groupedHandlingUnits={[groupedHandlingUnits]} selectable={false} />
        </>
    );
};

const Main = () => {
    const { watch } = useFormContext<PackageTogetherFormValues>();
    const packagingMaterial = watch('packagingMaterial');
    const auxiliaryPackagingImage = useImage('auxiliaryPackaging');

    return (
        <div className={'display-flex flex-column flex-1-1-0 height-100pct justify-content-around'}>
            <AuxiliaryHeaderWithoutCounter packagingMaterial={packagingMaterial?.boxCode} />
            <div
                className={
                    'display-flex flex-column border-style-solid border-color-light border-width-1 padding-left-5pct padding-right-5pct padding-20 margin-bottom-25'
                }
            >
                <span className={'margin-top-25 margin-bottom-25 text-center'}>
                    <img src={auxiliaryPackagingImage} className={'width-40pct'} alt={'auxiliaryPackaging'} />
                </span>
                <div className={`display-flex`}>
                    <AuxiliaryPackagings />
                </div>
            </div>
        </div>
    );
};

const AuxiliaryPackagings = () => {
    const { fields, append, remove } = useFieldArray<Pick<PackageTogetherFormValues, 'auxiliaryPackagings'>>({
        name: 'auxiliaryPackagings',
    });

    return (
        <div className={'width-100pct'}>
            <div className={'display-flex flex-column width-100pct margin-bottom-20'}>
                {fields.map((it, index) => (
                    <AuxiliaryPackagingInput key={it.id} index={index} remove={remove} />
                ))}
                <span className={'text-center text-color-dark'}>
                    <FormattedMessage id='webedi.packaging.outer.auxiliaryPackaging.title' />
                </span>
                <HorizontalAddButton onClick={() => append({ amountPerHandlingUnit: 1, packagingMaterial: {} })} />
            </div>
        </div>
    );
};

const AuxiliaryPackagingInput = (props: {
    index: number;
    remove: (index: number) => void;
}) => {
    const { index, remove } = props;

    return (
        <div className={'row padding-top-1pct padding-bottom-1pct margin-bottom-20'}>
            <div className={'column col-sm-11'}>
                <div className={'col-md-4 word-break margin-bottom-10'}>
                    <label htmlFor={`auxiliaryPackagings.${index}.amountPerHandlingUnit`}>
                        <FormattedMessage id={'webedi.packaging.auxiliaryPackagingPerUnit'} />
                    </label>
                    <PositiveNumberFormInputWithFeedback<PackageTogetherFormValues>
                        fieldName={`auxiliaryPackagings.${index}.amountPerHandlingUnit`}
                        required={true}
                        decimalAllowed={false}
                        step={1}
                        getError={(errors) => errors.auxiliaryPackagings?.[index]?.amountPerHandlingUnit}
                    />
                </div>
                <div className={'col-md-8'}>
                    <BoxFinderV2
                        fieldName={`auxiliaryPackagings.${index}.packagingMaterial`}
                        showLinkToVWMaterialHelp={false}
                        getPackagingMaterialError={(errors) => errors.auxiliaryPackagings?.[index]?.packagingMaterial}
                    />
                </div>
            </div>
            <div
                onClick={() => remove(index)}
                className={'column col-sm-1 text-size-20 text-center padding-top-25-sm padding-top-10'}
                data-testid={AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_REMOVE_BUTTON_TEST_ID}
            >
                <span className={'rioglyph rioglyph-trash cursor-pointer text-color-gray hover-text-color-danger'} />
            </div>
        </div>
    );
};
