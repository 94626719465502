import { isBefore, utcNow } from '../../../../../../dateUtils';
import { TransportOrderIntent } from '../../../../api/transportOrderIntent/types';

export const logTransportOrderIntentInfo = (transportOrderIntent: TransportOrderIntent) => {
    console.log('Transport Order Intent created at ', transportOrderIntent.createdAt);
    console.log('Transport Order Intent last modified at ', transportOrderIntent.lastModifiedAt);
    if (transportOrderIntent.sentAt !== undefined) {
        console.log('Transport Order was sent at ', transportOrderIntent.sentAt);
    } else {
        const cutOffAt = transportOrderIntent.cutOffAt;
        const isCutOffInFuture = isBefore(utcNow(), cutOffAt);
        console.log(`Transport Order is ${transportOrderIntent.validForOrdering ? '' : 'NOT '}valid`);
        if (isCutOffInFuture) {
            console.log(
                `Transport order will ${transportOrderIntent.validForOrdering ? '' : 'NOT '}be sent at`,
                cutOffAt,
            );
        } else {
            if (transportOrderIntent.validForOrdering) {
                console.log('Transport Order will be sent shortly, notification time was at', cutOffAt);
            } else {
                console.log('Due to invalid data, Transport Order was not sent at', cutOffAt);
            }
        }
    }
};
