import { FieldRenderProps } from 'react-final-form';
import { DatePicker, DatePickerProps } from '../DatePicker';

export interface DatePickerInputProps extends FieldRenderProps<string | undefined> {
    datePickerProps?: Omit<DatePickerProps, 'onChange' | 'value'>;
}

export const DatePickerInput = (props: DatePickerInputProps) => {
    const { input, datePickerProps } = props;

    return (
        <div>
            <DatePicker onChange={input.onChange} value={input.value} clearableInput={true} {...datePickerProps} />
        </div>
    );
};
