import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { PackagedArticleInfo } from '../../../../reducers/shipments/packaging.types';
import { Quantity } from '../../../common/Quantity';
import { Tooltip } from '../../../common/Tooltip';

export interface PackagedArticlesInfoTooltipProps {
    packagedArticlesInfo: PackagedArticleInfo[];
}

export const PackagedArticlesInfoTooltip = (props: PackagedArticlesInfoTooltipProps) => {
    const intl = useIntl(); // Tooltip seemingly does not play nicely with <FormattedMessage />

    const renderEntry = (packagedArticleInfo: PackagedArticleInfo) => {
        const { deliveryNoteNumber, position } = packagedArticleInfo.loadItemPositionReference;
        return (
            <p key={`${deliveryNoteNumber}-${position}`}>
                <span>
                    {intl.formatMessage({ id: 'webedi.articleNumber.buyer' })}
                    {': '}
                    {packagedArticleInfo.articleNumberBuyer}
                </span>
                <br />
                <span>
                    {intl.formatMessage({ id: 'webedi.packaging.header.quantity' })}
                    {': '}
                    <Quantity
                        quantity={packagedArticleInfo.amount.value}
                        measurementUnitCode={packagedArticleInfo.amount.measurementUnitCode}
                    />
                </span>
                <br />
                <span>
                    {intl.formatMessage({ id: 'webedi.packaging.header.deliveryNoteNumber' })}
                    {': '}
                    {packagedArticleInfo.loadItemPositionReference.deliveryNoteNumber}
                </span>
                <br />
                <span>
                    {intl.formatMessage({ id: 'webedi.packaging.header.deliveryNotePosition' })}
                    {': '}
                    {packagedArticleInfo.loadItemPositionReference.position}
                </span>
            </p>
        );
    };

    const renderText = () => (
        <span className={'text-left'}>
            <p>
                <strong>{intl.formatMessage({ id: 'webedi.packaging.contents' })}: </strong>
            </p>
            {props.packagedArticlesInfo.map((packagedArticleInfo) => renderEntry(packagedArticleInfo))}
        </span>
    );

    if (isEmpty(props.packagedArticlesInfo)) {
        return null;
    }

    return (
        <Tooltip text={renderText()} placement={'bottom'}>
            <span
                className={'rioglyph rioglyph-info-sign text-color-info text-size-18'}
                data-testid={PACKAGED_ARTICLES_INFO_TOOLTIP_TEST_ID}
            />
        </Tooltip>
    );
};

export const PACKAGED_ARTICLES_INFO_TOOLTIP_TEST_ID = 'PACKAGED_ARTICLES_INFO_TOOLTIP_TEST_ID';
