import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { TextInput } from '../../../common/form/TextInput';
import { fieldNames } from '../courierOrderIntentDialog.types';

export const CourierOrderIntentContactDetails = () => {
    return (
        <div className='panel panel-default display-flex flex-column padding-20 border-style-solid border-width-1 border-color-light'>
            <div className='display-flex flex-column flex-row-md'>
                <div className='display-flex flex-column width-50pct-md' data-testid={'shipperData'}>
                    <div className='text-size-14 text-medium padding-x-10 padding-bottom-20'>
                        <span className='rioglyph rioglyph-user text-size-18 padding-right-5' />
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.firstStep.shipperForm.title'}
                        />
                    </div>
                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14' htmlFor={fieldNames.senderFirstAndLastName}>
                            <FormattedMessage
                                id={
                                    'webedi.dispatchProposals.expressParcel.dialog.firstStep.shipperForm.firstAndLastNameField'
                                }
                            />{' '}
                            *
                        </label>
                        <div className='margin-0 flex-1-1'>
                            <Field
                                name={fieldNames.senderFirstAndLastName}
                                className='form-control'
                                id={fieldNames.senderFirstAndLastName}
                                type='text'
                                component={TextInput}
                            />
                        </div>
                    </div>

                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14' htmlFor={fieldNames.senderPhoneNumber}>
                            <FormattedMessage
                                id={
                                    'webedi.dispatchProposals.expressParcel.dialog.firstStep.shipperForm.phoneNumberField'
                                }
                            />{' '}
                            *
                        </label>
                        <div className={`margin-0 flex-1-1`}>
                            <Field
                                name={fieldNames.senderPhoneNumber}
                                className='form-control'
                                id={fieldNames.senderPhoneNumber}
                                type='tel'
                                component={TextInput}
                            />
                        </div>
                    </div>
                </div>
                <div className='display-flex flex-column width-25 margin-left-25 border-style-solid border-left-only border-width-1 border-color-light' />
                <div className='display-flex flex-column width-50pct-md' data-testid={'recipientData'}>
                    <div className='text-size-14 text-medium padding-x-10 padding-bottom-20'>
                        <span className='rioglyph rioglyph-user text-size-18 padding-right-5' />
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.firstStep.recipientForm.title'}
                        />
                    </div>
                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14' htmlFor={fieldNames.recipientFirstAndLastName}>
                            <FormattedMessage
                                id={
                                    'webedi.dispatchProposals.expressParcel.dialog.firstStep.recipientForm.firstAndLastNameField'
                                }
                            />{' '}
                            *
                        </label>
                        <div className='margin-0 flex-1-1'>
                            <Field
                                name={fieldNames.recipientFirstAndLastName}
                                className='form-control'
                                id={fieldNames.recipientFirstAndLastName}
                                type='text'
                                component={TextInput}
                            />
                        </div>
                    </div>

                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14' htmlFor={fieldNames.recipientPhoneNumber}>
                            <FormattedMessage
                                id={
                                    'webedi.dispatchProposals.expressParcel.dialog.firstStep.recipientForm.phoneNumberField'
                                }
                            />{' '}
                            *
                        </label>
                        <div className='margin-0 flex-1-1'>
                            <Field
                                name={fieldNames.recipientPhoneNumber}
                                className='form-control'
                                id={fieldNames.recipientPhoneNumber}
                                type='tel'
                                component={TextInput}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
