import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterValues } from '../types';
import { ShipmentSuggestionResponse, ShipmentSuggestionsQuery, ShipmentSuggestionsState } from './types';

export const initialStateShipmentSuggestions: ShipmentSuggestionsState = {
    totalCount: 0,
    isLoadingShipmentSuggestions: false,
    shipmentSuggestions: [],
    query: {
        dunsNumber: undefined,
        params: {
            q: '',
            offset: 0,
            limit: 25,
        },
    },
    filterValues: {},
};

export const shipmentSuggestionsSlice = createSlice({
    name: 'shipmentSuggestions',
    initialState: initialStateShipmentSuggestions,
    reducers: {
        setIsLoading: (state: ShipmentSuggestionsState) => {
            state.isLoadingShipmentSuggestions = true;
        },
        setFailed: (state: ShipmentSuggestionsState) => {
            state.isLoadingShipmentSuggestions = false;
            state.shipmentSuggestions = [];
        },
        updateShipmentSuggestions: (
            state: ShipmentSuggestionsState,
            action: PayloadAction<ShipmentSuggestionResponse>,
        ) => {
            state.isLoadingShipmentSuggestions = false;
            state.shipmentSuggestions = action.payload.shipmentSuggestions;
            state.totalCount = action.payload.totalCount;
        },
        setFilterValues: (state: ShipmentSuggestionsState, action: PayloadAction<FilterValues>) => {
            state.filterValues = Object.fromEntries(
                action.payload.items.map((filterItem) => [filterItem.key, filterItem.values]),
            );
        },
        updateQuery: (state: ShipmentSuggestionsState, action: PayloadAction<ShipmentSuggestionsQuery>) => {
            state.query = action.payload;
        },
    },
});
