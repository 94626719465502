import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreDeliverySchedules } from '../../actions/deliverySchedules/DeliverySchedules.actions';
import { isLoadingDeliverySchedules } from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { PaginationBar } from '../common/PaginationBar';

export interface DeliverySchedulesPaginationBarProps {
    totalNumberDeliverySchedules: number;
    limit: number;
    offset: number;
}

export const DeliverySchedulesPaginationBar = (props: DeliverySchedulesPaginationBarProps) => {
    const dispatch = useAppDispatch();
    const { limit, offset, totalNumberDeliverySchedules } = props;
    const isLoading = useAppSelector(isLoadingDeliverySchedules);

    if (isLoading) {
        return null;
    }

    const onOffsetSelect = (newOffset: number) => {
        dispatch(fetchAndStoreDeliverySchedules({ offset: newOffset }));
    };

    const onChangeLimit = (newLimit: number, newOffset: number) => {
        dispatch(fetchAndStoreDeliverySchedules({ limit: newLimit, offset: newOffset }));
    };

    return (
        <div>
            <PaginationBar
                limit={limit}
                offset={offset}
                totalNumber={totalNumberDeliverySchedules}
                limits={[25, 50, 100, 1000]}
                onOffsetSelect={onOffsetSelect}
                onChangeLimit={onChangeLimit}
            />
        </div>
    );
};
