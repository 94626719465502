import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { areDaysEqual } from '../../../../dateUtils';
import { useGetDispatchProposalProblemsQuery } from '../../api/dispatchProposal/dispatchProposalApi';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { getOverviewDateRangeISO } from '../../selectors/dispatchProposals/DispatchProposals.selector';

export const useDispatchProposalProblemsForDay = (date: string) => {
    const dunsNumber = useDunsNumberFromPath() ?? 'UNKNOWN';
    const overviewDateRangeISO = useAppSelector(getOverviewDateRangeISO);
    return useGetDispatchProposalProblemsQuery(
        {
            dunsNumber,
            affectedDateFrom: overviewDateRangeISO.from,
            affectedDateTo: overviewDateRangeISO.to,
        },
        {
            selectFromResult: (result) => {
                const problems = result.data ?? [];
                const filteredProblems = problems.filter((problem) => areDaysEqual(problem.affectedDate, date));
                return { data: filteredProblems };
            },
            skip: dunsNumber === 'UNKNOWN',
        },
    );
};
