import { jwtDecode } from 'jwt-decode';
import join from 'lodash/fp/join';
import { UserManager } from 'oidc-client-ts';
import { IdentityProvider, config } from '../../config';
import { mapUserProfile } from './userProfile';

const pullLocale = (profile) => {
    const localeFromToken =
        config.identityProvider === IdentityProvider.RIO ? profile.locale : profile.preferred_language;

    return localeFromToken || 'en-GB';
};

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig, userManager) => {
    userManager.signinSilent().catch((error) => {
        if (error.message === 'login_required') {
            oauthConfig.onSessionExpired();
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    idToken: result.profile,
    locale: pullLocale(result.profile),
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = config.login.redirectUri;
    const silentRedirectUri = config.login.silentRedirectUri;

    const settings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        filterProtocolClaims: false,
        redirect_uri: `${redirectUri}`,
        response_type: `code`,
        scope: join(' ', config.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        staleStateAgeInSeconds: 600,
        monitorSession: config.login.monitorSession ?? true,
        post_logout_redirect_uri: config.postLogoutRedirectUri,
        userStore: config.login.userStore,
    };

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig, userManager) => {
    userManager.events.addUserLoaded((user) => {
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(() => {
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired();
    });

    return userManager;
};

export const configureMockUserManager = ({ onSessionRenewed }, { accessToken, idToken } = {}) => {
    const signoutRedirect = () => {
        window.location.href = window.location.href.concat('/loggedOut');
        return Promise.resolve();
    };

    const signinSilent = () => {
        const fakeUserWithSupplierRole = {
            account: 'mockaccount',
            tenant: 'rio-eu.test',
            azp: 'test-client',
            email: 'test@example.com',
            family_name: 'Client',
            given_name: 'Test',
            locale: config.login.mockLocale,
            name: 'Test Client',
            sub: 'prod-rio-users:mock-user',
            preferred_language: config.login.mockLocale,
            mobilePhone: '+491111111111',
        };

        onSessionRenewed(
            adaptPublishedInfo({
                access_token: accessToken || 'valid-mocked-oauth-bogus-token',

                expires_in: 60 * 60 * 24 * 365,
                idToken: idToken ? jwtDecode(idToken) : { preferred_language: 'de' },
                profile: idToken ? jwtDecode(idToken) : fakeUserWithSupplierRole,
            }),
        );
        return Promise.resolve();
    };

    const clearStaleState = () => {
        // biome-ignore lint/suspicious/noConsole: Only for debugging
        console.info('[feature/login/oidc-session] Stale state cleared');
        return Promise.resolve();
    };

    return { signinSilent, clearStaleState, signoutRedirect };
};
