import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import { accessToken } from '../../../configuration/tokenHandling/accessToken';

export const DELIVER_SCHEDULES_API_TAG = 'DeliverySchedules';
export const DISPATCH_PROPOSAL_FILTER_OPTIONS_API_TAG = 'DispatchProposalFilterOptions';
export const DISPATCH_PROPOSALS_TAG = 'DispatchProposals';
export const DISPATCH_PROPOSAL_PROBLEMS_TAG = 'DispatchProposalProblems';
export const baseApi = createApi({
    reducerPath: 'baseApi',
    tagTypes: [
        DELIVER_SCHEDULES_API_TAG,
        DISPATCH_PROPOSALS_TAG,
        DISPATCH_PROPOSAL_PROBLEMS_TAG,
        DISPATCH_PROPOSAL_FILTER_OPTIONS_API_TAG,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: () => ({}),
});
