import { SelectOption } from '@rio-cloud/rio-uikit';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { TransportOrder } from '../../../domain/transportOrder.types';
import { calculateAllowedFilterValues } from '../../../domain/utils.transportOrders';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import { getSelectedFilters } from '../../../selectors/transportOrders/transportOrders.selector';
import { FilterDropdown } from '../../common/filter/FilterDropdown';

interface TransportOrdersFilterRowProps {
    transportOrders: TransportOrder[];
}

export const FREIGHT_FORWARDER_DROPDOWN_TEST_ID = 'FREIGHT_FORWARDER_DROPDOWN_TEST_ID';
export const TRANSPORT_CONCEPT_DROPDOWN_TEST_ID = 'TRANSPORT_CONCEPT_DROPDOWN_TEST_ID';
export const PLACE_OF_DISCHARGE_DROPDOWN_TEST_ID = 'PLACE_OF_DISCHARGE_DROPDOWN_TEST_ID';
export const BUYER_NAME_DROPDOWN_TEST_ID = 'BUYER_NAME_DROPDOWN_TEST_ID';
export const PLANT_NUMBER_DROPDOWN_TEST_ID = 'PLANT_NUMBER_DROPDOWN_TEST_ID';

export const TransportOrdersFilterPanel = (props: TransportOrdersFilterRowProps) => {
    const filters = useAppSelector(getSelectedFilters);
    const dispatch = useAppDispatch();

    const allowedFilterValues = calculateAllowedFilterValues(props.transportOrders);

    const filterClassName = 'flex-1-1 flex-basis-10pct form-group margin-10 max-width-250';
    return (
        <div className={'display-flex flex-wrap justify-content-center'}>
            <div data-testid={FREIGHT_FORWARDER_DROPDOWN_TEST_ID} className={filterClassName}>
                <FilterDropdown
                    dropdownOptions={toSelectOptions(
                        allowedFilterValues.freightForwarderName || [],
                        filters.freightForwarderName,
                    )}
                    labelId={'webedi.transportOrders.overview.table.header.freightForwarder'}
                    onChange={(value) => {
                        dispatch(transportOrdersSlice.actions.setFreightForwarderNameFilter(value));
                    }}
                />
            </div>
            <div data-testid={TRANSPORT_CONCEPT_DROPDOWN_TEST_ID} className={filterClassName}>
                <FilterDropdown
                    dropdownOptions={toSelectOptions(
                        allowedFilterValues.transportConcept || [],
                        filters.transportConcept,
                    )}
                    labelId={'webedi.transportOrders.overview.table.header.transportConcept'}
                    onChange={(value) => {
                        dispatch(
                            transportOrdersSlice.actions.setTransportConceptFilter(
                                value as TransportConcept | undefined,
                            ),
                        );
                    }}
                />
            </div>
            <div data-testid={BUYER_NAME_DROPDOWN_TEST_ID} className={filterClassName}>
                <FilterDropdown
                    dropdownOptions={toSelectOptions(allowedFilterValues.buyerName || [], filters.buyerName)}
                    labelId={'webedi.transportOrders.overview.table.header.buyer'}
                    onChange={(value) => {
                        dispatch(transportOrdersSlice.actions.setBuyerNameFilter(value));
                    }}
                />
            </div>
            <div data-testid={PLANT_NUMBER_DROPDOWN_TEST_ID} className={filterClassName}>
                <FilterDropdown
                    dropdownOptions={toSelectOptions(allowedFilterValues.plantNumber || [], filters.plantNumber)}
                    labelId={'webedi.transportOrders.overview.table.header.plantNumber'}
                    onChange={(value) => {
                        dispatch(transportOrdersSlice.actions.setPlantNumberFilter(value));
                    }}
                />
            </div>
            <div data-testid={PLACE_OF_DISCHARGE_DROPDOWN_TEST_ID} className={filterClassName}>
                <FilterDropdown
                    dropdownOptions={toSelectOptions(
                        allowedFilterValues.placeOfDischarge || [],
                        filters.placeOfDischarge,
                    )}
                    labelId={'webedi.transportOrders.overview.table.header.placeOfDischarge'}
                    onChange={(value) => {
                        dispatch(transportOrdersSlice.actions.setPlaceOfDischargeFilter(value));
                    }}
                />
            </div>
        </div>
    );
};

const toSelectOptions = (values: string[], currentValue: string | undefined): SelectOption[] => {
    return values.map((value) => ({
        id: value,
        label: value,
        selected: value === currentValue,
    }));
};
