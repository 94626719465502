import { State } from '../../../../configuration/setup/store';
import {
    DocumentSettings,
    GtlDocumentSettings,
    ShipmentDocuments,
    TsbDocumentSettings,
} from '../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { getSelectedShipmentId } from './Shipments.selector';

const getShipmentDocuments = (state: State): Record<string, ShipmentDocuments> =>
    state.webEdi.shipmentDocuments.documents;

export const getShipmentDocumentsForSelectedShipment = (state: State): ShipmentDocuments | undefined => {
    const selectedShipmentId = getSelectedShipmentId(state);
    return selectedShipmentId ? getShipmentDocuments(state)[selectedShipmentId] : undefined;
};

export const isShipmentDocumentsGenerationRunningForSelectedShipment = (state: State): boolean => {
    const selectedShipmentId = state.webEdi.shipments.selectedShipmentId;
    if (!selectedShipmentId) {
        return false;
    }

    return state.webEdi.shipmentDocuments.isGeneratingShipmentDocumentsFor[selectedShipmentId] ?? false;
};

export const getDocumentSettings = (state: State): DocumentSettings => state.webEdi.shipmentDocuments.documentSettings;

export const getTsbDocumentSettings = (state: State): TsbDocumentSettings =>
    state.webEdi.shipmentDocuments.documentSettings.tsbSettings;

export const getGtlDocumentSettings = (state: State): GtlDocumentSettings =>
    state.webEdi.shipmentDocuments.documentSettings.gtlSettings;
