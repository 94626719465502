import { captureException, withScope } from '@sentry/browser';
import { Component, ErrorInfo, ReactNode } from 'react';

export class ErrorBoundary extends Component<{ children?: ReactNode }> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        withScope((scope) => {
            scope.setExtra('componentStack', errorInfo.componentStack);
            captureException(error);
        });
    }

    render() {
        return this.props.children;
    }
}
