// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const AddressCodec = t.partial({
    street: t.string,
    city_name: t.string,
    postal_identification_code: t.string,
    country_name_code: t.string,
    country_sub_entity_name_code: t.string,
});

export type ApiAddress = t.TypeOf<typeof AddressCodec>;
