import { isEmpty } from 'lodash';
import { BaseSyntheticEvent, KeyboardEvent, KeyboardEventHandler, ReactNode } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';

import { MeasurementUnitCode } from '../../../domain/common.types';

export interface NumberInputProps extends FieldRenderProps<number> {
    id?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    digitPrecision?: number;
    maxStringLength?: number;
    className?: string;
    suppressErrorBlock?: boolean | undefined;
    forbidScientificNotation?: boolean;
    measurementUnitCode?: MeasurementUnitCode;
    outerClassName?: string;
    errorMessageClassName?: string;
    warning?: any;
    inputGroupAddonRight?: string | ReactNode;
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
}

export const getNumberInputParseFunction =
    (measurementUnitCode?: MeasurementUnitCode) =>
    (value: any): number => {
        if (!isDecimalBasedMeasurementUnit(measurementUnitCode)) {
            return value && parseInt(value, 10);
        } else {
            return value && parseFloat(value);
        }
    };

export const isDecimalBasedMeasurementUnit = (measurementUnitCode?: MeasurementUnitCode): boolean => {
    switch (measurementUnitCode) {
        case undefined:
        case MeasurementUnitCode.PIECE:
        case MeasurementUnitCode.DRUM:
        case MeasurementUnitCode.LEAF:
        case MeasurementUnitCode.PAIR:
        case MeasurementUnitCode.SET:
        case MeasurementUnitCode.ROLL:
            return false;
        default:
            return true;
    }
};

export const getDefaultDigitPrecision = (unit?: MeasurementUnitCode) => (isDecimalBasedMeasurementUnit(unit) ? 2 : 0);

export const NumberInput = (props: NumberInputProps) => {
    const { input, meta, ...rest } = props;
    const {
        id,
        disabled,
        min,
        max,
        step,
        maxStringLength,
        className,
        suppressErrorBlock,
        forbidScientificNotation,
        measurementUnitCode,
        outerClassName,
        errorMessageClassName,
        warning,
        inputGroupAddonRight,
    } = rest;
    const dataTestId = rest[DATA_TEST_ID_PROPERTY_NAME];
    const feedbackClassName = 'has-feedback';
    const errorClassName = 'has-error';
    const warningClassName = 'has-warning';
    const getInputRowClassName = (touched: boolean | undefined, error: any) => {
        const showError = !suppressErrorBlock && error && touched;
        const showWarning = warning !== undefined;
        const showFeedback = showError || showWarning;

        return `form-group ${showFeedback ? feedbackClassName : ''}  
            ${showError ? errorClassName : ''}
            ${showWarning ? warningClassName : ''}
            ${outerClassName ?? ''}
            form-control-feedback-wrapper`;
    };
    const blockScientificNotation: KeyboardEventHandler<HTMLInputElement> = (event: KeyboardEvent) => {
        if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault();
        }
    };
    const getDefaultStep = () => (isDecimalBasedMeasurementUnit(measurementUnitCode) ? 0.01 : 1);

    const onBlur = (event: BaseSyntheticEvent) => {
        const digitPrecision = props.digitPrecision ?? getDefaultDigitPrecision(measurementUnitCode);
        event.target.value !== undefined && !isEmpty(event.target.value)
            ? input.onChange(Number(event.target.value).toFixed(digitPrecision))
            : input.onChange(event.target.value);
        input.onBlur(event as any);
    };

    const renderInput = (
        <input
            id={id}
            data-testid={dataTestId}
            type={'number'}
            min={min}
            max={max}
            step={step ?? getDefaultStep()}
            className={`${className} padding-right-5`}
            disabled={disabled}
            onKeyDown={forbidScientificNotation ? blockScientificNotation : (event) => event}
            {...input}
            onBlur={onBlur}
        />
    );
    const renderInputGroupWithAddon = (
        <div className={'input-group'}>
            {renderInput}
            <div className={'input-group-addon'}>{inputGroupAddonRight}</div>
        </div>
    );

    return (
        <div className={getInputRowClassName(meta.touched, meta.error)}>
            {inputGroupAddonRight ? renderInputGroupWithAddon : renderInput}
            {!suppressErrorBlock && meta.touched && meta.error ? (
                <span className={`help-block ${errorMessageClassName}`}>
                    <FormattedMessage
                        id={meta.error}
                        values={{
                            number: maxStringLength,
                            maxValue: max,
                        }}
                    />
                </span>
            ) : null}
            {warning ? (
                <>
                    <span className='form-control-feedback rioglyph rioglyph-exclamation-sign' />
                    <span className='help-block'>
                        <FormattedMessage id={warning} />
                    </span>
                </>
            ) : null}
        </div>
    );
};
