import EditableContent from '@rio-cloud/rio-uikit/lib/es/EditableContent';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MeasurementUnitCode } from '../../domain/common.types';
import { MeasurementUnitCodeDisplay } from './MeasurementUnitCodeDisplay';

export const DimensionInputComponent = (props: {
    value: number | undefined;
    saveHandler: (value: number | undefined) => void;
    valueTestId: string;
    validationErrorTestId: string | undefined;
    content: ReactNode;
}) => {
    const errorMessage = (
        <span data-testid={props.validationErrorTestId}>
            <FormattedMessage id={'webedi.dimension.error.invalidValue'} />
        </span>
    );

    const [value, setValue] = useState<number | undefined>(props.value);
    const [valid, setValid] = useState<boolean>(true);

    const resetState = () => {
        setValue(value);
        setValid(true);
    };

    const validateInput = () => {
        const newValid = value !== undefined && value > 0;
        setValid(newValid);
        return newValid;
    };

    return (
        <EditableContent
            onEditMode={resetState}
            errorMessage={valid ? null : errorMessage}
            onSaveValidation={validateInput}
            onSave={(_: string) => props.saveHandler(value)}
            editorClassName={'width-200'}
            customEditor={
                <div className={'margin-0 min-width-100'}>
                    <NumberInput
                        className={'padding-right-5'}
                        value={props.value}
                        unit={<MeasurementUnitCodeDisplay unit={MeasurementUnitCode.MILLIMETRE} />}
                        data-testid={props.valueTestId}
                        digitPrecision={0}
                        onChange={setValue}
                    />
                </div>
            }
        >
            {props.content}
        </EditableContent>
    );
};
