import { createSlice } from '@reduxjs/toolkit';
import { AppState, InitializationState } from './types';

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    initializationState: InitializationState.INCOMPLETE,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state: AppState) => {
            state.sessionExpiredAcknowledged = true;
        },
        completeInitialization: (state: AppState) => {
            state.initializationState = InitializationState.COMPLETE;
        },
        initializationFailed: (state: AppState) => {
            state.initializationState = InitializationState.FAILED;
        },
    },
});
