import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '../common/Tooltip';
import { TextInput } from '../common/form/TextInput';

export const MEANS_OF_TRANSPORT_TDT_ELEMENT_TEST_ID = 'MEANS_OF_TRANSPORT_TDT_ELEMENT_TEST_ID';
export const MEANS_OF_TRANSPORT_EQD_ELEMENT_TEST_ID = 'MEANS_OF_TRANSPORT_EQD_ELEMENT_TEST_ID';

interface TransportModeProps {
    tdtPropertyId: string;
    tdtPropertyValue?: string;
    tdtPropertyPreviousValue?: string;
    tdtPropertyTooltip?: string;
    eqdPropertyId?: string;
    eqdPropertyValue?: string;
    readonly: boolean;
}

export const TransportMode = (props: TransportModeProps) => (
    <div className={'display-flex-sm'}>
        <div className={'width-100pct-sm padding-right-15'}>
            <TransportModeProperty
                id={props.tdtPropertyId}
                value={props.tdtPropertyValue}
                previousValue={props.tdtPropertyPreviousValue}
                tooltip={props.tdtPropertyTooltip}
                readonly={props.readonly}
                testId={MEANS_OF_TRANSPORT_TDT_ELEMENT_TEST_ID}
            />
        </div>
        <div className={'width-100pct-sm padding-right-15'}>
            {props.eqdPropertyId && props.eqdPropertyValue && (
                <TransportModeProperty
                    id={props.eqdPropertyId!}
                    value={props.eqdPropertyValue!}
                    readonly={props.readonly}
                    testId={MEANS_OF_TRANSPORT_EQD_ELEMENT_TEST_ID}
                />
            )}
        </div>
    </div>
);

export interface TransportModePropertyProps {
    id: string;
    value?: string;
    previousValue?: string;
    readonly: boolean;
    testId?: string;
    tooltip?: string;
}

export const TransportModeProperty = (props: TransportModePropertyProps) => (
    <div data-testid={props.testId}>
        <label htmlFor={props.id}>
            <FormattedMessage id={`webedi.shipment.meansOfTransport.labels.${props.id}`} />
        </label>

        {props.tooltip && (
            <Tooltip width={300} text={<FormattedMessage id={props.tooltip} />} placement={'auto'}>
                <span
                    data-testid={'tooltip-test-id'}
                    className={'rioglyph rioglyph-info-sign text-size-18 text-info form-group margin-left-5'}
                />
            </Tooltip>
        )}

        {props.readonly ? (
            <input id={props.id} className={'form-control'} disabled type={'text'} value={props.value} />
        ) : (
            <Field
                id={props.id}
                name={props.value ? props.value : ''}
                component={TextInput}
                className={'form-control'}
                previousValue={props.previousValue}
            />
        )}
    </div>
);
