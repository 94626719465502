import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { CourierOrder, CourierOrderParcel } from '../../domain/shippers.types';
import { decodeJson } from '../apiUtils';
import { ApiCourierOrderParcel, ApiCourierOrders, courierOrdersCodec } from './courierOrders.types';

export const mapCourierOrderParcels = (data: ApiCourierOrderParcel[]): CourierOrderParcel[] => {
    return data.map((i) => ({ trackingId: i.tracking_id, trackingUrl: i.tracking_url }));
};

export const mapApiCourierOrders = (data: ApiCourierOrders): CourierOrder[] => {
    return data.items.map((i) => ({
        parcels: mapCourierOrderParcels(i.parcels),
        printableLabel: i.printable_label,
        shipmentId: i.shipment_id,
    }));
};

export const transformCourierOrderValidationResponse = (response: unknown): CourierOrder[] => {
    const decoded = decodeJson(courierOrdersCodec)(response);
    return mapApiCourierOrders(decoded);
};

interface CourierOrdersParams {
    dunsNumber: string;
    shipmentIds: string[];
}

export const shippersApi = createApi({
    reducerPath: 'shippersApi',
    tagTypes: ['Shippers'],
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        courierOrders: builder.query<CourierOrder[], CourierOrdersParams>({
            query: ({ dunsNumber, shipmentIds }) => {
                if (!shipmentIds || shipmentIds.length === 0 || shipmentIds.includes('')) {
                    throw new Error('shipmentIds must contain at least one element.');
                }
                return {
                    url: `/shippers/${dunsNumber}/courier-orders?shipment_ids=${shipmentIds.join(',')}`,
                };
            },
            transformResponse: (response: unknown) => transformCourierOrderValidationResponse(response),
        }),
    }),
});

export const { useCourierOrdersQuery, useLazyCourierOrdersQuery } = shippersApi;
