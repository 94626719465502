import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { config } from '../../../../config';
import { accessToken } from '../../../../configuration/tokenHandling/accessToken';
import { neverReachedFor } from '../../../../utils';
import {
    DeliveryInstructionType,
    EdiFileSummary,
    ReferenceType,
    ReferencedDeliveryInstructions,
    ReferencedShipments,
    VDA4984EdiFileSummary,
    VDA4987EdiFileSummary,
} from '../../domain/files.types';
import { decodeJson } from '../apiUtils';
import { fromEnum } from '../util';

const DeliveryInstructionTypeCodec = fromEnum<DeliveryInstructionType>(
    'DeliveryInstructionType',
    DeliveryInstructionType,
);

const vda4984EdiFileSummary = t.type(
    {
        id: t.string,
        stored_at: t.string,
        duns_number: t.string,
        name: t.string,
        size: t.number,
        reference_type: t.literal('DELIVERY_INSTRUCTION'),
        referenced_delivery_instructions: t.record(
            t.string,
            t.intersection([
                t.type({ id: t.string }),
                t.partial({
                    delivery_instruction_number: t.string,
                    delivery_instruction_date: t.string,
                }),
            ]),
        ),
        delivery_instruction_type: DeliveryInstructionTypeCodec,
    },
    'vda4984EdiFileSummary',
);

const vda4987EdiFileSummary = t.type(
    {
        id: t.string,
        stored_at: t.string,
        duns_number: t.string,
        name: t.string,
        size: t.number,
        referenced_shipments: t.record(t.string, t.type({ id: t.string })),
        reference_type: t.literal('SHIPMENT'),
    },
    'vda4987EdiFileSummary',
);

const ediFileSummaryList = t.type(
    {
        items: t.array(t.union([vda4984EdiFileSummary, vda4987EdiFileSummary])),
    },
    'ediFileSummaryList',
);

const EdiFileSummariesApiCodec = ediFileSummaryList;

type Vda4984EdiFileSummariesApi = t.TypeOf<typeof vda4984EdiFileSummary>;
type Vda4987EdiFileSummariesApi = t.TypeOf<typeof vda4987EdiFileSummary>;
type EdiFileSummariesApi = t.TypeOf<typeof ediFileSummaryList>;

const mapVda4984 = (it: Vda4984EdiFileSummariesApi): VDA4984EdiFileSummary => {
    const referencedDeliveryInstructions: ReferencedDeliveryInstructions = {};
    Object.values(it.referenced_delivery_instructions).forEach((value) => {
        referencedDeliveryInstructions[value.id] = {
            id: value.id,
            deliveryInstructionNumber: value.delivery_instruction_number,
            deliveryInstructionDate: value.delivery_instruction_date,
        };
    });

    return {
        id: it.id,
        storedAt: it.stored_at,
        dunsNumber: it.duns_number,
        filename: it.name,
        fileSize: it.size,
        referencedDeliveryInstructions,
        referenceType: ReferenceType[it.reference_type],
        deliveryInstructionType: it.delivery_instruction_type,
    };
};

const mapVda4987 = (it: Vda4987EdiFileSummariesApi): VDA4987EdiFileSummary => {
    const referencedShipments: ReferencedShipments = {};
    Object.values(it.referenced_shipments).forEach((value) => {
        referencedShipments[value.id] = {
            id: value.id,
        };
    });

    return {
        id: it.id,
        storedAt: it.stored_at,
        dunsNumber: it.duns_number,
        filename: it.name,
        fileSize: it.size,
        referencedShipments,
        referenceType: ReferenceType[it.reference_type],
    };
};

const mapToEdiFileSummaries = (decoded: EdiFileSummariesApi): EdiFileSummary[] =>
    decoded.items.map((it) => {
        switch (it.reference_type) {
            case 'DELIVERY_INSTRUCTION':
                return mapVda4984(it);
            case 'SHIPMENT':
                return mapVda4987(it);
            default:
                return neverReachedFor(it);
        }
    });

const transformEdiFileSummaries = (response: unknown): EdiFileSummary[] => {
    const decoded = decodeJson(EdiFileSummariesApiCodec)(response);
    return mapToEdiFileSummaries(decoded);
};

interface GetEdiFileMetadataParameters {
    dunsNumber: string;
    referenceType?: ReferenceType;
    referenceId?: string;
}

export const ediFilesApi = createApi({
    reducerPath: 'ediFilesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.webEdiServiceUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            headers.set('accept', 'application/json');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getEdiFileMetadata: builder.query<EdiFileSummary[], GetEdiFileMetadataParameters>({
            query: ({ dunsNumber, referenceId, referenceType }) => ({
                url: `/shippers/${dunsNumber}/files`,

                params: { reference_type: referenceType, reference_id: referenceId },
            }),
            transformResponse: (response: unknown) => transformEdiFileSummaries(response),
        }),
    }),
});

export const { useGetEdiFileMetadataQuery } = ediFilesApi;
