// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { fromEnum } from '../util';

export enum ShipmentDateType {
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
}

const ShipmentDateTypeCodec = fromEnum<ShipmentDateType>('ShipmentDateType', ShipmentDateType);

export const ShipmentDateCodec = t.type({
    value: t.string,
    type: ShipmentDateTypeCodec,
});

export type ApiShipmentDate = t.TypeOf<typeof ShipmentDateCodec>;
