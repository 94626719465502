// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { Quantity } from '../../domain/common.types';
import { AuxiliaryHandlingUnitGroup } from '../../reducers/auxiliaryPackaging.types';
import { Ownership } from '../../reducers/shipments/packaging.types';
import { ApiQuantity } from '../shared/quantity.types';
import { ApiAuxiliaryHandlingUnitGroup } from '../shipment/packaging.types';
import { DispatchProposalItemKey, TimeWindow } from './dispatchProposalApi';

const dispatchProposalIdentifierCodec = t.type({
    dispatch_proposal_id: t.string,
});

export type ApiDispatchProposalIdentifier = t.TypeOf<typeof dispatchProposalIdentifierCodec>;

export const dispatchProposalOperationResultCodec = t.type({
    affected_dispatch_proposals: t.array(dispatchProposalIdentifierCodec),
});

export type ApiDispatchProposalOperationResult = t.TypeOf<typeof dispatchProposalOperationResultCodec>;

export type ApiDispatchProposalOperation =
    | ApiAddArticleNumberOperation
    | ApiModifyLoadItemAmountOperation
    | ApiDeleteTransportOrderIntentsOperation
    | ApiApplyPackagingTemplateToItemOperation
    | ApiCombinePackagingOperation
    | ApiChangeLoadingLocationOperation
    | ApiConvertItemToKepOperation;

interface ApiArticleAmount {
    referenced_delivery_schedule_id: string;
    quantity: ApiQuantity;
}

interface ApiAddArticleNumberOperation {
    type: 'ADD_ARTICLE';
    id: string;
    parameters: {
        article_amount: ApiArticleAmount;
    };
}

interface ApiModifyLoadItemAmountOperation {
    type: 'MODIFY_LOAD_ITEM_AMOUNT';
    id: string;
    parameters: {
        shipment_id: string;
        delivery_note_number: number;
        delivery_note_position: number;
        target_amount: number;
    };
}

interface ApiDeleteTransportOrderIntentsOperation {
    type: 'DELETE_TRANSPORT_ORDER_INTENTS';
    id: string;
}

export type ApiDispatchProposalsOperation = ApiCreateTransportOrderIntentsOperation;

export interface ApiCreateTransportOrderIntentsOperation {
    id: string;
    operation: 'CREATE_TRANSPORT_ORDER_INTENTS';
    dispatch_proposal_item_keys: ApiDispatchProposalItemKey[];
    pickup_time_window: ApiTimeWindow;
    access_code: string | undefined;
}

export interface ApiTimeWindow {
    time_from: string;
    time_to: string;
}

export type ApiDispatchProposalItemKey =
    | ApiPackagedDispatchProposalItemIdentifier
    | ApiUnpackagedDispatchProposalItemIdentifier;

interface ApiPackagedDispatchProposalItemIdentifier {
    id: string;
    shipment_id: string;
}

interface ApiUnpackagedDispatchProposalItemIdentifier {
    shipment_id: string;
    delivery_note_number: number;
    delivery_note_position: number;
}

interface ApiApplyPackagingTemplateToItemOperation {
    type: 'APPLY_PACKAGING_TEMPLATE_TO_ITEM';
    id: string;
    parameters: {
        item_identifier: ApiDispatchProposalItemKey;
        packaging_template_id: string;
    };
}

export interface ApiCombinePackagingOperation {
    type: 'COMBINE_PACKAGING';
    id: string;
    parameters: {
        shipment_id: string;
        giphys_to_combine: {
            giphy_id: string;
            amount: number;
        }[];
        amount_of_handling_units: number;
        outer_packaging_to_apply: {
            type: string;
            description?: string;
            auxiliary_packaging: ApiAuxiliaryHandlingUnitGroup[];
            is_reusable: boolean;
            ownership: Ownership;
            stacking_factor: number;
            tare_weight_in_kg: number;
        };
    };
}

export interface ApiChangeLoadingLocationOperation {
    type: 'CHANGE_LOADING_LOCATION';
    id: string;
    parameters: {
        item_identifier: ApiDispatchProposalItemKey;
        loading_location_id: string;
    };
}

export interface ApiConvertItemToKepOperation {
    type: 'CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP';
    id: string;
    parameters: {
        item_identifier: ApiDispatchProposalItemKey;
    };
}

export enum DispatchProposalOperationType {
    ADD_ARTICLE = 'ADD_ARTICLE',
    MODIFY_LOAD_ITEM_AMOUNT = 'MODIFY_LOAD_ITEM_AMOUNT',
    DELETE_TRANSPORT_ORDER_INTENTS = 'DELETE_TRANSPORT_ORDER_INTENTS',
    APPLY_PACKAGING_TEMPLATE_TO_ITEM = 'APPLY_PACKAGING_TEMPLATE_TO_ITEM',
    COMBINE_PACKAGING = 'COMBINE_PACKAGING',
    CHANGE_LOADING_LOCATION = 'CHANGE_LOADING_LOCATION',
    CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP = 'CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP',
}

export type DispatchProposalOperation =
    | AddArticleToDispatchProposalOperation
    | ModifyLoadItemAmountOperation
    | DeleteDispatchProposalIntentsOperation
    | ApplyPackagingTemplateToItemOperation
    | CombinePackagingOperation
    | ChangeLoadingLocationOperation
    | ConvertItemToKepOperation;

export interface AddArticleToDispatchProposalOperation {
    type: DispatchProposalOperationType.ADD_ARTICLE;
    dunsNumber: string;
    dispatchProposalId: string;
    referencedDeliveryScheduleId: string;
    amount: Quantity;
}

export interface ModifyLoadItemAmountOperation {
    type: DispatchProposalOperationType.MODIFY_LOAD_ITEM_AMOUNT;
    dunsNumber: string;
    dispatchProposalId: string;
    shipmentId: string;
    deliveryNoteNumber: number;
    deliveryNotePosition: number;
    targetAmount: number;
}

export interface DeleteDispatchProposalIntentsOperation {
    type: DispatchProposalOperationType.DELETE_TRANSPORT_ORDER_INTENTS;
    dunsNumber: string;
    dispatchProposalId: string;
}

export interface ApplyPackagingTemplateToItemOperation {
    type: DispatchProposalOperationType.APPLY_PACKAGING_TEMPLATE_TO_ITEM;
    dunsNumber: string;
    dispatchProposalId: string;
    parameters: {
        itemIdentifier: DispatchProposalItemKey;
        packagingTemplateId: string;
    };
}
export interface CombinePackagingOperation {
    type: DispatchProposalOperationType.COMBINE_PACKAGING;
    dunsNumber: string;
    dispatchProposalId: string;
    parameters: {
        shipmentId: string;
        giphysToCombine: {
            giphyId: string;
            amount: number;
        }[];
        amountOfHandlingUnits: number;
        outerPackagingToApply: {
            type: string;
            description?: string;
            auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
            isReusable: boolean;
            ownership: Ownership;
            stackingFactor: number;
            tareWeightInKg: number;
        };
    };
}

export interface ChangeLoadingLocationOperation {
    type: DispatchProposalOperationType.CHANGE_LOADING_LOCATION;
    dunsNumber: string;
    dispatchProposalId: string;
    parameters: {
        itemIdentifier: DispatchProposalItemKey;
        loadingLocationId: string;
    };
}

export interface ConvertItemToKepOperation {
    type: DispatchProposalOperationType.CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP;
    dunsNumber: string;
    dispatchProposalId: string;
    parameters: {
        itemIdentifier: DispatchProposalItemKey;
    };
}

export type DispatchProposalsOperation = CreateTransportOrderIntentsOperation;

interface CreateTransportOrderIntentsOperation {
    operation: 'CREATE_TRANSPORT_ORDER_INTENTS';
    dunsNumber: string;
    dispatchProposalItemKeys: DispatchProposalItemKey[];
    pickupTimeWindow: TimeWindow;
    accessCode: string | undefined;
}
