// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { FreightForwarderCodec } from '../shipment/shipment.types';

export const ManagedFreightForwarderAddressCodec = t.intersection([
    t.type({
        street_address: t.array(t.string),
    }),
    t.partial({
        postal_code: t.string,
        city: t.string,
        region_code: t.string,
        country_code: t.string,
    }),
]);

export const ManagedFreightForwarderCodec = t.intersection([
    t.type({
        id: t.string,
        duns_number: t.string,
        duns_number_owner: t.string,
        name: t.array(t.string),
        address: ManagedFreightForwarderAddressCodec,
    }),
    t.partial({
        party_identifier_buyer: t.string,
        party_identifier_seller: t.string,
    }),
]);

export const PartyIdentifierAssignedByBuyerCodec = t.type({
    type: t.literal('ASSIGNED_BY_BUYER'),
    value: t.string,
});

export const PartyIdentifierAssignedBySellerCodec = t.type({
    type: t.literal('ASSIGNED_BY_SELLER'),
    value: t.string,
});

const PartyIdentifierCodec = t.union([PartyIdentifierAssignedByBuyerCodec, PartyIdentifierAssignedBySellerCodec]);

export const FreightForwarderFromTransportOrderCodec = t.type({
    name: t.array(t.string),
    duns_number: t.string,
    party_identifier: PartyIdentifierCodec,
});

export const ManagedFreightForwardersCodec = t.type({
    items: t.array(ManagedFreightForwarderCodec),
});

export type ApiPartyIdentifier = t.TypeOf<typeof PartyIdentifierCodec>;
export type ApiFreightForwarder = t.TypeOf<typeof FreightForwarderCodec>;
export type ApiManagedFreightForwarder = t.TypeOf<typeof ManagedFreightForwarderCodec>;
export type ApiFreightForwarderFromTransportOrder = t.TypeOf<typeof FreightForwarderFromTransportOrderCodec>;
export type ApiManagedFreightForwarderAddress = t.TypeOf<typeof ManagedFreightForwarderAddressCodec>;
export type ApiManagedFreightForwarders = t.TypeOf<typeof ManagedFreightForwardersCodec>;
