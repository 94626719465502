import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { neverReachedFor } from '../../../../utils';
import { deliverySchedulesSlice } from '../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    getSelectedDeliveryScheduleId,
    getShowDeliveryScheduleDialog,
} from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { DeliveryInstructionsArchiveDialog } from './archive/DeliveryInstructionsArchiveDialog';
import { DeliveryScheduleDetailsDialog } from './deliveryScheduleDetails/DeliveryScheduleDetailsDialog';

export enum DisplayMode {
    ARCHIVE = 'ARCHIVE',
    DETAILS = 'DETAILS',
}

export const DeliveryScheduleDialog = () => {
    const dispatch = useAppDispatch();
    const selectedDeliveryScheduleId = useAppSelector(getSelectedDeliveryScheduleId);
    const showDeliveryScheduleDialog = useAppSelector(getShowDeliveryScheduleDialog);
    const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.DETAILS);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        setDisplayMode(DisplayMode.DETAILS);
    }, [selectedDeliveryScheduleId]);

    if (selectedDeliveryScheduleId === undefined || !showDeliveryScheduleDialog) {
        return null;
    }

    const onHideDialog = () => {
        dispatch(deliverySchedulesSlice.actions.clearSelectedDeliveryScheduleId());
        dispatch(deliverySchedulesSlice.actions.setShowDeliveryScheduleDialog(false));
    };

    switch (displayMode) {
        case DisplayMode.ARCHIVE:
            return <DeliveryInstructionsArchiveDialog setDisplayMode={setDisplayMode} onHide={onHideDialog} />;
        case DisplayMode.DETAILS:
            return <DeliveryScheduleDetailsDialog setDisplayMode={setDisplayMode} onHide={onHideDialog} />;
        default:
            return neverReachedFor(displayMode);
    }
};

export interface DeliveryScheduleChildDialogProps {
    setDisplayMode: (_: DisplayMode) => void;
    onHide: () => void;
}
