import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from '../../features/app/api/baseApi';
import { supplierDataApi } from '../../features/app/api/deliverySchedule/supplierDataApi';
import { courierOrderIntentApi } from '../../features/app/api/dispatchProposal/courierOrderIntent/courierOrderIntentApi';
import { emailNotificationApi } from '../../features/app/api/emailNotification/emailNotificationApi';
import { ediFilesApi } from '../../features/app/api/file/ediFilesApi';
import { loadingLocationApi } from '../../features/app/api/loadingLocation/loadingLocationApi';
import { placeOfDischargeMasterDataApi } from '../../features/app/api/masterData/placesOfDischargeMasterDataApi';
import { packagingTemplateApi } from '../../features/app/api/packagingTemplate/packagingTemplateApi';
import { rtkGlobalErrorHandler } from '../../features/app/api/rtkGlobalErrorHandler';
import { shipmentApi } from '../../features/app/api/shipment/shipmentApi';
import { shippersApi } from '../../features/app/api/shippers/shippersApi';
import { transportOrderApi } from '../../features/app/api/transportOrder/transportOrdersApi';
import { transportOrderIntentApi } from '../../features/app/api/transportOrderIntent/transportOrderIntentsApi';
import { rootReducer } from '../../reducers';

export const createStore = (preloadedState?: any) =>
    configureStore({
        reducer: rootReducer(),
        middleware: (getDefaultMiddleWare) =>
            getDefaultMiddleWare({ serializableCheck: { warnAfter: 256 }, immutableCheck: { warnAfter: 256 } })
                .concat(shipmentApi.middleware)
                .concat(transportOrderIntentApi.middleware)
                .concat(transportOrderApi.middleware)
                .concat(baseApi.middleware)
                .concat(supplierDataApi.middleware)
                .concat(placeOfDischargeMasterDataApi.middleware)
                .concat(ediFilesApi.middleware)
                .concat(courierOrderIntentApi.middleware)
                .concat(shippersApi.middleware)
                .concat(packagingTemplateApi.middleware)
                .concat(loadingLocationApi.middleware)
                .concat(emailNotificationApi.middleware)
                .concat(rtkGlobalErrorHandler()),
        preloadedState,
    });

const store = createStore();

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

export { store };
