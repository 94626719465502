import { SelectOption } from '@rio-cloud/rio-uikit';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import SelectUiKit from '@rio-cloud/rio-uikit/lib/es/Select';
import { FormApi } from 'final-form';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { getDisplayCountries } from '../../../../configuration/lang/selectors';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../configuration/tokenHandling/selectors';
import { sanitize } from '../../../../utils';
import {
    deleteManagedFreightForwarderAndRefresh,
    saveManagedFreightForwarderAndRefresh,
} from '../../actions/masterData/MasterData.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { ManagedFreightForwarder } from '../../reducers/transportation/types';
import { getSelectedManagedFreightForwarder } from '../../selectors/masterData/MasterData.selector';
import { Tooltip } from '../common/Tooltip';
import { ManagedFreightForwarderFormValues } from './ManagedFreightForwarderForm.types';
import { maxFieldLengths, validate } from './ManagedFreightForwarderFormValidation';
import { ManagedFreightForwarderIdHelpLink } from './ManagedFreightForwarderIdHelpLink';

export const MANAGED_FREIGHT_FORWARDER_FORM_TEST_ID = 'MANAGED_FREIGHT_FORWARDER_FORM_TEST_ID';

export const dataTestIds = {
    partyIdentifierBuyer: 'MANAGED_FREIGHT_FORWARDER_FORM_PARTY_IDENTIFIER_BUYER_INPUT',
    partyIdentifierSeller: 'MANAGED_FREIGHT_FORWARDER_FORM_PARTY_IDENTIFIER_SELLER_INPUT',
    name: 'MANAGED_FREIGHT_FORWARDER_FORM_NAME_INPUT',
    name2: 'MANAGED_FREIGHT_FORWARDER_FORM_NAME_2_INPUT',
    address: 'MANAGED_FREIGHT_FORWARDER_FORM_ADDRESS_INPUT',
    address2: 'MANAGED_FREIGHT_FORWARDER_FORM_ADDRESS_2_INPUT',
    postalCode: 'MANAGED_FREIGHT_FORWARDER_FORM_POSTAL_CODE_INPUT',
    city: 'MANAGED_FREIGHT_FORWARDER_FORM_CITY_INPUT',
    regionCode: 'MANAGED_FREIGHT_FORWARDER_FORM_REGION_CODE_INPUT',
    countryCode: 'MANAGED_FREIGHT_FORWARDER_FORM_COUNTRY_CODE_SELECT',
    dunsNumber: 'DUNS_NUMBER_INPUT',
};

const managedFreightForwarderFormId = 'MANAGED_FREIGHT_FORWARDER_FORM_ID';

export const mapManagedFreightForwarderFormValuesToManagedFreightForwarder = (
    values: ManagedFreightForwarderFormValues,
    dunsNumberOwner: string,
): ManagedFreightForwarder => {
    return sanitize({
        id: values.id || uuid(),
        dunsNumber: values.dunsNumber,
        dunsNumberOwner: values.dunsNumberOwner || dunsNumberOwner,
        partyIdentifierBuyer: values.partyIdentifierBuyer,
        partyIdentifierSeller: values.partyIdentifierSeller,
        name: [values.name, values.name2],
        address: {
            streetAddress: [values.address, values.address2],
            postalCode: values.postalCode,
            city: values.city,
            regionCode: values.regionCode,
            countryCode: values.countryCode,
        },
        type: 'MANAGED_FREIGHT_FORWARDER',
    });
};

export const mapManagedFreightForwarderToManagedFreightForwarderFormValues = (
    managedFreightForwarder: ManagedFreightForwarder,
): ManagedFreightForwarderFormValues => {
    return {
        id: managedFreightForwarder.id,
        dunsNumberOwner: managedFreightForwarder.dunsNumberOwner,
        dunsNumber: managedFreightForwarder.dunsNumber || '',
        partyIdentifierBuyer: managedFreightForwarder.partyIdentifierBuyer || '',
        partyIdentifierSeller: managedFreightForwarder.partyIdentifierSeller || '',
        name: managedFreightForwarder.name[0],
        name2: managedFreightForwarder.name[1] || '',
        address: managedFreightForwarder.address.streetAddress[0],
        address2: managedFreightForwarder.address.streetAddress[1] || '',
        postalCode: managedFreightForwarder.address.postalCode || '',
        city: managedFreightForwarder.address.city || '',
        regionCode: managedFreightForwarder.address.regionCode || '',
        countryCode: managedFreightForwarder.address.countryCode || '',
    };
};

export const formatCountryForDisplay = (countryName: string, code: string) => `${countryName} (${code})`;

export const ManagedFreightForwarderForm = (props: { show: boolean; hideDialog: () => void }) => {
    const dispatch = useAppDispatch();
    const selectedManagedFreightForwarder = useAppSelector(getSelectedManagedFreightForwarder);
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const isoCountries = useAppSelector(getDisplayCountries);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);

    if (dunsNumber === undefined) {
        return null;
    }

    const onSubmit = (values: ManagedFreightForwarderFormValues, form: FormApi<ManagedFreightForwarderFormValues>) => {
        const invalid = form.getState().invalid;
        if (!invalid) {
            // Trim whitespace from all string fields
            const trimmedValues = Object.entries(values).reduce((acc, [key, value]) => {
                acc[key as keyof ManagedFreightForwarderFormValues] = value?.trim();
                return acc;
            }, {} as ManagedFreightForwarderFormValues);

            dispatch(
                saveManagedFreightForwarderAndRefresh(
                    mapManagedFreightForwarderFormValuesToManagedFreightForwarder(trimmedValues, dunsNumber),
                ),
            );
            props.hideDialog();
        }
    };

    const makeCountryCodeOptions = (countries: object) => {
        return Object.entries(countries).map(
            ([key, value]) =>
                ({
                    id: key,
                    label: formatCountryForDisplay(value, key),
                }) as SelectOption,
        );
    };

    const renderBody = () => {
        const rowClassName = 'display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-10';
        const errorClassName = 'has-error has-feedback';
        const getInputRowClassName = (touched: any, error: any) =>
            `form-group margin-0 flex-1-1 ${touched && error ? errorClassName : ''}`;
        const formInputClassName = 'form-control';
        const fieldNames = {
            partyIdentifierBuyer: 'partyIdentifierBuyer',
            partyIdentifierSeller: 'partyIdentifierSeller',
            name: 'name',
            name2: 'name2',
            address: 'address',
            address2: 'address2',
            postalCode: 'postalCode',
            city: 'city',
            regionCode: 'regionCode',
            countryCode: 'countryCode',
            dunsNumber: 'dunsNumber',
        };
        const initialValues: ManagedFreightForwarderFormValues = selectedManagedFreightForwarder
            ? mapManagedFreightForwarderToManagedFreightForwarderFormValues(selectedManagedFreightForwarder)
            : {
                  partyIdentifierBuyer: '',
                  partyIdentifierSeller: '',
                  name: '',
                  name2: '',
                  address: '',
                  address2: '',
                  postalCode: '',
                  city: '',
                  regionCode: '',
                  countryCode: '',
                  dunsNumber: '',
              };
        return (
            <Form<ManagedFreightForwarderFormValues>
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form
                        id={managedFreightForwarderFormId}
                        onSubmit={handleSubmit}
                        data-testid={MANAGED_FREIGHT_FORWARDER_FORM_TEST_ID}
                        className={'form-horizontal'}
                    >
                        <div className='display-flex-sm'>
                            <div className='display-flex flex-column width-50pct-sm'>
                                <Field name={fieldNames.name}>
                                    {({ input, meta }) => (
                                        <div className={`${rowClassName} margin-top-10 margin-bottom-0`}>
                                            <label className={'width-30pct text-size-14'} htmlFor={fieldNames.name}>
                                                <FormattedMessage id={'webedi.carrier.name'} />*
                                            </label>
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    id={fieldNames.name}
                                                    type={'text'}
                                                    className={formInputClassName}
                                                    data-testid={dataTestIds.name}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.name }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.name2}>
                                    {({ input, meta }) => (
                                        <div className={`${rowClassName}`}>
                                            <label className={'width-30pct text-size-14'} htmlFor={fieldNames.name2} />
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    className={formInputClassName}
                                                    id={fieldNames.name2}
                                                    data-testid={dataTestIds.name2}
                                                    type={'text'}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.name2 }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.address}>
                                    {({ input, meta }) => (
                                        <div className={rowClassName}>
                                            <label className={'width-30pct text-size-14'} htmlFor={fieldNames.address}>
                                                <FormattedMessage id={'webedi.carrier.address'} />*
                                            </label>
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    className={formInputClassName}
                                                    id={fieldNames.address}
                                                    data-testid={dataTestIds.address}
                                                    type={'text'}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.address }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.address2}>
                                    {({ input, meta }) => (
                                        <div className={rowClassName}>
                                            <label
                                                className={'width-30pct text-size-14'}
                                                htmlFor={fieldNames.address2}
                                            />
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    className={formInputClassName}
                                                    id={fieldNames.address2}
                                                    data-testid={dataTestIds.address2}
                                                    type={'text'}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.address2 }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.postalCode}>
                                    {({ input, meta }) => (
                                        <div className={`${rowClassName} margin-top-10`}>
                                            <label
                                                className={'width-30pct text-size-14'}
                                                htmlFor={fieldNames.postalCode}
                                            >
                                                <FormattedMessage id={'webedi.carrier.postalCode'} />*
                                            </label>
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    className={formInputClassName}
                                                    id={fieldNames.postalCode}
                                                    data-testid={dataTestIds.postalCode}
                                                    type={'text'}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.postalCode }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.city}>
                                    {({ input, meta }) => (
                                        <div className={`${rowClassName} margin-top-10`}>
                                            <label className={'width-30pct text-size-14'} htmlFor={fieldNames.city}>
                                                <FormattedMessage id={'webedi.carrier.city'} />*
                                            </label>
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    className={formInputClassName}
                                                    id={fieldNames.city}
                                                    data-testid={dataTestIds.city}
                                                    type={'text'}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.city }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.regionCode}>
                                    {({ input, meta }) => (
                                        <div className={`${rowClassName} margin-top-10`}>
                                            <label
                                                className={'width-30pct text-size-14'}
                                                htmlFor={fieldNames.regionCode}
                                            >
                                                <FormattedMessage id={'webedi.carrier.regionCode'} />
                                            </label>
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <input
                                                    {...input}
                                                    className={formInputClassName}
                                                    id={fieldNames.regionCode}
                                                    data-testid={dataTestIds.regionCode}
                                                    type={'text'}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage
                                                            id={meta.error}
                                                            values={{ number: maxFieldLengths.regionCode }}
                                                        />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <Field name={fieldNames.countryCode}>
                                    {({ input, meta }) => (
                                        <div className={`${rowClassName} margin-top-10`}>
                                            <label
                                                className={'width-30pct text-size-14'}
                                                htmlFor={fieldNames.countryCode}
                                                data-testid={dataTestIds.countryCode}
                                            >
                                                <FormattedMessage id={'webedi.carrier.countryCode'} />*
                                            </label>
                                            <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                <SelectUiKit
                                                    useFilter={true}
                                                    dropup={true}
                                                    id={fieldNames.countryCode}
                                                    options={makeCountryCodeOptions(isoCountries)}
                                                    onChange={(event?: SelectOption) => input.onChange(event?.id)}
                                                    value={input.value ? [input.value] : undefined}
                                                    placeholder={
                                                        <FormattedMessage
                                                            id={'webedi.inputPlaceholder.dropdown.standard'}
                                                        />
                                                    }
                                                />
                                                {meta.touched && meta.error ? (
                                                    <span className={'help-block'}>
                                                        <FormattedMessage id={meta.error} />
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </Field>
                                <p
                                    className={
                                        'pull-left text-color-gray padding-10 margin-top-10 text-size-10 margin-bottom-10'
                                    }
                                >
                                    <FormattedMessage id={'webedi.hint.requiredFields'} />
                                </p>
                            </div>
                            <div
                                className='display-flex flex-column width-50 margin-left-25
                                     border-style-solid border-left-only border-width-1 border-color-light'
                            />
                            <div
                                className='display-flex flex-column width-50pct-sm
                                     margin-top-15 justify-content-center'
                            >
                                <span className='text-size-16 margin-bottom-25'>
                                    <FormattedMessage id={'webedi.dunsNumber'} />
                                    &nbsp;&&nbsp;
                                    <FormattedMessage id={'webedi.carrier.carrierId'} />
                                </span>
                                <div className='display-flex flex-column flex-1-1'>
                                    <Field name={fieldNames.dunsNumber}>
                                        {({ input, meta }) => (
                                            <div className={`${rowClassName} margin-bottom-0`}>
                                                <label
                                                    className={'width-40pct text-size-14'}
                                                    htmlFor={fieldNames.dunsNumber}
                                                >
                                                    <FormattedMessage id={'webedi.dunsNumber'} />*
                                                </label>
                                                <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                    <input
                                                        {...input}
                                                        className={formInputClassName}
                                                        id={fieldNames.dunsNumber}
                                                        data-testid={dataTestIds.dunsNumber}
                                                        type={'text'}
                                                        minLength={9}
                                                        maxLength={9}
                                                    />
                                                    {meta.touched && meta.error ? (
                                                        <span className={'help-block'}>
                                                            <FormattedMessage
                                                                id={meta.error}
                                                                values={{ number: maxFieldLengths.dunsNumber }}
                                                            />
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <span className='text-size-14 margin-bottom-10'>
                                    <FormattedMessage id={'webedi.carrier.carrierIdHint'} />:
                                </span>
                                <div className='display-flex flex-column flex-1-1'>
                                    <Field name={fieldNames.partyIdentifierBuyer}>
                                        {({ input, meta }) => (
                                            <div className={`${rowClassName} margin-bottom-0`}>
                                                <label
                                                    className={'width-40pct padding-top-20 text-size-14'}
                                                    htmlFor={fieldNames.partyIdentifierBuyer}
                                                >
                                                    <FormattedMessage id={'webedi.carrier.partyIdentifierBuyer'} />*
                                                </label>
                                                <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                    <label className={'display-flex justify-content-end control-label'}>
                                                        <ManagedFreightForwarderIdHelpLink />
                                                    </label>
                                                    <input
                                                        {...input}
                                                        className={formInputClassName}
                                                        id={fieldNames.partyIdentifierBuyer}
                                                        data-testid={dataTestIds.partyIdentifierBuyer}
                                                        type={'text'}
                                                    />
                                                    {meta.touched && meta.error ? (
                                                        <span className={'help-block'}>
                                                            <FormattedMessage
                                                                id={meta.error}
                                                                values={{
                                                                    number: maxFieldLengths.partyIdentifierBuyer,
                                                                }}
                                                            />
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                    <label className='width-90pct padding-10 text-color-gray margin-bottom-25'>
                                        <span className='rioglyph rioglyph-info-sign text-size-14 margin-right-5 text-color-info' />
                                        <FormattedMessage id={'webedi.carrier.partyIdentifierBuyerHint'} />
                                    </label>
                                    <Field name={fieldNames.partyIdentifierSeller}>
                                        {({ input, meta }) => (
                                            <div className={`${rowClassName} margin-bottom-0`}>
                                                <label
                                                    className={'width-40pct text-size-14'}
                                                    htmlFor={fieldNames.partyIdentifierSeller}
                                                >
                                                    <FormattedMessage id={'webedi.carrier.partyIdentifierSeller'} />*
                                                </label>
                                                <div className={getInputRowClassName(meta.touched, meta.error)}>
                                                    <input
                                                        {...input}
                                                        className={formInputClassName}
                                                        id={fieldNames.partyIdentifierSeller}
                                                        data-testid={dataTestIds.partyIdentifierSeller}
                                                        type={'text'}
                                                    />
                                                    {meta.touched && meta.error ? (
                                                        <span className={'help-block'}>
                                                            <FormattedMessage
                                                                id={meta.error}
                                                                values={{
                                                                    number: maxFieldLengths.partyIdentifierSeller,
                                                                }}
                                                            />
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}
                                    </Field>

                                    <label className='width-90pct padding-10 text-color-gray'>
                                        <span className='rioglyph rioglyph-info-sign text-size-14 margin-right-5 text-color-info' />
                                        <FormattedMessage id={'webedi.carrier.partyIdentifierSellerHint'} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            />
        );
    };

    const onDeleteButtonClick = () => {
        selectedManagedFreightForwarder &&
            dispatch(deleteManagedFreightForwarderAndRefresh(dunsNumber, selectedManagedFreightForwarder.id));
        props.hideDialog();
    };

    const renderFooter = () => {
        const DeleteButton = () => {
            const content = (
                <button
                    className={'btn-danger btn btn-default  btn-link'}
                    onClick={onDeleteButtonClick}
                    disabled={isReadOnly}
                >
                    <span className={'rioglyph rioglyph-trash'} />
                    <FormattedMessage id={'webedi.label.delete'} />
                    {isReadOnly && (
                        <span
                            className={
                                'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                            }
                        />
                    )}
                </button>
            );

            if (isReadOnly) {
                return (
                    <Tooltip
                        text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />}
                        placement={'right'}
                    >
                        <span>{content}</span>
                    </Tooltip>
                );
            }

            return content;
        };

        const SaveButton = () => {
            const content = (
                <button
                    className={`ConfirmationButton btn btn-primary`}
                    type={'submit'}
                    form={managedFreightForwarderFormId}
                    disabled={isReadOnly}
                >
                    <FormattedMessage id={'webedi.label.save'} />
                    {isReadOnly && (
                        <span
                            className={
                                'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                            }
                        />
                    )}
                </button>
            );

            if (isReadOnly) {
                return (
                    <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'left'}>
                        <span>{content}</span>
                    </Tooltip>
                );
            }

            return content;
        };

        return (
            <div>
                {selectedManagedFreightForwarder && (
                    <div className={'btn-toolbar pull-left'}>
                        <DeleteButton />
                    </div>
                )}
                <div className={'btn-toolbar pull-right'}>
                    <button className={'CancelButton btn btn-default'} onClick={props.hideDialog}>
                        <FormattedMessage id={'webedi.label.cancel'} />
                    </button>
                    <div className={'btn padding-0'}>
                        <SaveButton />
                    </div>
                </div>
            </div>
        );
    };

    const renderTitle = () => {
        return selectedManagedFreightForwarder === undefined ? (
            <FormattedMessage id={'webedi.carrier.form.title.create'} />
        ) : (
            <FormattedMessage id={'webedi.carrier.form.title.edit'} />
        );
    };

    return (
        <Dialog
            show={props.show}
            title={renderTitle()}
            body={renderBody()}
            footer={renderFooter()}
            bsSize={Dialog.SIZE_LG}
            useOverflow={true}
            onHide={props.hideDialog}
            bodyClassName={'padding-bottom-0'}
        />
    );
};
