import { useForm } from 'react-final-form';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { getCourierOrder } from '../../../../selectors/courierOrderIntent/CourierOrderIntent.selector';
import { fieldNames } from '../courierOrderIntentDialog.types';
import { DownloadTrackingFiles } from './DownloadTrackingFiles';
import { GeneralInformation } from './GeneralInformation';
import { TrackingInfoBanner } from './TrackingInfoBanner';

export const OrderCourierSuccessPage = () => {
    const courierOrder = useAppSelector(getCourierOrder);
    const formApiRef = useForm();
    const trackingId = courierOrder?.parcels[0]?.trackingId!;
    const base64Pdf = courierOrder?.printableLabel!;
    const commissionDate = formApiRef.getState().values[fieldNames.pickupDate];
    const serviceProvider = formApiRef.getState().values[fieldNames.serviceProviderSelection];

    return (
        <>
            <TrackingInfoBanner date={commissionDate} parcels={courierOrder?.parcels!} courier={serviceProvider} />
            <DownloadTrackingFiles pdf={base64Pdf} courier={serviceProvider} id={trackingId} />
            <GeneralInformation courier={serviceProvider} />
        </>
    );
};
