import {
    isNowBeforeNoon,
    localNow,
    plusDuration,
    roundedToNextHalfHour,
    toLocalTime,
    today,
} from '../../../../../../dateUtils';
import { PICKUP_TIME_BUFFER } from '../courierOrderIntentDialogValidation';

export const calculateDefaultPickupDate = (input?: string | null | undefined): string => {
    if (input) {
        return input;
    }

    return isNowBeforeNoon() ? today() : plusDuration(today(), { days: 1 });
};

export const calculateEarliestPickupTime = () => {
    if (isNowBeforeNoon()) {
        return toLocalTime(roundedToNextHalfHour(plusDuration(localNow(), { minutes: 2 * PICKUP_TIME_BUFFER })));
    }
    return '08:00';
};

export const calculateLatestPickupTime = () => '17:00';
