import { getOrBlank } from '../../utils';

interface ExternalVWIdToken {
    auth_time: number;
    jti: string;
    sub: string;
    typ: string;
    azp: string;
    session_state: string;
    acr: string;
    s_hash: string;
    sid: string;
    DUNS: string;
    name: string;
    groups: string[];
    preferred_username: string;
    given_name: string;
    family_name: string;
    email: string;
    resource_access: { [key: string]: { roles: string[] } };
}

interface ExternalRIOIdToken {
    sub: string;
    azp: string;
    account: string;
    tenant: string;
    given_name: string;
    family_name: string;
    name: string;
    username: string;
    locale: string;
    email: string;
}

interface IdToken {
    name: string;
    given_name: string;
    family_name: string;
    sub: string;
    email: string;
}

export interface VWIdToken extends IdToken {
    preferred_username: string;
    roles: string[];
    duns_number: string;
    groups: string[];
}

export const isVWIdToken = (idToken: IdToken): idToken is VWIdToken =>
    (idToken as VWIdToken).roles !== undefined || (idToken as VWIdToken).groups !== undefined;

export const isRioIdToken = (idToken: IdToken): idToken is RIOIdToken => (idToken as RIOIdToken).tenant !== undefined;

export interface RIOIdToken extends IdToken {
    account: string;
    tenant: string;
    locale: string;
}

export const mapExternalTokenToIdToken = (idToken: unknown): VWIdToken | RIOIdToken | undefined => {
    if (typeof idToken !== 'object' || idToken === null || Array.isArray(idToken)) {
        return undefined;
    }

    if (hasVWTokenRequiredFields(idToken)) {
        return mapExternalVWToken(idToken);
    }

    if (hasRioTokenRequiredFields(idToken)) {
        return mapExternalRIOToken(idToken);
    }
    return undefined;
};

const hasVWTokenRequiredFields = (idToken: object): boolean => {
    return (
        idToken.hasOwnProperty('DUNS') ||
        idToken.hasOwnProperty('preferred_username') ||
        idToken.hasOwnProperty('resource_access') ||
        idToken.hasOwnProperty('groups')
    );
};

const hasRioTokenRequiredFields = (idToken: object): boolean => {
    return idToken.hasOwnProperty('account') || idToken.hasOwnProperty('tenant') || idToken.hasOwnProperty('locale');
};

const mapExternalVWToken = (vwIdToken: Partial<ExternalVWIdToken>): VWIdToken => ({
    name: getOrBlank(vwIdToken.name),
    given_name: getOrBlank(vwIdToken.given_name),
    family_name: getOrBlank(vwIdToken.family_name),
    sub: getOrBlank(vwIdToken.sub),
    email: getOrBlank(vwIdToken.email),
    preferred_username: getOrBlank(vwIdToken.preferred_username),
    roles:
        vwIdToken.resource_access && vwIdToken.azp
            ? extractRolesFromVwToken(vwIdToken.resource_access, vwIdToken.azp)
            : [],
    duns_number: getOrBlank(vwIdToken.DUNS),
    groups: vwIdToken.groups || [],
});

const extractRolesFromVwToken = (resources: Partial<ExternalVWIdToken['resource_access']>, azp: string): string[] => {
    if (resources[azp]?.roles === undefined) {
        return [];
    }

    return resources[azp]!.roles;
};

const mapExternalRIOToken = (rioIdToken: Partial<ExternalRIOIdToken>): RIOIdToken => ({
    name: getOrBlank(rioIdToken.name),
    given_name: getOrBlank(rioIdToken.given_name),
    family_name: getOrBlank(rioIdToken.family_name),
    sub: getOrBlank(rioIdToken.sub),
    email: getOrBlank(rioIdToken.email),
    locale: getOrBlank(rioIdToken.locale),
    account: getOrBlank(rioIdToken.account),
    tenant: getOrBlank(rioIdToken.tenant),
});
