import { today } from '../../../../../dateUtils';
import { PostDeliverySchedule } from '../../../api/deliverySchedule/deliveryScheduleApi';
import {
    DeliveryType,
    ItemTypeIdentificationCode,
    ResponsibleAgencyCode,
} from '../../../reducers/deliverySchedules/types';
import { CompleteManualCreationOfDeliverySchedulesFormValues } from './formConfig';

export const mapToPostDeliverySchedule = (
    src: CompleteManualCreationOfDeliverySchedulesFormValues,
    dunsNumber: string,
): PostDeliverySchedule => ({
    dunsNumber,
    processIndicator: src.processIndicator,
    seller: src.supplierData.seller,
    buyer: src.supplierData.buyer,
    shipFrom: src.supplierData.shipFrom,
    shipTo: {
        placeOfDischargeId: src.placeOfDischarge.id,
        placeOfDelivery: {
            locationNameCode: src.placeOfDelivery,
            responsibleAgencyCode: ResponsibleAgencyCode.BUYER,
        },
    },
    scheduledArticleDetails: {
        lineItem: {
            lineItemIdentifier: {
                itemNumberIdentification: {
                    itemIdentifier: src.articleNumberBuyer,
                    itemTypeIdentificationCode: ItemTypeIdentificationCode.BUYERS_ITEM_NUMBER,
                },
            },
            itemShortDescriptions: [
                {
                    itemDescription: src.itemShortDescription,
                    itemDescriptionCode: src.itemShortDescriptionCode,
                },
            ],
        },
        measurementUnitCode: src.measurementUnitCode,
        orderDocumentIdentifierBuyerAssigned: {
            referenceIdentifier: src.orderNumber,
        },
        schedulingData: src.schedulingData.map((it) => ({
            quantity: it.value,
            scheduledDeliveryTime: {
                scheduledDate: it.date,
                type: DeliveryType.DELIVERY,
            },
        })),
    },
    articleMasterData: {
        articleNumberSeller: src.articleNumberSeller,
        countryOfOrigin: src.countryOfOrigin,
        articleNetWeightInKg: src.articleNetWeightInKg,
        netExplosiveMassInKg: src.netExplosiveMassInKg,
        defaultShippingLocationId: src.defaultShippingLocationId,
        dangerousGoods: src.isDangerousGoods
            ? {
                  unDangerousGoodsNumber: src.unDangerousGoodsNumber,
                  description: src.dangerousGoodsDescription,
                  declarationException: src.dangerousGoodsDeclarationException,
              }
            : undefined,
    },
    creationDate: today(),
    partner: src.supplierData.partner,
});
