import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { useImage } from '../../../../../hooks/useImage';
import { packagingSlice } from '../../../../../reducers/shipments/Packaging.reducer';
import { PackagingStep, UpdatePackagingConfigurationType } from '../../../../../reducers/shipments/packaging.types';
import { getArticleToBePackaged } from '../../../../../selectors/packaging/Packaging.selector';
import { getSelectedShipmentId } from '../../../../../selectors/shipments/Shipments.selector';
import { FieldErrorType, FormErrors } from '../../../../common/form/types';
import { BoxFinder } from '../../../../packaging/BoxFinder';
import { UnpackagedArticle } from '../../packagingEditView/types';
import { INNER_PACKAGING_TEST_IDS } from './InnerPackaging';
import {
    InnerPackagingMaterialSelectionFormValues,
    formIds,
    formPropertyNamesPackagingMaterialSelectionStep,
} from './InnerPackagingFormConfig';

type InnerPackagingPackagingMaterialSelectionFormErrors = FormErrors<InnerPackagingMaterialSelectionFormValues>;

export const validateForm = ({
    packagingMaterial,
}: InnerPackagingMaterialSelectionFormValues): InnerPackagingPackagingMaterialSelectionFormErrors => {
    const validationResult: InnerPackagingPackagingMaterialSelectionFormErrors = { packagingMaterial: {} };

    if (packagingMaterial?.boxCode === undefined) {
        validationResult.packagingMaterial!.boxCode = FieldErrorType.REQUIRED;
    }

    if (packagingMaterial) {
        const { tareWeightInKg, ownership, isReusable, stackingFactor } = packagingMaterial;

        if (tareWeightInKg === undefined || String(tareWeightInKg) === '') {
            validationResult.packagingMaterial!.tareWeightInKg = FieldErrorType.REQUIRED;
        }
        if (ownership === undefined) {
            validationResult.packagingMaterial!.ownership = FieldErrorType.REQUIRED;
        }
        if (isReusable === undefined) {
            validationResult.packagingMaterial!.isReusable = FieldErrorType.REQUIRED;
        }
        if (stackingFactor === undefined || String(stackingFactor) === '') {
            validationResult.packagingMaterial!.stackingFactor = FieldErrorType.REQUIRED;
        }
    }

    return validationResult;
};

export const InnerPackagingPackagingMaterialSelection = () => {
    const loadItemToPackage = useAppSelector(getArticleToBePackaged);
    const dispatch = useAppDispatch();
    const selectedShipmentId = useAppSelector(getSelectedShipmentId);
    const packagingMaterialSelectionImage = useImage('packagingMaterialSelection');

    useEffect(() => {
        dispatch(packagingSlice.actions.updateFormIdInPackagingWizard(formIds.packagingMaterialSelection));
    }, [dispatch]);

    if (!loadItemToPackage) {
        return null;
    }

    const { articleNumberBuyer: articleNumber }: UnpackagedArticle = loadItemToPackage;

    const initialValues: InnerPackagingMaterialSelectionFormValues = {
        packagingMaterial: undefined,
    };

    const onSubmitHandler = (values: InnerPackagingMaterialSelectionFormValues) => {
        if (selectedShipmentId === undefined) {
            throw new Error('shipment must be selected before submitting form values. This should never happen');
        }

        dispatch(
            packagingSlice.actions.addPackagingMaterialForInnerPackaging({
                type: UpdatePackagingConfigurationType.INNER_PACKAGING,
                packagingMaterial: values.packagingMaterial!,
            }),
        );
        dispatch(packagingSlice.actions.setPackagingStep(PackagingStep.CONFIGURATION));
    };

    return (
        <Form<InnerPackagingMaterialSelectionFormValues>
            onSubmit={onSubmitHandler}
            initialValues={initialValues}
            validate={validateForm}
            render={({ handleSubmit }) => {
                return (
                    <div
                        data-testid={INNER_PACKAGING_TEST_IDS.materialSelection}
                        className={
                            'display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct ' +
                            'margin-left-20pct margin-right-20pct min-height-500'
                        }
                    >
                        <div className={'display-flex flex-column'}>
                            <div className={'text-size-18 text-center margin-bottom-25'}>
                                <FormattedMessage id={'webedi.packaging.innerPackaging.step1.title'} />
                            </div>
                            <div className={'counter color-highlight'}>
                                <div>
                                    <FormattedMessage
                                        id={'webedi.packaging.innerPackaging.step1.explanation'}
                                        values={{
                                            articleNumber,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                'border-style-solid border-color-light border-width-1 padding-left-5pct padding-right-5pct padding-20'
                            }
                        >
                            <div className={'text-center margin-bottom-20'}>
                                <img
                                    src={packagingMaterialSelectionImage}
                                    className={'width-70pct'}
                                    alt={'packaging_step_1'}
                                />
                            </div>
                            <form id={formIds.packagingMaterialSelection} onSubmit={handleSubmit}>
                                <BoxFinder
                                    id={formIds.packagingMaterial}
                                    name={formPropertyNamesPackagingMaterialSelectionStep.packagingMaterial}
                                    showLinkToVWMaterialHelp={true}
                                />
                            </form>
                        </div>
                    </div>
                );
            }}
        />
    );
};
