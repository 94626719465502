import ForbiddenState from '@rio-cloud/rio-uikit/lib/es/ForbiddenState';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/lib';
import { getTranslationKeyForProcessIndicator } from '../../../../../utils';
import { DeliveryScheduleWorkflow, ScheduledQuantity } from '../../../reducers/deliverySchedules/types';
import {
    getClassnameForRequestCondition,
    getTranslationKeyForRequestCondition,
} from '../../common/RequestConditionDisplay';
import { FormattedDateTimeOrPeriod } from '../../common/i18n/FormattedDateTimeOrPeriod';
import { DeliveryScheduleDetailsTableProps } from '../types';

export const DELIVERY_SCHEDULES_DETAILS_TABLE_ROW_TEST_ID = 'DELIVERY_SCHEDULES_DETAILS_TABLE_ROW_TEST_ID';
export const DELIVERY_SCHEDULES_DETAILS_TABLE_TEST_ID = 'DELIVERY_SCHEDULES_DETAILS_TABLE_TEST_ID';
export const DELIVERY_SCHEDULE_DETAILS_SCHEDULING_DATA_EMPTY_STATE_TEST_ID =
    'DELIVERY_SCHEDULE_DETAILS_SCHEDULING_DATA_EMPTY_STATE_TEST_ID';

export const DeliveryScheduleDetailsTable = (props: DeliveryScheduleDetailsTableProps) => {
    const intl = useIntl();
    const isWebSCMClassicWorkflow = props.workflow === DeliveryScheduleWorkflow.WEBSCM_CLASSIC;
    const renderRow = (scheduledQuantity: ScheduledQuantity, index: number) => {
        const translationKeyForRequestCondition = getTranslationKeyForRequestCondition(
            scheduledQuantity.requestCondition,
        );
        return (
            <tr key={`scheduledQuantity-${index}`} data-testid={DELIVERY_SCHEDULES_DETAILS_TABLE_ROW_TEST_ID}>
                <td className={'padding-left-25'}>
                    <FormattedDateTimeOrPeriod datetimeOrPeriod={scheduledQuantity.scheduledDateTime} />
                </td>
                <td>{scheduledQuantity.quantity}</td>
                {isWebSCMClassicWorkflow && <td>{scheduledQuantity.outstandingQuantity}</td>}
                <td className={'padding-right-25'}>
                    <FormattedMessage id={getTranslationKeyForProcessIndicator(scheduledQuantity.processIndicator)} />
                </td>
                <td>
                    <span className={getClassnameForRequestCondition(scheduledQuantity.requestCondition)}>
                        {translationKeyForRequestCondition ? (
                            <FormattedMessage id={translationKeyForRequestCondition} />
                        ) : null}
                    </span>
                </td>
            </tr>
        );
    };

    const EmptyState = (intl: IntlShape) => {
        return (
            <div
                className={'display-flex justify-content-center align-items-center width-100pct height-100pct'}
                data-testid={DELIVERY_SCHEDULE_DETAILS_SCHEDULING_DATA_EMPTY_STATE_TEST_ID}
            >
                <ForbiddenState
                    outerClassName={'border-none'}
                    headline={intl.formatMessage({
                        id: 'webedi.deliverySchedule.deliveryScheduleDetails.noSchedulingData',
                    })}
                />
            </div>
        );
    };

    const tableClassNames = ['table', 'table-column-overflow-hidden', 'table-sticky', 'table-head-filled'].join(' ');

    return (
        <div>
            <table className={tableClassNames} data-testid={DELIVERY_SCHEDULES_DETAILS_TABLE_TEST_ID}>
                <thead>
                    <tr>
                        <th className={'position-sticky top-0 padding-left-25'}>
                            <FormattedMessage id={'webedi.schedulingData.deliveryDate'} />
                        </th>
                        <th className={'position-sticky top-0'}>
                            <FormattedMessage id={'webedi.schedulingData.quantity'} />
                        </th>
                        {isWebSCMClassicWorkflow && (
                            <th className={'position-sticky top-0'}>
                                <FormattedMessage id={'webedi.schedulingData.outstandingQuantity'} />
                            </th>
                        )}
                        <th className={'position-sticky top-0 padding-right-25'}>
                            <FormattedMessage id={'webedi.schedulingData.typeOfCallOff'} />
                        </th>
                        <th className={'position-sticky top-0'}>
                            <FormattedMessage id={'webedi.schedulingData.requestCondition'} />
                        </th>
                    </tr>
                </thead>
                {!props.hasZeroDemand ? <tbody>{props.schedulingData.map(renderRow)}</tbody> : null}
            </table>
            {props.hasZeroDemand ? EmptyState(intl) : null}
        </div>
    );
};
