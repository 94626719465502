import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { getSelectedShipment } from '../../../../selectors/shipments/Shipments.selector';
import { Tooltip } from '../../../common/Tooltip';
import { TextInput } from '../../../common/form/TextInput';

export const TransportOrderNumberEdit = () => {
    const shipment = useAppSelector(getSelectedShipment);

    return (
        <div className={'display-flex-sm'}>
            <div className={'width-33pct-sm padding-right-15'}>
                <label>
                    <FormattedMessage id={'webedi.shipment.transportOrderNumber'} />
                    <Tooltip
                        text={<FormattedMessage id={'webedi.shipment.transportOrderNumber.tooltip'} />}
                        placement={'right'}
                        width={350}
                    >
                        <span className={'rioglyph rioglyph-info-sign text-color-info text-size-18'} />
                    </Tooltip>
                </label>
                <Field
                    name={'transportOrderNumber'}
                    component={TextInput}
                    className={'form-control'}
                    disabled={shipment?.transportOrderReference !== undefined}
                />
            </div>
        </div>
    );
};
