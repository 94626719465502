// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { fromEnum } from '../util';

export enum ApiItemDescriptionCode {
    PRODUCTION = 'PRODUCTION',
    SPAREPART = 'SPAREPART',
    PRODUCTION_AND_SPAREPART = 'PRODUCTION_AND_SPAREPART',
    TRY_OUT = 'TRY_OUT',
    PILOT = 'PILOT',
    ADDITIONAL_REQUIREMENT = 'ADDITIONAL_REQUIREMENT',
    INITIAL_SAMPLE = 'INITIAL_SAMPLE',
    SAMPLE = 'SAMPLE',
    OTHER = 'OTHER',
}

export const ItemShortDescriptionCodec = t.intersection([
    t.type({
        item_description_code: fromEnum<ApiItemDescriptionCode>('ApiItemDescriptionCode', ApiItemDescriptionCode),
    }),
    t.partial({
        item_description: t.string,
    }),
]);

export type ApiItemShortDescription = t.TypeOf<typeof ItemShortDescriptionCodec>;
