import { Filter } from '../../domain/filters.types';
import { TransportOrder } from '../../domain/transportOrder.types';

export const filterAndSortTransportOrders = (transportOrders: TransportOrder[], filters: Filter): TransportOrder[] =>
    [...transportOrders]

        .filter((transportOrder) => {
            const { freightForwarderName, transportConcept, placeOfDischarge, buyerName, plantNumber } = filters;

            return (
                (freightForwarderName ? transportOrder.freightForwarder.name === freightForwarderName : true) &&
                (transportConcept ? transportOrder.transportConcept === transportConcept : true) &&
                (placeOfDischarge ? transportOrder.unloading.placeOfDischarge === placeOfDischarge : true) &&
                ((buyerName ? transportOrder.buyer?.name === buyerName : true) ||
                    (buyerName ? transportOrder.buyer?.id === buyerName : true)) &&
                (plantNumber ? transportOrder.unloading.plantNumber === plantNumber : true)
            );
        })
        .sort((one, another) => {
            const oneFrom =
                one.freightForwarderResponse?.confirmedLoadingTimeWindow?.from ||
                one.loading.plannedLoadingTimeWindow.from;
            const anotherFrom =
                another.freightForwarderResponse?.confirmedLoadingTimeWindow?.from ||
                another.loading.plannedLoadingTimeWindow.from;

            return anotherFrom.localeCompare(oneFrom);
        });
