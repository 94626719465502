import { config } from '../../../../config';
import { ManagedFreightForwarder } from '../../reducers/transportation/types';
import { decodeJson, jsonOrReject, okOrReject } from '../apiUtils';
import { deleteRequest, getRequest, putRequest } from '../requests';
import { mapManagedFreightForwarderToApi, mapManagedFreightForwarders } from '../shared/freightForwarderMapper';
import { ManagedFreightForwardersCodec } from '../shared/transportation.types';

export const getManagedFreightForwarders = (dunsNumber: string): Promise<ManagedFreightForwarder[]> => {
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/carriers`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ManagedFreightForwardersCodec))
        .then(mapManagedFreightForwarders);
};

export const putManagedFreightForwarder = (managedFreightForwarder: ManagedFreightForwarder): Promise<void> => {
    const body = mapManagedFreightForwarderToApi(managedFreightForwarder);
    const dunsNumber = managedFreightForwarder.dunsNumberOwner;
    const id = managedFreightForwarder.id;
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/carriers/${id}`, putRequest(body)).then(okOrReject);
};

export const deleteManagedFreightForwarder = (dunsNumber: string, id: string): Promise<void> => {
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/carriers/${id}`, deleteRequest()).then(okOrReject);
};
