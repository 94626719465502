import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import { Controller, useFormContext } from 'react-hook-form';
import { ManualCreationOfDeliverySchedulesFormValues } from '../formConfig';
import { genericInputErrorTranslations } from '../validations';
import { ErrorHelpBlock } from './ErrorHelpBlock';
import { InputFormProps } from './types';

export type TextInputFormProps = InputFormProps<string> & {
    pattern?: RegExp;
    maxLength?: number;
    errorTranslations?: Map<string, string>;
    dataTestId?: string;
    rowClassName?: string;
    labelClassName?: string;
    formInputClassName?: string;
    validate?: (value: string | undefined) => string | undefined;
    maskConfig?: {
        mask: string | (string | RegExp)[];
        maskPlaceholder?: string | null;
    };
};

export const TextInputForm = (props: TextInputFormProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const errorClassName = 'has-error has-feedback';

    const error = errors[props.fieldName];
    return (
        <div className={props.rowClassName} data-testid={props.dataTestId}>
            <label className={props.labelClassName} htmlFor={props.fieldName}>
                {props.label}
                {props.required && '*'}
            </label>
            <div className={`${props.formInputClassName} ${error ? errorClassName : ''}`}>
                <Controller
                    rules={{
                        required: props.required,
                        pattern: props.pattern,
                        validate: props.validate,
                        maxLength: props.maxLength,
                    }}
                    name={props.fieldName}
                    control={control}
                    render={({ field }) => (
                        <ClearableInput
                            {...field}
                            id={props.fieldName}
                            mask={props.maskConfig?.mask}
                            maskPlaceholder={props.maskConfig?.maskPlaceholder}
                            alwaysShowMask={props.maskConfig?.mask !== undefined}
                        />
                    )}
                />
                <ErrorHelpBlock translations={props.errorTranslations || genericInputErrorTranslations} error={error} />
            </div>
        </div>
    );
};
