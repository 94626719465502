import { Address } from '../reducers/shipments/types';

export interface PlaceOfDischargeMasterData {
    readonly id: string;
    readonly plantCode: string;
    readonly plantName?: string;
    readonly placeOfDischargeCode: string;
    readonly brand: Brand;
    readonly address?: Address;
}

export enum Brand {
    PORSCHE = 'PORSCHE',
    LAMBORGHINI = 'LAMBORGHINI',
    BENTLEY = 'BENTLEY',
    MAN = 'MAN',
    VOLKSWAGEN_STANDARD = 'VOLKSWAGEN_STANDARD',
}
