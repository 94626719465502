import { createSelector } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { State } from '../../../../configuration/setup/store';
import { neverReachedFor } from '../../../../utils';
import { HandlingUnitCategory } from '../../components/common/PackagingCommon';
import {
    ArticleInPackagingTemplateConfig,
    HandlingUnitInPackagingTemplateConfig,
    HandlingUnitInPackagingTemplateConfigForHomogeneousSteps,
    HandlingUnitInPackagingTemplateConfigForInnerSteps,
    PackageableInPackagingTemplateConfig,
} from '../../components/deliverySchedules/types';
import {
    containLisonTemplate,
    hasMaxNumberOfHomogeneousTemplates,
} from '../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    CumulativeQuantitySentOffset,
    DeliverySchedule,
    DeliverySchedulesFilterParams,
    DeliverySchedulesPaginationQuery,
    DeliverySchedulesQuery,
    HomogeneousPackagingStep,
    InnerPackagingStep,
    MetadataEntry,
    PackagingStep,
    PackagingStepConfiguration,
    PackagingStepType,
    PackagingTemplate,
    PackagingTemplateDictionary,
    PackagingTemplateType,
    SelectedMetadataEntryId,
    TemplateWizardState,
    isPackagingTemplate,
} from '../../reducers/deliverySchedules/types';
import { Dimensions } from '../../reducers/shipments/types';

const getDeliveryScheduleSlice = (state: State) => state.webEdi.deliverySchedules;

export const getDeliverySchedules = (a: State): DeliverySchedule[] => getDeliveryScheduleSlice(a).deliverySchedules;

export const getTotalCountOfMatchedDeliverySchedules = (state: State): number =>
    getDeliveryScheduleSlice(state).totalCountOfMatchedDeliverySchedules;

export const getDeliverySchedulesQuery = (state: State): DeliverySchedulesQuery =>
    getDeliveryScheduleSlice(state).deliverySchedulesQuery;

export const getDeliverySchedulesPaginationQuery = createSelector(
    [getDeliverySchedulesQuery],
    (query): DeliverySchedulesPaginationQuery => {
        const { q, ...rest } = query.params;
        return { ...rest, articleNumberSearchString: q };
    },
);

export const getDeliverySchedulesQueryParams = (state: State) => getDeliverySchedulesQuery(state).params;

export const getDeliverySchedulesActiveFilters = createSelector(
    [getDeliverySchedulesQuery],
    (query): DeliverySchedulesFilterParams => {
        const params = query.params;
        return {
            deliveryDateFrom: params.deliveryDateFrom,
            manufacturingCompany: params.manufacturingCompany,
            orderNumber: params.orderNumber,
            plantNumber: params.plantNumber,
            storagePlace: params.storagePlace,
            supplierIdentifier: params.supplierIdentifier,
            unloadingPoint: params.unloadingPoint,
            excludeSoftDeleted: params.excludeSoftDeleted,
        };
    },
);

export const getActiveSortParameters = (state: State) =>
    state.webEdi.deliverySchedules.deliverySchedulesQuery.params.sort;

export const getSelectedDeliveryScheduleId = (state: State): string | undefined =>
    state.webEdi.deliverySchedules.selectedDeliveryScheduleId;

export const getDeliverySchedulesFilterValues = (state: State): { [key: string]: string[] } =>
    state.webEdi.deliverySchedules.filterValues;

export const getSelectedDeliverySchedule = (state: State): DeliverySchedule | undefined => {
    const selectedDeliveryScheduleId = getSelectedDeliveryScheduleId(state);
    return state.webEdi.deliverySchedules.deliverySchedules.find(
        (delSchedule) => delSchedule.id === selectedDeliveryScheduleId,
    );
};

export const isLoadingDeliverySchedules = (state: State): boolean =>
    state.webEdi.deliverySchedules.isLoadingDeliverySchedules;

export const isLoadingLisonPackagingTemplate = (state: State): boolean =>
    state.webEdi.deliverySchedules.isLoadingLisonPackagingTemplate;

export const getSelectedDeliverySchedulePackagingTemplates = (state: State): PackagingTemplateDictionary =>
    state.webEdi.deliverySchedules.selectedDeliverySchedulePackagingTemplates;

export const getSelectedMetadataEntryId = (state: State): string | undefined =>
    state.webEdi.deliverySchedules.selectedMetadataEntryId;

export const getShowDeliveryScheduleDialog = (state: State): boolean =>
    state.webEdi.deliverySchedules.showDeliveryScheduleDialog;

export const hasMaxNumberOfHomogeneousPackagingTemplates = createSelector(
    [getSelectedDeliverySchedulePackagingTemplates],
    (packagingTemplates): boolean => {
        return hasMaxNumberOfHomogeneousTemplates(packagingTemplates);
    },
);

export const hasLisonTemplate = createSelector(
    [getSelectedDeliverySchedulePackagingTemplates],
    (packagingTemplates): boolean => {
        return containLisonTemplate(packagingTemplates);
    },
);

export const getSelectedMetadataEntry = createSelector(
    [getSelectedDeliverySchedule, getSelectedDeliverySchedulePackagingTemplates, getSelectedMetadataEntryId],
    (deliverySchedule, packagingTemplates, metadataEntryId): MetadataEntry | undefined => {
        if (metadataEntryId === undefined) {
            return undefined;
        }
        if (metadataEntryId === SelectedMetadataEntryId.ARTICLE_MASTER_DATA) {
            if (deliverySchedule === undefined) {
                throw new Error('No selected delivery schedule found. This should never happen.');
            }
            return deliverySchedule?.articleMasterData;
        }
        if (metadataEntryId === SelectedMetadataEntryId.MISSING_LISON) {
            return { type: PackagingTemplateType.MISSING_LISON };
        }
        const selectedPackagingTemplate = packagingTemplates[metadataEntryId];
        if (selectedPackagingTemplate === undefined) {
            throw new Error('Selected meta data entry does not exist. This should never happen.');
        }
        return selectedPackagingTemplate;
    },
);

export const getSelectedPackagingTemplate = createSelector(
    getSelectedMetadataEntry,
    (selectedMetadataEntry): PackagingTemplate => {
        if (selectedMetadataEntry === undefined || !isPackagingTemplate(selectedMetadataEntry)) {
            throw new Error('Selected metadata entry is not a valid packaging template. This should never happen.');
        }
        return selectedMetadataEntry;
    },
);

export const getPackagingTemplateNameFromForm = (state: State): string | undefined =>
    state.webEdi.deliverySchedules.form.templateName;

export const getPackagingTemplateDimensionsFromForm = (state: State): Dimensions | undefined =>
    state.webEdi.deliverySchedules.form.templateDimensions;

export const isMetadataViewContentDirty = (state: State): boolean => {
    const metadataFormState = state.webEdi.deliverySchedules.form;
    return (
        metadataFormState.templateName !== undefined ||
        metadataFormState.templateDimensions !== undefined ||
        metadataFormState.templateStepsAdded ||
        metadataFormState.isMetadataViewContentDirty
    );
};

export const getActionToBeConfirmed = (state: State): Action | undefined =>
    state.webEdi.deliverySchedules.form.actionToBeConfirmed;

export const getTemplateWizardState = (state: State): TemplateWizardState | undefined => {
    return state.webEdi.deliverySchedules.form.templateWizardState;
};

export const getTemplateStepConfiguration = (state: State): PackagingStepConfiguration | undefined => {
    return state.webEdi.deliverySchedules.form.templateStepConfiguration;
};

export const getPackagingTemplateHandlingUnitConfigs = createSelector(
    [getSelectedMetadataEntry],
    (selectedMetadataEntry): HandlingUnitInPackagingTemplateConfig[] | undefined => {
        if (
            selectedMetadataEntry === undefined ||
            ![PackagingTemplateType.HOMOGENEOUS, PackagingTemplateType.LISON].includes(
                (selectedMetadataEntry as PackagingTemplate).type,
            )
        ) {
            return undefined;
        }

        const packagingTemplateSteps: PackagingStep[] = (selectedMetadataEntry as PackagingTemplate).steps;

        type StepToHandlingUnitCategory = {
            [Key in PackagingStepType]: HandlingUnitCategory;
        };
        const mapStepToHandlingUnitCategory: StepToHandlingUnitCategory = {
            [PackagingStepType.INNER]: HandlingUnitCategory.PARTS_CONTAINER,
            [PackagingStepType.HOMOGENEOUS]: HandlingUnitCategory.LOAD_CARRIER,
            [PackagingStepType.HETEROGENEOUS]: HandlingUnitCategory.LOAD_CARRIER,
        };

        const getStepById = (id: string): PackagingStep => {
            const foundStep = packagingTemplateSteps.find((step) => step.id === id);
            if (foundStep === undefined) {
                throw new Error(`Could not find step by id ${id}`);
            }
            return foundStep;
        };

        const mapStepToPackageableInPackagingTemplateConfig = (
            step: PackagingStep,
        ): PackageableInPackagingTemplateConfig[] => {
            if (step.type === PackagingStepType.INNER) {
                return [{ articleNumber: step.articleNumberBuyer } as ArticleInPackagingTemplateConfig];
            } else {
                return step.prerequisites.flatMap((prerequisiteId) =>
                    mapStepToHandlingUnitConfig(getStepById(prerequisiteId)),
                );
            }
        };

        const getNumberPerParentPackaging = (step: PackagingStep) => {
            const parentStep = packagingTemplateSteps.find((templateStep) =>
                templateStep.prerequisites.includes(step.id),
            );
            if (parentStep === undefined) {
                return 1;
            }

            switch (parentStep.type) {
                case PackagingStepType.HOMOGENEOUS:
                    return parentStep.numberOfHandlingUnitsPerLayer * parentStep.numberOfLayersPerHandlingUnit;
                case PackagingStepType.HETEROGENEOUS:
                    throw new Error('Not implemented yet');
                case PackagingStepType.INNER:
                    throw new Error(
                        'This should never happen, as InnerPackagingSteps could not be parents of other steps.',
                    );
                default:
                    return neverReachedFor(parentStep);
            }
        };

        const mapStepToHandlingUnitConfig = (step: PackagingStep): HandlingUnitInPackagingTemplateConfig => {
            const commonHandlingUnitInPackagingTemplateConfig = {
                typeOfHandlingUnit: step.typeOfHandlingUnit,
                type: step.type,
                description: step.descriptionOfHandlingUnit,
                category: mapStepToHandlingUnitCategory[step.type],
                contents: mapStepToPackageableInPackagingTemplateConfig(step),
                auxiliaryPackaging: step.auxiliaryPackaging,
                isReusable: step.handlingUnit.isReusable,
                ownership: step.handlingUnit.ownership,
                stackingFactor: step.handlingUnit.stackingFactor,
                tareWeightInKg: step.handlingUnit.tareWeightInKg,
            };
            switch (step.type) {
                case PackagingStepType.INNER:
                    return {
                        ...commonHandlingUnitInPackagingTemplateConfig,
                        quantity: (step as InnerPackagingStep).quantity,
                        numberOfContainers: getNumberPerParentPackaging(step),
                    } as HandlingUnitInPackagingTemplateConfigForInnerSteps;
                case PackagingStepType.HOMOGENEOUS:
                    const homogeneousPackagingStep = step as HomogeneousPackagingStep;
                    return {
                        ...commonHandlingUnitInPackagingTemplateConfig,
                        numberOfHandlingUnitsPerLayer: homogeneousPackagingStep?.numberOfHandlingUnitsPerLayer,
                        numberOfLayersPerHandlingUnit: homogeneousPackagingStep?.numberOfLayersPerHandlingUnit,
                        numberOfContainers: getNumberPerParentPackaging(step),
                    } as HandlingUnitInPackagingTemplateConfigForHomogeneousSteps;
                default:
                    return neverReachedFor(step.type as never);
            }
        };

        const prerequisiteIds: string[] = packagingTemplateSteps.flatMap((step) => step.prerequisites);

        return packagingTemplateSteps.flatMap((step) => {
            if (!prerequisiteIds.includes(step.id)) {
                return mapStepToHandlingUnitConfig(step);
            }
            return [];
        });
    },
);

export const getCumulativeQuantitySentOffsets = (state: State): CumulativeQuantitySentOffset[] =>
    state.webEdi.deliverySchedules.selectedDeliveryScheduleCumulativeQuantitySentOffsets;

export const getisLoadingCumulativeQuantitySentOffsets = (state: State): boolean =>
    state.webEdi.deliverySchedules.isLoadingCumulativeQuantitySentOffsets;

export const getIsPostingCumulativeQuantitySentOffset = (state: State): boolean =>
    state.webEdi.deliverySchedules.isPostingCumulativeQuantitySentOffset;

export const getIsSavingArticleMetadata = (state: State): boolean =>
    state.webEdi.deliverySchedules.isSavingArticleMetadata;
