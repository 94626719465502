import { neverReachedFor } from '../../../../../../../utils';
import { PackagingStep } from '../../../../../reducers/shipments/packaging.types';
import { AuxiliaryPackaging } from '../AuxiliaryPackaging/AuxiliaryPackaging';
import { InnerPackagingConfiguration } from './InnerPackagingConfiguration';
import { InnerPackagingPackagingMaterialSelection } from './InnerPackagingPackagingMaterialSelection';

export const INNER_PACKAGING_TEST_IDS = {
    materialSelection: 'PACKAGING_MATERIAL_SELECTION_TEST_ID',
    configuration: 'PACKAGING_CONFIGURATION_TEST_ID',
    numberOfUnpackagedArticles: 'NUMBER_OF_UNPACKAGED_ARTICLES_TEST_ID',
    numberOfPackagedArticles: 'NUMBER_OF_PACKAGED_ARTICLES_TEST_ID',
    footer: 'INNER_PACKAGING_DIALOG_FOOTER_TEST_ID',
};

export const InnerPackaging = (props: { step: PackagingStep }) => {
    switch (props.step) {
        case PackagingStep.PACKAGING_MATERIAL_SELECTION:
            return <InnerPackagingPackagingMaterialSelection />;
        case PackagingStep.CONFIGURATION:
            return <InnerPackagingConfiguration />;
        case PackagingStep.AUXILIARY_CONFIGURATION:
            return <AuxiliaryPackaging />;
        case PackagingStep.GROUPING_SELECTION:
            return null;
        default:
            return neverReachedFor(props.step);
    }
};
