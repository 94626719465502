import { skipToken } from '@reduxjs/toolkit/query';
import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import { MenuItemProps } from '@rio-cloud/rio-uikit/MenuItem';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { saveAs } from 'file-saver';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { State } from '../../../../../configuration/setup/store';
import { Dispatch as AppDispatch } from '../../../../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { formatDateOrDateTime } from '../../../../../dateUtils';
import { useCourierOrdersQuery } from '../../../api/shippers/shippersApi';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { FreightForwarderResponse, HandlingUnit, TransportOrder } from '../../../domain/transportOrder.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import { filterAndSortTransportOrders } from '../../../reducers/transportOrders/TransportOrders.util';
import {
    getSelectedFilters,
    getSelectedLoadingFromFilter,
    getSelectedLoadingToFilter,
    getSelectedTransportOrders,
    getShowDetailsForTransportOrder,
    isTransportOrderSelected,
} from '../../../selectors/transportOrders/transportOrders.selector';
import { Tooltip } from '../../common/Tooltip';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';
import { OuterPackaging } from '../common/OuterPackaging';
import { extractLicensePlate } from '../common/dataExtractors';
import { DownloadShipmentDocumentsDialog } from './DownloadShipmentDocumentsDialog';
import { ExportShippingNoticesDialog } from './ExportShippingNoticesDialog';
import { MoreDataIndicator } from './MoreDataIndicator';
import { TimeWindowDisplay } from './TimeWindowDisplay';
import { mergeBase64Documents } from './mergeDocuments';

export const TRANSPORT_ORDER_CONCEPT_ICON_CELL = 'TRANSPORT_ORDER_CONCEPT_ICON_CELL_TEST_ID';
export const LICENSE_PLATE_CELL = 'LICENSE_PLATE_CELL_TEST_ID';
export const HANDLING_UNITS_CELL = 'HANDLING_UNITS_CELL_TEST_ID';
export const BUYER_CELL = 'BUYER_CELL_TEST_ID';
export const CONFIRMATION_STATUS_CELL = 'CONFIRMATION_STATUS_CELL_TEST_ID';
export const SHIPMENT_EXPORTED_STATUS_CELL = 'SHIPMENT_EXPORTED_CELL_TEST_ID';
export const TRANSPORT_ORDER_ROW = 'TRANSPORT_ORDER_ROW_TEST_ID';

interface TransportOrdersTableProps {
    transportOrders: TransportOrder[];
}

const MAX_TRANSPORT_ORDERS_TO_SHOW = 1000;
export const TransportOrdersTable = (props: TransportOrdersTableProps) => {
    const { transportOrders } = props;

    const selectedLoadingTimeFrom = useAppSelector(getSelectedLoadingFromFilter);
    const selectedLoadingTimeTo = useAppSelector(getSelectedLoadingToFilter);
    const selectedFilters = useAppSelector(getSelectedFilters);
    const selectedTransportOrderId = useAppSelector(getShowDetailsForTransportOrder);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [showDownloadShipmentDocumentsDialog, setShowDownloadShipmentDocumentsDialog] = useState(false);
    const [showExportASNDialog, setShowExportASNDialog] = useState(false);

    const onSelectRow = (transportOrderId: string, isActive: boolean) => () =>
        dispatch(transportOrdersSlice.actions.setShowDetailsForTransportOrder(isActive ? undefined : transportOrderId));

    const transportOrderMatchingFilterCriteria = filterAndSortTransportOrders(transportOrders, selectedFilters);

    if (transportOrderMatchingFilterCriteria.length === 0) {
        const from = formatDateOrDateTime(selectedLoadingTimeFrom, intl);
        const to = formatDateOrDateTime(selectedLoadingTimeTo, intl);
        return (
            <NotFoundState headline={<FormattedMessage id={'webedi.transportOrders.overview.table.noData'} />}>
                <FormattedMessage
                    id={'webedi.transportOrders.overview.table.noData.details'}
                    values={{ firstDate: from, secondDate: to }}
                />
            </NotFoundState>
        );
    } else {
        const transportOrdersToShow = transportOrderMatchingFilterCriteria.slice(0, MAX_TRANSPORT_ORDERS_TO_SHOW);
        return (
            <div data-testid={'TRANSPORT_TABLE'}>
                <table
                    className={
                        'table table-column-overflow-hidden table-bordered table-sticky table-layout-fixed table-hover table-head-filled margin-bottom-20'
                    }
                >
                    <colgroup>
                        <col />
                        <col className='width-40' />
                        <col className='width-150' />
                        <col className='width-15pct' />
                        <col className='width-150' />
                        <col className='width-150' />
                        <col className='width-70-md' />
                        <col />
                        <col className='width-100-md ellipsis-1' />
                        <col />
                        <col className='width-100-md ellipsis-1' />
                        <col className='width-150-md ellipsis-1' />
                        <col className='width-100-md' />
                        <col className='width-80-md' />
                        <col className='width-60' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className='table-checkbox'>
                                <BatchButton
                                    onDownloadShipmentDocuments={() => setShowDownloadShipmentDocumentsDialog(true)}
                                    onExportASN={() => setShowExportASNDialog(true)}
                                />
                            </th>
                            <th>{/* Empty column for icon */}</th>
                            <th>
                                <FormattedMessage
                                    id={'webedi.transportOrders.overview.table.header.transportOrderNumber'}
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'webedi.transportOrders.overview.table.header.freightForwarder'}
                                />
                            </th>
                            <th>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.licensePlate'} />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'webedi.transportOrders.overview.table.header.loadingTimeWindow'}
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'webedi.transportOrders.overview.table.header.transportConcept'}
                                />
                            </th>
                            <th>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.handlingUnits'} />
                            </th>
                            <th>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.placeOfLoading'} />
                            </th>
                            <th>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.buyer'} />
                            </th>
                            <th className={'text-center'}>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.plantNumber'} />
                            </th>
                            <th className={'text-center'}>
                                <FormattedMessage
                                    id={'webedi.transportOrders.overview.table.header.placeOfDischarge'}
                                />
                            </th>
                            <th className={'text-center'}>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.confirmed'} />
                            </th>
                            <th className={'text-center'}>
                                <FormattedMessage id={'webedi.transportOrders.overview.table.header.asnExported'} />
                            </th>
                            <th className={'table-action'}>{/* Empty column for for 3-dot menu */}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transportOrdersToShow.map((transportOrder, index) => {
                            const isActive = selectedTransportOrderId === transportOrder.id;
                            return (
                                <TransportOrderRow
                                    transportOrder={transportOrder}
                                    isActive={isActive}
                                    onClick={onSelectRow(transportOrder.id, isActive)}
                                    index={index}
                                    key={transportOrder.id}
                                    setShowDownloadShipmentDocumentsDialog={setShowDownloadShipmentDocumentsDialog}
                                    setShowExportASNDialog={setShowExportASNDialog}
                                />
                            );
                        })}
                    </tbody>
                </table>
                <MoreDataIndicator
                    shown={transportOrdersToShow.length}
                    total={transportOrderMatchingFilterCriteria.length}
                    messageOnMore={
                        <FormattedMessage id={'webedi.transportOrders.overview.table.footer.messageOnMore'} />
                    }
                />
                <DownloadShipmentDocumentsDialog
                    show={showDownloadShipmentDocumentsDialog}
                    onClose={() => setShowDownloadShipmentDocumentsDialog(false)}
                />
                <ExportShippingNoticesDialog
                    show={showExportASNDialog}
                    onClose={() => setShowExportASNDialog(false)}
                    transportOrders={props.transportOrders}
                />
            </div>
        );
    }
};

interface BatchButtonProps {
    onDownloadShipmentDocuments: () => void;
    onExportASN: () => void;
}

const BatchButton = (props: BatchButtonProps) => {
    const selectedTransportOrders = useAppSelector(getSelectedTransportOrders);
    const dispatch = useAppDispatch();
    const duns = useDunsNumberFromPath();

    const kepTransportSelectedOrders = selectedTransportOrders.filter((to) => to.transportConcept === 'KEP');

    const totalKepSelectedTransportOrders = kepTransportSelectedOrders.length;

    const courierOrders = useCourierOrdersQuery(
        duns && kepTransportSelectedOrders.length > 0
            ? { dunsNumber: duns, shipmentIds: kepTransportSelectedOrders.map((to) => to.shipmentId) }
            : skipToken,
    );

    const onDeselectAll = () => dispatch(transportOrdersSlice.actions.clearSelectedTransportOrders());
    const handleMultiKepLabelDownloads = async () => {
        if (courierOrders.data !== null && courierOrders.data !== undefined) {
            const labels = await mergeBase64Documents(
                courierOrders.data!.map((courierOrder) => courierOrder.printableLabel),
            );
            saveAs(labels, `kep_labels.pdf`);
        }
    };

    const dropDownButtonItems = [
        {
            value: (
                <>
                    <span className='rioglyph rioglyph-download margin-right-5 text-size-18' />
                    <FormattedMessage
                        id={'webedi.transportOrders.overview.table.batch.menu.generateShippingDocuments'}
                        values={{ number: selectedTransportOrders.length }}
                    />
                </>
            ),
            onSelect: () => props.onDownloadShipmentDocuments(),
        },
        {
            value: (
                <>
                    <span className='rioglyph rioglyph-download margin-right-5 text-size-18' />
                    <FormattedMessage
                        id={'webedi.transportOrders.overview.table.batch.menu.downloadKepLabels'}
                        values={{ number: totalKepSelectedTransportOrders }}
                    />
                </>
            ),
            disabled: totalKepSelectedTransportOrders === 0,
            onSelect: () => handleMultiKepLabelDownloads(),
        },
        {
            value: (
                <>
                    <span className='rioglyph rioglyph-arrow-right margin-right-5 text-size-18' />
                    <FormattedMessage
                        id={'webedi.transportOrders.overview.table.batch.menu.exportASN'}
                        values={{ number: selectedTransportOrders.length }}
                    />
                </>
            ),
            onSelect: () => props.onExportASN(),
        },
        { divider: true },
        {
            value: <FormattedMessage id={'webedi.transportOrders.overview.table.batch.menu.deselectAll'} />,
            onSelect: onDeselectAll,
        },
    ];
    return (
        <ButtonDropdown
            title={<span className='rioglyph rioglyph rioglyph-checkboxes' />}
            className={'btn-s'}
            bsStyle={'link' as BUTTON_STYLE}
            iconOnly
            disabled={selectedTransportOrders.length === 0}
            items={dropDownButtonItems}
        />
    );
};

interface TransportOrderRowProps {
    transportOrder: TransportOrder;
    isActive: boolean;
    onClick: () => void;
    index: number;
    setShowDownloadShipmentDocumentsDialog: Dispatch<SetStateAction<boolean>>;
    setShowExportASNDialog: Dispatch<SetStateAction<boolean>>;
}

const TransportOrderRow = (props: TransportOrderRowProps) => {
    const { transportOrder, isActive, onClick, index } = props;
    const isChecked = useAppSelector((state: State) => isTransportOrderSelected(state, transportOrder.id));
    const duns = useDunsNumberFromPath();
    const courierOrders = useCourierOrdersQuery(
        duns && transportOrder.shipment ? { dunsNumber: duns, shipmentIds: [transportOrder.shipment.id] } : skipToken,
    );
    const selectedTransportOrders = useAppSelector(getSelectedTransportOrders);
    const dispatch = useAppDispatch();
    const toggleChecked = () => {
        if (isChecked) {
            dispatch(transportOrdersSlice.actions.removeSelectedTransportOrder(transportOrder.id));
        } else {
            dispatch(
                transportOrdersSlice.actions.addSelectedTransportOrder({
                    shipmentId: transportOrder.shipment!.id,
                    shipmentNumber: transportOrder.shipment!.shipmentNumber,
                    transportOrderId: transportOrder.id,
                    transportOrderNumber: transportOrder.transportOrderNumber,
                    transportConcept: transportOrder.transportConcept,
                    index,
                }),
            );
        }
    };

    const handleKepLabelDownload = () => {
        const val = courierOrders.data?.[0]?.printableLabel;
        if (val) {
            const anchorElement = document.createElement('a');

            anchorElement.href = `data:application/pdf;base64,${val}`;
            anchorElement.download = `${transportOrder?.shipment?.id}.pdf`;
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
        }
    };

    const exported = transportOrder.shipment?.exportedAt !== undefined;
    const activeRowClasses = 'active bg-highlight-lightest border-color-primary';
    const rowClassName = `cursor-pointer${exported ? ' bg-lightest' : ''} ${isActive ? activeRowClasses : ''}`;
    const invalidShipment = transportOrder.shipment?.id === undefined;
    const isExportable = !invalidShipment && !exported;
    const checkBoxDisabled = invalidShipment || exported || (!isChecked && selectedTransportOrders.length >= 5);

    const isKEP = transportOrder.transportConcept === 'KEP';

    const dropdownMenuButtons = [
        RowMenuItem(
            {
                transportOrder,
                onClickSelectMenuItem: () => props.setShowDownloadShipmentDocumentsDialog(true),
                index,
                isDisabled: invalidShipment,
                translationKey: 'webedi.transportOrders.overview.table.row.menu.generateShippingDocuments',
                iconName: 'rioglyph-download',
            },
            dispatch,
        ),
        RowMenuItem(
            {
                transportOrder,
                onClickSelectMenuItem: () => props.setShowExportASNDialog(true),
                index,
                isDisabled: !isExportable,
                translationKey: 'webedi.transportOrders.overview.table.row.menu.exportASN',
                iconName: 'rioglyph-arrow-right',
            },
            dispatch,
        ),
    ];

    const buttonOptions = isKEP
        ? [
              ...dropdownMenuButtons,
              RowMenuItem(
                  {
                      transportOrder,
                      onClickSelectMenuItem: () => {
                          handleKepLabelDownload();
                      },
                      index,
                      isDisabled: false,
                      translationKey: 'webedi.transportOrders.overview.table.row.menu.downloadKepLabel',
                      iconName: 'rioglyph-download',
                  },
                  dispatch,
              ),
          ]
        : dropdownMenuButtons;

    return (
        <tr className={rowClassName} key={transportOrder.id} data-testid={TRANSPORT_ORDER_ROW} onClick={onClick}>
            <td className={'table-checkbox'}>
                <Checkbox checked={isChecked} onClick={toggleChecked} disabled={checkBoxDisabled} />
            </td>
            <td className={'padding-left-20'}>{transportConceptIcon(transportOrder.transportConcept)}</td>
            <td className={'text-color-primary'}>
                <strong>{transportOrder.transportOrderNumber}</strong>
            </td>
            <td className='ellipsis-1'>
                <span>{transportOrder.freightForwarder.name}</span>
            </td>
            <td>{licensePlate(transportOrder)}</td>
            <td>{timeWindow(transportOrder)}</td>
            <td>
                <FormattedMessage id={`webedi.transportConcept.${transportOrder.transportConcept}`} />
            </td>
            <td>{handlingUnits(transportOrder.shipment?.content?.handlingUnits)}</td>
            <td>{transportOrder.loading.placeOfLoading}</td>
            <td>{buyerName(transportOrder.buyer)}</td>
            <td className={'text-center'}>{transportOrder.unloading.plantNumber}</td>
            <td className={'text-center'}>{transportOrder.unloading.placeOfDischarge}</td>
            <td className={'text-size-20 text-center'}>
                {freightForwarderResponseIcon(transportOrder.freightForwarderResponse)}
            </td>
            <td className={'text-size-20 text-center'}>{shipmentExportedIcon(transportOrder.shipment?.exportedAt)}</td>
            <td className={'table-action'} onClick={(e) => e.stopPropagation()}>
                <ButtonDropdown
                    aria-label={'rowmenu'}
                    toggleClassName={'dropdown-toggle btn btn-link btn-icon-only'}
                    title={<span className='rioglyph rioglyph-option-vertical' />}
                    bsStyle={'link' as BUTTON_STYLE}
                    dropdownClassName={'width-350'}
                    iconOnly
                    items={buttonOptions}
                />
            </td>
        </tr>
    );
};

const transportConceptIcon = (transportConcept: TransportConcept) => (
    <span
        data-testid={TRANSPORT_ORDER_CONCEPT_ICON_CELL}
        className={`rioglyph text-size-20 text-color-primary 
        ${transportConcept === 'KEP' ? 'rioglyph-van' : 'rioglyph-truck'}`}
    />
);

const licensePlate = (transportOrder: TransportOrder) => {
    const value = extractLicensePlate(transportOrder.shipment?.meansOfTransport);
    return (
        <div data-testid={LICENSE_PLATE_CELL}>
            {value !== undefined ? <div className={'text-uppercase'}>{value}</div> : <NotAvailableIndicator />}
        </div>
    );
};

const timeWindow = (transportOrder: TransportOrder) => {
    const plannedTimeWindow =
        transportOrder.freightForwarderResponse?.confirmedLoadingTimeWindow ??
        transportOrder.loading.plannedLoadingTimeWindow;

    return <TimeWindowDisplay plannedLoadingTimeWindow={plannedTimeWindow} lineBreakBetweenDateAndTime={true} />;
};

const handlingUnits = (handlingUnitList: HandlingUnit[] | undefined) => {
    return (
        <div data-testid={HANDLING_UNITS_CELL} className={'display-flex flex-1-0'}>
            {handlingUnitList ? (
                <Tooltip
                    text={
                        <div>
                            {handlingUnitList.map(({ type, amount }, index) => (
                                <div key={`${index}-${type}-handlingUnit-tooltip`}>
                                    <OuterPackaging amount={amount} type={type} key={`${index}-${type}`} />
                                    {index < handlingUnitList.length - 1 ? ',' : null}
                                </div>
                            ))}
                        </div>
                    }
                    placement={'bottom'}
                    textAlignment={'left'}
                >
                    {
                        <div className={'ellipsis-1'}>
                            {handlingUnitList.slice(0, 2).map(({ type, amount }, index) => (
                                <div className={'ellipsis-1'} key={`${index}-${type}`}>
                                    <OuterPackaging amount={amount} type={type} />
                                    {index < handlingUnitList.length - 1 ? ',' : null}
                                    {index === 1 && handlingUnitList.length > 2 ? <> &hellip;</> : null}
                                </div>
                            ))}
                        </div>
                    }
                </Tooltip>
            ) : (
                <NotAvailableIndicator />
            )}
        </div>
    );
};

const buyerName = (buyer: { id: string; name?: string } | undefined) => {
    return (
        <div data-testid={BUYER_CELL}>
            {buyer !== undefined ? buyer.name ? buyer.name : buyer.id : <NotAvailableIndicator />}
        </div>
    );
};

const freightForwarderResponseIcon = (freightForwarderResponse: FreightForwarderResponse | undefined) => {
    const isConfirmedTransportOrder = freightForwarderResponse?.type === 'Confirmation';
    const iconColor = isConfirmedTransportOrder ? 'text-color-success' : 'text-color-light';
    const tooltipMessageId = isConfirmedTransportOrder
        ? 'webedi.transportOrders.overview.table.tooltip.confirmed'
        : 'webedi.transportOrders.overview.table.tooltip.unconfirmed';
    return (
        <Tooltip text={<FormattedMessage id={tooltipMessageId} />} placement={'bottom'}>
            <span data-testid={CONFIRMATION_STATUS_CELL} className={`rioglyph rioglyph-ok-sign ${iconColor}`} />
        </Tooltip>
    );
};

const shipmentExportedIcon = (exportedAt: string | undefined) => {
    const exported = exportedAt !== undefined;
    const tooltipMessage = exported ? (
        <FormattedMessage
            id={'webedi.transportOrders.overview.table.tooltip.exported'}
            values={{ exportedAt: <FormattedDateOrDateTime date={exportedAt} /> }}
        />
    ) : (
        <FormattedMessage id={'webedi.transportOrders.overview.table.tooltip.notExported'} />
    );
    const iconColor = exported ? 'text-color-primary' : 'text-color-light';
    return (
        <Tooltip text={tooltipMessage} placement={'bottom'}>
            <span
                data-testid={SHIPMENT_EXPORTED_STATUS_CELL}
                className={`rioglyph rioglyph-arrow-right ${iconColor}`}
            />
        </Tooltip>
    );
};

const NotAvailableIndicator = () => (
    <div className={'text-color-light text-uppercase text-size-12'}>
        <FormattedMessage id={'webedi.transportOrders.overview.table.notAvailable'} />
    </div>
);

interface PrintDialogueMenuItemProps {
    transportOrder: TransportOrder;
    onClickSelectMenuItem: () => void;
    index: number;
    isDisabled: boolean;
    translationKey: string;
    iconName: string;
}

const RowMenuItem = (props: PrintDialogueMenuItemProps, dispatch: AppDispatch): MenuItemProps => {
    const { transportOrder, index, isDisabled } = props;
    const onSelect = () => {
        props.onClickSelectMenuItem();
        dispatch(transportOrdersSlice.actions.clearSelectedTransportOrders());
        dispatch(
            transportOrdersSlice.actions.addSelectedTransportOrder({
                shipmentNumber: transportOrder.shipment!.shipmentNumber,
                shipmentId: transportOrder.shipment!.id,
                transportOrderId: transportOrder.id,
                transportOrderNumber: transportOrder.transportOrderNumber,
                transportConcept: transportOrder.transportConcept,
                index,
            }),
        );
    };
    return {
        value: (
            <div className={'display-flex'}>
                <div className={'felx-row'}>
                    <span className={`rioglyph ${props.iconName} margin-right-5 text-size-18`} />
                </div>
                <div className={'felx-row'}>
                    <FormattedMessage id={props.translationKey} />
                </div>
            </div>
        ),
        onSelect,
        disabled: isDisabled,
    };
};
