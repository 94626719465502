// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const buyerCodec = t.type({
    name: t.string,
});

export type ApiBuyer = t.TypeOf<typeof buyerCodec>;

export const shipFromCodec = t.partial({
    shipping_location_id: t.string,
});

export type ApiShipFrom = t.TypeOf<typeof shipFromCodec>;

export const shipToCodec = t.type({
    plant_code: t.string,
    place_of_discharge: t.string,
});

export type ApiShipTo = t.TypeOf<typeof shipToCodec>;
