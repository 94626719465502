import { toLocalDate, toLocalTime } from '../../../../../dateUtils';
import { TimeWindow } from '../../../domain/transportOrder.types';
import {
    FormattedDateOnly,
    FormattedDateOrDateTime,
    FormattedTimeOnly,
} from '../../common/i18n/FormattedDateOrDateTime';

export const TIME_WINDOW = 'TIME_WINDOW_TEST_ID';

interface TimeWindowDisplayProps {
    plannedLoadingTimeWindow: TimeWindow;
    lineBreakBetweenDateAndTime?: boolean;
}
interface FormattedDateTimeWindowProps {
    startDate: string;
    endDate: string;
}

const FormattedDateTimeWindow = (props: FormattedDateTimeWindowProps) => {
    return (
        <>
            <FormattedDateOnly date={props.startDate} isZoned={true} />
            <span className={'text-normal'}>
                {' '}
                <FormattedTimeOnly date={props.startDate} isZoned={true} />
            </span>
            {' -'} <br />
            <FormattedDateOnly date={props.endDate} isZoned={true} />
            <span className={'text-normal'}>
                {' '}
                <FormattedTimeOnly date={props.endDate} isZoned={true} />
            </span>
        </>
    );
};

export const TimeWindowDisplay = (props: TimeWindowDisplayProps) => {
    const isTimeWindowOnTheSameDay =
        toLocalDate(props.plannedLoadingTimeWindow.from) === toLocalDate(props.plannedLoadingTimeWindow.to);
    const isTimeWindowInTheSameHourAndMinute =
        toLocalTime(props.plannedLoadingTimeWindow.from) === toLocalTime(props.plannedLoadingTimeWindow.to);

    const renderTimeWindow = () => {
        if (!isTimeWindowOnTheSameDay) {
            return (
                <FormattedDateTimeWindow
                    startDate={props.plannedLoadingTimeWindow.from}
                    endDate={props.plannedLoadingTimeWindow.to}
                />
            );
        } else {
            if (isTimeWindowInTheSameHourAndMinute) {
                return <FormattedDateOrDateTime date={props.plannedLoadingTimeWindow.from} isZoned={true} />;
            } else {
                return (
                    <>
                        <FormattedDateOnly date={props.plannedLoadingTimeWindow.from} isZoned={true} />
                        {props.lineBreakBetweenDateAndTime ? <br /> : ' '}
                        <span className={'text-normal'}>
                            <FormattedTimeOnly date={props.plannedLoadingTimeWindow.from} isZoned={true} />
                            {' - '}
                            <FormattedTimeOnly date={props.plannedLoadingTimeWindow.to} isZoned={true} />
                        </span>
                    </>
                );
            }
        }
    };

    return (
        <div data-testid={TIME_WINDOW} className={'text-medium'}>
            {renderTimeWindow()}
        </div>
    );
};
