import { FieldRenderProps } from 'react-final-form';
import { isLocalDate, isValidIso8601, toLocalDate, toLocalTime } from '../../../../../dateUtils';
import { DATA_TEST_ID_PROPERTY_NAME } from '../../../../../utils';
import { DatePicker } from '../DatePicker';
import { TimePicker } from '../TimePicker';

export interface DateAndTimePickerInputProps extends FieldRenderProps<string | undefined> {
    isValidDate?: (currentDate: string | undefined) => boolean;
    placeholderDate?: string | undefined;
    placeholderTime?: string | undefined;
    [DATA_TEST_ID_PROPERTY_NAME]?: string;
}

export const DateAndTimePickerInput = (props: DateAndTimePickerInputProps) => {
    const { input, isValidDate, placeholderDate, placeholderTime, ...rest } = props;
    const testId = rest[DATA_TEST_ID_PROPERTY_NAME];

    const mergeDateTime = (date?: string, time?: string): string | undefined => {
        if (date === undefined || date === '') {
            return undefined;
        } else if (time === undefined || time === '') {
            return date;
        } else {
            return `${date}T${time}:00`;
        }
    };

    const onDateChangeHandler = (date: string | undefined) => {
        const time = isLocalDate(input.value) || !isValidIso8601(input.value) ? undefined : toLocalTime(input.value);
        return input.onChange(mergeDateTime(date, time));
    };

    const onTimeChangeHandler = (time?: string) => {
        const date = input.value === undefined || !isValidIso8601(input.value) ? undefined : toLocalDate(input.value);
        return input.onChange(mergeDateTime(date, time));
    };

    return (
        <div className={'form-inline display-flex align-items-end'} data-testid={testId}>
            <div className={'form-group'}>
                <DatePicker
                    onChange={onDateChangeHandler}
                    value={isValidIso8601(input.value) ? toLocalDate(input.value) : undefined}
                    isValidDate={isValidDate}
                    clearableInput={true}
                    inputProps={{ placeholder: placeholderDate }}
                />
            </div>
            <div className={'form-group width-100'}>
                <TimePicker
                    value={
                        isValidIso8601(input.value) && !isLocalDate(input.value) ? toLocalTime(input.value) : undefined
                    }
                    onChange={onTimeChangeHandler}
                    disabled={input.value === undefined || input.value === ''}
                    placeholder={placeholderTime}
                />
            </div>
        </div>
    );
};
