import ForbiddenState from '@rio-cloud/rio-uikit/lib/es/ForbiddenState';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Routing } from './routing/Routes';

export const ForbiddenPage = () => {
    const navigate = useNavigate();
    const returnToInitialPage = () => navigate(Routing.dunsSelection);

    return (
        <ForbiddenState
            headline={<FormattedMessage id={'webedi.errorPage.forbidden.heading'} />}
            message={<FormattedMessage id={'webedi.errorPage.forbidden.message'} />}
            buttons={[
                {
                    text: <FormattedMessage id={'webedi.label.return'} />,
                    onClick: returnToInitialPage,
                },
            ]}
        />
    );
};
