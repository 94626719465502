import { State } from '../../../../configuration/setup/store';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { getShipmentsAction } from '../../actions/shipments/Shipments.actions';
import {
    getShipmentsQuery,
    getTotalCountOfMatchedShipments,
    isLoadingShipments,
} from '../../selectors/shipments/Shipments.selector';
import { PaginationBar } from '../common/PaginationBar';

interface Props {
    exported: boolean;
}

export const ShipmentsPaginationBar = (props: Props) => {
    const { exported } = props;
    const totalNumber = useAppSelector(getTotalCountOfMatchedShipments);
    const {
        params: { offset, limit },
        dunsNumber,
    } = useAppSelector((state: State) => getShipmentsQuery(state, exported))!;
    const isLoading = useAppSelector(isLoadingShipments);

    const dispatch = useAppDispatch();

    if (isLoading) {
        return null;
    }

    const onChangeLimit = (newLimit: number, newOffset: number) =>
        dispatch(getShipmentsAction(dunsNumber!, exported, { limit: newLimit, offset: newOffset }));
    const onOffsetSelect = (newOffset: number) =>
        dispatch(getShipmentsAction(dunsNumber!, exported, { offset: newOffset }));

    return (
        <>
            <PaginationBar
                limit={limit}
                offset={offset}
                totalNumber={totalNumber}
                limits={[25, 50, 100]}
                onOffsetSelect={onOffsetSelect}
                onChangeLimit={onChangeLimit}
            />
        </>
    );
};
