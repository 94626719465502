// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { Ownership } from '../../reducers/shipments/packaging.types';
import { fromEnum } from '../util';

export const PackagingMaterialCodec = t.partial({
    name: t.string,
    box_code: t.string,
    outer_height_in_mm: t.number,
    outer_width_in_mm: t.number,
    outer_length_in_mm: t.number,
    tare_weight_in_kg: t.number,
    is_reusable: t.boolean,
    ownership: fromEnum<Ownership>('Ownership', Ownership),
    stacking_factor: t.number,
});

export type ApiPackagingMaterial = t.TypeOf<typeof PackagingMaterialCodec>;
