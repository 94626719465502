import { MenuItemProps } from '@rio-cloud/rio-uikit/MenuItem';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/lib/es/SimpleButtonDropdown';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/lib';
import { useNavigate } from 'react-router';
import { Action } from 'redux';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../../configuration/tokenHandling/selectors';
import {
    fetchAndStoreDeliverySchedule,
    setSelectedDeliveryScheduleId,
} from '../../../actions/deliverySchedules/DeliverySchedules.actions';
import {
    createPackagingTemplate,
    updatePackagingTemplate,
} from '../../../actions/deliverySchedules/PackagingTemplate.actions';
import { Partner } from '../../../domain/common.types';
import { useDeliveryScheduleIdFromPath, useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    PackagingTemplate,
    PackagingTemplateType,
    SelectedMetadataEntryId,
} from '../../../reducers/deliverySchedules/types';
import {
    getActionToBeConfirmed,
    getIsSavingArticleMetadata,
    getPackagingTemplateDimensionsFromForm,
    getPackagingTemplateNameFromForm,
    getSelectedDeliverySchedule,
    getSelectedDeliveryScheduleId,
    getSelectedDeliverySchedulePackagingTemplates,
    getSelectedMetadataEntry,
    getSelectedMetadataEntryId,
    hasLisonTemplate,
    hasMaxNumberOfHomogeneousPackagingTemplates,
    isLoadingDeliverySchedules,
    isMetadataViewContentDirty,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { ArticleNumber } from '../../common/ArticleNumber';
import { ErrorBox } from '../../common/ErrorBox';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Tooltip } from '../../common/Tooltip';
import { DiscardChangesConfirmationDialog } from '../../common/dialog/DiscardChangesConfirmationDialog';
import { NavigationPrompt } from '../../common/dialog/NavigationPrompt';
import { Routing } from '../../routing/Routes';
import { ArticleMasterDataForm, articleMasterDataFormId } from '../articles/ArticleMasterDataForm';
import { LisonPackagingConfig } from '../packagingTemplate/LisonPackagingConfig';
import { PackagingTemplateView } from '../packagingTemplate/PackagingTemplateView';

export const DELIVERY_SCHEDULE_METADATA_VIEW_TEST_ID = 'DELIVERY_SCHEDULE_METADATA_VIEW_TEST_ID';
export const DELIVERY_SCHEDULE_METADATA_VIEW_HEADER_TEST_ID = 'DELIVERY_SCHEDULE_METADATA_VIEW_HEADER_TEST_ID';
export const DELIVERY_SCHEDULE_METADATA_VIEW_SAVE_BUTTON_TEST_ID =
    'DELIVERY_SCHEDULE_METADATA_VIEW_SAVE_BUTTON_TEST_ID';
export const DELIVERY_SCHEDULE_METADATA_VIEW_CLOSE_BUTTON_TEST_ID =
    'DELIVERY_SCHEDULE_METADATA_VIEW_CLOSE_BUTTON_TEST_ID';
export const DELIVERY_SCHEDULE_METADATA_VIEW_SIDEBAR_TEST_ID = 'DELIVERY_SCHEDULE_METADATA_VIEW_SIDEBAR_TEST_ID';
export const DELIVERY_SCHEDULE_METADATA_VIEW_SIDEBAR_DROPDOWN_TEST_ID =
    'DELIVERY_SCHEDULE_METADATA_VIEW_SIDEBAR_DROPDOWN_TEST_ID';

export const DeliveryScheduleMetadataViewConfirmationDialog = (props: { actionToDispatch: Action | undefined }) => {
    const dispatch = useAppDispatch();

    const { actionToDispatch } = props;
    return (
        <DiscardChangesConfirmationDialog
            show={actionToDispatch !== undefined}
            onClickDiscard={() => {
                dispatch(deliverySchedulesSlice.actions.clearNonPersistedTemplates());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateName());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateStepsAdded());
                dispatch(deliverySchedulesSlice.actions.clearPackagingTemplateDimensions());
                dispatch(deliverySchedulesSlice.actions.setIsMetadataViewContentDirty(false));
                if (actionToDispatch) {
                    dispatch(actionToDispatch);
                }
                dispatch(deliverySchedulesSlice.actions.setActionToBeConfirmed(undefined));
            }}
            onClickReturn={() => {
                dispatch(deliverySchedulesSlice.actions.setActionToBeConfirmed(undefined));
            }}
        />
    );
};

export const DeliveryScheduleMetadataView = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dunsNumber = useDunsNumberFromPath();
    const isContentDirty = useAppSelector(isMetadataViewContentDirty);
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);
    const selectedDeliveryScheduleIdFromPath = useDeliveryScheduleIdFromPath();
    const selectedDeliveryScheduleId = useAppSelector(getSelectedDeliveryScheduleId);
    const selectedMetadataEntryId = useAppSelector(getSelectedMetadataEntryId);
    const selectedDeliverySchedulePackagingTemplates = useAppSelector(getSelectedDeliverySchedulePackagingTemplates);
    const packagingTemplateFormName = useAppSelector(getPackagingTemplateNameFromForm);
    const packagingTemplateFormDimensions = useAppSelector(getPackagingTemplateDimensionsFromForm);
    const isLoading = useAppSelector(isLoadingDeliverySchedules);
    const actionToBeConfirmed = useAppSelector(getActionToBeConfirmed);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);
    const isSavingArticleMetadata = useAppSelector(getIsSavingArticleMetadata);
    const selectedDeliveryScheduleRenderedSuccessfully = useRef(false);

    const confirmAndDispatchAction = (action: Action) => {
        if (isContentDirty && !isReadOnly) {
            dispatch(deliverySchedulesSlice.actions.setActionToBeConfirmed(action));
        } else {
            dispatch(action);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (selectedDeliveryScheduleId === undefined) {
            dispatch(setSelectedDeliveryScheduleId(dunsNumber!, selectedDeliveryScheduleIdFromPath!, navigate));
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (!selectedDeliverySchedule && selectedDeliveryScheduleId) {
            dispatch(fetchAndStoreDeliverySchedule(dunsNumber!, selectedDeliveryScheduleId));
            dispatch(setSelectedDeliveryScheduleId(dunsNumber!, selectedDeliveryScheduleId, navigate));
        } else if (
            !selectedDeliverySchedule &&
            selectedDeliveryScheduleId === undefined &&
            selectedDeliveryScheduleRenderedSuccessfully.current
        ) {
            navigate(`${Routing.webScm}/${dunsNumber}${Routing.deliverySchedules}`);
        }
        selectedDeliveryScheduleRenderedSuccessfully.current = true;
    }, [selectedDeliveryScheduleId]);

    if (!selectedDeliverySchedule) {
        if (isLoading) {
            return <LoadingIndicator />;
        } else {
            if (selectedDeliveryScheduleId !== undefined) {
                return <NotFoundState headline={<FormattedMessage id={'webedi.nothingFound'} />} message={''} />;
            } else {
                return <></>;
            }
        }
    }

    const SaveButtonArticleMasterData = () => {
        const content = (
            <button
                className={'btn btn-default'}
                data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_SAVE_BUTTON_TEST_ID}
                type={'submit'}
                form={articleMasterDataFormId}
                disabled={!isContentDirty || isReadOnly}
            >
                <FormattedMessage id={'webedi.label.save'} />
                {isReadOnly && (
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                        }
                    />
                )}
            </button>
        );

        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    };

    const SaveButtonPackagingTemplates = () => {
        const content = (
            <button
                className={'btn btn-default'}
                data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_SAVE_BUTTON_TEST_ID}
                onClick={() => {
                    const id = selectedMetadataEntryId!;
                    const selectedPackagingTemplate = selectedDeliverySchedulePackagingTemplates[id];

                    if (selectedPackagingTemplate.persisted) {
                        dispatch(
                            updatePackagingTemplate(
                                selectedDeliveryScheduleIdFromPath!,
                                id,
                                dunsNumber!,
                                selectedPackagingTemplate.steps,
                                packagingTemplateFormDimensions ?? selectedPackagingTemplate.dimensions,
                                packagingTemplateFormName ?? selectedPackagingTemplate.name,
                            ),
                        );
                    } else {
                        dispatch(
                            createPackagingTemplate(
                                dunsNumber!,
                                selectedDeliveryScheduleIdFromPath!,
                                PackagingTemplateType.HOMOGENEOUS,
                                selectedPackagingTemplate.referencedDeliveryScheduleIds,
                                selectedPackagingTemplate.steps,
                                packagingTemplateFormDimensions ?? selectedPackagingTemplate.dimensions,
                                packagingTemplateFormName ?? selectedPackagingTemplate.name,
                            ),
                        );
                    }
                }}
                disabled={!isContentDirty || isReadOnly}
            >
                <FormattedMessage id={'webedi.label.save'} />
                {isReadOnly && (
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                        }
                    />
                )}
            </button>
        );

        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    };

    return (
        <>
            <div className={'margin-bottom-20'}>
                <ErrorBox headerKey={'webedi.error.deliverySchedule'} />
            </div>
            <Spinner
                show={isSavingArticleMetadata}
                text={<FormattedMessage id={'webedi.saving'} />}
                isFullSized={true}
            />
            <div className={'panel panel-default'} data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_TEST_ID}>
                <div
                    className={
                        'panel-heading display-flex flex-row align-items-center justify-content-between padding-right-10 padding-20'
                    }
                    data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_HEADER_TEST_ID}
                >
                    <div
                        className={'text-color-primary text-size-20 min-width-300-lg margin-right-20'}
                        style={{ flexShrink: 0 }}
                    >
                        <FormattedMessage id={'webedi.deliverySchedule.metadata.title'} />
                    </div>
                    <div
                        className={
                            'display-flex flex-row flex-1-0-lg margin-left-20 margin-right-25 min-width-100 text-size-16'
                        }
                    >
                        <span className={'margin-right-25 ellipsis-1'}>
                            <FormattedMessage id={'webedi.articleNumber.buyer'} />
                            {': '}
                            <ArticleNumber
                                articleNumber={
                                    selectedDeliverySchedule.scheduledArticleDetails.lineItem.lineItemIdentifier
                                        .itemNumberIdentification.itemIdentifier
                                }
                            />
                        </span>
                        <span className={'margin-right-25 ellipsis-1'}>
                            <FormattedMessage id={'webedi.plantIdentifier'} />
                            {': '}
                            <span className={'rioglyph rioglyph-factory text-size-18 margin-left-5 margin-right-5'} />
                            <span>{selectedDeliverySchedule.shipTo.shipTo.partyIdentificationDetails.identifier}</span>
                        </span>
                        <span className={'ellipsis-1'}>
                            <FormattedMessage id={'webedi.common.placeOfDischarge'} />
                            {': '}
                            <span
                                className={'rioglyph rioglyph-load-unload text-size-18 margin-left-5 margin-right-5'}
                            />
                            <span>{selectedDeliverySchedule.shipTo.placeOfDischarge.locationNameCode}</span>
                        </span>
                    </div>
                    <div className={'display-flex flex-0-1-lg btn-toolbar'} style={{ flexShrink: 0 }}>
                        {selectedMetadataEntryId === SelectedMetadataEntryId.ARTICLE_MASTER_DATA ? (
                            <SaveButtonArticleMasterData />
                        ) : (
                            <SaveButtonPackagingTemplates />
                        )}
                        <Tooltip text={<FormattedMessage id={'webedi.common.close'} />} placement={'bottom'}>
                            <button
                                type={'button'}
                                className={'btn btn-link btn-icon-only'}
                                onClick={() =>
                                    confirmAndDispatchAction(
                                        deliverySchedulesSlice.actions.clearSelectedDeliveryScheduleId(),
                                    )
                                }
                                data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_CLOSE_BUTTON_TEST_ID}
                            >
                                <span className={'rioglyph rioglyph-remove'} />
                            </button>
                        </Tooltip>
                    </div>
                </div>
                <div className={'display-flex min-height-500'}>
                    <div
                        className={'border-style-solid border-color-light border-width-1 border-right-only padding-25'}
                    >
                        <Sidebar />
                    </div>
                    <div className={'flex-1-1'}>
                        <Content />
                    </div>
                </div>
            </div>
            {!isReadOnly && <DeliveryScheduleMetadataViewConfirmationDialog actionToDispatch={actionToBeConfirmed} />}
            <NavigationPrompt when={isContentDirty && !isReadOnly} />
        </>
    );
};

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const packagingTemplates = useAppSelector(getSelectedDeliverySchedulePackagingTemplates);
    const selectedMetadataEntryId = useAppSelector(getSelectedMetadataEntryId);
    const maxAmountOfHomogeneousPackagingTemplatesReached = useAppSelector(hasMaxNumberOfHomogeneousPackagingTemplates);
    const lisonTemplateExists = useAppSelector(hasLisonTemplate);
    const isContentDirty = useAppSelector(isMetadataViewContentDirty);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);

    const confirmAndDispatchAction = (action: Action) => {
        if (isContentDirty && !isReadOnly) {
            dispatch(deliverySchedulesSlice.actions.setActionToBeConfirmed(action));
        } else {
            dispatch(action);
        }
    };

    const shouldShowLisonRefreshPage =
        !lisonTemplateExists &&
        (selectedDeliverySchedule?.partner === Partner.VW || selectedDeliverySchedule?.partner === Partner.PORSCHE);

    const dropdownItems: MenuItemProps[] = [];
    dropdownItems.push({
        value: `${intl.formatMessage({ id: 'webedi.deliverySchedule.createHomogeneousPackaging' })}...`,
        onSelect: () =>
            confirmAndDispatchAction(
                deliverySchedulesSlice.actions.addHomogeneousPackagingTemplate(
                    intl.formatMessage({ id: 'webedi.deliverySchedule.homogeneousPackagingTemplate' }),
                ),
            ),
        disabled: maxAmountOfHomogeneousPackagingTemplatesReached,
    });
    dropdownItems.push({
        value: `${intl.formatMessage({ id: 'webedi.deliverySchedule.createMixedPackaging' })}...`,
        onSelect: () => {},
        disabled: true,
    });

    const WrappedSimpleButtonDropdown = () => {
        return (
            <SimpleButtonDropdown
                title={
                    <>
                        <span className={'rioglyph rioglyph-plus-light'} />
                        <FormattedMessage id={'webedi.deliverySchedule.addPackagingConfiguration'} />
                        <span className={'caret'} />
                    </>
                }
                items={dropdownItems}
            />
        );
    };

    return (
        <>
            <ul className={'nav nav-pills nav-stacked'} data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_SIDEBAR_TEST_ID}>
                <li
                    className={selectedMetadataEntryId === SelectedMetadataEntryId.ARTICLE_MASTER_DATA ? 'active' : ''}
                    key={SelectedMetadataEntryId.ARTICLE_MASTER_DATA}
                    onClick={() =>
                        confirmAndDispatchAction(
                            deliverySchedulesSlice.actions.setSelectedDeliveryScheduleMetadataEntry(
                                SelectedMetadataEntryId.ARTICLE_MASTER_DATA,
                            ),
                        )
                    }
                >
                    <div className={'display-flex align-items-center'}>
                        <span className={'rioglyph rioglyph-document text-size-18 padding-right-5'} />
                        <FormattedMessage id={'webedi.deliverySchedule.masterData.title'} tagName='span' />
                    </div>
                </li>
                <hr />
                <li className={'pointer-events-none'}>
                    <span className={'text-color-dark'}>
                        <FormattedMessage id={'webedi.deliverySchedule.packagingTemplates'} tagName={'span'} />
                    </span>
                </li>
                {shouldShowLisonRefreshPage && (
                    <li
                        className={selectedMetadataEntryId === SelectedMetadataEntryId.MISSING_LISON ? 'active' : ''}
                        key={SelectedMetadataEntryId.MISSING_LISON}
                        onClick={() =>
                            confirmAndDispatchAction(
                                deliverySchedulesSlice.actions.setSelectedDeliveryScheduleMetadataEntry(
                                    SelectedMetadataEntryId.MISSING_LISON,
                                ),
                            )
                        }
                    >
                        <div className={'display-flex align-items-center'}>
                            <span className={'rioglyph rioglyph-parcel text-size-18 padding-right-5'} />
                            <FormattedMessage
                                id={'webedi.deliverySchedule.packagingConfigurationLison.title'}
                                tagName='span'
                            />
                        </div>
                    </li>
                )}
                {Object.values(packagingTemplates).map((value) => (
                    <li
                        className={selectedMetadataEntryId === value.id ? 'active' : ''}
                        key={value.id}
                        onClick={() =>
                            confirmAndDispatchAction(
                                deliverySchedulesSlice.actions.setSelectedDeliveryScheduleMetadataEntry(value.id),
                            )
                        }
                    >
                        <div className={'display-flex align-items-center'}>
                            <span className={'rioglyph rioglyph-parcel text-size-18 padding-right-5'} />
                            <span>{showTemplateName(value.name, intl)}</span>
                        </div>
                    </li>
                ))}
            </ul>
            <div
                className={'padding-top-10 padding-left-15 padding-right-15'}
                data-testid={DELIVERY_SCHEDULE_METADATA_VIEW_SIDEBAR_DROPDOWN_TEST_ID}
            >
                <WrappedSimpleButtonDropdown />
            </div>
            <hr />
        </>
    );
};

const showTemplateName = (name: string, intl: IntlShape): string => {
    if (name === 'LISON Template') {
        return intl.formatMessage({
            id: 'webedi.deliverySchedule.packagingConfigurationLison.title',
        });
    }
    return name;
};

const Content = () => {
    const selectedDeliverySchedule = useAppSelector(getSelectedDeliverySchedule);
    const selectedMetadataEntry = useAppSelector(getSelectedMetadataEntry);
    const selectedMetadataEntryId = useAppSelector(getSelectedMetadataEntryId);

    if (selectedDeliverySchedule === undefined || selectedMetadataEntryId === undefined) {
        return null;
    }

    if (selectedMetadataEntryId === SelectedMetadataEntryId.ARTICLE_MASTER_DATA) {
        return <ArticleMasterDataForm deliverySchedule={selectedDeliverySchedule} />;
    } else {
        switch ((selectedMetadataEntry as PackagingTemplate).type) {
            case PackagingTemplateType.MISSING_LISON:
                return <LisonPackagingConfig />;
            case PackagingTemplateType.LISON:
            case PackagingTemplateType.HOMOGENEOUS:
            case PackagingTemplateType.HETEROGENEOUS:
                return <PackagingTemplateView />;
            default:
                return null;
        }
    }
};
