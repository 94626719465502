import { BUTTON_STYLE } from '@rio-cloud/rio-uikit/Button';
import { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import {
    DispatchProposal,
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    DispatchProposalProblemLevel,
} from '../../../domain/dispatchProposal.types';
import { HorizontalLine } from './DispatchProposalProblemBannerFormatters';

export interface DispatchProposalProblemBannerFunctions {
    readonly filterForOneTypeOfProblem: (problems: DispatchProposalProblem[]) => DispatchProposalProblemBase[];
    readonly useGenerateProblemDescriptor: (
        problemBase: DispatchProposalProblemBase[],
        dispatchProposals: DispatchProposal[],
    ) => ProblemDescriptor | undefined;
}

export const DispatchProposalProblemBanner = (props: {
    problems: DispatchProposalProblem[];
    generateAndFilterProblems: DispatchProposalProblemBannerFunctions;
    dispatchProposals: DispatchProposal[];
}) => {
    const singleTypeOfProblems = props.generateAndFilterProblems.filterForOneTypeOfProblem(props.problems);
    const problemDescriptor = props.generateAndFilterProblems.useGenerateProblemDescriptor(
        singleTypeOfProblems,
        props.dispatchProposals,
    );
    if (problemDescriptor === undefined) {
        return undefined;
    }
    return (
        <div
            data-testid={`DISPATCH_PROPOSAL_PROBLEM_BANNER_${problemDescriptor.formatting.testId}`}
            className={`alert alert-${problemDescriptor.formatting.buttonStyle} padding-bottom-10 margin-bottom-25`}
        >
            <DispatchProposalProblemBannerProblemSpecificContent {...props} />
        </div>
    );
};

const noErrorsInProblems = (
    problems: DispatchProposalProblem[],
    errorProcessors: DispatchProposalProblemBannerFunctions[],
): boolean => {
    const sumOfErrors = errorProcessors
        .map((it) => it.filterForOneTypeOfProblem(problems).length)
        .reduce((sum, errorsOfSpecificType) => sum + errorsOfSpecificType, 0);
    return sumOfErrors === 0;
};

const hasSubsequentProblem = (
    problems: DispatchProposalProblem[],
    errorProcessors: DispatchProposalProblemBannerFunctions[],
    index: number,
): boolean => {
    const sumOfSubsequentErrors = errorProcessors
        .slice(index + 1)
        .map((it) => it.filterForOneTypeOfProblem(problems).length)
        .reduce((sum, errorsOfSpecificType) => sum + errorsOfSpecificType, 0);
    return sumOfSubsequentErrors > 0;
};

export const DispatchProposalProblemCombinedErrorBanner = (props: {
    problems: DispatchProposalProblem[];
    errorProcessors: DispatchProposalProblemBannerFunctions[];
    dispatchProposals: DispatchProposal[];
}) => {
    if (noErrorsInProblems(props.problems, props.errorProcessors)) {
        return <></>;
    }
    return (
        <div
            data-testid={`DISPATCH_PROPOSAL_PROBLEM_COMBINED_ERRORS`}
            className={`alert alert-danger padding-bottom-10 margin-bottom-25`}
        >
            {props.errorProcessors.map((callback, index) => (
                <DispatchProposalProblemBannerProblemSpecificContent
                    key={uuid()}
                    problems={props.problems}
                    generateAndFilterProblems={callback}
                    dispatchProposals={props.dispatchProposals}
                    drawHorizontalRule={hasSubsequentProblem(props.problems, props.errorProcessors, index)}
                />
            ))}
        </div>
    );
};

export const DispatchProposalProblemBannerProblemSpecificContent = (props: {
    problems: DispatchProposalProblem[];
    generateAndFilterProblems: DispatchProposalProblemBannerFunctions;
    dispatchProposals: DispatchProposal[];
    drawHorizontalRule?: boolean;
}) => {
    const singleTypeOfProblems = props.generateAndFilterProblems.filterForOneTypeOfProblem(props.problems);
    const problemDescriptor = props.generateAndFilterProblems.useGenerateProblemDescriptor(
        singleTypeOfProblems,
        props.dispatchProposals,
    );
    if (problemDescriptor === undefined) {
        return undefined;
    }
    const rows = problemDescriptor.rows;

    return (
        <div
            data-testid={`DISPATCH_PROPOSAL_PROBLEM_BANNER_CONTENT_${problemDescriptor.formatting.testId}`}
            className={'display-flex flex-1-0'}
        >
            <>{problemDescriptor.formatting.icon}</>
            <div className={'flex-1-1'}>
                <>{problemDescriptor.title}</>
                <>{problemDescriptor.formatting.horizontalDivider}</>
                {rows.map((row) => (
                    <LineWithArticleNumberAndDescription key={uuid()} row={row} />
                ))}
                {props.drawHorizontalRule ? <HorizontalLine level={DispatchProposalProblemLevel.ERROR} /> : null}
            </div>
        </div>
    );
};

const LineWithArticleNumberAndDescription = (props: { row: ProblemDescriptorRow }) => {
    const problemDescriptorRow = props.row;
    return (
        <div
            data-testid={`DISPATCH_PROPOSAL_PROBLEM_BANNER_DESCRIPTION_ROW`}
            className={'display-flex gap-50 full-width align-items-center'}
        >
            {problemDescriptorRow.identifier}
            {problemDescriptorRow.description}
            {problemDescriptorRow.actionButton}
        </div>
    );
};

export interface ProblemDescriptor {
    readonly title: ReactElement;
    readonly rows: ProblemDescriptorRow[];
    readonly formatting: ProblemDescriptorFormatting;
}

export interface ProblemDescriptorRow {
    readonly identifier: ReactElement;
    readonly description: ReactElement;
    readonly actionButton: ReactElement | undefined;
}

interface ProblemDescriptorFormatting {
    readonly testId: string;
    readonly horizontalDivider: ReactElement | undefined;
    readonly icon: ReactElement;
    readonly buttonStyle: BUTTON_STYLE;
}
