import { Decorator } from 'final-form';
import createDecorator from 'final-form-calculate';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../../configuration/tokenHandling/selectors';
import { roundOffFloatingPointErrors } from '../../../../../utils';
import { postCumulativeQuantitySentOffsetAndRefresh } from '../../../actions/deliverySchedules/DeliverySchedules.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { DeliverySchedule } from '../../../reducers/deliverySchedules/types';
import { ChevronRight } from '../../common/ChevronRight';
import { renderDetailAttributeTextCard } from '../../common/DetailAttributeCard';
import { Tooltip } from '../../common/Tooltip';
import { NumberInput, getNumberInputParseFunction } from '../../common/form/NumberInput';

export interface CumulativeQuantitySentOffsetFormProps {
    deliverySchedule: DeliverySchedule;
    setShowCumulativeOffsetTable: (value: boolean) => void;
}

export interface CumulativeQuantitySentOffsetFormValues {
    currentReceived: number;
    newValueSent?: number;
    newValueTransit?: number;
}

const cumulativeQuantitySentOffsetFormPropertyNames: Record<
    keyof CumulativeQuantitySentOffsetFormValues,
    keyof CumulativeQuantitySentOffsetFormValues
> = {
    currentReceived: 'currentReceived',
    newValueTransit: 'newValueTransit',
    newValueSent: 'newValueSent',
};

export const CUMULATIVE_QUANTITY_SENT_OFFSET_FORM_TEST_ID = 'CUMULATIVE_QUANTITY_SENT_OFFSET_FORM_TEST_ID';

const offsetCalculator = createDecorator({
    field: [cumulativeQuantitySentOffsetFormPropertyNames.newValueSent],
    updates: {
        newValueTransit: (_, allValues) => {
            const formValues = allValues as CumulativeQuantitySentOffsetFormValues | undefined;
            if (
                typeof formValues?.newValueSent === 'number' &&
                formValues?.newValueSent > formValues?.currentReceived
            ) {
                return roundOffFloatingPointErrors(formValues.newValueSent - formValues.currentReceived);
            } else {
                return 0;
            }
        },
    },
}) as Decorator<CumulativeQuantitySentOffsetFormValues, Partial<CumulativeQuantitySentOffsetFormValues>>;

const cumulativeQuantitySentOffsetFormId = 'CUMULATIVE_QUANTITY_SENT_OFFSET_FORM_ID';

export const CumulativeQuantitySentOffsetForm = (props: CumulativeQuantitySentOffsetFormProps) => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const isReadOnly = useAppSelector(isReadOnlyAdmin);

    const initialValues: CumulativeQuantitySentOffsetFormValues = {
        currentReceived: props.deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived?.quantity ?? 0,
        newValueSent: undefined,
        newValueTransit: undefined,
    };

    const renderField = (name: string, labelKey: string, disabled: boolean) => (
        <div className={'margin-auto width-100pct'}>
            <label className={'text-color-gray text-size-12'} htmlFor={name}>
                <FormattedMessage id={labelKey} />
            </label>
            <Field
                id={name}
                name={name}
                component={NumberInput}
                className={'form-control'}
                measurementUnitCode={props.deliverySchedule.scheduledArticleDetails.measurementUnitCode}
                parse={getNumberInputParseFunction(props.deliverySchedule.scheduledArticleDetails.measurementUnitCode)}
                errorMessageClassName={'text-size-10 margin-bottom-0'}
                disabled={disabled}
            />
        </div>
    );

    const renderSaveButton = (newValueSent: number | undefined) => {
        const content = (
            <button
                type={'submit'}
                form={cumulativeQuantitySentOffsetFormId}
                className={'btn btn-primary'}
                disabled={typeof newValueSent !== 'number' || isReadOnly}
            >
                <FormattedMessage id={'webedi.label.save'} />
                {isReadOnly && (
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                        }
                    />
                )}
            </button>
        );

        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    };

    const onSubmitHandler = (values: CumulativeQuantitySentOffsetFormValues) => {
        const newQuantity = values.newValueSent!;
        dispatch(postCumulativeQuantitySentOffsetAndRefresh(dunsNumber!, props.deliverySchedule.id, newQuantity));
    };

    const columnClasses =
        'padding-5pct padding-bottom-3pct padding-top-3pct display-flex flex-column position-relative ' +
        'width-33pct height-auto border-style-solid border-color-lighter border-width-1 border-right-only';
    const formBoxClasses =
        'bg-white display-flex flex-row border-width-1 border-style-solid ' +
        'border-color-light border-left-none border-right-none';

    return (
        <Form<CumulativeQuantitySentOffsetFormValues>
            onSubmit={onSubmitHandler}
            decorators={[offsetCalculator]}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {
                return (
                    <form
                        id={cumulativeQuantitySentOffsetFormId}
                        onSubmit={handleSubmit}
                        className={'form-horizontal'}
                        data-testid={CUMULATIVE_QUANTITY_SENT_OFFSET_FORM_TEST_ID}
                    >
                        <div className={formBoxClasses}>
                            <div className={`${columnClasses} bg-lightest`}>
                                <label className={'text-size-16 text-color-darkest margin-bottom-0'}>
                                    <FormattedMessage id={'webedi.label.currentValues'} />
                                </label>
                                <div className={'margin-auto padding-top-10 width-100pct'}>
                                    {renderDetailAttributeTextCard(
                                        <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantitySent'} />,
                                        props.deliverySchedule.cumulativeQuantitySent,
                                        undefined,
                                    )}
                                    {renderDetailAttributeTextCard(
                                        <FormattedMessage
                                            id={
                                                'webedi.deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived'
                                            }
                                        />,
                                        props.deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived
                                            ?.quantity,
                                        undefined,
                                    )}
                                    {renderDetailAttributeTextCard(
                                        <FormattedMessage id={'webedi.deliverySchedule.cumulativeQuantityInTransit'} />,
                                        Math.max(
                                            roundOffFloatingPointErrors(
                                                props.deliverySchedule.cumulativeQuantitySent -
                                                    (props.deliverySchedule.scheduledArticleDetails
                                                        .cumulativeQuantityReceived?.quantity ?? 0),
                                            ),
                                            0,
                                        ),
                                        undefined,
                                    )}
                                </div>
                                <ChevronRight backgroundColor={'bg-lightest'} />
                            </div>
                            <div className={`${columnClasses} display-flex flex-column position-relative`}>
                                <label className={'text-size-16 text-color-darkest'}>
                                    <FormattedMessage id={'webedi.label.inputValues'} />
                                </label>
                                {renderField(
                                    cumulativeQuantitySentOffsetFormPropertyNames.newValueSent,
                                    'webedi.deliverySchedule.cumulativeQuantitySent',
                                    false,
                                )}
                                <ChevronRight backgroundColor={'bg-white'} />
                            </div>
                            <div className={`${columnClasses} border-right-none`}>
                                <label className={'text-size-16 text-color-darkest'}>
                                    <FormattedMessage id={'webedi.label.resultingValues'} />
                                </label>
                                {renderField(
                                    cumulativeQuantitySentOffsetFormPropertyNames.newValueTransit,
                                    'webedi.deliverySchedule.cumulativeQuantityInTransit',
                                    true,
                                )}
                            </div>
                        </div>
                        <div className={'padding-20 bg-white display-flex justify-content-between'}>
                            <div className={'btn-toolbar'}>
                                <button
                                    type={'button'}
                                    className={'btn btn-default'}
                                    onClick={() => props.setShowCumulativeOffsetTable(false)}
                                >
                                    <span className={'rioglyph rioglyph-chevron-left'} />
                                    <FormattedMessage id={'webedi.label.return'} />
                                </button>
                            </div>
                            <div className={'btn-toolbar'}>
                                <button
                                    type={'button'}
                                    className={'btn btn-default'}
                                    onClick={() => props.setShowCumulativeOffsetTable(false)}
                                >
                                    <FormattedMessage id={'webedi.label.cancel'} />
                                </button>
                                {renderSaveButton(values.newValueSent)}
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
};
