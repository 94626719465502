import { FormattedMessage } from 'react-intl';
import { measurementUnitCodeToTranslation } from '../../../../../../../utils';

import { MeasurementUnitCode } from '../../../../../domain/common.types';

export interface UnpackagedPackagesProps {
    groupedHandlingUnits: Array<{
        toBePackagedAmount: number;
        packagedAmount?: number;
        packagingMaterialBoxCode?: string;
    }>;
}

export const UNPACKAGED_PACKAGES_TEST_ID = 'UNPACKAGED_PACKAGES_TEST_ID';
export const AMOUNT_OF_UNPACKAGED_PACKAGES_TEST_ID = 'AMOUNT_OF_UNPACKAGED_PACKAGES_TEST_ID';

export const UnpackagedPackages = (props: UnpackagedPackagesProps) => {
    return (
        <div className={'text-center text-size-16'} data-testid={UNPACKAGED_PACKAGES_TEST_ID}>
            <FormattedMessage id={'webedi.package.unpackagedPackages'} />
            <div className={'padding-20'}>
                {props.groupedHandlingUnits.map((groupedHandlingUnit, index) => {
                    const { toBePackagedAmount, packagedAmount, packagingMaterialBoxCode } = groupedHandlingUnit;
                    const actualPackagedAmount = packagedAmount ? packagedAmount : 0;
                    const unpackagedAmount = Math.min(
                        Math.max(toBePackagedAmount - actualPackagedAmount, 0),
                        toBePackagedAmount,
                    );

                    return (
                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                        <div className={'text-left'} key={index}>
                            <span
                                className={'rioglyph rioglyph-parcel text-size-h3 margin-right-10-lg margin-right-5-sm'}
                            />
                            <span className={'text-size-20'} data-testid={AMOUNT_OF_UNPACKAGED_PACKAGES_TEST_ID}>
                                {unpackagedAmount}&nbsp;
                                {unpackagedAmount === 1 ? (
                                    <FormattedMessage
                                        id={measurementUnitCodeToTranslation(MeasurementUnitCode.PIECE)}
                                    />
                                ) : (
                                    <FormattedMessage id={'webedi.deliveryInstruction.unit.piece.descriptive'} />
                                )}
                                &nbsp;
                                <b>{packagingMaterialBoxCode}</b>
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
