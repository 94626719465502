window.addEventListener('message', (event: MessageEvent) => {
    const messageOrigin = event.origin.replace(/\/$/, ''); // remove trailing slash from src
    if (
        messageOrigin === 'https://user-menu.iam.rio.cloud' &&
        event.data !== undefined &&
        event.data.type !== undefined
    ) {
        const message = event.data;
        switch (message.type) {
            case 'IFRAME_LANGUAGE_CHANGED':
                window.location.reload();
                break;
            default:
                break;
        }
    }
});
