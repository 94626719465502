// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { PackagingTemplateType } from '../../reducers/deliverySchedules/types';
import { Ownership } from '../../reducers/shipments/packaging.types';
import { ApiDimensions, DimensionsCodec } from '../shared/dimensions.types';
import { fromEnum } from '../util';

export interface ApiPackagedArticleGroup {
    delivery_note_number: number;
    delivery_note_position: number;
    quantity: number;
    net_weight_in_kg?: number;
}

export interface ApiPackagedArticleGroupUpdate {
    delivery_note_number: number;
    delivery_note_position: number;
    quantity: number;
}

export type ApiPackageable = ApiHandlingUnitGroup | ApiPackagedArticleGroup;
export type ApiPackageableUpdate = ApiHandlingUnitGroupUpdate | ApiPackagedArticleGroupUpdate;

export interface ApiHandlingUnit {
    id: string;
    type: string;
    description?: string;
    label_number: number;
    contents: ApiPackageable[];
    auxiliary_packaging: ApiAuxiliaryHandlingUnitGroup[];
    is_reusable: boolean;
    ownership: Ownership;
    stacking_factor: number;
    tare_weight_in_kg: number;
    net_weight_in_kg?: number;
    gross_weight_in_kg?: number;
}

export interface ApiHandlingUnitUpdate {
    id: string;
    type: string;
    description?: string;
    label_number: number;
    contents: ApiPackageableUpdate[];
    auxiliary_packaging: ApiAuxiliaryHandlingUnitGroup[];
    is_reusable: boolean;
    ownership: Ownership;
    dimensions: ApiDimensions;
    stacking_factor: number;
    tare_weight_in_kg: number;
}

export interface ApiHandlingUnitGroup {
    quantity: number;
    handling_unit: ApiHandlingUnit;
}

export interface ApiHandlingUnitGroupUpdate {
    quantity: number;
    handling_unit: ApiHandlingUnitUpdate;
}

export interface ApiAuxiliaryHandlingUnitGroup {
    quantity: number;
    auxiliary_packaging_content: ApiAuxiliaryPackaging;
}

export interface ApiAuxiliaryPackaging {
    type: string;
    description?: string;
    auxiliary_packaging: ApiAuxiliaryHandlingUnitGroup[];
    is_reusable: boolean;
    ownership: Ownership;
    stacking_factor: number;
    tare_weight_in_kg: number;
}

export const PackagedArticleGroupCodec: t.Type<ApiPackagedArticleGroup> = t.intersection([
    t.type({
        delivery_note_number: t.number,
        delivery_note_position: t.number,
        quantity: t.number,
    }),
    t.partial({
        net_weight_in_kg: t.number,
    }),
]);

export const PackagedArticleGroupUpdateCodec = t.type({
    delivery_note_number: t.number,
    delivery_note_position: t.number,
    quantity: t.number,
});

export const HandlingUnitGroupCodec: t.Type<ApiHandlingUnitGroup> = t.recursion('HandlingUnitGroup', () =>
    t.type({
        quantity: t.number,
        handling_unit: HandlingUnitCodec,
    }),
);

export const HandlingUnitGroupUpdateCodec: t.Type<ApiHandlingUnitGroupUpdate> = t.recursion(
    'HandlingUnitGroupUpdate',
    () =>
        t.type({
            quantity: t.number,
            handling_unit: HandlingUnitUpdateCodec,
        }),
);

export const HandlingUnitCodec: t.Type<ApiHandlingUnit> = t.recursion('HandlingUnit', () =>
    t.intersection([
        t.type({
            id: t.string,
            type: t.string,
            label_number: t.number,
            contents: t.array(t.union([HandlingUnitGroupCodec, PackagedArticleGroupCodec])),
            auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
            is_reusable: t.boolean,
            ownership: fromEnum<Ownership>('Ownership', Ownership),
            stacking_factor: t.number,
            tare_weight_in_kg: t.number,
        }),
        t.partial({
            description: t.string,
            net_weight_in_kg: t.number,
            gross_weight_in_kg: t.number,
        }),
    ]),
);

export const HandlingUnitUpdateCodec: t.Type<ApiHandlingUnitUpdate> = t.recursion('HandlingUnitUpdate', () =>
    t.intersection([
        t.type({
            id: t.string,
            type: t.string,
            label_number: t.number,
            contents: t.array(t.union([HandlingUnitGroupUpdateCodec, PackagedArticleGroupUpdateCodec])),
            auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
            is_reusable: t.boolean,
            ownership: fromEnum<Ownership>('Ownership', Ownership),
            dimensions: DimensionsCodec,
            stacking_factor: t.number,
            tare_weight_in_kg: t.number,
        }),
        t.partial({
            description: t.string,
        }),
    ]),
);

export const AuxiliaryHandlingUnitGroupCodec: t.Type<ApiAuxiliaryHandlingUnitGroup> = t.recursion(
    'AuxiliaryHandlingUnitGroup',
    () =>
        t.type({
            quantity: t.number,
            auxiliary_packaging_content: AuxiliaryPackagingCodec,
        }),
);

export const AuxiliaryPackagingCodec: t.Type<ApiAuxiliaryPackaging> = t.intersection([
    t.type({
        type: t.string,
        auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
        is_reusable: t.boolean,
        ownership: fromEnum<Ownership>('Ownership', Ownership),
        stacking_factor: t.number,
        tare_weight_in_kg: t.number,
    }),
    t.partial({
        description: t.string,
    }),
]);

export const isApiPackagedArticleGroup = (
    handlingUnitOrPackagedArticle: ApiPackageable | ApiPackageableUpdate,
): handlingUnitOrPackagedArticle is ApiPackagedArticleGroup =>
    (handlingUnitOrPackagedArticle as ApiPackagedArticleGroup | ApiPackagedArticleGroupUpdate).delivery_note_number !==
    undefined;

export const isApiPackagedArticleGroupUpdate = (
    handlingUnitOrPackagedArticle: ApiPackageableUpdate,
): handlingUnitOrPackagedArticle is ApiPackagedArticleGroupUpdate =>
    (handlingUnitOrPackagedArticle as ApiPackagedArticleGroupUpdate).delivery_note_number !== undefined;

export const ApplicablePackagingTemplateCodec = t.type({
    id: t.string,
    name: t.string,
    type: fromEnum<PackagingTemplateType>('PackagingTemplateType', PackagingTemplateType),
});

export const ApplicablePackagingTemplatesCodec = t.type({
    items: t.record(t.string, t.array(ApplicablePackagingTemplateCodec)),
});

export const LoadItemIdentifierCodec = t.type({
    delivery_note_number: t.number,
    delivery_note_position: t.number,
});

export const PackagingTemplateConfigToApplyCodec = t.type({
    identifier: LoadItemIdentifierCodec,
    quantity: t.number,
    template_id: t.string,
});

export const PackagingTemplateConfigsToApplyCodec = t.type({
    items: t.array(PackagingTemplateConfigToApplyCodec),
});

export const PackagingConfigFromTemplatesCodec = t.type({
    items: t.array(HandlingUnitGroupUpdateCodec),
});

export type ApiApplicablePackagingTemplate = t.TypeOf<typeof ApplicablePackagingTemplateCodec>;
export type ApiApplicablePackagingTemplates = t.TypeOf<typeof ApplicablePackagingTemplatesCodec>;
export type ApiPackagingTemplateConfigsToApply = t.TypeOf<typeof PackagingTemplateConfigsToApplyCodec>;
export type ApiPackagingConfigFromTemplates = t.TypeOf<typeof PackagingConfigFromTemplatesCodec>;
