import { HTMLProps } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker } from '../DatePicker';

interface DateFilterProps {
    className?: string;
    labelId?: string;
    dataTestId: string;
    selectedDate?: string;
    onChange: (date?: string) => void;
    isValidDate?: (currentDate?: string) => boolean;
    datePickerClassName?: string;
    clearableInput?: boolean;
}

export const DateFilter = (props: DateFilterProps) => {
    const { labelId, selectedDate, onChange, isValidDate } = props;
    const intl = useIntl();

    const datePickerInputProps: HTMLProps<HTMLInputElement> = {
        placeholder: intl.formatMessage({ id: 'webedi.common.filter.dateSelectionPlaceholder' }),
        id: labelId,
    };
    if (selectedDate === undefined) {
        datePickerInputProps.value = ''; // See https://github.com/arqex/react-datetime/issues/749
    }

    return (
        <div data-testid={props.dataTestId} className={props.className}>
            {labelId && (
                <label htmlFor={labelId}>
                    <FormattedMessage id={labelId} />
                </label>
            )}
            <DatePicker
                inputProps={datePickerInputProps}
                onChange={onChange}
                value={selectedDate}
                isValidDate={isValidDate}
                clearableInput={props.clearableInput !== undefined ? props.clearableInput : true}
                mandatory={props.clearableInput !== undefined ? !props.clearableInput : false}
                className={props.datePickerClassName}
            />
        </div>
    );
};
