import { PackagingMaterialResult, PackagingMaterialState } from '../../reducers/packaging/types';

export enum HandlingUnitCategory {
    PARTS_CONTAINER = 'PARTS_CONTAINER',
    LOAD_CARRIER = 'LOAD_CARRIER',
    PACKAGING_AID = 'PACKAGING_AID',
}

export const HandlingUnitCategoryTranslation = {
    [HandlingUnitCategory.PARTS_CONTAINER]: 'webedi.packaging.category.partsContainer' as const,
    [HandlingUnitCategory.LOAD_CARRIER]: 'webedi.packaging.category.loadCarrier' as const,
    [HandlingUnitCategory.PACKAGING_AID]: 'webedi.packaging.category.packagingAid' as const,
};

export const ExpandRowIconPlaceHolder = () => <span className={'width-20 margin-left-1'} />;

export const findPackagingMaterialResultByBoxCode = (
    boxCode: string | undefined,
    packagingMaterials: PackagingMaterialState['packagingMaterials'],
): PackagingMaterialResult | undefined => {
    return boxCode ? packagingMaterials[boxCode] : undefined;
};
