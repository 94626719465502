import { Field, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { measurementUnitCodeToTranslation, normalizeDecimalsToContainOnlyOneDigit } from '../../../../../../utils';
import { MeasurementUnitCode } from '../../../../domain/common.types';
import { shipmentDocumentsSlice } from '../../../../reducers/shipmentDocuments/ShipmentDocuments.reducer';
import {
    DocumentLanguage,
    ExtraMarginInMm,
    GtlDocumentSettings,
    GtlLabelSize,
    GtlPaperFormat,
    PlantSpecificSettings,
} from '../../../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { Tooltip } from '../../../common/Tooltip';
import { CheckboxInput } from '../../../common/form/CheckboxInput';
import { NumberInput } from '../../../common/form/NumberInput';
import { RadioButtonInput } from '../../../common/form/RadioButtonInput';
import { TextInput } from '../../../common/form/TextInput';
import { ShipmentDocumentSettingsFormValues } from '../../../common/shipmentDocumentSettings/ShipmentDocumentSettingsDialogContent';

export const GLOBAL_TRANSPORT_LABELS_SETTINGS_TEST_ID = 'GLOBAL_TRANSPORT_LABELS_SETTINGS_TEST_ID';
const GLOBAL_TRANSPORT_LABELS_SETTINGS_LANGUAGE_GROUP_TEST_ID =
    'GLOBAL_TRANSPORT_LABELS_SETTINGS_LANGUAGE_GROUP_TEST_ID';
const GLOBAL_TRANSPORT_LABELS_SETTINGS_SPECIAL_SIZES_GROUP_TEST_ID =
    'GLOBAL_TRANSPORT_LABELS_SETTINGS_SPECIAL_SIZES_GROUP_TEST_ID';

export interface GtlSettingsFormValues {
    language: DocumentLanguage;
    labelsForIntermediateLevels: boolean;
    labelSize: GtlLabelSize;
    paperFormat: GtlPaperFormat;
    additionalSmallKltTypes: string | undefined;
    trayTypes: string | undefined;
    extraMarginInMm: ExtraMarginInMm;
    includeLabelsOnlyForOuterMostLevel: boolean;
    plantSpecificSettings: PlantSpecificSettingsFormValues;
}

export type PlantSpecificSettingsFormValues = Omit<PlantSpecificSettings, 'applyForPlants'> & {
    applyForPlants: string;
};

export const generateInitialValuesFromRedux = (settings: GtlDocumentSettings): GtlSettingsFormValues => ({
    language: settings.language,
    labelsForIntermediateLevels: settings.labelsForIntermediateLevels,
    labelSize: settings.labelSize,
    paperFormat: settings.paperFormat,
    additionalSmallKltTypes: convertArrayToCommaSeparatedString(settings.specialSizes.additionalSmallKltTypes),
    trayTypes: convertArrayToCommaSeparatedString(settings.specialSizes.trayTypes),
    extraMarginInMm: settings.extraMarginInMm,
    includeLabelsOnlyForOuterMostLevel: settings.includeLabelsOnlyForOuterMostLevel,
    plantSpecificSettings: {
        ...settings.plantSpecificSettings,
        applyForPlants: convertArrayToCommaSeparatedString(settings.plantSpecificSettings.applyForPlants),
    },
});

const mapFormValuesToRedux = (formValues: GtlSettingsFormValues): GtlDocumentSettings => ({
    language: formValues.language,
    labelsForIntermediateLevels: formValues.labelsForIntermediateLevels,
    labelSize: formValues.labelSize,
    paperFormat: formValues.paperFormat,
    specialSizes: {
        additionalSmallKltTypes: convertCommaSeparatedStringToArray(formValues.additionalSmallKltTypes),
        trayTypes: convertCommaSeparatedStringToArray(formValues.trayTypes),
    },
    extraMarginInMm: formValues.extraMarginInMm,
    includeLabelsOnlyForOuterMostLevel: formValues.includeLabelsOnlyForOuterMostLevel,
    plantSpecificSettings: {
        ...formValues.plantSpecificSettings,
        applyForPlants: convertCommaSeparatedStringToArray(formValues.plantSpecificSettings.applyForPlants),
    },
});

export const generateSubmitAction = (formValues: GtlSettingsFormValues) => {
    const mappedValues: GtlDocumentSettings = mapFormValuesToRedux(formValues);
    return shipmentDocumentsSlice.actions.setGtlDocumentSettings(mappedValues);
};

export const plantSpecificSettingsFieldValues: Record<keyof PlantSpecificSettings, keyof PlantSpecificSettings> = {
    numberOfLabelCopies: 'numberOfLabelCopies',
    applyForPlants: 'applyForPlants',
};

export const extraMarginInMmFieldValues: Record<keyof ExtraMarginInMm, keyof ExtraMarginInMm> = {
    top: 'top',
    bottom: 'bottom',
};

const fieldNames: Record<keyof GtlSettingsFormValues, keyof GtlSettingsFormValues> = {
    language: 'language',
    labelsForIntermediateLevels: 'labelsForIntermediateLevels',
    labelSize: 'labelSize',
    paperFormat: 'paperFormat',
    additionalSmallKltTypes: 'additionalSmallKltTypes',
    trayTypes: 'trayTypes',
    extraMarginInMm: 'extraMarginInMm',
    includeLabelsOnlyForOuterMostLevel: 'includeLabelsOnlyForOuterMostLevel',
    plantSpecificSettings: 'plantSpecificSettings',
};

interface GlobalTransportLabelsSettingsProps {
    inputValuesSlicePath: string;
}

export const EXTRA_MARGIN_IN_MM_TOP_TEST_ID = 'EXTRA_MARGIN_IN_MM_TOP_TEST_ID';
export const EXTRA_MARGIN_IN_MM_BOTTOM_TEST_ID = 'EXTRA_MARGIN_IN_MM_BOTTOM_TEST_ID';

export const GlobalTransportLabelsSettings = (props: GlobalTransportLabelsSettingsProps) => {
    const formState = useFormState<ShipmentDocumentSettingsFormValues>();
    const rowClassName = 'display-flex flex-row form-group';
    const fieldNamesWithPath = Object.fromEntries(
        Object.entries(fieldNames).map(([key, value]) => [key, `${props.inputValuesSlicePath}.${value}`]),
    ) as Record<keyof GtlSettingsFormValues, string>;

    const renderMiscSettingsGroup = () => {
        return (
            <div>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.mergeOptions'} />
                    </label>
                </div>
                <hr />
                <Field<boolean>
                    name={fieldNamesWithPath.labelsForIntermediateLevels}
                    component={CheckboxInput}
                    type={'checkbox'}
                    className={'form-control'}
                >
                    <FormattedMessage id={'webedi.shipmentDocuments.settings.labelsForIntermediateLevels'} />
                </Field>
                <Field<boolean>
                    name={fieldNamesWithPath.includeLabelsOnlyForOuterMostLevel}
                    component={CheckboxInput}
                    type={'checkbox'}
                >
                    <FormattedMessage
                        id={'webedi.shipmentDocuments.settings.includeLabelsOnlyForOuterMostLevelHeader'}
                    />
                </Field>
            </div>
        );
    };

    const renderPlantSpecificSettings = () => {
        const fieldNameNumberOfLabelCopies = `${fieldNamesWithPath.plantSpecificSettings}.${plantSpecificSettingsFieldValues.numberOfLabelCopies}`;

        return (
            <div className={'margin-y-15'}>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.plantSpecificSettings'} />
                    </label>
                </div>
                <hr />
                <div className={`${rowClassName} align-items-end`}>
                    <div className={'width-33pct margin-right-10'}>
                        <label htmlFor={fieldNameNumberOfLabelCopies}>
                            <FormattedMessage
                                id={'webedi.shipmentDocuments.settings.plantSpecificSettings.numberOfLabelCopiesHeader'}
                            />
                        </label>
                        <Field
                            id={fieldNameNumberOfLabelCopies}
                            name={fieldNameNumberOfLabelCopies}
                            component={NumberInput}
                            parse={(value) => parseInt(value, 10)}
                            forbidScientificNotation
                            min={1}
                            steps={1}
                            className={'width-100pct form-control'}
                        />
                    </div>
                    <div className={'width-66pct'}>
                        {renderInputTextFieldWithTooltip(
                            `${fieldNamesWithPath.plantSpecificSettings}.${plantSpecificSettingsFieldValues.applyForPlants}`,
                            'webedi.shipmentDocuments.settings.plantSpecificSettings.plantNumbersTooltip',
                            'webedi.shipmentDocuments.settings.plantSpecificSettings.plantNumbersHeader',
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderInputTextFieldWithTooltip = (fieldName: string, tooltipMessageId: string, fieldLabel: string) => {
        return (
            <div className={'margin-bottom-15'}>
                <Tooltip text={<FormattedMessage id={tooltipMessageId} />} placement={'top'}>
                    <label htmlFor={fieldName}>
                        <FormattedMessage id={fieldLabel} />
                        <span className={'rioglyph rioglyph-info-sign text-size-14 text-color-info padding-left-5'} />
                    </label>
                </Tooltip>
                <Field<string>
                    id={fieldName}
                    name={fieldName}
                    component={TextInput}
                    className={'width-100pct form-control'}
                />
            </div>
        );
    };

    const renderSpecialSizesSettingsGroup = () => {
        return (
            <div data-testid={GLOBAL_TRANSPORT_LABELS_SETTINGS_SPECIAL_SIZES_GROUP_TEST_ID}>
                <div className={`${rowClassName} margin-bottom--15`}>
                    <label className={'text-size-14'}>
                        <FormattedMessage id={'webedi.shipmentDocuments.settings.specialSizes'} />
                    </label>
                </div>
                <hr />

                <div className={'text-size-14 margin-bottom-15'}>
                    <FormattedMessage id={'webedi.shipmentDocuments.settings.smallKLTInfo'} />
                </div>

                {renderInputTextFieldWithTooltip(
                    fieldNamesWithPath.additionalSmallKltTypes,
                    'webedi.shipmentDocuments.settings.typesTooltip',
                    'webedi.shipmentDocuments.settings.additionalSmallKLTTypes',
                )}
                <div className={`${rowClassName} align-items-end gap-10`}>
                    <div className={'width-50pct'}>
                        {renderInputTextFieldWithTooltip(
                            fieldNamesWithPath.trayTypes,
                            'webedi.shipmentDocuments.settings.typesTooltip',
                            'webedi.shipmentDocuments.settings.trayTypes',
                        )}
                    </div>
                    <div className={'width-50pct'}>
                        <label>
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.extraMarginInMmHeader'} />
                        </label>
                        <div className={`display-flex flex-row gap-10`}>
                            <Field<number>
                                data-testid={EXTRA_MARGIN_IN_MM_TOP_TEST_ID}
                                name={`${fieldNamesWithPath.extraMarginInMm}.${extraMarginInMmFieldValues.top}`}
                                component={NumberInput}
                                className={'width-100pct form-control'}
                                step={0.1}
                                digitPrecision={1}
                                min={0}
                                inputGroupAddonRight={
                                    <FormattedMessage
                                        id={measurementUnitCodeToTranslation(MeasurementUnitCode.MILLIMETRE)}
                                    />
                                }
                                parse={(value) => normalizeDecimalsToContainOnlyOneDigit(value)}
                                forbidScientificNotation
                                disabled={!formState.values.gtl.trayTypes}
                            />
                            <Field<number>
                                data-testid={EXTRA_MARGIN_IN_MM_BOTTOM_TEST_ID}
                                name={`${fieldNamesWithPath.extraMarginInMm}.${extraMarginInMmFieldValues.bottom}`}
                                component={NumberInput}
                                className={'width-100pct form-control'}
                                step={0.1}
                                digitPrecision={1}
                                min={0}
                                inputGroupAddonRight={
                                    <FormattedMessage
                                        id={measurementUnitCodeToTranslation(MeasurementUnitCode.MILLIMETRE)}
                                    />
                                }
                                parse={(value) => normalizeDecimalsToContainOnlyOneDigit(value)}
                                forbidScientificNotation
                                disabled={!formState.values.gtl.trayTypes}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div data-testid={GLOBAL_TRANSPORT_LABELS_SETTINGS_TEST_ID}>
            <div>
                <div data-testid={GLOBAL_TRANSPORT_LABELS_SETTINGS_LANGUAGE_GROUP_TEST_ID}>
                    <div className={`${rowClassName} margin-bottom--15`}>
                        <label className={'text-size-14'}>
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.documentLanguage'} />
                        </label>
                    </div>
                    <hr />

                    <div className={`${rowClassName} justify-content-start ellipsis-1`}>
                        <label className={'width-33pct'}>
                            <Field<DocumentLanguage>
                                type={'radio'}
                                name={fieldNamesWithPath.language}
                                component={RadioButtonInput}
                                value={DocumentLanguage.GERMAN}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.documentLanguage.german'} />
                            </Field>
                        </label>

                        <label className={'width-33pct'}>
                            <Field<DocumentLanguage>
                                type={'radio'}
                                name={fieldNamesWithPath.language}
                                component={RadioButtonInput}
                                value={DocumentLanguage.ENGLISH}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.documentLanguage.english'} />
                            </Field>
                        </label>
                    </div>
                </div>
                <div>
                    <div className={`${rowClassName} margin-bottom--15`}>
                        <label className={'text-size-14'}>
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.labelSize'} />
                        </label>
                    </div>
                    <hr />

                    <div className={`${rowClassName} justify-content-start ellipsis-1`}>
                        <label className={'width-33pct'}>
                            <Field<GtlLabelSize>
                                type={'radio'}
                                name={fieldNamesWithPath.labelSize}
                                component={RadioButtonInput}
                                value={GtlLabelSize.DIN_A5}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.DINA5'} />
                            </Field>
                        </label>

                        <label className={'width-33pct'}>
                            <Field<GtlLabelSize>
                                type={'radio'}
                                name={fieldNamesWithPath.labelSize}
                                component={RadioButtonInput}
                                value={GtlLabelSize.AIAG_B10}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.AIAG'} />
                            </Field>
                        </label>
                    </div>
                </div>
                <div>
                    <div className={`${rowClassName} margin-bottom--15`}>
                        <label className={'text-size-14'}>
                            <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat'} />
                        </label>
                    </div>
                    <hr />

                    <div className={`${rowClassName} justify-content-start ellipsis-1`}>
                        <label className={'width-33pct'}>
                            <Field<GtlPaperFormat>
                                type={'radio'}
                                name={fieldNamesWithPath.paperFormat}
                                component={RadioButtonInput}
                                value={GtlPaperFormat.DIN_A4}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.DINA4'} />
                            </Field>
                        </label>
                        <label className={'width-33pct'}>
                            <Field<GtlPaperFormat>
                                type={'radio'}
                                name={fieldNamesWithPath.paperFormat}
                                component={RadioButtonInput}
                                value={GtlPaperFormat.DIN_A5_PORTRAIT}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.DINA5'} />
                            </Field>
                        </label>
                        <label className={'width-33pct'}>
                            <Field<GtlPaperFormat>
                                type={'radio'}
                                name={fieldNamesWithPath.paperFormat}
                                component={RadioButtonInput}
                                value={GtlPaperFormat.DIN_A5_LANDSCAPE}
                            >
                                <FormattedMessage id={'webedi.shipmentDocuments.settings.paperFormat.DINA5Landscape'} />
                            </Field>
                        </label>
                    </div>
                </div>

                {renderMiscSettingsGroup()}
                {renderPlantSpecificSettings()}
                {renderSpecialSizesSettingsGroup()}
            </div>
        </div>
    );
};

export const convertArrayToCommaSeparatedString = (input: string[]): string => {
    return input.join(', ');
};

export const convertCommaSeparatedStringToArray = (input: string | undefined): string[] => {
    if (input === undefined) {
        return [];
    }
    return input
        .split(',')
        .map((it) => it.trim())
        .filter((it) => it.length > 0);
};
