import { ReactElement } from 'react';

interface BannerProps {
    type: string;
    messages: ReactElement[];
}

export const Banner = (props: BannerProps) => {
    const { type, messages } = props;

    return (
        <div className={`alert alert-dismissible alert-${type} margin-bottom-25`}>
            <div className={'display-flex'}>
                <span className={`text-color-${type} text-size-h4 margin-right-10 rioglyph rioglyph-info-sign`} />
                <div>
                    {messages.map((message, index) => {
                        return (
                            // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                            <div key={`message-${index}`} className={'margin-bottom-10 last-child-margin-bottom-0'}>
                                {message}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
