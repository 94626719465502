import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum ApiErrorCode {
    TERMS_OF_DELIVERY_INVALID = 'S01',
    FREIGHT_FORWARDER_UNDEFINED = 'S02',
    MISSING_FREIGHT_FORWARDER_PARTY_IDENTIFIER_BUYER = 'S03',
    MISSING_FREIGHT_FORWARDER_PARTY_IDENTIFIER_SELLER = 'S04',
    MISSING_CUSTOMS_PREFERENCE_AUTHORIZATION = 'S05',
    DESPATCH_DATE_UNDEFINED = 'S07',
    MISSING_MEANS_OF_TRANSPORT_MODE_PROPERTY = 'S08',
    MISSING_MARITIME_MODE_PROPERTIES = 'S09',
    MISSING_ROAD_MODE_PROPERTIES = 'S10',
    MISSING_RAIL_MODE_PROPERTIES = 'S11',
    MISSING_AIR_MODE_PROPERTIES = 'S12',
    MISSING_MAIL_MODE_PROPERTIES = 'S13',
    MISSING_MULTIMODAL_MODE_PROPERTIES = 'S14',
    MISSING_DELIVERY_DATE_PROPERTY = 'S15',
    MISSING_ARTICLE_MASTER_DATA_PROPERTY = 'S30',
    DUPLICATE_SHIPMENT_NUMBER = 'S31',
    DUPLICATE_DELIVERY_NOTE_NUMBER = 'S32',
    DUPLICATE_LABEL_NUMBER = 'S33',
    DUPLICATE_KEY = 'S34',
    MISSING_COUNTRY_OF_ORIGIN_PROPERTY = 'S36',
    INVALID_ARTICLE_MASTER_DATA_PROPERTY = 'S37',
    INVALID_CUMULATIVE_QUANTITIES_DATA_PROPERTY = 'S38',
    INVALID_MASTER_DATA_UPDATE = 'D01',
    PORSCHE_SHIPMENT_MISSING_TRANSPORT_ORDER_NUMBER = 'S39',
    SHIPMENT_EMPTY_LOAD = 'S40',
    PORSCHE_SHIPMENT_DESPATCH_DATE_INVALID_PROPERTY = 'S41',
    PORSCHE_SHIPMENT_INVALID_STATE = 'S42',
    PORSCHE_SHIPMENT_INVALID_FREIGHT_FORWARDER_MISSING_BUYER_PROPERTY = 'S49',
    PORSCHE_SHIPMENT_INVALID_MEANS_OF_TRANSPORT_PROPERTY = 'S51',
    SHIPMENT_INCONSISTENT_PACKAGING = 'S52',
    PORSCHE_SHIPMENT_INVALID_REQUESTED_DELIVERY_PROPERTY = 'S53',
    SHIPMENT_DESPATCH_DATE_INVALID_VALUE = 'S54',
    SHIPMENT_MARITIME_MEANS_OF_TRANSPORT_INVALID_VALUE = 'S55',
    SHIPMENT_GROSS_WEIGHT_INVALID_VALUE = 'S61',
}

export interface UIState {
    error: undefined | ApiErrorCode;
    transportOrderViewErrors: undefined | Record<string, string>;
}

const initialState: UIState = {
    error: undefined,
    transportOrderViewErrors: {},
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setError: (state: UIState, action: PayloadAction<ApiErrorCode>) => {
            state.error = action.payload;
        },
        clearError: (state: UIState) => {
            state.error = undefined;
        },
        setTransportOrderViewErrors: (state: UIState, action: PayloadAction<Record<string, string>>) => {
            state.transportOrderViewErrors = action.payload;
        },
        clearTransportOrderViewErrors: (state: UIState) => {
            state.transportOrderViewErrors = undefined;
        },
    },
});
