import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import UikitTooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { TextAlignment } from '@rio-cloud/rio-uikit/lib/es/values/TextAlignment';
import { ReactNode } from 'react';

interface TooltipProps {
    text: string | ReactNode;
    width?: 'auto' | 100 | 150 | 200 | 250 | 300 | 350 | 400 | 450 | 500;
    children: ReactNode;
    placement: 'top' | 'bottom' | 'right' | 'left' | 'auto' | 'bottom-start';
    extraClasses?: string;
    textAlignment?: TextAlignment;
}

export const Tooltip = (props: TooltipProps) => {
    return (
        <OverlayTrigger
            placement={props.placement}
            overlay={
                <UikitTooltip
                    id={'tooltip'}
                    className={`props.placement ${props.extraClasses}`}
                    textAlignment={props.textAlignment ?? 'center'}
                    width={props.width || 'auto'}
                >
                    {props.text}
                </UikitTooltip>
            }
        >
            {props.children}
        </OverlayTrigger>
    );
};
