import { retry } from '@lifeomic/attempt';
// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

// this utility function can be used to turn a TypeScript enum into a io-ts codec.
export const fromEnum = <EnumType>(enumName: string, theEnum: Record<string, string | number>) => {
    const isEnumValue = (input: unknown): input is EnumType => Object.values<unknown>(theEnum).includes(input);

    return new t.Type<EnumType>(
        enumName,
        isEnumValue,
        (input, context) => (isEnumValue(input) ? t.success(input) : t.failure(input, context)),
        t.identity,
    );
};

type SymmetricalEnum<TEnum> = {
    [Key in keyof TEnum]: Key;
};

type MapperResult<
    TSourceEnumObj,
    TDestEnumObj extends SymmetricalEnum<TSourceEnumObj>,
    TSourceValue extends keyof TSourceEnumObj,
> = TDestEnumObj extends { [Key in TSourceValue]: infer TResult } ? TResult : never;

export const createEnumMapper =
    <TSourceEnumObj, TDestEnumObj extends SymmetricalEnum<TSourceEnumObj>>(_from: TSourceEnumObj, _to: TDestEnumObj) =>
    <TInput extends keyof TSourceEnumObj>(value: TInput) =>
        value as MapperResult<TSourceEnumObj, TDestEnumObj, TInput>;

export const defaultTimeoutInMs = 5 * 60 * 1000;
export const defaultFixedDelayInMs = 10 * 1000;

export const waitByRetryingForResponseToBeAvailable = (
    callFunction: () => Promise<void>,
    fixedDelayInMs: number = defaultFixedDelayInMs,
    timeoutInMs: number = defaultTimeoutInMs,
): Promise<void> =>
    retry(
        callFunction,
        // The attempt library doesn't support a 'global' timeout. It only allows attempt-specific timeouts,
        // where the already exceeded time of previous attempts isn't taken into account
        // To simulate a 'global' timeout, maxAttempts must be used
        {
            delay: fixedDelayInMs,
            initialDelay: 0,
            maxAttempts: Math.ceil(timeoutInMs / fixedDelayInMs),
        },
    );
