import { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { MeasurementUnitCode } from '../../../../../domain/common.types';
import { useImage } from '../../../../../hooks/useImage';
import { PackagingMaterial } from '../../../../../reducers/packaging/types';
import { MeasurementUnitCodeDisplay } from '../../../../common/MeasurementUnitCodeDisplay';
import { CheckboxInput } from '../../../../common/form/CheckboxInput';
import {
    NumberInput,
    getNumberInputParseFunction,
    isDecimalBasedMeasurementUnit,
} from '../../../../common/form/NumberInput';
import { ManyHandlingUnitsWarning } from '../ManyHandlingUnitsWarning';
import { formIds, formPropertyNamesConfigurationStep } from './InnerPackagingFormConfig';

export interface InnerPackagingArticleCreationProps {
    handleSubmit: () => void;
    articleNumber: string;
    formId: string;
    packagingMaterial: PackagingMaterial;
    measurementUnitCode: MeasurementUnitCode;
    values: {
        calculateNumberOfHandlingUnits?: boolean;
        numberOfHandlingUnits?: number;
    };
}

export const InnerPackagingArticleCreation = (props: InnerPackagingArticleCreationProps) => {
    const { values, articleNumber, packagingMaterial, measurementUnitCode } = props;
    const measurementUnitCodeTranslation = <MeasurementUnitCodeDisplay unit={measurementUnitCode} />;

    const handlingUnitWithArticlesImage = useImage('handlingUnitWithArticles');
    const highlightedHandlingUnitImage = useImage('highlightedHandlingUnit');

    return (
        <div className={'display-flex flex-column'}>
            <span className={'text-size-18 text-center margin-bottom-25'}>
                <FormattedMessage id={'webedi.packaging.innerPackaging.step2.title'} />
            </span>
            <span className={'margin-bottom-10'}>
                <FormattedMessage
                    id={'webedi.packaging.innerPackaging.step2.explanation'}
                    values={{
                        packagingMaterial: packagingMaterial.boxCode,
                        articleNumber,
                    }}
                />
            </span>
            <div className={'text-left padding-top-10 margin-bottom-25'}>
                <Field
                    id={formIds.calculateNumberOfHandlingUnits}
                    name={formPropertyNamesConfigurationStep.calculateNumberOfHandlingUnits}
                    component={CheckboxInput}
                    type={'checkbox'}
                    tabIndex={-1}
                >
                    <FormattedMessage id={'webedi.packaging.inner.calculateNumberOfHandlingUnits'} />
                </Field>
            </div>
            <form id={props.formId} onSubmit={props.handleSubmit}>
                <div className={'display-flex flex-row align-items-center'}>
                    <div className={'flex-column width-40pct'}>
                        <img
                            src={handlingUnitWithArticlesImage}
                            className={'width-50pct height-50pct'}
                            alt={'handlingUnitWithArticles'}
                        />
                    </div>
                    <div className={`flex-column width-60pct counter color-highlight`}>
                        <div className={'invisible min-height-auto margin-0'} />
                        <div className={'display-flex flex-column align-items-baseline'}>
                            <label
                                className={'text-size-14 text-color-darkest'}
                                htmlFor={formIds.quantityValuePerHandlingUnit}
                            >
                                <FormattedMessage
                                    id={'webedi.packaging.inner.articlesPerHandlingUnit.label'}
                                    values={{
                                        measurementUnitCode: measurementUnitCodeTranslation,
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                    }}
                                />
                            </label>
                            <Field
                                id={formIds.quantityValuePerHandlingUnit}
                                name={formPropertyNamesConfigurationStep.quantityValuePerHandlingUnit}
                                component={NumberInput}
                                className={'form-control'}
                                measurementUnitCode={measurementUnitCode}
                                parse={getNumberInputParseFunction(measurementUnitCode)}
                                forbidScientificNotation
                                min={isDecimalBasedMeasurementUnit(measurementUnitCode) ? 0 : 1}
                                outerClassName={'width-100pct'}
                                errorMessageClassName={'text-size-10'}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
                <hr className={'padding-top-10 padding-bottom-10'} />
                <div className={'display-flex flex-row align-items-center'}>
                    <>
                        <div className={'flex-column width-40pct'}>
                            <img
                                src={highlightedHandlingUnitImage}
                                className={'width-50pct height-50pct'}
                                alt={'handlingUnitWithArticles'}
                            />
                        </div>
                        <div className={'width-60pct counter color-highlight'}>
                            <div className={'invisible min-height-auto margin-0'} />
                            <div className={'invisible min-height-auto margin-0'} />
                            <div className={'display-flex flex-column align-items-baseline'}>
                                <label
                                    className={'text-size-14 text-color-darkest'}
                                    htmlFor={formIds.numberOfHandlingUnits}
                                >
                                    <FormattedMessage
                                        id={'webedi.packaging.inner.numberOfHandlingUnits.label'}
                                        values={{
                                            measurementUnitCode: measurementUnitCodeTranslation,
                                            b: (chunks: ReactNode) => <b>{chunks}</b>,
                                        }}
                                    />
                                </label>
                                <Field
                                    id={formIds.numberOfHandlingUnits}
                                    name={formPropertyNamesConfigurationStep.numberOfHandlingUnits}
                                    component={NumberInput}
                                    className={'form-control'}
                                    measurementUnitCode={measurementUnitCode}
                                    parse={getNumberInputParseFunction(measurementUnitCode)}
                                    forbidScientificNotation
                                    min={1}
                                    step={1}
                                    digitPrecision={0}
                                    outerClassName={'width-100pct'}
                                    errorMessageClassName={'text-size-10'}
                                    disabled={values.calculateNumberOfHandlingUnits === true}
                                />
                            </div>
                        </div>
                    </>
                </div>
                <div className={'margin-top-25'}>
                    <ManyHandlingUnitsWarning numberOfHandlingUnits={values.numberOfHandlingUnits} />
                </div>
            </form>
        </div>
    );
};
