// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { PackagingStepType } from '../../reducers/deliverySchedules/types';
import { Ownership } from '../../reducers/shipments/packaging.types';
import { DimensionsCodec } from '../shared/dimensions.types';
import { quantityCodec } from '../shared/quantity.types';
import { AuxiliaryHandlingUnitGroupCodec } from '../shipment/packaging.types';
import { fromEnum } from '../util';

const HeterogeneousPackagingStepContentCodec = t.intersection([
    t.type({
        handling_unit_type_to_be_packaged: t.string,
        number_of_handling_units_per_handling_unit: t.number,
        is_reusable: t.boolean,
        ownership: fromEnum<Ownership>('Ownership', Ownership),
        stacking_factor: t.number,
        tare_weight_in_kg: t.number,
    }),
    t.partial({
        handling_unit_description_to_be_packaged: t.string,
    }),
]);

const HomogeneousPackagingStepLayerStabilizationCodec = t.intersection([
    t.type({
        handling_unit_type: t.string,
        required: t.boolean,
        auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
        is_reusable: t.boolean,
        ownership: fromEnum<Ownership>('Ownership', Ownership),
        stacking_factor: t.number,
        tare_weight_in_kg: t.number,
    }),
    t.partial({
        handling_unit_description: t.string,
    }),
]);

const HeterogeneousPackagingStepCodec = t.intersection([
    t.type({
        id: t.string,
        type: t.literal(PackagingStepType.HETEROGENEOUS),
        auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
        prerequisites: t.array(t.string),
        type_of_handling_unit: t.string,
        content: t.array(HeterogeneousPackagingStepContentCodec),
        is_reusable: t.boolean,
        ownership: fromEnum<Ownership>('Ownership', Ownership),
        stacking_factor: t.number,
        tare_weight_in_kg: t.number,
    }),
    t.partial({
        description_of_handling_unit: t.string,
    }),
]);

const HomogeneousPackagingStepCodec = t.intersection([
    t.type({
        id: t.string,
        type: t.literal(PackagingStepType.HOMOGENEOUS),
        auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
        prerequisites: t.array(t.string),
        type_of_handling_unit: t.string,
        handling_unit_type_to_be_packaged: t.string,
        number_of_handling_units_per_layer: t.number,
        number_of_layers_per_handling_unit: t.number,
        layer_stabilization: HomogeneousPackagingStepLayerStabilizationCodec,
        is_reusable: t.boolean,
        ownership: fromEnum<Ownership>('Ownership', Ownership),
        stacking_factor: t.number,
        tare_weight_in_kg: t.number,
    }),
    t.partial({
        description_of_handling_unit: t.string,
        handling_unit_description_to_be_packaged: t.string,
    }),
]);

const InnerPackagingStepCodec = t.intersection([
    t.type({
        id: t.string,
        type: t.literal(PackagingStepType.INNER),
        auxiliary_packaging: t.array(AuxiliaryHandlingUnitGroupCodec),
        prerequisites: t.array(t.string),
        type_of_handling_unit: t.string,
        article_number_buyer: t.string,
        quantity: quantityCodec,
        tare_weight_in_kg: t.number,
        ownership: fromEnum<Ownership>('Ownership', Ownership),
        is_reusable: t.boolean,
        stacking_factor: t.number,
    }),
    t.partial({
        description_of_handling_unit: t.string,
    }),
]);

const PackagingStepCodec = t.union([
    HeterogeneousPackagingStepCodec,
    HomogeneousPackagingStepCodec,
    InnerPackagingStepCodec,
]);

export const PackagingTemplateCodec = t.type({
    id: t.string,
    name: t.string,
    type: t.string,
    duns_number_owner: t.string,
    is_valid: t.boolean,
    referenced_delivery_schedule_ids: t.array(t.string),
    steps: t.array(PackagingStepCodec),
    dimensions: DimensionsCodec,
});

export const PackagingTemplatesCodec = t.exact(
    t.type({
        items: t.array(PackagingTemplateCodec),
    }),
);

export const PackagingTemplateCreationRequestCodec = t.intersection([
    t.type({
        type: t.string,
        referenced_delivery_schedule_ids: t.array(t.string),
        steps: t.array(PackagingStepCodec),
        dimensions: DimensionsCodec,
    }),
    t.partial({
        name: t.string,
    }),
]);

export const PackagingTemplateUpdateRequestCodec = t.intersection([
    t.type({
        steps: t.array(PackagingStepCodec),
        dimensions: DimensionsCodec,
    }),
    t.partial({
        name: t.string,
    }),
]);

export type ApiPackagingTemplateCreationRequest = t.TypeOf<typeof PackagingTemplateCreationRequestCodec>;
export type ApiPackagingTemplateUpdateRequest = t.TypeOf<typeof PackagingTemplateUpdateRequestCodec>;
export type ApiHeterogeneousPackagingStepContent = t.TypeOf<typeof HeterogeneousPackagingStepContentCodec>;
export type ApiStepLayerStabilization = t.TypeOf<typeof HomogeneousPackagingStepLayerStabilizationCodec>;
export type ApiPackagingStep = t.TypeOf<typeof PackagingStepCodec>;
export type ApiPackagingTemplate = t.TypeOf<typeof PackagingTemplateCodec>;
export type ApiPackagingTemplates = t.TypeOf<typeof PackagingTemplatesCodec>;
