import MaintenanceState from '@rio-cloud/rio-uikit/MaintenanceState';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

export const MaintenancePage = () => {
    const navigate = useNavigate();
    const returnToInitialPage = () => navigate('/');

    return (
        <MaintenanceState
            headline={<FormattedMessage id={'webedi.errorPage.maintenance.heading'} />}
            message={<FormattedMessage id={'webedi.errorPage.maintenance.message'} />}
            buttons={[
                {
                    text: <FormattedMessage id={'webedi.label.return'} />,
                    onClick: returnToInitialPage,
                },
            ]}
        />
    );
};
