import { useEffect, useState } from 'react';
import { IdentityProvider, config } from '../../../config';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';

export const useImage = (imageName: string) => {
    const rioAssetsPath = 'rio';
    const vwAssetsPath = 'vw';
    const [imageSrc, setImageSrc] = useState();

    useEffect(() => {
        const isRioTheme = config.identityProvider === IdentityProvider.RIO;
        const imagePath = isRioTheme ? rioAssetsPath : vwAssetsPath;

        const fetchImage = async () => {
            try {
                const response = await import(`../../../assets/${imagePath}/images/${imageName}.png`);
                setImageSrc(response.default);
            } catch (err) {
                reportErrorToSentry(err);
            }
        };

        fetchImage();
    }, [imageName]);
    return imageSrc;
};
