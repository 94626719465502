import { OptionalType } from '../types';

export enum DocumentLanguage {
    GERMAN = 'DE',
    ENGLISH = 'EN',
}

export enum GtlLabelSize {
    DIN_A5 = 'DIN_A5',
    AIAG_B10 = 'AIAG_B10',
}

export enum GtlPaperFormat {
    DIN_A4 = 'DIN_A4',
    DIN_A5_LANDSCAPE = 'DIN_A5_LANDSCAPE',
    DIN_A5_PORTRAIT = 'DIN_A5_PORTRAIT',
}

export enum TsbPaperFormat {
    DIN_A4 = 'DIN_A4',
    US_LEGAL = 'US_LEGAL',
}

export interface ExtraMarginInMm {
    bottom: number;
    top: number;
}

export interface PlantSpecificSettings {
    numberOfLabelCopies: number;
    applyForPlants: string[];
}

export interface TsbDocumentSettings {
    language: DocumentLanguage;
    comment: string;
    includeReceiptInformation: boolean;
    mergePackages: boolean;
    mergeLoadingUnits: boolean;
    paperFormat: TsbPaperFormat;
    copies: {
        numShipmentMasterSheets: number;
        numShipmentPositionSheets: number;
        numBarcodeSheets: number;
        numLoadingUnitSheets: number;
    };
}

export interface GtlDocumentSettings {
    language: DocumentLanguage;
    labelsForIntermediateLevels: boolean;
    labelSize: GtlLabelSize;
    paperFormat: GtlPaperFormat;
    specialSizes: {
        additionalSmallKltTypes: string[];
        trayTypes: string[];
    };
    extraMarginInMm: ExtraMarginInMm;
    includeLabelsOnlyForOuterMostLevel: boolean;
    plantSpecificSettings: PlantSpecificSettings;
}

export type GtlDocumentSettingsWithOptionalFields = OptionalType<
    GtlDocumentSettings,
    'extraMarginInMm' | 'includeLabelsOnlyForOuterMostLevel' | 'plantSpecificSettings'
>;

export interface DocumentSettingsWithOptionalFields {
    tsbSettings: TsbDocumentSettings;
    gtlSettings: GtlDocumentSettingsWithOptionalFields;
}

export interface DocumentSettings {
    tsbSettings: TsbDocumentSettings;
    gtlSettings: GtlDocumentSettings;
}

export interface ShipmentDocumentsRequest {
    asnUrl: string;
    documentSettings: DocumentSettings;
    shipmentNumber: number;
}

export interface ShipmentDocument {
    url: string;
    hasWarnings: boolean;
}

export interface ShipmentDocuments {
    tsbDocument: ShipmentDocument;
    gtlDocument: ShipmentDocument;
}

export interface ShipmentDocumentsState {
    documents: Record<string, ShipmentDocuments>;
    isGeneratingShipmentDocumentsFor: Record<string, boolean>;
    documentSettings: DocumentSettings;
}

export interface DeliveryForecastDocumentSettings {
    documentLanguage: DocumentLanguage;
    articleNumberBuyer?: string;
}

export interface DeliveryForecastDocumentSettings {
    documentLanguage: DocumentLanguage;
}
