import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useIntl } from 'react-intl';

export const LoadingIndicator = () => {
    const intl = useIntl();

    return (
        <div className={'display-flex justify-content-center'}>
            <Spinner text={intl.formatMessage({ id: 'webedi.loading' })} />
        </div>
    );
};
