import { Field } from 'react-final-form';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { normalizeToMaxLength } from '../../../../../../utils';
import { hasDeliveryNoteNumberError, hasShipmentNumberError } from '../../../../selectors/ui/UI.selector';
import { Tooltip } from '../../../common/Tooltip';
import { BorderlessNumberInput } from '../../../common/form/BorderlessNumberInput';
import { VALIDATION_ERROR_ICON_TEST_ID } from '../../../common/form/FormErrorIcon';

interface EditableNumberFieldProps {
    customError?: string;
    path: string;
    value: number;
    inputInnerClasses: string;
}

type EditableShipmentNumberFieldProps = Omit<EditableNumberFieldProps, 'customError'>;
type EditableDeliveryNoteNumberFieldProps = Omit<EditableNumberFieldProps, 'customError'>;

const ErrorIcon = (props: { error: string }) => (
    <Tooltip text={props.error} placement={'top'}>
        <div>
            <span
                data-testid={VALIDATION_ERROR_ICON_TEST_ID}
                className={'rioglyph rioglyph-error-sign text-color-danger text-size-18'}
            />
        </div>
    </Tooltip>
);

export const EditableShipmentNumberField = (props: EditableShipmentNumberFieldProps) => {
    const isShipmentNumberInvalid = useAppSelector(hasShipmentNumberError);

    const validateShipmentNumber = () => {
        if (isShipmentNumberInvalid) {
            return 'Shipment Number is invalid';
        } else {
            return undefined;
        }
    };

    return <EditableNumberField customError={validateShipmentNumber()} {...props} />;
};

export const EditableDeliveryNoteNumberField = (props: EditableDeliveryNoteNumberFieldProps) => {
    const isDeliveryNoteNumberInvalid = useAppSelector(hasDeliveryNoteNumberError);

    const validateDeliveryNoteNumber = () => {
        if (isDeliveryNoteNumberInvalid) {
            return 'Delivery Note Numbers are invalid';
        } else {
            return undefined;
        }
    };

    const formPath = `${props.path}.deliveryNoteNumber`;
    return <EditableNumberField customError={validateDeliveryNoteNumber()} {...props} path={formPath} />;
};

const EditableNumberField = (props: EditableNumberFieldProps) => {
    const { customError, path, value, inputInnerClasses } = props;

    const inputOuterClassNames = () => {
        return ['display-flex', 'align-items-center', 'borderless-input', 'no-control-arrows', 'text-size-14'].join(
            ' ',
        );
    };

    return (
        <div className={inputOuterClassNames()}>
            <div className={'input-group'}>
                <Field<number>
                    name={path}
                    component={BorderlessNumberInput}
                    customError={customError}
                    parse={(newValue) => normalizeToMaxLength(newValue.toString())}
                    initialValue={value}
                    className={`padding-right-25 margin-right-5 margin-left-10 form-control ${inputInnerClasses}`}
                    suppressErrorBlock={true}
                    key={path}
                    forbidScientificNotation
                    zeroPadDigits={8}
                    errorIcon={<ErrorIcon error={customError!} />}
                />
            </div>
        </div>
    );
};
