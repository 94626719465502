import { useEffect } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

export const useCrossValidation = <T extends FieldValues>(src: Path<T>, target: Path<T>) => {
    const { watch, trigger, getFieldState } = useFormContext<T>();
    const srcFormValue = watch(src);
    const targetIsTouched = getFieldState(target).isTouched;

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (targetIsTouched) {
            void trigger(target);
        }
    }, [srcFormValue, targetIsTouched, target, trigger]);
};
