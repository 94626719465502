import { SelectOption } from '@rio-cloud/rio-uikit';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { dateTimeToLocalDate, localDateToLocalStartOfDayInUtc } from '../../../../dateUtils';
import {
    fetchAndStoreDeliveryScheduleFilterValues,
    fetchAndStoreDeliverySchedules,
} from '../../actions/deliverySchedules/DeliverySchedules.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { DeliverySchedulesQueryParameters } from '../../reducers/deliverySchedules/types';
import {
    getDeliverySchedulesActiveFilters,
    getDeliverySchedulesFilterValues,
} from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { DateFilter } from '../common/filter/DateFilter';
import { FilterDropdown } from '../common/filter/FilterDropdown';
import { getBuyerName } from './BuyerAbbreviationsMappings';

const SUPPLIER_IDENTIFIER_KEY = 'SUPPLIER_IDENTIFIER';
const PLANT_NUMBER_KEY = 'PLANT_NUMBER';
const UNLOADING_POINT_KEY = 'UNLOADING_POINT';
const STORAGE_PLACE_KEY = 'STORAGE_PLACE';
const ORDER_NUMBER_KEY = 'ORDER_NUMBER';
const MANUFACTURING_COMPANY_KEY = 'MANUFACTURING_COMPANY';

const DELIVERY_DATE_PICKER_TEST_ID = 'DELIVERY_DATE_PICKER_TEST_ID';

export const DeliverySchedulesFilterRow = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const activeFilters = useAppSelector(getDeliverySchedulesActiveFilters);

    useEffect(() => {
        dispatch(fetchAndStoreDeliveryScheduleFilterValues(dunsNumber!));
    }, [dunsNumber, dispatch]);

    const onChange = (queryChange: Partial<DeliverySchedulesQueryParameters>) => {
        dispatch(fetchAndStoreDeliverySchedules({ offset: 0, ...queryChange }));
    };

    const filterValues = useAppSelector(getDeliverySchedulesFilterValues);

    const getFilterOptionsForKey = (key: string, currentValue: string | undefined): SelectOption[] => {
        const values = filterValues[key] || [];
        return values.map((value) => ({
            id: value,
            label: getBuyerName(value),
            selected: value === currentValue,
        }));
    };

    const filterClassName = 'flex-1-1 form-group margin-10';
    return (
        <>
            <div className={'display-flex flex-wrap'}>
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(SUPPLIER_IDENTIFIER_KEY, activeFilters.supplierIdentifier)}
                    labelId={'webedi.common.shipFrom.identifier'}
                    onChange={(supplierIdentifier) => onChange({ supplierIdentifier })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(PLANT_NUMBER_KEY, activeFilters.plantNumber)}
                    labelId={'webedi.plantIdentifier'}
                    onChange={(plantNumber) => onChange({ plantNumber })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(UNLOADING_POINT_KEY, activeFilters.unloadingPoint)}
                    labelId={'webedi.common.placeOfDischarge'}
                    onChange={(unloadingPoint) => onChange({ unloadingPoint })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(STORAGE_PLACE_KEY, activeFilters.storagePlace)}
                    labelId={'webedi.deliverySchedule.placeOfDelivery'}
                    onChange={(storagePlace) => onChange({ storagePlace })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(ORDER_NUMBER_KEY, activeFilters.orderNumber)}
                    labelId={'webedi.orderNumber'}
                    onChange={(orderNumber) => onChange({ orderNumber })}
                />
                <FilterDropdown
                    className={filterClassName}
                    dropdownOptions={getFilterOptionsForKey(
                        MANUFACTURING_COMPANY_KEY,
                        activeFilters.manufacturingCompany,
                    )}
                    labelId={'webedi.common.manufacturingCompany'}
                    onChange={(manufacturingCompany) => onChange({ manufacturingCompany })}
                />
                <DateFilter
                    className={filterClassName}
                    labelId={'webedi.common.earliestDeliveryDate'}
                    dataTestId={DELIVERY_DATE_PICKER_TEST_ID}
                    selectedDate={
                        activeFilters.deliveryDateFrom ? dateTimeToLocalDate(activeFilters.deliveryDateFrom) : undefined
                    }
                    onChange={(deliveryDateFrom) => {
                        onChange({
                            deliveryDateFrom: deliveryDateFrom
                                ? localDateToLocalStartOfDayInUtc(deliveryDateFrom)
                                : undefined,
                        });
                    }}
                />
            </div>
        </>
    );
};
