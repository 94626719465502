import { BaseSyntheticEvent, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';

interface TimePickerProps {
    value?: string;
    onChange: (value?: string) => void;
    disabled?: boolean;
    placeholder?: string | undefined;
}

export const TimePicker = (props: TimePickerProps) => {
    const [internalValue, setInternalValue] = useState(props.value === undefined ? '' : props.value);

    const mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
    const fullRegexp = /^(?:\d|[01]\d|2[0-3]):[0-5]\d$/;
    const blankValue = '__:__';
    const isValid = (value: string) => value === blankValue || value === '' || fullRegexp.test(value);

    useEffect(() => {
        setInternalValue(props.value || '');
    }, [props.value]);

    const handleChangeTime = (event: BaseSyntheticEvent) => {
        const currentValue = event.target.value;
        setInternalValue(currentValue);
        if (currentValue === blankValue) {
            props.onChange(undefined);
        } else if (fullRegexp.test(currentValue)) {
            props.onChange(currentValue);
        }
    };

    const clearInvalidOnBlur = () => {
        if (!isValid(internalValue)) {
            setInternalValue('');
            props.onChange(undefined);
        }
    };

    return (
        <div className={'TimePicker input-group'} data-testid={'TIME_PICKER_CONTAINER_TEST_ID'}>
            <ReactInputMask
                className={'TimePickerInput form-control'}
                mask={mask}
                value={internalValue}
                onChange={handleChangeTime}
                alwaysShowMask={props.placeholder === undefined}
                onBlur={clearInvalidOnBlur}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
        </div>
    );
};
