import { State } from '../../../configuration/setup/store';

export const ensureValueExists = <T>(
    selector: (state: State) => T,
    state: State,
    parameterName: string = 'Value',
): NonNullable<T> => {
    const value = selector(state);
    if (value === undefined || value === null) {
        throw Error(`${parameterName} must exist in Redux store but selector returned null or undefined.`);
    }
    return value!;
};
