import { FieldErrors, Path, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Partner } from '../../domain/common.types';
import { BoxFinderInputV2, PackagingMaterialFormValues } from '../common/form/BoxFinderInputV2';
import { PackageTogetherFormValues } from '../dispatchProposals/dispatchProposalPanel/PackageTogetherDialog';

const PackagingMaterialHelpLink = () => {
    return (
        <a
            className={'float-right text-size-12 text-decoration-none'}
            href={
                'https://lso.volkswagen.de/one-kbp/content/media/kbp_private/kbp_private_documents/kbp_systemdokumente_applikationen/webscm/handbuecher_3/WEB_SCM_Verpackungs-Codierung_fuer_Einweg-_und_Ausweichverpackung.pdf?streaming=true'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
            <span className={'rioglyph rioglyph-question-sign padding-right-3'} />
            <FormattedMessage id={'webedi.packaging.packagingMaterial.help'} />
        </a>
    );
};

type BoxFinderProps = {
    fieldName: Path<PackageTogetherFormValues>;
    showLinkToVWMaterialHelp: boolean;
    getPackagingMaterialError: (
        errors: FieldErrors<PackageTogetherFormValues>,
    ) => FieldErrors<PackagingMaterialFormValues> | undefined;
    boxFinderInputProps?: {
        disabled: boolean;
        outerClassName: string;
        packagingMaterialInputPrefix: string;
    };
};

export const BoxFinderV2 = (props: BoxFinderProps) => {
    const { watch } = useFormContext<PackageTogetherFormValues>();
    const items = watch('selectedItemsToPackage');
    const buyer = items[0]?.dispatchProposalItem.buyer.name;
    const partner = items[0]?.dispatchProposalItem.partner;
    const isVWorPorschePartner = partner === Partner.VW || partner === Partner.PORSCHE;

    return (
        <div>
            <label className={'align-left'} htmlFor={props.fieldName}>
                <FormattedMessage id={'webedi.packagingMaterial'} />
            </label>
            {props.showLinkToVWMaterialHelp && isVWorPorschePartner && (
                <span className={'float-right text-size-12'}>
                    <PackagingMaterialHelpLink />
                </span>
            )}
            <BoxFinderInputV2
                buyer={buyer}
                partner={partner}
                fieldName={props.fieldName}
                getPackagingMaterialError={props.getPackagingMaterialError}
                {...props.boxFinderInputProps}
            />
        </div>
    );
};
