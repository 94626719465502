import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '../../common/DatePicker';
import { HorizontalAddButton } from '../../common/HorizontalAddButton';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { isDecimalBasedMeasurementUnit } from '../../common/form/NumberInput';
import { PositiveNumberFormInput } from '../../common/form/PositiveNumberFormInput';
import { ErrorHelpBlock } from './common/ErrorHelpBlock';
import { ManualCreationOfDeliverySchedulesFormValues } from './formConfig';
import { genericInputErrorTranslations, numberInputErrorTranslations } from './validations';

export const DELETE_SCHEDULING_DATA_ITEM_IN_WIZARD_DATA_TEST_ID = 'delete-scheduling-data-item-in-wizard';

const errorClassName = 'has-error has-feedback';

export const Step3 = () => (
    <div className='padding-20'>
        <div className='display-flex flex-column margin--20 padding-top-5pct padding-bottom-5pct margin-x-10pct-md min-height-500'>
            <Header />
            <div className='display-flex flex-row padding-top-5pct justify-content-center border-style-solid border-width-1 border-color-light min-height-400'>
                <SchedulingData />
            </div>
        </div>
    </div>
);

const Header = () => (
    <div className='display-flex flex-column align-items-center'>
        <div className='text-size-18 margin-bottom-25'>
            <FormattedMessage id={'webedi.deliverySchedules.create.wizard.schedulingData.title'} />
        </div>
        <div className='counter color-highlight data-count'>
            <span className='display-flex flex-row' data-count='3'>
                <FormattedMessage id={'webedi.deliverySchedules.create.wizard.schedulingData.text'} />
            </span>
        </div>
    </div>
);

const SchedulingData = () => {
    const { fields, append, remove } = useFieldArray({
        name: 'schedulingData',
        rules: {
            required: true,
            minLength: 1,
        },
    });
    return (
        <div className='display-flex flex-column width-66pct margin-bottom-20'>
            {fields.map((item, index) => (
                <div className='display-flex flex-row gap-25' key={item.id}>
                    <DeliveryDateInput index={index} />
                    <QuantityInput index={index} />
                    <RemoveButton index={index} remove={remove} />
                </div>
            ))}
            <HorizontalAddButton onClick={() => append({})} />
        </div>
    );
};

interface DeliveryScheduleInputProps {
    index: number;
    remove?: UseFieldArrayRemove;
}

const DeliveryDateInput = (props: DeliveryScheduleInputProps) => {
    const {
        control,
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();

    const errorDate = errors['schedulingData']?.[props.index]?.date;

    return (
        <div className='rdt DatePicker width-60pct'>
            <label htmlFor={`schedulingData.${props.index}.date`}>
                <FormattedMessage id={'webedi.schedulingData.deliveryDate'} />*
            </label>
            <div className={`form-group margin-0 flex-1-1 ${errorDate ? errorClassName : ''}`}>
                <Controller
                    name={`schedulingData.${props.index}.date`}
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field }) => (
                        <DatePicker
                            className={'margin-bottom-0'}
                            inputProps={{
                                id: `schedulingData.${props.index}.date`,
                                required: true,
                            }}
                            onChange={field.onChange}
                            clearableInput={true}
                            value={field.value}
                        />
                    )}
                />
                <ErrorHelpBlock translations={genericInputErrorTranslations} error={errorDate} />
            </div>
        </div>
    );
};

const QuantityInput = (props: DeliveryScheduleInputProps) => {
    const {
        watch,
        formState: { errors },
    } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();

    const watchMeasurementUnitCode = watch('measurementUnitCode');
    const isDecimal = isDecimalBasedMeasurementUnit(watchMeasurementUnitCode);
    const step = isDecimal ? 0.01 : 1;

    const errorValue = errors['schedulingData']?.[props.index]?.value;

    return (
        <div className='width-30pct'>
            <label htmlFor={`schedulingData.${props.index}.value`}>
                <FormattedMessage id={'webedi.schedulingData.quantity'} />*
            </label>
            <div className={`form-group margin-0 flex-1-1 ${errorValue ? errorClassName : ''}`}>
                <div className='input-group'>
                    <div className={'form-control-feedback-wrapper'}>
                        <PositiveNumberFormInput
                            fieldName={`schedulingData.${props.index}.value`}
                            required={true}
                            step={step}
                            decimalAllowed={isDecimal}
                        />
                        <ErrorHelpBlock translations={numberInputErrorTranslations} error={errorValue} />
                    </div>
                    <div className='input-group-addon'>
                        {watchMeasurementUnitCode !== undefined ? (
                            <MeasurementUnitCodeDisplay unit={watchMeasurementUnitCode} />
                        ) : (
                            '?'
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const RemoveButton = (props: DeliveryScheduleInputProps) => {
    const { watch } = useFormContext<ManualCreationOfDeliverySchedulesFormValues>();
    const watchSchedulingData = watch('schedulingData');

    return (
        <div className='form-group width-8pct'>
            <label>&thinsp;</label>
            <button
                data-testid={DELETE_SCHEDULING_DATA_ITEM_IN_WIZARD_DATA_TEST_ID}
                className={'btn btn-muted btn-icon-only'}
                type='button'
                onClick={() => {
                    if (watchSchedulingData.length > 1 && props.remove) {
                        props.remove(props.index);
                    }
                }}
                disabled={watchSchedulingData.length <= 1}
            >
                <span className={'rioglyph rioglyph-trash margin-right-5'} />
            </button>
        </div>
    );
};
