import { ValidPackagingMaterial } from '../../../../../reducers/packaging/types';

export const formIds = {
    packagingMaterialSelection: 'packagingMaterialSelectionForm',
    packagingConfiguration: 'packagingConfigurationForm',
    calculateNumberOfHandlingUnits: 'calculateNumberOfHandlingUnits',
    numberOfHandlingUnits: 'numberOfHandlingUnits',
    quantityValuePerHandlingUnit: 'quantityValuePerHandlingUnit',
    packagingMaterial: 'packagingMaterial',
};

export interface InnerPackagingMaterialSelectionFormValues {
    packagingMaterial?: ValidPackagingMaterial;
}

export interface InnerPackagingConfigurationFormValues {
    calculateNumberOfHandlingUnits: boolean;
    quantityValuePerHandlingUnit?: number;
    numberOfHandlingUnits?: number;
    numberOfPackagedArticles: number;
    numberOfArticlesToPack: number;
}
export type ValidatedFormValuesConfigurationStep = Required<InnerPackagingConfigurationFormValues>;

export const formPropertyNamesConfigurationStep: Record<
    keyof InnerPackagingConfigurationFormValues,
    keyof InnerPackagingConfigurationFormValues
> = {
    quantityValuePerHandlingUnit: 'quantityValuePerHandlingUnit',
    calculateNumberOfHandlingUnits: 'calculateNumberOfHandlingUnits',
    numberOfHandlingUnits: 'numberOfHandlingUnits',
    numberOfPackagedArticles: 'numberOfPackagedArticles',
    numberOfArticlesToPack: 'numberOfArticlesToPack',
};

export const formPropertyNamesPackagingMaterialSelectionStep: Record<
    keyof InnerPackagingMaterialSelectionFormValues,
    keyof InnerPackagingMaterialSelectionFormValues
> = {
    packagingMaterial: 'packagingMaterial',
};

export const formPropertyNamesInnerCreationPackagingTemplate: Record<
    keyof InnerPackagingConfigurationFormValues,
    keyof InnerPackagingConfigurationFormValues
> &
    Record<keyof InnerPackagingMaterialSelectionFormValues, keyof InnerPackagingMaterialSelectionFormValues> = {
    ...formPropertyNamesPackagingMaterialSelectionStep,
    ...formPropertyNamesConfigurationStep,
};
