import { Field } from 'react-final-form';
import { useIntl } from 'react-intl';
import { MeasurementUnitCodeDisplay } from '../../../common/MeasurementUnitCodeDisplay';
import { BorderlessNumberInput } from '../../../common/form/BorderlessNumberInput';
import { FormErrorIcon } from '../../../common/form/FormErrorIcon';
import { getNumberInputParseFunction } from '../../../common/form/NumberInput';
import { maxValueInputQuantity, minValueInputQuantity, validateNumberInRange } from '../ShipmentEditViewFormConfig';
import { QuantityFieldProps } from './types';

export const QuantityField = (props: QuantityFieldProps) => {
    const { index, loadItem, deliveryNotePath, isDragging } = props;

    const intl = useIntl();

    const quantityInputClassNames = (dragging: boolean) => {
        const classes = [
            'display-flex',
            'align-items-center',
            'borderless-input',
            'no-control-arrows',
            'text-size-14',
        ].join(' ');

        return dragging ? classes.concat(' dragging-borderless-input') : classes;
    };

    return (
        <div className={quantityInputClassNames(isDragging)}>
            <div className={'input-group'}>
                <Field<number>
                    name={`${deliveryNotePath}.loadItems.[${index}].amount.value`}
                    component={BorderlessNumberInput}
                    measurementUnitCode={loadItem.amount.measurementUnitCode}
                    parse={getNumberInputParseFunction(loadItem.amount.measurementUnitCode)}
                    initialValue={loadItem.amount.value}
                    outerClassName={''}
                    className={'width-80 padding-right-25 margin-right-5 form-control'}
                    suppressErrorBlock={true}
                    validate={(value) =>
                        validateNumberInRange(value, minValueInputQuantity, maxValueInputQuantity, intl)
                    }
                    key={loadItem.amount.value === 0 ? `${index}-1` : `${index}-0`}
                    forbidScientificNotation
                    errorIcon={<FormErrorIcon name={`${deliveryNotePath}.loadItems.[${index}].amount.value`} />}
                />
                <span>
                    <MeasurementUnitCodeDisplay unit={loadItem.amount.measurementUnitCode} />
                </span>
            </div>
        </div>
    );
};
