export enum MeasurementUnitCode {
    DEGREE_CELSIUS = 'DEGREE_CELSIUS',
    CENTIMETRE = 'CENTIMETRE',
    CUBIC_DECIMETRE = 'CUBIC_DECIMETRE',
    DECIMETRE = 'DECIMETRE',
    DRUM = 'DRUM',
    GRAM = 'GRAM',
    KILOGRAM = 'KILOGRAM',
    KILOMETRE = 'KILOMETRE',
    LEAF = 'LEAF',
    LITRE = 'LITRE',
    MILLILITRE = 'MILLILITRE',
    MILLIMETRE = 'MILLIMETRE',
    SQUARE_METRE = 'SQUARE_METRE',
    CUBIC_METRE = 'CUBIC_METRE',
    METRE = 'METRE',
    PIECE = 'PIECE',
    PAIR = 'PAIR',
    ROLL = 'ROLL',
    SET = 'SET',
    METRIC_TON = 'METRIC_TON',
}

export interface Quantity {
    value: number;
    measurementUnitCode: MeasurementUnitCode;
}

export enum Partner {
    VW = 'VW',
    PORSCHE = 'PORSCHE',
    MAN = 'MAN',
    POWERCO = 'POWERCO',
}
