import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { State } from '../../../../configuration/setup/store';
import { useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { ApiErrorCode } from '../../reducers/ui/UI.reducer';
import { getCurrentError } from '../../selectors/ui/UI.selector';

export const ErrorBox = (props: Omit<SelectingErrorBoxProps<ApiErrorCode>, 'selector'>) => (
    <SelectingErrorBox headerKey={props.headerKey} selector={getCurrentError} />
);

interface SelectingErrorBoxProps<ErrorCode> {
    headerKey: string;
    selector: (state: State) => ErrorCode | undefined;
}

const SelectingErrorBox = <ErrorCode,>(props: SelectingErrorBoxProps<ErrorCode>) => {
    const errorMessage: ErrorCode | undefined = useAppSelector(props.selector);

    return errorMessage ? <ErrorBoxForErrorCode errorCode={errorMessage} headerKey={props.headerKey} /> : null;
};

export const ErrorBoxForErrorCode = (props: { errorCode?: unknown; headerKey: string }) => {
    const { errorCode, headerKey } = props;

    const ref = useRef<HTMLDivElement>(null);
    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [errorCode]);

    return (
        <div ref={ref} className={'alert alert-danger'} role={'alert'} data-testid={ERROR_BOX_TEST_ID}>
            <div className={'display-flex'}>
                <span className={`text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph-error-sign`} />
                <div>
                    <span className={'text-size-16'}>
                        <strong>
                            <FormattedMessage id={headerKey} />
                        </strong>
                    </span>
                    <ul className={'margin-0'}>
                        <li>
                            <FormattedMessage id={errorCode ? `webedi.error.${errorCode}` : 'webedi.error.unknown'} />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export const ERROR_BOX_TEST_ID = 'ERROR_BOX_TEST_ID';
