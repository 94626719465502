import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import useScrollPosition from '@rio-cloud/rio-uikit/lib/es/useScrollPosition';
import isEmpty from 'lodash/fp/isEmpty';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../../configuration/tokenHandling/selectors';
import { isLocalDateTime, isSameOrAfter, minusDuration, today } from '../../../../../dateUtils';
import { formatAddress } from '../../../../../utils';
import { exportShipment } from '../../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { Shipment } from '../../../reducers/shipments/types';
import { hasPackagingConfigurationForSelectedShipmentChanged } from '../../../selectors/packaging/Packaging.selector';
import { renderDetailAttributeCard, renderDetailAttributeTextCard } from '../../common/DetailAttributeCard';
import { Tooltip } from '../../common/Tooltip';
import { NavigationPrompt } from '../../common/dialog/NavigationPrompt';
import { DateAndTimePickerInput, DateAndTimePickerInputProps } from '../../common/form/DateAndTimePickerInput';
import { FormattedDateOrDateTime } from '../../common/i18n/FormattedDateOrDateTime';
import { FormattedManufacturingCompany } from '../../deliverySchedules/FormattedManufacturingCompany';
import { Routing } from '../../routing/Routes';
import {
    SteppedProgressBarStep,
    labelsShipmentProgressBar,
    mapNumberToStep,
    mapStepToNumber,
} from '../ShipmentSteppedProgressConfig';
import { DocumentGenerationInProgressInfoDialog } from '../shipmentDocuments/DocumentGenerationInProgressInfoDialog';
import {
    SHIPMENT_EDIT_STEPPED_PROGRESS_BAR_TEST_ID,
    SHIPMENT_EDIT_VIEW_CLOSE_BUTTON_TEST_ID,
    SHIPMENT_EDIT_VIEW_DESPATCH_DATE_DISABLED_TEST_ID,
    SHIPMENT_EDIT_VIEW_DESPATCH_DATE_ENABLED_TEST_ID,
    SHIPMENT_EDIT_VIEW_ESTIMATED_ARRIVAL_DATE_TEST_ID,
    SHIPMENT_EDIT_VIEW_HEADER_SHIPMENT_NUMBER_TEST_ID,
    SHIPMENT_EDIT_VIEW_HEADER_TEST_ID,
    SHIPMENT_EDIT_VIEW_HEADER_WEIGHT_WARNING_TEST_ID,
} from './ShipmentEditView';
import { EditableShipmentNumberField } from './deliveryNotes/EditableNumberField';
import { ShipmentEditHeaderProps } from './types';

const moveToPage = (
    direction: 'next' | 'previous',
    selectedStep: SteppedProgressBarStep,
    setSelectedStep: Dispatch<SetStateAction<SteppedProgressBarStep | undefined>>,
) =>
    direction === 'next'
        ? setSelectedStep(mapNumberToStep(mapStepToNumber(selectedStep) + 1))
        : setSelectedStep(mapNumberToStep(mapStepToNumber(selectedStep) - 1));

interface ActualDispatchDateProps {
    shipment: Shipment;
    readOnly: boolean;
}

interface EstimatedArrivalDateProps {
    shipment: Shipment;
    readOnly: boolean;
}

interface RequestedDeliveryDateProps {
    shipment: Shipment;
}

export const SHIPMENT_EDIT_HEADER_NEXT_BUTTON_TEST_ID = 'SHIPMENT_EDIT_HEADER_NEXT_BUTTON_TEST_ID';
export const SHIPMENT_EDIT_HEADER_BACK_BUTTON_TEST_ID = 'SHIPMENT_EDIT_HEADER_BACK_BUTTON_TEST_ID';
export const SHIPMENT_EDIT_HEADER_SAVE_BUTTON_TEST_ID = 'SHIPMENT_EDIT_HEADER_SAVE_BUTTON_TEST_ID';
export const SHIPMENT_EDIT_HEADER_EXPORT_BUTTON_TEST_ID = 'SHIPMENT_EDIT_HEADER_EXPORT_BUTTON_TEST_ID';

export const SHIPMENT_MAX_ALLOWED_GROSS_WEIGHT_IN_KG = 65_000;

const ActualDispatchDateAttributeCard = (props: ActualDispatchDateProps) => {
    if (!props.readOnly) {
        const isValidDate = (currentDate: string) => isSameOrAfter(currentDate, minusDuration(today(), { days: 1 }));

        return renderDetailAttributeCard(
            <FormattedMessage id={'webedi.shipment.actualDespatchDate'} />,
            <Field<string>
                name={'despatchDate'}
                component={DateAndTimePickerInput}
                data-testid={SHIPMENT_EDIT_VIEW_DESPATCH_DATE_ENABLED_TEST_ID}
                isValidDate={isValidDate}
            />,
        );
    }

    const text = props.shipment.despatchDate ? <FormattedDateOrDateTime date={props.shipment.despatchDate} /> : '---';

    return renderDetailAttributeTextCard(
        <FormattedMessage id={'webedi.shipment.actualDespatchDate'} />,
        <span data-testid={SHIPMENT_EDIT_VIEW_DESPATCH_DATE_DISABLED_TEST_ID}>{text}</span>,
    );
};

const EstimatedArrivalDateAttributeCard = (props: EstimatedArrivalDateProps) => {
    const { shipment, readOnly } = props;
    const intl = useIntl();

    const placeholderDate = shipment.requestedDeliveryDate
        ? intl.formatDate(shipment.requestedDeliveryDate)
        : undefined;
    const placeholderTime = isLocalDateTime(shipment.requestedDeliveryDate)
        ? intl.formatTime(shipment.requestedDeliveryDate)
        : undefined;

    const EstimatedDatePicker = (estimatedDatePickerProps: DateAndTimePickerInputProps) => (
        <DateAndTimePickerInput
            input={estimatedDatePickerProps.input}
            meta={estimatedDatePickerProps.meta}
            placeholderDate={placeholderDate}
            placeholderTime={placeholderTime}
            data-testid={SHIPMENT_EDIT_VIEW_ESTIMATED_ARRIVAL_DATE_TEST_ID}
        />
    );

    const label = (
        <span>
            <FormattedMessage id={'webedi.shipment.estimatedArrivalDate'} />
            {shipment.requestedDeliveryDate && (
                <Tooltip
                    text={<FormattedMessage id={'webedi.shipment.estimatedArrivalDate.tooltip'} />}
                    placement={'right'}
                    width={400}
                >
                    <span className={'rioglyph rioglyph-info-sign text-color-info margin-left-5'} />
                </Tooltip>
            )}
        </span>
    );

    if (readOnly) {
        return renderDetailAttributeTextCard(
            label,
            shipment.estimatedArrivalDate ? (
                <FormattedDateOrDateTime date={shipment.estimatedArrivalDate} />
            ) : (
                <span>–</span>
            ),
        );
    } else {
        return renderDetailAttributeCard(
            label,
            <Field<string> name={'estimatedArrivalDate'} component={EstimatedDatePicker} />,
        );
    }
};

const RequestedDeliveryDateAttributeCard = (props: RequestedDeliveryDateProps) => {
    const { shipment } = props;

    return renderDetailAttributeTextCard(
        <FormattedMessage id={'webedi.shipment.requestedDeliveryDate'} />,
        shipment.requestedDeliveryDate ? (
            <FormattedDateOrDateTime date={shipment.requestedDeliveryDate} />
        ) : (
            <span>–</span>
        ),
    );
};

export const ShipmentEditHeader = (props: ShipmentEditHeaderProps) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const packagingConfigurationChanged = useAppSelector(hasPackagingConfigurationForSelectedShipmentChanged);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);

    const { shipment, pristine, submitForm, selectedStep, setSelectedStep, errors, readOnly } = props;
    const [headerExpanded, setHeaderExpanded] = useState(true);
    const [ignoreScrolling, setIgnoreScrolling] = useState(false);
    const [exportButtonDisabledDueToPreviousClick, setExportButtonDisabledDueToPreviousClick] = useState(false);

    const scrollPosition = useScrollPosition();

    const ignoreScrollingWithinTheNextSecond = () => {
        setIgnoreScrolling(true);
        setTimeout(() => {
            setIgnoreScrolling(false);
        }, 1000);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (ignoreScrolling) {
            return;
        }

        if (headerExpanded && scrollPosition > 50) {
            ignoreScrollingWithinTheNextSecond();
            setHeaderExpanded(false);
        }

        if (!headerExpanded && scrollPosition === 0) {
            ignoreScrollingWithinTheNextSecond();
            setHeaderExpanded(true);
        }
    }, [scrollPosition]);

    const hasChangedValues = !pristine || packagingConfigurationChanged;

    const submitIfChanged = () => {
        if (hasChangedValues && !isReadOnly) {
            setExportButtonDisabledDueToPreviousClick(false);
            submitForm();
        }
    };

    const closeEditView = () => {
        navigate(`${Routing.webScm}/${dunsNumber}${Routing.shipments}`);
    };

    const exportButtonClickHandler = () => {
        setExportButtonDisabledDueToPreviousClick(true);
        dispatch(exportShipment(dunsNumber!, shipment, navigate));
    };

    const renderExportButton = () => {
        const content = (
            <button
                type={'button'}
                className={'btn btn-default'}
                disabled={hasChangedValues || isReadOnly || exportButtonDisabledDueToPreviousClick}
                onClick={exportButtonClickHandler}
                data-testid={SHIPMENT_EDIT_HEADER_EXPORT_BUTTON_TEST_ID}
            >
                <span className={'rioglyph rioglyph-arrow-right'} />
                <FormattedMessage id={'webedi.label.export'} />
                {isReadOnly && (
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                        }
                    />
                )}
            </button>
        );

        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    };

    const renderSaveButton = () => {
        const content = (
            <button
                type={'button'}
                className={'btn btn-default'}
                disabled={readOnly || !hasChangedValues || !isEmpty(errors) || isReadOnly}
                onClick={() => submitIfChanged()}
                data-testid={SHIPMENT_EDIT_HEADER_SAVE_BUTTON_TEST_ID}
            >
                <FormattedMessage id={'webedi.label.save'} />
                {isReadOnly && (
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'
                        }
                    />
                )}
            </button>
        );

        if (isReadOnly) {
            return (
                <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                    <span>{content}</span>
                </Tooltip>
            );
        }

        return content;
    };

    const calculateDisableFollowingPages = () => {
        if (!isEmpty(errors)) {
            return mapStepToNumber(selectedStep);
        } else if (
            shipment.termsOfDelivery !== undefined ||
            shipment.meansOfTransport !== undefined ||
            shipment.customs !== undefined
        ) {
            return Math.max(
                mapStepToNumber(selectedStep) + 1,
                mapStepToNumber(SteppedProgressBarStep.SHIPMENT_DOCUMENTS),
            );
        } else if (shipment.packaging.length > 0) {
            return Math.max(mapStepToNumber(selectedStep) + 1, mapStepToNumber(SteppedProgressBarStep.TRANSPORT));
        }
        return mapStepToNumber(selectedStep) + 1;
    };

    const renderWeightsNotUpToDateWarning = () => {
        return (
            packagingConfigurationChanged && (
                <Tooltip
                    text={<FormattedMessage id={'webedi.valueNotUpToDate.pleaseSave'} />}
                    placement={'bottom'}
                    width={400}
                >
                    <span
                        data-testid={SHIPMENT_EDIT_VIEW_HEADER_WEIGHT_WARNING_TEST_ID}
                        className={'rioglyph rioglyph-exclamation-sign text-color-warning padding-left-5'}
                    />
                </Tooltip>
            )
        );
    };

    const renderGrossWeightExceedsLimitTooltip = () => {
        return (
            <Tooltip
                text={<FormattedMessage id={'webedi.shipment.weightExceedsLimitWarning'} />}
                placement={'bottom'}
                width={400}
            >
                <span className={'rioglyph rioglyph-exclamation-sign text-color-danger padding-left-5'} />
            </Tooltip>
        );
    };

    const renderShipmentGrossWeightData = () => {
        const shipmentGrossWeightExceedsLimit = shipment.grossWeightInKg > SHIPMENT_MAX_ALLOWED_GROSS_WEIGHT_IN_KG;
        let className = '';
        if (shipmentGrossWeightExceedsLimit) {
            className = 'text-color-danger';
        } else if (packagingConfigurationChanged) {
            className = 'text-color-warning';
        }

        return (
            <span className={className}>
                {`${shipment.grossWeightInKg.toLocaleString(intl.locale, {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                })} kg`}
                {packagingConfigurationChanged && <></>}
                {shipmentGrossWeightExceedsLimit
                    ? renderGrossWeightExceedsLimitTooltip()
                    : renderWeightsNotUpToDateWarning()}
            </span>
        );
    };

    return (
        <div
            className={'panel panel-default position-sticky z-index-5'}
            style={{ top: '-29px' }}
            data-testid={SHIPMENT_EDIT_VIEW_HEADER_TEST_ID}
        >
            <DocumentGenerationInProgressInfoDialog />
            <div className={'panel-heading display-flex flex-row justify-content-between padding-right-10 padding-20'}>
                <div className={'display-flex align-items-center text-color-primary text-size-20'}>
                    <span className={'rioglyph rioglyph-order padding-right-10'} />
                    <FormattedMessage id={'webedi.shipment'} />
                    <div
                        data-testid={SHIPMENT_EDIT_VIEW_HEADER_SHIPMENT_NUMBER_TEST_ID}
                        className={'borderless-input no-control-arrows'}
                    >
                        <EditableShipmentNumberField
                            path={'shipmentNumber'}
                            value={shipment.shipmentNumber}
                            inputInnerClasses={'width-150 text-color-primary text-size-20 margin-right--25 text-left'}
                        />
                    </div>
                </div>
                <div className={'pull-right btn-toolbar'}>
                    <button
                        type={'button'}
                        className={'btn btn-default'}
                        onClick={() => {
                            submitIfChanged();
                            moveToPage('previous', selectedStep, setSelectedStep);
                        }}
                        disabled={selectedStep === SteppedProgressBarStep.DELIVERY_NOTE || !isEmpty(errors)}
                        data-testid={SHIPMENT_EDIT_HEADER_BACK_BUTTON_TEST_ID}
                    >
                        <span className={'rioglyph rioglyph rioglyph-chevron-left'} />
                        <FormattedMessage id={'webedi.back'} />
                    </button>
                    <button
                        type={'button'}
                        className={'btn btn-primary'}
                        onClick={() => {
                            submitIfChanged();
                            moveToPage('next', selectedStep, setSelectedStep);
                        }}
                        disabled={selectedStep === SteppedProgressBarStep.SHIPMENT_DOCUMENTS || !isEmpty(errors)}
                        data-testid={SHIPMENT_EDIT_HEADER_NEXT_BUTTON_TEST_ID}
                    >
                        <FormattedMessage id={'webedi.next'} />
                        <span className={'rioglyph rioglyph-chevron-right margin-left-5'} />
                    </button>
                    <span className={'height-20 bg-light padding-1 margin-5 margin-bottom-0 width-1 rounded'} />
                    {renderExportButton()}
                    {renderSaveButton()}
                    <button
                        type={'button'}
                        className={'btn btn-link btn-icon-only'}
                        onClick={() => setHeaderExpanded(!headerExpanded)}
                    >
                        <span className={`rioglyph rioglyph-resize-${headerExpanded ? 'small' : 'full'}`} />
                    </button>
                    <button
                        type={'button'}
                        className={'btn btn-link btn-icon-only'}
                        onClick={closeEditView}
                        data-testid={SHIPMENT_EDIT_VIEW_CLOSE_BUTTON_TEST_ID}
                    >
                        <span className={'rioglyph rioglyph-remove'} />
                    </button>
                </div>
            </div>
            <Collapse open={headerExpanded} unmountOnExit={true}>
                <div className={'panel-body'}>
                    <div className={'display-flex gap-10 flex-row padding-20'}>
                        {renderDetailAttributeTextCard(
                            <FormattedMessage id={'webedi.common.manufacturingCompany'} />,
                            <FormattedManufacturingCompany manufacturingCompany={shipment.buyer} />,
                        )}
                        {renderDetailAttributeTextCard(
                            <FormattedMessage id={'webedi.plantIdentifier'} />,
                            <span>{shipment.shipToId}</span>,
                        )}
                        {renderDetailAttributeTextCard(
                            <FormattedMessage id={'webedi.common.placeOfDischarge.address'} />,
                            <span>
                                <span className={'rioglyph rioglyph-factory text-size-20 margin-right-5'} />
                                {shipment.placeOfDischarge.address
                                    ? formatAddress(shipment.placeOfDischarge.address)
                                    : '-'}
                            </span>,
                        )}
                        {renderDetailAttributeTextCard(
                            <FormattedMessage id={'webedi.common.placeOfDischarge'} />,
                            <span>
                                <span className={'rioglyph rioglyph-load-unload text-size-20 margin-right-5'} />
                                {shipment.placeOfDischarge.id}
                            </span>,
                        )}
                    </div>
                    <div className={'display-flex gap-10 flex-row padding-20'}>
                        <RequestedDeliveryDateAttributeCard shipment={shipment} />
                        <EstimatedArrivalDateAttributeCard readOnly={props.readOnly} shipment={shipment} />
                        <ActualDispatchDateAttributeCard readOnly={props.readOnly} shipment={shipment} />
                        <div className={'flex-1-1-0'}>
                            <div className={'display-flex flex-row'}>
                                {renderDetailAttributeTextCard(
                                    <FormattedMessage id={'webedi.shipment.grossWeight'} />,
                                    renderShipmentGrossWeightData(),
                                )}
                                {renderDetailAttributeTextCard(
                                    <FormattedMessage id={'webedi.shipment.netWeight'} />,
                                    <span className={packagingConfigurationChanged ? 'text-color-warning' : ''}>
                                        {`${shipment.netWeightInKg.toLocaleString(intl.locale, {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} kg`}
                                        {packagingConfigurationChanged && <></>}
                                        {renderWeightsNotUpToDateWarning()}
                                    </span>,
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
            <div
                data-testid={SHIPMENT_EDIT_STEPPED_PROGRESS_BAR_TEST_ID}
                className={'border-style-solid border-top-only border-width-1 border-color-light margin-top--1'}
            >
                <SteppedProgressBar
                    onSelectedChanged={(step: number) => {
                        submitIfChanged();
                        setSelectedStep(mapNumberToStep(step));
                    }}
                    labels={labelsShipmentProgressBar(selectedStep, errors)}
                    selectedStepNumber={mapStepToNumber(selectedStep)}
                    variant={'roadsign'}
                    disableFollowingPages={calculateDisableFollowingPages()}
                />
            </div>
            <NavigationPrompt when={hasChangedValues && !isReadOnly} />
        </div>
    );
};
