import { State } from '../../../../configuration/setup/store';

const getMasterDataSlice = (state: State) => state.webEdi.masterData;

export const getSelectedShippingLocationId = (state: State) => getMasterDataSlice(state).selectedShippingLocationId;

export const getManagedFreightForwarders = (state: State) => getMasterDataSlice(state).managedFreightForwarders;

export const getSelectedManagedFreightForwarderId = (state: State) =>
    getMasterDataSlice(state).selectedManagedFreightForwarderId;

export const getSelectedManagedFreightForwarder = (state: State) =>
    getManagedFreightForwarders(state).find(
        (managedFreightForwarder) => managedFreightForwarder.id === getSelectedManagedFreightForwarderId(state),
    );

export const isLoadingMasterData = (state: State) => getMasterDataSlice(state).isLoading;
