import { FormattedMessage, useIntl } from 'react-intl';

interface TrackingInfoBannerProps {
    courier: string;
}

export const GeneralInformation = (props: TrackingInfoBannerProps) => {
    const { courier } = props;
    const intl = useIntl();
    return (
        <div className='margin-left-10pct-md margin-right-10pct-md alert alert-info margin-top-25 padding-top-10'>
            <div className='display-flex'>
                <span className='text-color-info text-size-h4 margin-right-10 rioglyph rioglyph-info-sign' />
                <div>
                    <strong className='text-size-16'>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.notesHeadline'}
                            values={{ courier }}
                        />
                    </strong>

                    <p data-testid='general-information-body-text'>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.notesBody1'}
                            values={{
                                transport: (
                                    <strong>
                                        {intl.formatMessage({
                                            id: 'webedi.dispatchProposals.expressParcel.dialog.thirdStep.transport',
                                        })}
                                    </strong>
                                ),
                            }}
                        />
                    </p>

                    <p>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.thirdStep.notesBody'}
                            values={{ courier }}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};
