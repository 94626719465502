import { FormattedDate } from 'react-intl';
import { isLocalDate, toLocalDateTime } from '../../../../../dateUtils';

interface FormattedDateOrDateTimeProps {
    date: string;
    isZoned?: boolean;
}

export const dateFormatProps: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
export const dateTimeFormatProps: Intl.DateTimeFormatOptions = {
    ...dateFormatProps,
    hour: '2-digit',
    minute: '2-digit',
};

export const timeFormatProps: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

export const FormattedDateOrDateTime = (props: FormattedDateOrDateTimeProps) => {
    return isLocalDate(props.date) ? (
        <FormattedDateOnly date={props.date} isZoned={props.isZoned} />
    ) : (
        <FormattedDateTime date={props.date} isZoned={props.isZoned} />
    );
};

export const FormattedDateOnly = (props: FormattedDateOrDateTimeProps) => {
    const transformedDate = props.isZoned ? toLocalDateTime(props.date) : props.date;
    return (
        <FormattedDate
            value={transformedDate}
            {...dateFormatProps}
            timeZone={isLocalDate(props.date) ? 'UTC' : undefined}
        />
    );
};

const FormattedDateTime = (props: FormattedDateOrDateTimeProps) => {
    const transformedDate = props.isZoned ? toLocalDateTime(props.date) : props.date;
    return <FormattedDate value={transformedDate} {...dateTimeFormatProps} />;
};

export const FormattedTimeOnly = (props: FormattedDateOrDateTimeProps) => {
    const transformedDate = props.isZoned ? toLocalDateTime(props.date) : props.date;
    return <FormattedDate value={transformedDate} {...timeFormatProps} />;
};

export const FormattedWeekday = (props: FormattedDateOrDateTimeProps) => {
    const transformedDate = props.isZoned ? toLocalDateTime(props.date) : props.date;
    return (
        <FormattedDate
            value={transformedDate}
            weekday={'long'}
            timeZone={isLocalDate(props.date) ? 'UTC' : undefined}
        />
    );
};
