import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Partner } from '../../domain/common.types';
import { PackagingMaterial, PackagingMaterialState } from './types';

const initialState: PackagingMaterialState = {
    packagingMaterials: {},
    isFetching: false,
};

export const packagingMaterialSlice = createSlice({
    name: 'packagingMaterial',
    initialState,
    reducers: {
        setPackagingMaterial: (
            state: PackagingMaterialState,
            action: PayloadAction<{
                boxCode: string;
                packagingMaterial: PackagingMaterial | undefined;
                buyer: string | undefined;
                partner: Partner | undefined;
            }>,
        ) => {
            if (action.payload?.boxCode) {
                state.packagingMaterials[action.payload.boxCode] = {
                    successful: true,
                    packagingMaterial: action.payload.packagingMaterial,
                    buyer: action.payload.buyer,
                    partner: action.payload.partner,
                };
            }
        },
        setError: (state: PackagingMaterialState, action: PayloadAction<{ boxCode: string }>) => {
            state.packagingMaterials[action.payload.boxCode] = {
                successful: false,
                packagingMaterial: undefined,
            };
        },
        setIsFetching: (state: PackagingMaterialState, action: PayloadAction<{ isFetching: boolean }>) => {
            return { ...state, isFetching: action.payload.isFetching };
        },
        cleanPackagingMaterials: (state: PackagingMaterialState) => {
            return { ...state, packagingMaterials: {} };
        },
    },
});
