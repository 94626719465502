import { isStringAlphaNumeric, isStringNumeric } from '../../../../utils';
import { FieldErrorType } from '../common/form/types';
import {
    ManagedFreightForwarderFormErrors,
    ManagedFreightForwarderFormFieldError,
    ManagedFreightForwarderFormValues,
} from './ManagedFreightForwarderForm.types';

export const maxFieldLengths = {
    partyIdentifierBuyer: 10,
    partyIdentifierSeller: 35,
    dunsNumber: 9,
    name: 35,
    name2: 35,
    address: 35,
    address2: 35,
    postalCode: 17,
    city: 35,
    regionCode: 9,
    countryCode: 2,
};

export const forbiddenDuns = [
    '123456789',
    '987654321',
    '876543210',
    '012345678',
    '000000000',
    '111111111',
    '222222222',
    '333333333',
    '444444444',
    '555555555',
    '666666666',
    '777777777',
    '888888888',
    '999999999',
];

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code, legacy complexity
export const validate = (values: ManagedFreightForwarderFormValues): ManagedFreightForwarderFormErrors => {
    const errors: ManagedFreightForwarderFormErrors = {};

    if (!values.name) {
        errors.name = ManagedFreightForwarderFormFieldError.NAME_ABSENT;
    }
    if (values.name && values.name.length > maxFieldLengths.name) {
        errors.name = FieldErrorType.TOO_LONG;
    }
    if (values.name2 && values.name2.length > maxFieldLengths.name2) {
        errors.name2 = FieldErrorType.TOO_LONG;
    }
    if (!values.address) {
        errors.address = ManagedFreightForwarderFormFieldError.ADDRESS_ABSENT;
    }
    if (values.address && values.address.length > maxFieldLengths.address) {
        errors.address = FieldErrorType.TOO_LONG;
    }
    if (values.address2 && values.address2.length > maxFieldLengths.address2) {
        errors.address2 = FieldErrorType.TOO_LONG;
    }
    if (!values.postalCode) {
        errors.postalCode = ManagedFreightForwarderFormFieldError.POSTAL_CODE_ABSENT;
    }
    if (values.postalCode && values.postalCode.length > maxFieldLengths.postalCode) {
        errors.postalCode = FieldErrorType.TOO_LONG;
    }
    if (!values.city) {
        errors.city = ManagedFreightForwarderFormFieldError.CITY_ABSENT;
    }
    if (values.city && values.city.length > maxFieldLengths.city) {
        errors.city = FieldErrorType.TOO_LONG;
    }
    if (values.regionCode && values.regionCode.length > maxFieldLengths.regionCode) {
        errors.regionCode = FieldErrorType.TOO_LONG;
    }
    if (!values.countryCode) {
        errors.countryCode = ManagedFreightForwarderFormFieldError.COUNTRY_CODE_ABSENT;
    }
    if (values.partyIdentifierBuyer && !isStringAlphaNumeric(values.partyIdentifierBuyer)) {
        errors.partyIdentifierBuyer = FieldErrorType.NON_ALPHANUMERIC;
    }
    if (values.partyIdentifierBuyer && values.partyIdentifierBuyer.length > maxFieldLengths.partyIdentifierBuyer) {
        errors.partyIdentifierBuyer = FieldErrorType.TOO_LONG;
    }
    if (values.partyIdentifierSeller && !isStringAlphaNumeric(values.partyIdentifierSeller)) {
        errors.partyIdentifierSeller = FieldErrorType.NON_ALPHANUMERIC;
    }
    if (values.partyIdentifierSeller && values.partyIdentifierSeller.length > maxFieldLengths.partyIdentifierSeller) {
        errors.partyIdentifierSeller = FieldErrorType.TOO_LONG;
    }
    if (!values.partyIdentifierBuyer && !values.partyIdentifierSeller) {
        errors.partyIdentifierBuyer = ManagedFreightForwarderFormFieldError.AT_LEAST_ONE_PARTY_IDENTIFIER;
        errors.partyIdentifierSeller = ManagedFreightForwarderFormFieldError.AT_LEAST_ONE_PARTY_IDENTIFIER;
    }
    if (!values.dunsNumber) {
        errors.dunsNumber = ManagedFreightForwarderFormFieldError.DUNS_NUMBER_ABSENT;
    }
    if (values.dunsNumber && !isStringNumeric(values.dunsNumber)) {
        errors.dunsNumber = FieldErrorType.CONTAINS_INVALID_CHARACTERS;
    }
    if (values.dunsNumber && isStringNumeric(values.dunsNumber) && values.dunsNumber.length !== 9) {
        errors.dunsNumber = FieldErrorType.EXACT_LENGTH;
    }
    if (values.dunsNumber && forbiddenDuns.includes(values.dunsNumber)) {
        errors.dunsNumber = FieldErrorType.FORBIDDEN_VALUE;
    }
    return errors;
};
