// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { PartnerCodec } from '../common.types';
import { DimensionsCodec } from '../shared/dimensions.types';
import { quantityCodec } from '../shared/quantity.types';
import { fromEnum } from '../util';
import { buyerCodec, shipFromCodec, shipToCodec } from './common.types';

export enum ApiTransportConcept {
    FTL = 'FTL',
    LTL = 'LTL',
    KEP = 'KEP',
}

const freightForwarderCodec = t.type({
    name: t.string,
    duns_number: t.string,
    supplier_code: t.string,
});

export type ApiFreightForwarder = t.TypeOf<typeof freightForwarderCodec>;

const ftlTerminationCodec = t.type({
    transport_concept: t.literal(ApiTransportConcept.FTL),
    freight_forwarder: freightForwarderCodec,
    cutoff_date: t.string,
    pickup_date: t.string,
});

export type ApiFtlTermination = t.TypeOf<typeof ftlTerminationCodec>;

const ltlTerminationCodec = t.type({
    transport_concept: t.literal(ApiTransportConcept.LTL),
    freight_forwarder: freightForwarderCodec,
    cutoff_date: t.string,
    pickup_date: t.string,
});

export type ApiLtlTermination = t.TypeOf<typeof ltlTerminationCodec>;

const kepTerminationCodec = t.type({
    transport_concept: t.literal(ApiTransportConcept.KEP),
    cutoff_date: t.string,
});

export type ApiKepTermination = t.TypeOf<typeof kepTerminationCodec>;

const terminationCodec = t.union([ftlTerminationCodec, ltlTerminationCodec, kepTerminationCodec]);

export type ApiTermination = t.TypeOf<typeof terminationCodec>;

const articleContentCodec = t.type({
    article_number_buyer: t.string,
    quantity: quantityCodec,
    delivery_schedule_id: t.string,
});

export type ApiArticleContent = t.TypeOf<typeof articleContentCodec>;

const packagingSummaryCodec = t.type({
    type: t.string,
    count: t.number,
});

export type ApiPackagingSummary = t.TypeOf<typeof packagingSummaryCodec>;

export enum ApiTransportOrderStatus {
    UNPLANNED = 'UNPLANNED',
    PLANNED = 'PLANNED',
    ORDERED = 'ORDERED',
}

const transportOrderStatusCodec = fromEnum<ApiTransportOrderStatus>('ApiTransportOrderStatus', ApiTransportOrderStatus);

const dispatchProposalItemIdentifierBaseCodec = t.type({
    shipment_id: t.string,
});

const unpackagedDispatchProposalItemIdentifierCodec = t.intersection([
    dispatchProposalItemIdentifierBaseCodec,
    t.type({
        delivery_note_number: t.number,
        delivery_note_position: t.number,
    }),
]);

const packagedDispatchProposalItemIdentifierCodec = t.intersection([
    dispatchProposalItemIdentifierBaseCodec,
    t.type({
        id: t.string,
    }),
]);

const dispatchProposalItemIdentifierCodec = t.union([
    unpackagedDispatchProposalItemIdentifierCodec,
    packagedDispatchProposalItemIdentifierCodec,
]);

const unpackagedDispatchProposalItemCodec = t.intersection([
    t.type({
        type: t.literal('UNPACKAGED_DISPATCH_PROPOSAL_ITEM'),
        identifier: unpackagedDispatchProposalItemIdentifierCodec,
        article_content: articleContentCodec,
        buyer: buyerCodec,
        partner: PartnerCodec,
        ship_from: shipFromCodec,
        ship_to: shipToCodec,
    }),
    t.partial({
        delivery_date: t.string,
        shipment_number: t.number,
    }),
]);

export type ApiUnpackagedDispatchProposalItem = t.TypeOf<typeof unpackagedDispatchProposalItemCodec>;

const deliveryNoteNumberAndPositionCodec = t.type({
    delivery_note_number: t.number,
    position: t.number,
});

export type ApiDeliveryNoteNumberAndPosition = t.TypeOf<typeof deliveryNoteNumberAndPositionCodec>;

const packagedDispatchProposalItemCodec = t.intersection([
    t.type({
        type: t.literal('PACKAGED_DISPATCH_PROPOSAL_ITEM'),
        identifier: packagedDispatchProposalItemIdentifierCodec,
        article_contents: t.array(articleContentCodec),
        gross_weight_in_kg: t.number,
        buyer: buyerCodec,
        partner: PartnerCodec,
        ship_from: shipFromCodec,
        ship_to: shipToCodec,
        outer_packaging: packagingSummaryCodec,
        inner_packaging: t.array(packagingSummaryCodec),
        dimensions: DimensionsCodec,
        referenced_delivery_note_positions: t.array(deliveryNoteNumberAndPositionCodec),
    }),
    t.partial({
        delivery_date: t.string,
        shipment_number: t.number,
    }),
]);

export type ApiPackagedDispatchProposalItem = t.TypeOf<typeof packagedDispatchProposalItemCodec>;

const dispatchProposalItemCodec = t.union([unpackagedDispatchProposalItemCodec, packagedDispatchProposalItemCodec]);

export type ApiDispatchProposalItem = t.TypeOf<typeof dispatchProposalItemCodec>;

const unconstrainedDispatchProposalOperationCodec = t.type({
    allowed: t.boolean,
});

const timeBasedDispatchProposalOperationCodec = t.type({
    allowed: t.boolean,
    available_until: t.string,
});
const timeBasedDispatchProposalItemOperationCodec = t.type({
    allowed_per_item: t.array(
        t.type({
            identifier: dispatchProposalItemIdentifierCodec,
            allowed: t.boolean,
        }),
    ),
    available_until: t.string,
});

const dispatchProposalOperationsCodec = t.type({
    create_transport_order_intent: timeBasedDispatchProposalItemOperationCodec,
    delete_transport_order_intent: timeBasedDispatchProposalOperationCodec,
    add_article: unconstrainedDispatchProposalOperationCodec,
    modify_load_item_amount: unconstrainedDispatchProposalOperationCodec,
    change_loading_location: unconstrainedDispatchProposalOperationCodec,
    convert_dispatch_proposal_item_to_kep: unconstrainedDispatchProposalOperationCodec,
});

export type ApiDispatchProposalOperations = t.TypeOf<typeof dispatchProposalOperationsCodec>;
export type ApiTimeBasedDispatchProposalOperation = t.TypeOf<typeof timeBasedDispatchProposalOperationCodec>;
export type ApiTimeBasedDispatchProposalItemOperation = t.TypeOf<typeof timeBasedDispatchProposalItemOperationCodec>;

const dispatchProposalCodec = t.intersection([
    t.type({
        id: t.string,
        termination: terminationCodec,
        items: t.array(dispatchProposalItemCodec),
        transport_order_status: transportOrderStatusCodec,
        supplier_timezone: t.string,
        last_modified_at: t.string,
        operations: dispatchProposalOperationsCodec,
        untouched: t.boolean,
    }),
    t.partial({
        application_reference_number: t.string,
    }),
]);

export type ApiDispatchProposal = t.TypeOf<typeof dispatchProposalCodec>;

export const getDispatchProposalsResponseCodec = t.type({
    items: t.array(dispatchProposalCodec),
});

export enum ApiDispatchProposalProblemLevel {
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    INFO = 'INFO',
}

const dispatchProposalProblemLevelCodec = fromEnum<ApiDispatchProposalProblemLevel>(
    'ApiDispatchProposalProblemLevel',
    ApiDispatchProposalProblemLevel,
);

const dispatchProposalProblemBaseCodec = t.type({
    id: t.string,
    duns_number: t.string,
    affected_date: t.string,
    created_at: t.string,
    level: dispatchProposalProblemLevelCodec,
});

export enum ApiMissingArticleMasterDataProblemMissingData {
    ARTICLE_NET_WEIGHT = 'ARTICLE_NET_WEIGHT',
    COUNTRY_OF_ORIGIN = 'COUNTRY_OF_ORIGIN',
}

const missingArticleMasterDataProblemMissingDataCodec = fromEnum<ApiMissingArticleMasterDataProblemMissingData>(
    'ApiMissingArticleMasterDataProblemMissingData',
    ApiMissingArticleMasterDataProblemMissingData,
);

const missingArticleMasterDataProblemCodec = t.intersection([
    dispatchProposalProblemBaseCodec,
    t.type({
        type: t.literal('MISSING_ARTICLE_MASTER_DATA'),
        missing_data: t.array(missingArticleMasterDataProblemMissingDataCodec),
        article_number_buyer: t.string,
        delivery_schedule_id: t.string,
    }),
]);

const articleQuantityDiscrepancyProblemCodec = t.intersection([
    dispatchProposalProblemBaseCodec,
    t.type({
        type: t.literal('ARTICLE_QUANTITY_DISCREPANCY'),
        dismissible: t.boolean,
        article_number_buyer: t.string,
        delivery_date: t.string,
        target_quantity: quantityCodec,
        plant_code: t.string,
        place_of_discharge: t.string,
    }),
]);

const dispatchProposalNotCompletelyPackagedProblemCodec = t.intersection([
    dispatchProposalProblemBaseCodec,
    t.type({
        type: t.literal('INCOMPLETE_PACKAGING'),
        dismissible: t.boolean,
        dispatch_proposal_id: t.string,
        identifiers: t.array(unpackagedDispatchProposalItemIdentifierCodec),
    }),
]);

const missingPackagingHeightProblemCodec = t.intersection([
    dispatchProposalProblemBaseCodec,
    t.type({
        type: t.literal('MISSING_PACKAGING_HEIGHT'),
        dispatch_proposal_id: t.string,
        giphy_id: t.string,
    }),
]);

const dispatchProposalMissingLoadingLocationProblemCodec = t.intersection([
    dispatchProposalProblemBaseCodec,
    t.type({
        type: t.literal('MISSING_LOADING_LOCATION'),
        dismissible: t.boolean,
        dispatch_proposal_id: t.string,
        identifiers: t.array(dispatchProposalItemIdentifierCodec),
    }),
]);

const dispatchProposalFreightForwarderChangedProblemCodec = t.intersection([
    dispatchProposalProblemBaseCodec,
    t.type({
        type: t.literal('FREIGHT_FORWARDER_CHANGED'),
        dismissible: t.boolean,
        dispatch_proposal_id: t.string,
    }),
    t.partial({
        new_cutoff_date: t.string,
    }),
]);

const dispatchProposalProblemCodec = t.union([
    missingArticleMasterDataProblemCodec,
    articleQuantityDiscrepancyProblemCodec,
    dispatchProposalNotCompletelyPackagedProblemCodec,
    missingPackagingHeightProblemCodec,
    dispatchProposalMissingLoadingLocationProblemCodec,
    dispatchProposalFreightForwarderChangedProblemCodec,
]);

export const getDispatchProposalProblemResponseCodec = t.type({
    items: t.array(dispatchProposalProblemCodec),
});

export type ApiDispatchProposalProblem = t.TypeOf<typeof dispatchProposalProblemCodec>;
export type ApiMissingPackagingHeightProblem = t.TypeOf<typeof missingPackagingHeightProblemCodec>;
export type ApiMissingArticleMasterDataProblem = t.TypeOf<typeof missingArticleMasterDataProblemCodec>;
export type ApiArticleQuantityDiscrepancyProblem = t.TypeOf<typeof articleQuantityDiscrepancyProblemCodec>;
export type ApiDispatchProposalNotCompletelyPackagedProblem = t.TypeOf<
    typeof dispatchProposalNotCompletelyPackagedProblemCodec
>;
export type ApiUnpackagedDispatchProposalItemIdentifier = t.TypeOf<
    typeof unpackagedDispatchProposalItemIdentifierCodec
>;
export type ApiPackagedDispatchProposalItemIdentifier = t.TypeOf<typeof packagedDispatchProposalItemIdentifierCodec>;
export type ApiDispatchProposalMissingLoadingLocationProblem = t.TypeOf<
    typeof dispatchProposalMissingLoadingLocationProblemCodec
>;
export type ApiDispatchProposalFreightForwarderChangedProblem = t.TypeOf<
    typeof dispatchProposalFreightForwarderChangedProblemCodec
>;
export type ApiDispatchProposalItemIdentifier = t.TypeOf<typeof dispatchProposalItemIdentifierCodec>;
export type ApiDispatchProposalProblemResponse = t.TypeOf<typeof getDispatchProposalProblemResponseCodec>;
