import { MeasurementUnitCode } from '../../../domain/common.types';
import { PlaceOfDischargeMasterData } from '../../../domain/placeOfDischargeMasterData.types';
import { ProcessIndicator, SupplierData } from '../../../reducers/deliverySchedules/types';
import { ItemDescriptionCode } from '../../../reducers/types';

type CompleteManualCreationOfDeliverySchedulesFormValuesStep1 = {
    processIndicator: ProcessIndicator;
    articleNumberBuyer: string;
    articleNumberSeller?: string;
    itemShortDescription: string;
    itemShortDescriptionCode: ItemDescriptionCode;
    supplierData: SupplierData;
    placeOfDischarge: PlaceOfDischargeMasterData;
};

type CompleteManualCreationOfDeliverySchedulesFormValuesStep2 = {
    placeOfDelivery: string;
    orderNumber: string;
    defaultShippingLocationId: string;
    measurementUnitCode: MeasurementUnitCode;
    articleNetWeightInKg: number;
    netExplosiveMassInKg?: number;
    countryOfOrigin: string;
} & (
    | {
          isDangerousGoods: true;
          unDangerousGoodsNumber: string;
          dangerousGoodsDescription?: string;
          dangerousGoodsDeclarationException?: string;
      }
    | {
          isDangerousGoods?: false;
          unDangerousGoodsNumber?: undefined;
          dangerousGoodsDescription?: undefined;
          dangerousGoodsDeclarationException?: undefined;
      }
);

type CompleteManualCreationOfDeliverySchedulesFormValuesStep3 = {
    schedulingData: { date: string; value: number }[];
};

export type CompleteManualCreationOfDeliverySchedulesFormValues =
    CompleteManualCreationOfDeliverySchedulesFormValuesStep1 &
        CompleteManualCreationOfDeliverySchedulesFormValuesStep2 &
        CompleteManualCreationOfDeliverySchedulesFormValuesStep3;

type WithPartialArrays<T> = {
    [K in keyof T]: T[K] extends Array<infer U> ? Partial<U>[] : never;
};

export type ManualCreationOfDeliverySchedulesFormValues =
    Partial<CompleteManualCreationOfDeliverySchedulesFormValuesStep1> &
        Partial<CompleteManualCreationOfDeliverySchedulesFormValuesStep2> &
        WithPartialArrays<CompleteManualCreationOfDeliverySchedulesFormValuesStep3>;

export const isComplete = (
    raw: ManualCreationOfDeliverySchedulesFormValues,
): raw is CompleteManualCreationOfDeliverySchedulesFormValues => {
    if (!raw.processIndicator) {
        return false;
    }
    if (!raw.articleNumberBuyer) {
        return false;
    }
    if (!raw.itemShortDescription) {
        return false;
    }
    if (!raw.itemShortDescriptionCode) {
        return false;
    }
    if (!raw.supplierData) {
        return false;
    }
    if (!raw.placeOfDischarge) {
        return false;
    }
    if (!raw.placeOfDelivery) {
        return false;
    }
    if (!raw.orderNumber) {
        return false;
    }
    if (!raw.defaultShippingLocationId) {
        return false;
    }
    if (!raw.measurementUnitCode) {
        return false;
    }
    if (!raw.articleNetWeightInKg) {
        return false;
    }
    if (!raw.countryOfOrigin) {
        return false;
    }
    if (raw.isDangerousGoods && !raw.unDangerousGoodsNumber) {
        return false;
    }
    if (raw.schedulingData.find((it) => !it.date || !it.value)) {
        return false;
    }
    return true;
};
