import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { v4 as uuid } from 'uuid';
import { Dispatch, State } from '../../../../configuration/setup/store';
import { hashPackagingTree, toPackagingOuterDimensionsFromHandlingUnitGroupUpdates } from '../../../../utils';
import {
    fetchPackagingConfigurationForTemplates,
    fetchPackagingTemplatesForShipment,
    getNextLabelNumberData,
} from '../../api/shipment/packagingCalls';
import { HandlingUnitCategory } from '../../components/common/PackagingCommon';
import { AuxiliaryHandlingUnitGroup, AuxiliaryPackagingPayload } from '../../reducers/auxiliaryPackaging.types';
import { packagingSlice } from '../../reducers/shipments/Packaging.reducer';
import {
    ContentPerHandlingUnitGroupEntry,
    GroupedHandlingUnits,
    HandlingUnitGroup,
    HandlingUnitGroupUpdate,
    HandlingUnitGroupsDictionary,
    Ownership,
    PackagingTemplateConfigToApply,
    PackagingTemplatesDictionary,
    UpdateHeterogeneousAdditionalPackagingConfiguration,
    UpdateHomogeneousAdditionalPackagingConfiguration,
    UpdateInnerPackagingConfigurationStepConfiguration,
    UpdatePackagingConfigurationType,
    instanceOfHandlingUnitGroupUpdate,
} from '../../reducers/shipments/packaging.types';
import { LabelNumberData } from '../../reducers/shipments/types';
import {
    getNextAvailableLabelNumber,
    getPackagingForSelectedShipment,
    getUpdatePackagingConfig,
} from '../../selectors/packaging/Packaging.selector';
import { getSelectedShipment, getSelectedShipmentId } from '../../selectors/shipments/Shipments.selector';

export const updateSelectedGroupedHandlingUnits =
    (selectedGroupedHandlingUnits: GroupedHandlingUnits[]) => async (dispatch: Dispatch) =>
        dispatch(packagingSlice.actions.updateSelectedGroupedHandlingUnits(selectedGroupedHandlingUnits));

export const fetchAndStorePackagingTemplatesForShipment =
    (dunsNumber: string, shipmentId: string) => async (dispatch: Dispatch) => {
        return fetchPackagingTemplatesForShipment(dunsNumber, shipmentId)
            .then((packagingTemplates: PackagingTemplatesDictionary) =>
                dispatch(packagingSlice.actions.setPackagingTemplates(packagingTemplates)),
            )
            .catch((error: Error) => {
                Notification.error(error.message);
            });
    };

export const fetchAndStorePackagingConfigurationForTemplates =
    (dunsNumber: string, shipmentId: string, packagingTemplateConfigs: PackagingTemplateConfigToApply[]) =>
    async (dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const shipment = getSelectedShipment(state);
        if (shipment === undefined) {
            return Promise.reject('No selected shipment found. This should never happen.');
        }
        return fetchPackagingConfigurationForTemplates(dunsNumber, shipmentId, packagingTemplateConfigs, shipment.load)
            .then((newPackaging: HandlingUnitGroupUpdate[]) => {
                const existingPackaging = getPackagingForSelectedShipment(state);
                const nextAvailableLableNumber = getNextAvailableLabelNumber(state);
                const labelNumberGenerator = generateLabelNumberSequence(nextAvailableLableNumber);
                dispatch(
                    packagingSlice.actions.updatePackagingForShipment(
                        {
                            packaging: [
                                ...existingPackaging,
                                ...newPackaging.map((handlingUnitUpdate) =>
                                    assignLabelNumbers(handlingUnitUpdate, labelNumberGenerator),
                                ),
                            ],
                            packagingOuterDimensions:
                                toPackagingOuterDimensionsFromHandlingUnitGroupUpdates(newPackaging),
                        },
                        shipmentId,
                    ),
                );
                dispatch(packagingSlice.actions.updateNextAvailableLabelNumber(labelNumberGenerator.next().value));
            })
            .catch((error: Error) => {
                Notification.error(error.message);
            });
    };

export const setSelectedLabelNumber =
    (id: string, labelNumber: number) => async (dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const shipmentId = getSelectedShipmentId(state);
        if (!shipmentId) {
            throw new Error('Shipment must be selected');
        }
        const nextAvailableLabelNumber = getNextAvailableLabelNumber(state);
        const payload = { shipmentId, id, labelNumber };
        if (labelNumber >= nextAvailableLabelNumber!) {
            dispatch(packagingSlice.actions.updateNextAvailableLabelNumber(labelNumber + 1));
        }
        return dispatch(packagingSlice.actions.updateSelectedLabelNumber(payload));
    };

export const getNextAvailableLabelNumberAction = (dunsNumber: string) => async (dispatch: Dispatch) =>
    getNextLabelNumberData(dunsNumber)
        .then((labelNumberData: LabelNumberData) => {
            dispatch(packagingSlice.actions.updateNextAvailableLabelNumber(labelNumberData.labelNumber));
        })
        .catch((error: Error) => {
            Notification.error(error.message);
        });

export const applyUpdatePackagingConfiguration =
    (auxiliaryPackagingPayload: AuxiliaryPackagingPayload, shipmentId: string) =>
    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
    async (dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const updatePackagingConfig = getUpdatePackagingConfig(state);
        const existingPackaging = getPackagingForSelectedShipment(state);
        const nextAvailableLabelNumber = getNextAvailableLabelNumber(state);
        const labelNumberGenerator = generateLabelNumberSequence(nextAvailableLabelNumber);
        if (updatePackagingConfig === undefined || existingPackaging === undefined) {
            throw new Error('Existing or new packaging not set. This should never happen');
        }
        if (updatePackagingConfig.type === UpdatePackagingConfigurationType.INNER_PACKAGING) {
            const updateInnerPackagingConfigurationStepConfiguration =
                updatePackagingConfig as UpdateInnerPackagingConfigurationStepConfiguration;
            const newHandlingUnitGroupsWithAuxiliaryPackaging =
                updateInnerPackagingConfigurationStepConfiguration.handlingUnitGroups.map((handlingUnitGroup) => ({
                    ...handlingUnitGroup,
                    handlingUnit: {
                        ...handlingUnitGroup.handlingUnit,
                        labelNumber: labelNumberGenerator.next().value,
                        auxiliaryPackaging: auxiliaryPackagingPayload.auxiliaryPackaging,
                    },
                }));
            dispatch(
                packagingSlice.actions.updatePackagingForShipment(
                    {
                        packaging: [...existingPackaging, ...newHandlingUnitGroupsWithAuxiliaryPackaging],
                    },
                    shipmentId,
                ),
            );
            dispatch(packagingSlice.actions.updateNextAvailableLabelNumber(labelNumberGenerator.next().value));
            dispatch(packagingSlice.actions.clearUpdatePackagingConfiguration());
        } else {
            const updatePackagingConfigurationOuterPackaging = updatePackagingConfig as
                | UpdateHomogeneousAdditionalPackagingConfiguration
                | UpdateHeterogeneousAdditionalPackagingConfiguration;
            const contentPerHandlingUnit =
                updatePackagingConfigurationOuterPackaging.type ===
                UpdatePackagingConfigurationType.HETEROGENEOUS_ADDITIONAL_PACKAGING
                    ? updatePackagingConfigurationOuterPackaging.contentPerHandlingUnit
                    : [updatePackagingConfigurationOuterPackaging.contentPerHandlingUnit];

            const { matchingHandlingUnitGroupsDictionary, nonMatchingHandlingUnitGroups } = matchHandlingUnitGroups(
                existingPackaging,
                contentPerHandlingUnit,
            );

            const numberOfHandlingUnitsWithFullCapacity = calculateNumberOfHandlingUnitsWithFullCapacity(
                matchingHandlingUnitGroupsDictionary,
                contentPerHandlingUnit,
                updatePackagingConfigurationOuterPackaging.numberOfHandlingUnits,
            );

            const packagedMatchingHandlingUnitGroups: HandlingUnitGroup[] = [];

            for (let i = 0; i < numberOfHandlingUnitsWithFullCapacity; i++) {
                const contents: HandlingUnitGroup[] = [];
                contentPerHandlingUnit.forEach(({ hashOfContainedHandlingUnitGroup, quantity }) => {
                    for (let j = 0; j < quantity; j++) {
                        contents.push(matchingHandlingUnitGroupsDictionary[hashOfContainedHandlingUnitGroup]!.pop()!);
                    }
                });
                packagedMatchingHandlingUnitGroups.push(
                    createHandlingUnitGroup({
                        contents,
                        handlingUnitType: updatePackagingConfigurationOuterPackaging.handlingUnit.type,
                        auxiliaryPackaging: auxiliaryPackagingPayload.auxiliaryPackaging,
                        labelNumber: labelNumberGenerator.next().value,
                        isReusable: updatePackagingConfigurationOuterPackaging.handlingUnit.isReusable,
                        ownership: updatePackagingConfigurationOuterPackaging.handlingUnit.ownership,
                        stackingFactor: updatePackagingConfigurationOuterPackaging.handlingUnit.stackingFactor,
                        tareWeightInKg: updatePackagingConfigurationOuterPackaging.handlingUnit.tareWeightInKg,
                        handlingUnitDescription: updatePackagingConfigurationOuterPackaging.handlingUnit.description,
                    }),
                );
            }

            if (
                numberOfHandlingUnitsWithFullCapacity !==
                updatePackagingConfigurationOuterPackaging.numberOfHandlingUnits
            ) {
                if (contentPerHandlingUnit.length > 1) {
                    throw new Error('handling unit with free capacity is not supported for mixed packages');
                }

                const hash = contentPerHandlingUnit[0].hashOfContainedHandlingUnitGroup;
                const handlingUnitGroupWithRemainingContents = createHandlingUnitGroup({
                    contents: matchingHandlingUnitGroupsDictionary[hash] as HandlingUnitGroup[],
                    handlingUnitType: updatePackagingConfigurationOuterPackaging.handlingUnit.type,
                    auxiliaryPackaging: [
                        ...auxiliaryPackagingPayload.auxiliaryPackaging,
                        ...(auxiliaryPackagingPayload.layerStabilization
                            ? [auxiliaryPackagingPayload.layerStabilization]
                            : []),
                    ],
                    labelNumber: labelNumberGenerator.next().value,
                    isReusable: updatePackagingConfigurationOuterPackaging.handlingUnit.isReusable,
                    ownership: updatePackagingConfigurationOuterPackaging.handlingUnit.ownership,
                    stackingFactor: updatePackagingConfigurationOuterPackaging.handlingUnit.stackingFactor,
                    tareWeightInKg: updatePackagingConfigurationOuterPackaging.handlingUnit.tareWeightInKg,
                    handlingUnitDescription: updatePackagingConfigurationOuterPackaging.handlingUnit.description,
                });

                packagedMatchingHandlingUnitGroups.push(handlingUnitGroupWithRemainingContents);
                matchingHandlingUnitGroupsDictionary[hash] = [];
            }

            const remainingMatchingHandlingUnitGroups = Object.values(
                matchingHandlingUnitGroupsDictionary,
            ).flat() as HandlingUnitGroup[];

            dispatch(
                packagingSlice.actions.updatePackagingForShipment(
                    {
                        packaging: [
                            ...nonMatchingHandlingUnitGroups,
                            ...packagedMatchingHandlingUnitGroups,
                            ...remainingMatchingHandlingUnitGroups,
                        ],
                    },
                    shipmentId,
                ),
            );
            dispatch(packagingSlice.actions.updateNextAvailableLabelNumber(labelNumberGenerator.next().value));
            dispatch(packagingSlice.actions.updateSelectedGroupedHandlingUnits([]));
            dispatch(packagingSlice.actions.clearUpdatePackagingConfiguration());
        }
    };

const matchHandlingUnitGroups = (
    existingHandlingUnitGroups: HandlingUnitGroup[],
    contentPerHandlingUnit: ContentPerHandlingUnitGroupEntry[],
) => {
    const matchingHandlingUnitGroupsDictionary: HandlingUnitGroupsDictionary = {};
    const nonMatchingHandlingUnitGroups: HandlingUnitGroup[] = [];
    const hashes = contentPerHandlingUnit.map((it) => it.hashOfContainedHandlingUnitGroup);

    existingHandlingUnitGroups.forEach((handlingUnitGroup) => {
        if (handlingUnitGroup.quantity !== 1) {
            throw new Error('this code assumes that all handlingUnitGroups have a quantity of 1');
        }
        const hash = hashPackagingTree(handlingUnitGroup);
        if (hashes.includes(hash)) {
            if (matchingHandlingUnitGroupsDictionary[hash] === undefined) {
                matchingHandlingUnitGroupsDictionary[hash] = [handlingUnitGroup];
            } else {
                matchingHandlingUnitGroupsDictionary[hash]!.push(handlingUnitGroup);
            }
        } else {
            nonMatchingHandlingUnitGroups.push(handlingUnitGroup);
        }
    });
    return {
        matchingHandlingUnitGroupsDictionary,
        nonMatchingHandlingUnitGroups,
    };
};

export const assignLabelNumbers = (
    handlingUnitGroupUpdate: HandlingUnitGroupUpdate,
    labelNumberGenerator: IterableIterator<number>,
): HandlingUnitGroup => {
    return {
        ...handlingUnitGroupUpdate,
        handlingUnit: {
            ...handlingUnitGroupUpdate.handlingUnit,
            labelNumber: labelNumberGenerator.next().value,
            contents: handlingUnitGroupUpdate.handlingUnit.contents.map((packageable) => {
                if (instanceOfHandlingUnitGroupUpdate(packageable)) {
                    return assignLabelNumbers(packageable, labelNumberGenerator);
                } else {
                    return packageable;
                }
            }),
        },
    };
};

export const generateLabelNumberSequence = function* (start?: number): IterableIterator<number> {
    const largestLabelNumber = 999_999_999;
    let labelNumber = start ?? 1;
    while (true) {
        yield labelNumber;
        if (labelNumber === largestLabelNumber) {
            labelNumber = 1;
        } else {
            labelNumber++;
        }
    }
};

export const calculateNumberOfHandlingUnitsWithFullCapacity = (
    matchingHandlingUnitGroupsDictionary: HandlingUnitGroupsDictionary,
    contentPerHandlingUnit: ContentPerHandlingUnitGroupEntry[],
    totalNumberOfHandlingUnits: number,
): number => {
    let numberOfHandlingUnitsWithFullCapacity = totalNumberOfHandlingUnits;
    Object.entries(matchingHandlingUnitGroupsDictionary).forEach(([hash, handlingUnitGroup]) => {
        const capacity = contentPerHandlingUnit.find((it) => it.hashOfContainedHandlingUnitGroup === hash)!.quantity;
        const numberOfHandlingUnitGroupsRequiredForPackaging = totalNumberOfHandlingUnits * capacity;

        if (numberOfHandlingUnitGroupsRequiredForPackaging - handlingUnitGroup!.length >= capacity) {
            throw new Error(
                'At most one resulting handling unit must have partial capacity. Having empty boxes should never happen',
            );
        }

        if (numberOfHandlingUnitGroupsRequiredForPackaging > handlingUnitGroup!.length) {
            numberOfHandlingUnitsWithFullCapacity -= 1;
        }
    });
    return numberOfHandlingUnitsWithFullCapacity;
};

const createHandlingUnitGroup = (params: {
    contents: HandlingUnitGroup[];
    handlingUnitType: string;
    auxiliaryPackaging: AuxiliaryHandlingUnitGroup[];
    labelNumber: number;
    isReusable: boolean;
    ownership: Ownership;
    stackingFactor: number;
    tareWeightInKg: number;
    grossWeightInKg?: number;
    netWeightInKg?: number;
    handlingUnitDescription?: string;
}): HandlingUnitGroup => {
    return {
        quantity: 1,
        handlingUnit: {
            id: uuid(),
            type: params.handlingUnitType,
            description: params.handlingUnitDescription,
            category: HandlingUnitCategory.LOAD_CARRIER,
            labelNumber: params.labelNumber,
            contents: params.contents,
            auxiliaryPackaging: params.auxiliaryPackaging,
            isReusable: params.isReusable,
            ownership: params.ownership,
            stackingFactor: params.stackingFactor,
            tareWeightInKg: params.tareWeightInKg,
            grossWeightInKg: params.grossWeightInKg,
            netWeightInKg: params.netWeightInKg,
        },
    };
};
