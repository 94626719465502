import { TagProps } from '@rio-cloud/rio-uikit/Tag';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { fetchAndStoreDeliverySchedules } from '../../actions/deliverySchedules/DeliverySchedules.actions';
import { DeliverySchedulesQueryParameters } from '../../reducers/deliverySchedules/types';
import { getDeliverySchedulesQueryParams } from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { getBuyerName } from './BuyerAbbreviationsMappings';

export const SELECTED_FILTERS_TAG_LIST = 'SELECTED_FILTERS_TAG_LIST';

export const DeliverySchedulesSelectedFilters = () => {
    const query = useAppSelector(getDeliverySchedulesQueryParams);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const dispatchQueryChangeFactory = (changes: Partial<DeliverySchedulesQueryParameters>) => () => {
        dispatch(fetchAndStoreDeliverySchedules({ offset: 0, ...changes }));
    };

    const tags = [
        {
            messageKeyId: 'webedi.common.shipFrom.identifier',
            value: query.supplierIdentifier,
            onDelete: dispatchQueryChangeFactory({ supplierIdentifier: undefined }),
        },
        {
            messageKeyId: 'webedi.plantIdentifier',
            value: query.plantNumber,
            onDelete: dispatchQueryChangeFactory({ plantNumber: undefined }),
        },
        {
            messageKeyId: 'webedi.common.placeOfDischarge',
            value: query.unloadingPoint,
            onDelete: dispatchQueryChangeFactory({ unloadingPoint: undefined }),
        },
        {
            messageKeyId: 'webedi.deliverySchedule.placeOfDelivery',
            value: query.storagePlace,
            onDelete: dispatchQueryChangeFactory({ storagePlace: undefined }),
        },
        {
            messageKeyId: 'webedi.orderNumber',
            value: query.orderNumber,
            onDelete: dispatchQueryChangeFactory({ orderNumber: undefined }),
        },
        {
            messageKeyId: 'webedi.common.manufacturingCompany',
            value: query.manufacturingCompany && getBuyerName(query.manufacturingCompany),
            onDelete: dispatchQueryChangeFactory({ manufacturingCompany: undefined }),
        },
        {
            messageKeyId: 'webedi.common.earliestDeliveryDate',
            value: query.deliveryDateFrom && intl.formatDate(query.deliveryDateFrom),
            onDelete: dispatchQueryChangeFactory({ deliveryDateFrom: undefined }),
        },
    ].filter((kv): kv is FilterTagProps => !!kv.value);

    return (
        <>
            <TagList data-testid={SELECTED_FILTERS_TAG_LIST}>
                {tags.map((tag) => (
                    <FilterTag
                        key={tag.messageKeyId}
                        messageKeyId={tag.messageKeyId}
                        value={tag.value}
                        onDelete={tag.onDelete}
                    />
                ))}
            </TagList>
        </>
    );
};

interface FilterTagProps {
    messageKeyId: string;
    value: 'small';
    onDelete: () => void;
}

const FilterTag = (props: FilterTagProps) => {
    const { onDelete, messageKeyId, value } = props;

    const tagProperties: TagProps = {
        deletable: true,
        onClick: onDelete,
        size: 'small',
    };
    return (
        <Tag {...tagProperties}>
            <FormattedMessage id={messageKeyId} />: {value}
        </Tag>
    );
};
