import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';
import useStateWithValidation from '@rio-cloud/rio-uikit/lib/es/useStateWithValidation';
import { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { usePostOperationMutation } from '../../api/dispatchProposal/dispatchProposalApi';
import {
    DispatchProposalOperationType,
    ModifyLoadItemAmountOperation,
} from '../../api/dispatchProposal/operations.types';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import { getEditLoadItemAmountDialogData } from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { MeasurementUnitCodeDisplay } from '../common/MeasurementUnitCodeDisplay';
import { isDecimalBasedMeasurementUnit } from '../common/form/NumberInput';

export const EditLoadItemAmountDialog = () => {
    const maxAllowedInput = 9999999999;
    const validateAmount = (amount: number) => {
        return amount > 0 && amount < maxAllowedInput;
    };

    const [updateLoadItemAmount] = usePostOperationMutation();
    const dispatch = useAppDispatch();
    const data = useAppSelector(getEditLoadItemAmountDialogData);
    const dunsNumber = useDunsNumberFromPath();
    const [newAmount, setNewAmount, isValid] = useStateWithValidation<number>(
        validateAmount,
        data?.quantity.value ?? 0,
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (data !== undefined) {
            setNewAmount(data.quantity.value);
        }
    }, [data]);

    if (data === undefined || dunsNumber === undefined) {
        return null;
    }

    const onCancel = () => {
        dispatch(dispatchProposalsSlice.actions.setEditLoadItemAmountDialogData(undefined));
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }
        if (data.quantity.value === newAmount) {
            Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
            dispatch(dispatchProposalsSlice.actions.setEditLoadItemAmountDialogData(undefined));
        } else {
            const operation: ModifyLoadItemAmountOperation = {
                type: DispatchProposalOperationType.MODIFY_LOAD_ITEM_AMOUNT,
                dunsNumber,
                dispatchProposalId: data.dispatchProposalId,
                shipmentId: data.shipmentId,
                deliveryNoteNumber: data.deliveryNoteNumber,
                deliveryNotePosition: data.deliveryNotePosition,
                targetAmount: newAmount,
            };

            updateLoadItemAmount(operation)
                .unwrap()
                .then(() => {
                    Notification.success(<FormattedMessage id={'webedi.label.success.save'} />);
                    dispatch(dispatchProposalsSlice.actions.setEditLoadItemAmountDialogData(undefined));
                })
                .catch(() => {
                    // do nothing; the error is already presented by the rtkGlobalErrorHandler
                });
        }
    };

    const renderBody = () => {
        return (
            <>
                <div className='padding-5pct text-left'>
                    <div className='text-size-18 margin-bottom-25'>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.dialog.editLoadItemAmount.subheader'}
                            values={{
                                articleNumberBuyer: data.articleNumberBuyer,
                                nowrap: (chunks: ReactNode) => (
                                    <span className={'text-medium'} style={{ whiteSpace: 'nowrap' }}>
                                        {chunks}
                                    </span>
                                ),
                            }}
                        />
                    </div>
                    <p>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.dialog.editLoadItemAmount.text1'}
                            values={{
                                numberOfDispatchProposalItems: data.numberOfDispatchProposalItems,
                                strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.dialog.editLoadItemAmount.text2'}
                            values={{
                                articleNumberBuyer: data.articleNumberBuyer,
                                strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
                                nowrap: (chunks: ReactNode) => <span style={{ whiteSpace: 'nowrap' }}>{chunks}</span>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage id={'webedi.dispatchProposals.dialog.editLoadItemAmount.text3'} />
                    </p>
                </div>
                <div className='display-flex flex-row padding-x-25 padding-top-25'>
                    <div className='form-group width-100pct'>
                        <label htmlFor={'currentLoadItemAmount'}>
                            <FormattedMessage
                                id={'webedi.dispatchProposals.dialog.editLoadItemAmount.currentTotalAmount'}
                            />
                        </label>
                        <NumberInput
                            id={'currentLoadItemAmount'}
                            unit={<MeasurementUnitCodeDisplay unit={data.quantity.measurementUnitCode} />}
                            value={data.quantity.value}
                            disabled={true}
                        />
                    </div>
                    <div className={'display-flex align-items-center padding-top-10 text-center'}>
                        <span className='rioglyph rioglyph-arrow-right text-size-20 margin-x-25 text-color-gray' />
                    </div>
                    <br />
                    <div className={`form-group width-100pct ${!isValid ? `has-error has-feedback` : ``}`}>
                        <label htmlFor={'newLoadItemAmount'}>
                            <FormattedMessage
                                id={'webedi.dispatchProposals.dialog.editLoadItemAmount.newTotalAmount'}
                            />
                        </label>
                        <NumberInput
                            id={'newLoadItemAmount'}
                            min={-maxAllowedInput}
                            max={maxAllowedInput}
                            digitPrecision={isDecimalBasedMeasurementUnit(data.quantity.measurementUnitCode) ? 3 : 0}
                            value={newAmount}
                            onChange={(value: number | undefined) => setNewAmount(value ?? 0)}
                            unit={<MeasurementUnitCodeDisplay unit={data.quantity.measurementUnitCode} />}
                            errorMessage={
                                isValid ? undefined : (
                                    <FormattedMessage
                                        id={'webedi.dispatchProposals.dialog.editLoadItemAmount.validationError'}
                                        values={{
                                            lowerBound: 1,
                                            upperBound: maxAllowedInput,
                                        }}
                                    />
                                )
                            }
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderFooter = () => {
        return (
            <div className={'pull-right btn-toolbar'}>
                <button type={'button'} className={'btn btn-default'} onClick={onCancel}>
                    <FormattedMessage id={'webedi.label.cancel'} />
                </button>
                <button type={'button'} className={'btn btn-primary'} onClick={onSubmit}>
                    <FormattedMessage id={'webedi.dispatchProposals.dialog.editLoadItemAmount.saveAmount'} />
                </button>
            </div>
        );
    };

    return (
        <Dialog
            show={true}
            title={<FormattedMessage id={'webedi.dispatchProposals.dialog.editLoadItemAmount.header'} />}
            body={renderBody()}
            footer={renderFooter()}
            bsSize={Dialog.SIZE_SM}
            onHide={onCancel}
            onEsc={onCancel}
            data-testid={EDIT_LOAD_ITEM_AMOUNT_DIALOG_TEST_ID}
        />
    );
};

export const EDIT_LOAD_ITEM_AMOUNT_DIALOG_TEST_ID = 'EDIT_LOAD_ITEM_AMOUNT_DIALOG_TEST_ID';
