import { SelectOption } from '@rio-cloud/rio-uikit';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { FormattedMessage } from 'react-intl';

const FILTER_DROPDOWN_TEST_CLASS_NAME = 'FILTER_DROPDOWN_TEST_CLASS_NAME';

interface FilterDropdownProps {
    className?: string;
    dropdownOptions: SelectOption[];
    labelId: string;
    onChange: (id: string | undefined) => void;
}

export const FilterDropdown = (props: FilterDropdownProps) => {
    const compareSelectOptions = (a: SelectOption, b: SelectOption) => {
        if (typeof a.label === 'string' && typeof b.label === 'string') {
            return a.label.localeCompare(b.label);
        } else {
            return 0;
        }
    };
    const sortedOptions = [...props.dropdownOptions].sort(compareSelectOptions);
    return (
        <>
            <div className={props.className}>
                <label htmlFor={props.labelId}>
                    <FormattedMessage id={props.labelId} />
                </label>
                <Select
                    disabled={sortedOptions.length === 0}
                    id={props.labelId}
                    placeholder={<FormattedMessage id={'webedi.common.filter.selectionPlaceholder'} />}
                    options={sortedOptions}
                    onChange={(e: SelectOption | undefined) => props.onChange(e?.id)}
                    className={FILTER_DROPDOWN_TEST_CLASS_NAME}
                    useClear
                />
            </div>
        </>
    );
};
