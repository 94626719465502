import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export const HANDLING_UNIT = 'HANDLING_UNIT_TEST_ID';

type OuterPackagingProps = {
    type: string;
    amount: number;
};

export const OuterPackaging = ({ type, amount }: OuterPackagingProps) => (
    <span data-testid={HANDLING_UNIT} className={'align-items-center'}>
        <span className={'rioglyph rioglyph-parcel padding-right-3'} />
        <FormattedMessage
            id={'webedi.transportOrders.common.outerPackaging'}
            values={{
                amount,
                type,
                strong: (chunks: ReactNode) => <strong className={'padding-left-3'}>{chunks}</strong>,
            }}
        />
    </span>
);
