import { FormattedMessage } from 'react-intl';
import { Carrier } from '../../domain/carrier.types';

export const ReadOnlyCarrierInput = (props: { carrier: Carrier | undefined }) => (
    <>
        <label htmlFor={'carrier-read-only-input'}>
            <FormattedMessage id={'webedi.carrier'} />
        </label>
        <div className={'padding-right-15 margin-bottom-10'}>
            <input
                id={'carrier-read-only-input'}
                className={'form-control'}
                type={'text'}
                disabled={true}
                value={props.carrier?.name ?? '-'}
            />
        </div>
    </>
);
