import { neverReachedFor } from '../../../../utils';
import {
    CommunicationAddress,
    CumulativeQuantityDetails,
    CumulativeQuantitySentOffset,
    CumulativeQuantitySentOffsetCreationRequest,
    DeliveryInstructionNumber,
    DeliveryInstructionReference,
    DeliverySchedule,
    DeliveryScheduleSortParameterName,
    DeliveryScheduleWorkflow,
    DeliverySchedulesQueryResult,
    DeliverySchedulesSortParameters,
    DeliveryType,
    DespatchDocumentReference,
    ItemNumberIdentification,
    LineItem,
    Location,
    ReceivedQuantity,
    ScheduledArticleDetails,
    ScheduledDate,
    ScheduledDateTime,
    ScheduledPeriod,
    ScheduledQuantity,
    SchedulingContact,
    ShipTo,
    SortDirection,
} from '../../reducers/deliverySchedules/types';
import { ExtendedArticleMasterData } from '../../reducers/shipments/types';
import { ItemDescriptionCode, ItemShortDescription } from '../../reducers/types';
import { mapPartner, mapParty } from '../commonMapper';
import { ApiItemDescriptionCode, ApiItemShortDescription } from '../shared/itemShortDescription.types';
import { mapExtendedArticleMasterData, mapExtendedArticleMasterDataToApi } from '../shared/sharedMappers';

import {
    ApiCommunicationAddress,
    ApiCumulativeQuantityDetails,
    ApiCumulativeQuantitySentOffset,
    ApiCumulativeQuantitySentOffsetCreationRequest,
    ApiCumulativeQuantitySentOffsetsQueryResponse,
    ApiDeliveryInstructionNumber,
    ApiDeliveryInstructionReference,
    ApiDeliverySchedule,
    ApiDeliveryScheduleDataUpdateRequestCodec,
    ApiDeliveryScheduleWorkflow,
    ApiDeliverySchedulesQueryResponse,
    ApiDeliveryType,
    ApiDespatchDocumentReference,
    ApiItemNumberIdentification,
    ApiLineItem,
    ApiLineItemIdentifier,
    ApiLocation,
    ApiReceivedQuantity,
    ApiScheduledArticleDetails,
    ApiScheduledDate,
    ApiScheduledDateTime,
    ApiScheduledPeriod,
    ApiScheduledQuantity,
    ApiSchedulingContact,
    ApiShipTo,
    isApiScheduledDate,
    isApiScheduledDateTime,
} from './deliverySchedule.types';

export const mapDeliveryScheduleQueryResult = (
    apiResponse: ApiDeliverySchedulesQueryResponse,
): DeliverySchedulesQueryResult => {
    return {
        deliverySchedules: apiResponse.items.map(mapDeliverySchedule),
        totalCountOfMatchedDeliverySchedules: apiResponse.total_count,
        query: {
            q: apiResponse.query.q ?? '',
            offset: apiResponse.query.offset,
            limit: apiResponse.query.limit,
            supplierIdentifier: apiResponse.query.supplier_identifier,
            plantNumber: apiResponse.query.plant_number,
            unloadingPoint: apiResponse.query.unloading_point,
            storagePlace: apiResponse.query.storage_place,
            orderNumber: apiResponse.query.order_number,
            manufacturingCompany: apiResponse.query.manufacturing_company,
            deliveryDateFrom: apiResponse.query.delivery_date_from,
            sort: mapSortQueryResponse(apiResponse.query.sort),
            excludeSoftDeleted: apiResponse.query.exclude_soft_deleted ?? false,
        },
    };
};

export const mapSortQueryResponse = (sortQueryParameter: string): DeliverySchedulesSortParameters => {
    const result = sortQueryParameter.match(new RegExp('^([+\\-])?([a-z_]+)$')) ?? [];
    if (result.length !== 3) {
        throw Error(`Invalid sortQueryParameter: ${sortQueryParameter}`);
    }
    return {
        sortBy: mapParameterName(result[2]),
        direction: mapSortDirection(result[1]),
    };
};

const mapSortDirection = (sortDirection: string) => {
    if (sortDirection === '-') {
        return SortDirection.DESCENDING;
    }
    return SortDirection.ASCENDING;
};

const mapParameterName = (soryByParameter: string) => {
    switch (soryByParameter) {
        case 'article_number_buyer':
            return DeliveryScheduleSortParameterName.ARTICLE_NUMBER_BUYER;
        case 'latest_delivery_call_off_date':
            return DeliveryScheduleSortParameterName.LATEST_DELIVERY_CALL_OFF_DATE;
        default:
            throw Error(`unknown sortByParameter name: ${soryByParameter}`);
    }
};

export const mapDeliveryScheduleWorkflow = (apiWorkflow: ApiDeliveryScheduleWorkflow): DeliveryScheduleWorkflow => {
    switch (apiWorkflow) {
        case ApiDeliveryScheduleWorkflow.WEBSCM_CLASSIC:
            return DeliveryScheduleWorkflow.WEBSCM_CLASSIC;
        case ApiDeliveryScheduleWorkflow.DISCOVERY_NEXT_GEN:
            return DeliveryScheduleWorkflow.DISCOVERY_NEXT_GEN;
        default:
            throw new Error('Unhandled ApiWorkflow value');
    }
};

export const mapDeliverySchedule = (apiDeliverySchedule: ApiDeliverySchedule): DeliverySchedule => {
    return {
        id: apiDeliverySchedule.id,
        partner: mapPartner(apiDeliverySchedule.partner),
        buyer: mapParty(apiDeliverySchedule.buyer),
        seller: mapParty(apiDeliverySchedule.seller),
        shipFrom: mapParty(apiDeliverySchedule.ship_from),
        shipTo: mapShipTo(apiDeliverySchedule.ship_to),
        articleMasterData:
            apiDeliverySchedule.article_master_data &&
            mapExtendedArticleMasterData(apiDeliverySchedule.article_master_data),
        scheduledArticleDetails: mapScheduledArticleDetails(apiDeliverySchedule.scheduled_article_details),
        cumulativeQuantitySent: apiDeliverySchedule.cumulative_quantity_sent,
        cumulativeQuantityInPreparation: apiDeliverySchedule.cumulative_quantity_in_preparation,
        hasValidArticleMasterData: apiDeliverySchedule.has_valid_article_master_data,
        hasValidCumulativeQuantitySent: apiDeliverySchedule.has_valid_cumulative_quantity_sent,
        hasZeroDemand: apiDeliverySchedule.has_zero_demand,
        flags: apiDeliverySchedule.flags,
        deliveryInstructionReferences: apiDeliverySchedule.delivery_instruction_references.map(
            mapDeliveryInstructionReference,
        ),
        latestDeliveryCallOffDate:
            apiDeliverySchedule.latest_delivery_call_off_date && apiDeliverySchedule.latest_delivery_call_off_date,
        isSoftDeleted: apiDeliverySchedule.is_soft_deleted,
        manuallyCreated: apiDeliverySchedule.manually_created,
        workflow: mapDeliveryScheduleWorkflow(apiDeliverySchedule.workflow),
        includeInDispatchProposalCreation: apiDeliverySchedule.include_in_dispatch_proposal_creation,
    };
};

export const mapDeliveryInstructionReference = (
    apiDeliveryInstructionReference: ApiDeliveryInstructionReference,
): DeliveryInstructionReference => ({
    id: apiDeliveryInstructionReference.id,
    type: apiDeliveryInstructionReference.type,
    ediFileId: apiDeliveryInstructionReference.edi_file_id,
    deliveryInstructionNumber:
        apiDeliveryInstructionReference.delivery_instruction_number &&
        mapDeliveryInstructionNumber(apiDeliveryInstructionReference.delivery_instruction_number),
});

export const mapShipTo = (apiShipTo: ApiShipTo): ShipTo => {
    return {
        shipTo: mapParty(apiShipTo.ship_to),
        placeOfDischarge: mapLocation(apiShipTo.place_of_discharge),
        placeOfDelivery: mapLocation(apiShipTo.place_of_delivery),
        placeOfTranshipment: apiShipTo.place_of_transhipment && mapLocation(apiShipTo.place_of_transhipment),
    };
};

export const mapLocation = (apiLocation: ApiLocation): Location => {
    return {
        locationNameCode: apiLocation.location_name_code,
        responsibleAgencyCode: apiLocation.responsible_agency_code,
        locationName: apiLocation.location_name,
    };
};

export const mapCumulativeQuantitySentOffsetsQueryResult = (
    apiCumulativeQuantitySentOffsetsQueryResponse: ApiCumulativeQuantitySentOffsetsQueryResponse,
): CumulativeQuantitySentOffset[] =>
    apiCumulativeQuantitySentOffsetsQueryResponse.items.map(mapCumulativeQuantitySentOffset);

export const mapCumulativeQuantitySentOffset = (
    apiCumulativeQuantitySentOffset: ApiCumulativeQuantitySentOffset,
): CumulativeQuantitySentOffset => ({
    dateTime: apiCumulativeQuantitySentOffset.date_time,
    previousQuantity: apiCumulativeQuantitySentOffset.previous_quantity,
    newQuantity: apiCumulativeQuantitySentOffset.new_quantity,
    creator: apiCumulativeQuantitySentOffset.creator,
});

export const mapCumulativeQuantitySentOffsetCreationRequestToApi = (
    cumulativeQuantitySentOffsetCreationRequest: CumulativeQuantitySentOffsetCreationRequest,
): ApiCumulativeQuantitySentOffsetCreationRequest => ({
    new_quantity: cumulativeQuantitySentOffsetCreationRequest.newQuantity,
});

export const mapScheduledArticleDetails = (
    apiScheduledArticleDetails: ApiScheduledArticleDetails,
): ScheduledArticleDetails => {
    return {
        lineItem: mapLineItem(apiScheduledArticleDetails.line_item),
        measurementUnitCode: apiScheduledArticleDetails.measurement_unit_code,
        deliveryInstructionNumberNew: mapDeliveryInstructionNumber(apiScheduledArticleDetails.delivery_schedule_number),
        deliveryInstructionNumberOld: apiScheduledArticleDetails.previous_delivery_instruction_number
            ? mapDeliveryInstructionNumber(apiScheduledArticleDetails.previous_delivery_instruction_number)
            : undefined,
        orderNumber: {
            referenceIdentifier:
                apiScheduledArticleDetails.order_document_identifier_buyer_assigned.reference_identifier,
        },
        cumulativeQuantityOrdered:
            apiScheduledArticleDetails.cumulative_quantity_ordered &&
            mapCumulativeQuantityDetails(apiScheduledArticleDetails.cumulative_quantity_ordered),
        cumulativeQuantityReceived:
            apiScheduledArticleDetails.cumulative_quantity_received &&
            mapCumulativeQuantityDetails(apiScheduledArticleDetails.cumulative_quantity_received),
        mostRecentArrivalsOfGoods: apiScheduledArticleDetails.most_recent_arrivals_of_goods.map(mapReceivedQuantity),
        schedulingData: apiScheduledArticleDetails.scheduling_data.map(mapScheduledQuantity),
        schedulingContact:
            apiScheduledArticleDetails.scheduling_contact &&
            mapSchedulingContact(apiScheduledArticleDetails.scheduling_contact),
    };
};

export const mapLineItemIdentifier = (apiLineItemIdentifier: ApiLineItemIdentifier) => {
    return {
        itemNumberIdentification: mapItemNumberIdentification(apiLineItemIdentifier.item_number_identification),
        lineItemIdentifier: apiLineItemIdentifier.line_item_identifier,
    };
};

export const mapItemShortDescription = (apiItemShortDescription: ApiItemShortDescription): ItemShortDescription => {
    return {
        itemDescription: apiItemShortDescription.item_description,
        itemDescriptionCode: mapItemDescriptionCode(apiItemShortDescription.item_description_code),
    };
};

const mapItemDescriptionCode = (code: ApiItemDescriptionCode): ItemDescriptionCode => {
    switch (code) {
        case ApiItemDescriptionCode.PRODUCTION:
            return ItemDescriptionCode.PRODUCTION;
        case ApiItemDescriptionCode.SPAREPART:
            return ItemDescriptionCode.SPAREPART;
        case ApiItemDescriptionCode.PRODUCTION_AND_SPAREPART:
            return ItemDescriptionCode.PRODUCTION_AND_SPAREPART;
        case ApiItemDescriptionCode.TRY_OUT:
            return ItemDescriptionCode.TRY_OUT;
        case ApiItemDescriptionCode.PILOT:
            return ItemDescriptionCode.PILOT;
        case ApiItemDescriptionCode.ADDITIONAL_REQUIREMENT:
            return ItemDescriptionCode.ADDITIONAL_REQUIREMENT;
        case ApiItemDescriptionCode.INITIAL_SAMPLE:
            return ItemDescriptionCode.INITIAL_SAMPLE;
        case ApiItemDescriptionCode.SAMPLE:
            return ItemDescriptionCode.SAMPLE;
        case ApiItemDescriptionCode.OTHER:
            return ItemDescriptionCode.OTHER;
        default:
            return neverReachedFor(code);
    }
};

export const mapLineItem = (apiLineItem: ApiLineItem): LineItem => {
    return {
        lineItemIdentifier: mapLineItemIdentifier(apiLineItem.line_item_identifier),
        itemShortDescriptions: apiLineItem.item_short_descriptions.map(mapItemShortDescription),
    };
};

export const mapItemNumberIdentification = (
    apiItemNumberIdentification: ApiItemNumberIdentification,
): ItemNumberIdentification => {
    return {
        itemIdentifier: apiItemNumberIdentification.item_identifier,
        itemTypeIdentificationCode: apiItemNumberIdentification.item_type_identification_code,
    };
};

export const mapDeliveryInstructionNumber = (
    apiDeliveryInstructionNumber: ApiDeliveryInstructionNumber,
): DeliveryInstructionNumber => {
    return {
        referenceIdentifier: apiDeliveryInstructionNumber.reference_identifier,
        referenceDate: apiDeliveryInstructionNumber.reference_date,
    };
};

export const mapCumulativeQuantityDetails = (
    apiCumulativeQuantityDetails: ApiCumulativeQuantityDetails,
): CumulativeQuantityDetails => {
    return {
        quantity: apiCumulativeQuantityDetails.quantity,
        date: apiCumulativeQuantityDetails.date,
    };
};

export const mapReceivedQuantity = (apiReceivedQuantity: ApiReceivedQuantity): ReceivedQuantity => ({
    quantity: apiReceivedQuantity.quantity,
    receiptDate: apiReceivedQuantity.receipt_date,
    despatchDocumentReferences: apiReceivedQuantity.despatch_document_references.map(mapDespatchDocumentReference),
});

export const mapDespatchDocumentReference = (
    apiDespatchDocumentReference: ApiDespatchDocumentReference,
): DespatchDocumentReference => ({
    referenceCodeQualifier: apiDespatchDocumentReference.reference_code_qualifier,
    referenceIdentifier: apiDespatchDocumentReference.reference_identifier,
    referenceDocumentDate: apiDespatchDocumentReference.reference_document_date,
});

export const mapScheduledQuantity = (apiScheduledQuantity: ApiScheduledQuantity): ScheduledQuantity => {
    return {
        deliveryPlanCommitmentLevelCode: apiScheduledQuantity.delivery_plan_commitment_level_code,
        quantity: apiScheduledQuantity.quantity,
        outstandingQuantity: apiScheduledQuantity.outstanding_quantity,
        scheduledDateTime: mapScheduledDateTimeOrPeriod(apiScheduledQuantity.scheduled_delivery_time),
        processIndicator: apiScheduledQuantity.process_indicator,
        requestCondition: apiScheduledQuantity.request_condition,
    };
};

export const mapScheduledDateTimeOrPeriod = (
    apiScheduledDateTimeOrPeriod: ApiScheduledDate | ApiScheduledDateTime | ApiScheduledPeriod,
): ScheduledDate | ScheduledDateTime | ScheduledPeriod => {
    if (isApiScheduledDateTime(apiScheduledDateTimeOrPeriod)) {
        return {
            scheduledDateTime: apiScheduledDateTimeOrPeriod.date_time,
            type: mapDeliveryType(apiScheduledDateTimeOrPeriod.type),
        };
    }

    if (isApiScheduledDate(apiScheduledDateTimeOrPeriod)) {
        return {
            scheduledDate: apiScheduledDateTimeOrPeriod.date,
            type: mapDeliveryType(apiScheduledDateTimeOrPeriod.type),
        };
    }
    return {
        earliestDeliveryDate: apiScheduledDateTimeOrPeriod.earliest_delivery_date,
        latestDeliveryDate: apiScheduledDateTimeOrPeriod.latest_delivery_date,
        type: mapDeliveryType(apiScheduledDateTimeOrPeriod.type),
    };
};

const mapDeliveryType = (type: ApiDeliveryType): DeliveryType => {
    switch (type) {
        case ApiDeliveryType.SHIPMENT:
            return DeliveryType.SHIPMENT;
        case ApiDeliveryType.DELIVERY:
            return DeliveryType.DELIVERY;
        default:
            return neverReachedFor(type);
    }
};

export const mapSchedulingContact = (apiSchedulingContact: ApiSchedulingContact): SchedulingContact => ({
    id: apiSchedulingContact.id,
    name: apiSchedulingContact.name,
    communicationAddresses: apiSchedulingContact.communication_addresses.map(mapCommunicationAddress),
});

export const mapCommunicationAddress = (
    apiCommunicationAddressCodec: ApiCommunicationAddress,
): CommunicationAddress => ({
    identifier: apiCommunicationAddressCodec.identifier,
    codeQualifier: apiCommunicationAddressCodec.code_qualifier,
});

export const mapToApiDeliveryScheduleDataUpdateRequest = (
    articleMasterData: ExtendedArticleMasterData,
    includeInDispatchProposalCreation: boolean,
): ApiDeliveryScheduleDataUpdateRequestCodec => ({
    article_master_data: mapExtendedArticleMasterDataToApi(articleMasterData),
    include_in_dispatch_proposal_creation: includeInDispatchProposalCreation,
});
