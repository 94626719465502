import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isReadOnlyAdmin } from '../../../../../configuration/tokenHandling/selectors';
import { fetchAndStoreLisonPackagingTemplate } from '../../../actions/deliverySchedules/PackagingTemplate.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import {
    getSelectedDeliveryScheduleId,
    isLoadingLisonPackagingTemplate,
} from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { Tooltip } from '../../common/Tooltip';

export const PackagingTemplateLisonRefreshButton = () => {
    const spinning = useAppSelector(isLoadingLisonPackagingTemplate);
    const dunsNumber = useDunsNumberFromPath();
    const selectedDeliveryScheduleId = useAppSelector(getSelectedDeliveryScheduleId);
    const isReadOnly = useAppSelector(isReadOnlyAdmin);
    const dispatch = useAppDispatch();

    const onClick = async () => {
        dispatch(fetchAndStoreLisonPackagingTemplate(dunsNumber!, selectedDeliveryScheduleId!));
    };

    const content = (
        <button className={`btn btn-default ${spinning || isReadOnly ? 'disabled' : ''}`} onClick={onClick}>
            <span className={`rioglyph rioglyph-refresh ${spinning ? 'spinning' : ''}`} />
            <FormattedMessage id={'webedi.label.refresh'} />
            {isReadOnly && (
                <span
                    className={'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'}
                />
            )}
        </button>
    );

    if (isReadOnly) {
        return (
            <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'bottom'}>
                <span>{content}</span>
            </Tooltip>
        );
    }

    return content;
};
