import { Dispatch, State } from '../../../../configuration/setup/store';
import {
    startAsnGenerationForShipment,
    testIfFileIsAvailableInS3,
} from '../../api/shipmentDocuments/shipmentDocumentCalls';
import { defaultFixedDelayInMs, defaultTimeoutInMs, waitByRetryingForResponseToBeAvailable } from '../../api/util';
import { getSelectedShipment } from '../../selectors/shipments/Shipments.selector';
import { ensureValueExists } from '../ensureValueExists';
import { testIfShipmentFileSummaryIsAvailable } from '../files/Files.actions';
import { getDownloadInformationForSelectedShipment } from './Shipments.actions';

export const getAsnUrlForSelectedShipment =
    (dunsNumber: string, timeoutInMs: number = defaultTimeoutInMs, fixedDelayInMs: number = defaultFixedDelayInMs) =>
    async (dispatch: Dispatch, getState: () => State) => {
        const state = getState();
        const selectedShipment = ensureValueExists(getSelectedShipment, state, 'Selected Shipment');
        let asnUrl: string;
        let asnFilename: string = '';
        if (selectedShipment.exported) {
            await waitByRetryingForResponseToBeAvailable(
                () => testIfShipmentFileSummaryIsAvailable(dunsNumber, selectedShipment.id),
                fixedDelayInMs,
                timeoutInMs,
            );
            const { url, filename } = await dispatch(getDownloadInformationForSelectedShipment(dunsNumber));
            asnUrl = url;
            asnFilename = filename;
        } else {
            asnUrl = await startAsnGenerationForShipment(dunsNumber, selectedShipment.id);
        }
        await waitByRetryingForResponseToBeAvailable(
            () => testIfFileIsAvailableInS3(asnUrl),
            fixedDelayInMs,
            timeoutInMs,
        );
        return { url: asnUrl, filename: asnFilename };
    };
