import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { useRef } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { fieldNames } from '../courierOrderIntentDialog.types';

export const AcceptTransmissionOfDataCheckbox = () => {
    const ref = useRef<HTMLInputElement>(null);
    return (
        <Field name={fieldNames.transmissionOfData} type='checkbox'>
            {({ input, meta }: FieldRenderProps<boolean>) => (
                <div className={`form-group ${meta.error && 'has-error has-feedback'} `}>
                    <Checkbox
                        {...input}
                        error={meta.error && meta.touched}
                        required
                        className='display-block position-relative'
                        inputRef={ref}
                    >
                        <FormattedMessage
                            id={'webedi.dispatchProposals.expressParcel.dialog.firstStep.transmissionOfData'}
                        />
                        {meta.error && meta.touched && (
                            <>
                                <span className='help-block' style={{ marginLeft: '-23px' }}>
                                    <FormattedMessage id={meta.error} />
                                </span>
                            </>
                        )}
                    </Checkbox>
                    {meta.error && meta.touched && ref.current?.focus()}
                </div>
            )}
        </Field>
    );
};
