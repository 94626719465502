import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import SaveableInput from '@rio-cloud/rio-uikit/lib/es/components/saveableInput/SaveableInput';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import {
    BaseSyntheticEvent,
    KeyboardEvent,
    KeyboardEventHandler,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { Tooltip as WebEDIToolTip } from '../../../../app/components/common/Tooltip';
import { MeasurementUnitCode, Quantity } from '../../../domain/common.types';
import {
    ArticleContent,
    ArticleSuggestion,
    DispatchProposal,
    DispatchProposalItem,
    PackagedDispatchProposalItem,
    isDispatchProposalItem,
    isPackagedDispatchProposalItem,
    isUnpackagedDispatchProposalItem,
} from '../../../domain/dispatchProposal.types';
import { dispatchProposalsSlice } from '../../../reducers/dispatchProposal/DispatchProposalsReducer';
import { ArticleNumber } from '../../common/ArticleNumber';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { getDefaultDigitPrecision, isDecimalBasedMeasurementUnit } from '../../common/form/NumberInput';
import { getBuyerName } from '../../deliverySchedules/BuyerAbbreviationsMappings';

export const BUYER_COLUMN_TEST_ID = 'BUYER_COLUMN_TEST_ID';

export const PLANT_COLUMN_TEST_ID = 'PLANT_COLUMN_TEST_ID';
export const EDITABLE_ARTICLE_NUMBER_COLUMN_TEST_ID = 'EDITABLE_ARTICLE_NUMBER_COLUMN_TEST_ID';

export const BuyerColumn = (props: { item: DispatchProposalItem | ArticleSuggestion }) => (
    <td data-testid={BUYER_COLUMN_TEST_ID}>
        <div className={'ellipsis-1'}>{getBuyerName(props.item.buyer.name)}</div>
    </td>
);

export const PlantColumn = (props: { item: DispatchProposalItem | ArticleSuggestion }) => {
    return (
        <td data-testid={PLANT_COLUMN_TEST_ID}>
            <div className='display-flex flex-row gap-15'>
                <div className='display-flex align-items-center'>
                    <span className='rioglyph rioglyph-factory text-size-18 padding-right-5' aria-hidden='true' />
                    <span>{props.item.shipTo.plantCode}</span>
                </div>
                <div className='display-flex ellipsis-1 align-items-center'>
                    <span className='rioglyph rioglyph-load text-size-18 padding-right-5' aria-hidden='true' />
                    <span className='ellipsis-1'>{props.item.shipTo.placeOfDischarge}</span>
                </div>
            </div>
        </td>
    );
};

export const EditableArticleNumberColumn = (props: {
    item: DispatchProposalItem | ArticleSuggestion;
    dispatchProposal: DispatchProposal | undefined;
    onSaveArticleSuggestion: (item: ArticleSuggestion, quantity: number) => void;
    onSaveDispatchProposalItem: (item: DispatchProposalItem, quantity: number) => void;
    disabled?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [isEditMode, setIsEditMode] = useState(!isDispatchProposalItem(props.item));

    const inputRef = useRef<HTMLInputElement>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        if (!isDispatchProposalItem(props.item)) {
            inputRef.current?.focus();
        }
    }, []);

    const item = props.item;
    // FIXME If in the future these values must be calculated at various locations,
    // move the calculations into or next to the domain models
    let articleNumber: string;
    let initialQuantity: number | undefined;
    let unit: MeasurementUnitCode;

    if (isDispatchProposalItem(item)) {
        if (isUnpackagedDispatchProposalItem(item)) {
            articleNumber = item.articleContent.articleNumberBuyer;
            initialQuantity = item.articleContent.quantity.value;
            unit = item.articleContent.quantity.measurementUnitCode;
        } else if (item.articleContents.length === 1) {
            articleNumber = item.articleContents[0].articleNumberBuyer;
            initialQuantity = item.articleContents[0].quantity.value;
            unit = item.articleContents[0].quantity.measurementUnitCode;
        } else {
            /*
                In this case, MultipleArticleNumbers will be returned; so these values are not used.
                Nevertheless, the compiler complains if these values are not set.
                Cannot return MultipleArticleNumbers from here,
                since useState must be used after setting initialQuantity.
            */
            articleNumber = 'unused';
            unit = MeasurementUnitCode.PIECE;
        }
    } else {
        articleNumber = item.articleNumberBuyer;
        initialQuantity = undefined;
        unit = item.measurementUnitCode;
    }

    const [quantity, setQuantity] = useState(initialQuantity);

    useEffect(() => {
        setQuantity(initialQuantity);
    }, [initialQuantity]);

    if (isDispatchProposalItem(item) && isPackagedDispatchProposalItem(item) && item.articleContents.length > 1) {
        return <MultipleArticleNumbers item={item} />;
    }

    const abortEditMode = () => {
        dispatch(dispatchProposalsSlice.actions.stopAddArticleToDispatchProposalWorkflow());
        setIsEditMode(false);
        setQuantity(initialQuantity);
    };

    const hasQuantityChanged = () => {
        return initialQuantity !== quantity;
    };

    const handleEditModeSave = () => {
        if (quantity === undefined) {
            return;
        }
        if (isDispatchProposalItem(item)) {
            props.onSaveDispatchProposalItem(item, quantity);
        } else {
            props.onSaveArticleSuggestion(item, quantity);
        }
    };

    const openEditQuantityDialog = () => {
        // TODO: This will have to be adjusted when we support heterogeneous packaging for dispatch proposals.
        // Right now we assume that the first article content for a packaged item corresponds to the selected
        // article and that heterogeneous packaging will never contain two load items with the same article number
        let articleNumberBuyer: string;
        let deliveryNoteNumber: number;
        let deliveryNotePosition: number;
        if (!isDispatchProposalItem(item) || props.dispatchProposal === undefined) {
            return;
        } else if (isUnpackagedDispatchProposalItem(item)) {
            articleNumberBuyer = item.articleContent.articleNumberBuyer;
            deliveryNoteNumber = item.identifier.deliveryNoteNumber;
            deliveryNotePosition = item.identifier.deliveryNotePosition;
        } else {
            articleNumberBuyer = item.articleContents[0].articleNumberBuyer;
            deliveryNoteNumber = item.referencedDeliveryNotePositions[0].deliveryNoteNumber;
            deliveryNotePosition = item.referencedDeliveryNotePositions[0].position;
        }
        const shipmentId = item.identifier.shipmentId;

        const matchingItems = props.dispatchProposal.items.filter((it) => {
            if (it.identifier.shipmentId !== shipmentId) {
                return false;
            }
            if (isUnpackagedDispatchProposalItem(it)) {
                return (
                    it.identifier.deliveryNoteNumber === deliveryNoteNumber &&
                    it.identifier.deliveryNotePosition === deliveryNotePosition
                );
            } else {
                return it.referencedDeliveryNotePositions.some(
                    (position) =>
                        position.deliveryNoteNumber === deliveryNoteNumber &&
                        position.position === deliveryNotePosition,
                );
            }
        });
        const totalAmount = matchingItems
            .flatMap((it) => (isUnpackagedDispatchProposalItem(it) ? [it.articleContent] : it.articleContents))
            .filter((it) => it.articleNumberBuyer === articleNumberBuyer)
            .reduce((sum, it) => sum + it.quantity.value, 0);

        dispatch(
            dispatchProposalsSlice.actions.setEditLoadItemAmountDialogData({
                dispatchProposalId: props.dispatchProposal.id,
                shipmentId,
                deliveryNoteNumber,
                deliveryNotePosition,
                articleNumberBuyer,
                quantity: { value: totalAmount, measurementUnitCode: unit },
                numberOfDispatchProposalItems: matchingItems.length,
            }),
        );
    };

    return (
        <td data-testid={EDITABLE_ARTICLE_NUMBER_COLUMN_TEST_ID}>
            <div className='display-flex align-items-center'>
                <WebEDIToolTip text={articleNumber} placement={'top'}>
                    <span className='min-width-100 margin-right-15 ellipsis-1'>
                        <ArticleNumber articleNumber={articleNumber} />
                    </span>
                </WebEDIToolTip>
                <span className={'flex-1-0'}>
                    {isEditMode ? (
                        <EditMode
                            quantity={quantity}
                            unit={unit}
                            onChange={setQuantity}
                            onClick={() => {
                                handleEditModeSave();
                                setIsEditMode(false);
                            }}
                            onDiscard={abortEditMode}
                            hasQuantityChanged={hasQuantityChanged}
                            inputRef={inputRef}
                        />
                    ) : (
                        <QuantityReadMode
                            quantity={quantity}
                            unit={unit}
                            disabled={props.disabled}
                            onClick={openEditQuantityDialog}
                        />
                    )}
                </span>
            </div>
        </td>
    );
};

const MultipleArticleNumbers = (props: { item: PackagedDispatchProposalItem }) => {
    const articleContents = props.item.articleContents;
    const moreThanTwoContents = props.item.articleContents.length > 2;

    const QuantityRow = ({ quantity, suffix }: { quantity: Quantity; suffix?: ReactNode }) => (
        <div className={'padding-left-20'}>
            {quantity.value} {<MeasurementUnitCodeDisplay unit={quantity.measurementUnitCode} />}
            {suffix}
        </div>
    );

    const shortedArticleNumbers = (
        <>
            <div>
                <ArticleNumber articleNumber={articleContents[0].articleNumberBuyer} />
            </div>
            <div>
                <ArticleNumber articleNumber={articleContents[1].articleNumberBuyer} />
            </div>
        </>
    );

    const shortenedQuantities = (
        <>
            <QuantityRow quantity={articleContents[0].quantity} />
            <QuantityRow quantity={articleContents[1].quantity} suffix={moreThanTwoContents ? ', ...' : ''} />
        </>
    );

    const displayedContent = (
        <div className='display-flex align-items-center'>
            <span className='width-100'>{shortedArticleNumbers}</span>
            <span className={'flex-1-0'}>{shortenedQuantities}</span>
        </div>
    );

    // The ArticleContent doesn't have a unique key, so generate one.
    // DisPropItems are grouped into ArticleContents by DeliveryNote number and position.
    // There might be two DeliveryNote positions for the same DeliverySchedule and with the same quantity.
    // In this case, the key won't be unique.
    // But for now this is sufficient.
    const keyForArticleContent = (content: ArticleContent) => content.deliveryScheduleId + content.quantity.value;

    const allArticleNumbers = articleContents.map((content) => (
        <div key={keyForArticleContent(content)}>
            <ArticleNumber articleNumber={content.articleNumberBuyer} />
        </div>
    ));

    const allQuantities = articleContents.map((content) => (
        <QuantityRow key={keyForArticleContent(content)} quantity={content.quantity} />
    ));

    const tooltip = (
        <Tooltip>
            <div className='display-flex align-items-center'>
                <span className='width-90'>{allArticleNumbers}</span>
                <span className={'flex-1-0'}>{allQuantities}</span>
            </div>
        </Tooltip>
    );

    const columnContent = moreThanTwoContents ? (
        <OverlayTrigger placement={'top-start'} trigger={['hover', 'focus']} overlay={tooltip}>
            {displayedContent}
        </OverlayTrigger>
    ) : (
        displayedContent
    );

    return <td data-testid={EDITABLE_ARTICLE_NUMBER_COLUMN_TEST_ID}>{columnContent}</td>;
};

const openDialog = debounce((callback: () => void) => {
    callback();
}, 200);

export const EDITABLE_NUMBER_INPUT_TEST_ID = 'EDITABLE_NUMBER_INPUT_TEST_ID';

export type EditModeProps = {
    quantity?: number;
    unit: MeasurementUnitCode;
    onChange: (value: number) => void;
    onClick: () => void;
    onDiscard: () => void;
    hasQuantityChanged: () => boolean;
    inputRef: RefObject<HTMLInputElement>;
};
export const EditMode = (props: EditModeProps) => {
    const intl = useIntl();

    const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);

    const findSaveButton = () => {
        return props.inputRef.current?.nextSibling?.nextSibling?.firstChild as HTMLButtonElement;
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        findSaveButton()?.click();

        setTimeout(() => {
            props.inputRef.current?.focus();
        }, 1);
    }, []);

    const onBlur = () => {
        if (props.hasQuantityChanged() || props.quantity === undefined) {
            openDialog(() => {
                setShowDiscardConfirmation(true);
            });
        } else {
            props.onDiscard();
        }
    };

    const formatNumberWithPrecision = (inputValue?: string) => {
        const digitPrecision = getDefaultDigitPrecision(props.unit);
        const updatedValue = !isEmpty(inputValue)
            ? Number(Number(inputValue).toFixed(digitPrecision))
            : Number(inputValue);
        props.onChange(updatedValue);
    };

    const onInputChanged = (event: BaseSyntheticEvent) => {
        const value = event.target.value;
        if (value) {
            formatNumberWithPrecision(value);
        } else {
            props.onChange(value);
        }
    };

    const onValueChanged = () => {
        const quantity = props.quantity ?? 0;
        if (quantity > 0) {
            openDialog.cancel();
            props.onClick();
        }
    };

    const onEscape = () => {
        setShowDiscardConfirmation(false);
        props.onDiscard();
    };

    const blockScientificNotation: KeyboardEventHandler<HTMLInputElement> = (event: KeyboardEvent) => {
        if (['e', 'E', '+', '-'].includes(event.key)) {
            event.preventDefault();
        }
    };
    return (
        <>
            <SaveableInput
                data-testid={EDITABLE_NUMBER_INPUT_TEST_ID}
                className={'margin-0 max-width-200'}
                unit={<MeasurementUnitCodeDisplay unit={props.unit} />}
                value={`${props.quantity ?? 0}`}
                onValueChanged={onValueChanged}
                onEsc={onEscape}
                inputClassName={'no-controls'}
                isValid={(props.quantity ?? 0) > 0}
                placeholder={intl.formatMessage({
                    id: 'webedi.dispatchProposals.overview.expander.table.item.quantity.placeholder',
                })}
                inputProps={{
                    onBlur,
                    // @ts-expect-error as this property does not exist in the type definition
                    type: 'number',
                    ref: props.inputRef,
                    min: 0,
                    step: isDecimalBasedMeasurementUnit(props.unit) ? 0.01 : 1,
                    onChange: onInputChanged,
                    onKeyDown: blockScientificNotation,
                }}
            />

            <ConfirmationDialog
                show={showDiscardConfirmation}
                title={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.title'} />}
                content={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.description'} />}
                bsSize={Dialog.SIZE_SM}
                onClickConfirm={() => {
                    setShowDiscardConfirmation(false);
                    props.onDiscard();
                }}
                onClickCancel={() => {
                    setShowDiscardConfirmation(false);
                    props.inputRef.current?.focus();
                }}
                cancelButtonText={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.back'} />}
                confirmButtonText={<FormattedMessage id={'webedi.discardUnsavedChangesDialog.discard'} />}
                useOverflow
            />
        </>
    );
};

const QuantityReadMode = (props: {
    quantity?: number;
    unit: MeasurementUnitCode;
    onClick: () => void;
    disabled?: boolean;
}) => (
    <Button
        bsStyle={'primary'}
        variant={'link'}
        onClick={props.onClick}
        disabled={props.disabled}
        className={'margin-0 padding-x-5 padding-y-0'}
    >
        <span>
            {props.quantity} {<MeasurementUnitCodeDisplay unit={props.unit} />}
        </span>
        <span className='rioglyph rioglyph-pencil margin-left-5' aria-hidden='true' />
    </Button>
);
