const MAN_BUYER_IDS = [
    '0110',
    '0120',
    '0156',
    '0160',
    '0173',
    '0174',
    '0200',
    '0260',
    '0300',
    '0310',
    '0320',
    '0340',
    '0502',
    '0504',
    '0509',
];
const buyerAbbreviationsMAN: [string, string][] = MAN_BUYER_IDS.map((id) => [id, 'MAN Truck & Bus SE']);

const buyerAbbreviationsVw = new Map([
    ['VW', 'Volkswagen Europe'],
    ['AU', 'AUDI Europe'],
    ['GY', 'AUDI Hungaria'],
    ['AM', 'AUDI Mexico'],
    ['LA', 'Lamborghini Italy'],
    ['BY', 'Bentley UK'],
    ['RU', 'Volkswagen Russia'],
    ['IN', 'Volkswagen India'],
    ['US', 'Volkswagen America'],
    ['PA', 'Porsche Osnabrück'],
    ['AR', 'Volkswagen Argentina'],
    ['CS', 'SKODA Europe'],
    ['IS', 'SKODA India'],
    ['SE', 'Seat Spain'],
    ['MX', 'Volkswagen Mexico'],
    ['PORSCHE', 'Porsche AG'],
]);

const POWERCO_BUYER_IDS = Array.from({ length: 64 }, (_, index) => (index + 1).toString().padStart(3, '0'));
const buyerAbbreviationsPowerCo: [string, string][] = POWERCO_BUYER_IDS.map((id) => [id, 'PowerCo SE']);

const BUYER_ABBREVIATIONS_MAPPINGS = new Map([
    ...buyerAbbreviationsVw,
    ...buyerAbbreviationsMAN,
    ...buyerAbbreviationsPowerCo,
]);

if (
    BUYER_ABBREVIATIONS_MAPPINGS.size !==
    buyerAbbreviationsVw.size + buyerAbbreviationsMAN.length + buyerAbbreviationsPowerCo.length
) {
    throw new Error('Duplicate buyer identifiers found!');
}

export const getBuyerName = (identifier: string) => {
    if (BUYER_ABBREVIATIONS_MAPPINGS.has(identifier)) {
        return BUYER_ABBREVIATIONS_MAPPINGS.get(identifier);
    }
    return identifier;
};
