import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isAdmin, isReadOnlyAdmin, isSupplier } from '../../../../../configuration/tokenHandling/selectors';
import { deleteDeliveryScheduleAndRefresh } from '../../../actions/deliverySchedules/DeliverySchedules.actions';
import { createShipment } from '../../../actions/shipments/Shipments.actions';
import { mapShipToToPlaceOfDischarge } from '../../../api/shipment/shipmentMapper';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { DeliverySchedule, DeliveryScheduleWorkflow } from '../../../reducers/deliverySchedules/types';
import { PlaceOfDischarge, ShipmentCreationLoadItem } from '../../../reducers/shipments/types';
import { getDeliverySchedulesQueryParams } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { Tooltip } from '../../common/Tooltip';
import { DeletionConfirmationDialog } from '../../common/dialog/DeletionConfirmationDialog';

export const DELIVERY_SCHEDULES_DETAILS_FOOTER_TEST_ID = 'DELIVERY_SCHEDULES_DETAILS_FOOTER_TEST_ID';

export const DeliveryScheduleDetailsFooterSection = (props: {
    deliverySchedule: DeliverySchedule;
    hideDialog: () => void;
}) => {
    const dispatch = useAppDispatch();

    const dunsNumber = useDunsNumberFromPath();
    const isAdminUser = useAppSelector(isAdmin);
    const isSupplierUser = useAppSelector(isSupplier);
    const deliverySchedulesQueryParams = useAppSelector(getDeliverySchedulesQueryParams);

    const deliverySchedule = props.deliverySchedule;
    const [showDeletionConfirmationDialog, setShowDeletionConfirmationDialog] = useState(false);

    if (deliverySchedule === undefined) {
        return null;
    }

    const deleteDeliverySchedule = () => {
        dispatch(deleteDeliveryScheduleAndRefresh(dunsNumber!, deliverySchedule.id, deliverySchedulesQueryParams));
        props.hideDialog();
    };

    const handleDeletionConfirmation = () => {
        setShowDeletionConfirmationDialog(false);
        deleteDeliverySchedule();
    };

    const DeleteButton = () => {
        return (
            <button
                className={'btn-danger btn btn-default  btn-link'}
                onClick={() => setShowDeletionConfirmationDialog(true)}
            >
                <span className={'rioglyph rioglyph-trash'} />
                <FormattedMessage id={'webedi.label.delete'} />
            </button>
        );
    };

    const renderCreateShipmentButton =
        deliverySchedule &&
        !deliverySchedule.hasZeroDemand &&
        deliverySchedule.workflow !== DeliveryScheduleWorkflow.DISCOVERY_NEXT_GEN;
    const renderDeleteFunctionalityCondition = isAdminUser || (isSupplierUser && deliverySchedule.hasZeroDemand);

    return (
        <div>
            <div data-testid={DELIVERY_SCHEDULES_DETAILS_FOOTER_TEST_ID}>
                <div className={'display-flex align-items-center btn-toolbar pull-left'}>
                    {renderCreateShipmentButton && (
                        <CreateNewShipmentButton deliverySchedule={deliverySchedule} dunsNumber={dunsNumber} />
                    )}
                </div>
                <div className={'display-flex align-items-center btn-toolbar pull-right'}>
                    {renderDeleteFunctionalityCondition && (
                        <DeletionConfirmationDialog
                            show={showDeletionConfirmationDialog}
                            onClickConfirm={() => handleDeletionConfirmation()}
                            onClickCancel={() => setShowDeletionConfirmationDialog(false)}
                        />
                    )}
                    {renderDeleteFunctionalityCondition && <DeleteButton />}
                </div>
            </div>
        </div>
    );
};

const CreateNewShipmentButton = (props: {
    deliverySchedule: DeliverySchedule;
    dunsNumber: string | undefined;
}) => {
    const { deliverySchedule } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [createNewShipmentButtonDisabledDueToPreviousClick, setCreateNewShipmentButtonDisabledDueToPreviousClick] =
        useState(false);
    const isReadOnlyUser = useAppSelector(isReadOnlyAdmin);

    const deliveryScheduleIsInvalid =
        deliverySchedule &&
        (!deliverySchedule.hasValidArticleMasterData || !deliverySchedule.hasValidCumulativeQuantitySent);
    const createShipmentHandler = () => {
        setCreateNewShipmentButtonDisabledDueToPreviousClick(true);
        const placeOfDischarge: PlaceOfDischarge = mapShipToToPlaceOfDischarge(deliverySchedule.shipTo);
        const load: [ShipmentCreationLoadItem] = [
            {
                deliveryScheduleId: deliverySchedule.id,
                amount: { value: 1, measurementUnitCode: deliverySchedule.scheduledArticleDetails.measurementUnitCode },
            },
        ];
        dispatch(createShipment(navigate, props.dunsNumber!, placeOfDischarge, load, undefined, undefined));
    };

    const content = (
        <button
            type={'button'}
            className={'btn btn-primary btn-link padding-0'}
            onClick={() => createShipmentHandler()}
            disabled={deliveryScheduleIsInvalid || isReadOnlyUser || createNewShipmentButtonDisabledDueToPreviousClick}
        >
            <span aria-hidden={'true'} className={'rioglyph rioglyph-order'} />
            <FormattedMessage id={'webedi.common.createShipment'} />
            {isReadOnlyUser && (
                <span
                    className={'rioglyph rioglyph-info-sign text-color-info text-size-18 padding-left-5 margin-right-0'}
                />
            )}
        </button>
    );

    if (isReadOnlyUser) {
        return (
            <Tooltip text={<FormattedMessage id={'webedi.error.insufficientPermissions'} />} placement={'right'}>
                <span>{content}</span>
            </Tooltip>
        );
    }

    return (
        <>
            {content}
            <Tooltip
                text={<FormattedMessage id={'webedi.common.createShipment.hint'} />}
                placement={'right'}
                extraClasses={'white-space-pre-line'}
                textAlignment={'right'}
            >
                <span
                    className={'padding-left-5 rioglyph rioglyph-info-sign text-color-info text-size-18 align-middle'}
                />
            </Tooltip>
        </>
    );
};
