export const PACKAGED_ARTICLES_HEADER_READ_EXPAND_ALL_BUTTON = 'PACKAGED_ARTICLES_HEADER_READ_EXPAND_ALL_BUTTON';
export const PACKAGED_ARTICLES_HEADER_READ_VIEW = 'PACKAGED_ARTICLES_HEADER_READ_VIEW';

export interface PackagedArticlesHeaderViewProps {
    allItemsExpanded: boolean;
    setAllItemsExpanded: (arg: boolean) => void;
}

export const PackagedArticlesHeaderReadView = (props: PackagedArticlesHeaderViewProps) => {
    const { allItemsExpanded, setAllItemsExpanded } = props;

    return (
        <div
            className={'display-flex pull-right bg-white padding-10 width-100pct justify-content-end'}
            data-testid={PACKAGED_ARTICLES_HEADER_READ_VIEW}
        >
            <button
                type={'button'}
                className={'btn btn-link btn-icon-only'}
                data-testid={PACKAGED_ARTICLES_HEADER_READ_EXPAND_ALL_BUTTON}
                onClick={() => setAllItemsExpanded(!allItemsExpanded)}
            >
                <span className={`rioglyph rioglyph-resize-${allItemsExpanded ? 'small' : 'full'}`} />
            </button>
        </div>
    );
};
