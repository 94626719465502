import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterValues } from '../types';
import {
    ArticleSuggestion,
    ArticleSuggestionForDeliveryNote,
    SavingShipmentStatus,
    ShipmentsQuery,
    ShipmentsQueryFor,
    ShipmentsState,
    ShipmentsUpdatePayload,
} from './types';

export const initialShipmentsQuery: ShipmentsQuery = {
    dunsNumber: undefined,
    params: {
        limit: 25,
        offset: 0,
        queryFor: ShipmentsQueryFor.SHIPMENT_NUMBER,
    },
};

export const initialShipmentsState: ShipmentsState = {
    selectedShipmentId: undefined,
    nextAvailableDeliveryNoteNumber: undefined,
    isLoadingShipments: false,
    savingShipmentStatus: SavingShipmentStatus.DEFAULT,
    shipments: [],
    exportedQuery: initialShipmentsQuery,
    exportedFilterValues: {},
    notExportedQuery: initialShipmentsQuery,
    notExportedFilterValues: {},
    totalCountOfMatchedShipments: 0,
    articleSuggestionSidebar: {
        open: false,
        referencedDeliveryNote: undefined,
        articleSuggestions: [],
    },
    form: {
        outstandingArticleSuggestionForDeliveryNote: undefined,
        loadItemPathForAdditionalPropertiesDialog: undefined,
        isPackagingDialogVisible: false,
    },
};

export const shipmentsSlice = createSlice({
    name: 'shipments',
    initialState: initialShipmentsState,
    reducers: {
        openArticleSuggestionSidebarForDeliveryNote: (state: ShipmentsState, action: PayloadAction<number>) => {
            state.articleSuggestionSidebar.open = true;
            state.articleSuggestionSidebar.referencedDeliveryNote = action.payload;
        },
        closeArticleSuggestionSidebar: (state: ShipmentsState) => {
            state.articleSuggestionSidebar.open = false;
            state.articleSuggestionSidebar.referencedDeliveryNote = undefined;
        },
        updateArticleSuggestions: (state: ShipmentsState, action: PayloadAction<ArticleSuggestion[]>) => {
            state.articleSuggestionSidebar.articleSuggestions = action.payload;
        },
        updateSelectedShipmentId: (state: ShipmentsState, action: PayloadAction<string>) => {
            state.selectedShipmentId = action.payload;
        },
        setIsLoading: (state: ShipmentsState) => {
            state.isLoadingShipments = true;
        },
        setSavingStatus: (state: ShipmentsState, action: PayloadAction<SavingShipmentStatus>) => {
            state.savingShipmentStatus = action.payload;
        },
        setFailed: (state: ShipmentsState) => {
            state.isLoadingShipments = false;
            state.shipments = [];
        },
        updateShipments: (state: ShipmentsState, action: PayloadAction<ShipmentsUpdatePayload>) => {
            state.isLoadingShipments = false;
            state.shipments = action.payload.shipments;
            state.totalCountOfMatchedShipments = action.payload.totalCountOfMatchedShipments;
        },
        updateExportedQuery: (state: ShipmentsState, action: PayloadAction<ShipmentsQuery>) => {
            state.exportedQuery = action.payload;
        },
        updateNotExportedQuery: (state: ShipmentsState, action: PayloadAction<ShipmentsQuery>) => {
            state.notExportedQuery = action.payload;
        },
        setExportedFilterValues: (state: ShipmentsState, action: PayloadAction<FilterValues>) => {
            state.exportedFilterValues = Object.fromEntries(
                action.payload.items.map((filterItem) => [filterItem.key, filterItem.values]),
            );
        },
        setNotExportedFilterValues: (state: ShipmentsState, action: PayloadAction<FilterValues>) => {
            state.notExportedFilterValues = Object.fromEntries(
                action.payload.items.map((filterItem) => [filterItem.key, filterItem.values]),
            );
        },
        addArticleSuggestionForDeliveryNote: (
            state: ShipmentsState,
            action: PayloadAction<ArticleSuggestionForDeliveryNote>,
        ) => {
            state.form.outstandingArticleSuggestionForDeliveryNote = action.payload;
        },
        resetArticleSuggestionForDeliveryNote: (state: ShipmentsState) => {
            state.form.outstandingArticleSuggestionForDeliveryNote = undefined;
        },
        showAdditionalPropertiesDialogForLoadItem: (state: ShipmentsState, action: PayloadAction<string>) => {
            state.form.loadItemPathForAdditionalPropertiesDialog = action.payload;
        },
        hideAdditionalPropertiesDialog: (state: ShipmentsState) => {
            state.form.loadItemPathForAdditionalPropertiesDialog = undefined;
        },
        showPackagingDialog: (state: ShipmentsState) => {
            state.form.isPackagingDialogVisible = true;
        },
        hidePackagingDialog: (state: ShipmentsState) => {
            state.form.isPackagingDialogVisible = false;
        },
        updateNextAvailableDeliveryNoteNumber: (state: ShipmentsState, action: PayloadAction<number>) => {
            state.nextAvailableDeliveryNoteNumber = action.payload;
        },
    },
});
