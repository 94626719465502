import { FormattedMessage } from 'react-intl';
import { zeroPadNumber } from '../../../../../utils';
import { LoadItem } from '../../../reducers/shipments/types';
import { ArticleNumber } from '../../common/ArticleNumber';
import { Quantity } from '../../common/Quantity';
import { Tooltip } from '../../common/Tooltip';
import {
    ADDITIONAL_PROPERTIES_DISPLAY_TEST_ID,
    ADDITIONAL_PROPERTIES_DISPLAY_TOOLTIP_TEST_ID,
    AdditionalPropertiesDisplay,
} from '../AdditionalPropertiesDisplay';
import { hasLoadItemAdditionalProperties } from '../shipmentEditView/deliveryNotes/DeliveryNoteEdit';
import { DeliveryNoteReadViewProps } from './types';

export const DELIVERY_NOTE_HEADER_TEST_ID = 'DELIVERY_NOTE_HEADER_TEST_ID';
export const DELIVERY_NOTE_LOAD_TABLE_TEST_ID = 'DELIVERY_NOTE_LOAD_TABLE_TEST_ID';
export const DELIVERY_NOTE_LOAD_TABLE_ROW_TEST_ID = 'DELIVERY_NOTE_LOAD_TABLE_ROW_TEST_ID';
export const DELIVERY_NOTE_LOAD_TABLE_ROW_HEADER_TEST_ID = 'DELIVERY_NOTE_LOAD_TABLE_ROW_HEADER_TEST_ID';
export const DELIVERY_NOTE_LOAD_TABLE_ADDITIONAL_PROPS_TOOLTIP = 'DELIVERY_NOTE_LOAD_TABLE_ADDITIONAL_PROPS_TOOLTIP';

export const DeliveryNoteReadView = (props: DeliveryNoteReadViewProps) => {
    const { deliveryNote } = props;

    const tableClassNames = ['table', 'table-column-overflow-hidden', 'table-head-filled'].join(' ');

    const renderRow = (loadItem: LoadItem, index: number) => {
        return (
            <tr key={index} data-testid={DELIVERY_NOTE_LOAD_TABLE_ROW_TEST_ID}>
                <td className={'text-center width-100'}>
                    <span className={'badge badge-default'}>{index + 1}</span>
                </td>
                <td>
                    <ArticleNumber articleNumber={loadItem.articleNumberBuyer} />
                </td>
                <td>
                    <ArticleNumber articleNumber={loadItem.articleMasterData?.articleNumberSeller} />
                </td>
                <td>
                    <Quantity
                        quantity={loadItem.amount.value}
                        measurementUnitCode={loadItem.amount.measurementUnitCode}
                    />
                </td>
                <td className={'text-center max-width-250'}>
                    {hasLoadItemAdditionalProperties(loadItem) ? (
                        <Tooltip
                            text={
                                <AdditionalPropertiesDisplay
                                    loadItem={loadItem}
                                    tooltip={true}
                                    testId={ADDITIONAL_PROPERTIES_DISPLAY_TOOLTIP_TEST_ID}
                                />
                            }
                            placement={'bottom'}
                        >
                            <span className={'ellipsis-2'}>
                                <AdditionalPropertiesDisplay
                                    loadItem={loadItem}
                                    tooltip={false}
                                    testId={ADDITIONAL_PROPERTIES_DISPLAY_TEST_ID}
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        <span className={'text-color-gray text-uppercase text-size-14'}>
                            <FormattedMessage id={'webedi.noDetails'} />
                        </span>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <div className='panel panel-default' key={deliveryNote.deliveryNoteNumber}>
            <div
                className='panel-heading display-flex flex-row justify-content-between padding-left-20 padding-15'
                data-testid={DELIVERY_NOTE_HEADER_TEST_ID}
            >
                <div className={'display-flex align-items-center text-color-dark'}>
                    <span className='rioglyph rioglyph-document text-size-20 padding-right-10' />
                    <span>
                        <span className={'margin-right-5'}>
                            <FormattedMessage id={'webedi.deliveryNote'} />
                        </span>
                        <strong>{zeroPadNumber(deliveryNote.deliveryNoteNumber, 8)}</strong>
                    </span>
                </div>
            </div>
            <table className={tableClassNames} data-testid={DELIVERY_NOTE_LOAD_TABLE_TEST_ID}>
                <thead>
                    <tr data-testid={DELIVERY_NOTE_LOAD_TABLE_ROW_HEADER_TEST_ID}>
                        <th className={'text-center width-100'}>
                            <FormattedMessage id={'webedi.position'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.articleNumber.buyer'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.articleNumber.seller'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.schedulingData.quantity'} />
                        </th>
                        <th className={'text-center max-width-250'}>
                            <FormattedMessage id={'webedi.shipment.additionalProperties'} />
                        </th>
                    </tr>
                </thead>
                <tbody>{deliveryNote.loadItems.map((loadItem, index) => renderRow(loadItem, index))}</tbody>
            </table>
        </div>
    );
};
