import { PlaceOfDischargeMasterData } from '../../../domain/placeOfDischargeMasterData.types';

const CUSTOM_ERROR_PLACE_OF_DISCHARGE_MISSING = 'placeOfDischargeMissing';

export const validateArticleNumberBuyer = (
    articleNumberBuyerFormValue: string | undefined,
    placeOfDischarge: PlaceOfDischargeMasterData | undefined,
): string | undefined => {
    const isArticleNumberBuyerGiven =
        articleNumberBuyerFormValue !== undefined && articleNumberBuyerFormValue.length > 0;
    if (isArticleNumberBuyerGiven && !placeOfDischarge) {
        return CUSTOM_ERROR_PLACE_OF_DISCHARGE_MISSING;
    }
    return undefined;
};

export const genericInputErrorTranslations = new Map([['required', 'webedi.form.error.requiredField']]);

export const lengthRestrictedRequiredInputErrorTranslations = new Map([
    ...genericInputErrorTranslations,
    ['pattern', 'webedi.form.error.tooLong'],
]);

export const textInputErrorTranslations = new Map([
    ...genericInputErrorTranslations,
    ['pattern', `webedi.form.error.containsInvalidCharacters`],
    ['maxLength', `webedi.form.error.tooLong`],
]);
export const numberInputErrorTranslations = new Map([
    ...genericInputErrorTranslations,
    ['pattern', 'webedi.form.error.nonPositiveNumber'],
    ['validate', 'webedi.form.error.nonPositiveNumber'],
]);

export const articleNumberBuyerInputErrorTranslations = new Map([
    ...genericInputErrorTranslations,
    ['pattern', 'webedi.form.error.articleNumberBuyer.pattern'],
    [CUSTOM_ERROR_PLACE_OF_DISCHARGE_MISSING, 'webedi.form.error.placeOfDischargeMissing'],
]);

export const undgNumberInputErrorTranslations = new Map([
    ...genericInputErrorTranslations,
    ['pattern', 'webedi.form.error.undgNumber'],
]);
