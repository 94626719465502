import { config } from '../../../../config';
import {
    PackagingTemplate,
    PackagingTemplateCreationRequest,
    PackagingTemplateUpdateRequest,
} from '../../reducers/deliverySchedules/types';
import { decodeJson, jsonOrReject, okOrReject } from '../apiUtils';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../requests';
import { PackagingTemplateCodec, PackagingTemplatesCodec } from './packagingTemplate.types';
import {
    mapPackagingTemplate,
    mapPackagingTemplateCreationRequestToApi,
    mapPackagingTemplateUpdatedRequestToApi,
    mapPackagingTemplates,
} from './packagingTemplateMapper';

export const fetchPackagingTemplates = (
    dunsNumber: string,
    deliveryScheduleId: string,
): Promise<PackagingTemplate[]> => {
    const url = new URL(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/packaging-templates`);
    url.searchParams.append('delivery_schedule_id', deliveryScheduleId);
    return fetch(url.toString(), getRequest())
        .then(jsonOrReject)
        .then(decodeJson(PackagingTemplatesCodec))
        .then(mapPackagingTemplates);
};

export const fetchLisonPackagingTemplate = (
    dunsNumber: string,
    deliveryScheduleId: string,
): Promise<PackagingTemplate> => {
    const url = new URL(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/lison-packaging-templates`);
    url.searchParams.append('delivery_schedule_id', deliveryScheduleId);
    return fetch(url.toString(), getRequest())
        .then(jsonOrReject)
        .then(decodeJson(PackagingTemplateCodec))
        .then(mapPackagingTemplate);
};

export const postPackagingTemplate = (
    dunsNumber: string,
    creationRequest: PackagingTemplateCreationRequest,
): Promise<PackagingTemplate> => {
    const body = mapPackagingTemplateCreationRequestToApi(creationRequest);
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/packaging-templates`, postRequest(body))
        .then(jsonOrReject)
        .then(decodeJson(PackagingTemplateCodec))
        .then(mapPackagingTemplate);
};

export const patchPackagingTemplate = (
    id: string,
    dunsNumber: string,
    updateRequest: PackagingTemplateUpdateRequest,
): Promise<PackagingTemplate> => {
    const body = mapPackagingTemplateUpdatedRequestToApi(updateRequest);
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/packaging-templates/${id}`, patchRequest(body))
        .then(jsonOrReject)
        .then(decodeJson(PackagingTemplateCodec))
        .then(mapPackagingTemplate);
};

export const deletePackagingTemplate = (id: string, dunsNumber: string): Promise<void> => {
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/packaging-templates/${id}`, deleteRequest()).then(
        okOrReject,
    );
};
