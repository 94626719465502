import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../../configuration/setup/typedReduxHooks';
import { GroupedHandlingUnits } from '../../../../../reducers/shipments/packaging.types';
import { getNewPackagingFromWizardState } from '../../../../../selectors/packaging/Packaging.selector';
import { GroupedPackagingHandlingUnits } from '../GroupedPackagingHandlingUnits';
import { SplitView } from '../SplitView';
import { AuxiliaryPackagingConfigurationContainer } from './AuxiliaryPackagingConfigurationContainer';

export const AUXILIARY_PACKAGING_TEST_ID = 'AUXILIARY_PACKAGING_TEST_ID';

const renderResultingGroupedHandlingUnits = (groupedHandlingUnits: GroupedHandlingUnits[]): ReactElement => (
    <>
        <span className={'text-center margin-bottom-15 text-color-success'}>
            <div className={'text-size-18 margin-right-5 display-inline'}>
                <span className={'rioglyph rioglyph-ok-sign'} />
            </div>
            <FormattedMessage id={'webedi.packaging.hasBeenCreated'} />:
        </span>
        <GroupedPackagingHandlingUnits groupedHandlingUnits={groupedHandlingUnits} selectable={false} />
    </>
);

export const AuxiliaryPackaging = () => {
    const newPackagingFromWizard = useAppSelector(getNewPackagingFromWizardState);
    return (
        <SplitView
            left={{ content: renderResultingGroupedHandlingUnits([newPackagingFromWizard]), renderAsColumn: true }}
            main={{ content: <AuxiliaryPackagingConfigurationContainer />, renderAsColumn: true }}
            dataTestId={AUXILIARY_PACKAGING_TEST_ID}
        />
    );
};
