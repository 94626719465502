import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    CommunicationAddress,
    CommunicationAddressCodeQualifier,
    DeliverySchedule,
    SchedulingContact,
} from '../../../../reducers/deliverySchedules/types';
import { Party } from '../../../../reducers/types';
import { MostRecentArrivalOfGoods } from './MostRecentArrivalOfGoods';
import { PartyAddressDetails } from './PartyAddressDetails';

export const DELIVERY_SCHEDULES_DETAILS_SIDE_TEST_ID = 'DELIVERY_SCHEDULES_DETAILS_SIDE_TEST_ID';
export const SCHEDULING_CONTACT_TEST_ID = 'SCHEDULING_CONTACT_TEST_ID';
export const SCHEDULING_CONTACT_COMMUNICATION_ADDRESSES_TEST_ID = 'SCHEDULING_CONTACT_COMMUNICATION_ADDRESSES_TEST_ID';

const hasAddress = (party: Party) => {
    return party.partyName || party.street || party.postalIdentificationCode || party.cityName || party.countryNameCode;
};

const renderSchedulingContact = (schedulingContact?: SchedulingContact): ReactElement | undefined => {
    if (schedulingContact) {
        const compareLexicographically = (a: CommunicationAddress, b: CommunicationAddress) =>
            a.identifier.localeCompare(b.identifier);
        const telephoneAddresses = schedulingContact.communicationAddresses
            .filter((address) => address.codeQualifier === CommunicationAddressCodeQualifier.TELEPHONE)
            .sort(compareLexicographically);
        const telefaxAddresses = schedulingContact.communicationAddresses
            .filter((address) => address.codeQualifier === CommunicationAddressCodeQualifier.TELEFAX)
            .sort(compareLexicographically);
        const electronicMailAddresses = schedulingContact.communicationAddresses
            .filter((address) => address.codeQualifier === CommunicationAddressCodeQualifier.ELECTRONIC_MAIL)
            .sort(compareLexicographically);
        const classNamesForAddresses = 'text-size-14 margin-left-5 margin-right-25';

        return (
            <div data-testid={SCHEDULING_CONTACT_TEST_ID} className={'padding-bottom-10'}>
                <label className={'text-size-12'}>
                    <FormattedMessage id={'webedi.deliverySchedule.schedulingContact.disposition'} />
                </label>
                {(schedulingContact.name || schedulingContact.id) && (
                    <div className={'display-flex'}>
                        <span className={'text-size-20 rioglyph rioglyph-user margin-right-10'} />
                        <div className={'flex-1-1 padding-bottom-5'}>
                            <span className={'text-size-16'}>
                                {schedulingContact.name}
                                {schedulingContact.name && schedulingContact.id && ' - '}
                                {schedulingContact.id}
                            </span>
                        </div>
                    </div>
                )}
                <table data-testid={SCHEDULING_CONTACT_COMMUNICATION_ADDRESSES_TEST_ID}>
                    <tbody>
                        {telephoneAddresses.map((value) => (
                            <tr key={value.identifier}>
                                <td>
                                    <span className={classNamesForAddresses} />
                                </td>
                                <td className={'padding-right-5'}>
                                    <FormattedMessage id={'webedi.deliverySchedule.schedulingContact.telephone'} />
                                </td>
                                <td>
                                    <a href={`tel:${value.identifier}`}>{value.identifier}</a>
                                </td>
                            </tr>
                        ))}
                        {telefaxAddresses.map((value) => (
                            <tr key={value.identifier}>
                                <td>
                                    <span className={classNamesForAddresses} />
                                </td>
                                <td className={'padding-right-5'}>
                                    <FormattedMessage id={'webedi.deliverySchedule.schedulingContact.telefax'} />
                                </td>
                                <td>
                                    <span>{value.identifier}</span>
                                </td>
                            </tr>
                        ))}
                        {electronicMailAddresses.map((value) => (
                            <tr key={value.identifier}>
                                <td>
                                    <span className={classNamesForAddresses} />
                                </td>
                                <td className={'padding-right-5'}>
                                    <FormattedMessage id={'webedi.deliverySchedule.schedulingContact.electronicMail'} />
                                </td>
                                <td>
                                    <a href={`mailto:${value.identifier}`}>{value.identifier}</a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    return undefined;
};

const renderPlaceOfDischargeAddress = (placeOfDischarge: Party): ReactElement | undefined => {
    if (hasAddress(placeOfDischarge)) {
        return (
            <div>
                <label className={'text-size-12'}>
                    <FormattedMessage id={'webedi.common.placeOfDischarge.address'} />
                </label>
                <PartyAddressDetails {...placeOfDischarge} />
            </div>
        );
    }
    return undefined;
};

const renderPlaceOfDischargeIdentifier = (identifier: string): ReactElement => (
    <div className={'margin-bottom-20'}>
        <label className={'text-size-12'}>
            <FormattedMessage id={'webedi.common.placeOfDischarge'} />
        </label>
        <div>
            <span className={'text-size-20 rioglyph rioglyph-load-unload margin-right-10'} />
            <span className={'text-size-16'}>{identifier}</span>
        </div>
    </div>
);

export const SideInformationContent = (deliverySchedule: DeliverySchedule) => (
    <div className={'flex-1-1 flex-column'} data-testid={DELIVERY_SCHEDULES_DETAILS_SIDE_TEST_ID}>
        <div className={'padding-20'}>
            {renderSchedulingContact(deliverySchedule.scheduledArticleDetails.schedulingContact)}
            {renderPlaceOfDischargeAddress(deliverySchedule.shipTo.shipTo)}
            {renderPlaceOfDischargeIdentifier(deliverySchedule.shipTo.placeOfDischarge.locationNameCode)}
        </div>
        <div className={'bg-lighter padding-top-1'} />
        <div className={'padding-20'}>
            <MostRecentArrivalOfGoods
                measurementUnitCode={deliverySchedule.scheduledArticleDetails.measurementUnitCode}
                mostRecentArrivalOfGoods={deliverySchedule.scheduledArticleDetails.mostRecentArrivalsOfGoods}
            />
        </div>
    </div>
);
