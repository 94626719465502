import { FilterValues } from './filters.types';
import { TransportConcept } from './meansOfTransport.types';
import { TransportOrder } from './transportOrder.types';

export const calculateAllowedFilterValues = (transportOrders: TransportOrder[]): FilterValues => {
    const freightForwarderNames: Set<string> = new Set();
    const transportConcept: Set<TransportConcept> = new Set();
    const placeOfDischarges: Set<string> = new Set();
    const buyerNames: Set<string> = new Set();
    const plantNumbers: Set<string> = new Set();

    transportOrders.forEach((transportOrder) => {
        freightForwarderNames.add(transportOrder.freightForwarder.name);
        transportConcept.add(transportOrder.transportConcept);
        placeOfDischarges.add(transportOrder.unloading.placeOfDischarge);
        if (transportOrder.buyer !== undefined) {
            buyerNames.add(transportOrder.buyer.name ? transportOrder.buyer.name : transportOrder.buyer.id);
        }
        plantNumbers.add(transportOrder.unloading.plantNumber);
    });

    return {
        freightForwarderName: Array.from(freightForwarderNames),
        transportConcept: Array.from(transportConcept),
        placeOfDischarge: Array.from(placeOfDischarges),
        buyerName: Array.from(buyerNames),
        plantNumber: Array.from(plantNumbers),
    };
};
