import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomsEdit } from './CustomsEdit';
import { IncotermEdit } from './IncotermEdit';
import { TransportEdit } from './TransportEdit';
import { TransportOrderIntentConsoleLog } from './TransportOrderIntentConsoleLog';

export const TRANSPORT_CONTENT_EDIT_VIEW_TEST_ID = 'TRANSPORT_CONTENT_EDIT_VIEW_TEST_ID';

export const panelRow = (headingTranslation: ReactNode, rioglyph: string, content: ReactNode) => (
    <div className={'panel panel-default margin-top-10'}>
        <div className={'panel-heading'}>{headingTranslation}</div>
        <div className={'panel-body display-flex flex-row align-items-center'}>
            <div
                className={
                    'display-flex width-10pct min-width-100 text-size-22 margin-right-15 align-items-center justify-content-center'
                }
            >
                <h1>
                    <span className={`rioglyph ${rioglyph} text-color-light text-size-200pct`} />
                </h1>
            </div>
            <div className={'width-90pct'}>{content}</div>
        </div>
    </div>
);

export const headingTermsOfDelivery = <FormattedMessage id={'webedi.shipment.termsOfDelivery'} />;
export const headingTransport = <FormattedMessage id={'webedi.transport'} />;
export const headingTransportOrder = <FormattedMessage id={'not-existing'} defaultMessage={'Transport Order'} />;
export const headingCustoms = <FormattedMessage id={'webedi.shipment.customs'} />;

export const TransportContentEditView = () => {
    return (
        <div data-testid={TRANSPORT_CONTENT_EDIT_VIEW_TEST_ID}>
            {panelRow(headingTermsOfDelivery, 'rioglyph-transfer', <IncotermEdit />)}
            {panelRow(headingTransport, 'rioglyph-truck', <TransportEdit />)}
            {panelRow(headingCustoms, 'rioglyph-cost-efficency', <CustomsEdit />)}
            <TransportOrderIntentConsoleLog />
        </div>
    );
};
