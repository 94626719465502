import { FormattedMessage } from 'react-intl';
import { DispatchProposalProblemsCount, TransportOrderStatus } from '../../../domain/dispatchProposal.types';

interface IconAndDataTestId {
    iconClass: string;
    dataTestId: string;
}

export const DISPATCH_PROPOSAL_ERROR_ICON_TEST_ID = 'DISPATCH_PROPOSAL_ERROR_ICON_TEST_ID';
export const DISPATCH_PROPOSAL_WARN_ICON_TEST_ID = 'DISPATCH_PROPOSAL_WARN_ICON_TEST_ID';
export const DISPATCH_PROPOSAL_INFO_ICON_TEST_ID = 'DISPATCH_PROPOSAL_INFO_ICON_TEST_ID';
export const DISPATCH_PROPOSAL_NO_ICON_TEST_ID = 'DISPATCH_PROPOSAL_NO_ICON_TEST_ID';
export const DISPATCH_PROPOSAL_PLANNED_ICON_TEST_ID = 'DISPATCH_PROPOSAL_PLANNED_ICON_TEST_ID';
export const DISPATCH_PROPOSAL_ORDERED_ICON_TEST_ID = 'DISPATCH_PROPOSAL_EXPORTED_ICON_TEST_ID';

const getDesiredIconAndDataTestId = (
    problemsCount: DispatchProposalProblemsCount | undefined,
    defaultIconAndDataTestId: IconAndDataTestId,
): IconAndDataTestId => {
    if (problemsCount === undefined) {
        return {
            dataTestId: defaultIconAndDataTestId.dataTestId,
            iconClass: defaultIconAndDataTestId.iconClass,
        };
    }
    if (problemsCount.error > 0) {
        return {
            dataTestId: DISPATCH_PROPOSAL_ERROR_ICON_TEST_ID,
            iconClass: 'rioglyph rioglyph-error-sign',
        };
    }
    if (problemsCount.warning > 0) {
        return {
            dataTestId: DISPATCH_PROPOSAL_WARN_ICON_TEST_ID,
            iconClass: 'rioglyph rioglyph-exclamation-sign',
        };
    }
    if (problemsCount.info > 0) {
        return {
            dataTestId: DISPATCH_PROPOSAL_INFO_ICON_TEST_ID,
            iconClass: 'rioglyph rioglyph-info-sign',
        };
    }
    return { dataTestId: defaultIconAndDataTestId.dataTestId, iconClass: defaultIconAndDataTestId.iconClass };
};

export const StatusIndicator = (props: {
    status: TransportOrderStatus;
    problemsCount?: DispatchProposalProblemsCount;
}) => {
    switch (props.status) {
        case 'UNPLANNED':
            const unplannedSymbolAndTestId = getDesiredIconAndDataTestId(props.problemsCount, {
                iconClass: '',
                dataTestId: DISPATCH_PROPOSAL_NO_ICON_TEST_ID,
            });
            return (
                <span
                    className={`${unplannedSymbolAndTestId.iconClass} margin-right-5 text-size-18`}
                    data-testId={unplannedSymbolAndTestId.dataTestId}
                />
            );
        case 'PLANNED':
            const plannedSymbolAndTestId = getDesiredIconAndDataTestId(props.problemsCount, {
                iconClass: 'rioglyph rioglyph-pm',
                dataTestId: DISPATCH_PROPOSAL_PLANNED_ICON_TEST_ID,
            });
            return (
                <span
                    className={`${plannedSymbolAndTestId.iconClass} margin-right-5 text-size-18`}
                    data-testId={plannedSymbolAndTestId.dataTestId}
                />
            );
        case 'ORDERED':
            return (
                <span
                    className={'rioglyph rioglyph-ok-sign margin-right-5 text-size-18'}
                    data-testId={DISPATCH_PROPOSAL_ORDERED_ICON_TEST_ID}
                />
            );
    }
};

export const PanelPlantItem = (props: { plantCodes: string[] }) => {
    return (
        <div className={'ellipsis-1'}>
            <span className={'ellipsis-1 text-color-gray'}>
                <FormattedMessage id='webedi.dispatchProposals.overview.expander.header.plant' />:
            </span>
            <span className={'rioglyph rioglyph-factory text-size-18 margin-left-5 margin-right-5'} />
            <span className={'ellipsis-1 margin-right-15'}>{Array.from(new Set(props.plantCodes)).join(', ')}</span>
        </div>
    );
};

export const PanelTransportConceptInputItem = (props: { transportConcept: string }) => {
    return (
        <span>
            <span className={'ellipsis-1 text-color-gray'}>
                <FormattedMessage id='webedi.dispatchProposals.overview.expander.header.transportConcept' />:
            </span>
            <span className={'margin-left-5 margin-right-15'}>
                <FormattedMessage id={`webedi.transportConcept.${props.transportConcept}`} />
            </span>
        </span>
    );
};
