import { config } from '../../../../config';
import { neverReachedFor } from '../../../../utils';
import {
    CumulativeQuantitySentOffset,
    CumulativeQuantitySentOffsetCreationRequest,
    DeliverySchedule,
    DeliveryScheduleSortParameterName,
    DeliveryScheduleUpdateDataRequest,
    DeliverySchedulesQuery,
    DeliverySchedulesQueryResult,
    DeliverySchedulesSortParameters,
    SortDirection,
} from '../../reducers/deliverySchedules/types';
import { FilterValues } from '../../reducers/types';
import { decodeJson, jsonOrReject, okOrReject } from '../apiUtils';
import { FilterValuesCodec } from '../common.types';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../requests';
import { buildQueryParametersSuffix } from '../shared/sharedMappers';
import {
    CumulativeQuantitySentOffsetsQueryResponseCodec,
    DeliveryScheduleCodec,
    DeliverySchedulesQueryResponseCodec,
} from './deliverySchedule.types';
import {
    mapCumulativeQuantitySentOffsetCreationRequestToApi,
    mapCumulativeQuantitySentOffsetsQueryResult,
    mapDeliverySchedule,
    mapDeliveryScheduleQueryResult,
    mapToApiDeliveryScheduleDataUpdateRequest,
} from './deliveryScheduleMapper';

export const fetchDeliverySchedule = (dunsNumber: string, deliveryScheduleId: string): Promise<DeliverySchedule> => {
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${dunsNumber}/delivery-schedules/${deliveryScheduleId}`;

    return fetch(requestUrl, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(DeliveryScheduleCodec))
        .then(mapDeliverySchedule);
};

export const deleteDeliverySchedule = (dunsNumber: string, deliveryScheduleId: string): Promise<void> => {
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/delivery-schedules/${deliveryScheduleId}`,
        deleteRequest(),
    ).then(okOrReject);
};

export const clearMarkingsOfDeliverySchedule = (dunsNumber: string, deliveryScheduleId: string): Promise<void> => {
    const body = { flags: [] };

    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/delivery-schedules/${deliveryScheduleId}`,
        patchRequest(body),
    ).then((response: Response): Promise<void> => {
        if (response.ok || response.status === 403) {
            return Promise.resolve();
        }
        return Promise.reject(response);
    });
};

export const fetchDeliverySchedules = (query: DeliverySchedulesQuery): Promise<DeliverySchedulesQueryResult> => {
    if (!query.dunsNumber) {
        return Promise.reject('DUNS number must be set');
    }
    const params = { ...query.params, sort: buildSortQueryParameter(query.params.sort) };
    const queryParameters = buildQueryParametersSuffix(params);
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${query.dunsNumber}/delivery-schedules${queryParameters}`;
    return fetch(requestUrl, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(DeliverySchedulesQueryResponseCodec))
        .then(mapDeliveryScheduleQueryResult);
};

const buildSortQueryParameter = (sort: DeliverySchedulesSortParameters): string => {
    return `${buildSortDirection(sort.direction)}${buildSortParameter(sort.sortBy)}`;
};

export const updateDeliveryScheduleData = async (updateRequest: DeliveryScheduleUpdateDataRequest): Promise<void> => {
    const body = mapToApiDeliveryScheduleDataUpdateRequest(
        updateRequest.articleMasterData,
        updateRequest.includeInDispatchProposalCreation,
    );

    if (
        body.article_master_data.hasOwnProperty('default_shipping_location_id') &&
        body.article_master_data.default_shipping_location_id === undefined
    ) {
        delete body.article_master_data['default_shipping_location_id'];
    }

    const response = await fetch(
        `${config.webEdiServiceUrl}/shippers/${updateRequest.dunsNumber}/delivery-schedules/${updateRequest.deliveryScheduleId}`,
        patchRequest(body, true),
    );
    return okOrReject(response);
};

const buildSortDirection = (sortDirection: SortDirection): string => {
    switch (sortDirection) {
        case SortDirection.ASCENDING:
            return '+';
        case SortDirection.DESCENDING:
            return '-';
        default:
            return neverReachedFor(sortDirection);
    }
};

const buildSortParameter = (property: DeliveryScheduleSortParameterName): string => {
    switch (property) {
        case DeliveryScheduleSortParameterName.ARTICLE_NUMBER_BUYER:
            return 'article_number_buyer';
        case DeliveryScheduleSortParameterName.LATEST_DELIVERY_CALL_OFF_DATE:
            return 'latest_delivery_call_off_date';
        default:
            return neverReachedFor(property);
    }
};

export const fetchDeliverySchedulesFilterValues = (dunsNumber: string): Promise<FilterValues> => {
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${dunsNumber}/delivery-schedules-filter-values`;
    return fetch(requestUrl, getRequest()).then(jsonOrReject).then(decodeJson(FilterValuesCodec));
};

export const fetchCumulativeQuantitySentOffsets = (
    dunsNumber: string,
    deliveryScheduleId: string,
): Promise<CumulativeQuantitySentOffset[]> => {
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${dunsNumber}/delivery-schedules/${deliveryScheduleId}/cumulative-quantity-sent-offsets`;

    return fetch(requestUrl, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(CumulativeQuantitySentOffsetsQueryResponseCodec))
        .then(mapCumulativeQuantitySentOffsetsQueryResult);
};

export const postCumulativeQuantitySentOffset = (
    cumulativeQuantitySentOffsetCreationRequest: CumulativeQuantitySentOffsetCreationRequest,
): Promise<void> => {
    const dunsNumber = cumulativeQuantitySentOffsetCreationRequest.dunsNumberOwner;
    const deliveryScheduleId = cumulativeQuantitySentOffsetCreationRequest.deliveryScheduleId;
    const body = mapCumulativeQuantitySentOffsetCreationRequestToApi(cumulativeQuantitySentOffsetCreationRequest);
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${dunsNumber}/delivery-schedules/${deliveryScheduleId}/cumulative-quantity-sent-offsets`;

    return fetch(requestUrl, postRequest(body)).then(okOrReject);
};
