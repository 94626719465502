import { ReactElement } from 'react';
import { neverReachedFor } from '../../../../../utils';
import { PackagingStepType } from '../../../reducers/deliverySchedules/types';
import { AuxiliaryPackagingTemplateConfigurationContainer } from './AuxiliaryPackagingTemplateConfigurationContainer';
import { HomogeneousPackagingTemplateConfigurationContainer } from './HomogeneousPackagingTemplateConfigurationContainer';
import { InnerPackagingTemplateConfiguration } from './InnerPackagingTemplateConfiguration';
import { InnerPackagingTemplateConfigurationContainer } from './InnerPackagingTemplateConfigurationContainer';

export const PACKAGING_TEMPLATE_TEST_IDS = {
    packagingMaterialSelection: 'PACKAGING_TEMPLATE_PACKAGING_MATERIAL_SELECTION_TEST_ID',
    innerConfig: 'PACKAGING_TEMPLATE_INNER_CONFIG_TEST_ID',
    homogeneousConfig: 'PACKAGING_TEMPLATE_HOMOGENEOUS_CONFIG_SELECTION_TEST_ID',
    heterogeneousConfig: 'PACKAGING_TEMPLATE_HETEROGENEOUS_CONFIG_SELECTION_TEST_ID',
};

interface PackagingTemplateConfigurationProps {
    step?: number;
    stepType?: PackagingStepType;
}

export const PackagingTemplateConfiguration = (props: PackagingTemplateConfigurationProps) => {
    const { step, stepType } = props;

    const renderFirstStep = (packagingStepType: PackagingStepType): ReactElement => {
        switch (packagingStepType) {
            case PackagingStepType.INNER:
                return <InnerPackagingTemplateConfigurationContainer />;
            case PackagingStepType.HOMOGENEOUS:
                return <HomogeneousPackagingTemplateConfigurationContainer />;
            case PackagingStepType.HETEROGENEOUS:
                return <></>;
            default:
                return neverReachedFor(packagingStepType);
        }
    };

    const renderPackagingTemplateConfiguration = (packagingStepType: PackagingStepType) => {
        switch (packagingStepType) {
            case PackagingStepType.INNER:
                return <InnerPackagingTemplateConfiguration />;
            case PackagingStepType.HOMOGENEOUS:
                return <HomogeneousPackagingTemplateConfigurationContainer />;
            case PackagingStepType.HETEROGENEOUS:
                return <></>;
            default:
                return neverReachedFor(packagingStepType);
        }
    };

    if (stepType !== undefined && step !== undefined) {
        if (step === 0) {
            return renderFirstStep(stepType);
        } else if (step === 1) {
            return stepType === PackagingStepType.INNER ? (
                renderPackagingTemplateConfiguration(stepType)
            ) : (
                <AuxiliaryPackagingTemplateConfigurationContainer />
            );
        } else if (step === 2) {
            return <AuxiliaryPackagingTemplateConfigurationContainer />;
        }
    }

    return <></>;
};
