import { ReactElement } from 'react';
import { ChevronRight } from '../../../common/ChevronRight';

export type SplitColumnProps = {
    content: ReactElement;
    renderAsColumn?: boolean;
    alignItemsCenter?: boolean;
};

export interface SplitViewProps {
    left: SplitColumnProps;
    main: SplitColumnProps;
    right?: SplitColumnProps;
    dataTestId?: string;
}

export const SPLIT_COMPONENT_TEST_ID = 'SPLIT_COMPONENT_TEST_ID';

export const SplitView = (props: SplitViewProps) => {
    const borderClasses = 'border-style-solid border-color-lighter border-width-1 border-right-only';

    const renderColumn = (column: SplitColumnProps, additionalClasses: string, chevronBackground?: string) => {
        const standardClasses = 'display-flex justify-content-center position-relative';
        const alignItemsCenterClass = column.alignItemsCenter ? 'align-items-center' : '';
        const flexColumnClass = column.renderAsColumn ? 'flex-column' : '';
        return (
            <div
                data-testid={SPLIT_COMPONENT_TEST_ID}
                className={`${standardClasses} ${additionalClasses} ${alignItemsCenterClass} ${flexColumnClass}`}
            >
                {column.content}
                {chevronBackground && <ChevronRight backgroundColor={chevronBackground} />}
            </div>
        );
    };

    return (
        <div className={'display-flex flex-row min-height-500 margin--20'} data-testid={props.dataTestId}>
            {props.right ? (
                <>
                    {renderColumn(props.left, `${borderClasses} width-25pct padding-15 bg-lightest`, 'bg-lightest')}
                    {renderColumn(props.main, `${borderClasses} width-50pct padding-5pct bg-white`, 'bg-white')}
                    {renderColumn(props.right, `width-25pct padding-15 bg-white`)}
                </>
            ) : (
                <>
                    {renderColumn(props.left, `${borderClasses} width-40pct padding-15 bg-lightest`, 'bg-lightest')}
                    {renderColumn(props.main, `width-60pct padding-5pct bg-white`)}
                </>
            )}
        </div>
    );
};
