import { config } from '../../../../config';
import { PackagingConfig } from '../../reducers/shipments/packaging.types';
import {
    ArticleSuggestion,
    DeliveryNoteNumberData,
    Shipment,
    ShipmentCreationRequest,
    ShipmentUpdateRequest,
    ShipmentsQuery,
    ShipmentsQueryFor,
    ShipmentsQueryParams,
} from '../../reducers/shipments/types';
import { FilterValues } from '../../reducers/types';
import { decodeJson, jsonOrReject, okOrReject } from '../apiUtils';
import { FilterValuesCodec } from '../common.types';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../requests';
import { buildQueryParametersSuffix } from '../shared/sharedMappers';
import {
    ArticleSuggestionsCodec,
    DeliveryNoteNumberDataCodec,
    ShipmentCodec,
    ShipmentsQueryResultCodec,
} from './shipment.types';
import {
    mapArticleSuggestions,
    mapDeliveryNoteNumberData,
    mapShipment,
    mapShipmentCreationRequestToApi,
    mapShipmentUpdateRequestToApi,
    mapShipmentsQueryResult,
} from './shipmentMapper';

export const postShipment = (shipmentCreationRequest: ShipmentCreationRequest): Promise<Shipment> => {
    const body = mapShipmentCreationRequestToApi(shipmentCreationRequest);
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${shipmentCreationRequest.dunsNumberOwner}/shipments`,
        postRequest(body),
    )
        .then(jsonOrReject)
        .then(decodeJson(ShipmentCodec))
        .then(mapShipment);
};

export const getShipments = (exported: boolean, query: ShipmentsQueryWithOptionalSearch) => {
    if (!query.dunsNumber) {
        return Promise.reject('DUNS number must be set');
    }

    const queryParams = buildQueryParametersSuffix({ ...query.params, exported });

    return fetch(`${config.webEdiServiceUrl}/shippers/${query.dunsNumber}/shipments${queryParams}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ShipmentsQueryResultCodec))
        .then(mapShipmentsQueryResult);
};

export const fetchShipmentsFilterValues = (dunsNumber: string, exported: boolean): Promise<FilterValues> => {
    const requestUrl = `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments-filter-values?exported=${exported}`;
    return fetch(requestUrl, getRequest()).then(jsonOrReject).then(decodeJson(FilterValuesCodec));
};

export const getShipment = (dunsNumber: string, shipmentId: string): Promise<Shipment> => {
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ShipmentCodec))
        .then(mapShipment);
};

export const deleteShipment = (dunsNumber: string, shipmentId: string): Promise<void> => {
    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}`, deleteRequest()).then(
        okOrReject,
    );
};

export const patchShipment = (
    shipmentUpdateRequest: ShipmentUpdateRequest,
    dunsNumber: string,
    shipmentId: string,
    packagingOuterDimensions: NonNullable<PackagingConfig['packagingOuterDimensions']>,
): Promise<void> => {
    const apiUpdateShipmentRequest = mapShipmentUpdateRequestToApi(shipmentUpdateRequest, packagingOuterDimensions);
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}`,
        patchRequest(apiUpdateShipmentRequest),
    ).then(okOrReject);
};

export const patchShipmentExported = (dunsNumber: string, shipmentId: string): Promise<void> => {
    const body = { exported: true };

    return fetch(`${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}`, patchRequest(body)).then(
        okOrReject,
    );
};

export const getArticleSuggestions = async (dunsNumber: string, shipmentId: string): Promise<ArticleSuggestion[]> => {
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}/article-suggestions`,
        getRequest(),
    )
        .then(jsonOrReject)
        .then(decodeJson(ArticleSuggestionsCodec))
        .then(mapArticleSuggestions);
};

export const getNextDeliveryNoteNumberData = async (dunsNumber: string): Promise<DeliveryNoteNumberData> => {
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipment-number-sequences/next-delivery-note-number`,
        getRequest(),
    )
        .then(jsonOrReject)
        .then(decodeJson(DeliveryNoteNumberDataCodec))
        .then(mapDeliveryNoteNumberData);
};

export type ShipmentsQueryWithOptionalSearch = Omit<ShipmentsQuery, 'params'> & {
    params: Omit<ShipmentsQueryParams, 'queryFor'> & {
        queryFor?: ShipmentsQueryFor;
    };
};
