import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const PRIVACY_POLICY_DIALOG_TEST_ID = 'PRIVACY_POLICY_DIALOG_TEST_ID';

export const PrivacyPolicyDisplay = () => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <div>
            <span
                className={'padding-right-10 text-color-darkest cursor-pointer text-decoration-underline'}
                onClick={() => setShowDialog(true)}
            >
                <FormattedMessage id={'webedi.privacyPolicy'} />
            </span>
            <PrivacyPolicyDialog show={showDialog} onHide={() => setShowDialog(false)} />
        </div>
    );
};

const PrivacyPolicyDialog = (props: { show: boolean; onHide: () => void }) => {
    const Body = () => (
        <div data-testid={PRIVACY_POLICY_DIALOG_TEST_ID}>
            <div>
                <p className={'white-space-pre-line'}>
                    <FormattedMessage id={'webedi.privacyPolicy.statement.part1'} />
                </p>
                <h5>
                    <FormattedMessage id={'webedi.privacyPolicy.statement.part2'} />
                </h5>
                <p className={'white-space-pre-line'}>
                    <FormattedMessage
                        id={'webedi.privacyPolicy.statement.part3'}
                        values={{
                            a: (chunks: ReactNode) => (
                                <a href={`${chunks}`} target={'_blank'} rel={'noreferrer'}>
                                    {chunks}
                                </a>
                            ),
                            b: (chunks: ReactNode) => <b>{chunks}</b>,
                        }}
                    />
                </p>
                <p className={'white-space-pre-line'}>
                    <FormattedMessage
                        id={'webedi.privacyPolicy.statement.part4'}
                        values={{
                            // biome-ignore lint/style/useNamingConvention: No need to change the variable name
                            a_mailto: (chunks: ReactNode) => <a href={`mailto:${chunks}`}>{chunks}</a>,
                            b: (chunks: ReactNode) => <b>{chunks}</b>,
                        }}
                    />
                </p>
            </div>
            <div className={'padding-top-20'}>
                <a
                    className={'btn btn-primary'}
                    href={'https://www.volkswagenag.com/de/meta/data-protection-declaration.html'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    <FormattedMessage id={'webedi.privacyPolicy.details'} />
                </a>
            </div>
        </div>
    );

    return (
        <Dialog
            show={props.show}
            title={<FormattedMessage id={'webedi.privacyPolicy'} />}
            body={<Body />}
            footer={''}
            onHide={props.onHide}
            showCloseButton
        />
    );
};
