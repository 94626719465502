import { FormattedMessage } from 'react-intl';
import { fieldNames } from '../courierOrderIntentDialog.types';

interface ServiceProviderSelectionProps {
    serviceProvider?: string;
}

export const ServiceProviderSelection = (props: ServiceProviderSelectionProps) => {
    const { serviceProvider } = props;
    const fieldName = fieldNames.serviceProviderSelection;

    return (
        <div className='display-flex flex-column align-items-center'>
            <div className='panel panel-default display-flex flex-column padding-20 border-style-solid border-width-1 border-color-light width-75pct margin-bottom-20'>
                <div className='display-flex flex-column'>
                    <div className='display-flex flex-row align-items-center text-color-dark padding-10 margin-bottom-0'>
                        <label className='width-50pct text-size-14 text-color-darkest text-medium' htmlFor={fieldName}>
                            <span className='rioglyph rioglyph-van text-size-18 padding-right-5' />
                            <FormattedMessage
                                id={'webedi.dispatchProposals.expressParcel.dialog.secondStep.label.courier'}
                            />
                        </label>
                        <div className='form-group margin-0 flex-1-1 '>
                            <input
                                type='text'
                                value={serviceProvider}
                                id={fieldName}
                                className='form-control cursor-default'
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
