import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { getPackagingWizardInfo } from '../../../../selectors/packaging/Packaging.selector';

interface FooterProps {
    onCancel: () => void;
    finalStep: boolean;
}

export const PACKAGING_DIALOG_FOOTER_TEST_ID = 'PACKAGING_DIALOG_FOOTER_TEST_ID';

export const Footer = (props: FooterProps) => {
    const formId = useAppSelector(getPackagingWizardInfo)!.formId!;

    return (
        <div className={'pull-right btn-toolbar'} data-testid={PACKAGING_DIALOG_FOOTER_TEST_ID}>
            <button type={'button'} className={'btn btn-default'} onClick={props.onCancel}>
                <FormattedMessage id={'webedi.label.cancel'} />
            </button>
            {props.finalStep ? (
                <button type={'submit'} className={'btn btn-primary'} form={formId}>
                    <FormattedMessage id={'webedi.label.package'} />
                </button>
            ) : (
                <button className={'btn btn-primary'} type={'submit'} form={formId}>
                    <FormattedMessage id={'webedi.next'} />
                </button>
            )}
        </div>
    );
};
