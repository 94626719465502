import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    DocumentLanguage,
    DocumentSettings,
    DocumentSettingsWithOptionalFields,
    ExtraMarginInMm,
    GtlDocumentSettings,
    GtlLabelSize,
    GtlPaperFormat,
    PlantSpecificSettings,
    ShipmentDocuments,
    ShipmentDocumentsState,
    TsbDocumentSettings,
    TsbPaperFormat,
} from './ShipmentDocuments.types';
import {
    readDocumentSettingsFromLocalStorage,
    writeDocumentSettingsToLocalStorage,
} from './ShipmentDocumentsLocalStorage.reducer';

const defaultExtraMarginInMm: ExtraMarginInMm = {
    bottom: 0,
    top: 0,
};

const defaultPlantSpecificSettings: PlantSpecificSettings = {
    numberOfLabelCopies: 1,
    applyForPlants: [],
};

const defaultIncludeLabelsOnlyForOuterMostLevel: boolean = false;

const defaultDocumentSettings: DocumentSettings = {
    tsbSettings: {
        language: DocumentLanguage.GERMAN,
        comment: '',
        includeReceiptInformation: true,
        mergePackages: true,
        mergeLoadingUnits: true,
        paperFormat: TsbPaperFormat.DIN_A4,
        copies: {
            numShipmentMasterSheets: 1,
            numShipmentPositionSheets: 1,
            numBarcodeSheets: 1,
            numLoadingUnitSheets: 0,
        },
    },
    gtlSettings: {
        language: DocumentLanguage.GERMAN,
        labelsForIntermediateLevels: false,
        labelSize: GtlLabelSize.DIN_A5,
        paperFormat: GtlPaperFormat.DIN_A4,
        specialSizes: {
            additionalSmallKltTypes: [],
            trayTypes: [],
        },
        extraMarginInMm: defaultExtraMarginInMm,
        includeLabelsOnlyForOuterMostLevel: defaultIncludeLabelsOnlyForOuterMostLevel,
        plantSpecificSettings: defaultPlantSpecificSettings,
    },
};

export const mapToDocumentsSettings = (input: DocumentSettingsWithOptionalFields): DocumentSettings => ({
    tsbSettings: input.tsbSettings,
    gtlSettings: {
        language: input.gtlSettings.language,
        labelsForIntermediateLevels: input.gtlSettings.labelsForIntermediateLevels,
        labelSize: input.gtlSettings.labelSize,
        paperFormat: input.gtlSettings.paperFormat,
        specialSizes: input.gtlSettings.specialSizes,
        extraMarginInMm: input.gtlSettings.extraMarginInMm ?? defaultExtraMarginInMm,
        includeLabelsOnlyForOuterMostLevel:
            input.gtlSettings.includeLabelsOnlyForOuterMostLevel ?? defaultIncludeLabelsOnlyForOuterMostLevel,
        plantSpecificSettings: input.gtlSettings.plantSpecificSettings ?? defaultPlantSpecificSettings,
    },
});

const getInitialDocumentSettings = (): DocumentSettings => {
    const settingsFromLocalStorage: DocumentSettingsWithOptionalFields | undefined =
        readDocumentSettingsFromLocalStorage();
    return settingsFromLocalStorage ? mapToDocumentsSettings(settingsFromLocalStorage) : defaultDocumentSettings;
};

const initialState: ShipmentDocumentsState = {
    documents: {},
    isGeneratingShipmentDocumentsFor: {},
    documentSettings: getInitialDocumentSettings(),
};

const removeAllGeneratedDocumentFromStore = (state: ShipmentDocumentsState) => {
    state.documents = {};
};

export const shipmentDocumentsSlice = createSlice({
    name: 'shipmentDocuments',
    initialState,
    reducers: {
        setGeneratingShipmentDocuments: (
            state: ShipmentDocumentsState,
            action: PayloadAction<{ state: boolean; shipmentId: string }>,
        ) => {
            const shipmentId = action.payload.shipmentId;
            if (action.payload.state) {
                state.isGeneratingShipmentDocumentsFor[shipmentId] = true;
                delete state.documents[shipmentId];
            } else {
                delete state.isGeneratingShipmentDocumentsFor[shipmentId];
            }
        },
        updateShipmentDocuments: (
            state: ShipmentDocumentsState,
            action: PayloadAction<{ shipmentId: string; shipmentDocuments: ShipmentDocuments }>,
        ) => {
            const { shipmentId, shipmentDocuments } = action.payload;
            state.documents[shipmentId] = shipmentDocuments;
        },
        setTsbDocumentSettings: (state: ShipmentDocumentsState, action: PayloadAction<TsbDocumentSettings>) => {
            state.documentSettings.tsbSettings = action.payload;
            writeDocumentSettingsToLocalStorage(state.documentSettings);
            removeAllGeneratedDocumentFromStore(state);
        },
        setGtlDocumentSettings: (state: ShipmentDocumentsState, action: PayloadAction<GtlDocumentSettings>) => {
            state.documentSettings.gtlSettings = action.payload;
            writeDocumentSettingsToLocalStorage(state.documentSettings);
            removeAllGeneratedDocumentFromStore(state);
        },
        clearShipmentDocuments: (state: ShipmentDocumentsState) => {
            removeAllGeneratedDocumentFromStore(state);
        },
    },
});
