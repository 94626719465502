import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { snakeToCamel } from '../../../../utils';

export type LoadingLocationUpsertErrorBoxProps = {
    errorCode: string | undefined;
};

export const LoadingLocationUpsertErrorBox = (props: LoadingLocationUpsertErrorBoxProps): ReactElement | null => {
    if (!props.errorCode) {
        return null;
    }
    const errorCodeCamelCase = snakeToCamel(props.errorCode);
    return (
        <div className={'alert alert-danger form-group'}>
            <FormattedMessage id={`webedi.shippingLocations.error.${errorCodeCamelCase}`} />
        </div>
    );
};
