export enum TransportConcept {
    FTL = 'FTL',
    LTL = 'LTL',
    KEP = 'KEP',
}

export enum MeansOfTransportMode {
    MARITIME = 'Maritime',
    RAIL = 'Rail',
    ROAD = 'Road',
    AIR = 'Air',
    MAIL = 'Mail',
    MULTIMODAL = 'Multimodal',
}

export interface MaritimeTransport {
    mode: MeansOfTransportMode.MARITIME;
    shipName?: string;
    containerNumber?: string;
}

export interface RailTransport {
    mode: MeansOfTransportMode.RAIL;
    trainNumber?: string;
    railCarNumber?: string;
}

export interface RoadTransport {
    mode: MeansOfTransportMode.ROAD;
    registrationNumber?: string;
    registrationNumberFromConfirmation?: string;
    trailerRegistrationNumber?: string;
}

export type RoadTransportWithoutConfirmationData = Omit<RoadTransport, 'registrationNumberFromConfirmation'>;

export interface AirTransport {
    mode: MeansOfTransportMode.AIR;
    flightNumber?: string;
}

export interface Mail {
    mode: MeansOfTransportMode.MAIL;
    parcelNumber?: string;
}

export interface MultimodalTransport {
    mode: MeansOfTransportMode.MULTIMODAL;
    swapBodyRegistrationNumber?: string;
    swapBodyNumber?: string;
}

export type MeansOfTransport =
    | MaritimeTransport
    | RailTransport
    | RoadTransport
    | AirTransport
    | Mail
    | MultimodalTransport;

export type MeansOfTransportWithoutConfirmationData =
    | Exclude<MeansOfTransport, RoadTransport>
    | RoadTransportWithoutConfirmationData;

export type MeansOfTransportPropertyKeys =
    | keyof MaritimeTransport
    | keyof RailTransport
    | keyof RoadTransport
    | keyof AirTransport
    | keyof Mail
    | keyof MultimodalTransport;
