import { FormattedMessage } from 'react-intl';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { Routing } from '../../routing/Routes';

export const CreationConfirmation = (props: {
    articleNumberBuyer: string;
    deliveryScheduleId: string | undefined;
}) => {
    const dunsNumber = useDunsNumberFromPath();

    return (
        <div className='display-flex flex-column align-items-center margin-y-10pct'>
            <div
                className='display-flex justify-content-center just flex-row align-items-center text-size-18 text-color-success text-center margin-bottom-50'
                style={{ width: '100%' }}
            >
                <span className='rioglyph rioglyph-ok-sign text-size-200pct padding-x-10' />
                <span className='text-medium'>
                    <FormattedMessage
                        id={'webedi.deliverySchedule.creation.confirmation.header'}
                        values={{
                            article: props.articleNumberBuyer,
                        }}
                    />
                </span>
            </div>
            <a
                href={`${Routing.webScm}/${dunsNumber}${Routing.deliverySchedules}/${props.deliveryScheduleId}${Routing.metadata}`}
                target='_blank'
                className='panel panel-default border-color-primary padding-20 margin-x-10pct-md  display-flex justify-content-between text-color-primary hover-bg-highlight-lightest text-decoration-none'
                rel='noreferrer'
            >
                <div>
                    <div className='text-bold text-left text-size-16'>
                        <FormattedMessage
                            id={'webedi.deliverySchedule.creation.confirmation.packaging.header'}
                            values={{
                                article: props.articleNumberBuyer,
                            }}
                        />
                    </div>
                    <div className='margin-top-20 text-size-14 text-left text-color-dark'>
                        <FormattedMessage id={'webedi.deliverySchedule.creation.confirmation.packaging.description'} />
                    </div>
                </div>
                <div className='margin-left-20 display-flex align-items-center text-center'>
                    <div>
                        <div className='rioglyph rioglyph-parcel text-size-h1' />
                    </div>
                </div>
            </a>
        </div>
    );
};
