import { SelectOption } from '@rio-cloud/rio-uikit';
import { PickupSelection } from '../components/dispatchProposals/courierOrderIntent/courierOrderIntentDialog.types';

export type ServiceLevelOption = SelectOption & { requiresApproval: boolean };

export interface ExpressShipmentPickupSelection {
    pickupType: PickupSelection;
    pickupDate: string;
    pickupEarliestTime: string;
    pickupLatestTime: string;
}

export enum ExpressShipmentServiceProvider {
    UPS = 'UPS',
    DHL = 'DHL',
}

export enum CourierOrderValidationError {
    WEIGHT_EXCEEDED = 'WEIGHT_EXCEEDED',
    LENGTH_EXCEEDED = 'LENGTH_EXCEEDED',
    GIRTH_EXCEEDED = 'GIRTH_EXCEEDED',
    DANGEROUS_GOODS_UNSUPPORTED = 'DANGEROUS_GOODS_UNSUPPORTED',
}

export interface ExpressShipmentServiceLevel {
    code: string;
    name: string;
    requiresApproval: boolean;
}

export interface ExpressShipmentCourierOptions {
    serviceProvider: ExpressShipmentServiceProvider;
    serviceLevels: ServiceLevelOption[];
}

export interface CourierOrderDetails {
    pickupSelection: ExpressShipmentPickupSelection;
    courierSelection: CourierSelection;
    contactDetails: ContactDetails;
}

export interface CourierSelection {
    serviceProvider: ExpressShipmentServiceProvider;
    serviceLevel: string;
    approvalPerson?: string;
    approvalCostCenter?: string;
}

export interface ContactDetails {
    senderName: string;
    senderPhone: string;
    recipientName: string;
    recipientPhone: string;
}

export interface CourierOrder {
    printableLabel: string;
    parcels: CourierOrderParcel[];
}

export interface CourierOrderParcel {
    trackingId: string;
    articleNumbers: string[];
    packageItemNumber: number;
    trackingUrl?: string;
}

export interface CourierOrderValidation {
    actual?: string;
    limit?: string;
    code: CourierOrderValidationError;
}
