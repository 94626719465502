import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/typedReduxHooks';
import { useCourierOrdersQuery } from '../../../api/shippers/shippersApi';
import { TransportConcept } from '../../../domain/meansOfTransport.types';
import { TransportOrder } from '../../../domain/transportOrder.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { transportOrdersSlice } from '../../../reducers/transportOrders/TransportOrders.reducer';
import { DownloadShipmentDocumentsDialog } from '../table/DownloadShipmentDocumentsDialog';

export const KEP_LABEL_TEST_ID = 'KEP_LABEL';
export const SHIPPING_DOCUMENTS_TEST_ID = 'SHIPPING_DOCUMENTS';

export const SidebarFooter = (props: { transportOrder: TransportOrder }) => {
    const { transportOrder } = props;
    const duns = useDunsNumberFromPath();
    const courierOrders = useCourierOrdersQuery(
        duns && transportOrder.shipment ? { dunsNumber: duns, shipmentIds: [transportOrder.shipment.id] } : skipToken,
    );
    const dispatch = useAppDispatch();
    const [showDownloadShipmentDocumentsDialog, setShowDownloadShipmentDocumentsDialog] = useState(false);

    const handleKepLabelDownload = () => {
        const val = courierOrders.data?.[0]?.printableLabel;
        if (val) {
            const anchorElement = document.createElement('a');

            anchorElement.href = `data:application/pdf;base64,${val}`;
            anchorElement.download = `${transportOrder?.shipment?.id}.pdf`;
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
        }
    };

    const handleShowDownloadShipmentDocumentsDialog = () => {
        dispatch(transportOrdersSlice.actions.clearSelectedTransportOrders());
        dispatch(
            transportOrdersSlice.actions.addSelectedTransportOrder({
                shipmentId: transportOrder.shipment!.id,
                shipmentNumber: transportOrder.shipment!.shipmentNumber,
                transportOrderId: transportOrder.id,
                transportOrderNumber: transportOrder.transportOrderNumber,
                transportConcept: transportOrder.transportConcept,
                index: 0,
            }),
        );
        setShowDownloadShipmentDocumentsDialog(true);
    };

    return (
        <>
            <div
                className='flex-1-1 margin-bottom-10'
                data-testid={SHIPPING_DOCUMENTS_TEST_ID}
                onClick={handleShowDownloadShipmentDocumentsDialog}
            >
                <div className={'panel panel-primary margin-x-10'}>
                    <div className={'cursor-pointer panel-body hover-bg-highlight-lightest'}>
                        <div
                            className={
                                'text-color-primary display-flex justify-content-between align-items-center flex-row'
                            }
                        >
                            <div className={'text-size-h5 text-size-16 word-break ellipsis-1 margin-right-15'}>
                                <FormattedMessage id={'webedi.transportOrders.sidebar.generateShippingDocuments'} />
                            </div>
                            <div className={'display-flex justify-content-end'}>
                                <div className='display-flex flex-column'>
                                    <span className={'rioglyph text-color-primary rioglyph-download text-size-h4'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DownloadShipmentDocumentsDialog
                show={showDownloadShipmentDocumentsDialog}
                onClose={() => {
                    setShowDownloadShipmentDocumentsDialog(false);
                }}
            />

            {transportOrder.transportConcept === TransportConcept.KEP && (
                <div className='flex-1-1' data-testid={KEP_LABEL_TEST_ID} onClick={handleKepLabelDownload}>
                    <div className={'panel panel-primary margin-x-10'}>
                        <div className={'cursor-pointer panel-body hover-bg-highlight-lightest'}>
                            <div
                                className={
                                    'text-color-primary display-flex justify-content-between align-items-center flex-row'
                                }
                            >
                                <div className={'text-size-h5 text-size-16 ellipsis-1 margin-right-15'}>
                                    <FormattedMessage
                                        id={'webedi.transportOrders.sidebar.downloadKepLabel'}
                                        values={{
                                            courierServiceProvider:
                                                transportOrder.freightForwarder.name ?? 'Placeholder',
                                        }}
                                    />
                                </div>
                                <div className={'display-flex justify-content-end'}>
                                    <div className='display-flex flex-column'>
                                        <span
                                            className={'rioglyph text-color-primary rioglyph-download text-size-h4'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
