import { SelectOption } from '@rio-cloud/rio-uikit';
import SelectUiKit from '@rio-cloud/rio-uikit/lib/es/Select';
import { FieldRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

export interface SelectInputProps extends FieldRenderProps<string | string[]> {
    id?: string;
    options: SelectOption[];
    disabled?: boolean;
    useFilter?: boolean;
    outerClassName?: string;
    errorMessageClassName?: string;
    useClear?: boolean;
}

export const SelectInput = (props: SelectInputProps) => {
    const { input, meta, ...rest } = props;
    const { id, disabled, options, useFilter, outerClassName, errorMessageClassName, useClear } = rest;
    const errorClassName = 'form-group has-error has-feedback';
    const getInputRowClassName = (touched: boolean | undefined, error: any) =>
        `${touched && error ? errorClassName : ''} ${outerClassName}`;
    const onChangeHandler = (selectedItem?: SelectOption) => {
        return input.onChange(selectedItem?.id);
    };

    return (
        <div className={getInputRowClassName(meta.touched, meta.error)}>
            <SelectUiKit
                id={id}
                onChange={onChangeHandler}
                options={options}
                useFilter={useFilter}
                placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                value={Array.isArray(input.value) ? input.value : [input.value]}
                disabled={disabled}
                useClear={useClear}
            />
            {meta.touched && meta.error ? (
                <span className={`help-block ${errorMessageClassName}`}>
                    <FormattedMessage id={meta.error} />
                </span>
            ) : null}
        </div>
    );
};
