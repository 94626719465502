import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/lib';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { formatWeight, measurementUnitCodeToTranslation } from '../../../../../utils';
import { getNextAvailableLabelNumberAction } from '../../../actions/shipments/Packaging.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { AuxiliaryHandlingUnitGroup } from '../../../reducers/auxiliaryPackaging.types';
import { Ownership } from '../../../reducers/shipments/packaging.types';
import { getEnrichedPackagingDataForSelectedShipment } from '../../../selectors/packaging/Packaging.selector';
import {
    ExpandRowIconPlaceHolder,
    HandlingUnitCategory,
    HandlingUnitCategoryTranslation,
} from '../../common/PackagingCommon';
import { Tooltip } from '../../common/Tooltip';
import { BorderlessLabelNumberInput } from '../../common/form/BorderlessLabelNumberInput';
import { PackagedArticlesHeaderEditView } from './packagingEditView/PackagedArticlesHeaderEditView';
import {
    ArticleInPackageConfig,
    HandlingUnitInPackageConfig,
    PackageableInPackageConfig,
    isArticleInPackageConfig,
    isHandlingUnitInPackageConfig,
} from './packagingEditView/types';
import { PackagedArticlesHeaderReadView } from './packagingReadView/PackagedArticlesHeaderReadView';
import {
    ArticleInPackageConfigProps,
    AuxiliaryRowProps,
    HandlingUnitInPackageConfigProps,
    PackagedArticlesProps,
    PackagedHandlingUnitExpanderProps,
    RowContentProps,
} from './types';

export const PACKAGED_ARTICLES_EXPANDER_SINGLE_ITEM = 'PACKAGED_ARTICLES_EXPANDER_SINGLE_ITEM';
export const PACKAGED_ARTICLES_TEST_ID = 'PACKAGED_ARTICLES_TEST_ID';
export const PACKAGED_ARTICLES_EMPTY_STATE_TEST_ID = 'PACKAGED_ARTICLES_EMPTY_STATE_TEST_ID';
export const PACKAGED_ARTICLES_AUXILIARY_PACKAGING_ITEM = 'PACKAGED_ARTICLES_AUXILIARY_PACKAGING_ITEM';
export const PACKAGED_ARTICLES_EXPANDER_GROUP = 'PACKAGED_ARTICLES_EXPANDER_GROUP';

export const createHandlingUnitTooltipMessage = (
    intl: IntlShape,
    isReusable: boolean,
    ownership: Ownership,
    stackingFactor: number,
    tareWeightInKg: number,
): ReactNode =>
    intl.formatMessage(
        { id: 'webedi.handlingUnit.type.tooltip' },
        {
            packaging: isReusable
                ? intl.formatMessage({ id: 'webedi.packaging.packagingType.option.reusable' })
                : intl.formatMessage({ id: 'webedi.packaging.packagingType.option.disposable' }),
            ownership:
                ownership === Ownership.BUYER
                    ? intl.formatMessage({ id: 'webedi.packaging.ownership.option.buyer' })
                    : intl.formatMessage({ id: 'webedi.packaging.ownership.option.seller' }),
            stackingFactor: stackingFactor.toFixed(0),
            tareWeightInKg: tareWeightInKg.toLocaleString(intl.locale, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
            }),
            b: (chunks: ReactNode) => <b>{chunks}</b>,
        },
    );

const EmptyState = () => {
    const intl = useIntl();

    return (
        <div
            className={'display-flex justify-content-center align-items-center width-100pct height-100pct'}
            data-testid={PACKAGED_ARTICLES_EMPTY_STATE_TEST_ID}
        >
            <CustomState
                outerClassName={'bg-lighter border-none'}
                headline={intl.formatMessage({ id: 'webedi.packaging.noPackagingCreated' })}
                message={intl.formatMessage({ id: 'webedi.packaging.selectUnpackedArticles' })}
                icons={[
                    {
                        name: 'rioglyph rioglyph-parcel',
                        color: 'text-color-gray',
                        className: 'text-size-300pct',
                    },
                ]}
            />
        </div>
    );
};

const RowContent = (props: RowContentProps) => (
    <>
        <span style={{ paddingLeft: props.iteration }} className={`width-30pct ${props.classNameDescriptionElement}`}>
            {props.description}
            {props.descriptionTooltip !== undefined ? (
                <Tooltip
                    text={props.descriptionTooltip}
                    placement={'right'}
                    extraClasses={'white-space-pre-line'}
                    textAlignment={'left'}
                >
                    <span
                        className={
                            'rioglyph rioglyph-info-sign text-color-info text-size-20 padding-left-4 align-middle'
                        }
                    />
                </Tooltip>
            ) : (
                <></>
            )}
        </span>
        <span className={'width-10pct padding-right-10'}>
            <span className={props.classNameCategoryElement}>{props.category}</span>
        </span>
        <span className={'width-20pct ellipsis-1'}>
            {props.deliveryNote && `${props.deliveryNote.number} - ${props.deliveryNote.position}`}
        </span>
        <span className={'width-10pct'}>{props.quantity}</span>
        <span className={'width-10pct'}>{props.grossWeight}</span>
        <span className={'width-10pct'}>{props.netWeight}</span>
        <span className={'width-10pct'}>{props.labelNumber}</span>
    </>
);

export const getQuantity = (handlingUnit: HandlingUnitInPackageConfig): number => {
    if (isArticleInPackageConfig(handlingUnit.contents[0])) {
        return (handlingUnit.contents[0] as ArticleInPackageConfig).quantity;
    } else {
        return 1;
    }
};

const HandlingUnitRow = (props: HandlingUnitInPackageConfigProps) => {
    const intl = useIntl();
    const handlingUnit = props.handlingUnit;
    const quantity = getQuantity(handlingUnit);
    const packagedArticle =
        handlingUnit.category === HandlingUnitCategory.PARTS_CONTAINER
            ? (handlingUnit.contents[0] as ArticleInPackageConfig)
            : undefined;
    const description = handlingUnit.description ? ` ${handlingUnit.description}` : '';
    return (
        <div
            className={'display-flex align-items-center padding-top-10 padding-bottom-10'}
            data-testid={PACKAGED_ARTICLES_EXPANDER_SINGLE_ITEM}
        >
            <RowContent
                description={
                    <span>
                        <b>{handlingUnit.type}</b>
                        {description}
                    </span>
                }
                category={intl.formatMessage({ id: HandlingUnitCategoryTranslation[handlingUnit.category] })}
                descriptionTooltip={createHandlingUnitTooltipMessage(
                    intl,
                    handlingUnit.isReusable,
                    handlingUnit.ownership,
                    handlingUnit.stackingFactor,
                    handlingUnit.tareWeightInKg,
                )}
                deliveryNote={
                    packagedArticle && {
                        number: packagedArticle.deliveryNoteNumber.toString(),
                        position: packagedArticle.deliveryNotePosition.toString(),
                    }
                }
                quantity={quantity.toLocaleString(intl.locale, { maximumFractionDigits: 2 })}
                grossWeight={formatWeight(intl.locale, handlingUnit.grossWeightInKg)}
                netWeight={formatWeight(intl.locale, handlingUnit.netWeightInKg)}
                labelNumber={
                    <BorderlessLabelNumberInput
                        labelNumber={handlingUnit.labelNumber}
                        id={handlingUnit.id}
                        readOnly={props.readOnly}
                    />
                }
                classNameCategoryElement={'label label-condensed label-default label-filled'}
                classNameDescriptionElement={`padding-left-${(props.iteration * 10).toString()}`}
            />
        </div>
    );
};

const ArticleRow = (props: ArticleInPackageConfigProps) => {
    const intl = useIntl();
    const article = props.article;

    const articleNetWeightString = formatWeight(intl.locale, article.articleNetWeightInKg);
    const tooltipText =
        article.articleMeasurementUnitCode !== undefined ? (
            <FormattedMessage
                id={'webedi.article.weightPerUnit'}
                values={{
                    weight: articleNetWeightString,
                    unit: intl.formatMessage({
                        id: measurementUnitCodeToTranslation(article.articleMeasurementUnitCode),
                    }),
                }}
            />
        ) : (
            articleNetWeightString
        );

    return (
        <div
            className={'display-flex padding-15 border-style-solid border-width-1 border-color-lighter border-top-only'}
        >
            <RowContent
                iteration={props.iteration * 15}
                description={
                    <span>
                        <b>{article.articleNumber}</b> {article.articleDescription}
                    </span>
                }
                descriptionTooltip={tooltipText}
                category={intl.formatMessage({ id: 'webedi.handlingUnit.type.article' })}
                deliveryNote={{
                    number: article.deliveryNoteNumber.toString(),
                    position: article.deliveryNotePosition.toString(),
                }}
                classNameCategoryElement={'label label-condensed label-primary label-filled'}
            />
            <ExpandRowIconPlaceHolder />
        </div>
    );
};

const AuxiliaryPackagingRow = (props: AuxiliaryRowProps) => {
    const intl = useIntl();
    const auxiliaryPackagingContent = props.auxiliaryHandlingUnitGroup.auxiliaryPackagingContent;
    const description = auxiliaryPackagingContent.description ? ` ${auxiliaryPackagingContent.description}` : '';

    return (
        <div
            className={'display-flex padding-15 text-color-dark border-color-lighter'}
            data-testid={PACKAGED_ARTICLES_AUXILIARY_PACKAGING_ITEM}
            key={props.iteration}
        >
            <RowContent
                description={
                    <span>
                        <b>{auxiliaryPackagingContent.type}</b>
                        {description}
                    </span>
                }
                category={intl.formatMessage({
                    id: HandlingUnitCategoryTranslation[HandlingUnitCategory.PACKAGING_AID],
                })}
                descriptionTooltip={createHandlingUnitTooltipMessage(
                    intl,
                    auxiliaryPackagingContent.isReusable,
                    auxiliaryPackagingContent.ownership,
                    auxiliaryPackagingContent.stackingFactor,
                    auxiliaryPackagingContent.tareWeightInKg,
                )}
                labelNumber={''}
                quantity={props.auxiliaryHandlingUnitGroup.quantity.toString()}
                classNameCategoryElement={'label label-condensed label-default label-filled'}
                classNameDescriptionElement={`padding-left-${(props.iteration * 10).toString()}`}
            />
            <ExpandRowIconPlaceHolder />
        </div>
    );
};

const renderAuxiliaryPackagingHandlingUnitContent = (
    auxiliaryHandlingUnitGroup: AuxiliaryHandlingUnitGroup,
    iteration: number,
) => {
    if (auxiliaryHandlingUnitGroup) {
        return <AuxiliaryPackagingRow auxiliaryHandlingUnitGroup={auxiliaryHandlingUnitGroup} iteration={iteration} />;
    }
    return undefined;
};

const renderPackagedHandlingUnitContent = (
    packageable: PackageableInPackageConfig,
    index: number,
    allItemsExpanded: boolean,
    iteration: number,
    readOnly: boolean,
): ReactElement | undefined => {
    const currentIteration = iteration + 1;
    if (isArticleInPackageConfig(packageable)) {
        return <ArticleRow article={packageable} iteration={currentIteration} key={index} />;
    } else if (isHandlingUnitInPackageConfig(packageable)) {
        return (
            <PackagedHandlingUnitExpander
                packagedHandlingUnit={packageable}
                allItemsExpanded={allItemsExpanded}
                index={index}
                key={index}
                iteration={currentIteration}
                readOnly={readOnly}
            />
        );
    }
    return undefined;
};

const PackagedHandlingUnitExpander = (props: PackagedHandlingUnitExpanderProps) => {
    const { allItemsExpanded, packagedHandlingUnit, iteration, readOnly } = props;

    return (
        <div data-testid={PACKAGED_ARTICLES_EXPANDER_GROUP}>
            <ExpanderPanel
                className={`margin-top-2 margin-bottom-2 border-bottom-none border-left-none'}`}
                title={
                    <HandlingUnitRow handlingUnit={packagedHandlingUnit} iteration={iteration} readOnly={readOnly} />
                }
                bsStyle={'default'}
                titleClassName={'width-100pct height-100pct text-color-dark'}
                bodyClassName={'padding-0'}
                open={allItemsExpanded}
                key={`${packagedHandlingUnit.id}-${allItemsExpanded}`}
            >
                {packagedHandlingUnit.auxiliaryPackaging.map(
                    (auxiliaryPackagingHandlingUnitGroup: AuxiliaryHandlingUnitGroup, contentIndex: number) => (
                        <div
                            className={`margin-top-2 margin-bottom-2 border-style-solid border-width-1 border-color-lighter border-top-only`}
                            // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                            key={contentIndex}
                        >
                            {renderAuxiliaryPackagingHandlingUnitContent(
                                auxiliaryPackagingHandlingUnitGroup,
                                iteration,
                            )}
                        </div>
                    ),
                )}
                <div className={'position-relative'}>
                    <span
                        className={`position-absolute top-0 left-0 bottom-0 width-${(iteration + 1) * 15} margin-left--1 margin-bottom--1 bg-lighter`}
                    />
                    {packagedHandlingUnit.contents.map(
                        (packageable: PackageableInPackageConfig, contentIndex: number) =>
                            renderPackagedHandlingUnitContent(
                                packageable,
                                contentIndex,
                                allItemsExpanded,
                                iteration,
                                readOnly,
                            ),
                    )}
                </div>
            </ExpanderPanel>
        </div>
    );
};

export const PackagedArticles = (props: PackagedArticlesProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath() ?? '';
    const packagedHandlingUnits: HandlingUnitInPackageConfig[] = useAppSelector(
        getEnrichedPackagingDataForSelectedShipment,
    );
    const [allItemsExpanded, setAllItemsExpanded] = useState(false);

    useEffect(() => {
        dispatch(getNextAvailableLabelNumberAction(dunsNumber));
    }, [dispatch, dunsNumber]);

    if (packagedHandlingUnits.length === 0) {
        return <EmptyState />;
    }

    const renderReadOrEditHeader = () => {
        if (props.readOnly) {
            return (
                <PackagedArticlesHeaderReadView
                    allItemsExpanded={allItemsExpanded}
                    setAllItemsExpanded={setAllItemsExpanded}
                />
            );
        } else {
            return (
                <PackagedArticlesHeaderEditView
                    allItemsExpanded={allItemsExpanded}
                    setAllItemsExpanded={setAllItemsExpanded}
                />
            );
        }
    };

    return (
        <div data-testid={PACKAGED_ARTICLES_TEST_ID}>
            <div className={'position-sticky z-index-1'} style={{ top: '-28px' }}>
                {renderReadOrEditHeader()}
                <div className={'display-flex bg-lightest padding-15'}>
                    <RowContent
                        description={intl.formatMessage({ id: 'webedi.packaging.header.description' })}
                        category={intl.formatMessage({ id: 'webedi.packaging.header.category' })}
                        deliveryNote={{
                            number: intl.formatMessage({ id: 'webedi.packaging.header.deliveryNoteNumber' }),
                            position: intl.formatMessage({ id: 'webedi.packaging.header.deliveryNotePosition' }),
                        }}
                        quantity={intl.formatMessage({ id: 'webedi.packaging.header.quantity' })}
                        grossWeight={intl.formatMessage({ id: 'webedi.packaging.header.grossWeight' })}
                        netWeight={intl.formatMessage({ id: 'webedi.packaging.header.netWeight' })}
                        labelNumber={intl.formatMessage({ id: 'webedi.packaging.header.labelNumber' })}
                    />
                    <ExpandRowIconPlaceHolder />
                </div>
            </div>
            {packagedHandlingUnits.map((packagedHandlingUnit: HandlingUnitInPackageConfig, index) => (
                <PackagedHandlingUnitExpander
                    packagedHandlingUnit={packagedHandlingUnit}
                    allItemsExpanded={allItemsExpanded}
                    index={index}
                    // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                    key={index}
                    iteration={0}
                    readOnly={props.readOnly}
                />
            ))}
        </div>
    );
};
