import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { sortBy } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../../../configuration/setup/typedReduxHooks';
import { setSelectedDeliveryScheduleId } from '../../../../actions/deliverySchedules/DeliverySchedules.actions';
import {
    DispatchProposalProblem,
    DispatchProposalProblemBase,
    MissingArticleMasterDataProblem,
    MissingArticleMasterDataProblemMissingData,
    isMissingArticleMasterDataProblem,
} from '../../../../domain/dispatchProposal.types';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import { DispatchProposalProblemBannerFunctions, ProblemDescriptorRow } from '../DispatchProposalProblemBanner';
import {
    BannerIcon,
    FormattedArticleNumber,
    HorizontalLine,
    colorByProblemLevel,
} from '../DispatchProposalProblemBannerFormatters';

const filterMissingArticleMasterDataProblem = (problems: DispatchProposalProblem[]) =>
    removeDuplicatesBasedOnAffectedDateAndDeliveryScheduleId(problems.filter(isMissingArticleMasterDataProblem));

const removeDuplicatesBasedOnAffectedDateAndDeliveryScheduleId = (problems: MissingArticleMasterDataProblem[]) => {
    const toGroupingKey = (problem: MissingArticleMasterDataProblem) =>
        problem.affectedDate + problem.deliveryScheduleId.toLowerCase();
    return [...new Map(problems.map((problem) => [toGroupingKey(problem), problem])).values()];
};

const useMapToMissingArticleMasterDataProblem = (problemBaseUnsafe: DispatchProposalProblemBase[]) => {
    const problems = problemBaseUnsafe as MissingArticleMasterDataProblem[];

    if (problems.length === 0) {
        return undefined;
    }

    const alertLevel = problems[0].level;
    const rows = problems.map(
        (problem) =>
            ({
                id: problem.id,
                identifier: <FormattedArticleNumber articleNumber={problem.articleNumberBuyer} />,
                description: <MissingArticleMasterDataProblemDescription problem={problem} />,
                actionButton: <MissingArticleMasterDataProblemActionButton problem={problem} />,
            }) as ProblemDescriptorRow,
    );
    return {
        title: <MissingArticleMasterDataProblemTitle entries={rows} />,
        rows,
        formatting: {
            testId: problems[0].type,
            horizontalDivider: <HorizontalLine level={alertLevel} />,
            icon: <BannerIcon level={alertLevel} />,
            buttonStyle: colorByProblemLevel(alertLevel),
            printEntries: true,
        },
    };
};

const MissingArticleMasterDataProblemDescription = (props: { problem: MissingArticleMasterDataProblem }) => {
    const intl = useIntl();
    const missingDataLabelIdentifier: Record<MissingArticleMasterDataProblemMissingData, string> = {
        [MissingArticleMasterDataProblemMissingData.ARTICLE_NET_WEIGHT]: 'articleNetWeight',
        [MissingArticleMasterDataProblemMissingData.COUNTRY_OF_ORIGIN]: 'countryOfOrigin',
    };
    const renderMissingData = (missingData: MissingArticleMasterDataProblemMissingData[]) => {
        return sortBy(missingData)
            .map((missingDataEntry) =>
                intl.formatMessage({
                    id: `webedi.dispatchProposals.problemBanner.missingArticleMasterData.missingData.${missingDataLabelIdentifier[missingDataEntry]}`,
                }),
            )
            .join(', ');
    };
    return (
        <p>
            <strong>
                <FormattedMessage id={'webedi.dispatchProposals.problemBanner.missingArticleMasterData.missingData'} />:
            </strong>{' '}
            {renderMissingData(props.problem.missingData)}
        </p>
    );
};

const MissingArticleMasterDataProblemActionButton = (props: { problem: MissingArticleMasterDataProblem }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const buttonStyle = colorByProblemLevel(props.problem.level);
    const goToDeliverySchedulePage = () => {
        dispatch(setSelectedDeliveryScheduleId(dunsNumber!, props.problem.deliveryScheduleId, navigate));
    };
    const label = 'webedi.dispatchProposals.problemBanner.missingArticleMasterDataProblem.actionButton.label';

    return (
        <Button bsStyle={buttonStyle} onClick={goToDeliverySchedulePage} className={'margin-left-auto'}>
            <FormattedMessage id={label} />
        </Button>
    );
};

const MissingArticleMasterDataProblemTitle = (props: { entries: ProblemDescriptorRow[] }) => {
    const id =
        props.entries.length === 1
            ? 'webedi.dispatchProposals.problemBanner.missingArticleMasterData.title.one'
            : 'webedi.dispatchProposals.problemBanner.missingArticleMasterData.title.other';
    return (
        <strong className='text-size-16'>
            <FormattedMessage id={id} values={{ numberOfArticleNumbers: props.entries.length }} />
        </strong>
    );
};

export const missingArticleMasterDataProblemHandler: DispatchProposalProblemBannerFunctions = {
    filterForOneTypeOfProblem: filterMissingArticleMasterDataProblem,
    useGenerateProblemDescriptor: useMapToMissingArticleMasterDataProblem,
};
