import { createSelector } from '@reduxjs/toolkit';
import { State } from '../../../../configuration/setup/store';
import { plusDuration } from '../../../../dateUtils';
import {
    ArticleSuggestion,
    DispatchProposal,
    DispatchProposalItem,
    EditLoadItemAmountDialogData,
    FreightForwarder,
    ManualDispatchProposalDraft,
    PackagedDispatchProposalItem,
} from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { DispatchProposalsFilterParams, TransportPlanningDraft } from '../../reducers/dispatchProposal/types';

const getDispatchProposalsSlice = (state: State) => state.webEdi.dispatchProposals;

export const getDispatchProposalsSelectedFilters = (state: State): DispatchProposalsFilterParams =>
    getDispatchProposalsSlice(state).selectedFilters;

export const hasAnyActiveFilter = (state: State): boolean => {
    const filterParams = getDispatchProposalsSelectedFilters(state);
    return (
        Object.values(filterParams).find((v) => v !== undefined && (Array.isArray(v) ? v.length > 0 : true)) !==
        undefined
    );
};

export const getOverviewSelectedDate = (state: State) => state.webEdi.dispatchProposals.overviewSelectedDate;

export const getOverviewStartDate = (state: State) => state.webEdi.dispatchProposals.overviewStartDate;

export const getOverviewDateRangeISO = createSelector(
    [getOverviewStartDate],
    (overviewStartDate): { from: string; to: string } => {
        return {
            from: overviewStartDate,
            to: plusDuration(overviewStartDate, { days: 20 }),
        };
    },
);
export const getManualDispatchProposalDrafts = (state: State): ManualDispatchProposalDraft[] =>
    state.webEdi.dispatchProposals.manualDispatchProposalDrafts;

export const getSelectedDispatchProposal = (state: State): DispatchProposal | ManualDispatchProposalDraft | undefined =>
    state.webEdi.dispatchProposals.selectedDispatchProposal;

export const getSelectedDispatchProposalToPackage = (state: State): DispatchProposal =>
    state.webEdi.dispatchProposals.selectedDispatchProposalToPackage!;

export const getSelectedDispatchProposalItemToPackage = (state: State): PackagedDispatchProposalItem =>
    state.webEdi.dispatchProposals.selectedDispatchProposalItemToPackage!;

export const getAddArticleSidebarOpen = (state: State): boolean => state.webEdi.dispatchProposals.addArticleSidebarOpen;

export const getSelectedArticleSuggestion = (state: State): ArticleSuggestion | undefined =>
    state.webEdi.dispatchProposals.selectedArticleSuggestion;

export const getPlanDispatchProposalsSidebarOpen = (state: State): boolean =>
    state.webEdi.dispatchProposals.planDispatchProposalsSidebarOpen;

export const selectTransportPlanningDraft = (state: State): TransportPlanningDraft | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft;

export const getSelectedDispatchProposalItems = createSelector(
    [selectTransportPlanningDraft],
    (draft): DispatchProposalItem[] => {
        return draft?.selectedDispatchProposalItems || [];
    },
);

export const getTransportConceptOfSelectedDispatchProposalItems = (state: State): TransportConcept | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.transportConcept;

export const getPickupDateOfSelectedDispatchProposalItems = (state: State): string | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.pickupDate;

export const getFreightForwarderOfSelectedDispatchProposalItems = (state: State): FreightForwarder | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.freightForwarder;

export const getPickupTimeFrom = (state: State): string | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.pickupTimeFrom;

export const getPickupTimeTo = (state: State): string | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.pickupTimeTo;

export const getAccessCode = (state: State): string | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.accessCode;

export const getSupplierTimezone = (state: State): string | undefined =>
    state.webEdi.dispatchProposals.transportPlanningDraft?.supplierTimezone;

export const getEditLoadItemAmountDialogData = (state: State): EditLoadItemAmountDialogData | undefined =>
    state.webEdi.dispatchProposals.editLoadItemAmountDialogData;
