import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { PackagingType } from '../../../../reducers/shipments/packaging.types';
import { HorizontalAddButton } from '../../HorizontalAddButton';
import { FieldErrorType } from '../../form/types';
import { auxiliaryFormPropertyNames } from './AuxiliaryPackagingFormConfiguration';
import { AuxiliaryPackagingInputs } from './AuxiliaryPackagingInputs';
import { AuxiliaryPackagingConfigurationFormValues, AuxiliaryPackagingErrorType } from './types';

export const AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_TEST_ID =
    'AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_TEST_ID';

export const validateAddAuxiliaryPackaging = (
    auxiliaryPackagingFormValues: AuxiliaryPackagingConfigurationFormValues,
): AuxiliaryPackagingErrorType[] | undefined => {
    const { auxiliaryPackagings } = auxiliaryPackagingFormValues;
    if (auxiliaryPackagings) {
        const auxPackageErrors: AuxiliaryPackagingErrorType[] = [];
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
        auxiliaryPackagings.forEach((auxiliaryPackage, index) => {
            const auxPackageError: AuxiliaryPackagingErrorType = {
                packagingMaterial: {},
            };
            if (
                auxiliaryPackage.auxiliaryPackagingPerHandlingUnit === undefined ||
                auxiliaryPackage.auxiliaryPackagingPerHandlingUnit <= 0
            ) {
                auxPackageError.auxiliaryPackagingPerHandlingUnit = auxPackageError.auxiliaryPackagingPerHandlingUnit =
                    FieldErrorType.NON_POSITIVE_NUMBER;
            }
            if (auxiliaryPackage.packagingMaterial?.boxCode === undefined) {
                auxPackageError.packagingMaterial!.boxCode = FieldErrorType.REQUIRED;
            }
            if (auxiliaryPackage.packagingMaterial) {
                const { tareWeightInKg, ownership, isReusable, stackingFactor } = auxiliaryPackage.packagingMaterial;

                if (tareWeightInKg === undefined || String(tareWeightInKg) === '') {
                    auxPackageError.packagingMaterial!.tareWeightInKg = FieldErrorType.REQUIRED;
                }
                if (ownership === undefined) {
                    auxPackageError.packagingMaterial!.ownership = FieldErrorType.REQUIRED;
                }
                if (isReusable === undefined) {
                    auxPackageError.packagingMaterial!.isReusable = FieldErrorType.REQUIRED;
                }
                if (stackingFactor === undefined || String(stackingFactor) === '') {
                    auxPackageError.packagingMaterial!.stackingFactor = FieldErrorType.REQUIRED;
                }
            }
            auxPackageErrors[index] = auxPackageError;
        });
        return auxPackageErrors;
    }
    return undefined;
};

export const AddAuxiliaryPackaging = (props: {
    addAuxiliaryPackagingItem: (propertyName: string) => void;
    packagingType: PackagingType | undefined;
}) => {
    const removeAuxiliaryPackage = (fields: any, index: number) => {
        fields.remove(index);
    };

    return (
        <div className={'width-100pct'} data-testid={AUXILIARY_PACKAGING_CONFIGURATION_ADD_AUXILIARY_PACKAGING_TEST_ID}>
            <div className={'display-flex flex-column width-100pct margin-bottom-20'}>
                <FieldArray name={auxiliaryFormPropertyNames.auxiliaryPackagings}>
                    {({ fields }) =>
                        fields.map((name, index) => {
                            return (
                                <AuxiliaryPackagingInputs
                                    auxiliaryPackagingPropertyName={name}
                                    key={name}
                                    renderRemoveButton={true}
                                    removeHandler={() => removeAuxiliaryPackage(fields, index)}
                                />
                            );
                        })
                    }
                </FieldArray>
                <span className={'text-center text-color-dark'}>
                    {props.packagingType === PackagingType.INNER ? (
                        <FormattedMessage id='webedi.packaging.inner.auxiliaryPackaging.title' />
                    ) : (
                        <FormattedMessage id='webedi.packaging.outer.auxiliaryPackaging.title' />
                    )}
                </span>
                <HorizontalAddButton
                    onClick={() => props.addAuxiliaryPackagingItem(auxiliaryFormPropertyNames.auxiliaryPackagings)}
                />
            </div>
        </div>
    );
};
