import { FormattedMessage } from 'react-intl';
import { CourierOrderIntentErrorBanner } from '../common/CourierOrderIntentErrorBanner';

interface Props {
    showErrorBanner?: boolean;
    errorCode?: string;
}
export const ServiceProviderInfoBanner = (props: Props) => {
    return (
        <div className='display-flex flex-column align-items-center padding-x-5pct'>
            <div className='text-size-18 margin-bottom-25'>
                <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.secondStep.headline'} />
            </div>
            {props.showErrorBanner && <CourierOrderIntentErrorBanner errorId={props.errorCode} />}
            <div className='counter color-highlight'>
                <span className='display-flex flex-row' data-count='2'>
                    <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.secondStep.description'} />
                </span>
            </div>
        </div>
    );
};
