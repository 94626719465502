import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { getCompanyInfo } from '../../../actions/ui/CompanyInfo.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { getCompanyName } from '../../../selectors/ui/CompanyInfo.selector';

export const MENU_COMPANY_NAME = 'MENU_COMPANY_NAME';

export const RioCompanyName = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const companyName = useAppSelector(getCompanyName);

    useEffect(() => {
        dunsNumber && dispatch(getCompanyInfo(dunsNumber));
    }, [dunsNumber, dispatch]);

    return (
        <>
            {dunsNumber && companyName && (
                <div className={'text-left'} data-testid={MENU_COMPANY_NAME}>
                    <i>{companyName}</i>
                </div>
            )}
        </>
    );
};
