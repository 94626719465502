import languages from '@cospired/i18n-iso-languages';
import { createSelector } from '@reduxjs/toolkit';
import countries from 'i18n-iso-countries';
import get from 'lodash/fp/get';

export const getLocale = get('lang.displayLocale');

export const getDisplayMessages = get('lang.displayMessages');

export const getDisplayCountries = createSelector([getLocale], (locale) => {
    const localeCode = locale.split('-')[0];
    return countries.getNames(localeCode, { select: 'official' });
});

export const getDisplayLanguages = createSelector([getLocale], (locale) => {
    const localeCode = locale.split('-')[0];
    return languages.getNames(localeCode);
});
