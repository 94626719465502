import { config } from '../../../../config';
import { DocumentLanguage, ShipmentDocumentsRequest } from '../../reducers/shipmentDocuments/ShipmentDocuments.types';
import { decodeJson, jsonOrReject } from '../apiUtils';
import { getRequest, postRequest } from '../requests';
import {
    mapDeliveryForecastDocumentsRequestToApi,
    mapShipmentDocumentRequestToApi,
    mapToShipmentDocumentsResponse,
} from './shipmentDocumentCallMapper';
import {
    DocumentResponseCodec,
    FileDownloadLinkCodec,
    ShipmentDocumentsResponseCodec,
} from './shipmentDocuments.types';

export const requestShipmentDocumentsGeneration = (shipmentDocumentRequest: ShipmentDocumentsRequest) => {
    const body = mapShipmentDocumentRequestToApi(shipmentDocumentRequest);
    return fetch(`${config.webEdiServiceUrl}/documents/shipment-documents`, postRequest(body))
        .then(jsonOrReject)
        .then(decodeJson(ShipmentDocumentsResponseCodec))
        .then(mapToShipmentDocumentsResponse);
};

/**
 * Kicks off the asn generation process for the given shipment.
 *
 * The promise will be resolved/rejected immediately, without waiting for the asn file to be available.
 *
 * @param dunsNumber
 * @param shipmentId
 *
 * @returns promise, that resolves to the url of the generating asn file
 */
export const startAsnGenerationForShipment = (dunsNumber: string, shipmentId: string): Promise<string> => {
    return fetch(
        `${config.webEdiServiceUrl}/shippers/${dunsNumber}/shipments/${shipmentId}/asn-download-link`,
        getRequest(),
    )
        .then(jsonOrReject)
        .then(decodeJson(FileDownloadLinkCodec))
        .then((fileDownloadLink) => fileDownloadLink.url);
};

export const testIfFileIsAvailableInS3 = async (asnFileUrl: string): Promise<void> => {
    return fetch(asnFileUrl).then((response: Response) => {
        if (!response.ok) {
            throw new Error('ASN file not available');
        }
    }); // discard response body; we are only interested, if the call was successful (2XX was returned)
};

export const requestDeliveryForecastDocumentsGeneration = (
    deliveryForecastUrl: string,
    documentLanguage: DocumentLanguage,
    articleNumberBuyer?: string,
): Promise<string> => {
    const body = mapDeliveryForecastDocumentsRequestToApi(deliveryForecastUrl, {
        documentLanguage,
        articleNumberBuyer,
    });
    return fetch(`${config.webEdiServiceUrl}/documents/delivery-forecasts`, postRequest(body))
        .then(jsonOrReject)
        .then(decodeJson(DocumentResponseCodec))
        .then((document) => document.url);
};
