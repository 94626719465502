import { FormattedMessage } from 'react-intl';
import { returnChronologicallySortedQuantities } from '../../../../../../utils';
import { DespatchDocumentReferenceCodeQualifier, ReceivedQuantity } from '../../../../reducers/deliverySchedules/types';
import { MeasurementUnitCodeDisplay } from '../../../common/MeasurementUnitCodeDisplay';
import { FormattedDateOrDateTime } from '../../../common/i18n/FormattedDateOrDateTime';
import { MostRecentArrivalOfGoodsProps } from '../../types';

export const MOST_RECENT_ARRIVAL_GOODS_DATE_TEST_ID = 'MOST_RECENT_ARRIVAL_GOODS_DATE_TEST_ID';
export const MOST_RECENT_ARRIVAL_GOODS_ENTRY_TEST_ID = 'MOST_RECENT_ARRIVAL_GOODS_ENTRY_TEST_ID';

export const MostRecentArrivalOfGoods = (props: MostRecentArrivalOfGoodsProps) => {
    const renderQuantities = (receivedQuantity: ReceivedQuantity['quantity']) => {
        return (
            <>
                <span>{receivedQuantity}</span>
                <span className={'padding-left-3'}>
                    <MeasurementUnitCodeDisplay unit={props.measurementUnitCode} />
                </span>
            </>
        );
    };

    const renderReceivedQuantity = (receivedQuantities: ReceivedQuantity[]) => {
        return returnChronologicallySortedQuantities(receivedQuantities).map(
            (receivedQuantity, receivedQuantityIndex) => {
                const despatchNoteDocumentReferences = receivedQuantity.despatchDocumentReferences.filter(
                    (reference) =>
                        reference.referenceCodeQualifier ===
                        DespatchDocumentReferenceCodeQualifier.DESPATCH_NOTE_DOCUMENT_IDENTIFIER,
                );

                const receivedQuantityKey = `receivedQuantity-${receivedQuantityIndex}`;
                return (
                    <div
                        className={'flex-row margin-bottom-20'}
                        key={receivedQuantityKey}
                        data-testid={MOST_RECENT_ARRIVAL_GOODS_ENTRY_TEST_ID}
                    >
                        <div className={'text-color-darkest text-light padding-5 bg-lightest'}>
                            {
                                <div data-testid={MOST_RECENT_ARRIVAL_GOODS_DATE_TEST_ID}>
                                    {receivedQuantity.receiptDate ? (
                                        <FormattedDateOrDateTime date={receivedQuantity.receiptDate} />
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            }
                        </div>
                        <div className={'bg-lighter padding-top-2 margin-bottom-10'} />
                        <div className={'display-flex flex-row-md flex-column-sm'}>
                            <div className={'display-flex flex-column flex-1-1'}>
                                <label className={'margin-bottom-4'}>
                                    <FormattedMessage id={'webedi.schedulingData.quantity'} />
                                </label>
                                <div className={'text-size-14'}>
                                    <span className={'rioglyph rioglyph-parcel margin-right-10 text-size-20'} />
                                    {renderQuantities(receivedQuantity.quantity)}
                                </div>
                            </div>
                            <div className={'display-flex flex-column flex-1-1 padding-top-0-md padding-top-10-sm'}>
                                {despatchNoteDocumentReferences.length > 0 && (
                                    <>
                                        <label>
                                            <FormattedMessage id={'webedi.deliveryNoteNr.short'} />
                                        </label>
                                        <div>
                                            {despatchNoteDocumentReferences.map((reference, despatchNoteIndex) => {
                                                return (
                                                    <div
                                                        // biome-ignore lint/suspicious/noArrayIndexKey: Fix this see RIOINBBL-1932
                                                        key={`${receivedQuantityKey}-despatchNote-${despatchNoteIndex}`}
                                                    >
                                                        {`${reference.referenceIdentifier}`}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                );
            },
        );
    };

    return (
        <div>
            <div className={'text-size-h6 margin-bottom-20'}>
                <FormattedMessage id={'webedi.deliverySchedule.mostRecentArrivalOfGoods'} />
            </div>
            {renderReceivedQuantity(props.mostRecentArrivalOfGoods)}
        </div>
    );
};
