import { reportErrorToSentry } from '../../../../../configuration/setup/sentry';

export const mergeDocuments = async (documents: ArrayBuffer[]) => {
    const { PDFDocument } = await import('pdf-lib');
    const mergedPdfDocument = await PDFDocument.create();
    for (const document of documents) {
        const loadedPdfDocument = await PDFDocument.load(document);
        const copiedPagesGtl = await mergedPdfDocument.copyPages(loadedPdfDocument, loadedPdfDocument.getPageIndices());
        for (const page of copiedPagesGtl) {
            mergedPdfDocument.addPage(page);
        }
    }
    return await mergedPdfDocument.saveAsBase64({ dataUri: true });
};

export const mergeBase64Documents = async (base64Documents: string[]): Promise<string> => {
    const { PDFDocument } = await import('pdf-lib');
    const mergedPdf = await PDFDocument.create();

    for (let i = 0; i < base64Documents.length; i++) {
        const base64String = base64Documents[i].replace(/^data:application\/pdf;base64,/, ''); // Remove the prefix if it exists
        try {
            const pdfBytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
            const pdfDoc = await PDFDocument.load(pdfBytes);

            const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            copiedPages.forEach((page) => {
                mergedPdf.addPage(page);
            });
        } catch (error) {
            reportErrorToSentry(`Error processing PDF at index ${i}:`, error);
        }
    }

    return await mergedPdf.saveAsBase64({ dataUri: true });
};
