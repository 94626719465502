// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { IncotermsCode, ShipmentCreationOrigin, ShipmentsQueryFor } from '../../reducers/shipments/types';
import { PartnerCodec, PartyCodec } from '../common.types';
import { ArticleMasterDataCodec, ExtendedArticleMasterDataCodec } from '../shared/articleMasterData.types';
import { CarrierCodec } from '../shared/carrier.types';
import { DimensionsCodec } from '../shared/dimensions.types';
import { ItemShortDescriptionCodec } from '../shared/itemShortDescription.types';
import { MeansOfTransportCodec, UpdateMeansOfTransportCodec } from '../shared/meansOfTransport.types';
import { PlaceOfDischargeCodec } from '../shared/placeOfDischarge.types';
import { measurementUnitCodeCodec, quantityCodec } from '../shared/quantity.types';
import { ShipmentDateCodec } from '../shared/shipmentDate.types';
import { FreightForwarderFromTransportOrderCodec, ManagedFreightForwarderCodec } from '../shared/transportation.types';
import { fromEnum } from '../util';
import { CustomsCodec } from './customs.types';
import { HandlingUnitGroupCodec, HandlingUnitGroupUpdateCodec } from './packaging.types';

export const AdditionalProductIdCodec = t.partial({
    software_status: t.string,
    hardware_status: t.string,
    parts_generation_status: t.string,
});

export const LoadItemCodec = t.intersection([
    t.type({
        order_number: t.string,
        article_number_buyer: t.string,
        amount: quantityCodec,
        is_subject_to_preference_authorization: t.boolean,
        referenced_delivery_schedule_id: t.string,
    }),
    t.partial({
        batch_number: t.string,
        expiry_date: t.string,
        additional_product_id: AdditionalProductIdCodec,
        article_master_data: ArticleMasterDataCodec,
    }),
]);

export const DeliveryNoteCodec = t.type({
    delivery_note_number: t.number,
    load_items: t.array(LoadItemCodec),
});

const IncotermsCodeCodec = fromEnum<IncotermsCode>('IncotermsCode', IncotermsCode);

export const TermsOfDeliveryCodec = t.partial({
    incoterms_code: IncotermsCodeCodec,
    location: t.type({
        name: t.string,
    }),
});

export const GroupOfIdenticalPackagingHierarchyCodec = t.type({
    id: t.string,
    dimensions: DimensionsCodec,
    mergeable: t.boolean,
    handling_unit_ids: t.array(t.string),
});

export const FreightForwarderCodec = t.union([ManagedFreightForwarderCodec, FreightForwarderFromTransportOrderCodec]);

const ShipmentCreationOriginCodec = fromEnum<ShipmentCreationOrigin>('ShipmentCreationOrigin', ShipmentCreationOrigin);

export const ShipmentCodec = t.intersection([
    t.type({
        id: t.string,
        partner: PartnerCodec,
        buyer: PartyCodec,
        shipment_number: t.number,
        ship_to_id: t.string,
        duns_number_owner: t.string,
        place_of_discharge: PlaceOfDischargeCodec,
        load: t.array(DeliveryNoteCodec),
        exported: t.boolean,
        last_modified_at: t.string,
        packaging: t.array(HandlingUnitGroupCodec),
        group_of_identical_packaging_hierarchies: t.array(GroupOfIdenticalPackagingHierarchyCodec),
        net_weight_in_kg: t.number,
        gross_weight_in_kg: t.number,
        creation_origin: ShipmentCreationOriginCodec,
    }),
    t.partial({
        requested_delivery_date: ShipmentDateCodec,
        estimated_arrival_date: ShipmentDateCodec,
        despatch_date: ShipmentDateCodec,
        terms_of_delivery: TermsOfDeliveryCodec,
        carrier: FreightForwarderCodec,
        executive_carrier: CarrierCodec,
        customs: CustomsCodec,
        means_of_transport: MeansOfTransportCodec,
        exported_at: t.string,
        transport_order_number: t.string,
        transport_order_reference: t.string,
    }),
]);

export const ShipmentsQueryCodec = t.intersection([
    t.type({
        offset: t.number,
        limit: t.number,
    }),
    t.partial({
        q: t.string,
        query_for: fromEnum<ShipmentsQueryFor>('ShipmentsQueryForCodec', ShipmentsQueryFor),
        plant_number: t.string,
        place_of_discharge: t.string,
        exported_at_from: t.string,
        exported_at_to: t.string,
    }),
]);

export const ShipmentsQueryResultCodec = t.type({
    items: t.array(ShipmentCodec),
    total_count: t.number,
    query: t.exact(ShipmentsQueryCodec),
});

export const ShipmentCreationLoadItemCodec = t.type({
    delivery_schedule_id: t.string,
    amount: quantityCodec,
});

export const ShipmentCreationRequestCodec = t.intersection([
    t.type({
        place_of_discharge: PlaceOfDischargeCodec,
        load: t.array(ShipmentCreationLoadItemCodec),
    }),
    t.partial({
        requested_delivery_date: ShipmentDateCodec,
        estimated_arrival_date: ShipmentDateCodec,
    }),
]);

export const ManagedFreightForwarderIdCodec = t.type({
    id: t.string,
});

export const ShipmentUpdateRequestCodec = t.intersection([
    t.type({
        load: t.array(DeliveryNoteCodec),
        packaging: t.array(HandlingUnitGroupUpdateCodec),
        shipment_number: t.number,
    }),
    t.partial({
        despatch_date: ShipmentDateCodec,
        terms_of_delivery: TermsOfDeliveryCodec,
        carrier: ManagedFreightForwarderIdCodec,
        customs: CustomsCodec,
        means_of_transport: UpdateMeansOfTransportCodec,
        transport_order_number: t.string,
        estimated_arrival_date: ShipmentDateCodec,
    }),
]);

export const ArticleSuggestionCodec = t.intersection([
    t.type({
        article_number_buyer: t.string,
        order_number: t.string,
        item_short_descriptions: t.array(ItemShortDescriptionCodec),
        measurement_unit_code: measurementUnitCodeCodec,
        referenced_delivery_schedule_id: t.string,
        has_valid_article_master_data: t.boolean,
        has_valid_cumulative_quantity_sent: t.boolean,
    }),
    t.partial({
        article_master_data: ArticleMasterDataCodec,
    }),
]);

export const ArticleSuggestionsCodec = t.type({
    items: t.array(ArticleSuggestionCodec),
});

export const DeliveryNoteNumberDataCodec = t.type({
    delivery_note_number: t.number,
});

export const LabelNumberDataCodec = t.type({
    label_number: t.number,
});

export type ApiShipment = t.TypeOf<typeof ShipmentCodec>;
export type ApiShipmentCreationRequest = t.TypeOf<typeof ShipmentCreationRequestCodec>;
export type ApiLoadItem = t.TypeOf<typeof LoadItemCodec>;
export type ApiDeliveryNote = t.TypeOf<typeof DeliveryNoteCodec>;
export type ApiTermsOfDelivery = t.TypeOf<typeof TermsOfDeliveryCodec>;
export type ApiShipmentCreationLoadItem = t.TypeOf<typeof ShipmentCreationLoadItemCodec>;
export type ApiShipmentUpdateRequest = t.TypeOf<typeof ShipmentUpdateRequestCodec>;
export type ApiShipmentsQuery = t.TypeOf<typeof ShipmentsQueryCodec>;
export type ApiShipmentsQueryResult = t.TypeOf<typeof ShipmentsQueryResultCodec>;
export type ApiArticleSuggestion = t.TypeOf<typeof ArticleSuggestionCodec>;
export type ApiArticleSuggestions = t.TypeOf<typeof ArticleSuggestionsCodec>;
export type ApiAdditionalProductId = t.TypeOf<typeof AdditionalProductIdCodec>;
export type ApiDeliveryNoteNumberData = t.TypeOf<typeof DeliveryNoteNumberDataCodec>;
export type ApiLabelNumberData = t.TypeOf<typeof LabelNumberDataCodec>;
export type ApiGroupOfIdenticalPackagingHierarchy = t.TypeOf<typeof GroupOfIdenticalPackagingHierarchyCodec>;
export type ApiShipmentCreationOrigin = t.TypeOf<typeof ShipmentCreationOriginCodec>;

export const ExtendedArticleMasterDataListCodec = t.type({
    items: t.array(ExtendedArticleMasterDataCodec),
});
export type ApiExtendedArticleMasterDataList = t.TypeOf<typeof ExtendedArticleMasterDataListCodec>;
