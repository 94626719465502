import { ShipmentSuggestion, ShipmentSuggestionResponse, SuggestedLoadItem } from '../../reducers/suggestions/types';
import { mapItemShortDescription } from '../deliverySchedule/deliveryScheduleMapper';
import { mapArticleMasterData, mapQuantity } from '../shared/sharedMappers';
import { mapPlaceOfDischarge } from '../shipment/shipmentMapper';
import { ApiShipmentSuggestion, ApiShipmentSuggestions, ApiSuggestedLoadItem } from './shipmentSuggestion.types';

export const mapShipmentSuggestions = (apiShipmentSuggestions: ApiShipmentSuggestions): ShipmentSuggestionResponse => {
    return {
        shipmentSuggestions: apiShipmentSuggestions.items.map(mapShipmentSuggestion),
        totalCount: apiShipmentSuggestions.total_count,
    };
};

export const mapShipmentSuggestion = (apiShipmentSuggestion: ApiShipmentSuggestion): ShipmentSuggestion => {
    return {
        requestedDeliveryDate: apiShipmentSuggestion.requested_delivery_date.value,
        placeOfDischarge: mapPlaceOfDischarge(apiShipmentSuggestion.place_of_discharge),
        load: mapSuggestedLoad(apiShipmentSuggestion.load),
        shipToId: apiShipmentSuggestion.ship_to_id,
        manufacturingCompany: apiShipmentSuggestion.manufacturing_company,
        shipFromId: apiShipmentSuggestion.ship_from_id,
    };
};

export const mapSuggestedLoad = (apiLoad: ApiSuggestedLoadItem[]): SuggestedLoadItem[] => {
    return apiLoad.map(mapSuggestedLoadItem);
};

const mapSuggestedLoadItem = (apiLoadItem: ApiSuggestedLoadItem): SuggestedLoadItem => {
    return {
        deliveryScheduleId: apiLoadItem.delivery_schedule_id,
        articleNumberBuyer: apiLoadItem.article_number_buyer,
        articleMasterData: apiLoadItem.article_master_data && mapArticleMasterData(apiLoadItem.article_master_data),
        amount: mapQuantity(apiLoadItem.amount),
        itemShortDescriptions: apiLoadItem.item_short_descriptions.map(mapItemShortDescription),
        orderNumber: apiLoadItem.order_number,
        requestCondition: apiLoadItem.request_condition,
        hasValidArticleMasterData: apiLoadItem.has_valid_article_master_data,
        hasValidCumulativeQuantitySent: apiLoadItem.has_valid_cumulative_quantity_sent,
    };
};
