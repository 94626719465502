import { Dispatch, State } from '../../../../configuration/setup/store';
import { fetchPackagingMaterial } from '../../api/packaging/packagingMaterialCalls';
import { packagingMaterialSlice } from '../../reducers/packaging/PackagingMaterial.reducer';
import { PackagingMaterial } from '../../reducers/packaging/types';
import { BuyerContext } from '../../selectors/packaging/PackagingMaterial.selector';

export const fetchAndStorePackagingMaterial =
    (boxCode: string, buyerContext: BuyerContext) =>
    async (dispatch: Dispatch, getState: () => State): Promise<PackagingMaterial | undefined> => {
        const state = getState();
        const alreadyFetchedPackagingMaterialResult = state.webEdi.packagingMaterial.packagingMaterials[boxCode];

        if (
            alreadyFetchedPackagingMaterialResult?.successful &&
            alreadyFetchedPackagingMaterialResult.buyer === buyerContext.buyer &&
            alreadyFetchedPackagingMaterialResult.partner === buyerContext.partner
        ) {
            return alreadyFetchedPackagingMaterialResult.packagingMaterial!;
        }

        dispatch(packagingMaterialSlice.actions.setIsFetching({ isFetching: true }));
        return fetchPackagingMaterial(boxCode, buyerContext.buyer, buyerContext.partner)
            .then((packagingMaterial: PackagingMaterial) => {
                dispatch(
                    packagingMaterialSlice.actions.setPackagingMaterial({
                        boxCode,
                        packagingMaterial,
                        buyer: buyerContext.buyer,
                        partner: buyerContext.partner,
                    }),
                );
                return packagingMaterial;
            })
            .catch(() => {
                dispatch(packagingMaterialSlice.actions.setError({ boxCode }));
                return undefined;
            })
            .finally(() => {
                dispatch(packagingMaterialSlice.actions.setIsFetching({ isFetching: false }));
            });
    };

export const cleanPackagingMaterials = () => async (dispatch: Dispatch) => {
    await dispatch(packagingMaterialSlice.actions.cleanPackagingMaterials());
};
