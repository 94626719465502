import { Dimensions } from '../reducers/shipments/types';
import { ItemShortDescription } from '../reducers/types';
import { MeasurementUnitCode, Partner, Quantity } from './common.types';
import { TransportConcept } from './meansOfTransport.types';

export interface DispatchProposal {
    readonly id: string;
    readonly termination: Termination;
    readonly applicationReferenceNumber?: string;
    readonly items: DispatchProposalItem[];
    readonly transportOrderStatus: TransportOrderStatus;
    readonly supplierTimezone: string;
    readonly lastModifiedAt: string;
    readonly operations: DispatchProposalOperations;
    readonly untouched: boolean;
}

export interface FtlTermination {
    readonly transportConcept: TransportConcept.FTL;
    readonly freightForwarder: FreightForwarder;
    readonly cutoffDate: string;
    readonly pickupDate: string;
}

export interface LtlTermination {
    readonly transportConcept: TransportConcept.LTL;
    readonly freightForwarder: FreightForwarder;
    readonly cutoffDate: string;
    readonly pickupDate: string;
}

export interface KepTermination {
    readonly transportConcept: TransportConcept.KEP;
    readonly cutoffDate: string;
}

export type Termination = FtlTermination | LtlTermination | KepTermination;

export const isLtlOrFtlTermination = (it: DispatchProposal): it is DispatchProposal =>
    [TransportConcept.FTL, TransportConcept.LTL].includes(it.termination.transportConcept);

export interface FreightForwarder {
    readonly name: string;
    readonly dunsNumber: string;
    readonly supplierCode: string;
}

export type DispatchProposalItem = UnpackagedDispatchProposalItem | PackagedDispatchProposalItem;

export const areDispatchProposalItemIdentifiersEqual = (
    left: DispatchProposalItem,
    right: DispatchProposalItem,
): boolean => {
    if (left.type !== right.type) {
        return false;
    }
    switch (left.type) {
        case 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM': {
            const leftIdentifier = (left as UnpackagedDispatchProposalItem).identifier;
            const rightIdentifier = (right as UnpackagedDispatchProposalItem).identifier;
            return (
                leftIdentifier.shipmentId === rightIdentifier.shipmentId &&
                leftIdentifier.deliveryNoteNumber === rightIdentifier.deliveryNoteNumber &&
                leftIdentifier.deliveryNotePosition === rightIdentifier.deliveryNotePosition
            );
        }
        case 'PACKAGED_DISPATCH_PROPOSAL_ITEM': {
            const leftIdentifier = (left as PackagedDispatchProposalItem).identifier;
            const rightIdentifier = (right as PackagedDispatchProposalItem).identifier;
            return leftIdentifier.shipmentId === rightIdentifier.shipmentId && leftIdentifier.id === rightIdentifier.id;
        }
        default:
            return false;
    }
};

export interface UnpackagedDispatchProposalItem {
    readonly type: 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM';
    readonly identifier: UnpackagedDispatchProposalItemIdentifier;
    readonly articleContent: ArticleContent;
    readonly buyer: Buyer;
    readonly partner: Partner;
    readonly deliveryDate?: string;
    readonly shipFrom: ShipFrom;
    readonly shipTo: ShipTo;
    readonly shipmentNumber?: number;
}

export type DispatchProposalItemIdentifier =
    | PackagedDispatchProposalItemIdentifier
    | UnpackagedDispatchProposalItemIdentifier;

export interface UnpackagedDispatchProposalItemIdentifier {
    shipmentId: string;
    deliveryNoteNumber: number;
    deliveryNotePosition: number;
}

export interface ArticleContent {
    readonly articleNumberBuyer: string;
    readonly quantity: Quantity;
    readonly deliveryScheduleId: string;
}

export interface Buyer {
    readonly name: string;
}

export interface ShipFrom {
    readonly shippingLocationId?: string;
}

export interface ShipTo {
    readonly plantCode: string;
    readonly placeOfDischarge: string;
}

export type TransportOrderStatus = 'UNPLANNED' | 'PLANNED' | 'ORDERED';

export interface PackagedDispatchProposalItem {
    readonly type: 'PACKAGED_DISPATCH_PROPOSAL_ITEM';
    readonly identifier: PackagedDispatchProposalItemIdentifier;
    readonly deliveryDate?: string;
    readonly articleContents: ArticleContent[];
    readonly grossWeightInKg: number;
    readonly buyer: Buyer;
    readonly partner: Partner;
    readonly shipFrom: ShipFrom;
    readonly shipTo: ShipTo;
    readonly shipmentNumber?: number;
    readonly outerPackaging: PackagingSummary;
    readonly innerPackaging: PackagingSummary[];
    readonly dimensions: Dimensions;
    readonly referencedDeliveryNotePositions: DeliveryNoteNumberAndPosition[];
}

export interface DeliveryNoteNumberAndPosition {
    readonly deliveryNoteNumber: number;
    readonly position: number;
}

interface DispatchProposalItemIdentifierBase {
    readonly shipmentId: string;
}

export interface PackagedDispatchProposalItemIdentifier extends DispatchProposalItemIdentifierBase {
    readonly id: string;
}

export interface PackagingSummary {
    readonly type: string;
    readonly count: number;
}

export interface DispatchProposalOperations {
    readonly createTransportOrderIntent: TimeBasedDispatchProposalItemOperation;
    readonly deleteTransportOrderIntent: TimeBasedDispatchProposalOperation;
    readonly addArticle: UnconstrainedDispatchProposalOperation;
    readonly modifyLoadItemAmount: UnconstrainedDispatchProposalOperation;
    readonly changeLoadingLocation: UnconstrainedDispatchProposalOperation;
    readonly convertDispatchProposalItemToKep: UnconstrainedDispatchProposalOperation;
}

export interface UnconstrainedDispatchProposalOperation {
    readonly allowed: boolean;
}

export interface TimeBasedDispatchProposalOperation {
    readonly allowed: boolean;
    readonly availableUntil: string;
}

export interface TimeBasedDispatchProposalItemOperation {
    readonly allowedPerItem: Array<{ identifier: DispatchProposalItemIdentifier; allowed: boolean }>;
    readonly availableUntil: string;
}

export interface ArticleSuggestion {
    readonly buyer: Buyer;
    readonly shipFrom: ShipFrom;
    readonly shipTo: ShipTo;
    readonly articleNumberBuyer: string;
    readonly itemShortDescriptions: ItemShortDescription[];
    readonly measurementUnitCode: MeasurementUnitCode;
    readonly referencedDeliveryScheduleId: string;
    readonly hasValidArticleMasterData: boolean;
    readonly hasValidCumulativeQuantitySent: boolean;
}

export type ManualDispatchProposalDraft =
    | ManualDispatchProposalDraftFTL
    | ManualDispatchProposalDraftLTL
    | ManualDispatchProposalDraftKEP;

export type ManualDispatchProposalDraftCommon = {
    readonly id: string;
    readonly cutoffDate: string;
    readonly plantCode: string;
};

export type ManualDispatchProposalDraftFTL = {
    readonly placeOfDischargeMasterDataId: string;
    readonly transportConcept: TransportConcept.FTL;
} & ManualDispatchProposalDraftCommon;

export type ManualDispatchProposalDraftLTL = {
    readonly transportConcept: TransportConcept.LTL;
} & ManualDispatchProposalDraftCommon;

export type ManualDispatchProposalDraftKEP = {
    readonly placeOfDischargeMasterDataId: string;
    readonly transportConcept: TransportConcept.KEP;
} & ManualDispatchProposalDraftCommon;

export interface EditLoadItemAmountDialogData {
    readonly dispatchProposalId: string;
    readonly shipmentId: string;
    readonly deliveryNoteNumber: number;
    readonly deliveryNotePosition: number;
    readonly articleNumberBuyer: string;
    readonly quantity: Quantity;
    readonly numberOfDispatchProposalItems: number;
}

export const isDispatchProposalItem = (item: DispatchProposalItem | ArticleSuggestion): item is DispatchProposalItem =>
    (item as DispatchProposalItem).type !== undefined;

export const isUnpackagedDispatchProposalItem = (item: DispatchProposalItem): item is UnpackagedDispatchProposalItem =>
    item.type === 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM';

export const isPackagedDispatchProposalItem = (item: DispatchProposalItem): item is PackagedDispatchProposalItem =>
    item.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM';

export interface DispatchProposalProblemsCount {
    info: number;
    warning: number;
    error: number;

    [key: string]: number;
}

export const groupDispatchProposalProblemsByLevel = (
    problems: DispatchProposalProblem[],
    dispatchProposalId: string = '',
): DispatchProposalProblemsCount =>
    problems
        .filter(
            (it) =>
                ('dispatchProposalId' in it && it.dispatchProposalId === dispatchProposalId) ||
                dispatchProposalId === '',
        )
        .reduce(
            (problemsCount: DispatchProposalProblemsCount, { level }) => {
                problemsCount[level.toLowerCase() as keyof DispatchProposalProblemsCount]++;
                return problemsCount;
            },
            { info: 0, warning: 0, error: 0 },
        );

export enum DispatchProposalProblemType {
    MISSING_ARTICLE_MASTER_DATA = 'MISSING_ARTICLE_MASTER_DATA',
    ARTICLE_QUANTITY_DISCREPANCY = 'ARTICLE_QUANTITY_DISCREPANCY',
    INCOMPLETE_PACKAGING = 'INCOMPLETE_PACKAGING',
    MISSING_PACKAGING_HEIGHT = 'MISSING_PACKAGING_HEIGHT',
    MISSING_LOADING_LOCATION = 'MISSING_LOADING_LOCATION',
    FREIGHT_FORWARDER_CHANGED = 'FREIGHT_FORWARDER_CHANGED',
}

export type DispatchProposalProblem =
    | MissingArticleMasterDataProblem
    | ArticleQuantityDiscrepancyProblem
    | DispatchProposalNotCompletelyPackagedProblem
    | MissingPackagingHeightProblem
    | MissingLoadingLocationProblem
    | DispatchProposalFreightForwarderChangedProblem;

export interface DispatchProposalProblemBase {
    id: string;
    dunsNumber: string;
    affectedDate: string;
    createdAt: string;
    level: DispatchProposalProblemLevel;
    type: DispatchProposalProblemType;
}

export enum DispatchProposalProblemLevel {
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    INFO = 'INFO',
}

export interface MissingArticleMasterDataProblem extends DispatchProposalProblemBase {
    type: DispatchProposalProblemType.MISSING_ARTICLE_MASTER_DATA;
    missingData: MissingArticleMasterDataProblemMissingData[];
    articleNumberBuyer: string;
    deliveryScheduleId: string;
}

export enum MissingArticleMasterDataProblemMissingData {
    ARTICLE_NET_WEIGHT = 'ARTICLE_NET_WEIGHT',
    COUNTRY_OF_ORIGIN = 'COUNTRY_OF_ORIGIN',
}

export interface ArticleQuantityDiscrepancyProblem extends DispatchProposalProblemBase {
    type: DispatchProposalProblemType.ARTICLE_QUANTITY_DISCREPANCY;
    dismissible: boolean;
    articleNumberBuyer: string;
    deliveryDate: string;
    targetQuantity: Quantity;
    plantCode: string;
    placeOfDischarge: string;
}

export interface MissingPackagingHeightProblem extends DispatchProposalProblemBase {
    type: DispatchProposalProblemType.MISSING_PACKAGING_HEIGHT;
    dispatchProposalId: string;
    giphyId: string;
}

export interface UnpackagedDispatchProposalItemIdentifier extends DispatchProposalItemIdentifierBase {
    deliveryNoteNumber: number;
    deliveryNotePosition: number;
}

export interface DispatchProposalNotCompletelyPackagedProblem extends DispatchProposalProblemBase {
    type: DispatchProposalProblemType.INCOMPLETE_PACKAGING;
    dismissible: boolean;
    dunsNumber: string;
    affectedDate: string;
    createdAt: string;
    dispatchProposalId: string;
    identifiers: UnpackagedDispatchProposalItemIdentifier[];
}

export interface MissingLoadingLocationProblem extends DispatchProposalProblemBase {
    type: DispatchProposalProblemType.MISSING_LOADING_LOCATION;
    dismissible: boolean;
    dunsNumber: string;
    affectedDate: string;
    createdAt: string;
    dispatchProposalId: string;
    identifiers: DispatchProposalItemIdentifier[];
}

export interface DispatchProposalFreightForwarderChangedProblem extends DispatchProposalProblemBase {
    type: DispatchProposalProblemType.FREIGHT_FORWARDER_CHANGED;
    dismissible: boolean;
    dunsNumber: string;
    affectedDate: string;
    createdAt: string;
    dispatchProposalId: string;
    newCutoffDate?: string;
}

export const isMissingArticleMasterDataProblem = (
    problem: DispatchProposalProblem,
): problem is MissingArticleMasterDataProblem =>
    problem.type === DispatchProposalProblemType.MISSING_ARTICLE_MASTER_DATA;

export const isArticleQuantityDiscrepancyProblem = (
    problem: DispatchProposalProblem,
): problem is ArticleQuantityDiscrepancyProblem =>
    problem.type === DispatchProposalProblemType.ARTICLE_QUANTITY_DISCREPANCY;

export const isDispatchProposalNotCompletelyPackagedProblem = (
    problem: DispatchProposalProblem,
): problem is DispatchProposalNotCompletelyPackagedProblem =>
    problem.type === DispatchProposalProblemType.INCOMPLETE_PACKAGING;

export const isMissingLoadingLocationProblem = (
    problem: DispatchProposalProblem,
): problem is MissingLoadingLocationProblem => problem.type === DispatchProposalProblemType.MISSING_LOADING_LOCATION;

export const isMissingPackagingHeightProblem = (
    problem: DispatchProposalProblem,
): problem is MissingPackagingHeightProblem => problem.type === DispatchProposalProblemType.MISSING_PACKAGING_HEIGHT;

export const isDispatchProposalFreightForwarderChangedProblem = (
    problem: DispatchProposalProblem,
): problem is DispatchProposalFreightForwarderChangedProblem =>
    problem.type === DispatchProposalProblemType.FREIGHT_FORWARDER_CHANGED;

export const containsProblemsOfDispatchProposal = (
    problems: DispatchProposalProblem[],
    dispatchProposal: DispatchProposal,
) => problems.some((it) => 'dispatchProposalId' in it && it.dispatchProposalId === dispatchProposal.id);

const containsMissingPackagingHeightProblemForItem = (
    problems: DispatchProposalProblem[],
    proposal: DispatchProposal,
    item: UnpackagedDispatchProposalItem | PackagedDispatchProposalItem,
) =>
    item.type === 'PACKAGED_DISPATCH_PROPOSAL_ITEM' &&
    problems
        .filter(isMissingPackagingHeightProblem)
        .filter((problem) => problem.dispatchProposalId === proposal.id)
        .filter((problem) => problem.giphyId === item.identifier.id).length > 0;

const containsMissingLoadingLocationProblemForItem = (
    problems: DispatchProposalProblem[],
    proposal: DispatchProposal,
    item: UnpackagedDispatchProposalItem | PackagedDispatchProposalItem,
) =>
    problems
        .filter(isMissingLoadingLocationProblem)
        .filter((problem) => problem.dispatchProposalId === proposal.id)
        .filter((problem) =>
            problem.identifiers.some((identifier) => sameDispatchProposalItemIdentifiers(identifier, item.identifier)),
        ).length > 0;

const containsDispatchProposalNotCompletelyPackagedIdentifiersForItem = (
    problems: DispatchProposalProblem[],
    proposal: DispatchProposal,
    item: UnpackagedDispatchProposalItem | PackagedDispatchProposalItem,
) =>
    problems
        .filter(isDispatchProposalNotCompletelyPackagedProblem)
        .filter((problem) => problem.dispatchProposalId === proposal.id)
        .filter((problem) =>
            problem.identifiers.some((identifier) => sameDispatchProposalItemIdentifiers(identifier, item.identifier)),
        ).length > 0;

export const containsProblemsForDispatchProposalItem = (
    problems: DispatchProposalProblem[],
    proposal: DispatchProposal,
    item: DispatchProposalItem,
) =>
    containsMissingPackagingHeightProblemForItem(problems, proposal, item) ||
    containsMissingLoadingLocationProblemForItem(problems, proposal, item) ||
    containsDispatchProposalNotCompletelyPackagedIdentifiersForItem(problems, proposal, item);

export const isPackagedDispatchProposalItemIdentifier = (
    identifier: DispatchProposalItemIdentifier,
): identifier is PackagedDispatchProposalItemIdentifier =>
    (identifier as PackagedDispatchProposalItemIdentifier).id !== undefined;

export const isUnpackagedDispatchProposalItemIdentifier = (
    identifier: DispatchProposalItemIdentifier,
): identifier is UnpackagedDispatchProposalItemIdentifier => {
    const casted = identifier as UnpackagedDispatchProposalItemIdentifier;
    return casted.deliveryNoteNumber !== undefined && casted.deliveryNotePosition !== undefined;
};

export const sameDispatchProposalItemIdentifiers = (
    left: DispatchProposalItemIdentifier,
    right: DispatchProposalItemIdentifier,
) => {
    if (isUnpackagedDispatchProposalItemIdentifier(left) && isUnpackagedDispatchProposalItemIdentifier(right)) {
        return (
            left.shipmentId === right.shipmentId &&
            left.deliveryNoteNumber === right.deliveryNoteNumber &&
            left.deliveryNotePosition === right.deliveryNotePosition
        );
    } else if (isPackagedDispatchProposalItemIdentifier(left) && isPackagedDispatchProposalItemIdentifier(right)) {
        return left.shipmentId === right.shipmentId && left.id === right.id;
    } else {
        return false;
    }
};
