import { FormattedMessage } from 'react-intl';

export const MANAGED_FREIGHT_FORWARDER_ID_HELP_LINK = 'MANAGED_FREIGHT_FORWARDER_ID_HELP_LINK';

export const ManagedFreightForwarderIdHelpLink = () => {
    return (
        <a
            className={'float-right text-size-12 text-decoration-none'}
            href={
                'https://lso.volkswagen.de/one-kbp/content/de/kbp_private/kbpprivate_systems/kbpprivate_systempage_31680.jsp'
            }
            target={'_blank'}
            rel={'noopener noreferrer'}
            data-testid={MANAGED_FREIGHT_FORWARDER_ID_HELP_LINK}
        >
            <span className={'rioglyph rioglyph-question-sign padding-right-3'} />
            <FormattedMessage id={'webedi.carrier.help'} />
        </a>
    );
};
