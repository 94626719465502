import { neverReachedFor } from '../../../../../../../utils';
import { PackagingStep } from '../../../../../reducers/shipments/packaging.types';
import { AuxiliaryPackaging } from '../AuxiliaryPackaging/AuxiliaryPackaging';
import { OuterPackagingGroupingSelection } from './OuterPackagingGroupingSelection';
import { OuterPackagingPackagingMaterialSelection } from './OuterPackagingPackagingMaterialSelection';
import { PackagingLayerConfiguration } from './PackagingLayerConfiguration';

export const PACKAGING_CONFIGURATION_TEST_ID = 'PACKAGING_CONFIGURATION_TEST_ID';
export const OuterPackaging = (props: { step: PackagingStep }) => {
    const renderOuterPackaging = () => {
        switch (props.step) {
            case PackagingStep.GROUPING_SELECTION:
                return <OuterPackagingGroupingSelection />;
            case PackagingStep.PACKAGING_MATERIAL_SELECTION:
                return <OuterPackagingPackagingMaterialSelection />;
            case PackagingStep.CONFIGURATION:
                return (
                    <div data-testid={PACKAGING_CONFIGURATION_TEST_ID}>
                        <PackagingLayerConfiguration />
                    </div>
                );
            case PackagingStep.AUXILIARY_CONFIGURATION:
                return <AuxiliaryPackaging />;
            default:
                return neverReachedFor(props.step);
        }
    };

    return renderOuterPackaging();
};
