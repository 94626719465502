import { config } from '../../../../config';
import { Partner } from '../../domain/common.types';
import { PackagingMaterial } from '../../reducers/packaging/types';
import { decodeJson, jsonOrReject } from '../apiUtils';
import { mapPartnerToApi } from '../commonMapper';
import { getRequest } from '../requests';
import { PackagingMaterialCodec } from '../shared/packagingMaterial.types';
import { mapPackagingMaterial } from './packagingMaterialMapper';

export const fetchPackagingMaterial = (
    boxCode: string,
    buyer: string,
    partner: Partner,
): Promise<PackagingMaterial> => {
    const apiPartner = mapPartnerToApi(partner);
    return fetch(
        `${config.webEdiServiceUrl}/packaging-materials/${boxCode}?buyer=${buyer}&partner=${apiPartner}`,
        getRequest(),
    )
        .then((value) => {
            if (value.status === 404) {
                return Promise.reject();
            }
            return Promise.resolve(value);
        })
        .then(jsonOrReject)
        .then(decodeJson(PackagingMaterialCodec))
        .then(mapPackagingMaterial);
};
