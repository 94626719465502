import { FormattedMessage, useIntl } from 'react-intl';
import { hasMlTypeProcessIndicator } from '../../../../../utils';
import { DeliveryInstructionType, DeliverySchedule } from '../../../reducers/deliverySchedules/types';
import { ArticleNumber } from '../../common/ArticleNumber';
import { renderDetailAttributeTextCard } from '../../common/DetailAttributeCard';
import { ItemShortDescription } from '../../common/ItemShortDescription';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { Tooltip } from '../../common/Tooltip';
import { FormattedManufacturingCompany } from '../FormattedManufacturingCompany';
import {
    deliveryScheduleContainsDailyCallOf,
    renderCumulativeQuantityReceived,
    renderDeliveryCallOffDateAndNumber,
} from './DeliveryScheduleDetailsHeaderSection';

export const DNG_DELIVERY_SCHEDULES_DETAILS_HEADER_TEST_ID = 'DNG_DELIVERY_SCHEDULES_DETAILS_HEADER_TEST_ID';

export const DNGDeliveryScheduleDetailsHeaderSection = ({
    deliverySchedule,
}: {
    deliverySchedule: DeliverySchedule;
}) => {
    const intl = useIntl();

    return (
        <div className={'padding-15 padding-top-20'} data-testid={DNG_DELIVERY_SCHEDULES_DETAILS_HEADER_TEST_ID}>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.common.manufacturingCompany'} />,
                    <FormattedManufacturingCompany manufacturingCompany={deliverySchedule.buyer} />,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.plantIdentifier'} />,
                    deliverySchedule.shipTo.shipTo.partyIdentificationDetails.identifier,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.placeOfDelivery'} />,
                    deliverySchedule.shipTo.placeOfDelivery.locationNameCode,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.shipFrom.partyIdentifier'} />,
                    deliverySchedule.shipFrom.partyIdentificationDetails.identifier,
                    '10',
                )}
            </div>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.orderNumber'} />,
                    deliverySchedule.scheduledArticleDetails.orderNumber.referenceIdentifier,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.articleNumber.buyer'} />,

                    <ArticleNumber
                        articleNumber={
                            deliverySchedule.scheduledArticleDetails.lineItem.lineItemIdentifier
                                .itemNumberIdentification.itemIdentifier
                        }
                    />,
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.articleNumber.seller'} />,
                    <ArticleNumber articleNumber={deliverySchedule.articleMasterData?.articleNumberSeller} />,
                    '10',
                )}
                <Tooltip
                    width={200}
                    text={deliverySchedule.scheduledArticleDetails.lineItem.itemShortDescriptions[0]?.itemDescription}
                    placement={'bottom'}
                >
                    {renderDetailAttributeTextCard(
                        <FormattedMessage id={'webedi.itemShortDescription'} />,
                        deliverySchedule.scheduledArticleDetails.lineItem.itemShortDescriptions && (
                            <ItemShortDescription
                                itemShortDescriptions={
                                    deliverySchedule.scheduledArticleDetails.lineItem.itemShortDescriptions
                                }
                            />
                        ),
                        '10',
                    )}
                </Tooltip>
            </div>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.deliveryCallOffDateAndNumber'} />,
                    renderDeliveryCallOffDateAndNumber(deliverySchedule, DeliveryInstructionType.LAB),
                    '10',
                )}
                {renderDetailAttributeTextCard(
                    <FormattedMessage id={'webedi.deliverySchedule.scheduledArticleDetails.quantityUnit'} />,
                    <MeasurementUnitCodeDisplay unit={deliverySchedule.scheduledArticleDetails.measurementUnitCode} />,
                    '10',
                )}
                {hasMlTypeProcessIndicator(deliverySchedule)
                    ? renderDetailAttributeTextCard(
                          <FormattedMessage
                              id={'webedi.deliverySchedule.scheduledArticleDetails.cumulativeQuantityOrdered'}
                          />,
                          deliverySchedule.scheduledArticleDetails.cumulativeQuantityOrdered?.quantity,
                          '10',
                      )
                    : renderDetailAttributeTextCard(
                          <FormattedMessage
                              id={'webedi.deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived'}
                          />,
                          renderCumulativeQuantityReceived(
                              deliverySchedule.scheduledArticleDetails.cumulativeQuantityReceived,
                              intl,
                          ),
                          '10',
                      )}
                {deliverySchedule.manuallyCreated
                    ? renderDetailAttributeTextCard(
                          <FormattedMessage
                              id={'webedi.deliverySchedule.scheduledArticleDetails.workflowType.label'}
                          />,
                          <span className={'text-color-gray'}>
                              {' '}
                              <FormattedMessage
                                  id={
                                      'webedi.deliverySchedule.scheduledArticleDetails.workflowType.withoutShipmentProposal'
                                  }
                              />
                          </span>,
                          '10',
                      )
                    : renderDetailAttributeTextCard(
                          <FormattedMessage
                              id={'webedi.deliverySchedule.scheduledArticleDetails.workflowType.label'}
                          />,
                          <b>
                              {' '}
                              <FormattedMessage
                                  id={'webedi.deliverySchedule.scheduledArticleDetails.workflowType.dispatchProposal'}
                              />
                          </b>,
                          '10',
                      )}
            </div>
            <div className={'display-flex flex-row padding-bottom-10'}>
                {deliveryScheduleContainsDailyCallOf(deliverySchedule) ? (
                    renderDetailAttributeTextCard(
                        <FormattedMessage id={'webedi.deliverySchedule.dailyCallOffDateAndNumber'} />,
                        renderDeliveryCallOffDateAndNumber(deliverySchedule, DeliveryInstructionType.FAB),
                        '10',
                    )
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
