import { ConfigCatProvider, DataGovernance, PollingMode, createConsoleLogger } from 'configcat-react';
import { ReactNode } from 'react';
import { config } from '../../config';
import { getConfigCatSdkKeyForToken } from '../setup/configSelector';
import { useAppSelector } from '../setup/typedReduxHooks';

export const FeatureToggleProvider = (props: { children?: ReactNode }) => {
    const configCatSdkKey = useAppSelector(getConfigCatSdkKeyForToken);
    return (
        <ConfigCatProvider
            sdkKey={configCatSdkKey}
            pollingMode={PollingMode.LazyLoad}
            options={{
                cacheTimeToLiveSeconds: config.featureToggles.cacheTimeToLiveSeconds,
                logger: createConsoleLogger(config.featureToggles.logLevel),
                dataGovernance: DataGovernance.EuOnly,
            }}
        >
            {props.children}
        </ConfigCatProvider>
    );
};
