import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { RioCompanyName } from './RioCompanyName';
import { RioSelectedDunsNumber } from './RioSelectedDunsNumber';

export const COMPANY_INFO_TEST_ID = 'COMPANY_INFO_TEST_ID';
export const RioCompanyInfo = () => {
    return (
        <ActionBarItem id={'companyInfo'} popoverWidth={300} data-testid={COMPANY_INFO_TEST_ID}>
            <ActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph rioglyph-factory'} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover>
                <div>
                    <FormattedMessage
                        id={'webedi.companyInfo.description'}
                        values={{ strong: (chunks: ReactNode) => <strong>{chunks}</strong> }}
                    />
                </div>
                <RioSelectedDunsNumber key={'selectedDunsNumber'} />
                <RioCompanyName />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};
