// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { config } from '../../../../config';
import { decodeJson, jsonOrReject } from '../apiUtils';
import { getRequest } from '../requests';

export interface UserInfo {
    dunsNumber: string;
}

export const fetchUserInfoFromApi = (userId: string): Promise<UserInfo> => {
    if (!userId) {
        return Promise.reject('UserId must not be null');
    }
    const url = new URL(`${config.webEdiServiceUrl}/user-info`);
    url.searchParams.append('user_id', userId);

    return fetch(url.toString(), getRequest()).then(jsonOrReject).then(decodeJson(UserInfoCodec)).then(mapToUserInfo);
};

const UserInfoCodec = t.strict({
    duns_number: t.string,
});

export type ApiUserInfo = t.TypeOf<typeof UserInfoCodec>;

const mapToUserInfo = (obj: ApiUserInfo): UserInfo => {
    return {
        dunsNumber: obj.duns_number,
    };
};

export interface CompanyInfo {
    companyName: string;
}

export const fetchCompanyInfoFromApi = (dunsNumber: string): Promise<CompanyInfo> => {
    if (!dunsNumber) {
        return Promise.reject(new Error('DUNS number must not be null'));
    }
    const url = new URL(`${config.webEdiServiceUrl}/shippers/${dunsNumber}`);

    return fetch(url.toString(), getRequest())
        .then(jsonOrReject)
        .then(decodeJson(CompanyInfoCodec))
        .then(mapToCompanyInfo);
};

const CompanyInfoCodec = t.strict({
    company_name: t.string,
});

export type ApiCompanyInfo = t.TypeOf<typeof CompanyInfoCodec>;

const mapToCompanyInfo = (obj: ApiCompanyInfo): CompanyInfo => {
    return {
        companyName: obj.company_name,
    };
};
