import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ManagedFreightForwarder } from '../transportation/types';
import { MasterDataState } from './types';

const initialState: MasterDataState = {
    selectedShippingLocationId: undefined,
    managedFreightForwarders: [],
    selectedManagedFreightForwarderId: undefined,
    isLoading: false,
};

export const masterDataSlice = createSlice({
    name: 'masterData',
    initialState,
    reducers: {
        setSelectedShippingLocation: (state: MasterDataState, action: PayloadAction<string | undefined>) => {
            state.selectedShippingLocationId = action.payload;
        },
        updateManagedFreightForwarders: (state: MasterDataState, action: PayloadAction<ManagedFreightForwarder[]>) => {
            state.managedFreightForwarders = action.payload;
        },
        setSelectedManagedFreightForwarder: (state: MasterDataState, action: PayloadAction<string | undefined>) => {
            state.selectedManagedFreightForwarderId = action.payload;
        },
        setIsLoading: (state: MasterDataState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});
