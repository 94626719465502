import { v4 as uuid } from 'uuid';
import { neverReachedFor } from '../../../../utils';
import { Quantity } from '../../domain/common.types';
import {
    ArticleContent,
    ArticleSuggestion,
    Buyer,
    DispatchProposal,
    DispatchProposalItem,
    DispatchProposalItemIdentifier,
    DispatchProposalOperations,
    DispatchProposalProblem,
    DispatchProposalProblemLevel,
    DispatchProposalProblemType,
    FreightForwarder,
    MissingArticleMasterDataProblemMissingData,
    PackagedDispatchProposalItem,
    PackagedDispatchProposalItemIdentifier,
    PackagingSummary,
    ShipTo,
    Termination,
    TimeBasedDispatchProposalItemOperation,
    TimeBasedDispatchProposalOperation,
    UnpackagedDispatchProposalItem,
    UnpackagedDispatchProposalItemIdentifier,
} from '../../domain/dispatchProposal.types';
import { TransportConcept } from '../../domain/meansOfTransport.types';
import { Dimensions } from '../../reducers/shipments/types';
import { decodeJson } from '../apiUtils';
import {
    DISPATCH_PROPOSALS_TAG,
    DISPATCH_PROPOSAL_FILTER_OPTIONS_API_TAG,
    DISPATCH_PROPOSAL_PROBLEMS_TAG,
    baseApi,
} from '../baseApi';
import { FilterValuesCodec } from '../common.types';
import { mapPartner } from '../commonMapper';
import { mapItemShortDescription } from '../deliverySchedule/deliveryScheduleMapper';
import { ApiDimensions } from '../shared/dimensions.types';
import { ApiQuantity } from '../shared/quantity.types';
import { mapQuantity } from '../shared/sharedMappers';
import { mapAuxiliaryPackagingToApi } from '../shipment/packagingMapper';
import { createEnumMapper } from '../util';
import { ApiArticleSuggestion, getArticleSuggestionsResponseCodec } from './articleSuggestions.types';
import { ApiBuyer, ApiShipTo } from './common.types';
import {
    ApiArticleContent,
    ApiDispatchProposal,
    ApiDispatchProposalItem,
    ApiDispatchProposalItemIdentifier,
    ApiDispatchProposalOperations,
    ApiDispatchProposalProblem,
    ApiDispatchProposalProblemLevel,
    ApiFreightForwarder,
    ApiMissingArticleMasterDataProblemMissingData,
    ApiPackagedDispatchProposalItem,
    ApiPackagedDispatchProposalItemIdentifier,
    ApiPackagingSummary,
    ApiTermination,
    ApiTimeBasedDispatchProposalItemOperation,
    ApiTimeBasedDispatchProposalOperation,
    ApiUnpackagedDispatchProposalItem,
    ApiUnpackagedDispatchProposalItemIdentifier,
    getDispatchProposalProblemResponseCodec,
    getDispatchProposalsResponseCodec,
} from './dispatchProposal.types';
import {
    ApiDispatchProposalItemKey,
    ApiDispatchProposalOperation,
    ApiDispatchProposalsOperation,
    DispatchProposalOperation,
    DispatchProposalOperationType,
    DispatchProposalsOperation,
    dispatchProposalOperationResultCodec,
} from './operations.types';

interface GetDispatchProposalProblemsParameters {
    dunsNumber: string;
    affectedDateFrom: string;
    affectedDateTo: string;
}

interface DismissDispatchProposalProblemParameters {
    dunsNumber: string;
    problemId: string;
}

interface GetDispatchProposalParameters {
    dunsNumber: string;
    cutoffDateFrom: string;
    cutoffDateTo: string;
}

interface GetArticleSuggestionsParameters {
    id: string;
    dunsNumber: string;
}

interface GetArticleSuggestionsForDraftQueryParameters {
    placeOfDischargeId?: string;
    dunsNumber: string;
    eligibleForKep?: boolean;
}

export interface TimeWindow {
    timeFrom: string;
    timeTo: string;
}

export interface PostDispatchProposal {
    transportConcept: TransportConcept;
    dunsNumber: string;
    id: string;
    cutoffDateTime: string;
    referencedDeliveryScheduleId: string;
    amount: Quantity;
}

export interface DispatchProposalFilterValues {
    [key: string]: string[];
}

export type DispatchProposalItemKey = PackagedDispatchProposalItemIdentifier | UnpackagedDispatchProposalItemIdentifier;

const mapToFreightForwarder = (src: ApiFreightForwarder): FreightForwarder => ({
    name: src.name,
    dunsNumber: src.duns_number,
    supplierCode: src.supplier_code,
});

const mapToTermination = (src: ApiTermination): Termination => {
    switch (src.transport_concept) {
        case 'FTL':
            return {
                transportConcept: TransportConcept.FTL,
                freightForwarder: mapToFreightForwarder(src.freight_forwarder),
                cutoffDate: src.cutoff_date,
                pickupDate: src.pickup_date,
            };
        case 'LTL':
            return {
                transportConcept: TransportConcept.LTL,
                freightForwarder: mapToFreightForwarder(src.freight_forwarder),
                cutoffDate: src.cutoff_date,
                pickupDate: src.pickup_date,
            };
        case 'KEP':
            return {
                transportConcept: TransportConcept.KEP,
                cutoffDate: src.cutoff_date,
            };
        default:
            throw Error(`Should never be called. Was called with ${src.transport_concept}`);
    }
};

const mapToArticleContent = (src: ApiArticleContent): ArticleContent => ({
    articleNumberBuyer: src.article_number_buyer,
    quantity: {
        measurementUnitCode: src.quantity.measurement_unit_code,
        value: src.quantity.value,
    },
    deliveryScheduleId: src.delivery_schedule_id,
});

const mapToBuyer = (src: ApiBuyer): Buyer => ({
    name: src.name,
});

const mapToShipTo = (src: ApiShipTo): ShipTo => ({
    plantCode: src.plant_code,
    placeOfDischarge: src.place_of_discharge,
});

const mapToUnpackagedDispatchProposalItem = (
    src: ApiUnpackagedDispatchProposalItem,
): UnpackagedDispatchProposalItem => ({
    type: src.type,
    identifier: {
        shipmentId: src.identifier.shipment_id,
        deliveryNoteNumber: src.identifier.delivery_note_number,
        deliveryNotePosition: src.identifier.delivery_note_position,
    },
    articleContent: mapToArticleContent(src.article_content),
    buyer: mapToBuyer(src.buyer),
    partner: mapPartner(src.partner),
    deliveryDate: src.delivery_date,
    shipFrom: {
        shippingLocationId: src.ship_from.shipping_location_id,
    },
    shipTo: mapToShipTo(src.ship_to),
    shipmentNumber: src.shipment_number,
});

const mapToPackagedDispatchProposalItem = (src: ApiPackagedDispatchProposalItem): PackagedDispatchProposalItem => ({
    type: src.type,
    identifier: {
        id: src.identifier.id,
        shipmentId: src.identifier.shipment_id,
    },
    deliveryDate: src.delivery_date,
    articleContents: src.article_contents.map((articleContent) => mapToArticleContent(articleContent)),
    grossWeightInKg: src.gross_weight_in_kg,
    buyer: mapToBuyer(src.buyer),
    partner: mapPartner(src.partner),
    shipFrom: {
        shippingLocationId: src.ship_from.shipping_location_id,
    },
    shipTo: mapToShipTo(src.ship_to),
    shipmentNumber: src.shipment_number,
    outerPackaging: mapToPackagingSummary(src.outer_packaging),
    innerPackaging: src.inner_packaging.map((packagingSummary) => mapToPackagingSummary(packagingSummary)),
    dimensions: mapToDimensions(src.dimensions),
    referencedDeliveryNotePositions: src.referenced_delivery_note_positions.map((it) => ({
        deliveryNoteNumber: it.delivery_note_number,
        position: it.position,
    })),
});

const mapToDimensions = (src: ApiDimensions): Dimensions => ({
    widthInMm: src.width_in_mm,
    lengthInMm: src.length_in_mm,
    heightInMm: src.height_in_mm,
});

const mapToDispatchProposalItem = (src: ApiDispatchProposalItem): DispatchProposalItem => {
    switch (src.type) {
        case 'UNPACKAGED_DISPATCH_PROPOSAL_ITEM':
            return mapToUnpackagedDispatchProposalItem(src);
        case 'PACKAGED_DISPATCH_PROPOSAL_ITEM':
            return mapToPackagedDispatchProposalItem(src);
        default:
            throw new Error(`Unknown dispatch proposal item type: ${JSON.stringify(src)}`);
    }
};

const mapToPackagingSummary = (src: ApiPackagingSummary): PackagingSummary => ({
    type: src.type,
    count: src.count,
});

const mapToTimeBasedDispatchProposalOperation = (
    src: ApiTimeBasedDispatchProposalOperation,
): TimeBasedDispatchProposalOperation => ({
    allowed: src.allowed,
    availableUntil: src.available_until,
});
const mapToTimeBasedDispatchProposalItemOperation = (
    src: ApiTimeBasedDispatchProposalItemOperation,
): TimeBasedDispatchProposalItemOperation => ({
    allowedPerItem: src.allowed_per_item.map((i) => ({
        identifier: mapDispatchProposalItemIdentifier(i.identifier),
        allowed: i.allowed,
    })),
    availableUntil: src.available_until,
});

const mapToOperations = (src: ApiDispatchProposalOperations): DispatchProposalOperations => ({
    createTransportOrderIntent: mapToTimeBasedDispatchProposalItemOperation(src.create_transport_order_intent),
    deleteTransportOrderIntent: mapToTimeBasedDispatchProposalOperation(src.delete_transport_order_intent),
    addArticle: { allowed: src.add_article.allowed },
    modifyLoadItemAmount: { allowed: src.modify_load_item_amount.allowed },
    changeLoadingLocation: { allowed: src.change_loading_location.allowed },
    convertDispatchProposalItemToKep: { allowed: src.convert_dispatch_proposal_item_to_kep.allowed },
});

const mapToDispatchProposal = (src: ApiDispatchProposal): DispatchProposal => ({
    id: src.id,
    termination: mapToTermination(src.termination),
    applicationReferenceNumber: src.application_reference_number,
    items: src.items.map((item) => mapToDispatchProposalItem(item)),
    transportOrderStatus: src.transport_order_status,
    supplierTimezone: src.supplier_timezone,
    lastModifiedAt: src.last_modified_at,
    operations: mapToOperations(src.operations),
    untouched: src.untouched,
});

const transformGetDispatchProposalsResponse = (response: unknown): DispatchProposal[] => {
    const decoded = decodeJson(getDispatchProposalsResponseCodec)(response);
    return decoded.items.map((dispatchProposal) => mapToDispatchProposal(dispatchProposal));
};

const mapToArticleSuggestion = (src: ApiArticleSuggestion): ArticleSuggestion => ({
    buyer: mapToBuyer(src.buyer),
    shipFrom: {
        shippingLocationId: src.ship_from.shipping_location_id,
    },
    shipTo: mapToShipTo(src.ship_to),
    articleNumberBuyer: src.article_number_buyer,
    itemShortDescriptions: src.item_short_descriptions.map((shortDescription) =>
        mapItemShortDescription(shortDescription),
    ),
    measurementUnitCode: src.measurement_unit_code,
    referencedDeliveryScheduleId: src.referenced_delivery_schedule_id,
    hasValidArticleMasterData: src.has_valid_article_master_data,
    hasValidCumulativeQuantitySent: src.has_valid_cumulative_quantity_sent,
});

const transformGetArticleSuggestionsResponse = (response: unknown): ArticleSuggestion[] => {
    const decoded = decodeJson(getArticleSuggestionsResponseCodec)(response);
    return decoded.items.map((articleSuggestion) => mapToArticleSuggestion(articleSuggestion));
};

const mapToApiQuantity = (quantity: Quantity): ApiQuantity => ({
    measurement_unit_code: quantity.measurementUnitCode,
    value: quantity.value,
});

const mapToApiDispatchProposalOperation = (operation: DispatchProposalOperation): ApiDispatchProposalOperation => {
    switch (operation.type) {
        case DispatchProposalOperationType.ADD_ARTICLE:
            return {
                id: uuid(),
                parameters: {
                    article_amount: {
                        referenced_delivery_schedule_id: operation.referencedDeliveryScheduleId,
                        quantity: mapToApiQuantity(operation.amount),
                    },
                },
                type: 'ADD_ARTICLE',
            };
        case DispatchProposalOperationType.MODIFY_LOAD_ITEM_AMOUNT:
            return {
                id: uuid(),
                parameters: {
                    shipment_id: operation.shipmentId,
                    delivery_note_number: operation.deliveryNoteNumber,
                    delivery_note_position: operation.deliveryNotePosition,
                    target_amount: operation.targetAmount,
                },
                type: 'MODIFY_LOAD_ITEM_AMOUNT',
            };
        case DispatchProposalOperationType.DELETE_TRANSPORT_ORDER_INTENTS:
            return {
                id: uuid(),
                type: 'DELETE_TRANSPORT_ORDER_INTENTS',
            };
        case DispatchProposalOperationType.APPLY_PACKAGING_TEMPLATE_TO_ITEM:
            return {
                id: uuid(),
                parameters: {
                    item_identifier: mapToApiDispatchProposalItemKey(operation.parameters.itemIdentifier),
                    packaging_template_id: operation.parameters.packagingTemplateId,
                },
                type: 'APPLY_PACKAGING_TEMPLATE_TO_ITEM',
            };
        case DispatchProposalOperationType.COMBINE_PACKAGING:
            return {
                id: uuid(),
                parameters: {
                    shipment_id: operation.parameters.shipmentId,
                    giphys_to_combine: operation.parameters.giphysToCombine.map((item) => {
                        return {
                            giphy_id: item.giphyId,
                            amount: item.amount,
                        };
                    }),
                    amount_of_handling_units: operation.parameters.amountOfHandlingUnits,
                    outer_packaging_to_apply: {
                        type: operation.parameters.outerPackagingToApply.type,
                        description: operation.parameters.outerPackagingToApply.description,
                        auxiliary_packaging:
                            operation.parameters.outerPackagingToApply.auxiliaryPackaging.map(
                                mapAuxiliaryPackagingToApi,
                            ),
                        is_reusable: operation.parameters.outerPackagingToApply.isReusable,
                        ownership: operation.parameters.outerPackagingToApply.ownership,
                        stacking_factor: operation.parameters.outerPackagingToApply.stackingFactor,
                        tare_weight_in_kg: operation.parameters.outerPackagingToApply.tareWeightInKg,
                    },
                },
                type: 'COMBINE_PACKAGING',
            };
        case DispatchProposalOperationType.CHANGE_LOADING_LOCATION:
            return {
                id: uuid(),
                parameters: {
                    item_identifier: mapToApiDispatchProposalItemKey(operation.parameters.itemIdentifier),
                    loading_location_id: operation.parameters.loadingLocationId,
                },
                type: 'CHANGE_LOADING_LOCATION',
            };
        case DispatchProposalOperationType.CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP:
            return {
                id: uuid(),
                parameters: {
                    item_identifier: mapToApiDispatchProposalItemKey(operation.parameters.itemIdentifier),
                },
                type: 'CONVERT_DISPATCH_PROPOSAL_ITEM_TO_KEP',
            };
        default:
            return neverReachedFor(operation['type']);
    }
};

const mapToApiDispatchProposalsOperation = (operation: DispatchProposalsOperation): ApiDispatchProposalsOperation => {
    switch (operation.operation) {
        case 'CREATE_TRANSPORT_ORDER_INTENTS':
            return {
                id: uuid(),
                operation: 'CREATE_TRANSPORT_ORDER_INTENTS',
                dispatch_proposal_item_keys: mapDispatchProposalItemKeys(operation.dispatchProposalItemKeys),
                pickup_time_window: {
                    time_from: operation.pickupTimeWindow.timeFrom,
                    time_to: operation.pickupTimeWindow.timeTo,
                },
                access_code: operation.accessCode,
            };
        default:
            return neverReachedFor(operation.operation);
    }
};

const isPackagedDispatchProposalItemIdentifierKey = (
    key: DispatchProposalItemKey,
): key is PackagedDispatchProposalItemIdentifier =>
    (key as PackagedDispatchProposalItemIdentifier).id !== undefined &&
    (key as PackagedDispatchProposalItemIdentifier).shipmentId !== undefined;

const isUnpackagedDispatchProposalIdentifierKey = (
    key: DispatchProposalItemKey,
): key is UnpackagedDispatchProposalItemIdentifier =>
    (key as UnpackagedDispatchProposalItemIdentifier).deliveryNoteNumber !== undefined &&
    (key as UnpackagedDispatchProposalItemIdentifier).deliveryNotePosition !== undefined;

const mapDispatchProposalItemKeys = (keys: DispatchProposalItemKey[]): ApiDispatchProposalItemKey[] =>
    keys.map((key) => {
        return mapToApiDispatchProposalItemKey(key);
    });

const mapToApiDispatchProposalItemKey = (key: DispatchProposalItemKey): ApiDispatchProposalItemKey => {
    if (isPackagedDispatchProposalItemIdentifierKey(key)) {
        return {
            id: key.id,
            shipment_id: key.shipmentId,
        };
    }
    if (isUnpackagedDispatchProposalIdentifierKey(key)) {
        return {
            shipment_id: key.shipmentId,
            delivery_note_number: key.deliveryNoteNumber,
            delivery_note_position: key.deliveryNotePosition,
        };
    }
    throw new Error(`Cannot map unknown key of type: ${typeof key}`);
};

const mapToApiPostDispatchProposal = (src: PostDispatchProposal) => ({
    id: src.id,
    transport_concept: src.transportConcept,
    cutoff_date_time: src.cutoffDateTime,
    article_amount: {
        referenced_delivery_schedule_id: src.referencedDeliveryScheduleId,
        quantity: mapToApiQuantity(src.amount),
    },
});

const transformGetDispatchProposalFilterValues = (response: unknown): DispatchProposalFilterValues => {
    return Object.fromEntries(
        decodeJson(FilterValuesCodec)(response).items.map((filterItem) => [filterItem.key, filterItem.values]),
    );
};

const transformGetDispatchProposalProblemsResponse = (response: unknown): DispatchProposalProblem[] => {
    return decodeJson(getDispatchProposalProblemResponseCodec)(response).items.map((apiProblem) =>
        mapToDispatchProposalProblem(apiProblem),
    );
};

const mapToDispatchProposalProblem = (src: ApiDispatchProposalProblem): DispatchProposalProblem => {
    if (src.type === 'MISSING_ARTICLE_MASTER_DATA') {
        return {
            type: DispatchProposalProblemType.MISSING_ARTICLE_MASTER_DATA,
            id: src.id,
            dunsNumber: src.duns_number,
            affectedDate: src.affected_date,
            createdAt: src.created_at,
            level: mapToDispatchProposalProblemLevel(src.level),
            missingData: src.missing_data.map(mapToMissingArticleMasterDataMissingData),
            articleNumberBuyer: src.article_number_buyer,
            deliveryScheduleId: src.delivery_schedule_id,
        };
    }

    if (src.type === 'ARTICLE_QUANTITY_DISCREPANCY') {
        return {
            type: DispatchProposalProblemType.ARTICLE_QUANTITY_DISCREPANCY,
            id: src.id,
            dunsNumber: src.duns_number,
            affectedDate: src.affected_date,
            createdAt: src.created_at,
            level: mapToDispatchProposalProblemLevel(src.level),
            dismissible: src.dismissible,
            articleNumberBuyer: src.article_number_buyer,
            deliveryDate: src.delivery_date,
            targetQuantity: mapQuantity(src.target_quantity),
            plantCode: src.plant_code,
            placeOfDischarge: src.place_of_discharge,
        };
    }

    if (src.type === 'INCOMPLETE_PACKAGING') {
        return {
            type: DispatchProposalProblemType.INCOMPLETE_PACKAGING,
            id: src.id,
            dunsNumber: src.duns_number,
            level: mapToDispatchProposalProblemLevel(src.level),
            dismissible: src.dismissible,
            affectedDate: src.affected_date,
            createdAt: src.created_at,
            dispatchProposalId: src.dispatch_proposal_id,
            identifiers: mapUnpackagedDispatchProposalItemIdentifiers(src.identifiers),
        };
    }

    if (src.type === 'MISSING_PACKAGING_HEIGHT') {
        return {
            type: DispatchProposalProblemType.MISSING_PACKAGING_HEIGHT,
            id: src.id,
            dunsNumber: src.duns_number,
            level: mapToDispatchProposalProblemLevel(src.level),
            affectedDate: src.affected_date,
            createdAt: src.created_at,
            dispatchProposalId: src.dispatch_proposal_id,
            giphyId: src.giphy_id,
        };
    }

    if (src.type === 'MISSING_LOADING_LOCATION') {
        return {
            type: DispatchProposalProblemType.MISSING_LOADING_LOCATION,
            id: src.id,
            dunsNumber: src.duns_number,
            level: mapToDispatchProposalProblemLevel(src.level),
            dismissible: src.dismissible,
            affectedDate: src.affected_date,
            createdAt: src.created_at,
            dispatchProposalId: src.dispatch_proposal_id,
            identifiers: mapDispatchProposalItemIdentifiers(src.identifiers),
        };
    }

    if (src.type === 'FREIGHT_FORWARDER_CHANGED') {
        return {
            type: DispatchProposalProblemType.FREIGHT_FORWARDER_CHANGED,
            id: src.id,
            dunsNumber: src.duns_number,
            level: mapToDispatchProposalProblemLevel(src.level),
            dismissible: src.dismissible,
            affectedDate: src.affected_date,
            createdAt: src.created_at,
            dispatchProposalId: src.dispatch_proposal_id,
            newCutoffDate: src.new_cutoff_date,
        };
    }

    throw new Error(`Unknown dispatch proposal problem type: ${src}`);
};

const mapUnpackagedDispatchProposalItemIdentifiers = (
    identifiers: ApiUnpackagedDispatchProposalItemIdentifier[],
): UnpackagedDispatchProposalItemIdentifier[] => identifiers.map(mapUnpackagedDispatchProposalItemIdentifier);

const isPackagedDispatchProposalItemIdentifier = (
    identifier: ApiDispatchProposalItemIdentifier,
): identifier is ApiPackagedDispatchProposalItemIdentifier =>
    (identifier as ApiPackagedDispatchProposalItemIdentifier).id !== undefined &&
    (identifier as ApiPackagedDispatchProposalItemIdentifier).shipment_id !== undefined;

const mapDispatchProposalItemIdentifiers = (
    identifiers: ApiDispatchProposalItemIdentifier[],
): DispatchProposalItemIdentifier[] =>
    identifiers.map((identifier) => {
        return mapDispatchProposalItemIdentifier(identifier);
    });
const mapDispatchProposalItemIdentifier = (
    identifier: ApiDispatchProposalItemIdentifier,
): DispatchProposalItemIdentifier => {
    if (isPackagedDispatchProposalItemIdentifier(identifier)) {
        return mapPackagedDispatchProposalItemIdentifier(identifier);
    } else {
        return mapUnpackagedDispatchProposalItemIdentifier(identifier as ApiUnpackagedDispatchProposalItemIdentifier);
    }
};

const mapUnpackagedDispatchProposalItemIdentifier = (
    identifier: ApiUnpackagedDispatchProposalItemIdentifier,
): UnpackagedDispatchProposalItemIdentifier => ({
    shipmentId: identifier.shipment_id,
    deliveryNoteNumber: identifier.delivery_note_number,
    deliveryNotePosition: identifier.delivery_note_position,
});

const mapPackagedDispatchProposalItemIdentifier = (
    identifier: ApiPackagedDispatchProposalItemIdentifier,
): PackagedDispatchProposalItemIdentifier => ({
    id: identifier.id,
    shipmentId: identifier.shipment_id,
});

const mapToMissingArticleMasterDataMissingData = createEnumMapper(
    ApiMissingArticleMasterDataProblemMissingData,
    MissingArticleMasterDataProblemMissingData,
);

const mapToDispatchProposalProblemLevel = createEnumMapper(
    ApiDispatchProposalProblemLevel,
    DispatchProposalProblemLevel,
);

export const dispatchProposalApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getDispatchProposals: builder.query<DispatchProposal[], GetDispatchProposalParameters>({
            query: ({ dunsNumber, cutoffDateFrom, cutoffDateTo }) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals`,
                params: { termination_cutoff_date_from: cutoffDateFrom, termination_cutoff_date_to: cutoffDateTo },
            }),
            transformResponse: (response: unknown) => transformGetDispatchProposalsResponse(response),
            providesTags: () => [{ type: DISPATCH_PROPOSALS_TAG }],
        }),
        getArticleSuggestions: builder.query<ArticleSuggestion[], GetArticleSuggestionsParameters>({
            query: ({ dunsNumber, id }) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals/${id}/article-suggestions`,
            }),
            transformResponse: (response: unknown) => transformGetArticleSuggestionsResponse(response),
        }),
        getArticleSuggestionsForDraft: builder.query<ArticleSuggestion[], GetArticleSuggestionsForDraftQueryParameters>(
            {
                query: ({ dunsNumber, placeOfDischargeId, eligibleForKep }) => ({
                    url: `/shippers/${dunsNumber}/dispatch-proposal-article-suggestions`,
                    params: {
                        place_of_discharge_id: placeOfDischargeId,
                        eligible_for_kep: eligibleForKep,
                    },
                }),
                transformResponse: (response: unknown) => transformGetArticleSuggestionsResponse(response),
            },
        ),
        getDispatchProposalProblems: builder.query<DispatchProposalProblem[], GetDispatchProposalProblemsParameters>({
            query: ({ dunsNumber, affectedDateFrom, affectedDateTo }) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals/problems`,
                params: {
                    affected_date_from: affectedDateFrom,
                    affected_date_to: affectedDateTo,
                },
            }),
            transformResponse: (response: unknown) => transformGetDispatchProposalProblemsResponse(response),
            providesTags: () => [{ type: DISPATCH_PROPOSAL_PROBLEMS_TAG }],
        }),
        dismissDispatchProposalProblem: builder.mutation<undefined, DismissDispatchProposalProblemParameters>({
            query: ({ dunsNumber, problemId }) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals/problems/${problemId}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: DISPATCH_PROPOSAL_PROBLEMS_TAG }],
        }),
        postOperation: builder.mutation<undefined, DispatchProposalOperation>({
            query: (arg: DispatchProposalOperation) => ({
                url: `/shippers/${arg.dunsNumber}/dispatch-proposals/${arg.dispatchProposalId}/operations`,
                method: 'POST',
                body: mapToApiDispatchProposalOperation(arg),
            }),
            transformResponse: (response: unknown) => {
                decodeJson(dispatchProposalOperationResultCodec)(response);
                return undefined;
            },
            invalidatesTags: () => [{ type: DISPATCH_PROPOSALS_TAG }, { type: DISPATCH_PROPOSAL_PROBLEMS_TAG }],
        }),
        postDispatchProposalsOperation: builder.mutation<undefined, DispatchProposalsOperation>({
            query: (arg: DispatchProposalsOperation) => ({
                url: `/shippers/${arg.dunsNumber}/dispatch-proposals/operations`,
                method: 'POST',
                body: mapToApiDispatchProposalsOperation(arg),
            }),
            invalidatesTags: () => [{ type: DISPATCH_PROPOSALS_TAG }, { type: DISPATCH_PROPOSAL_PROBLEMS_TAG }],
        }),
        postDispatchProposal: builder.mutation<undefined, PostDispatchProposal>({
            query: (arg: PostDispatchProposal) => ({
                url: `/shippers/${arg.dunsNumber}/dispatch-proposals`,
                method: 'POST',
                body: mapToApiPostDispatchProposal(arg),
            }),
            invalidatesTags: () => [{ type: DISPATCH_PROPOSALS_TAG }],
        }),
        getDispatchProposalFilterValues: builder.query<DispatchProposalFilterValues, string>({
            query: (dunsNumber) => ({
                url: `/shippers/${dunsNumber}/dispatch-proposals-filter-values`,
            }),
            transformResponse: (response: unknown) => transformGetDispatchProposalFilterValues(response),
            providesTags: () => [DISPATCH_PROPOSAL_FILTER_OPTIONS_API_TAG],
        }),
    }),
});

export const {
    useGetDispatchProposalsQuery,
    useGetArticleSuggestionsQuery,
    useGetArticleSuggestionsForDraftQuery,
    usePostOperationMutation,
    usePostDispatchProposalsOperationMutation,
    usePostDispatchProposalMutation,
    useGetDispatchProposalProblemsQuery,
    useDismissDispatchProposalProblemMutation,
    useGetDispatchProposalFilterValuesQuery,
} = dispatchProposalApi;
