import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import { formatDateOrDateTime, toZonedDateTime } from '../../../../dateUtils';
import { usePostDispatchProposalsOperationMutation } from '../../api/dispatchProposal/dispatchProposalApi';
import { DispatchProposalsOperation } from '../../api/dispatchProposal/operations.types';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { dispatchProposalsSlice } from '../../reducers/dispatchProposal/DispatchProposalsReducer';
import {
    getAccessCode,
    getPickupDateOfSelectedDispatchProposalItems,
    getPickupTimeFrom,
    getPickupTimeTo,
    getPlanDispatchProposalsSidebarOpen,
    getSelectedDispatchProposalItems,
    getSupplierTimezone,
    getTransportConceptOfSelectedDispatchProposalItems,
} from '../../selectors/dispatchProposals/DispatchProposals.selector';
import { TimePicker } from '../common/TimePicker';
import { PlanDispatchProposalSidebarBody } from './PlanDispatchProposalSidebarBody';

export const SIDEBAR_HEADER_TEST_ID = 'SIDEBAR_HEADER';

export const PlanDispatchProposalSidebar = () => {
    const isOpen = useAppSelector(getPlanDispatchProposalsSidebarOpen);
    const dunsNumber = useDunsNumberFromPath()!;
    const transportConcept = useAppSelector(getTransportConceptOfSelectedDispatchProposalItems);
    const selectedDispatchProposalItems = useAppSelector(getSelectedDispatchProposalItems);
    const pickupDate = useAppSelector(getPickupDateOfSelectedDispatchProposalItems);
    const pickupTimeFrom = useAppSelector(getPickupTimeFrom) ?? '00:00';
    const pickupTimeTo = useAppSelector(getPickupTimeTo) ?? '23:59';
    const accessCode = useAppSelector(getAccessCode);
    // The timezone should never be undefined,
    // since this sidebar is only displayed if the transportPlanningDraft is defined.
    // But syntactically, it is possible that the supplierTimezone is undefined.
    const supplierTimezone = useAppSelector(getSupplierTimezone) ?? 'Europe/Berlin';
    // pickupDate is formatted in ISO-8601 format, e.g. 2021-08-31
    const pickupDateTimeFrom =
        pickupDate === undefined ? undefined : toZonedDateTime(`${pickupDate}T${pickupTimeFrom}`, supplierTimezone);
    const pickupDateTimeTo =
        pickupDate === undefined ? undefined : toZonedDateTime(`${pickupDate}T${pickupTimeTo}`, supplierTimezone);

    const dispatch = useAppDispatch();
    const [postDispatchProposalsOperation] = usePostDispatchProposalsOperationMutation();
    const intl = useIntl();

    const onCloseSidebar = () => {
        dispatch(dispatchProposalsSlice.actions.setPlanDispatchProposalsSidebarOpen(false));
    };

    const onDiscard = () => {
        dispatch(dispatchProposalsSlice.actions.clearTransportPlanningDraft());
    };

    const onSubmit = () => {
        if (pickupDateTimeFrom === undefined || pickupDateTimeTo === undefined) {
            // Should not happen.
            // `pickupDate` should be defined if onSubmit is clickable.
            // In this case, also the timeFrom/timeTo will be defined.
            throw new Error('pickupDateTimeFrom or -To is undefined');
        }
        const operation: DispatchProposalsOperation = {
            operation: 'CREATE_TRANSPORT_ORDER_INTENTS',
            dunsNumber,
            dispatchProposalItemKeys: selectedDispatchProposalItems.map((it) => it.identifier),
            pickupTimeWindow: {
                timeFrom: pickupDateTimeFrom,
                timeTo: pickupDateTimeTo,
            },
            accessCode,
        };
        postDispatchProposalsOperation(operation)
            .unwrap()
            .then(() => {
                Notification.success(
                    <FormattedMessage id={'webedi.dispatchProposals.planDispatchProposal.create.success'} />,
                );
                dispatch(dispatchProposalsSlice.actions.clearTransportPlanningDraft());
            })
            .catch(() => {
                Notification.error(<FormattedMessage id={'webedi.error.general'} />);
            });
    };

    return (
        isOpen && (
            <Sidebar
                className={'margin-30'}
                title={
                    <div data-testid={SIDEBAR_HEADER_TEST_ID}>
                        <FormattedMessage
                            id={'webedi.dispatchProposals.planDispatchProposal.header'}
                            values={{
                                ftlOrLtl: transportConcept!,
                                date: formatDateOrDateTime(pickupDate, intl),
                            }}
                        />
                    </div>
                }
                position={'right'}
                fly={true}
                titleClassName={'padding-left-10'}
                width={600}
                closed={!isOpen}
                onClose={onCloseSidebar}
                footer={<SidebarFooter onDiscard={onDiscard} onSubmit={onSubmit} />}
                footerClassName={'padding-0'}
                minWidth={360}
                maxWidth={640}
                resizable={true}
            >
                <PlanDispatchProposalSidebarBody />
            </Sidebar>
        )
    );
};

const SidebarFooter = (props: {
    onDiscard: () => void;
    onSubmit: () => void;
}) => {
    return (
        <>
            <AdditionalInformationInputPanel />
            <ButtonRow onDiscard={props.onDiscard} onSubmit={props.onSubmit} />
        </>
    );
};

const AdditionalInformationInputPanel = () => {
    return (
        <div className={'panel panel-default margin-0 text-left border-left-none border-right-none border-top-none'}>
            <div className={'panel-heading text-medium bg-lightest'}>
                <FormattedMessage
                    id={'webedi.dispatchProposals.planDispatchProposal.additionalInformation.panelTitle'}
                />
            </div>
            <div className={'panel-body'}>
                <div className={'display-flex flex-row align-items-center gap-15 text-left'}>
                    <div className={'form-group width-25pct'}>
                        <PickupTimeFromInput />
                    </div>
                    <div className={'form-group width-25pct'}>
                        <PickupTimeToInput />
                    </div>
                    <div className={'form-group width-50pct'}>
                        <AccessCodeInput />
                    </div>
                </div>
            </div>
        </div>
    );
};

const PickupTimeFromInput = () => {
    const pickupTimeFrom = useAppSelector(getPickupTimeFrom);
    const pickupTimeTo = useAppSelector(getPickupTimeTo);
    const accessCode = useAppSelector(getAccessCode);
    const dispatch = useAppDispatch();

    return (
        <>
            <label>
                <FormattedMessage
                    id={'webedi.dispatchProposals.planDispatchProposal.additionalInformation.pickupTimeWindow.from'}
                />
            </label>
            <TimePicker
                value={pickupTimeFrom}
                onChange={(newPickupTimeFrom) =>
                    dispatch(
                        dispatchProposalsSlice.actions.setAdditionalTransportPlanningDraftInformation({
                            pickupTimeFrom: newPickupTimeFrom,
                            pickupTimeTo,
                            accessCode,
                        }),
                    )
                }
            />
        </>
    );
};

const PickupTimeToInput = () => {
    const pickupTimeFrom = useAppSelector(getPickupTimeFrom);
    const pickupTimeTo = useAppSelector(getPickupTimeTo);
    const accessCode = useAppSelector(getAccessCode);
    const dispatch = useAppDispatch();

    return (
        <>
            <label>
                <FormattedMessage
                    id={'webedi.dispatchProposals.planDispatchProposal.additionalInformation.pickupTimeWindow.to'}
                />
            </label>
            <TimePicker
                value={pickupTimeTo}
                onChange={(newPickupTimeTo) =>
                    dispatch(
                        dispatchProposalsSlice.actions.setAdditionalTransportPlanningDraftInformation({
                            pickupTimeFrom,
                            pickupTimeTo: newPickupTimeTo,
                            accessCode,
                        }),
                    )
                }
            />
        </>
    );
};

const AccessCodeInput = () => {
    const pickupTimeFrom = useAppSelector(getPickupTimeFrom);
    const pickupTimeTo = useAppSelector(getPickupTimeTo);
    const accessCode = useAppSelector(getAccessCode);
    const dispatch = useAppDispatch();

    return (
        <>
            <label>
                <FormattedMessage
                    id={'webedi.dispatchProposals.planDispatchProposal.additionalInformation.accessCode'}
                />
            </label>
            <ClearableInput
                data-testid={'DISPATCH_PROPOSAL_SIDEBAR_ACCESS_CODE_INPUT'}
                value={accessCode}
                onChange={(newAccessCode: string) =>
                    dispatch(
                        dispatchProposalsSlice.actions.setAdditionalTransportPlanningDraftInformation({
                            pickupTimeFrom,
                            pickupTimeTo,
                            accessCode: newAccessCode,
                        }),
                    )
                }
            />
        </>
    );
};

export const ButtonRow = (props: { onDiscard: () => void; onSubmit: () => void }) => {
    return (
        <div className='display-flex flex-row justify-content-between padding-15'>
            <DiscardButton onDiscard={props.onDiscard} />
            <SubmitButton onSubmit={props.onSubmit} />
        </div>
    );
};

const DiscardButton = (props: { onDiscard: () => void }) => {
    return (
        <button type='button' className='btn btn-default btn-link' onClick={props.onDiscard}>
            <span className='rioglyph rioglyph-revert' aria-hidden='true' />
            <FormattedMessage id={'webedi.dispatchProposals.planDispatchProposal.footer.discard'} />
        </button>
    );
};

const SubmitButton = (props: { onSubmit: () => void }) => {
    return (
        <button id='createTransport' className='btn btn-primary' onClick={props.onSubmit}>
            <span className='rioglyph rioglyph-truck' aria-hidden='true' />
            <FormattedMessage id={'webedi.dispatchProposals.planDispatchProposal.footer.planTransport'} />
        </button>
    );
};
