import { FormattedMessage } from 'react-intl';
import { PackagingStepConfiguration, PackagingStepType } from '../../../reducers/deliverySchedules/types';
import { HandlingUnitCategory, HandlingUnitCategoryTranslation } from '../../common/PackagingCommon';

export const PACKAGING_TEMPLATE_CONTENT_INFORMATION_TEST_IDS = {
    content: 'PACKAGING_TEMPLATE_CONTENT_INFORMATION_CONTENT_TEST_ID',
    groupingDescription: 'PACKAGING_TEMPLATE_GROUPING_DESCRIPTION_INFORMATION_CONTENT_TEST_ID',
};

export const PackagingTemplateContentInformation = (props: {
    templateStepConfiguration: PackagingStepConfiguration;
    displayHeadline: boolean;
}) => {
    const { templateStepConfiguration, displayHeadline } = props;
    const handlingUnitDescription = templateStepConfiguration.descriptionOfHandlingUnit;
    const isInnerPackagingStepConfiguration = templateStepConfiguration.type === PackagingStepType.INNER;
    const handlingUnitCategory = isInnerPackagingStepConfiguration
        ? HandlingUnitCategory.PARTS_CONTAINER
        : HandlingUnitCategory.LOAD_CARRIER;

    return (
        <>
            {displayHeadline && (
                <span className={'text-center margin-bottom-15 text-color-success'}>
                    <div className={'text-size-18 margin-right-5 display-inline'}>
                        <span className={'rioglyph rioglyph-ok-sign'} />
                    </div>
                    <FormattedMessage id={'webedi.packaging.hasBeenCreated'} />:
                </span>
            )}
            <div
                className={'panel panel-default'}
                data-testid={PACKAGING_TEMPLATE_CONTENT_INFORMATION_TEST_IDS.content}
            >
                <div className={'panel-body'}>
                    <span className={'margin-left-5 text-bold'}>{templateStepConfiguration.typeOfHandlingUnit}</span>
                    {handlingUnitDescription && (
                        <span
                            className={'margin-left-5'}
                            data-testid={PACKAGING_TEMPLATE_CONTENT_INFORMATION_TEST_IDS.groupingDescription}
                        >
                            {handlingUnitDescription}
                        </span>
                    )}
                    <span className={'float-right label label-default label-filled label-condensed'}>
                        <FormattedMessage id={HandlingUnitCategoryTranslation[handlingUnitCategory]} />
                    </span>
                </div>
            </div>
        </>
    );
};
