// Vite currently does not support glob imports inside node modules
// see https://github.com/vitejs/vite/issues/14102
// => we hard-code all country-code based imports here

export const isoCountries = {
    af: () => import('i18n-iso-countries/langs/af.json'),
    am: () => import('i18n-iso-countries/langs/am.json'),
    ar: () => import('i18n-iso-countries/langs/ar.json'),
    az: () => import('i18n-iso-countries/langs/az.json'),
    be: () => import('i18n-iso-countries/langs/be.json'),
    bg: () => import('i18n-iso-countries/langs/bg.json'),
    bn: () => import('i18n-iso-countries/langs/bn.json'),
    br: () => import('i18n-iso-countries/langs/br.json'),
    bs: () => import('i18n-iso-countries/langs/bs.json'),
    ca: () => import('i18n-iso-countries/langs/ca.json'),
    cs: () => import('i18n-iso-countries/langs/cs.json'),
    cy: () => import('i18n-iso-countries/langs/cy.json'),
    da: () => import('i18n-iso-countries/langs/da.json'),
    de: () => import('i18n-iso-countries/langs/de.json'),
    dv: () => import('i18n-iso-countries/langs/dv.json'),
    el: () => import('i18n-iso-countries/langs/el.json'),
    en: () => import('i18n-iso-countries/langs/en.json'),
    es: () => import('i18n-iso-countries/langs/es.json'),
    et: () => import('i18n-iso-countries/langs/et.json'),
    eu: () => import('i18n-iso-countries/langs/eu.json'),
    fa: () => import('i18n-iso-countries/langs/fa.json'),
    fi: () => import('i18n-iso-countries/langs/fi.json'),
    fr: () => import('i18n-iso-countries/langs/fr.json'),
    gl: () => import('i18n-iso-countries/langs/gl.json'),
    ha: () => import('i18n-iso-countries/langs/ha.json'),
    he: () => import('i18n-iso-countries/langs/he.json'),
    hi: () => import('i18n-iso-countries/langs/hi.json'),
    hr: () => import('i18n-iso-countries/langs/hr.json'),
    hu: () => import('i18n-iso-countries/langs/hu.json'),
    hy: () => import('i18n-iso-countries/langs/hy.json'),
    id: () => import('i18n-iso-countries/langs/id.json'),
    is: () => import('i18n-iso-countries/langs/is.json'),
    it: () => import('i18n-iso-countries/langs/it.json'),
    ja: () => import('i18n-iso-countries/langs/ja.json'),
    ka: () => import('i18n-iso-countries/langs/ka.json'),
    kk: () => import('i18n-iso-countries/langs/kk.json'),
    km: () => import('i18n-iso-countries/langs/km.json'),
    ko: () => import('i18n-iso-countries/langs/ko.json'),
    ku: () => import('i18n-iso-countries/langs/ku.json'),
    ky: () => import('i18n-iso-countries/langs/ky.json'),
    lt: () => import('i18n-iso-countries/langs/lt.json'),
    lv: () => import('i18n-iso-countries/langs/lv.json'),
    mk: () => import('i18n-iso-countries/langs/mk.json'),
    ml: () => import('i18n-iso-countries/langs/ml.json'),
    mn: () => import('i18n-iso-countries/langs/mn.json'),
    mr: () => import('i18n-iso-countries/langs/mr.json'),
    ms: () => import('i18n-iso-countries/langs/ms.json'),
    nb: () => import('i18n-iso-countries/langs/nb.json'),
    nl: () => import('i18n-iso-countries/langs/nl.json'),
    nn: () => import('i18n-iso-countries/langs/nn.json'),
    no: () => import('i18n-iso-countries/langs/no.json'),
    pl: () => import('i18n-iso-countries/langs/pl.json'),
    ps: () => import('i18n-iso-countries/langs/ps.json'),
    pt: () => import('i18n-iso-countries/langs/pt.json'),
    ro: () => import('i18n-iso-countries/langs/ro.json'),
    ru: () => import('i18n-iso-countries/langs/ru.json'),
    sd: () => import('i18n-iso-countries/langs/sd.json'),
    sk: () => import('i18n-iso-countries/langs/sk.json'),
    sl: () => import('i18n-iso-countries/langs/sl.json'),
    so: () => import('i18n-iso-countries/langs/so.json'),
    sq: () => import('i18n-iso-countries/langs/sq.json'),
    sr: () => import('i18n-iso-countries/langs/sr.json'),
    sv: () => import('i18n-iso-countries/langs/sv.json'),
    sw: () => import('i18n-iso-countries/langs/sw.json'),
    ta: () => import('i18n-iso-countries/langs/ta.json'),
    tg: () => import('i18n-iso-countries/langs/tg.json'),
    th: () => import('i18n-iso-countries/langs/th.json'),
    tr: () => import('i18n-iso-countries/langs/tr.json'),
    tt: () => import('i18n-iso-countries/langs/tt.json'),
    ug: () => import('i18n-iso-countries/langs/ug.json'),
    uk: () => import('i18n-iso-countries/langs/uk.json'),
    ur: () => import('i18n-iso-countries/langs/ur.json'),
    uz: () => import('i18n-iso-countries/langs/uz.json'),
    vi: () => import('i18n-iso-countries/langs/vi.json'),
    zh: () => import('i18n-iso-countries/langs/zh.json'),
};
