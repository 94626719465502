import { neverReachedFor } from '../../../utils';
import { Partner } from '../domain/common.types';
import { ResponsibleAgencyCode } from '../reducers/deliverySchedules/types';
import {
    AdditionalPartyIdentifier,
    Party,
    PartyIdentificationDetails,
    ReferenceCodeQualifier,
} from '../reducers/types';
import { ApiAdditionalPartyIdentifier, ApiPartner, ApiReferenceCodeQualifier } from './common.types';
import { ApiParty } from './deliverySchedule/deliverySchedule.types';
import { ApiPartyIdentificationDetails, ApiResponsibleAgencyCode } from './shared/partyIdentificationDetails.types';

export const mapParty = (apiParty: ApiParty): Party => {
    return {
        partyIdentificationDetails: mapToPartyIdentificationDetails(apiParty.party_identification_details),
        additionalPartyIdentifier: apiParty.additional_party_identifier
            ? mapToAdditionalPartyIdentifier(apiParty.additional_party_identifier!)
            : undefined,
        partyName: apiParty.party_name,
        street: apiParty.street,
        cityName: apiParty.city_name,
        countrySubEntityNameCode: apiParty.country_sub_entity_name_code,
        postalIdentificationCode: apiParty.postal_identification_code,
        countryNameCode: apiParty.country_name_code,
    };
};

const mapToPartyIdentificationDetails = (details: ApiPartyIdentificationDetails): PartyIdentificationDetails => ({
    identifier: details.identifier,
    responsibleAgencyCode: mapToResponsibleAgencyCode(details.responsible_agency_code),
});

const mapToResponsibleAgencyCode = (code: ApiResponsibleAgencyCode): ResponsibleAgencyCode => {
    switch (code) {
        case ApiResponsibleAgencyCode.DUNS:
            return ResponsibleAgencyCode.DUNS;
        case ApiResponsibleAgencyCode.SELLER:
            return ResponsibleAgencyCode.SELLER;
        case ApiResponsibleAgencyCode.BUYER:
            return ResponsibleAgencyCode.BUYER;
        default:
            return neverReachedFor(code);
    }
};

const mapToAdditionalPartyIdentifier = (additional: ApiAdditionalPartyIdentifier): AdditionalPartyIdentifier => ({
    identifier: additional.reference_identifier,
    referenceCodeQualifier: mapToReferenceCodeQualifier(additional.reference_code_qualifier),
});

const mapToReferenceCodeQualifier = (code: ApiReferenceCodeQualifier): ReferenceCodeQualifier => {
    switch (code) {
        case ApiReferenceCodeQualifier.DUNS:
            return ReferenceCodeQualifier.DUNS;
        default:
            return neverReachedFor(code);
    }
};

export const mapPartner = (partner: ApiPartner): Partner => {
    switch (partner) {
        case ApiPartner.VW:
            return Partner.VW;
        case ApiPartner.PORSCHE:
            return Partner.PORSCHE;
        case ApiPartner.MAN:
            return Partner.MAN;
        case ApiPartner.POWERCO:
            return Partner.POWERCO;
        default:
            return neverReachedFor(partner);
    }
};

export const mapPartnerToApi = (partner: Partner): ApiPartner => {
    switch (partner) {
        case Partner.VW:
            return ApiPartner.VW;
        case Partner.PORSCHE:
            return ApiPartner.PORSCHE;
        case Partner.MAN:
            return ApiPartner.MAN;
        case Partner.POWERCO:
            return ApiPartner.POWERCO;
        default:
            return neverReachedFor(partner);
    }
};
